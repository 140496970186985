.error-boundry-outer-view {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: 90vh;

    & .img-div {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 350px;
            height: 350px;

            @media(max-width: 768px){
                margin-right: 10px;
            }
        }
    }

    & .header-div {
        display: flex;
        justify-content: center;
        align-items: center;

        h4 {
            font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
            color: $brand-color;
        }
    }

    & .sub-header-div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 10px;

        p {
            margin: 0;
            font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
            font-weight: 500;
            color: $font-color;
            font-size: 14px;
        }
    }
}