.blog {
    font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;

    & .section-top-start {
        margin-top: 7rem !important;

        @media (max-width: 640px) {
            margin-top: 0rem !important;
        }

        @media (min-width: 640px) and (max-width: 768px) {
            margin-top: 7rem !important;
        }
    }

    & .p-4 {
        padding: 1rem !important;
    }

    & .p-3 {
        padding: .75rem !important;
    }

    & .p-12 {
        padding: 3rem !important;
    }

    & .p-5 {
        padding: 1.25rem !important;
    }

    & .p-6 {
        padding: 1.5rem !important;
    }

    & .p-8 {
        padding: 2rem !important;
    }

    & .py-3 {
        padding-top: .75rem !important;
        padding-bottom: .75rem !important;
    }

    & .py-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important;
    }

    & .px-3 {
        padding-left: .75rem !important;
        padding-right: .75rem !important;
    }

    & .py-6 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }

    & .px-6 {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }

    & .px-4 {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }

    & .px-1 {
        padding-left: .25rem !important;
        padding-right: .25rem !important;
    }

    & .py-4 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }

    & .px-8 {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }

    & .px-2 {
        padding-left: .5rem !important;
        padding-right: .5rem !important;
    }

    & .py-12 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }

    & .py-20 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;
    }

    & .py-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important;
    }

    & .px-5 {
        padding-left: 1.25rem !important;
        padding-right: 1.25rem !important;
    }

    & .py-40 {
        padding-top: 10rem !important;
        padding-bottom: 10rem !important;
    }

    & .px-12 {
        padding-left: 3rem !important;
        padding-right: 3rem !important;
    }

    & .py-10 {
        padding-top: 2.5rem !important;
        padding-bottom: 2.5rem !important;
    }

    & .py-24 {
        padding-top: 6rem !important;
        padding-bottom: 6rem !important;
    }

    & .py-5 {
        padding-top: 1.25rem !important;
        padding-bottom: 1.25rem !important;
    }

    & .pt-4 {
        padding-top: 1rem !important;
    }

    & .pb-4 {
        padding-bottom: 1rem !important;
    }

    & .pl-5 {
        padding-left: 1.25rem !important;
    }

    & .pt-6 {
        padding-top: 1.5rem !important;
    }

    & .pt-20 {
        padding-top: 5rem !important;
    }

    & .pb-32 {
        padding-bottom: 8rem !important;
    }

    & .pl-3 {
        padding-left: .75rem !important;
    }

    & .pt-40 {
        padding-top: 10rem !important;
    }

    & .pt-24 {
        padding-top: 6rem !important;
    }

    & .pb-10 {
        padding-bottom: 2.5rem !important;
    }

    & .pb-2 {
        padding-bottom: .5rem !important;
    }

    & .pt-8 {
        padding-top: 2rem !important;
    }

    & .pb-16 {
        padding-bottom: 4rem !important;
    }

    & .pb-8 {
        padding-bottom: 2rem !important;
    }

    & .pb-20 {
        padding-bottom: 5rem !important;
    }

    & .pb-12 {
        padding-bottom: 3rem !important;
    }

    & .pl-4 {
        padding-left: 1rem !important;
    }

    & .pt-5 {
        padding-top: 1.25rem !important;
    }

    & .pl-6 {
        padding-left: 1.5rem !important;
    }

    & .pb-40 {
        padding-bottom: 10rem !important;
    }

    & .pt-3 {
        padding-top: .75rem !important;
    }

    & .pt-px {
        padding-top: 1px !important;
    }

    & .pt-12 {
        padding-top: 3rem !important;
    }

    & .pr-4 {
        padding-right: 1rem !important;
    }

    & .pt-16 {
        padding-top: 4rem !important;
    }

    & .pb-6 {
        padding-bottom: 1.5rem !important;
    }

    & .pt-10 {
        padding-top: 2.5rem !important;
    }

    & .pb-5 {
        padding-bottom: 1.25rem !important;
    }

    & .pr-10 {
        padding-right: 2.5rem !important;
    }

    & .pr-3 {
        padding-right: .75rem !important;
    }

    & .pb-3 {
        padding-bottom: .75rem !important;
    }

    & .pr-12 {
        padding-right: 3rem !important;
    }

    & .pb-24 {
        padding-bottom: 6rem !important;
    }

    & .my-4 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }

    & .mx-auto {
        margin-left: auto !important;
        margin-right: auto !important;
    }

    & .-mx-4 {
        margin-left: -1rem !important;
        margin-right: -1rem !important;
    }

    & .my-auto {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }

    & .-mx-2 {
        margin-left: -.5rem !important;
        margin-right: -.5rem !important;
    }

    & .-mx-3 {
        margin-left: -.75rem !important;
        margin-right: -.75rem !important;
    }

    & .-mx-5 {
        margin-left: -1.25rem !important;
        margin-right: -1.25rem !important;
    }

    & .my-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important;
    }

    & .mx-2 {
        margin-left: .5rem !important;
        margin-right: .5rem !important;
    }

    & .my-5 {
        margin-top: 1.25rem !important;
        margin-bottom: 1.25rem !important;
    }

    & .my-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important;
    }

    & .-mx-8 {
        margin-left: -2rem !important;
        margin-right: -2rem !important;
    }

    & .my-3 {
        margin-top: .75rem !important;
        margin-bottom: .75rem !important;
    }

    & .my-12 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important;
    }

    & .my-6 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }

    & .mt-4 {
        margin-top: 1rem !important;
    }

    & .mb-8 {
        margin-bottom: 2rem !important;
    }

    & .mr-auto {
        margin-right: auto !important;
    }

    & .mb-1 {
        margin-bottom: .25rem !important;
    }

    & .mb-3 {
        margin-bottom: .75rem !important;
    }

    & .mb-2 {
        margin-bottom: .5rem !important;
    }

    & .mt-auto {
        margin-top: auto !important;
    }

    & .mb-4 {
        margin-bottom: 1rem !important;
    }

    & .mb-12 {
        margin-bottom: 3rem !important;
    }

    & .mb-6 {
        margin-bottom: 1.5rem !important;
    }

    & .ml-auto {
        margin-left: auto !important;
    }

    & .mt-12 {
        margin-top: 3rem !important;
    }

    & .mb-16 {
        margin-bottom: 4rem !important;
    }

    & .-mt-24 {
        margin-top: -6rem !important;
    }

    & .mt-3 {
        margin-top: .75rem !important;
    }

    & .ml-2 {
        margin-left: .5rem !important;
    }

    & .-mb-6 {
        margin-bottom: -1.5rem !important;
    }

    & .mt-2 {
        margin-top: .5rem !important;
    }

    & .mt-6 {
        margin-top: 1.5rem !important;
    }

    & .mr-4 {
        margin-right: 1rem !important;
    }

    & .mr-1 {
        margin-right: .25rem !important;
    }

    & .mb-5 {
        margin-bottom: 1.25rem !important;
    }

    & .ml-1 {
        margin-left: .25rem !important;
    }

    & .mr-3 {
        margin-right: .75rem !important;
    }

    & .mt-8 {
        margin-top: 2rem !important;
    }

    & .mr-2 {
        margin-right: .5rem !important;
    }

    & .mb-10 {
        margin-bottom: 2.5rem !important;
    }

    & .mr-8 {
        margin-right: 2rem !important;
    }

    & .ml-4 {
        margin-left: 1rem !important;
    }

    & .mb-20 {
        margin-bottom: 5rem !important;
    }

    & .mr-5 {
        margin-right: 1.25rem !important;
    }

    & .mt-32 {
        margin-top: 8rem !important;
    }

    & .mt-24 {
        margin-top: 6rem !important;
    }

    & .mt-16 {
        margin-top: 4rem !important;
    }

    & .mt-5 {
        margin-top: 1.25rem !important;
    }

    & .-ml-12 {
        margin-left: -3rem !important;
    }

    & .-mt-12 {
        margin-top: -3rem !important;
    }

    & .-mr-12 {
        margin-right: -3rem !important;
    }

    & .-mb-12 {
        margin-bottom: -3rem !important;
    }

    & .mb-24 {
        margin-bottom: 6rem !important;
    }

    & .-mr-6 {
        margin-right: -1.5rem !important;
    }

    & .font-heading {
        font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
    }

    & .text-center {
        text-align: center !important;
    }

    & .text-3xl {
        font-size: 1.875rem !important;

        & span {
            font-size: 1.875rem !important;
        }
    }

    & .text-sm {
        font-size: .875rem !important;
    }

    & .text-xs {
        font-size: .75rem !important;
    }

    & .text-4xl {
        font-size: 2.25rem !important;
        line-height: 3.2rem !important;
    }

    & .text-lg {
        font-size: 1.125rem !important;
    }

    & .text-2xl {
        font-size: 1.5rem !important;
    }

    & .text-xl {
        font-size: 1.25rem !important;
    }

    & .text-base {
        font-size: 1rem !important;
    }

    & .font-semibold {
        font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
        font-weight: 600 !important;
    }

    & .font-bold {
        font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
        font-weight: 600 !important;
    }

    & .text-5xl {
        font-size: 3rem !important;
        line-height: 4rem !important;
    }

    & .flex {
        display: -moz-box !important;
        display: flex !important;
    }

    & .flex-wrap {
        flex-wrap: wrap !important;
    }

    & .justify-center {
        -moz-box-pack: center !important;
        justify-content: center !important;
    }

    & .-mx-3 {
        margin-left: -.75rem !important;
        margin-right: -.75rem !important;
    }

    & .w-full {
        width: 100% !important;
    }

    & .width-half {
        width: 50% !important;
    }

    & .width-two-third {
        width: 66.666667% !important;

        @media (max-width: 640px) {
            width: 100% !important;
        }
    }

    & .width-one-third {
        width: 33.333333% !important;

        @media (max-width: 640px) {
            width: 100% !important;
        }        
    }

    & .width-one-fourth {
        width: 25% !important;
    }

    & .width-two-fifth {
        width: 40% !important;
    }

    & .width-three-fifth {
        width: 60% !important;
    }

    & .width-one-fifth {
        width: 20% !important;
    }

    & .w-12 {
        width: 3rem !important;
    }

    & .w-8 {
        width: 2rem !important;
    }

    & .w-24 {
        width: 6rem !important;
    }

    & .w-auto {
        width: auto !important;
    }

    & .w-16 {
        width: 4rem !important;
    }

    & .w-5 {
        width: 1.25rem !important;
    }

    & .w-40 {
        width: 10rem !important;
    }

    & .w-64 {
        width: 16rem !important;
    }

    & .max-w-2xl {
        max-width: 42rem !important;
    }

    & .max-w-xl {
        max-width:36rem !important;
    }

    & .px-3 {
        padding-left: .75rem !important;
        padding-right: .75rem !important;
    }

    & .mb-12 {
        margin-bottom: 3rem !important;
    }

    & .hover-up-5,
    .hover-up-5:hover {
        -webkit-transition: .3s;
        -moz-transition: .3s;
        transition: .3s
    }

    & .hover-up-5:hover {
        -webkit-transform: translateY(-5px);
        -moz-transform: translateY(-5px);
        transform: translateY(-5px)
    }

    & .h-10 {
        height: 2.5rem !important;
    }

    & .h-4 {
        height: 1rem !important;
    }

    & .h-6 {
        height: 1.5rem !important;
    }

    & .h-12 {
        height: 3rem !important;
    }

    & .h-8 {
        height: 2rem !important;
    }

    & .h-48 {
        height: 12rem !important;
    }

    & .h-36 {
        height: 9rem !important;
    }

    & .h-24 {
        height: 6rem !important;
    }

    & .h-5 {
        height: 1.25rem !important;
    }

    & .h-80 {
        height: 20rem !important;
    }

    & .h-16 {
        height: 4rem !important;
    }

    & .h-32 {
        height: 8rem !important;
    }

    & .h-full {
        height: 100% !important;
    }

    & .h-20 {
        height: 5rem !important;
    }

    & .h-64 {
        height: 16rem !important;
    }

    & .h-128 {
        height: 32rem !important;
    }

    & .object-cover {
        object-fit: cover !important;
    }

    & .rounded {
        border-radius: .25rem !important;
    }

    & .rounded-xl {
        border-radius: .75rem !important;
    }

    & .rounded-full {
        border-radius: 9999px !important;
    }

    & .rounded-lg {
        border-radius: .5rem !important;
    }

    & .rounded-l {
        border-top-left-radius: .25rem !important;
        border-bottom-left-radius: .25rem !important;
    }

    & .rounded-r {
        border-top-right-radius: .25rem !important;
        border-bottom-right-radius: .25rem !important;
    }

    & .text-blue-200 {
        --tw-text-opacity: 1 !important;
        color:rgba(191, 219, 254, var(--tw-text-opacity)) !important;
    }

    & .text-blue-300 {
        --tw-text-opacity: 1 !important;
        color:rgba(147, 197, 253, var(--tw-text-opacity)) !important;
    }

    & .text-blue-400 {
        --tw-text-opacity: 1 !important;
        color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
    }

    & .text-blue-600 {
        --tw-text-opacity: 1 !important;
        color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
    }

    & .text-blueGray-200 {
        --tw-text-opacity: 1 !important;
        color: rgba(226, 232, 240, var(--tw-text-opacity)) !important;
    }

    & .text-blueGray-100 {
        --tw-text-opacity: 1 !important;
        color: rgba(241, 245, 249, var(--tw-text-opacity)) !important;
    }

    & .text-gray-800 {
        --tw-text-opacity: 1 !important;
        color:rgba(31, 41, 55, var(--tw-text-opacity)) !important;
    }

    & .inline-block {
        display: inline-block !important;
    }

    & .bg-blue-100,
    .bg-blue-200 {
        --tw-bg-opacity: 1 !important;
    }

    & .bg-blue-100 {
        background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
    }

    & .bg-blueGray-500 {
        --tw-bg-opacity: 1 !important;
        background-color:rgba(100, 116, 139, var(--tw-bg-opacity)) !important;
    }
    
    & .bg-blue-500 {
        background-color:rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
    }
    
    & .bg-blue-50, .bg-blue-500 {
        --tw-bg-opacity:1 !important;
    }
    
    & .bg-blue-50 {
        background-color:rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
    }

    & .bg-white {
        background-color:rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
    }
    
    & .bg-blueGray-800, .bg-white {
        --tw-bg-opacity:1 !important;
    }

    & .focus-bg-white:focus {
        --tw-bg-opacity: 1 !important;
        background-color:rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
    }

    & .bg-gray-100 {
        --tw-bg-opacity: 1 !important;
        background-color:rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
    }

    & .border {
        border-width:1px !important;
    }
    
    & .border-r {
        border-right-width:1px !important;
    }
    
    & .border-t {
        border-top-width:1px !important;
    }
    
    & .border-l-4 {
        border-left-width:4px !important;
    }
    
    & .border-b {
        border-bottom-width:1px !important;
    }
    
    & .border-blue-200 {
        --tw-border-opacity: 1 !important;
        border-color:rgba(191, 219, 254, var(--tw-border-opacity)) !important;
    }
    
    & .border-blueGray-100 {
        --tw-border-opacity: 1 !important;
        border-color:rgba(241, 245, 249, var(--tw-border-opacity)) !important;
    }
    
    & .border-gray-100 {
        --tw-border-opacity: 1 !important;
        border-color:rgba(243, 244, 246, var(--tw-border-opacity)) !important;
    }
    
    & .border-blueGray-200 {
        --tw-border-opacity: 1 !important;
        border-color:rgba(226, 232, 240, var(--tw-border-opacity)) !important;
    }
    
    & .border-blue-300 {
        border-color:rgba(147, 197, 253, var(--tw-border-opacity)) !important;
    }
    
    & .border-blue-300, .border-white {
        --tw-border-opacity:1 !important;
    }
    
    & .border-white {
        border-color:rgba(255, 255, 255, var(--tw-border-opacity)) !important;
    }
    
    & .border-gray-200 {
        --tw-border-opacity: 1 !important;
        border-color:rgba(229, 231, 235, var(--tw-border-opacity)) !important;
    }
    
    & .border-blue-500 {
        --tw-border-opacity: 1 !important;
        border-color:rgba(59, 130, 246, var(--tw-border-opacity)) !important;
    }

    .hover-border-gray-200:hover {
        --tw-border-opacity: 1 !important;
        border-color:rgba(229, 231, 235, var(--tw-border-opacity)) !important;
    }

    & .text-white {
        color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
    }

    & .text-blue-800,
    .text-white {
        --tw-text-opacity: 1 !important;
    }

    & .bg-cover {
        background-size: cover !important;
    }

    & .author-banner-div {
        @media(min-width: 768px){
            margin-top: -20px;
        }
    }

    & .bg-center {
        background-position: 50% !important;
    }

    & .bg-top {
        background-position: top !important;
    }

    & .bg-no-repeat {
        background-repeat: no-repeat !important;
    }

    & .text-blueGray-400 {
        --tw-text-opacity: 1 !important;
        color: rgba(148, 163, 184, var(--tw-text-opacity)) !important;
    }

    & .text-blue-500:hover {
        --tw-text-opacity: 1 !important;
        color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
    }

    & .leading-none {
        line-height:1 !important;
    }

    & .leading-loose {
        line-height: 2 !important;
    }

    & .leading-normal {
        line-height:1.5 !important;
    }

    & .hover-underline:hover {
        text-decoration: underline !important;
    }

    & .shadow {
        --tw-shadow: 0px 2px 4px rgba(148, 163, 184, 0.05), 0px 6px 24px rgba(235, 238, 251, 0.4) !important;
        -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
        box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
    }

    & .antialiased {
        -webkit-font-smoothing: antialiased !important;
        -moz-osx-font-smoothing:grayscale !important;
    }

    & .max-w-screen-sm {
        max-width:640px !important;
    }

    & .space-y-3 > :not([hidden]) ~ :not([hidden]) {
        --tw-space-y-reverse: 0 !important;
        margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
        margin-bottom:calc(.75rem * var(--tw-space-y-reverse)) !important;
    }
    
    & .space-y-4 > :not([hidden]) ~ :not([hidden]) {
        --tw-space-y-reverse: 0 !important;
        margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse))) !important;
        margin-bottom:calc(1rem * var(--tw-space-y-reverse)) !important;
    }
    
    & .-space-x-2 > :not([hidden]) ~ :not([hidden]) {
        --tw-space-x-reverse: 0 !important;
        margin-right: calc(-.5rem * var(--tw-space-x-reverse)) !important;
        margin-left:calc(-.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
    }
    
    & .space-y-12 > :not([hidden]) ~ :not([hidden]) {
        --tw-space-y-reverse: 0 !important;
        margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse))) !important;
        margin-bottom:calc(3rem * var(--tw-space-y-reverse)) !important;
    }
    
    & .space-x-2 > :not([hidden]) ~ :not([hidden]) {
        --tw-space-x-reverse: 0 !important;
        margin-right: calc(.5rem * var(--tw-space-x-reverse)) !important;
        margin-left:calc(.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
    }
    
    & .space-y-2 > :not([hidden]) ~ :not([hidden]) {
        --tw-space-y-reverse: 0 !important;
        margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
        margin-bottom:calc(.5rem * var(--tw-space-y-reverse)) !important;
    }

    & .flex-1 {
        -moz-box-flex: 1 !important;
        flex:1 1 !important;
    }
    
    & .flex-shrink-0 {
        flex-shrink:0 !important;
    }

    & .leading-relaxed {
        line-height:1.625 !important;
    }

    & .items-center {
        -moz-box-align: center !important;
        align-items:center !important;
    }

    & .resize-none {
        resize:none !important;
    }

    & .outline-none {
        outline: none !important;
        // outline: 2px solid transparent !important;
        // outline-offset:2px !important;

        & input {
            border: none !important;
            outline: none !important;
            border-color: transparent !important;
            border-width: 0px !important;
            border-radius: 0px !important;
            border-style: none !important;
        }
    }

    & .focus-outline-none:focus {
        outline: 2px solid transparent !important;
        outline-offset:2px !important;
    }

    & .placeholder-white:-ms-input-placeholder {
        --tw-placeholder-opacity: 1 !important;
        color:rgba(24, 24, 24, var(--tw-placeholder-opacity)) !important;
    }
    
    & .placeholder-white::-webkit-input-placeholder {
        --tw-placeholder-opacity: 1 !important;
        color:rgba(24, 24, 24, var(--tw-placeholder-opacity)) !important;
    }
    
    & .placeholder-white::placeholder {
        --tw-placeholder-opacity: 1 !important;
        color:rgba(24, 24, 24, var(--tw-placeholder-opacity)) !important;
    }

    & .input-div {
        height: 4rem;
        margin-bottom: 20px;
    }

    & .subscribe-button {
        width: 9rem !important;
        margin-top: auto;
        margin-bottom: auto;
    }

    & strong {
        font-weight: 600 !important;
    }

    & .blog-item-div {
        position: relative;
    }

    & .blog-edit-icon {
        position: absolute;
        right: 1.5rem;
        margin-top: 10px;
        color: $brand-color;
        background-color: #FFFFFF;
        font-size: 20px;
        visibility:visible;
        cursor: pointer;
        padding: 0px 10px;
    }

    & .blog-details-banner {
        box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.3);
        @media (min-width:1275px) and (max-width:1300px) {
            height: 300px !important;
        }
    }

    @media (min-width: 640px) {
        .sm-w-8 {
            width:2rem !important;
        }

        .sm-w-10 {
            width:2.5rem !important;
        }

        .sm-h-8 {
            height:2rem !important;
        }

        .sm-h-10 {
            height:2.5rem !important;
        }

        .sm-px-6 {
            padding-left: 1.5rem !important;
            padding-right:1.5rem !important;
        }

        .sm-py-4 {
            padding-top: 1rem !important;
            padding-bottom:1rem !important;
        }
    }

    @media (min-width: 768px) {
        .md-w-full {
            width:100% !important;
        }

        .md-width-two-third {
            width:66.666667% !important;
        }

        .md-text-lg {
            font-size:1.125rem !important;
        }

        .md-mb-0 {
            margin-bottom:0 !important;
        }

        .md-mr-6 {
            margin-right:1.5rem !important;
        }
    }

    @media (min-width: 1024px) {
        .lg-text-3xl {
            font-size:1.875rem !important;
        }
    }

    & .flex-direction-coloum{
        flex-direction: column;
	    align-items: flex-start !important;
    }

    & .reply-icon-div{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;
        color: #0A043B;
    }

    & .default-comment-author{
        font-size: 30px !important;
    }
    
    & .default-comment-reply-author{
        font-size: 21px !important;
    }

    & .default-reply-author{
        font-size: 18px !important;
        margin-top: -4px !important;
    }

    & .title-blog-link:hover{
        color: #0A043B !important;
    }

    & .tag-item {
        @media(max-width: 768px){
            font-size:8px !important;
        }
    }

    & .blog-title-responsive {
        @media(max-width: 768px){
            font-size:12px !important;
            line-height: 14px !important;
        }
        @media (min-width:1275px) and (max-width:1300px) {
            font-size:2.5rem !important;
            line-height: 3rem !important;
        }
    }

    & .blog-details-banner{
        @media(max-width: 768px){
            padding-top: 10px !important;
            padding-bottom: 0px !important;
            margin-bottom: 10px !important;
            margin-top: 90px !important;
        }
    }
    & .blog-margin-10{
        @media (min-width:1275px) and (max-width:1300px) {
            margin-top:-15px;
        }
    }
}