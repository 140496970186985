@import url(https://cdnjs.cloudflare.com/ajax/libs/cookieconsent2/3.1.0/cookieconsent.min.css);
/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
	--blue: #007bff;
	--indigo: #6610f2;
	--purple: #6f42c1;
	--pink: #e83e8c;
	--red: #dc3545;
	--orange: #fd7e14;
	--yellow: #ffc107;
	--green: #28a745;
	--teal: #20c997;
	--cyan: #17a2b8;
	--white: #fff;
	--gray: #6c757d;
	--gray-dark: #343a40;
	--breakpoint-xs: 0;
	--breakpoint-sm: 576px;
	--breakpoint-md: 768px;
	--breakpoint-lg: 992px;
	--breakpoint-xl: 1200px;
	--breakpoint-xxl: 1600px;
	--font-family-sans-serif: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
	--font-family-monospace: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

html {
	font-family: sans-serif;
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-ms-overflow-style: scrollbar;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
	display: block;
}

body {
	margin: 0;
	font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.71429;
	color: #9a9a9a;
	text-align: left;
	background-color: #ffffff;
}

[tabindex="-1"]:focus {
	outline: 0 !important;
}

hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
	margin-bottom: 0.5rem;
}

p {
	margin-top: 0;
	margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
	text-decoration: underline;
	-webkit-text-decoration: underline dotted;
	        text-decoration: underline dotted;
	cursor: help;
	border-bottom: 0;
}

address {
	margin-bottom: 1rem;
	font-style: normal;
	line-height: inherit;
}

ol,
ul,
dl {
	margin-top: 0;
	margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
	margin-bottom: 0;
}

dt {
	font-weight: inherit;
}

dd {
	margin-bottom: .5rem;
	margin-left: 0;
}

blockquote {
	margin: 0 0 1rem;
}

dfn {
	font-style: italic;
}

b,
strong {
	font-weight: bolder;
}

small {
	font-size: 80%;
}

sub,
sup {
	position: relative;
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline;
}

sub {
	bottom: -.25em;
}

sup {
	top: -.5em;
}

a {
	color: #0A043B;
	text-decoration: none;
	background-color: transparent;
	-webkit-text-decoration-skip: objects;
}

a:hover {
	color: #0A043B;
	text-decoration: underline;
}

a:not([href]):not([tabindex]) {
	color: inherit;
	text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
	color: inherit;
	text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
	outline: 0;
}

pre,
code,
kbd,
samp {
	font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
	font-size: 1em;
}

pre {
	margin-top: 0;
	margin-bottom: 1rem;
	overflow: auto;
	-ms-overflow-style: scrollbar;
}

figure {
	margin: 0 0 1rem;
}

img {
	vertical-align: middle;
	border-style: none;
}

svg {
	overflow: hidden;
	vertical-align: middle;
}

table {
	border-collapse: collapse;
}

caption {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	color: #6c757d;
	text-align: left;
	caption-side: bottom;
}

th {
	text-align: inherit;
}

label {
	display: inline-block;
	margin-bottom: 0.5rem;
}

button {
	border-radius: 0;
}

button:focus {
	outline: 1px dotted;
	outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

button,
input {
	overflow: visible;
}

button,
select {
	text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
	-webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
	padding: 0;
	border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
	box-sizing: border-box;
	padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
	-webkit-appearance: listbox;
}

textarea {
	overflow: auto;
	resize: vertical;
}

fieldset {
	min-width: 0;
	padding: 0;
	margin: 0;
	border: 0;
}

legend {
	display: block;
	width: 100%;
	max-width: 100%;
	padding: 0;
	margin-bottom: .5rem;
	font-size: 1.5rem;
	line-height: inherit;
	color: inherit;
	white-space: normal;
}

progress {
	vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
	height: auto;
}

[type="search"] {
	outline-offset: -2px;
	-webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

::-webkit-file-upload-button {
	font: inherit;
	-webkit-appearance: button;
}

output {
	display: inline-block;
}

summary {
	display: list-item;
	cursor: pointer;
}

template {
	display: none;
}

[hidden] {
	display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	margin-bottom: 0.5rem;
	font-family: "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
	font-weight: 300;
	line-height: 1.1;
	color: #151515;
}

h1, .h1 {
	font-size: 70px;
}

h2, .h2 {
	font-size: 48px;
}

h3, .h3 {
	font-size: 36px;
}

h4, .h4 {
	font-size: 24px;
}

h5, .h5 {
	font-size: 18px;
}

h6, .h6 {
	font-size: 16px;
}

.lead {
	font-size: 24px;
	font-weight: 300;
}

.display-1 {
	font-size: 6rem;
	font-weight: 300;
	line-height: 1.2;
}

.display-2 {
	font-size: 5.5rem;
	font-weight: 300;
	line-height: 1.2;
}

.display-3 {
	font-size: 4.5rem;
	font-weight: 300;
	line-height: 1.2;
}

.display-4 {
	font-size: 3.5rem;
	font-weight: 300;
	line-height: 1.2;
}

hr {
	margin-top: 1rem;
	margin-bottom: 1rem;
	border: 0;
	border-top: 1px solid #e0e0e0;
}

small,
.small {
	font-size: 80%;
	font-weight: 400;
}

mark,
.mark {
	padding: 5px 10px;
	background-color: #0A043B;
}

.list-unstyled {
	padding-left: 0;
	list-style: none;
}

.list-inline {
	padding-left: 0;
	list-style: none;
}

.list-inline-item {
	display: inline-block;
}

.list-inline-item:not(:last-child) {
	margin-right: 5px;
}

.initialism {
	font-size: 90%;
	text-transform: uppercase;
}

.blockquote {
	margin-bottom: 1rem;
	font-size: 1.25rem;
}

.blockquote-footer {
	display: block;
	font-size: 80%;
	color: #6c757d;
}

.blockquote-footer::before {
	content: "\2014 \00A0";
}

.img-fluid {
	max-width: 100%;
	height: auto;
}

.img-thumbnail {
	padding: 0.25rem;
	background-color: #fff;
	border: 1px solid #dee2e6;
	border-radius: 0.25rem;
	max-width: 100%;
	height: auto;
}

.figure {
	display: inline-block;
}

.figure-img {
	margin-bottom: 0.5rem;
	line-height: 1;
}

.figure-caption {
	font-size: 90%;
	color: #6c757d;
}

code {
	font-size: 90%;
	color: #151515;
	word-break: break-word;
}

a > code {
	color: inherit;
}

kbd {
	padding: 0.2rem 0.4rem;
	font-size: 87.5%;
	color: #fff;
	background-color: #212529;
	border-radius: 0.2rem;
}

kbd kbd {
	padding: 0;
	font-size: 100%;
	font-weight: 700;
}

pre {
	display: block;
	font-size: 90%;
	color: #212529;
}

pre code {
	font-size: inherit;
	color: inherit;
	word-break: normal;
}

.pre-scrollable {
	max-height: 340px;
	overflow-y: scroll;
}

.container {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}

@media (min-width: 576px) {
	.container {
		max-width: 540px;
		margin-top: 25px;
    }	
}

@media (min-width: 768px) {
	.container {
		max-width: 720px;
	}
}

@media (min-width: 992px) {
	.container {
		max-width: 960px;
	}
}

@media (min-width: 1200px) {
	.container {
		max-width: 1200px;
	}
}

.container-fluid {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}

.row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}

.no-gutters {
	margin-right: 0;
	margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
	padding-right: 0;
	padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
	position: relative;
	width: 100%;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}

.col {
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
}

.col-auto {
	flex: 0 0 auto;
	width: auto;
	max-width: none;
}

.col-1 {
	flex: 0 0 8.33333%;
	max-width: 8.33333%;
}

.col-2 {
	flex: 0 0 16.66667%;
	max-width: 16.66667%;
}

.col-3 {
	flex: 0 0 25%;
	max-width: 25%;
}

.col-4 {
	flex: 0 0 33.33333%;
	max-width: 33.33333%;
}

.col-5 {
	flex: 0 0 41.66667%;
	max-width: 41.66667%;
}

.col-6 {
	flex: 0 0 50%;
	max-width: 50%;
}

.col-7 {
	flex: 0 0 58.33333%;
	max-width: 58.33333%;
}

.col-8 {
	flex: 0 0 66.66667%;
	max-width: 66.66667%;
}

.col-9 {
	flex: 0 0 75%;
	max-width: 75%;
}

.col-10 {
	flex: 0 0 83.33333%;
	max-width: 83.33333%;
}

.col-11 {
	flex: 0 0 91.66667%;
	max-width: 91.66667%;
}

.col-12 {
	flex: 0 0 100%;
	max-width: 100%;
}

.order-first {
	order: -1;
}

.order-last {
	order: 13;
}

.order-0 {
	order: 0;
}

.order-1 {
	order: 1;
}

.order-2 {
	order: 2;
}

.order-3 {
	order: 3;
}

.order-4 {
	order: 4;
}

.order-5 {
	order: 5;
}

.order-6 {
	order: 6;
}

.order-7 {
	order: 7;
}

.order-8 {
	order: 8;
}

.order-9 {
	order: 9;
}

.order-10 {
	order: 10;
}

.order-11 {
	order: 11;
}

.order-12 {
	order: 12;
}

.offset-1 {
	margin-left: 8.33333%;
}

.offset-2 {
	margin-left: 16.66667%;
}

.offset-3 {
	margin-left: 25%;
}

.offset-4 {
	margin-left: 33.33333%;
}

.offset-5 {
	margin-left: 41.66667%;
}

.offset-6 {
	margin-left: 50%;
}

.offset-7 {
	margin-left: 58.33333%;
}

.offset-8 {
	margin-left: 66.66667%;
}

.offset-9 {
	margin-left: 75%;
}

.offset-10 {
	margin-left: 83.33333%;
}

.offset-11 {
	margin-left: 91.66667%;
}

@media (min-width: 576px) {
	.col-sm {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
	}
	.col-sm-auto {
		flex: 0 0 auto;
		width: auto;
		max-width: none;
	}
	.col-sm-1 {
		flex: 0 0 8.33333%;
		max-width: 8.33333%;
	}
	.col-sm-2 {
		flex: 0 0 16.66667%;
		max-width: 16.66667%;
	}
	.col-sm-3 {
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-sm-4 {
		flex: 0 0 33.33333%;
		max-width: 33.33333%;
	}
	.col-sm-5 {
		flex: 0 0 41.66667%;
		max-width: 41.66667%;
	}
	.col-sm-6 {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-sm-7 {
		flex: 0 0 58.33333%;
		max-width: 58.33333%;
	}
	.col-sm-8 {
		flex: 0 0 66.66667%;
		max-width: 66.66667%;
	}
	.col-sm-9 {
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-sm-10 {
		flex: 0 0 83.33333%;
		max-width: 83.33333%;
	}
	.col-sm-11 {
		flex: 0 0 91.66667%;
		max-width: 91.66667%;
	}
	.col-sm-12 {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.order-sm-first {
		order: -1;
	}
	.order-sm-last {
		order: 13;
	}
	.order-sm-0 {
		order: 0;
	}
	.order-sm-1 {
		order: 1;
	}
	.order-sm-2 {
		order: 2;
	}
	.order-sm-3 {
		order: 3;
	}
	.order-sm-4 {
		order: 4;
	}
	.order-sm-5 {
		order: 5;
	}
	.order-sm-6 {
		order: 6;
	}
	.order-sm-7 {
		order: 7;
	}
	.order-sm-8 {
		order: 8;
	}
	.order-sm-9 {
		order: 9;
	}
	.order-sm-10 {
		order: 10;
	}
	.order-sm-11 {
		order: 11;
	}
	.order-sm-12 {
		order: 12;
	}
	.offset-sm-0 {
		margin-left: 0;
	}
	.offset-sm-1 {
		margin-left: 8.33333%;
	}
	.offset-sm-2 {
		margin-left: 16.66667%;
	}
	.offset-sm-3 {
		margin-left: 25%;
	}
	.offset-sm-4 {
		margin-left: 33.33333%;
	}
	.offset-sm-5 {
		margin-left: 41.66667%;
	}
	.offset-sm-6 {
		margin-left: 50%;
	}
	.offset-sm-7 {
		margin-left: 58.33333%;
	}
	.offset-sm-8 {
		margin-left: 66.66667%;
	}
	.offset-sm-9 {
		margin-left: 75%;
	}
	.offset-sm-10 {
		margin-left: 83.33333%;
	}
	.offset-sm-11 {
		margin-left: 91.66667%;
	}
}

@media (min-width: 768px) {
	.col-md {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
	}
	.col-md-auto {
		flex: 0 0 auto;
		width: auto;
		max-width: none;
	}
	.col-md-1 {
		flex: 0 0 8.33333%;
		max-width: 8.33333%;
	}
	.col-md-2 {
		flex: 0 0 16.66667%;
		max-width: 16.66667%;
	}
	.col-md-3 {
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-md-4 {
		flex: 0 0 33.33333%;
		max-width: 33.33333%;
	}
	.col-md-5 {
		flex: 0 0 41.66667%;
		max-width: 41.66667%;
	}
	.col-md-6 {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-md-7 {
		flex: 0 0 58.33333%;
		max-width: 58.33333%;
	}
	.col-md-8 {
		flex: 0 0 66.66667%;
		max-width: 66.66667%;
	}
	.col-md-9 {
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-md-10 {
		flex: 0 0 83.33333%;
		max-width: 83.33333%;
	}
	.col-md-11 {
		flex: 0 0 91.66667%;
		max-width: 91.66667%;
	}
	.col-md-12 {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.order-md-first {
		order: -1;
	}
	.order-md-last {
		order: 13;
	}
	.order-md-0 {
		order: 0;
	}
	.order-md-1 {
		order: 1;
	}
	.order-md-2 {
		order: 2;
	}
	.order-md-3 {
		order: 3;
	}
	.order-md-4 {
		order: 4;
	}
	.order-md-5 {
		order: 5;
	}
	.order-md-6 {
		order: 6;
	}
	.order-md-7 {
		order: 7;
	}
	.order-md-8 {
		order: 8;
	}
	.order-md-9 {
		order: 9;
	}
	.order-md-10 {
		order: 10;
	}
	.order-md-11 {
		order: 11;
	}
	.order-md-12 {
		order: 12;
	}
	.offset-md-0 {
		margin-left: 0;
	}
	.offset-md-1 {
		margin-left: 8.33333%;
	}
	.offset-md-2 {
		margin-left: 16.66667%;
	}
	.offset-md-3 {
		margin-left: 25%;
	}
	.offset-md-4 {
		margin-left: 33.33333%;
	}
	.offset-md-5 {
		margin-left: 41.66667%;
	}
	.offset-md-6 {
		margin-left: 50%;
	}
	.offset-md-7 {
		margin-left: 58.33333%;
	}
	.offset-md-8 {
		margin-left: 66.66667%;
	}
	.offset-md-9 {
		margin-left: 75%;
	}
	.offset-md-10 {
		margin-left: 83.33333%;
	}
	.offset-md-11 {
		margin-left: 91.66667%;
	}
}

@media (min-width: 992px) {
	.col-lg {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
	}
	.col-lg-auto {
		flex: 0 0 auto;
		width: auto;
		max-width: none;
	}
	.col-lg-1 {
		flex: 0 0 8.33333%;
		max-width: 8.33333%;
	}
	.col-lg-2 {
		flex: 0 0 16.66667%;
		max-width: 16.66667%;
	}
	.col-lg-3 {
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-lg-4 {
		flex: 0 0 33.33333%;
		max-width: 33.33333%;
	}
	.col-lg-5 {
		flex: 0 0 41.66667%;
		max-width: 41.66667%;
	}
	.col-lg-6 {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-lg-7 {
		flex: 0 0 58.33333%;
		max-width: 58.33333%;
	}
	.col-lg-8 {
		flex: 0 0 66.66667%;
		max-width: 66.66667%;
	}
	.col-lg-9 {
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-lg-10 {
		flex: 0 0 83.33333%;
		max-width: 83.33333%;
	}
	.col-lg-11 {
		flex: 0 0 91.66667%;
		max-width: 91.66667%;
	}
	.col-lg-12 {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.order-lg-first {
		order: -1;
	}
	.order-lg-last {
		order: 13;
	}
	.order-lg-0 {
		order: 0;
	}
	.order-lg-1 {
		order: 1;
	}
	.order-lg-2 {
		order: 2;
	}
	.order-lg-3 {
		order: 3;
	}
	.order-lg-4 {
		order: 4;
	}
	.order-lg-5 {
		order: 5;
	}
	.order-lg-6 {
		order: 6;
	}
	.order-lg-7 {
		order: 7;
	}
	.order-lg-8 {
		order: 8;
	}
	.order-lg-9 {
		order: 9;
	}
	.order-lg-10 {
		order: 10;
	}
	.order-lg-11 {
		order: 11;
	}
	.order-lg-12 {
		order: 12;
	}
	.offset-lg-0 {
		margin-left: 0;
	}
	.offset-lg-1 {
		margin-left: 8.33333%;
	}
	.offset-lg-2 {
		margin-left: 16.66667%;
	}
	.offset-lg-3 {
		margin-left: 25%;
	}
	.offset-lg-4 {
		margin-left: 33.33333%;
	}
	.offset-lg-5 {
		margin-left: 41.66667%;
	}
	.offset-lg-6 {
		margin-left: 50%;
	}
	.offset-lg-7 {
		margin-left: 58.33333%;
	}
	.offset-lg-8 {
		margin-left: 66.66667%;
	}
	.offset-lg-9 {
		margin-left: 75%;
	}
	.offset-lg-10 {
		margin-left: 83.33333%;
	}
	.offset-lg-11 {
		margin-left: 91.66667%;
	}
}

@media (min-width: 1200px) {
	.col-xl {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
	}
	.col-xl-auto {
		flex: 0 0 auto;
		width: auto;
		max-width: none;
	}
	.col-xl-1 {
		flex: 0 0 8.33333%;
		max-width: 8.33333%;
	}
	.col-xl-2 {
		flex: 0 0 16.66667%;
		max-width: 16.66667%;
	}
	.col-xl-3 {
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-xl-4 {
		flex: 0 0 33.33333%;
		max-width: 33.33333%;
	}
	.col-xl-5 {
		flex: 0 0 41.66667%;
		max-width: 41.66667%;
	}
	.col-xl-6 {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-xl-7 {
		flex: 0 0 58.33333%;
		max-width: 58.33333%;
	}
	.col-xl-8 {
		flex: 0 0 66.66667%;
		max-width: 66.66667%;
	}
	.col-xl-9 {
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-xl-10 {
		flex: 0 0 83.33333%;
		max-width: 83.33333%;
	}
	.col-xl-11 {
		flex: 0 0 91.66667%;
		max-width: 91.66667%;
	}
	.col-xl-12 {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.order-xl-first {
		order: -1;
	}
	.order-xl-last {
		order: 13;
	}
	.order-xl-0 {
		order: 0;
	}
	.order-xl-1 {
		order: 1;
	}
	.order-xl-2 {
		order: 2;
	}
	.order-xl-3 {
		order: 3;
	}
	.order-xl-4 {
		order: 4;
	}
	.order-xl-5 {
		order: 5;
	}
	.order-xl-6 {
		order: 6;
	}
	.order-xl-7 {
		order: 7;
	}
	.order-xl-8 {
		order: 8;
	}
	.order-xl-9 {
		order: 9;
	}
	.order-xl-10 {
		order: 10;
	}
	.order-xl-11 {
		order: 11;
	}
	.order-xl-12 {
		order: 12;
	}
	.offset-xl-0 {
		margin-left: 0;
	}
	.offset-xl-1 {
		margin-left: 8.33333%;
	}
	.offset-xl-2 {
		margin-left: 16.66667%;
	}
	.offset-xl-3 {
		margin-left: 25%;
	}
	.offset-xl-4 {
		margin-left: 33.33333%;
	}
	.offset-xl-5 {
		margin-left: 41.66667%;
	}
	.offset-xl-6 {
		margin-left: 50%;
	}
	.offset-xl-7 {
		margin-left: 58.33333%;
	}
	.offset-xl-8 {
		margin-left: 66.66667%;
	}
	.offset-xl-9 {
		margin-left: 75%;
	}
	.offset-xl-10 {
		margin-left: 83.33333%;
	}
	.offset-xl-11 {
		margin-left: 91.66667%;
	}
}

@media (min-width: 1600px) {
	.col-xxl {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
	}
	.col-xxl-auto {
		flex: 0 0 auto;
		width: auto;
		max-width: none;
	}
	.col-xxl-1 {
		flex: 0 0 8.33333%;
		max-width: 8.33333%;
	}
	.col-xxl-2 {
		flex: 0 0 16.66667%;
		max-width: 16.66667%;
	}
	.col-xxl-3 {
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-xxl-4 {
		flex: 0 0 33.33333%;
		max-width: 33.33333%;
	}
	.col-xxl-5 {
		flex: 0 0 41.66667%;
		max-width: 41.66667%;
	}
	.col-xxl-6 {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-xxl-7 {
		flex: 0 0 58.33333%;
		max-width: 58.33333%;
	}
	.col-xxl-8 {
		flex: 0 0 66.66667%;
		max-width: 66.66667%;
	}
	.col-xxl-9 {
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-xxl-10 {
		flex: 0 0 83.33333%;
		max-width: 83.33333%;
	}
	.col-xxl-11 {
		flex: 0 0 91.66667%;
		max-width: 91.66667%;
	}
	.col-xxl-12 {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.order-xxl-first {
		order: -1;
	}
	.order-xxl-last {
		order: 13;
	}
	.order-xxl-0 {
		order: 0;
	}
	.order-xxl-1 {
		order: 1;
	}
	.order-xxl-2 {
		order: 2;
	}
	.order-xxl-3 {
		order: 3;
	}
	.order-xxl-4 {
		order: 4;
	}
	.order-xxl-5 {
		order: 5;
	}
	.order-xxl-6 {
		order: 6;
	}
	.order-xxl-7 {
		order: 7;
	}
	.order-xxl-8 {
		order: 8;
	}
	.order-xxl-9 {
		order: 9;
	}
	.order-xxl-10 {
		order: 10;
	}
	.order-xxl-11 {
		order: 11;
	}
	.order-xxl-12 {
		order: 12;
	}
	.offset-xxl-0 {
		margin-left: 0;
	}
	.offset-xxl-1 {
		margin-left: 8.33333%;
	}
	.offset-xxl-2 {
		margin-left: 16.66667%;
	}
	.offset-xxl-3 {
		margin-left: 25%;
	}
	.offset-xxl-4 {
		margin-left: 33.33333%;
	}
	.offset-xxl-5 {
		margin-left: 41.66667%;
	}
	.offset-xxl-6 {
		margin-left: 50%;
	}
	.offset-xxl-7 {
		margin-left: 58.33333%;
	}
	.offset-xxl-8 {
		margin-left: 66.66667%;
	}
	.offset-xxl-9 {
		margin-left: 75%;
	}
	.offset-xxl-10 {
		margin-left: 83.33333%;
	}
	.offset-xxl-11 {
		margin-left: 91.66667%;
	}
}

.table {
	width: 100%;
	margin-bottom: 1rem;
	background-color: transparent;
}

.table th,
.table td {
	padding: 0.75rem;
	vertical-align: top;
	border-top: 1px solid #dee2e6;
}

.table thead th {
	vertical-align: bottom;
	border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
	border-top: 2px solid #dee2e6;
}

.table .table {
	background-color: #ffffff;
}

.table-sm th,
.table-sm td {
	padding: 0.3rem;
}

.table-bordered {
	border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
	border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
	border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
	border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
	background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
	background-color: rgba(0, 0, 0, 0.075);
}

.table-active,
.table-active > th,
.table-active > td {
	background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
	background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
	background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
	color: #fff;
	background-color: #212529;
	border-color: #32383e;
}

.table .thead-light th {
	color: #495057;
	background-color: #e9ecef;
	border-color: #dee2e6;
}

.table-dark {
	color: #fff;
	background-color: #212529;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
	border-color: #32383e;
}

.table-dark.table-bordered {
	border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
	background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
	background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
	.table-responsive-sm {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar;
	}
	.table-responsive-sm > .table-bordered {
		border: 0;
	}
}

@media (max-width: 767.98px) {
	.table-responsive-md {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar;
	}
	.table-responsive-md > .table-bordered {
		border: 0;
	}
}

@media (max-width: 991.98px) {
	.table-responsive-lg {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar;
	}
	.table-responsive-lg > .table-bordered {
		border: 0;
	}
}

@media (max-width: 1199.98px) {
	.table-responsive-xl {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar;
	}
	.table-responsive-xl > .table-bordered {
		border: 0;
	}
}

@media (max-width: 1599.98px) {
	.table-responsive-xxl {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar;
	}
	.table-responsive-xxl > .table-bordered {
		border: 0;
	}
}

.table-responsive {
	display: block;
	width: 100%;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive > .table-bordered {
	border: 0;
}

.form-control {
	display: block;
	width: 100%;
	height: calc(2.25rem + 2px);
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
	.form-control {
		transition: none;
	}
}

.form-control::-ms-expand {
	background-color: transparent;
	border: 0;
}

.form-control:focus {
	color: #495057;
	background-color: #fff;
	border-color: #80bdff;
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::-webkit-input-placeholder {
	color: #6c757d;
	opacity: 1;
}

.form-control::placeholder {
	color: #6c757d;
	opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
	background-color: #e9ecef;
	opacity: 1;
}

select.form-control:focus::-ms-value {
	color: #495057;
	background-color: #fff;
}

.form-control-file,
.form-control-range {
	display: block;
	width: 100%;
}

.col-form-label {
	padding-top: calc(0.375rem + 1px);
	padding-bottom: calc(0.375rem + 1px);
	margin-bottom: 0;
	font-size: inherit;
	line-height: 1.5;
}

.col-form-label-lg {
	padding-top: calc(0.5rem + 1px);
	padding-bottom: calc(0.5rem + 1px);
	font-size: 18px;
	line-height: 1.5;
}

.col-form-label-sm {
	padding-top: calc(0.25rem + 1px);
	padding-bottom: calc(0.25rem + 1px);
	font-size: 12px;
	line-height: 1.5;
}

.form-control-plaintext {
	display: block;
	width: 100%;
	padding-top: 0.375rem;
	padding-bottom: 0.375rem;
	margin-bottom: 0;
	line-height: 1.5;
	color: #212529;
	background-color: transparent;
	border: solid transparent;
	border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
	padding-right: 0;
	padding-left: 0;
}

.form-control-sm {
	height: calc(1.8125rem + 2px);
	padding: 0.25rem 0.5rem;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 0.2rem;
}

.form-control-lg {
	height: calc(2.875rem + 2px);
	padding: 0.5rem 1rem;
	font-size: 18px;
	line-height: 1.5;
	border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
	height: auto;
}

textarea.form-control {
	height: auto;
}

.form-group {
	margin-bottom: 1rem;
}

.form-text {
	display: block;
	margin-top: 0.25rem;
}

.form-row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -5px;
	margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
	padding-right: 5px;
	padding-left: 5px;
}

.form-check {
	position: relative;
	display: block;
	padding-left: 1.25rem;
}

.form-check-input {
	position: absolute;
	margin-top: 0.3rem;
	margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
	color: #6c757d;
}

.form-check-label {
	margin-bottom: 0;
}

.form-check-inline {
	display: inline-flex;
	align-items: center;
	padding-left: 0;
	margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
	position: static;
	margin-top: 0;
	margin-right: 0.3125rem;
	margin-left: 0;
}

.valid-feedback {
	display: none;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 80%;
	color: #98bf44;
}

.valid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: 6px 10px;
	margin-top: .1rem;
	font-size: 0.875rem;
	line-height: 1.71429;
	color: #212529;
	background-color: #98bf44;
	border-radius: 0.25rem;
}

.was-validated .form-control:valid, .form-control.is-valid {
	border-color: #98bf44;
	padding-right: 2.25rem;
	background-repeat: no-repeat;
	background-position: center right calc(2.25rem / 4);
	background-size: calc(2.25rem / 2) calc(2.25rem / 2);
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
	border-color: #98bf44;
	box-shadow: 0 0 0 0.2rem rgba(152, 191, 68, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip {
	display: block;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
	padding-right: 2.25rem;
	background-position: top calc(2.25rem / 4) right calc(2.25rem / 4);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
	border-color: #98bf44;
	padding-right: 2.25rem;
	background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E") no-repeat center right 2.025rem/calc(2.25rem / 2) calc(2.25rem / 2);
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
	border-color: #98bf44;
	box-shadow: 0 0 0 0.2rem rgba(152, 191, 68, 0.25);
}

.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
	display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
	display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
	color: #98bf44;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
	display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
	color: #98bf44;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
	background-color: #cce0a3;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
	display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
	background-color: #adcc6a;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
	box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(152, 191, 68, 0.25);
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
	border-color: #98bf44;
}

.was-validated .custom-file-input:valid ~ .custom-file-label::after, .custom-file-input.is-valid ~ .custom-file-label::after {
	border-color: inherit;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
	display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
	box-shadow: 0 0 0 0.2rem rgba(152, 191, 68, 0.25);
}

.invalid-feedback {
	display: none;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 80%;
	color: #eb6a6a;
}

.invalid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: 6px 10px;
	margin-top: .1rem;
	font-size: 0.875rem;
	line-height: 1.71429;
	color: #fff;
	background-color: #eb6a6a;
	border-radius: 0.25rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
	border-color: #eb6a6a;
	padding-right: 2.25rem;
	background-repeat: no-repeat;
	background-position: center right calc(2.25rem / 4);
	background-size: calc(2.25rem / 2) calc(2.25rem / 2);
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E");
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
	border-color: #eb6a6a;
	box-shadow: 0 0 0 0.2rem rgba(235, 106, 106, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip {
	display: block;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
	padding-right: 2.25rem;
	background-position: top calc(2.25rem / 4) right calc(2.25rem / 4);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
	border-color: #eb6a6a;
	padding-right: 2.25rem;
	background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E") no-repeat center right 2.025rem/calc(2.25rem / 2) calc(2.25rem / 2);
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
	border-color: #eb6a6a;
	box-shadow: 0 0 0 0.2rem rgba(235, 106, 106, 0.25);
}

.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
	display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
	display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
	color: #eb6a6a;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
	display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
	color: #eb6a6a;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
	background-color: #fadada;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
	display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
	background-color: #f19797;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
	box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(235, 106, 106, 0.25);
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
	border-color: #eb6a6a;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label::after, .custom-file-input.is-invalid ~ .custom-file-label::after {
	border-color: inherit;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
	display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
	box-shadow: 0 0 0 0.2rem rgba(235, 106, 106, 0.25);
}

.form-inline {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
}

.form-inline .form-check {
	width: 100%;
}

@media (min-width: 576px) {
	.form-inline label {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 0;
	}
	.form-inline .form-group {
		display: flex;
		flex: 0 0 auto;
		flex-flow: row wrap;
		align-items: center;
		margin-bottom: 0;
	}
	.form-inline .form-control {
		display: inline-block;
		width: auto;
		vertical-align: middle;
	}
	.form-inline .form-control-plaintext {
		display: inline-block;
	}
	.form-inline .input-group,
	.form-inline .custom-select {
		width: auto;
	}
	.form-inline .form-check {
		display: flex;
		align-items: center;
		justify-content: center;
		width: auto;
		padding-left: 0;
	}
	.form-inline .form-check-input {
		position: relative;
		margin-top: 0;
		margin-right: 0.25rem;
		margin-left: 0;
	}
	.form-inline .custom-control {
		align-items: center;
		justify-content: center;
	}
	.form-inline .custom-control-label {
		margin-bottom: 0;
	}
}

.btn {
	display: inline-block;
	font-weight: 700;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-webkit-user-select: none;
	        user-select: none;
	border: 1px solid transparent;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0;
	transition: all 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
	.btn {
		transition: none;
	}
}

.btn:hover, .btn:focus {
	text-decoration: none;
}

.btn:focus, .btn.focus {
	outline: 0;
	box-shadow: 0;
}

.btn.disabled, .btn:disabled {
	opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
	cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
	pointer-events: none;
}

.btn-link {
	font-weight: 400;
	color: #0A043B;
	background-color: transparent;
}

.btn-link:hover {
	color: #0A043B;
	text-decoration: underline;
	background-color: transparent;
	border-color: transparent;
}

.btn-link:focus, .btn-link.focus {
	text-decoration: underline;
	border-color: transparent;
	box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
	color: #868e96;
	pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
	padding: 0.5rem 1rem;
	font-size: 18px;
	line-height: 1.5;
	border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
	padding: 0.25rem 0.5rem;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 0.2rem;
}

.btn-block {
	display: block;
	width: 100%;
}

.btn-block + .btn-block {
	margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
	width: 100%;
}

.fade {
	transition: opacity 0.15s linear;
}

@media screen and (prefers-reduced-motion: reduce) {
	.fade {
		transition: none;
	}
}

.fade:not(.show) {
	opacity: 0;
}

.collapse:not(.show) {
	display: none;
}

.collapsing {
	position: relative;
	height: 0;
	overflow: hidden;
	transition: height 0.35s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
	.collapsing {
		transition: none;
	}
}

.dropup,
.dropright,
.dropdown,
.dropleft {
	position: relative;
}

.dropdown-toggle::after {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0.3em solid;
	border-right: 0.3em solid transparent;
	border-bottom: 0;
	border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
	margin-left: 0;
}

.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 10rem;
	padding: 0.5rem 0;
	margin: 0.125rem 0 0;
	font-size: 1rem;
	color: #9a9a9a;
	text-align: left;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 0.25rem;
}

.dropdown-menu-right {
	right: 0;
	left: auto;
}

.dropup .dropdown-menu {
	top: auto;
	bottom: 100%;
	margin-top: 0;
	margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0;
	border-right: 0.3em solid transparent;
	border-bottom: 0.3em solid;
	border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
	margin-left: 0;
}

.dropright .dropdown-menu {
	top: 0;
	right: auto;
	left: 100%;
	margin-top: 0;
	margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0.3em solid transparent;
	border-right: 0;
	border-bottom: 0.3em solid transparent;
	border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
	margin-left: 0;
}

.dropright .dropdown-toggle::after {
	vertical-align: 0;
}

.dropleft .dropdown-menu {
	top: 0;
	right: 100%;
	left: auto;
	margin-top: 0;
	margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
}

.dropleft .dropdown-toggle::after {
	display: none;
}

.dropleft .dropdown-toggle::before {
	display: inline-block;
	width: 0;
	height: 0;
	margin-right: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0.3em solid transparent;
	border-right: 0.3em solid;
	border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
	margin-left: 0;
}

.dropleft .dropdown-toggle::before {
	vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
	right: auto;
	bottom: auto;
}

.dropdown-divider {
	height: 0;
	margin: 0.5rem 0;
	overflow: hidden;
	border-top: 1px solid #e9ecef;
}

.dropdown-item {
	display: block;
	width: 100%;
	padding: 0.25rem 1.5rem;
	clear: both;
	font-weight: 400;
	color: #212529;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
	color: #16181b;
	text-decoration: none;
	background-color: #f8f9fa;
}

.dropdown-item.active, .dropdown-item:active {
	color: #fff;
	text-decoration: none;
	background-color: #007bff;
}

.dropdown-item.disabled, .dropdown-item:disabled {
	color: #6c757d;
	background-color: transparent;
}

.dropdown-menu.show {
	display: block;
}

.dropdown-header {
	display: block;
	padding: 0.5rem 1.5rem;
	margin-bottom: 0;
	font-size: 12px;
	color: #6c757d;
	white-space: nowrap;
}

.dropdown-item-text {
	display: block;
	padding: 0.25rem 1.5rem;
	color: #212529;
}

.btn-group,
.btn-group-vertical {
	position: relative;
	display: inline-flex;
	vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
	position: relative;
	flex: 0 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
	z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
	z-index: 1;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
	margin-left: -1px;
}

.btn-toolbar {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
}

.btn-toolbar .input-group {
	width: auto;
}

.btn-group > .btn:first-child {
	margin-left: 0;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
	padding-right: 0.5625rem;
	padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
	margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
	margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
	padding-right: 0.375rem;
	padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}

.btn-group-vertical {
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}

.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
	width: 100%;
}

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
	margin-top: -1px;
	margin-left: 0;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
	margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
	position: absolute;
	clip: rect(0, 0, 0, 0);
	pointer-events: none;
}

.input-group {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	width: 100%;
}

.input-group > .form-control,
.input-group > .custom-select,
.input-group > .custom-file {
	position: relative;
	flex: 1 1 auto;
	width: 1%;
	margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
	margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
	z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
	z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.input-group > .custom-file {
	display: flex;
	align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
	display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
	position: relative;
	z-index: 2;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
	margin-left: -1px;
}

.input-group-prepend {
	margin-right: -1px;
}

.input-group-append {
	margin-left: -1px;
}

.input-group-text {
	display: flex;
	align-items: center;
	padding: 0.375rem 0.75rem;
	margin-bottom: 0;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	text-align: center;
	white-space: nowrap;
	background-color: #e9ecef;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
	margin-top: 0;
}

.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
	height: calc(2.875rem + 2px);
	padding: 0.5rem 1rem;
	font-size: 18px;
	line-height: 1.5;
	border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
	height: calc(1.8125rem + 2px);
	padding: 0.25rem 0.5rem;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 0.2rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.custom-control {
	position: relative;
	display: block;
	min-height: 1.71429rem;
	padding-left: 1.5rem;
}

.custom-control-inline {
	display: inline-flex;
	margin-right: 1rem;
}

.custom-control-input {
	position: absolute;
	z-index: -1;
	opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
	color: #fff;
	background-color: #007bff;
}

.custom-control-input:focus ~ .custom-control-label::before {
	box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-control-input:active ~ .custom-control-label::before {
	color: #fff;
	background-color: #b3d7ff;
}

.custom-control-input:disabled ~ .custom-control-label {
	color: #6c757d;
}

.custom-control-input:disabled ~ .custom-control-label::before {
	background-color: #e9ecef;
}

.custom-control-label {
	position: relative;
	margin-bottom: 0;
}

.custom-control-label::before {
	position: absolute;
	top: 0.35714rem;
	left: -1.5rem;
	display: block;
	width: 1rem;
	height: 1rem;
	pointer-events: none;
	content: "";
	-webkit-user-select: none;
	        user-select: none;
	background-color: #dee2e6;
}

.custom-control-label::after {
	position: absolute;
	top: 0.35714rem;
	left: -1.5rem;
	display: block;
	width: 1rem;
	height: 1rem;
	content: "";
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 50% 50%;
}

.custom-checkbox .custom-control-label::before {
	border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
	background-color: #007bff;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
	background-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
	background-color: rgba(0, 123, 255, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
	background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
	border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
	background-color: #007bff;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
	background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
	display: inline-block;
	width: 100%;
	height: calc(2.25rem + 2px);
	padding: 0.375rem 1.75rem 0.375rem 0.75rem;
	line-height: 1.5;
	color: #495057;
	vertical-align: middle;
	background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center/8px 10px;
	background-color: #fff;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	-webkit-appearance: none;
	        appearance: none;
}

.custom-select:focus {
	border-color: #80bdff;
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(128, 189, 255, 0.5);
}

.custom-select:focus::-ms-value {
	color: #495057;
	background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
	height: auto;
	padding-right: 0.75rem;
	background-image: none;
}

.custom-select:disabled {
	color: #6c757d;
	background-color: #e9ecef;
}

.custom-select::-ms-expand {
	opacity: 0;
}

.custom-select-sm {
	height: calc(1.8125rem + 2px);
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	padding-left: 0.5rem;
	font-size: 0.875rem;
}

.custom-select-lg {
	height: calc(2.875rem + 2px);
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-left: 1rem;
	font-size: 1.25rem;
}

.custom-file {
	position: relative;
	display: inline-block;
	width: 100%;
	height: calc(2.25rem + 2px);
	margin-bottom: 0;
}

.custom-file-input {
	position: relative;
	z-index: 2;
	width: 100%;
	height: calc(2.25rem + 2px);
	margin: 0;
	opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
	border-color: #80bdff;
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-file-input:focus ~ .custom-file-label::after {
	border-color: #80bdff;
}

.custom-file-input:disabled ~ .custom-file-label {
	background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
	content: "Browse";
}

.custom-file-label {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1;
	height: calc(2.25rem + 2px);
	padding: 0.375rem 0.75rem;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

.custom-file-label::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 3;
	display: block;
	height: 2.25rem;
	padding: 0.375rem 0.75rem;
	line-height: 1.5;
	color: #495057;
	content: "Browse";
	background-color: #e9ecef;
	border-left: 1px solid #ced4da;
	border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
	width: 100%;
	height: calc(1rem + 0.4rem);
	padding: 0;
	background-color: transparent;
	-webkit-appearance: none;
	        appearance: none;
}

.custom-range:focus {
	outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
	box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-moz-range-thumb {
	box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-ms-thumb {
	box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range::-moz-focus-outer {
	border: 0;
}

.custom-range::-webkit-slider-thumb {
	width: 1rem;
	height: 1rem;
	margin-top: -0.25rem;
	background-color: #007bff;
	border: 0;
	border-radius: 1rem;
	-webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	-webkit-appearance: none;
	        appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
	.custom-range::-webkit-slider-thumb {
		-webkit-transition: none;
		transition: none;
	}
}

.custom-range::-webkit-slider-thumb:active {
	background-color: #b3d7ff;
}

.custom-range::-webkit-slider-runnable-track {
	width: 100%;
	height: 0.5rem;
	color: transparent;
	cursor: pointer;
	background-color: #dee2e6;
	border-color: transparent;
	border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
	width: 1rem;
	height: 1rem;
	background-color: #007bff;
	border: 0;
	border-radius: 1rem;
	-moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
	.custom-range::-moz-range-thumb {
		-moz-transition: none;
		transition: none;
	}
}

.custom-range::-moz-range-thumb:active {
	background-color: #b3d7ff;
}

.custom-range::-moz-range-track {
	width: 100%;
	height: 0.5rem;
	color: transparent;
	cursor: pointer;
	background-color: #dee2e6;
	border-color: transparent;
	border-radius: 1rem;
}

.custom-range::-ms-thumb {
	width: 1rem;
	height: 1rem;
	margin-top: 0;
	margin-right: 0.2rem;
	margin-left: 0.2rem;
	background-color: #007bff;
	border: 0;
	border-radius: 1rem;
	-ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
	.custom-range::-ms-thumb {
		-ms-transition: none;
		transition: none;
	}
}

.custom-range::-ms-thumb:active {
	background-color: #b3d7ff;
}

.custom-range::-ms-track {
	width: 100%;
	height: 0.5rem;
	color: transparent;
	cursor: pointer;
	background-color: transparent;
	border-color: transparent;
	border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
	background-color: #dee2e6;
	border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
	margin-right: 15px;
	background-color: #dee2e6;
	border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
	background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
	cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
	background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
	cursor: default;
}

.custom-range:disabled::-ms-thumb {
	background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
	transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
	.custom-control-label::before,
	.custom-file-label,
	.custom-select {
		transition: none;
	}
}

.nav {
	display: flex;
	flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}

.nav-link {
	display: block;
	padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
	text-decoration: none;
}

.nav-link.disabled {
	color: #6c757d;
}

.nav-tabs {
	border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
	margin-bottom: -1px;
}

.nav-tabs .nav-link {
	border: 1px solid transparent;
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
	border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
	color: #6c757d;
	background-color: transparent;
	border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
	color: #495057;
	background-color: #fff;
	border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
	margin-top: -1px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.nav-pills .nav-link {
	border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
	color: #fff;
	background-color: #007bff;
}

.nav-fill .nav-item {
	flex: 1 1 auto;
	text-align: center;
}

.nav-justified .nav-item {
	flex-basis: 0;
	flex-grow: 1;
	text-align: center;
}

.tab-content > .tab-pane {
	display: none;
}

.tab-content > .active {
	display: block;
}

.navbar {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	padding: 0.5rem 1rem;
}

.navbar > .container,
.navbar > .container-fluid {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
}

.navbar-brand {
	display: inline-block;
	padding-top: 0.3125rem;
	padding-bottom: 0.3125rem;
	margin-right: 1rem;
	font-size: 1.25rem;
	line-height: inherit;
	white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
	text-decoration: none;
}

.navbar-nav {
	display: flex;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}

.navbar-nav .nav-link {
	padding-right: 0;
	padding-left: 0;
}

.navbar-nav .dropdown-menu {
	position: static;
	float: none;
}

.navbar-text {
	display: inline-block;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.navbar-collapse {
	flex-basis: 100%;
	flex-grow: 1;
	align-items: center;
}

.navbar-toggler {
	padding: 0.25rem 0.75rem;
	font-size: 1.25rem;
	line-height: 1;
	background-color: transparent;
	border: 1px solid transparent;
	border-radius: 0.25rem;
}

.navbar-toggler:hover, .navbar-toggler:focus {
	text-decoration: none;
}

.navbar-toggler:not(:disabled):not(.disabled) {
	cursor: pointer;
}

.navbar-toggler-icon {
	display: inline-block;
	width: 1.5em;
	height: 1.5em;
	vertical-align: middle;
	content: "";
	background: no-repeat center center;
	background-size: 100% 100%;
}

@media (max-width: 575.98px) {
	.navbar-expand-sm > .container,
	.navbar-expand-sm > .container-fluid {
		padding-right: 0;
		padding-left: 0;
	}
}

@media (min-width: 576px) {
	.navbar-expand-sm {
		flex-flow: row nowrap;
		justify-content: flex-start;
	}
	.navbar-expand-sm .navbar-nav {
		flex-direction: row;
	}
	.navbar-expand-sm .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-sm .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}
	.navbar-expand-sm > .container,
	.navbar-expand-sm > .container-fluid {
		flex-wrap: nowrap;
	}
	.navbar-expand-sm .navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}
	.navbar-expand-sm .navbar-toggler {
		display: none;
	}
}

@media (max-width: 767.98px) {
	.navbar-expand-md > .container,
	.navbar-expand-md > .container-fluid {
		padding-right: 0;
		padding-left: 0;
	}
}

@media (min-width: 768px) {
	.navbar-expand-md {
		flex-flow: row nowrap;
		justify-content: flex-start;
	}
	.navbar-expand-md .navbar-nav {
		flex-direction: row;
	}
	.navbar-expand-md .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-md .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}
	.navbar-expand-md > .container,
	.navbar-expand-md > .container-fluid {
		flex-wrap: nowrap;
	}
	.navbar-expand-md .navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}
	.navbar-expand-md .navbar-toggler {
		display: none;
	}
}

@media (max-width: 991.98px) {
	.navbar-expand-lg > .container,
	.navbar-expand-lg > .container-fluid {
		padding-right: 0;
		padding-left: 0;
	}
}

@media (min-width: 992px) {
	.navbar-expand-lg {
		flex-flow: row nowrap;
		justify-content: flex-start;
	}
	.navbar-expand-lg .navbar-nav {
		flex-direction: row;
	}
	.navbar-expand-lg .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-lg .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}
	.navbar-expand-lg > .container,
	.navbar-expand-lg > .container-fluid {
		flex-wrap: nowrap;
	}
	.navbar-expand-lg .navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}
	.navbar-expand-lg .navbar-toggler {
		display: none;
	}
}

@media (max-width: 1199.98px) {
	.navbar-expand-xl > .container,
	.navbar-expand-xl > .container-fluid {
		padding-right: 0;
		padding-left: 0;
	}
}

@media (min-width: 1200px) {
	.navbar-expand-xl {
		flex-flow: row nowrap;
		justify-content: flex-start;
	}
	.navbar-expand-xl .navbar-nav {
		flex-direction: row;
	}
	.navbar-expand-xl .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-xl .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}
	.navbar-expand-xl > .container,
	.navbar-expand-xl > .container-fluid {
		flex-wrap: nowrap;
	}
	.navbar-expand-xl .navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}
	.navbar-expand-xl .navbar-toggler {
		display: none;
	}
}

@media (max-width: 1599.98px) {
	.navbar-expand-xxl > .container,
	.navbar-expand-xxl > .container-fluid {
		padding-right: 0;
		padding-left: 0;
	}
}

@media (min-width: 1600px) {
	.navbar-expand-xxl {
		flex-flow: row nowrap;
		justify-content: flex-start;
	}
	.navbar-expand-xxl .navbar-nav {
		flex-direction: row;
	}
	.navbar-expand-xxl .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-xxl .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}
	.navbar-expand-xxl > .container,
	.navbar-expand-xxl > .container-fluid {
		flex-wrap: nowrap;
	}
	.navbar-expand-xxl .navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}
	.navbar-expand-xxl .navbar-toggler {
		display: none;
	}
}

.navbar-expand {
	flex-flow: row nowrap;
	justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
	padding-right: 0;
	padding-left: 0;
}

.navbar-expand .navbar-nav {
	flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
	position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
	padding-right: 0.5rem;
	padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
	flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
	display: flex !important;
	flex-basis: auto;
}

.navbar-expand .navbar-toggler {
	display: none;
}

.navbar-light .navbar-brand {
	color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
	color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
	color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
	color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
	color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
	color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
	color: rgba(0, 0, 0, 0.5);
	border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
	color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
	color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
	color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
	color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
	color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
	color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
	color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
	color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
	color: #fff;
}

.navbar-dark .navbar-toggler {
	color: rgba(255, 255, 255, 0.5);
	border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
	color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
	color: #fff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
	color: #fff;
}

.card {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.25rem;
}

.card > hr {
	margin-right: 0;
	margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
	border-bottom-right-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
}

.card-body {
	flex: 1 1 auto;
	padding: 1.25rem;
}

.card-title {
	margin-bottom: 0.75rem;
}

.card-subtitle {
	margin-top: -0.375rem;
	margin-bottom: 0;
}

.card-text:last-child {
	margin-bottom: 0;
}

.card-link:hover {
	text-decoration: none;
}

.card-link + .card-link {
	margin-left: 1.25rem;
}

.card-header {
	padding: 0.75rem 1.25rem;
	margin-bottom: 0;
	background-color: rgba(0, 0, 0, 0.03);
	border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
	border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
	border-top: 0;
}

.card-footer {
	padding: 0.75rem 1.25rem;
	background-color: rgba(0, 0, 0, 0.03);
	border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
	border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
	margin-right: -0.625rem;
	margin-bottom: -0.75rem;
	margin-left: -0.625rem;
	border-bottom: 0;
}

.card-header-pills {
	margin-right: -0.625rem;
	margin-left: -0.625rem;
}

.card-img-overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 1.25rem;
}

.card-img {
	width: 100%;
	border-radius: calc(0.25rem - 1px);
}

.card-img-top {
	width: 100%;
	border-top-left-radius: calc(0.25rem - 1px);
	border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
	width: 100%;
	border-bottom-right-radius: calc(0.25rem - 1px);
	border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
	display: flex;
	flex-direction: column;
}

.card-deck .card {
	margin-bottom: 15px;
}

@media (min-width: 576px) {
	.card-deck {
		flex-flow: row wrap;
		margin-right: -15px;
		margin-left: -15px;
	}
	.card-deck .card {
		display: flex;
		flex: 1 0;
		flex-direction: column;
		margin-right: 15px;
		margin-bottom: 0;
		margin-left: 15px;
	}
}

.card-group {
	display: flex;
	flex-direction: column;
}

.card-group > .card {
	margin-bottom: 15px;
}

@media (min-width: 576px) {
	.card-group {
		flex-flow: row wrap;
	}
	.card-group > .card {
		flex: 1 0;
		margin-bottom: 0;
	}
	.card-group > .card + .card {
		margin-left: 0;
		border-left: 0;
	}
	.card-group > .card:first-child {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
	.card-group > .card:first-child .card-img-top,
	.card-group > .card:first-child .card-header {
		border-top-right-radius: 0;
	}
	.card-group > .card:first-child .card-img-bottom,
	.card-group > .card:first-child .card-footer {
		border-bottom-right-radius: 0;
	}
	.card-group > .card:last-child {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
	.card-group > .card:last-child .card-img-top,
	.card-group > .card:last-child .card-header {
		border-top-left-radius: 0;
	}
	.card-group > .card:last-child .card-img-bottom,
	.card-group > .card:last-child .card-footer {
		border-bottom-left-radius: 0;
	}
	.card-group > .card:only-child {
		border-radius: 0.25rem;
	}
	.card-group > .card:only-child .card-img-top,
	.card-group > .card:only-child .card-header {
		border-top-left-radius: 0.25rem;
		border-top-right-radius: 0.25rem;
	}
	.card-group > .card:only-child .card-img-bottom,
	.card-group > .card:only-child .card-footer {
		border-bottom-right-radius: 0.25rem;
		border-bottom-left-radius: 0.25rem;
	}
	.card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
		border-radius: 0;
	}
	.card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
	.card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
	.card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
	.card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
		border-radius: 0;
	}
}

.card-columns .card {
	margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
	.card-columns {
		-webkit-column-count: 3;
		        column-count: 3;
		grid-column-gap: 1.25rem;
		-webkit-column-gap: 1.25rem;
		        column-gap: 1.25rem;
		orphans: 1;
		widows: 1;
	}
	.card-columns .card {
		display: inline-block;
		width: 100%;
	}
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
	border-bottom: 0;
	border-radius: 0;
}

.accordion .card:not(:first-of-type) .card-header:first-child {
	border-radius: 0;
}

.accordion .card:first-of-type {
	border-bottom: 0;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.accordion .card:last-of-type {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.breadcrumb {
	display: flex;
	flex-wrap: wrap;
	padding: 0.75rem 1rem;
	margin-bottom: 1rem;
	list-style: none;
	background-color: #e9ecef;
	border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
	padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
	display: inline-block;
	padding-right: 0.5rem;
	color: #6c757d;
	content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
	text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
	text-decoration: none;
}

.breadcrumb-item.active {
	color: #6c757d;
}

.pagination {
	display: flex;
	padding-left: 0;
	list-style: none;
	border-radius: 0;
}

.page-link {
	position: relative;
	display: block;
	padding: 5px 12px;
	margin-left: -1px;
	line-height: 24px;
	color: #000000;
	background-color: #ffffff;
	border: 1px solid #e0e0e0;
}

.page-link:hover {
	z-index: 2;
	color: #ffffff;
	text-decoration: none;
	background-color: #0A043B;
	border-color: #0A043B;
}

.page-link:focus {
	z-index: 2;
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-link:not(:disabled):not(.disabled) {
	cursor: pointer;
}

.page-item:first-child .page-link {
	margin-left: 0;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.page-item:last-child .page-link {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.page-item.active .page-link {
	z-index: 1;
	color: #ffffff;
	background-color: #0A043B;
	border-color: #0A043B;
}

.page-item.disabled .page-link {
	color: #b7b7b7;
	pointer-events: none;
	cursor: auto;
	background-color: #e0e0e0;
	border-color: #e0e0e0;
}

.pagination-lg .page-link {
	padding: 0.75rem 1.5rem;
	font-size: 18px;
	line-height: 1.44444;
}

.pagination-lg .page-item:first-child .page-link {
	border-top-left-radius: 0.3rem;
	border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
	border-top-right-radius: 0.3rem;
	border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
	padding: 0.25rem 0.5rem;
	font-size: 12px;
	line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
	border-top-left-radius: 0.2rem;
	border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
	border-top-right-radius: 0.2rem;
	border-bottom-right-radius: 0.2rem;
}

.badge {
	display: inline-block;
	padding: 0.25em 0.4em;
	font-size: 75%;
	font-weight: 700;
	line-height: 1;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 0.25rem;
}

.badge:empty {
	display: none;
}

.btn .badge {
	position: relative;
	top: -1px;
}

.badge-pill {
	padding-right: 0.6em;
	padding-left: 0.6em;
	border-radius: 10rem;
}

.jumbotron {
	padding: 2rem 1rem;
	margin-bottom: 2rem;
	background-color: #e9ecef;
	border-radius: 0.3rem;
}

@media (min-width: 576px) {
	.jumbotron {
		padding: 4rem 2rem;
	}
}

.jumbotron-fluid {
	padding-right: 0;
	padding-left: 0;
	border-radius: 0;
}

.alert {
	position: relative;
	padding: 0.75rem 1.25rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: 0.25rem;
}

.alert-heading {
	color: inherit;
}

.alert-link {
	font-weight: 700;
}

.alert-dismissible {
	padding-right: 4rem;
}

.alert-dismissible .close {
	position: absolute;
	top: 0;
	right: 0;
	padding: 0.75rem 1.25rem;
	color: inherit;
}

@-webkit-keyframes progress-bar-stripes {
	from {
		background-position: 1rem 0;
	}
	to {
		background-position: 0 0;
	}
}

@keyframes progress-bar-stripes {
	from {
		background-position: 1rem 0;
	}
	to {
		background-position: 0 0;
	}
}

.progress {
	display: flex;
	height: 1rem;
	overflow: hidden;
	font-size: 0.75rem;
	background-color: #e9ecef;
	border-radius: 0.25rem;
}

.progress-bar {
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	background-color: #007bff;
	transition: width 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
	.progress-bar {
		transition: none;
	}
}

.progress-bar-striped {
	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-size: 1rem 1rem;
}

.progress-bar-animated {
	-webkit-animation: progress-bar-stripes 1s linear infinite;
	        animation: progress-bar-stripes 1s linear infinite;
}

.media {
	display: flex;
	align-items: flex-start;
}

.media-body {
	flex: 1 1;
}

.list-group {
	display: flex;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
}

.list-group-item-action {
	width: 100%;
	color: #495057;
	text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
	color: #495057;
	text-decoration: none;
	background-color: #f8f9fa;
}

.list-group-item-action:active {
	color: #212529;
	background-color: #e9ecef;
}

.list-group-item {
	position: relative;
	display: block;
	padding: 0.75rem 1.25rem;
	margin-bottom: -1px;
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
	margin-bottom: 0;
	border-bottom-right-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
}

.list-group-item:hover, .list-group-item:focus {
	z-index: 1;
	text-decoration: none;
}

.list-group-item.disabled, .list-group-item:disabled {
	color: #6c757d;
	background-color: #fff;
}

.list-group-item.active {
	z-index: 2;
	color: #fff;
	background-color: #007bff;
	border-color: #007bff;
}

.list-group-flush .list-group-item {
	border-right: 0;
	border-left: 0;
	border-radius: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
	border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
	border-bottom: 0;
}

.close {
	float: right;
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 1;
	color: #000;
	text-shadow: 0 1px 0 #fff;
	opacity: .5;
}

.close:not(:disabled):not(.disabled) {
	cursor: pointer;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
	color: #000;
	text-decoration: none;
	opacity: .75;
}

button.close {
	padding: 0;
	background-color: transparent;
	border: 0;
	-webkit-appearance: none;
}

.modal-open {
	overflow: hidden;
}

.modal-open .modal {
	overflow-x: hidden;
	overflow-y: auto;
}

.modal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1050;
	display: none;
	overflow: hidden;
	outline: 0;
}

.modal-dialog {
	position: relative;
	width: auto;
	margin: 0.5rem;
	pointer-events: none;
}

.modal.fade .modal-dialog {
	transition: -webkit-transform 0.3s ease-out;
	transition: transform 0.3s ease-out;
	transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
	-webkit-transform: translate(0, -25%);
	        transform: translate(0, -25%);
}

@media screen and (prefers-reduced-motion: reduce) {
	.modal.fade .modal-dialog {
		transition: none;
	}
}

.modal.show .modal-dialog {
	-webkit-transform: translate(0, 0);
	        transform: translate(0, 0);
}

.modal-dialog-centered {
	display: flex;
	align-items: center;
	min-height: calc(100% - (0.5rem * 2));
}

.modal-dialog-centered::before {
	display: block;
	height: calc(100vh - (0.5rem * 2));
	content: "";
}

.modal-content {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	pointer-events: auto;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 0.3rem;
	outline: 0;
}

.modal-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1040;
	background-color: #000;
}

.modal-backdrop.fade {
	opacity: 0;
}

.modal-backdrop.show {
	opacity: 0.5;
}

.modal-header {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding: 1rem;
	border-bottom: 1px solid #e9ecef;
	border-top-left-radius: 0.3rem;
	border-top-right-radius: 0.3rem;
}

.modal-header .close {
	padding: 1rem;
	margin: -1rem -1rem -1rem auto;
}

.modal-title {
	margin-bottom: 0;
	line-height: 1.5;
}

.modal-body {
	position: relative;
	flex: 1 1 auto;
	padding: 1rem;
}

.modal-footer {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 1rem;
	border-top: 1px solid #e9ecef;
}

.modal-footer > :not(:first-child) {
	margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
	margin-right: .25rem;
}

.modal-scrollbar-measure {
	position: absolute;
	top: -9999px;
	width: 50px;
	height: 50px;
	overflow: scroll;
}

@media (min-width: 576px) {
	.modal-dialog {
		max-width: 500px;
		margin: 1.75rem auto;
	}
	.modal-dialog-centered {
		min-height: calc(100% - (1.75rem * 2));
	}
	.modal-dialog-centered::before {
		height: calc(100vh - (1.75rem * 2));
	}
	.modal-sm {
		max-width: 300px;
	}
}

@media (min-width: 992px) {
	.modal-lg {
		max-width: 800px;
	}
}

@media (min-width: 1200px) {
	.modal-xl {
		max-width: 1140px;
	}
}

.tooltip {
	position: absolute;
	z-index: 1020;
	display: block;
	margin: 0;
	font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
	font-style: normal;
	font-weight: 400;
	line-height: 1.71429;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
	font-size: 0.875rem;
	word-wrap: break-word;
	opacity: 0;
}

.tooltip.show {
	opacity: 1;
}

.tooltip .arrow {
	position: absolute;
	display: block;
	width: 6px;
	height: 6px;
}

.tooltip .arrow::before {
	position: absolute;
	content: "";
	border-color: transparent;
	border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
	padding: 6px 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
	bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
	top: 0;
	border-width: 6px 3px 0;
	border-top-color: #0A043B;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
	padding: 0 6px;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
	left: 0;
	width: 6px;
	height: 6px;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
	right: 0;
	border-width: 3px 6px 3px 0;
	border-right-color: #0A043B;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
	padding: 6px 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
	top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
	bottom: 0;
	border-width: 0 3px 6px;
	border-bottom-color: #0A043B;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
	padding: 0 6px;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
	right: 0;
	width: 6px;
	height: 6px;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
	left: 0;
	border-width: 3px 0 3px 6px;
	border-left-color: #0A043B;
}

.tooltip-inner {
	max-width: 200px;
	padding: 6px 10px;
	color: #ffffff;
	text-align: center;
	background-color: #0A043B;
	border-radius: 0.25rem;
}

.popover {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1060;
	display: block;
	max-width: 276px;
	font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
	font-style: normal;
	font-weight: 400;
	line-height: 1.71429;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
	font-size: 0.875rem;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 0.3rem;
}

.popover .arrow {
	position: absolute;
	display: block;
	width: 1rem;
	height: 0.5rem;
	margin: 0 0.3rem;
}

.popover .arrow::before, .popover .arrow::after {
	position: absolute;
	display: block;
	content: "";
	border-color: transparent;
	border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
	margin-bottom: 0.5rem;
}

.bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
	bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
	border-width: 0.5rem 0.5rem 0;
}

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
	bottom: 0;
	border-top-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
	bottom: 1px;
	border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
	margin-left: 0.5rem;
}

.bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
	left: calc((0.5rem + 1px) * -1);
	width: 0.5rem;
	height: 1rem;
	margin: 0.3rem 0;
}

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
	border-width: 0.5rem 0.5rem 0.5rem 0;
}

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
	left: 0;
	border-right-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
	left: 1px;
	border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
	margin-top: 0.5rem;
}

.bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
	top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
	border-width: 0 0.5rem 0.5rem 0.5rem;
}

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
	top: 0;
	border-bottom-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
	top: 1px;
	border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
	position: absolute;
	top: 0;
	left: 50%;
	display: block;
	width: 1rem;
	margin-left: -0.5rem;
	content: "";
	border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
	margin-right: 0.5rem;
}

.bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
	right: calc((0.5rem + 1px) * -1);
	width: 0.5rem;
	height: 1rem;
	margin: 0.3rem 0;
}

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
	border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
	right: 0;
	border-left-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
	right: 1px;
	border-left-color: #fff;
}

.popover-header {
	padding: 0.5rem 0.75rem;
	margin-bottom: 0;
	font-size: 1rem;
	color: inherit;
	background-color: #f7f7f7;
	border-bottom: 1px solid #ebebeb;
	border-top-left-radius: calc(0.3rem - 1px);
	border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
	display: none;
}

.popover-body {
	padding: 0.5rem 0.75rem;
	color: #212529;
}

.carousel {
	position: relative;
}

.carousel-inner {
	position: relative;
	width: 100%;
	overflow: hidden;
}

.carousel-item {
	position: relative;
	display: none;
	align-items: center;
	width: 100%;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
	-webkit-perspective: 1000px;
	        perspective: 1000px;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
	display: block;
	transition: -webkit-transform 0.6s ease;
	transition: transform 0.6s ease;
	transition: transform 0.6s ease, -webkit-transform 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
	.carousel-item.active,
	.carousel-item-next,
	.carousel-item-prev {
		transition: none;
	}
}

.carousel-item-next,
.carousel-item-prev {
	position: absolute;
	top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
	-webkit-transform: translateX(0);
	        transform: translateX(0);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
	.carousel-item-next.carousel-item-left,
	.carousel-item-prev.carousel-item-right {
		-webkit-transform: translate3d(0, 0, 0);
		        transform: translate3d(0, 0, 0);
	}
}

.carousel-item-next,
.active.carousel-item-right {
	-webkit-transform: translateX(100%);
	        transform: translateX(100%);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
	.carousel-item-next,
	.active.carousel-item-right {
		-webkit-transform: translate3d(100%, 0, 0);
		        transform: translate3d(100%, 0, 0);
	}
}

.carousel-item-prev,
.active.carousel-item-left {
	-webkit-transform: translateX(-100%);
	        transform: translateX(-100%);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
	.carousel-item-prev,
	.active.carousel-item-left {
		-webkit-transform: translate3d(-100%, 0, 0);
		        transform: translate3d(-100%, 0, 0);
	}
}

.carousel-fade .carousel-item {
	opacity: 0;
	transition-duration: .6s;
	transition-property: opacity;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
	opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
	opacity: 0;
}

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
	-webkit-transform: translateX(0);
	        transform: translateX(0);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
	.carousel-fade .carousel-item-next,
	.carousel-fade .carousel-item-prev,
	.carousel-fade .carousel-item.active,
	.carousel-fade .active.carousel-item-left,
	.carousel-fade .active.carousel-item-prev {
		-webkit-transform: translate3d(0, 0, 0);
		        transform: translate3d(0, 0, 0);
	}
}

.carousel-control-prev,
.carousel-control-next {
	position: absolute;
	top: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 15%;
	color: #fff;
	text-align: center;
	opacity: 0.5;
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
	color: #fff;
	text-decoration: none;
	outline: 0;
	opacity: .9;
}

.carousel-control-prev {
	left: 0;
}

.carousel-control-next {
	right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
	display: inline-block;
	width: 20px;
	height: 20px;
	background: transparent no-repeat center center;
	background-size: 100% 100%;
}

.carousel-control-prev-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
	position: absolute;
	right: 0;
	bottom: 10px;
	left: 0;
	z-index: 15;
	display: flex;
	justify-content: center;
	padding-left: 0;
	margin-right: 15%;
	margin-left: 15%;
	list-style: none;
}

.carousel-indicators li {
	position: relative;
	flex: 0 1 auto;
	width: 30px;
	height: 3px;
	margin-right: 3px;
	margin-left: 3px;
	text-indent: -999px;
	cursor: pointer;
	background-color: #fff;
	opacity: .5;
	transition: opacity 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
	.carousel-indicators li {
		transition: none;
	}
}

.carousel-indicators li::before {
	position: absolute;
	top: -10px;
	left: 0;
	display: inline-block;
	width: 100%;
	height: 10px;
	content: "";
}

.carousel-indicators li::after {
	position: absolute;
	bottom: -10px;
	left: 0;
	display: inline-block;
	width: 100%;
	height: 10px;
	content: "";
}

.carousel-indicators .active {
	opacity: 1;
}

.carousel-caption {
	position: absolute;
	right: 15%;
	bottom: 20px;
	left: 15%;
	z-index: 10;
	padding-top: 20px;
	padding-bottom: 20px;
	color: #fff;
	text-align: center;
}

.align-baseline {
	vertical-align: baseline !important;
}

.align-top {
	vertical-align: top !important;
}

.align-middle {
	vertical-align: middle !important;
}

.align-bottom {
	vertical-align: bottom !important;
}

.align-text-bottom {
	vertical-align: text-bottom !important;
}

.align-text-top {
	vertical-align: text-top !important;
}

.bg-white {
	background-color: #ffffff !important;
}

.bg-transparent {
	background-color: transparent !important;
}

.border {
	border: 1px solid #dee2e6 !important;
}

.border-top {
	border-top: 1px solid #dee2e6 !important;
}

.border-right {
	border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
	border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
	border-left: 1px solid #dee2e6 !important;
}

.border-0 {
	border: 0 !important;
}

.border-top-0 {
	border-top: 0 !important;
}

.border-right-0 {
	border-right: 0 !important;
}

.border-bottom-0 {
	border-bottom: 0 !important;
}

.border-left-0 {
	border-left: 0 !important;
}

.border-white {
	border-color: #ffffff !important;
}

.rounded {
	border-radius: 0 !important;
}

.rounded-top {
	border-top-left-radius: 0 !important;
	border-top-right-radius: 0 !important;
}

.rounded-right {
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}

.rounded-bottom {
	border-bottom-right-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}

.rounded-left {
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}

.rounded-circle {
	border-radius: 50% !important;
}

.rounded-0 {
	border-radius: 0 !important;
}

.clearfix::after {
	display: block;
	clear: both;
	content: "";
}

.d-none {
	display: none !important;
}

.d-inline {
	display: inline !important;
}

.d-inline-block {
	display: inline-block !important;
}

.d-block {
	display: block !important;
}

.d-table {
	display: table !important;
}

.d-table-row {
	display: table-row !important;
}

.d-table-cell {
	display: table-cell !important;
}

.d-flex {
	display: flex !important;
}

.d-inline-flex {
	display: inline-flex !important;
}

@media (min-width: 576px) {
	.d-sm-none {
		display: none !important;
	}
	.d-sm-inline {
		display: inline !important;
	}
	.d-sm-inline-block {
		display: inline-block !important;
	}
	.d-sm-block {
		display: block !important;
	}
	.d-sm-table {
		display: table !important;
	}
	.d-sm-table-row {
		display: table-row !important;
	}
	.d-sm-table-cell {
		display: table-cell !important;
	}
	.d-sm-flex {
		display: flex !important;
	}
	.d-sm-inline-flex {
		display: inline-flex !important;
	}
}

@media (min-width: 768px) {
	.d-md-none {
		display: none !important;
	}
	.d-md-inline {
		display: inline !important;
	}
	.d-md-inline-block {
		display: inline-block !important;
	}
	.d-md-block {
		display: block !important;
	}
	.d-md-table {
		display: table !important;
	}
	.d-md-table-row {
		display: table-row !important;
	}
	.d-md-table-cell {
		display: table-cell !important;
	}
	.d-md-flex {
		display: flex !important;
	}
	.d-md-inline-flex {
		display: inline-flex !important;
	}
}

@media (min-width: 992px) {
	.d-lg-none {
		display: none !important;
	}
	.d-lg-inline {
		display: inline !important;
	}
	.d-lg-inline-block {
		display: inline-block !important;
	}
	.d-lg-block {
		display: block !important;
	}
	.d-lg-table {
		display: table !important;
	}
	.d-lg-table-row {
		display: table-row !important;
	}
	.d-lg-table-cell {
		display: table-cell !important;
	}
	.d-lg-flex {
		display: flex !important;
	}
	.d-lg-inline-flex {
		display: inline-flex !important;
	}
}

@media (min-width: 1200px) {
	.d-xl-none {
		display: none !important;
	}
	.d-xl-inline {
		display: inline !important;
	}
	.d-xl-inline-block {
		display: inline-block !important;
	}
	.d-xl-block {
		display: block !important;
	}
	.d-xl-table {
		display: table !important;
	}
	.d-xl-table-row {
		display: table-row !important;
	}
	.d-xl-table-cell {
		display: table-cell !important;
	}
	.d-xl-flex {
		display: flex !important;
	}
	.d-xl-inline-flex {
		display: inline-flex !important;
	}
}

@media (min-width: 1600px) {
	.d-xxl-none {
		display: none !important;
	}
	.d-xxl-inline {
		display: inline !important;
	}
	.d-xxl-inline-block {
		display: inline-block !important;
	}
	.d-xxl-block {
		display: block !important;
	}
	.d-xxl-table {
		display: table !important;
	}
	.d-xxl-table-row {
		display: table-row !important;
	}
	.d-xxl-table-cell {
		display: table-cell !important;
	}
	.d-xxl-flex {
		display: flex !important;
	}
	.d-xxl-inline-flex {
		display: inline-flex !important;
	}
}

@media print {
	.d-print-none {
		display: none !important;
	}
	.d-print-inline {
		display: inline !important;
	}
	.d-print-inline-block {
		display: inline-block !important;
	}
	.d-print-block {
		display: block !important;
	}
	.d-print-table {
		display: table !important;
	}
	.d-print-table-row {
		display: table-row !important;
	}
	.d-print-table-cell {
		display: table-cell !important;
	}
	.d-print-flex {
		display: flex !important;
	}
	.d-print-inline-flex {
		display: inline-flex !important;
	}
}

.embed-responsive {
	position: relative;
	display: block;
	width: 100%;
	padding: 0;
	overflow: hidden;
}

.embed-responsive::before {
	display: block;
	content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
}

.embed-responsive-21by9::before {
	padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
	padding-top: 56.25%;
}

.embed-responsive-4by3::before {
	padding-top: 75%;
}

.embed-responsive-1by1::before {
	padding-top: 100%;
}

.flex-row {
	flex-direction: row !important;
}

.flex-column {
	flex-direction: column !important;
}

.flex-row-reverse {
	flex-direction: row-reverse !important;
}

.flex-column-reverse {
	flex-direction: column-reverse !important;
}

.flex-wrap {
	flex-wrap: wrap !important;
}

.flex-nowrap {
	flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
	flex-wrap: wrap-reverse !important;
}

.flex-fill {
	flex: 1 1 auto !important;
}

.flex-grow-0 {
	flex-grow: 0 !important;
}

.flex-grow-1 {
	flex-grow: 1 !important;
}

.flex-shrink-0 {
	flex-shrink: 0 !important;
}

.flex-shrink-1 {
	flex-shrink: 1 !important;
}

.justify-content-start {
	justify-content: flex-start !important;
}

.justify-content-end {
	justify-content: flex-end !important;
}

.justify-content-center {
	justify-content: center !important;
}

.justify-content-between {
	justify-content: space-between !important;
}

.justify-content-around {
	justify-content: space-around !important;
}

.align-items-start {
	align-items: flex-start !important;
}

.align-items-end {
	align-items: flex-end !important;
}

.align-items-center {
	align-items: center !important;
}

.align-items-baseline {
	align-items: baseline !important;
}

.align-items-stretch {
	align-items: stretch !important;
}

.align-content-start {
	align-content: flex-start !important;
}

.align-content-end {
	align-content: flex-end !important;
}

.align-content-center {
	align-content: center !important;
}

.align-content-between {
	align-content: space-between !important;
}

.align-content-around {
	align-content: space-around !important;
}

.align-content-stretch {
	align-content: stretch !important;
}

.align-self-auto {
	align-self: auto !important;
}

.align-self-start {
	align-self: flex-start !important;
}

.align-self-end {
	align-self: flex-end !important;
}

.align-self-center {
	align-self: center !important;
}

.align-self-baseline {
	align-self: baseline !important;
}

.align-self-stretch {
	align-self: stretch !important;
}

@media (min-width: 576px) {
	.flex-sm-row {
		flex-direction: row !important;
	}
	.flex-sm-column {
		flex-direction: column !important;
	}
	.flex-sm-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-sm-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-sm-wrap {
		flex-wrap: wrap !important;
	}
	.flex-sm-nowrap {
		flex-wrap: nowrap !important;
	}
	.flex-sm-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.flex-sm-fill {
		flex: 1 1 auto !important;
	}
	.flex-sm-grow-0 {
		flex-grow: 0 !important;
	}
	.flex-sm-grow-1 {
		flex-grow: 1 !important;
	}
	.flex-sm-shrink-0 {
		flex-shrink: 0 !important;
	}
	.flex-sm-shrink-1 {
		flex-shrink: 1 !important;
	}
	.justify-content-sm-start {
		justify-content: flex-start !important;
	}
	.justify-content-sm-end {
		justify-content: flex-end !important;
	}
	.justify-content-sm-center {
		justify-content: center !important;
	}
	.justify-content-sm-between {
		justify-content: space-between !important;
	}
	.justify-content-sm-around {
		justify-content: space-around !important;
	}
	.align-items-sm-start {
		align-items: flex-start !important;
	}
	.align-items-sm-end {
		align-items: flex-end !important;
	}
	.align-items-sm-center {
		align-items: center !important;
	}
	.align-items-sm-baseline {
		align-items: baseline !important;
	}
	.align-items-sm-stretch {
		align-items: stretch !important;
	}
	.align-content-sm-start {
		align-content: flex-start !important;
	}
	.align-content-sm-end {
		align-content: flex-end !important;
	}
	.align-content-sm-center {
		align-content: center !important;
	}
	.align-content-sm-between {
		align-content: space-between !important;
	}
	.align-content-sm-around {
		align-content: space-around !important;
	}
	.align-content-sm-stretch {
		align-content: stretch !important;
	}
	.align-self-sm-auto {
		align-self: auto !important;
	}
	.align-self-sm-start {
		align-self: flex-start !important;
	}
	.align-self-sm-end {
		align-self: flex-end !important;
	}
	.align-self-sm-center {
		align-self: center !important;
	}
	.align-self-sm-baseline {
		align-self: baseline !important;
	}
	.align-self-sm-stretch {
		align-self: stretch !important;
	}
}

@media (min-width: 768px) {
	.flex-md-row {
		flex-direction: row !important;
	}
	.flex-md-column {
		flex-direction: column !important;
	}
	.flex-md-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-md-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-md-wrap {
		flex-wrap: wrap !important;
	}
	.flex-md-nowrap {
		flex-wrap: nowrap !important;
	}
	.flex-md-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.flex-md-fill {
		flex: 1 1 auto !important;
	}
	.flex-md-grow-0 {
		flex-grow: 0 !important;
	}
	.flex-md-grow-1 {
		flex-grow: 1 !important;
	}
	.flex-md-shrink-0 {
		flex-shrink: 0 !important;
	}
	.flex-md-shrink-1 {
		flex-shrink: 1 !important;
	}
	.justify-content-md-start {
		justify-content: flex-start !important;
	}
	.justify-content-md-end {
		justify-content: flex-end !important;
	}
	.justify-content-md-center {
		justify-content: center !important;
	}
	.justify-content-md-between {
		justify-content: space-between !important;
	}
	.justify-content-md-around {
		justify-content: space-around !important;
	}
	.align-items-md-start {
		align-items: flex-start !important;
	}
	.align-items-md-end {
		align-items: flex-end !important;
	}
	.align-items-md-center {
		align-items: center !important;
	}
	.align-items-md-baseline {
		align-items: baseline !important;
	}
	.align-items-md-stretch {
		align-items: stretch !important;
	}
	.align-content-md-start {
		align-content: flex-start !important;
	}
	.align-content-md-end {
		align-content: flex-end !important;
	}
	.align-content-md-center {
		align-content: center !important;
	}
	.align-content-md-between {
		align-content: space-between !important;
	}
	.align-content-md-around {
		align-content: space-around !important;
	}
	.align-content-md-stretch {
		align-content: stretch !important;
	}
	.align-self-md-auto {
		align-self: auto !important;
	}
	.align-self-md-start {
		align-self: flex-start !important;
	}
	.align-self-md-end {
		align-self: flex-end !important;
	}
	.align-self-md-center {
		align-self: center !important;
	}
	.align-self-md-baseline {
		align-self: baseline !important;
	}
	.align-self-md-stretch {
		align-self: stretch !important;
	}
}

@media (min-width: 992px) {
	.flex-lg-row {
		flex-direction: row !important;
	}
	.flex-lg-column {
		flex-direction: column !important;
	}
	.flex-lg-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-lg-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-lg-wrap {
		flex-wrap: wrap !important;
	}
	.flex-lg-nowrap {
		flex-wrap: nowrap !important;
	}
	.flex-lg-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.flex-lg-fill {
		flex: 1 1 auto !important;
	}
	.flex-lg-grow-0 {
		flex-grow: 0 !important;
	}
	.flex-lg-grow-1 {
		flex-grow: 1 !important;
	}
	.flex-lg-shrink-0 {
		flex-shrink: 0 !important;
	}
	.flex-lg-shrink-1 {
		flex-shrink: 1 !important;
	}
	.justify-content-lg-start {
		justify-content: flex-start !important;
	}
	.justify-content-lg-end {
		justify-content: flex-end !important;
	}
	.justify-content-lg-center {
		justify-content: center !important;
	}
	.justify-content-lg-between {
		justify-content: space-between !important;
	}
	.justify-content-lg-around {
		justify-content: space-around !important;
	}
	.align-items-lg-start {
		align-items: flex-start !important;
	}
	.align-items-lg-end {
		align-items: flex-end !important;
	}
	.align-items-lg-center {
		align-items: center !important;
	}
	.align-items-lg-baseline {
		align-items: baseline !important;
	}
	.align-items-lg-stretch {
		align-items: stretch !important;
	}
	.align-content-lg-start {
		align-content: flex-start !important;
	}
	.align-content-lg-end {
		align-content: flex-end !important;
	}
	.align-content-lg-center {
		align-content: center !important;
	}
	.align-content-lg-between {
		align-content: space-between !important;
	}
	.align-content-lg-around {
		align-content: space-around !important;
	}
	.align-content-lg-stretch {
		align-content: stretch !important;
	}
	.align-self-lg-auto {
		align-self: auto !important;
	}
	.align-self-lg-start {
		align-self: flex-start !important;
	}
	.align-self-lg-end {
		align-self: flex-end !important;
	}
	.align-self-lg-center {
		align-self: center !important;
	}
	.align-self-lg-baseline {
		align-self: baseline !important;
	}
	.align-self-lg-stretch {
		align-self: stretch !important;
	}
}

@media (min-width: 1200px) {
	.flex-xl-row {
		flex-direction: row !important;
	}
	.flex-xl-column {
		flex-direction: column !important;
	}
	.flex-xl-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-xl-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-xl-wrap {
		flex-wrap: wrap !important;
	}
	.flex-xl-nowrap {
		flex-wrap: nowrap !important;
	}
	.flex-xl-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.flex-xl-fill {
		flex: 1 1 auto !important;
	}
	.flex-xl-grow-0 {
		flex-grow: 0 !important;
	}
	.flex-xl-grow-1 {
		flex-grow: 1 !important;
	}
	.flex-xl-shrink-0 {
		flex-shrink: 0 !important;
	}
	.flex-xl-shrink-1 {
		flex-shrink: 1 !important;
	}
	.justify-content-xl-start {
		justify-content: flex-start !important;
	}
	.justify-content-xl-end {
		justify-content: flex-end !important;
	}
	.justify-content-xl-center {
		justify-content: center !important;
	}
	.justify-content-xl-between {
		justify-content: space-between !important;
	}
	.justify-content-xl-around {
		justify-content: space-around !important;
	}
	.align-items-xl-start {
		align-items: flex-start !important;
	}
	.align-items-xl-end {
		align-items: flex-end !important;
	}
	.align-items-xl-center {
		align-items: center !important;
	}
	.align-items-xl-baseline {
		align-items: baseline !important;
	}
	.align-items-xl-stretch {
		align-items: stretch !important;
	}
	.align-content-xl-start {
		align-content: flex-start !important;
	}
	.align-content-xl-end {
		align-content: flex-end !important;
	}
	.align-content-xl-center {
		align-content: center !important;
	}
	.align-content-xl-between {
		align-content: space-between !important;
	}
	.align-content-xl-around {
		align-content: space-around !important;
	}
	.align-content-xl-stretch {
		align-content: stretch !important;
	}
	.align-self-xl-auto {
		align-self: auto !important;
	}
	.align-self-xl-start {
		align-self: flex-start !important;
	}
	.align-self-xl-end {
		align-self: flex-end !important;
	}
	.align-self-xl-center {
		align-self: center !important;
	}
	.align-self-xl-baseline {
		align-self: baseline !important;
	}
	.align-self-xl-stretch {
		align-self: stretch !important;
	}
}

@media (min-width: 1600px) {
	.flex-xxl-row {
		flex-direction: row !important;
	}
	.flex-xxl-column {
		flex-direction: column !important;
	}
	.flex-xxl-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-xxl-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-xxl-wrap {
		flex-wrap: wrap !important;
	}
	.flex-xxl-nowrap {
		flex-wrap: nowrap !important;
	}
	.flex-xxl-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.flex-xxl-fill {
		flex: 1 1 auto !important;
	}
	.flex-xxl-grow-0 {
		flex-grow: 0 !important;
	}
	.flex-xxl-grow-1 {
		flex-grow: 1 !important;
	}
	.flex-xxl-shrink-0 {
		flex-shrink: 0 !important;
	}
	.flex-xxl-shrink-1 {
		flex-shrink: 1 !important;
	}
	.justify-content-xxl-start {
		justify-content: flex-start !important;
	}
	.justify-content-xxl-end {
		justify-content: flex-end !important;
	}
	.justify-content-xxl-center {
		justify-content: center !important;
	}
	.justify-content-xxl-between {
		justify-content: space-between !important;
	}
	.justify-content-xxl-around {
		justify-content: space-around !important;
	}
	.align-items-xxl-start {
		align-items: flex-start !important;
	}
	.align-items-xxl-end {
		align-items: flex-end !important;
	}
	.align-items-xxl-center {
		align-items: center !important;
	}
	.align-items-xxl-baseline {
		align-items: baseline !important;
	}
	.align-items-xxl-stretch {
		align-items: stretch !important;
	}
	.align-content-xxl-start {
		align-content: flex-start !important;
	}
	.align-content-xxl-end {
		align-content: flex-end !important;
	}
	.align-content-xxl-center {
		align-content: center !important;
	}
	.align-content-xxl-between {
		align-content: space-between !important;
	}
	.align-content-xxl-around {
		align-content: space-around !important;
	}
	.align-content-xxl-stretch {
		align-content: stretch !important;
	}
	.align-self-xxl-auto {
		align-self: auto !important;
	}
	.align-self-xxl-start {
		align-self: flex-start !important;
	}
	.align-self-xxl-end {
		align-self: flex-end !important;
	}
	.align-self-xxl-center {
		align-self: center !important;
	}
	.align-self-xxl-baseline {
		align-self: baseline !important;
	}
	.align-self-xxl-stretch {
		align-self: stretch !important;
	}
}

.float-left {
	float: left !important;
}

.float-right {
	float: right !important;
}

.float-none {
	float: none !important;
}

@media (min-width: 576px) {
	.float-sm-left {
		float: left !important;
	}
	.float-sm-right {
		float: right !important;
	}
	.float-sm-none {
		float: none !important;
	}
}

@media (min-width: 768px) {
	.float-md-left {
		float: left !important;
	}
	.float-md-right {
		float: right !important;
	}
	.float-md-none {
		float: none !important;
	}
}

@media (min-width: 992px) {
	.float-lg-left {
		float: left !important;
	}
	.float-lg-right {
		float: right !important;
	}
	.float-lg-none {
		float: none !important;
	}
}

@media (min-width: 1200px) {
	.float-xl-left {
		float: left !important;
	}
	.float-xl-right {
		float: right !important;
	}
	.float-xl-none {
		float: none !important;
	}
}

@media (min-width: 1600px) {
	.float-xxl-left {
		float: left !important;
	}
	.float-xxl-right {
		float: right !important;
	}
	.float-xxl-none {
		float: none !important;
	}
}

.position-static {
	position: static !important;
}

.position-relative {
	position: relative !important;
}

.position-absolute {
	position: absolute !important;
}

.position-fixed {
	position: fixed !important;
}

.position-sticky {
	position: -webkit-sticky !important;
	position: sticky !important;
}

.fixed-top {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
}

.fixed-bottom {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
	.sticky-top {
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		z-index: 1020;
	}
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
	position: static;
	width: auto;
	height: auto;
	overflow: visible;
	clip: auto;
	white-space: normal;
}

.shadow-sm {
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
	box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
	box-shadow: none !important;
}

.w-25 {
	width: 25% !important;
}

.w-50 {
	width: 50% !important;
}

.w-75 {
	width: 75% !important;
}

.w-100 {
	width: 100% !important;
}

.w-auto {
	width: auto !important;
}

.h-25 {
	height: 25% !important;
}

.h-50 {
	height: 50% !important;
}

.h-75 {
	height: 75% !important;
}

.h-100 {
	height: 100% !important;
}

.h-auto {
	height: auto !important;
}

.mw-100 {
	max-width: 100% !important;
}

.mh-100 {
	max-height: 100% !important;
}

.m-0 {
	margin: 0 !important;
}

.mt-0,
.my-0 {
	margin-top: 0 !important;
}

.mr-0,
.mx-0 {
	margin-right: 0 !important;
}

.mb-0,
.my-0 {
	margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
	margin-left: 0 !important;
}

.m-1 {
	margin: 0.25rem !important;
}

.mt-1,
.my-1 {
	margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
	margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
	margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
	margin-left: 0.25rem !important;
}

.m-2 {
	margin: 0.5rem !important;
}

.mt-2,
.my-2 {
	margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
	margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
	margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
	margin-left: 0.5rem !important;
}

.m-3 {
	margin: 1rem !important;
}

.mt-3,
.my-3 {
	margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
	margin-right: 1rem !important;
}

.mb-3,
.my-3 {
	margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
	margin-left: 1rem !important;
}

.m-4 {
	margin: 1.5rem !important;
}

.mt-4,
.my-4 {
	margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
	margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
	margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
	margin-left: 1.5rem !important;
}

.m-5 {
	margin: 3rem !important;
}

.mt-5,
.my-5 {
	margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
	margin-right: 3rem !important;
}

.mb-5,
.my-5 {
	margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
	margin-left: 3rem !important;
}

.p-0 {
	padding: 0 !important;
}

.pt-0,
.py-0 {
	padding-top: 0 !important;
}

.pr-0,
.px-0 {
	padding-right: 0 !important;
}

.pb-0,
.py-0 {
	padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
	padding-left: 0 !important;
}

.p-1 {
	padding: 0.25rem !important;
}

.pt-1,
.py-1 {
	padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
	padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
	padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
	padding-left: 0.25rem !important;
}

.p-2 {
	padding: 0.5rem !important;
}

.pt-2,
.py-2 {
	padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
	padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
	padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
	padding-left: 0.5rem !important;
}

.p-3 {
	padding: 1rem !important;
}

.pt-3,
.py-3 {
	padding-top: 1rem !important;
}

.pr-3,
.px-3 {
	padding-right: 1rem !important;
}

.pb-3,
.py-3 {
	padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
	padding-left: 1rem !important;
}

.p-4 {
	padding: 1.5rem !important;
}

.pt-4,
.py-4 {
	padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
	padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
	padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
	padding-left: 1.5rem !important;
}

.p-5 {
	padding: 3rem !important;
}

.pt-5,
.py-5 {
	padding-top: 3rem !important;
}

.pr-5,
.px-5 {
	padding-right: 3rem !important;
}

.pb-5,
.py-5 {
	padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
	padding-left: 3rem !important;
}

.m-auto {
	margin: auto !important;
}

.mt-auto,
.my-auto {
	margin-top: auto !important;
}

.mr-auto,
.mx-auto {
	margin-right: auto !important;
}

.mb-auto,
.my-auto {
	margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
	margin-left: auto !important;
}

@media (min-width: 576px) {
	.m-sm-0 {
		margin: 0 !important;
	}
	.mt-sm-0,
	.my-sm-0 {
		margin-top: 0 !important;
	}
	.mr-sm-0,
	.mx-sm-0 {
		margin-right: 0 !important;
	}
	.mb-sm-0,
	.my-sm-0 {
		margin-bottom: 0 !important;
	}
	.ml-sm-0,
	.mx-sm-0 {
		margin-left: 0 !important;
	}
	.m-sm-1 {
		margin: 0.25rem !important;
	}
	.mt-sm-1,
	.my-sm-1 {
		margin-top: 0.25rem !important;
	}
	.mr-sm-1,
	.mx-sm-1 {
		margin-right: 0.25rem !important;
	}
	.mb-sm-1,
	.my-sm-1 {
		margin-bottom: 0.25rem !important;
	}
	.ml-sm-1,
	.mx-sm-1 {
		margin-left: 0.25rem !important;
	}
	.m-sm-2 {
		margin: 0.5rem !important;
	}
	.mt-sm-2,
	.my-sm-2 {
		margin-top: 0.5rem !important;
	}
	.mr-sm-2,
	.mx-sm-2 {
		margin-right: 0.5rem !important;
	}
	.mb-sm-2,
	.my-sm-2 {
		margin-bottom: 0.5rem !important;
	}
	.ml-sm-2,
	.mx-sm-2 {
		margin-left: 0.5rem !important;
	}
	.m-sm-3 {
		margin: 1rem !important;
	}
	.mt-sm-3,
	.my-sm-3 {
		margin-top: 1rem !important;
	}
	.mr-sm-3,
	.mx-sm-3 {
		margin-right: 1rem !important;
	}
	.mb-sm-3,
	.my-sm-3 {
		margin-bottom: 1rem !important;
	}
	.ml-sm-3,
	.mx-sm-3 {
		margin-left: 1rem !important;
	}
	.m-sm-4 {
		margin: 1.5rem !important;
	}
	.mt-sm-4,
	.my-sm-4 {
		margin-top: 1.5rem !important;
	}
	.mr-sm-4,
	.mx-sm-4 {
		margin-right: 1.5rem !important;
	}
	.mb-sm-4,
	.my-sm-4 {
		margin-bottom: 1.5rem !important;
	}
	.ml-sm-4,
	.mx-sm-4 {
		margin-left: 1.5rem !important;
	}
	.m-sm-5 {
		margin: 3rem !important;
	}
	.mt-sm-5,
	.my-sm-5 {
		margin-top: 3rem !important;
	}
	.mr-sm-5,
	.mx-sm-5 {
		margin-right: 3rem !important;
	}
	.mb-sm-5,
	.my-sm-5 {
		margin-bottom: 3rem !important;
	}
	.ml-sm-5,
	.mx-sm-5 {
		margin-left: 3rem !important;
	}
	.p-sm-0 {
		padding: 0 !important;
	}
	.pt-sm-0,
	.py-sm-0 {
		padding-top: 0 !important;
	}
	.pr-sm-0,
	.px-sm-0 {
		padding-right: 0 !important;
	}
	.pb-sm-0,
	.py-sm-0 {
		padding-bottom: 0 !important;
	}
	.pl-sm-0,
	.px-sm-0 {
		padding-left: 0 !important;
	}
	.p-sm-1 {
		padding: 0.25rem !important;
	}
	.pt-sm-1,
	.py-sm-1 {
		padding-top: 0.25rem !important;
	}
	.pr-sm-1,
	.px-sm-1 {
		padding-right: 0.25rem !important;
	}
	.pb-sm-1,
	.py-sm-1 {
		padding-bottom: 0.25rem !important;
	}
	.pl-sm-1,
	.px-sm-1 {
		padding-left: 0.25rem !important;
	}
	.p-sm-2 {
		padding: 0.5rem !important;
	}
	.pt-sm-2,
	.py-sm-2 {
		padding-top: 0.5rem !important;
	}
	.pr-sm-2,
	.px-sm-2 {
		padding-right: 0.5rem !important;
	}
	.pb-sm-2,
	.py-sm-2 {
		padding-bottom: 0.5rem !important;
	}
	.pl-sm-2,
	.px-sm-2 {
		padding-left: 0.5rem !important;
	}
	.p-sm-3 {
		padding: 1rem !important;
	}
	.pt-sm-3,
	.py-sm-3 {
		padding-top: 1rem !important;
	}
	.pr-sm-3,
	.px-sm-3 {
		padding-right: 1rem !important;
	}
	.pb-sm-3,
	.py-sm-3 {
		padding-bottom: 1rem !important;
	}
	.pl-sm-3,
	.px-sm-3 {
		padding-left: 1rem !important;
	}
	.p-sm-4 {
		padding: 1.5rem !important;
	}
	.pt-sm-4,
	.py-sm-4 {
		padding-top: 1.5rem !important;
	}
	.pr-sm-4,
	.px-sm-4 {
		padding-right: 1.5rem !important;
	}
	.pb-sm-4,
	.py-sm-4 {
		padding-bottom: 1.5rem !important;
	}
	.pl-sm-4,
	.px-sm-4 {
		padding-left: 1.5rem !important;
	}
	.p-sm-5 {
		padding: 3rem !important;
	}
	.pt-sm-5,
	.py-sm-5 {
		padding-top: 3rem !important;
	}
	.pr-sm-5,
	.px-sm-5 {
		padding-right: 3rem !important;
	}
	.pb-sm-5,
	.py-sm-5 {
		padding-bottom: 3rem !important;
	}
	.pl-sm-5,
	.px-sm-5 {
		padding-left: 3rem !important;
	}
	.m-sm-auto {
		margin: auto !important;
	}
	.mt-sm-auto,
	.my-sm-auto {
		margin-top: auto !important;
	}
	.mr-sm-auto,
	.mx-sm-auto {
		margin-right: auto !important;
	}
	.mb-sm-auto,
	.my-sm-auto {
		margin-bottom: auto !important;
	}
	.ml-sm-auto,
	.mx-sm-auto {
		margin-left: auto !important;
	}
}

@media (min-width: 768px) {
	.m-md-0 {
		margin: 0 !important;
	}
	.mt-md-0,
	.my-md-0 {
		margin-top: 0 !important;
	}
	.mr-md-0,
	.mx-md-0 {
		margin-right: 0 !important;
	}
	.mb-md-0,
	.my-md-0 {
		margin-bottom: 0 !important;
	}
	.ml-md-0,
	.mx-md-0 {
		margin-left: 0 !important;
	}
	.m-md-1 {
		margin: 0.25rem !important;
	}
	.mt-md-1,
	.my-md-1 {
		margin-top: 0.25rem !important;
	}
	.mr-md-1,
	.mx-md-1 {
		margin-right: 0.25rem !important;
	}
	.mb-md-1,
	.my-md-1 {
		margin-bottom: 0.25rem !important;
	}
	.ml-md-1,
	.mx-md-1 {
		margin-left: 0.25rem !important;
	}
	.m-md-2 {
		margin: 0.5rem !important;
	}
	.mt-md-2,
	.my-md-2 {
		margin-top: 0.5rem !important;
	}
	.mr-md-2,
	.mx-md-2 {
		margin-right: 0.5rem !important;
	}
	.mb-md-2,
	.my-md-2 {
		margin-bottom: 0.5rem !important;
	}
	.ml-md-2,
	.mx-md-2 {
		margin-left: 0.5rem !important;
	}
	.m-md-3 {
		margin: 1rem !important;
	}
	.mt-md-3,
	.my-md-3 {
		margin-top: 1rem !important;
	}
	.mr-md-3,
	.mx-md-3 {
		margin-right: 1rem !important;
	}
	.mb-md-3,
	.my-md-3 {
		margin-bottom: 1rem !important;
	}
	.ml-md-3,
	.mx-md-3 {
		margin-left: 1rem !important;
	}
	.m-md-4 {
		margin: 1.5rem !important;
	}
	.mt-md-4,
	.my-md-4 {
		margin-top: 1.5rem !important;
	}
	.mr-md-4,
	.mx-md-4 {
		margin-right: 1.5rem !important;
	}
	.mb-md-4,
	.my-md-4 {
		margin-bottom: 1.5rem !important;
	}
	.ml-md-4,
	.mx-md-4 {
		margin-left: 1.5rem !important;
	}
	.m-md-5 {
		margin: 3rem !important;
	}
	.mt-md-5,
	.my-md-5 {
		margin-top: 3rem !important;
	}
	.mr-md-5,
	.mx-md-5 {
		margin-right: 3rem !important;
	}
	.mb-md-5,
	.my-md-5 {
		margin-bottom: 3rem !important;
	}
	.ml-md-5,
	.mx-md-5 {
		margin-left: 3rem !important;
	}
	.p-md-0 {
		padding: 0 !important;
	}
	.pt-md-0,
	.py-md-0 {
		padding-top: 0 !important;
	}
	.pr-md-0,
	.px-md-0 {
		padding-right: 0 !important;
	}
	.pb-md-0,
	.py-md-0 {
		padding-bottom: 0 !important;
	}
	.pl-md-0,
	.px-md-0 {
		padding-left: 0 !important;
	}
	.p-md-1 {
		padding: 0.25rem !important;
	}
	.pt-md-1,
	.py-md-1 {
		padding-top: 0.25rem !important;
	}
	.pr-md-1,
	.px-md-1 {
		padding-right: 0.25rem !important;
	}
	.pb-md-1,
	.py-md-1 {
		padding-bottom: 0.25rem !important;
	}
	.pl-md-1,
	.px-md-1 {
		padding-left: 0.25rem !important;
	}
	.p-md-2 {
		padding: 0.5rem !important;
	}
	.pt-md-2,
	.py-md-2 {
		padding-top: 0.5rem !important;
	}
	.pr-md-2,
	.px-md-2 {
		padding-right: 0.5rem !important;
	}
	.pb-md-2,
	.py-md-2 {
		padding-bottom: 0.5rem !important;
	}
	.pl-md-2,
	.px-md-2 {
		padding-left: 0.5rem !important;
	}
	.p-md-3 {
		padding: 1rem !important;
	}
	.pt-md-3,
	.py-md-3 {
		padding-top: 1rem !important;
	}
	.pr-md-3,
	.px-md-3 {
		padding-right: 1rem !important;
	}
	.pb-md-3,
	.py-md-3 {
		padding-bottom: 1rem !important;
	}
	.pl-md-3,
	.px-md-3 {
		padding-left: 1rem !important;
	}
	.p-md-4 {
		padding: 1.5rem !important;
	}
	.pt-md-4,
	.py-md-4 {
		padding-top: 1.5rem !important;
	}
	.pr-md-4,
	.px-md-4 {
		padding-right: 1.5rem !important;
	}
	.pb-md-4,
	.py-md-4 {
		padding-bottom: 1.5rem !important;
	}
	.pl-md-4,
	.px-md-4 {
		padding-left: 1.5rem !important;
	}
	.p-md-5 {
		padding: 3rem !important;
	}
	.pt-md-5,
	.py-md-5 {
		padding-top: 3rem !important;
	}
	.pr-md-5,
	.px-md-5 {
		padding-right: 3rem !important;
	}
	.pb-md-5,
	.py-md-5 {
		padding-bottom: 3rem !important;
	}
	.pl-md-5,
	.px-md-5 {
		padding-left: 3rem !important;
	}
	.m-md-auto {
		margin: auto !important;
	}
	.mt-md-auto,
	.my-md-auto {
		margin-top: auto !important;
	}
	.mr-md-auto,
	.mx-md-auto {
		margin-right: auto !important;
	}
	.mb-md-auto,
	.my-md-auto {
		margin-bottom: auto !important;
	}
	.ml-md-auto,
	.mx-md-auto {
		margin-left: auto !important;
	}
}

@media (min-width: 992px) {
	.m-lg-0 {
		margin: 0 !important;
	}
	.mt-lg-0,
	.my-lg-0 {
		margin-top: 0 !important;
	}
	.mr-lg-0,
	.mx-lg-0 {
		margin-right: 0 !important;
	}
	.mb-lg-0,
	.my-lg-0 {
		margin-bottom: 0 !important;
	}
	.ml-lg-0,
	.mx-lg-0 {
		margin-left: 0 !important;
	}
	.m-lg-1 {
		margin: 0.25rem !important;
	}
	.mt-lg-1,
	.my-lg-1 {
		margin-top: 0.25rem !important;
	}
	.mr-lg-1,
	.mx-lg-1 {
		margin-right: 0.25rem !important;
	}
	.mb-lg-1,
	.my-lg-1 {
		margin-bottom: 0.25rem !important;
	}
	.ml-lg-1,
	.mx-lg-1 {
		margin-left: 0.25rem !important;
	}
	.m-lg-2 {
		margin: 0.5rem !important;
	}
	.mt-lg-2,
	.my-lg-2 {
		margin-top: 0.5rem !important;
	}
	.mr-lg-2,
	.mx-lg-2 {
		margin-right: 0.5rem !important;
	}
	.mb-lg-2,
	.my-lg-2 {
		margin-bottom: 0.5rem !important;
	}
	.ml-lg-2,
	.mx-lg-2 {
		margin-left: 0.5rem !important;
	}
	.m-lg-3 {
		margin: 1rem !important;
	}
	.mt-lg-3,
	.my-lg-3 {
		margin-top: 1rem !important;
	}
	.mr-lg-3,
	.mx-lg-3 {
		margin-right: 1rem !important;
	}
	.mb-lg-3,
	.my-lg-3 {
		margin-bottom: 1rem !important;
	}
	.ml-lg-3,
	.mx-lg-3 {
		margin-left: 1rem !important;
	}
	.m-lg-4 {
		margin: 1.5rem !important;
	}
	.mt-lg-4,
	.my-lg-4 {
		margin-top: 1.5rem !important;
	}
	.mr-lg-4,
	.mx-lg-4 {
		margin-right: 1.5rem !important;
	}
	.mb-lg-4,
	.my-lg-4 {
		margin-bottom: 1.5rem !important;
	}
	.ml-lg-4,
	.mx-lg-4 {
		margin-left: 1.5rem !important;
	}
	.m-lg-5 {
		margin: 3rem !important;
	}
	.mt-lg-5,
	.my-lg-5 {
		margin-top: 3rem !important;
	}
	.mr-lg-5,
	.mx-lg-5 {
		margin-right: 3rem !important;
	}
	.mb-lg-5,
	.my-lg-5 {
		margin-bottom: 3rem !important;
	}
	.ml-lg-5,
	.mx-lg-5 {
		margin-left: 3rem !important;
	}
	.p-lg-0 {
		padding: 0 !important;
	}
	.pt-lg-0,
	.py-lg-0 {
		padding-top: 0 !important;
	}
	.pr-lg-0,
	.px-lg-0 {
		padding-right: 0 !important;
	}
	.pb-lg-0,
	.py-lg-0 {
		padding-bottom: 0 !important;
	}
	.pl-lg-0,
	.px-lg-0 {
		padding-left: 0 !important;
	}
	.p-lg-1 {
		padding: 0.25rem !important;
	}
	.pt-lg-1,
	.py-lg-1 {
		padding-top: 0.25rem !important;
	}
	.pr-lg-1,
	.px-lg-1 {
		padding-right: 0.25rem !important;
	}
	.pb-lg-1,
	.py-lg-1 {
		padding-bottom: 0.25rem !important;
	}
	.pl-lg-1,
	.px-lg-1 {
		padding-left: 0.25rem !important;
	}
	.p-lg-2 {
		padding: 0.5rem !important;
	}
	.pt-lg-2,
	.py-lg-2 {
		padding-top: 0.5rem !important;
	}
	.pr-lg-2,
	.px-lg-2 {
		padding-right: 0.5rem !important;
	}
	.pb-lg-2,
	.py-lg-2 {
		padding-bottom: 0.5rem !important;
	}
	.pl-lg-2,
	.px-lg-2 {
		padding-left: 0.5rem !important;
	}
	.p-lg-3 {
		padding: 1rem !important;
	}
	.pt-lg-3,
	.py-lg-3 {
		padding-top: 1rem !important;
	}
	.pr-lg-3,
	.px-lg-3 {
		padding-right: 1rem !important;
	}
	.pb-lg-3,
	.py-lg-3 {
		padding-bottom: 1rem !important;
	}
	.pl-lg-3,
	.px-lg-3 {
		padding-left: 1rem !important;
	}
	.p-lg-4 {
		padding: 1.5rem !important;
	}
	.pt-lg-4,
	.py-lg-4 {
		padding-top: 1.5rem !important;
	}
	.pr-lg-4,
	.px-lg-4 {
		padding-right: 1.5rem !important;
	}
	.pb-lg-4,
	.py-lg-4 {
		padding-bottom: 1.5rem !important;
	}
	.pl-lg-4,
	.px-lg-4 {
		padding-left: 1.5rem !important;
	}
	.p-lg-5 {
		padding: 3rem !important;
	}
	.pt-lg-5,
	.py-lg-5 {
		padding-top: 3rem !important;
	}
	.pr-lg-5,
	.px-lg-5 {
		padding-right: 3rem !important;
	}
	.pb-lg-5,
	.py-lg-5 {
		padding-bottom: 3rem !important;
	}
	.pl-lg-5,
	.px-lg-5 {
		padding-left: 3rem !important;
	}
	.m-lg-auto {
		margin: auto !important;
	}
	.mt-lg-auto,
	.my-lg-auto {
		margin-top: auto !important;
	}
	.mr-lg-auto,
	.mx-lg-auto {
		margin-right: auto !important;
	}
	.mb-lg-auto,
	.my-lg-auto {
		margin-bottom: auto !important;
	}
	.ml-lg-auto,
	.mx-lg-auto {
		margin-left: auto !important;
	}
}

@media (min-width: 1200px) {
	.m-xl-0 {
		margin: 0 !important;
	}
	.mt-xl-0,
	.my-xl-0 {
		margin-top: 0 !important;
	}
	.mr-xl-0,
	.mx-xl-0 {
		margin-right: 0 !important;
	}
	.mb-xl-0,
	.my-xl-0 {
		margin-bottom: 0 !important;
	}
	.ml-xl-0,
	.mx-xl-0 {
		margin-left: 0 !important;
	}
	.m-xl-1 {
		margin: 0.25rem !important;
	}
	.mt-xl-1,
	.my-xl-1 {
		margin-top: 0.25rem !important;
	}
	.mr-xl-1,
	.mx-xl-1 {
		margin-right: 0.25rem !important;
	}
	.mb-xl-1,
	.my-xl-1 {
		margin-bottom: 0.25rem !important;
	}
	.ml-xl-1,
	.mx-xl-1 {
		margin-left: 0.25rem !important;
	}
	.m-xl-2 {
		margin: 0.5rem !important;
	}
	.mt-xl-2,
	.my-xl-2 {
		margin-top: 0.5rem !important;
	}
	.mr-xl-2,
	.mx-xl-2 {
		margin-right: 0.5rem !important;
	}
	.mb-xl-2,
	.my-xl-2 {
		margin-bottom: 0.5rem !important;
	}
	.ml-xl-2,
	.mx-xl-2 {
		margin-left: 0.5rem !important;
	}
	.m-xl-3 {
		margin: 1rem !important;
	}
	.mt-xl-3,
	.my-xl-3 {
		margin-top: 1rem !important;
	}
	.mr-xl-3,
	.mx-xl-3 {
		margin-right: 1rem !important;
	}
	.mb-xl-3,
	.my-xl-3 {
		margin-bottom: 1rem !important;
	}
	.ml-xl-3,
	.mx-xl-3 {
		margin-left: 1rem !important;
	}
	.m-xl-4 {
		margin: 1.5rem !important;
	}
	.mt-xl-4,
	.my-xl-4 {
		margin-top: 1.5rem !important;
	}
	.mr-xl-4,
	.mx-xl-4 {
		margin-right: 1.5rem !important;
	}
	.mb-xl-4,
	.my-xl-4 {
		margin-bottom: 1.5rem !important;
	}
	.ml-xl-4,
	.mx-xl-4 {
		margin-left: 1.5rem !important;
	}
	.m-xl-5 {
		margin: 3rem !important;
	}
	.mt-xl-5,
	.my-xl-5 {
		margin-top: 3rem !important;
	}
	.mr-xl-5,
	.mx-xl-5 {
		margin-right: 3rem !important;
	}
	.mb-xl-5,
	.my-xl-5 {
		margin-bottom: 3rem !important;
	}
	.ml-xl-5,
	.mx-xl-5 {
		margin-left: 3rem !important;
	}
	.p-xl-0 {
		padding: 0 !important;
	}
	.pt-xl-0,
	.py-xl-0 {
		padding-top: 0 !important;
	}
	.pr-xl-0,
	.px-xl-0 {
		padding-right: 0 !important;
	}
	.pb-xl-0,
	.py-xl-0 {
		padding-bottom: 0 !important;
	}
	.pl-xl-0,
	.px-xl-0 {
		padding-left: 0 !important;
	}
	.p-xl-1 {
		padding: 0.25rem !important;
	}
	.pt-xl-1,
	.py-xl-1 {
		padding-top: 0.25rem !important;
	}
	.pr-xl-1,
	.px-xl-1 {
		padding-right: 0.25rem !important;
	}
	.pb-xl-1,
	.py-xl-1 {
		padding-bottom: 0.25rem !important;
	}
	.pl-xl-1,
	.px-xl-1 {
		padding-left: 0.25rem !important;
	}
	.p-xl-2 {
		padding: 0.5rem !important;
	}
	.pt-xl-2,
	.py-xl-2 {
		padding-top: 0.5rem !important;
	}
	.pr-xl-2,
	.px-xl-2 {
		padding-right: 0.5rem !important;
	}
	.pb-xl-2,
	.py-xl-2 {
		padding-bottom: 0.5rem !important;
	}
	.pl-xl-2,
	.px-xl-2 {
		padding-left: 0.5rem !important;
	}
	.p-xl-3 {
		padding: 1rem !important;
	}
	.pt-xl-3,
	.py-xl-3 {
		padding-top: 1rem !important;
	}
	.pr-xl-3,
	.px-xl-3 {
		padding-right: 1rem !important;
	}
	.pb-xl-3,
	.py-xl-3 {
		padding-bottom: 1rem !important;
	}
	.pl-xl-3,
	.px-xl-3 {
		padding-left: 1rem !important;
	}
	.p-xl-4 {
		padding: 1.5rem !important;
	}
	.pt-xl-4,
	.py-xl-4 {
		padding-top: 1.5rem !important;
	}
	.pr-xl-4,
	.px-xl-4 {
		padding-right: 1.5rem !important;
	}
	.pb-xl-4,
	.py-xl-4 {
		padding-bottom: 1.5rem !important;
	}
	.pl-xl-4,
	.px-xl-4 {
		padding-left: 1.5rem !important;
	}
	.p-xl-5 {
		padding: 3rem !important;
	}
	.pt-xl-5,
	.py-xl-5 {
		padding-top: 3rem !important;
	}
	.pr-xl-5,
	.px-xl-5 {
		padding-right: 3rem !important;
	}
	.pb-xl-5,
	.py-xl-5 {
		padding-bottom: 3rem !important;
	}
	.pl-xl-5,
	.px-xl-5 {
		padding-left: 3rem !important;
	}
	.m-xl-auto {
		margin: auto !important;
	}
	.mt-xl-auto,
	.my-xl-auto {
		margin-top: auto !important;
	}
	.mr-xl-auto,
	.mx-xl-auto {
		margin-right: auto !important;
	}
	.mb-xl-auto,
	.my-xl-auto {
		margin-bottom: auto !important;
	}
	.ml-xl-auto,
	.mx-xl-auto {
		margin-left: auto !important;
	}
}

@media (min-width: 1600px) {
	.m-xxl-0 {
		margin: 0 !important;
	}
	.mt-xxl-0,
	.my-xxl-0 {
		margin-top: 0 !important;
	}
	.mr-xxl-0,
	.mx-xxl-0 {
		margin-right: 0 !important;
	}
	.mb-xxl-0,
	.my-xxl-0 {
		margin-bottom: 0 !important;
	}
	.ml-xxl-0,
	.mx-xxl-0 {
		margin-left: 0 !important;
	}
	.m-xxl-1 {
		margin: 0.25rem !important;
	}
	.mt-xxl-1,
	.my-xxl-1 {
		margin-top: 0.25rem !important;
	}
	.mr-xxl-1,
	.mx-xxl-1 {
		margin-right: 0.25rem !important;
	}
	.mb-xxl-1,
	.my-xxl-1 {
		margin-bottom: 0.25rem !important;
	}
	.ml-xxl-1,
	.mx-xxl-1 {
		margin-left: 0.25rem !important;
	}
	.m-xxl-2 {
		margin: 0.5rem !important;
	}
	.mt-xxl-2,
	.my-xxl-2 {
		margin-top: 0.5rem !important;
	}
	.mr-xxl-2,
	.mx-xxl-2 {
		margin-right: 0.5rem !important;
	}
	.mb-xxl-2,
	.my-xxl-2 {
		margin-bottom: 0.5rem !important;
	}
	.ml-xxl-2,
	.mx-xxl-2 {
		margin-left: 0.5rem !important;
	}
	.m-xxl-3 {
		margin: 1rem !important;
	}
	.mt-xxl-3,
	.my-xxl-3 {
		margin-top: 1rem !important;
	}
	.mr-xxl-3,
	.mx-xxl-3 {
		margin-right: 1rem !important;
	}
	.mb-xxl-3,
	.my-xxl-3 {
		margin-bottom: 1rem !important;
	}
	.ml-xxl-3,
	.mx-xxl-3 {
		margin-left: 1rem !important;
	}
	.m-xxl-4 {
		margin: 1.5rem !important;
	}
	.mt-xxl-4,
	.my-xxl-4 {
		margin-top: 1.5rem !important;
	}
	.mr-xxl-4,
	.mx-xxl-4 {
		margin-right: 1.5rem !important;
	}
	.mb-xxl-4,
	.my-xxl-4 {
		margin-bottom: 1.5rem !important;
	}
	.ml-xxl-4,
	.mx-xxl-4 {
		margin-left: 1.5rem !important;
	}
	.m-xxl-5 {
		margin: 3rem !important;
	}
	.mt-xxl-5,
	.my-xxl-5 {
		margin-top: 3rem !important;
	}
	.mr-xxl-5,
	.mx-xxl-5 {
		margin-right: 3rem !important;
	}
	.mb-xxl-5,
	.my-xxl-5 {
		margin-bottom: 3rem !important;
	}
	.ml-xxl-5,
	.mx-xxl-5 {
		margin-left: 3rem !important;
	}
	.p-xxl-0 {
		padding: 0 !important;
	}
	.pt-xxl-0,
	.py-xxl-0 {
		padding-top: 0 !important;
	}
	.pr-xxl-0,
	.px-xxl-0 {
		padding-right: 0 !important;
	}
	.pb-xxl-0,
	.py-xxl-0 {
		padding-bottom: 0 !important;
	}
	.pl-xxl-0,
	.px-xxl-0 {
		padding-left: 0 !important;
	}
	.p-xxl-1 {
		padding: 0.25rem !important;
	}
	.pt-xxl-1,
	.py-xxl-1 {
		padding-top: 0.25rem !important;
	}
	.pr-xxl-1,
	.px-xxl-1 {
		padding-right: 0.25rem !important;
	}
	.pb-xxl-1,
	.py-xxl-1 {
		padding-bottom: 0.25rem !important;
	}
	.pl-xxl-1,
	.px-xxl-1 {
		padding-left: 0.25rem !important;
	}
	.p-xxl-2 {
		padding: 0.5rem !important;
	}
	.pt-xxl-2,
	.py-xxl-2 {
		padding-top: 0.5rem !important;
	}
	.pr-xxl-2,
	.px-xxl-2 {
		padding-right: 0.5rem !important;
	}
	.pb-xxl-2,
	.py-xxl-2 {
		padding-bottom: 0.5rem !important;
	}
	.pl-xxl-2,
	.px-xxl-2 {
		padding-left: 0.5rem !important;
	}
	.p-xxl-3 {
		padding: 1rem !important;
	}
	.pt-xxl-3,
	.py-xxl-3 {
		padding-top: 1rem !important;
	}
	.pr-xxl-3,
	.px-xxl-3 {
		padding-right: 1rem !important;
	}
	.pb-xxl-3,
	.py-xxl-3 {
		padding-bottom: 1rem !important;
	}
	.pl-xxl-3,
	.px-xxl-3 {
		padding-left: 1rem !important;
	}
	.p-xxl-4 {
		padding: 1.5rem !important;
	}
	.pt-xxl-4,
	.py-xxl-4 {
		padding-top: 1.5rem !important;
	}
	.pr-xxl-4,
	.px-xxl-4 {
		padding-right: 1.5rem !important;
	}
	.pb-xxl-4,
	.py-xxl-4 {
		padding-bottom: 1.5rem !important;
	}
	.pl-xxl-4,
	.px-xxl-4 {
		padding-left: 1.5rem !important;
	}
	.p-xxl-5 {
		padding: 3rem !important;
	}
	.pt-xxl-5,
	.py-xxl-5 {
		padding-top: 3rem !important;
	}
	.pr-xxl-5,
	.px-xxl-5 {
		padding-right: 3rem !important;
	}
	.pb-xxl-5,
	.py-xxl-5 {
		padding-bottom: 3rem !important;
	}
	.pl-xxl-5,
	.px-xxl-5 {
		padding-left: 3rem !important;
	}
	.m-xxl-auto {
		margin: auto !important;
	}
	.mt-xxl-auto,
	.my-xxl-auto {
		margin-top: auto !important;
	}
	.mr-xxl-auto,
	.mx-xxl-auto {
		margin-right: auto !important;
	}
	.mb-xxl-auto,
	.my-xxl-auto {
		margin-bottom: auto !important;
	}
	.ml-xxl-auto,
	.mx-xxl-auto {
		margin-left: auto !important;
	}
}

.text-monospace {
	font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
	text-align: justify !important;
}

.text-nowrap {
	white-space: nowrap !important;
}

.text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.text-left {
	text-align: left !important;
}

.text-right {
	text-align: right !important;
}

.text-center {
	text-align: center !important;
}

@media (min-width: 576px) {
	.text-sm-left {
		text-align: left !important;
	}
	.text-sm-right {
		text-align: right !important;
	}
	.text-sm-center {
		text-align: center !important;
	}
}

@media (min-width: 768px) {
	.text-md-left {
		text-align: left !important;
	}
	.text-md-right {
		text-align: right !important;
	}
	.text-md-center {
		text-align: center !important;
	}
}

@media (min-width: 992px) {
	.text-lg-left {
		text-align: left !important;
	}
	.text-lg-right {
		text-align: right !important;
	}
	.text-lg-center {
		text-align: center !important;
	}
}

@media (min-width: 1200px) {
	.text-xl-left {
		text-align: left !important;
	}
	.text-xl-right {
		text-align: right !important;
	}
	.text-xl-center {
		text-align: center !important;
	}
}

@media (min-width: 1600px) {
	.text-xxl-left {
		text-align: left !important;
	}
	.text-xxl-right {
		text-align: right !important;
	}
	.text-xxl-center {
		text-align: center !important;
	}
}

.text-lowercase {
	text-transform: lowercase !important;
}

.text-uppercase {
	text-transform: uppercase !important;
}

.text-capitalize {
	text-transform: capitalize !important;
}

.font-weight-light {
	font-weight: 300 !important;
}

.font-weight-lighter {
	font-weight: lighter !important;
}

.font-weight-normal {
	font-weight: 400 !important;
}

.font-weight-bold {
	font-weight: 700 !important;
}

.font-weight-bolder {
	font-weight: bolder !important;
}

.font-italic {
	font-style: italic !important;
}

.text-white {
	color: #ffffff !important;
}

.text-body {
	color: #9a9a9a !important;
}

.text-muted {
	color: #6c757d !important;
}

.text-black-50 {
	color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
	color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
}

.text-decoration-none {
	text-decoration: none !important;
}

.visible {
	visibility: visible !important;
}

.invisible {
	visibility: hidden !important;
}

@media print {
	*,
	*::before,
	*::after {
		text-shadow: none !important;
		box-shadow: none !important;
	}
	a:not(.btn) {
		text-decoration: underline;
	}
	abbr[title]::after {
		content: " (" attr(title) ")";
	}
	pre {
		white-space: pre-wrap !important;
	}
	pre,
	blockquote {
		border: 1px solid #9a9a9a;
		page-break-inside: avoid;
	}
	thead {
		display: table-header-group;
	}
	tr,
	img {
		page-break-inside: avoid;
	}
	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3;
	}
	h2,
	h3 {
		page-break-after: avoid;
	}
	@page {
		size: a3;
	}
	body {
		min-width: 992px !important;
	}
	.container {
		min-width: 992px !important;
	}
	.navbar {
		display: none;
	}
	.badge {
		border: 1px solid #000000;
	}
	.table {
		border-collapse: collapse !important;
	}
	.table td,
	.table th {
		background-color: #ffffff !important;
	}
	.table-bordered th,
	.table-bordered td {
		border: 1px solid #d6d6d6 !important;
	}
	.table-dark {
		color: inherit;
	}
	.table-dark th,
	.table-dark td,
	.table-dark thead th,
	.table-dark tbody + tbody {
		border-color: #dee2e6;
	}
	.table .thead-dark th {
		color: inherit;
		border-color: #dee2e6;
	}
}

@charset "UTF-8";


/*
* Trunk version 2.0.1
*/
* {
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
}

a:focus,
button:focus {
	outline: none !important;
}

button::-moz-focus-inner {
	border: 0;
}

*:focus {
	outline: none;
}

blockquote {
	padding: 0;
	margin: 0;
}

input,
button,
select,
textarea {
	outline: none;
	border-color: rgb(150, 145, 171);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
}

label {
	margin-bottom: 0;
}

p {
	margin: 0;
}

ul,
ol {
	list-style: none;
	padding: 0;
	margin: 0;
}

ul li,
ol li {
	display: block;
}

dl {
	margin: 0;
}

dt,
dd {
	line-height: inherit;
}

dt {
	font-weight: inherit;
}

dd {
	margin-bottom: 0;
}

cite {
	font-style: normal;
}

form {
	margin-bottom: 0;
}

blockquote {
	padding-left: 0;
	border-left: 0;
}

address {
	margin-top: 0;
	margin-bottom: 0;
}

figure {
	margin-bottom: 0;
}

html p a:hover {
	text-decoration: none;
}


/*
*
* Typography
*/

body {
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 14px;
	line-height: 1.71429;
	font-weight: 400;
	color: #9a9a9a;
	background-color: #ffffff;
	-webkit-text-size-adjust: none;
	-webkit-font-smoothing: subpixel-antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6,
[class*='heading-'] {
	margin-top: 0;
	margin-bottom: 0;
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-weight: 300;
	color: #151515;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
[class*='heading-'] a {
	color: inherit;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
[class*='heading-'] a:hover {
	color: #0A043B;
}

h1 [style*='max-width'],
h2 [style*='max-width'],
h3 [style*='max-width'],
h4 [style*='max-width'],
h5 [style*='max-width'],
h6 [style*='max-width'],
[class*='heading-'] [style*='max-width'] {
	display: inline-block;
}

h1,
.heading-1 {
	font-size: 32px;
	line-height: 1.5;
	font-weight: 300;
}

@media (min-width: 768px) {
	h1,
	.heading-1 {
		font-size: 38px;
	}
}

@media (min-width: 1200px) {
	h1,
	.heading-1 {
		font-size: 70px;
		line-height: 1.08571;
	}
}

h2,
.heading-2 {
	font-size: 28px;
	line-height: 1.4;
}

@media (min-width: 768px) {
	h2,
	.heading-2 {
		font-size: 35px;
	}
}

@media (min-width: 1200px) {
	h2,
	.heading-2 {
		font-size: 48px;
		line-height: 1.20833;
	}
	h2.h2-smaller,
	.heading-2.h2-smaller {
		font-size: 40px;
	}
}

h3,
.heading-3 {
	font-size: 24px;
	line-height: 1.5;
	letter-spacing: .025em;
}

@media (min-width: 1200px) {
	h3,
	.heading-3 {
		font-size: 36px;
		line-height: 1.33333;
	}
}

h4,
.heading-4 {
	font-size: 18px;
	line-height: 1.45;
	font-weight: 400;
}

@media (min-width: 768px) {
	h4,
	.heading-4 {
		font-size: 20px;
	}
}

@media (min-width: 1200px) {
	h4,
	.heading-4 {
		font-size: 24px;
		line-height: 1.41667;
	}
}

h5,
.heading-5 {
	font-size: 16px;
	line-height: 1.5;
	font-weight: 400;
}

@media (min-width: 768px) {
	h5,
	.heading-5 {
		font-size: 18px;
		line-height: 1.38889;
	}
}

h6,
.heading-6 {
	font-size: 14px;
	line-height: 1.7;
	font-weight: 400;
	letter-spacing: .025em;
}

label {
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif !important;
}

@media (min-width: 768px) {
	h6,
	.heading-6 {
		font-size: 16px;
		line-height: 1.5;
	}
}

.heading-7 {
	line-height: 1.5;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: .025em;
}

.heading-8 {
	/* font: 500 14px/24px "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", rubiklight, "Helvetica Neue", Arial, sans-serif; */
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif;
	line-height: 1.5;
	text-transform: uppercase;
	letter-spacing: .05em;
	font-weight: 400;
}

.heading-9 {
	font-size: 13px;
	line-height: 1.2;
	letter-spacing: .1em;
	font-weight: 500;
	text-transform: uppercase;
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif;
}

.heading-decorated_1::before {
	content: '';
	display: inline-block;
	vertical-align: middle;
	margin-right: 16px;
	width: 24px;
	height: 3px;
	background: #0A043B;
}

.text-style-1 {
	letter-spacing: .025em;
	/* color: #151515; */
}

small,
.small {
	display: block;
	font-size: 12px;
	line-height: 1.5;
}

mark,
.mark {
	padding: 3px 5px;
	color: #ffffff;
	background: #0A043B;
}

strong {
	color: #151515;
}

.context-dark strong,
.bg-gray-700 strong,
.bg-blue-13 strong,
.bg-blue-15 strong,
.bg-primary strong {
	color: #ffffff;
}

.big {
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 18px;
	line-height: 1.44444;
}

.big a {
	color: inherit;
}

.big a:hover {
	color: #0A043B;
}

.big a[href*='callto'] {
	white-space: nowrap;
}

.lead {
	font-size: 24px;
	line-height: 34px;
	font-weight: 300;
}

code {
	padding: 3px 10px;
	border-radius: 0.2rem;
	font-size: 90%;
	color: #151515;
	background: #f3f4f9;
}

p [data-toggle='tooltip'] {
	padding-left: .25em;
	padding-right: .25em;
	color: #0A043B;
}

p [style*='max-width'] {
	display: inline-block;
}


/*
*
* Brand
*/

.brand {
	display: inline-block;
}

.brand .brand-logo-light {
	display: none;
}

.brand .brand-logo-dark {
	display: block;
}


/*
*
* Links
*/

a {
	color: inherit;
	letter-spacing: .025em;
	transition: all 0.3s ease-in-out;
}

a,
a:focus,
a:active,
a:hover {
	text-decoration: none;
}

a[href*='tel'],
a[href*='mailto'] {
	white-space: nowrap;
}

a:hover {
	color: #0A043B;
}

p a {
	/* color: #0A043B; */
}

p a:active,
p a:focus {
	color: #0A043B;
}

p a:hover {
	color: #0A043B;
}

p .link-hover {
	color: #0A043B;
}

p .link-press {
	color: #0A043B;
}

.privacy-link {
	display: inline-block;
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 14px;
	line-height: 1.7;
}

*+.privacy-link {
	margin-top: 30px;
}

@media (min-width: 768px) {
	.privacy-link {
		font-size: 16px;
		line-height: 1.5;
	}
	*+.privacy-link {
		margin-top: 45px;
	}
}

.link-corporate {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 27px;
	border: 1px solid #d6d6d6;
	transition: .2s ease-in-out;
}

.link-corporate img {
	max-width: 90%;
}

.link-corporate::before,
.link-corporate::after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	border: 1px solid #0A043B;
	transition: .25s ease-out;
	contain: paint;
}

.link-corporate::before {
	-webkit-transform: scale3d(0, 1, 1);
	        transform: scale3d(0, 1, 1);
	border-width: 1px 0 1px 0;
}

.link-corporate::after {
	-webkit-transform: scale3d(1, 0, 1);
	        transform: scale3d(1, 0, 1);
	border-width: 0 1px 0 1px;
}

.link-corporate:hover {
	border-color: rgba(214, 214, 214, 0);
}

.link-corporate:hover::before,
.link-corporate:hover::after {
	-webkit-transform: scale3d(1, 1, 1);
	        transform: scale3d(1, 1, 1);
}

.context-dark .link-corporate,
.bg-gray-700 .link-corporate,
.bg-blue-13 .link-corporate,
.bg-blue-15 .link-corporate,
.bg-primary .link-corporate {
	border-color: rgba(255, 255, 255, 0.4);
}

.context-dark .link-corporate:hover,
.bg-gray-700 .link-corporate:hover,
.bg-blue-13 .link-corporate:hover,
.bg-blue-15 .link-corporate:hover,
.bg-primary .link-corporate:hover {
	border-color: transparent;
}

.context-dark .link-corporate:hover img,
.bg-gray-700 .link-corporate:hover img,
.bg-blue-13 .link-corporate:hover img,
.bg-blue-15 .link-corporate:hover img,
.bg-primary .link-corporate:hover img {
	-webkit-filter: saturate(0.45378) hue-rotate(137.22222deg);
	        filter: saturate(0.45378) hue-rotate(137.22222deg);
}

.bg-primary .link-corporate:hover {
	border-color: #ffffff;
}

@media (min-width: 576px) {
	.owl-item .link-corporate {
		margin-left: 2px;
		margin-right: 2px;
	}
}

@media (min-width: 1200px) {
	.link-corporate {
		min-height: 145px;
	}
}


/*
*
* Blocks
*/

.block-1 {
	max-width: 512px;
	margin-left: auto;
	margin-right: auto;
}

.block-2 {
	max-width: 640px;
	margin-left: auto;
	margin-right: auto;
}

@media (min-width: 768px) {
	.block-3 {
		max-width: 400px;
	}
}

.block-4 {
	display: inline-block;
	max-width: 400px;
}

@media (min-width: 992px) {
	.block-4 {
		max-width: 800px;
	}
}

@media (min-width: 992px) {
	.block-5 {
		max-width: 540px;
	}
}

.block-6 {
	max-width: 800px;
	margin-left: auto;
	margin-right: auto;
	padding-bottom: 20px;
}


/*
*
* Boxes
*/

.box-line {
	max-width: 250px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	cursor: default;
}

@media (max-width: 575.98px) {
	.owl-carousel-stretch .owl-item>.box-line {
		flex-shrink: 0;
		max-width: 400px;
	}
}

.box-line_corporate {
	max-width: 100%;
	padding: 30px 20px 40px;
	border-radius: 3px;
	background: #0A043B;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
}

.box-line_corporate .box-line-inner {
	max-width: 280px;
	margin-left: auto;
	margin-right: auto;
}

.box-line_corporate .box-line-title,
.box-line_corporate .icon.box-line-icon {
	color: #ffffff;
}

.box-line_corporate .box-line-divider {
	border-bottom-color: rgba(255, 255, 255, 0.22);
}

.box-line_corporate .box-line-title {
	padding-left: 20px;
	padding-right: 20px;
}

@media (min-width: 768px) {
	.box-line_corporate {
		padding: 65px 30px 80px;
	}
}

.box-line_creative {
	max-width: 100%;
	padding: 25px 17px 35px;
	background: #f3f4f9;
}

.box-line_creative .box-line-inner {
	max-width: 320px;
	margin-left: auto;
	margin-right: auto;
}

.box-line_creative .box-line-divider {
	border-bottom-color: rgba(21, 21, 21, 0.22);
}

.box-line_creative .box-line-title {
	padding-left: 20px;
	padding-right: 20px;
}

.box-line_creative:hover .box-line-icon {
	color: #0A043B;
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.box-line_creative .box-line-title {
		padding-left: 10px;
		padding-right: 10px;
	}
}

@media (min-width: 1200px) {
	.box-line_creative {
		padding: 40px 30px 45px;
	}
}

.icon.box-line-icon {
	font-size: 48px;
	line-height: 1;
	color: #0A043B;
	transition: .22s;
}

.box-line-title {
	color: #151515;
	transition: .22s;
}

.box-line-divider {
	position: relative;
	transition: .22s;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	border-bottom: 1px solid #d6d6d6;
}

*+.box-line-divider {
	margin-top: 20px;
}

*+.box-line-title {
	margin-top: 10px;
}

.box-line-icon+.box-line-title {
	margin-top: 8px;
}

@media (min-width: 768px) {
	.icon.box-line-icon {
		font-size: 74px;
	}
	.box-line_sm .icon.box-line-icon {
		font-size: 70px;
	}
	*+.box-line-title {
		margin-top: 18px;
	}
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	.box-line_sm .box-line-title {
		font-size: 15px;
	}
}

@media (min-width: 1200px) {
	.icon.box-line-icon {
		font-size: 88px;
	}
}

.box-info {
	position: relative;
	color: #151515;
	letter-spacing: .025em;
}

@media (min-width: 992px) {
	.box-info {
		margin-left: 25px;
	}
}

@media (min-width: 1200px) {
	.box-info {
		padding: 35px 50px 50px 40px;
	}
	.box-info *+p {
		margin-top: 20px;
	}
}

.box-info-2 {
	position: relative;
	padding: 20px 20px 40px;
	color: #151515;
	background: #ffffff;
	letter-spacing: .025em;
}

@media (min-width: 768px) {
	.box-info-2 {
		padding: 35px 30px 40px;
	}
}

@media (min-width: 992px) {
	.box-info-2 {
		margin-left: 24px;
		border-left-width: 0;
	}
	.box-info-2::before {
		content: '';
		position: absolute;
		top: 0;
		left: -23px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 24px 40px 0;
		border-color: transparent #ffffff transparent transparent;
	}
}

@media (min-width: 1200px) {
	.box-info-2 {
		padding: 35px 50px 50px 40px;
	}
}

.box-info-3 {
	width: 100%;
	position: relative;
	padding: 20px 30px 25px 34px;
	background: #ffffff;
	letter-spacing: .025em;
	text-align: center;
}

.box-info-3-title-divider {
	display: block;
	font-size: 0;
	line-height: 0;
}

.box-info-3-title-divider::before {
	content: '';
	display: inline-block;
	width: 43px;
	height: 2px;
	background: #0A043B;
}

*+.box-info-3-title-divider {
	margin-top: 8px;
}

@media (max-width: 767.98px) {
	.box-info-3 {
		max-width: 320px;
	}
	.height-fill .box-info-3 {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}

@media (min-width: 576px) {
	.box-info-3 {
		text-align: left;
	}
	.box-info-3-title {
		position: relative;
		text-indent: 55px;
	}
	.box-info-3-title-divider {
		position: absolute;
		top: -.22em;
		left: 0;
		display: inline-block;
		margin: 0;
		vertical-align: middle;
		font-size: inherit;
		line-height: inherit;
		text-indent: 0;
	}
}

@media (max-width: 1199.98px) {
	.box-info-3 {
		margin-bottom: 16px;
	}
	.box-info-3::before {
		content: '';
		position: absolute;
		left: 50%;
		margin-left: -9px;
		bottom: -18px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 18px 18px 0 18px;
		border-color: #ffffff transparent transparent transparent;
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	.box-info-3 {
		margin-bottom: 0;
	}
	.box-info-3::before {
		display: none;
	}
}

@media (min-width: 768px) {
	.box-info-3::before {
		left: 10%;
		margin-left: 0;
	}
}

@media (min-width: 1200px) {
	.box-info-3 {
		margin-right: 17px;
		margin-bottom: 0;
	}
	.box-info-3::before {
		content: '';
		position: absolute;
		top: 0;
		left: auto;
		right: -17px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 0 26px 17px;
		border-color: transparent transparent transparent #ffffff;
	}
}

.box-creative {
	position: relative;
	display: flex;
	padding: 25px 15px;
	text-align: center;
	border: 1px solid #d6d6d6;
	transition: .2s ease-in-out;
	-webkit-transform: translate3d(0, 0, 0);
	        transform: translate3d(0, 0, 0);
}

.box-new-candidate {
	border: 2px solid #160E4D;
	box-sizing: border-box;
	border-radius: 10px;
	color: #0B132A;
	position: relative;
	display: flex;
	padding: 25px 15px;
	height: 300px;
	width: 300px;
	text-align: center;
}
.new-candidate-img{
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}
.new-candidate-sm-pic{
	margin-left: 0px;
}
.new-candidate-name-div{
    margin-left: 20px;
    line-height: 8px;
	text-transform: capitalize;
}

.testimonial-img{
	/* min-width: 50px; */
	min-height: 55px;
	/* max-width: 50px; */
	max-height: 55px;
	border-radius: 50%;
	width: 55px;
	/* height: auto; */
}

@media (max-width: 768px){
	.testimonial-img{
		min-height: 45px;
		max-height: 45px;
		height: 45px !important;
		width: 45px !important;
	}
	.new-candidate-name-div{
		margin-left:10px;
	}
}

.new-candidate-img-name{
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 30px;
	display: flex;
	align-items: center;
	color: #0B132A;
}

@media (max-width: 450px){
	.new-candidate-img-name{
		line-height: 18px !important;
		margin-top: 7px;
	}
}

.new-candidate-place{
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 30px;
	display: flex;
	align-items: center;
	color: #4F5665;
	margin-top: -15px;
}
.box-new-candidate:nth-child(odd){
	margin-left: 50px;
	margin-right: 50px;
}
.box-new-candidate-inner {
	display: flex;
	flex-direction: column;
	/* align-items: center; */
	/* justify-content: center; */
}
.text-align-left{
	text-align:left!important;
}

.new-candidate-company-title {
	font-size: 20px;
	font-weight: 500;
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
}

.new-candidate-join-desc {
	text-align:justify;
	margin-top:15px;
	font-size: 16px;
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	color: #0B132A;
}

@media (max-width: 500px){
	.new-candidate-join-desc{
		line-height: 16px !important;
	}
}
@media (max-width: 350px){
	.new-candidate-join-desc{
		font-size: 11px !important;
	}
}

@media (min-width:1200px) and (max-width: 1300px){
	.new-candidate-join-desc{
		font-size: 14px !important;
	}
}

@media (min-width: 320px) and (max-width: 1023px){
	.box-new-candidate:nth-child(odd){
		margin-left: 0px;
		margin-right: 0px;
	}
}

@media (min-width: 1040px) and (max-width: 1220px){
	.box-new-candidate:nth-child(odd){
		margin-left: 0px;
		margin-right: 0px;
	}
}

.box-creative,
.box-creative:active,
.box-creative:focus,
.box-creative:hover {
	color: #9a9a9a;
}

.box-creative p+p {
	margin-top: 3px;
}

.box-creative::before,
.box-creative::after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	border: 1px solid #0A043B;
	transition: .25s ease-out;
	will-change: transform;
	contain: paint;
}

.box-creative::before {
	-webkit-transform: scale3d(0, 1, 1);
	        transform: scale3d(0, 1, 1);
	border-width: 1px 0 1px 0;
}

.box-creative::after {
	-webkit-transform: scale3d(1, 0, 1);
	        transform: scale3d(1, 0, 1);
	border-width: 0 1px 0 1px;
}

.box-creative:hover {
	border-color: rgba(214, 214, 214, 0);
	background: rgba(0, 0, 0, 0.01);
}

.box-creative:hover::before,
.box-creative:hover::after {
	-webkit-transform: scale3d(0.9999, 0.9999, 0.9999);
	        transform: scale3d(0.9999, 0.9999, 0.9999);
}

.box-creative-inner,
.box-new-candidate-inner,
.box-creative-dummy {
	width: 100%;
	flex-shrink: 0;
}

.box-creative-inner {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.box-creative-dummy {
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
}

.box-creative-dummy::before {
	content: '';
	display: block;
	padding-top: 89.65517%;
}

.box-creative-icon {
	font-size: 59px;
	line-height: 1;
	color: #0A043B;
}

.box-creative-title {
	font-size: 14px;
	line-height: 1.4;
	color: #151515;
}

*+.box-creative-title {
	margin-top: 6px;
}

@media (max-width: 575.98px) {
	.box-creative {
		max-width: 280px;
		margin-left: auto;
		margin-right: auto;
	}
}

@media (min-width: 576px) {
	.owl-item .box-creative {
		margin-left: 2px;
		margin-right: 2px;
	}
}

@media (min-width: 992px) {
	.box-creative-title {
		font-size: 16px;
	}
}


/*
* Element groups
*/

html .group {
	margin-bottom: -20px;
	margin-left: -15px;
}

html .group:empty {
	margin-bottom: 0;
	margin-left: 0;
}

html .group>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 10px;
	margin-left: 15px;
}

html .group-sm {
	margin-bottom: -15px;
	margin-left: -20px;
}

html .group-sm:empty {
	margin-bottom: 0;
	margin-left: 0;
}

html .group-sm>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 15px;
	margin-left: 20px;
}

html .group-xl {
	margin-bottom: -20px;
	margin-left: -30px;
}

html .group-xl:empty {
	margin-bottom: 0;
	margin-left: 0;
}

html .group-xl>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 20px;
	margin-left: 30px;
}

@media (min-width: 992px) {
	html .group-xl {
		margin-bottom: -20px;
		margin-left: -45px;
	}
	html .group-xl>* {
		margin-bottom: 20px;
		margin-left: 45px;
	}
}

html .group-justify {
	margin-bottom: -15px;
	margin-left: -13px;
}

html .group-justify:empty {
	margin-bottom: 0;
	margin-left: 0;
}

html .group-justify>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 15px;
	margin-left: 13px;
}

html .group-justify .button {
	padding-left: 20px;
	padding-right: 20px;
}

@media (min-width: 768px) {
	html .group-justify {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	html .group-justify>* {
		flex-grow: 1;
		flex-shrink: 0;
	}
	html .group-justify .button {
		min-width: 130px;
		padding-left: 20px;
		padding-right: 20px;
	}
}

html .group-middle {
	display: inline-table;
	vertical-align: middle;
}

html .group-middle>* {
	vertical-align: middle;
}

*+.group-justify {
	margin-top: 30px;
}

*+.group-sm {
	margin-top: 30px;
}

*+.group-xl {
	margin-top: 20px;
}

@media (min-width: 992px) {
	*+.group-justify {
		margin-top: 40px;
	}
}


/*
*
* Responsive units
*/

.unit {
	display: flex;
	flex: 0 1 100%;
	margin-bottom: -30px;
	margin-left: -20px;
}

.unit>* {
	margin-bottom: 30px;
	margin-left: 20px;
}

.unit:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.unit-body {
	flex: 0 1 auto;
}

.unit-left,
.unit-right {
	flex: 0 0 auto;
	max-width: 100%;
}

.unit-spacing-xxs {
	margin-bottom: -10px;
	margin-left: -10px;
}

.unit-spacing-xxs>* {
	margin-bottom: 10px;
	margin-left: 10px;
}

.unit-spacing-xs {
	margin-bottom: -15px;
	margin-left: -7px;
}

.unit-spacing-xs>* {
	margin-bottom: 15px;
	margin-left: 7px;
}

.unit-spacing-sm {
	margin-bottom: -15px;
	margin-left: -15px;
}

.unit-spacing-sm>* {
	margin-bottom: 15px;
	margin-left: 15px;
}


/*
*
* Lists
*/

.list>li+li {
	margin-top: 10px;
}

.list-xs>li+li {
	margin-top: 5px;
}

.list-sm>li+li {
	margin-top: 7px;
}

.list-md>li+li {
	margin-top: 18px;
}

.list-lg>li+li {
	margin-top: 25px;
}

.list-xl>li+li {
	margin-top: 25px;
}

.list-1>li+li {
	margin-top: 14px;
}

@media (min-width: 768px) {
	.list-xl>li+li {
		margin-top: 60px;
	}
}

.list-inline>li {
	display: inline-block;
}

html .list-inline-xs {
	-webkit-transform: translate3d(0, -5px, 0);
	        transform: translate3d(0, -5px, 0);
	margin-bottom: -5px;
	margin-left: -4px;
	margin-right: -4px;
}

html .list-inline-xs>* {
	margin-top: 5px;
	padding-left: 4px;
	padding-right: 4px;
}

html .list-inline-sm {
	-webkit-transform: translate3d(0, -8px, 0);
	        transform: translate3d(0, -8px, 0);
	margin-bottom: -8px;
	margin-left: -8px;
	margin-right: -8px;
}

html .list-inline-sm>* {
	margin-top: 8px;
	padding-left: 8px;
	padding-right: 8px;
}

html .list-inline-md {
	-webkit-transform: translate3d(0, -8px, 0);
	        transform: translate3d(0, -8px, 0);
	margin-bottom: -8px;
	margin-left: -10px;
	margin-right: -10px;
}

html .list-inline-md>* {
	margin-top: 8px;
	padding-left: 10px;
	padding-right: 10px;
}

.list-terms dd {
	color: #686868;
}

.list-terms dt+dd {
	margin-top: 15px;
}

.list-terms dd+dt {
	margin-top: 30px;
}

.list li > label {
	text-transform: capitalize;
}

@media (min-width: 768px) {
	.list-terms dd+dt {
		margin-top: 35px;
	}
}

@media (min-width: 1200px) {
	.list-terms dd+dt {
		margin-top: 45px;
	}
}

*+.list-terms {
	margin-top: 25px;
}

.list-terms-inline dt,
.list-terms-inline dd {
	display: inline;
}

.list-terms-inline dt::after {
	content: ':';
	display: inline-block;
}

.list-terms-inline a {
	color: inherit;
}

.list-terms-inline a:hover {
	color: #0A043B;
}

.index-list {
	counter-reset: li;
}

.index-list>li .list-index-counter:before {
	content: counter(li, decimal-leading-zero);
	counter-increment: li;
}

.list-marked {
	text-align: left;
	letter-spacing: .025em;
}

.list-marked>li {
	text-indent: -25px;
	padding-left: 40px;
}

.list-marked>li::before {
	position: relative;
	display: inline-block;
	left: 20px;
	top: -1px;
	min-width: 25px;
	content: '\e02d';
	font-family: 'fl-budicons-free';
	font-size: 11px;
	color: #0A043B;
}

.list-marked>li+li {
	margin-top: 11px;
}

.context-dark .list-marked,
.bg-gray-700 .list-marked,
.bg-blue-13 .list-marked,
.bg-blue-15 .list-marked,
.bg-primary .list-marked {
	color: #ffffff;
}

.bg-primary .list-marked>li::before {
	color: #ffffff;
}

*+.list-marked {
	margin-top: 20px;
}

.list-marked-1 {
	padding-left: 29px;
	text-align: left;
}

.list-marked-1>li {
	text-indent: -20px;
}

.list-marked-1>li::before {
	content: '';
	position: relative;
	top: -1px;
	display: inline-block;
	margin-right: 9px;
	vertical-align: middle;
	width: 0;
	height: 0;
	font-size: 0;
	line-height: 0;
	border-style: solid;
	border-width: 4px 0 4px 10px;
	border-color: transparent transparent transparent #0A043B;
}

.list-marked-1 a {
	color: inherit;
}

.list-marked-1 a:hover {
	color: #0A043B;
}

.list-marked-1>li+li {
	margin-top: 13px;
}

.list-marked-2 {
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif;
	color: #151515;
	text-align: left;
}

.list-marked-2>li {
	text-indent: -28px;
	padding-left: 40px;
}

.list-marked-2>li::before {
	position: relative;
	display: inline-block;
	left: 20px;
	top: -1px;
	min-width: 28px;
	content: '\e02d';
	font-family: 'fl-budicons-free';
	color: #0A043B;
}

.list-marked-2>li+li {
	margin-top: 10px;
}

*+.list-marked-2 {
	margin-top: 20px;
}

.list-marked-2+p {
	margin-top: 20px;
}

@media (min-width: 768px) {
	.list-marked-2 {
		font-size: 16px;
	}
	.list-marked-2>li+li {
		margin-top: 17px;
	}
	*+.list-marked-2 {
		margin-top: 38px;
	}
	.list-marked-2+p {
		margin-top: 38px;
	}
}

.list-marked-3 {
	color: #151515;
}

.list-marked-3>li {
	position: relative;
	padding-left: 30px;
	letter-spacing: .025em;
}

.list-marked-3>li::before {
	content: '';
	position: absolute;
	top: .65em;
	left: 0;
	display: inline-block;
	width: 18px;
	height: 3px;
	vertical-align: middle;
	background: #0A043B;
}

.list-marked-3>li+li {
	margin-top: 10px;
}

*+.list-marked-3 {
	margin-top: 15px;
}

@media (min-width: 768px) {
	.list-marked-3 {
		max-width: 340px;
	}
	.list-marked-3>li+li {
		margin-top: 18px;
	}
}

.list-ordered {
	counter-reset: li;
	padding-left: 25px;
	text-align: left;
	color: #151515;
}

.list-ordered>li {
	position: relative;
	padding-left: 25px;
}

.list-ordered>li:before {
	content: counter(li, decimal) ".";
	counter-increment: li;
	position: absolute;
	top: 0;
	left: 0;
	display: inline-block;
	width: 15px;
	color: #b7b7b7;
}

.list-ordered>li+li {
	margin-top: 10px;
}

*+.list-ordered {
	margin-top: 15px;
}

.list-categories {
	padding: 25px 30px;
	border: 1px solid #e0e0e0;
}

.list-categories>li>a {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
}

.list-categories>li.active {
	color: #0A043B;
}

.list-categories>li+li {
	margin-top: 11px;
}

.timeline-classic p {
	letter-spacing: .025em;
}

.timeline-classic-period {
	padding-right: 20px;
	white-space: nowrap;
	letter-spacing: .025em;
}

.timeline-classic-title {
	position: relative;
	padding-left: 35px;
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif;
}

.timeline-classic-title-sub {
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif;
	font-weight: 400;
}

.timeline-classic-title::before {
	content: '';
	position: absolute;
	left: 0;
	top: .65em;
	display: inline-block;
	width: 25px;
	height: 2px;
	vertical-align: middle;
	background: #0A043B;
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif;
}

.timeline-classic-main {
	position: relative;
	padding: 10px 0 30px 20px;
}

.timeline-classic-main::before,
.timeline-classic-main::after {
	content: '';
	position: absolute;
	pointer-events: none;
}

.timeline-classic-main::before {
	left: 0;
	top: 0;
	bottom: 0;
	border-left: 1px solid rgb(150, 145, 171);
}

.timeline-classic-main *+p {
	margin-top: 10px;
}

.timeline-classic-item:last-child .timeline-classic-main {
	padding-bottom: 0;
}

.timeline-classic-item:last-child .timeline-classic-main::before {
	bottom: 20px;
}

*+.timeline-classic {
	margin-top: 30px;
}

@media (max-width: 767.98px) {
	.timeline-classic-period {
		margin-left: -2px;
	}
}

@media (min-width: 768px) {
	.timeline-classic {
		display: table;
	}
	.timeline-classic-item {
		display: table-row;
	}
	.timeline-classic-item>* {
		display: table-cell;
	}
	.timeline-classic-main {
		padding-bottom: 20px;
	}
	.timeline-classic-main::after {
		top: 18px;
		left: -4px;
		width: 9px;
		height: 9px;
		background: #e0e0e0;
		border-radius: 50%;
	}
}

.timeline-classic-period label {
	width: 90px !important
}

.list-linked {
	display: flex;
	flex-wrap: wrap;
	counter-reset: list-linked-counter;
	text-align: center;
	margin-left: 100px;
	margin-right: 100px;
	margin-bottom: -30px;
}

.list-linked>* {
	margin-bottom: 30px;
}

.ll-item {
	padding: 0 15px;
	max-width: 240px;
	margin-left: auto;
	margin-right: auto;
}

.ll-item *+p {
	margin-top: 5px;
}

.ll-item:hover .ll-item-icon-inner {
	stroke-dashoffset: 0;
	opacity: 1;
}

.ll-item-icon {
	position: relative;
	align-items: center;
	justify-content: center;
	width: 100px;
	height: 100px;
	margin-left: auto;
	margin-right: auto;
	border-radius: 50%;
	font-size: 40px;
	line-height: 1;
	background: #ffffff;
	color: #0A043B;
	box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.06);
}

.ll-item-icon::after {
	content: '';
	position: absolute;
	top: 7px;
	right: 7px;
	bottom: 7px;
	left: 7px;
	border-radius: inherit;
	/* border: 1px solid rgba(16, 135, 235, 0.43); */
	border: 1px solid rgba(10, 4, 59, 0.43);
}

.ll-item-icon.icon {
	display: flex;
}

.ll-item-icon-inner {
	position: absolute;
	top: 7px;
	right: 7px;
	bottom: 7px;
	left: 7px;
	width: calc(100% - 14px);
	height: calc(100% - 14px);
	pointer-events: none;
	stroke: #0A043B;
	stroke-width: 2px;
	stroke-dasharray: 1000;
	stroke-dashoffset: 1000;
	opacity: 0;
	transition: opacity .22s, stroke-dashoffset .8s ease-in-out;
}

.ll-item-icon-sm {
	font-size: 26.66667;
}

.ll-item-title {
	font-weight: 500;
}

.ll-item-title::before {
	display: inline-block;
	margin-right: 10px;
	content: counter(list-linked-counter) ".";
	counter-increment: list-linked-counter;
}

*+.list-linked {
	margin-top: 35px;
}

*+.ll-item-main {
	margin-top: 20px;
}

@media (min-width: 400px) {
	.ll-item {
		width: 50%;
	}
}

@media (min-width: 768px) {
	.ll-item {
		position: relative;
		width: 25%;
		max-width: 100%;
	}
	.ll-item::after {
		content: '';
		position: absolute;
		left: calc(50% - -5px);
		z-index: 10;
		width: calc(100% - 50px);
		padding-bottom: 20.48193%;
		margin-left: 50px;
		background-repeat: no-repeat;
		background-position: center center;
		pointer-events: none;
	}
	.ll-item:nth-child(odd)::after {
		top: 10px;
		background-image: url(/img/divider-line-top.png);
	}
	.ll-item:nth-child(even)::after {
		top: 60px;
		background-image: url(/img/divider-line-bottom.png);
	}
	.ll-item:nth-child(3n)::after {
		display: none;
	}
}

@media (min-width: 1200px) {
	.ll-item::after {
		margin-left: 90px;
		width: calc(100% - 100px);
	}
	.ll-item:nth-child(even)::after {
		top: 100px;
	}
	.ll-item-icon {
		width: 160px;
		height: 160px;
		font-size: 66px;
	}
	.ll-item-icon::after {
		position: absolute;
		top: 13px;
		right: 13px;
		bottom: 13px;
		left: 13px;
	}
	.ll-item-icon-sm {
		font-size: 55px;
	}
	.ll-item-icon-inner {
		top: 13px;
		right: 13px;
		bottom: 13px;
		left: 13px;
		width: calc(100% - 26px);
		height: calc(100% - 26px);
	}
}

.list-icons>li,
.list-icons>li>a {
	white-space: nowrap;
}

.list-icons>li *,
.list-icons>li>a * {
	white-space: normal;
}

.list-icons>li>*,
.list-icons>li>a>* {
	display: inline-block;
	max-width: 92%;
	vertical-align: top;
}

.list-icons>li>*+*,
.list-icons>li>a>*+* {
	margin-left: 5px;
}

.list-progress {
	counter-reset: list-progress-counter;
}

.lp-item {
	padding: 20px 22px 25px 34px;
	max-width: 270px;
	margin-left: auto;
	margin-right: auto;
	background: #ffffff;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.19);
	transition: .22s;
}

.lp-item:hover {
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.22);
}

.lp-item p {
	letter-spacing: .05em;
}

.icon.lp-item-icon {
	font-size: 40px;
	line-height: 40px;
	color: #0A043B;
}

.lp-item-icon-sm {
	font-size: 32px;
}

.lp-item-header {
	display: flex;
	justify-content: space-between;
}

.lp-item-counter {
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 33px;
	line-height: 1;
	font-weight: 500;
	letter-spacing: .025em;
	color: #e7e9f0;
}

.lp-item-counter::before {
	content: counter(list-progress-counter, decimal-leading-zero);
	counter-increment: list-progress-counter;
}

.lp-item-title {
	font-weight: 500;
}

.bg-gray-100 .lp-item {
	box-shadow: none;
}

*+.lp-item-title {
	margin-top: 15px;
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.lp-item {
		padding: 20px 22px 25px 25px;
	}
}

@media (min-width: 768px) {
	.icon.lp-item-icon {
		font-size: 60px;
		line-height: 60px;
	}
	.icon.lp-item-icon-sm {
		font-size: 48px;
	}
	*+.lp-item-title {
		margin-top: 5px;
	}
}


/*
*
* Images
*/

img {
	display: inline-block;
	max-width: 100%;
	height: auto;
}

.img-responsive {
	width: 100%;
}


/*
*
* Icons
*/

.icon {
	display: inline-block;
	line-height: 1.1;
}

.icon::before {
	position: relative;
	display: inline-block;
	font-weight: 400;
	font-style: normal;
	speak: none;
	text-transform: none;
}

.icon-style-brand[class*='facebook']:hover {
	color: #4d70a8;
}

.icon-style-brand[class*='twitter']:hover {
	color: #02bcf3;
}

.icon-style-brand[class*='google']:hover {
	color: #e2411e;
}

.icon-style-brand[class*='pinterest']:hover {
	color: #c96867;
}

.icon-filled {
	display: inline-block;
	height: 2em;
	width: 2em;
	line-height: 2em;
	text-align: center;
}

.icon-filled-brand {
	position: relative;
}

.icon-filled-brand,
.icon-filled-brand:active,
.icon-filled-brand:focus,
.icon-filled-brand:hover {
	color: #ffffff;
}

.icon-filled-brand[class*='facebook'] {
	background: #5776b7;
}

.icon-filled-brand[class*='facebook']:hover {
	background: #6585b9;
}

.icon-filled-brand[class*='twitter'] {
	background: #88bdee;
}

.icon-filled-brand[class*='twitter']:hover {
	background: #21cbfd;
}

.icon-filled-brand[class*='google'] {
	background: #d46975;
}

.icon-filled-brand[class*='google']:hover {
	background: #e76042;
}

.icon-filled-brand[class*='pinterest'] {
	background: #c96867;
}

.icon-filled-brand[class*='pinterest']:hover {
	background: #d48685;
}

html a.icon-filled-brand:active,
html a.icon-filled-brand:focus,
html a.icon-filled-brand:hover {
	color: #ffffff;
}

.icon-circle {
	border-radius: 50%;
}

.icon-xxs {
	font-size: 13px;
}

.icon-xs {
	font-size: 15px;
}

.icon-sm {
	font-size: 19px;
}

.icon-lg {
	font-size: 30px;
}

.icon-xlg {
	font-size: 30px;
	line-height: 42px;
}

.icon-xl {
	font-size: 42px;
}

.icon-xxl {
	font-size: 60px;
}

@media (min-width: 768px) {
	.icon-xxl {
		font-size: 78px;
	}
}

.icon-default {
	font-size: 1rem;
}

.icon-1 {
	font-size: 14px !important;
}

.icon-2 {
	font-size: 22px;
}

.icon-3 {
	font-size: 26px;
}

.icon-3-company-edit {
	/* font-size: 26px !important; */
	margin-top: 1px !important;
	margin-left: 5% !important;
}

.icon-4 {
	font-size: 33px;
}


/*
*
* Tables custom
*/

.table {
	text-align: left;
	background: #ffffff;
	border-collapse: collapse;
}

.table tr {
	transition: .5s;
}

.table th,
.table td {
	color: #2c343b;
	background: #ffffff;
}

.table th {
	padding: 17px 24px;
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-weight: 600;
	letter-spacing: .05em;
	text-transform: uppercase;
	background: #f5f5f5;
	white-space: nowrap;
}

@media (max-width: 991.98px) {
	.table th {
		padding-top: 20px;
		padding-bottom: 20px;
	}
}

.table td {
	padding: 17px 24px;
	background-color: transparent;
}

.table tbody tr:first-child td {
	border-top: 0;
}

.table tr td {
	border-bottom: 1px solid #f5f5f5;
}

.table tr td:first-child {
	border-left: 1px solid #f5f5f5;
}

.table tr td:last-child {
	border-right: 1px solid #f5f5f5;
}

.table tfoot td {
	color: #34495e;
}

*+[class*='table-responsive'] {
	margin-top: 30px;
}

@media (min-width: 768px) {
	*+[class*='table-responsive'] {
		margin-top: 40px;
	}
}

.table.table-primary thead th {
	color: #ffffff;
	background: #0A043B;
	border: 0;
}

.table.table-primary tbody tr:hover {
	background: #f5f5f5;
}

.table.table-gray-700 thead th {
	color: #ffffff;
	background: #2c343b;
	border: 0;
}

.table.table-gray-700 tbody tr:hover {
	background: #f5f5f5;
}

.table.table-bordered tr td:first-child {
	border-left: 0;
}

.table.table-bordered tr td:last-child {
	border-right: 0;
}

.table.table-bordered td {
	border: 1px solid #f5f5f5;
}

.table.table-bordered tbody>tr:first-of-type>td {
	border-top: 0;
}

.table.table-striped {
	border-bottom: 1px solid #f5f5f5;
}

.table.table-striped tbody tr:nth-of-type(odd) {
	background: transparent;
}

.table.table-striped tbody tr:nth-of-type(even) {
	background: #f5f5f5;
}

.table.table-striped tbody td {
	border: 0;
}

.table.table-striped tfoot td:not(:first-child) {
	border-left: 0;
}

.table-job-listing {
	width: 100%;
	border-radius: 4px;
}

.table-job-listing tbody {
	width: 100%;
}

.table-job-listing tr {
	background-color: transparent;
	border: 1px solid rgb(150, 145, 171);
	transition: .22s;
}

.table-job-listing tr:hover {
	background: #f3f4f9;
}

.table-job-listing tr:hover .company-minimal-figure {
	background: #ffffff;
}

.table-job-listing td {
	padding: 16px 20px;
}

.table-job-listing .company-minimal-figure {
	transition: .22s;
}

.bg-gray-100 tr:hover {
	background: #ebedf3;
}

.table-job-listing_lg td {
	padding: 16px 30px;
}

.table-job-listing-main {
	min-width: 360px;
	width: 71.5%;
}

.table-job-listing-date {
	white-space: nowrap;
}

.table-job-listing-badge {
	text-align: right;
	width: 10%;
}

.heading-decorated_1+.table-job-listing {
	margin-top: 10px;
}

@media (min-width: 768px) {
	.table-job-listing tr {
		height: 110px;
	}
}

.table-job-offers {
	letter-spacing: .025em;
	color: #151515;
}

.table-job-offers tbody {
	width: 100%;
}

.table-job-offers tr {
	border-top: 1px solid #d6d6d6;
}

.table-job-offers tr:last-child {
	border-bottom: 1px solid #d6d6d6;
}

.table-job-offers td {
	padding: 14px 20px;
}

.table-job-offers.table-responsive {
	overflow-y: hidden;
}

.table-job-offers-main {
	min-width: 360px;
	width: 71.5%;
}

.table-job-offers-date:first-child {
	width: 16%;
	padding: 0;
}

.table-job-offers-date:first-child>* {
	display: block;
	padding: 16px 18px 16px 30px;
	border-right: 1px solid #d6d6d6;
}

.table-job-offers-date,
.table-job-offers-meta .object-inline>* {
	white-space: nowrap;
}

.table-job-offers-badge {
	text-align: right;
	width: 10%;
}

*+.table-job-offers-outer,
*+.table-job-offers {
	margin-top: 30px;
}

.table-job-offers-outer+.pagination-outer,
.table-job-offers+.pagination-outer {
	margin-top: 40px;
}

@media (min-width: 768px) {
	.table-job-offers tr {
		height: 102px;
	}
}

@media (min-width: 992px) {
	.table-job-offers-outer {
		position: relative;
		overflow: hidden;
		padding: 6px;
		margin-left: -12px;
		margin-right: -12px;
		margin-bottom: -6px;
	}
	.table-job-offers {
		padding: 6px;
		width: calc(100% + 6px);
	}
	.table-job-offers tr {
		transition: .35s ease-in-out;
	}
	.table-job-offers tr:hover {
		background: #f3f4f9;
		box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.15);
		border-color: #f3f4f9;
	}
	.table-job-offers tr:hover+tr {
		border-color: #f3f4f9;
	}
	.table-job-offers tr:last-child:hover {
		border-color: #f3f4f9;
	}
	.ie-10 .table-job-offers tr:hover,
	.ie-10 .table-job-offers tr:hover+tr,
	.ie-11 .table-job-offers tr:hover,
	.ie-11 .table-job-offers tr:hover+tr {
		border-color: #d6d6d6;
	}
}

@media (min-width: 1200px) {
	.table-job-offers-badge {
		width: 15%;
	}
}

.table-job-listing-2 {
	width: 100%;
	border-collapse: separate;
	margin: -10px 0;
	border-spacing: 0 10px;
}

.table-job-listing-2 tbody {
	width: 100%;
}

.table-job-listing-2 tr {
	background: #ffffff;
	transition: .22s;
}

.table-job-listing-2 td {
	padding: 16px 25px 16px 20px;
}

.table-job-listing-2-main {
	min-width: 360px;
	width: 50%;
}

.table-job-listing-2-location {
	width: 20%;
	white-space: nowrap;
}

.table-job-listing-2-badge {
	text-align: right;
	width: 10%;
}

.heading-decorated_1+.table-job-listing-2 {
	margin-top: 10px;
}

@media (min-width: 768px) {
	.table-job-listing-2 {
		margin: -20px 0;
		border-spacing: 0 20px;
	}
	.table-job-listing-2 tr {
		height: 110px;
	}
	.table-job-listing-2 .company-minimal-figure {
		min-width: 100px;
	}
	.table-job-listing-2-location {
		width: 30%;
	}
}


/*
*
* Dividers
*/

hr {
	margin-top: 0;
	margin-bottom: 0;
	border-top: 1px solid rgb(150, 145, 171);
}

.divider {
	font-size: 0;
	line-height: 0;
}

.divider::after {
	content: '';
	display: inline-block;
	width: 60px;
	height: 2px;
	background-color: #0A043B;
}

.divider-responsive {
	width: 100%;
	border-bottom: 1px solid #e0e0e0;
	margin-left: auto;
	margin-right: auto;
}

@media (min-width: 992px) {
	.col-lg-1 .divider-responsive {
		width: 0;
		border-bottom: 0;
		border-right: 1px solid #e0e0e0;
	}
}

.divider-1 {
	height: 20px;
	width: 1px;
	margin-left: auto;
	margin-right: auto;
	background: #d6d6d6;
}

.context-dark .divider-1,
.bg-gray-700 .divider-1,
.bg-blue-13 .divider-1,
.bg-blue-15 .divider-1,
.bg-primary .divider-1 {
	background: rgba(255, 255, 255, 0.46);
}

*+.divider-1 {
	margin-top: 15px;
}

.divider-1+.button {
	margin-top: 25px;
}

@media (min-width: 768px) {
	.divider-1 {
		height: 45px;
	}
}


/*
*
* Buttons
*/

.button {
	position: relative;
	overflow: hidden;
	display: inline-block;
	padding: 14px 35px;
	font-size: 14px;
	line-height: 1.3;
	border: 2px solid;
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-weight: 700;
	letter-spacing: 0.025em;
	text-transform: uppercase;
	white-space: nowrap;
	text-overflow: ellipsis;
	text-align: center;
	cursor: pointer;
	vertical-align: middle;
	-webkit-user-select: none;
	        user-select: none;
	transition: 250ms all ease-in-out;
}

.button-block {
	display: block;
	width: 100%;
}

.button-default,
.button-default:focus {
	color: #d6d6d6;
	background-color: #2c343b;
	border-color: #2c343b;
}

.button-default:hover,
.button-default:active {
	color: #ffffff;
	background-color: #0A043B;
	border-color: #0A043B;
}

.button-primary,
.button-primary:focus {
	color: #ffffff;
	background-color: #0A043B;
	border-color: #0A043B;
}

.button-primary:hover,
.button-primary:active {
	color: #ffffff;
	/* background-color: #ca5217;
	border-color: #ca5217; */
	background-color: rgb(39, 159, 141);
	border-color: rgb(39, 159, 141);
}

.bg-primary .button-primary,
.bg-primary .button-primary:focus {
	color: #151515;
	background-color: #ffffff;
	border-color: #ffffff;
}

.bg-primary .button-primary:hover,
.bg-primary .button-primary:active {
	color: #ffffff;
	background-color: #ca5217;
	border-color: #ca5217;
}

/* Grey Button Start */
.button-grey,
.button-grey:focus {
	color: #ffffff;
	background-color: #B8B8B9;
	border-color: #B8B8B9;
}

.button-grey:hover,
.button-grey:active {
	color: #ffffff;
	/* background-color: #ca5217;
	border-color: #ca5217; */
	background-color: #ca5217;
	border-color: #ca5217;
}

.bg-grey .button-grey,
.bg-grey .button-grey:focus {
	color: #151515;
	background-color: #ffffff;
	border-color: #ffffff;
}

.bg-grey .button-grey:hover,
.bg-grey .button-grey:active {
	color: #ffffff;
	background-color: #ca5217;
	border-color: #ca5217;
}
/* Grey Button Ends */

.button-gray-100,
.button-gray-100:focus {
	color: #151515;
	background-color: #f3f4f9;
	border-color: #f3f4f9;
}

.button-gray-100:hover,
.button-gray-100:active {
	color: #ffffff;
	background-color: #0A043B;
	border-color: #0A043B;
}

.button-secondary,
.button-secondary:focus {
	color: #ffffff;
	background-color: #ca5217;
	border-color: #ca5217;
}

.button-secondary:hover,
.button-secondary:active {
	color: #ffffff;
	background-color: #0A043B;
	border-color: #0A043B;
}

.button-blue-9,
.button-blue-9:focus {
	color: #ffffff;
	background-color: #49555e;
	border-color: #49555e;
}

.button-blue-9:hover,
.button-blue-9:active {
	color: #ffffff;
	background-color: #0A043B;
	border-color: #0A043B;
}

.button-blue-9 .icon,
.button-blue-9:active .icon,
.button-blue-9:focus .icon {
	color: #0A043B;
}

.button-blue-9:hover .icon {
	color: #ffffff;
}

.button-default-outline,
.button-default-outline:focus {
	color: #151515;
	background-color: transparent;
	border-color: #e0e0e0;
}

.button-default-outline:hover,
.button-default-outline:active {
	color: #ffffff;
	background-color: #0A043B;
	border-color: #0A043B;
}

.button-primary-outline,
.button-primary-outline:focus {
	color: #0A043B;
	background-color: transparent;
	border-color: #0A043B;
}

.button-primary-outline:hover,
.button-primary-outline:active {
	color: #ffffff;
	background-color: #0A043B;
	border-color: #0A043B;
}

.bg-primary .button-primary-outline,
.bg-primary .button-primary-outline:focus {
	color: #ffffff;
	background-color: transparent;
	border-color: #ffffff;
}

.bg-primary .button-primary-outline:hover,
.bg-primary .button-primary-outline:active {
	color: #151515;
	background-color: #ffffff;
	border-color: #ffffff;
}

.button-gray-800-outline,
.button-gray-800-outline:focus {
	color: #151515;
	background-color: transparent;
	border-color: #151515;
}

.button-gray-800-outline:hover,
.button-gray-800-outline:active {
	color: #ffffff;
	background-color: #151515;
	border-color: #151515;
}

.button-ghost {
	border: 0;
	background-color: transparent;
}

.button-ghost:hover {
	color: #ffffff;
	background: #0A043B;
}

.button-facebook,
.button-facebook:focus {
	color: #ffffff;
	background-color: #4d70a8;
	border-color: #4d70a8;
}

.button-facebook:hover,
.button-facebook:active {
	color: #ffffff;
	background-color: #6585b9;
	border-color: #6585b9;
}

.button-twitter,
.button-twitter:focus {
	color: #ffffff;
	background-color: #02bcf3;
	border-color: #02bcf3;
}

.button-twitter:hover,
.button-twitter:active {
	color: #ffffff;
	background-color: #21cbfd;
	border-color: #21cbfd;
}

.button-google,
.button-google:focus {
	color: #ffffff;
	background-color: #e2411e;
	border-color: #e2411e;
}

.button-google:hover,
.button-google:active {
	color: #ffffff;
	background-color: #e76042;
	border-color: #e76042;
}

.button-primary-hold {
	margin-left: 50%; 
	font-size: 12px;
}

.button-primary-hold,
.button-primary-hold:focus {
  color: #0A043B;
  background-color: transparent;
  border-color: #0A043B;
}

.button-primary-hold:hover,
.button-primary-hold:active {
  color: #ffffff;
  background-color: #151515;
  border-color: #151515;
}

.bg-primary .button-primary-hold,
.bg-primary .button-primary-hold:focus {
  color: #ffffff;
  background-color: transparent;
  border-color: #ffffff;
}

.bg-primary .button-primary-hold:hover,
.bg-primary .button-primary-hold:active {
  color: #151515;
  background-color: #ffffff;
  border-color: #ffffff;
}

.button-primary-reject {
	margin-left: 10px; 
	font-size: 12px;
}

.button-primary-reject,
.button-primary-reject:focus {
  color: #ffffff;
  background-color: red;
  border-color: red;
}

.button-primary-reject:hover,
.button-primary-reject:active {
  color: #ffffff;
  background-color: red;
  border-color: #151515;
}

.bg-primary .button-primary-reject,
.bg-primary .button-primary-reject:focus {
  color: #151515;
  background-color: #ffffff;
  border-color: #ffffff;
}

.bg-primary .button-primary-reject:hover,
.bg-primary .button-primary-reject:active {
  color: #ffffff;
  background-color: red;
  border-color: #151515;
}

.button-shadow {
	box-shadow: 0 9px 21px 0 rgba(214, 214, 214, 0.6);
}

.button-shadow:hover {
	box-shadow: 0 9px 10px 0 rgba(214, 214, 214, 0.6);
}

.button-shadow:focus,
.button-shadow:active {
	box-shadow: none;
}

.button-primary-approved,
.button-primary-approved:focus {
  color: #ffffff;
  background-color: darkgreen;
  border-color: darkgreen;
}

.button-primary-approved:hover,
.button-primary-approved:active {
  color: #ffffff;
  background-color: green;
  border-color: green;
}

.button-shadow-1 {
	box-shadow: 0 7px 18px 0 rgba(0, 0, 0, 0.18);
}

.button-xs {
	padding: 8px 15px;
	font-size: 13px;
	font-weight: 500;
	letter-spacing: .1em;
	border-radius: 4px;
}

.button-sm {
	font-weight: 500;
	letter-spacing: .1em;
	padding: 10px 25px;
	font-size: 13px;
	line-height: 1.5;
	border-radius: 4px;
}

.button-lg {
	font-weight: 500;
	letter-spacing: .05em;
	padding: 17px 40px;
	font-size: 14px;
	line-height: 1.5;
}

.button-xl {
	letter-spacing: .075em;
	padding: 16px 50px;
	font-size: 16px;
	line-height: 28px;
}

@media (min-width: 992px) {
	.button-xl {
		padding: 20px 80px;
	}
}

.button-circle {
	border-radius: 30px;
}

.button-round-1 {
	border-radius: 5px;
}

.button-round-2 {
	border-radius: 10px;
}

.button.button-icon,
.button-icon .button-inner {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	vertical-align: middle;
}

.button-icon-right,
.button-icon-right .button-inner {
	flex-direction: row-reverse;
}

.button-icon .icon {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	color: inherit;
	font-size: 1.55em;
	line-height: 1em;
	transition: color inherit;
}

.button-icon-left .icon {
	padding-right: 11px;
}

.button-icon-right .icon {
	padding-left: 11px;
}

.button-icon.button-xs .icon {
	padding-right: 5px;
}

.button-icon.button-xs .button-icon-right .icon {
	padding-left: 5px;
}

.button-icon.button-sm .icon {
	top: .05em;
	font-size: 1.2em;
	padding-right: 8px;
}

.button-icon.button-sm .button-icon-right .icon {
	padding-left: 8px;
}

.button.button-icon-only {
	padding: 5px;
}

.btn-primary {
	border-radius: 3px;
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-weight: 700;
	letter-spacing: .05em;
	text-transform: uppercase;
	transition: .33s;
}

.btn-primary,
.btn-primary:active,
.btn-primary:focus {
	color: #ffffff;
	background: #0A043B;
	border-color: #0A043B;
}

.btn-primary:hover {
	color: #ffffff;
	background: #000000;
	border-color: #000000;
}

.button-group {
	display: flex;
}

.button-group .button {
	margin: 0 2px;
	flex-grow: 1;
}

.button-group .button:not(:first-child) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.button-group .button:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.group .button-fixed-size {
	display: inline-flex;
}

.button-fixed-size {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 60px;
	padding: 5px;
}

.button-fixed-size img {
	width: auto;
	height: auto;
	max-width: 90%;
}

@media (min-width: 576px) {
	.button-fixed-size {
		width: 177px;
	}
}

label.button input {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}


/*
*
* Form styles
*/

.rd-form {
	position: relative;
	text-align: left;
}

.rd-form .button {
	padding-top: 10px;
	padding-bottom: 10px;
	min-height: 50px;
}

*+.rd-form {
	margin-top: 20px;
}

input:-webkit-autofill~label,
input:-webkit-autofill~.form-validation {
	color: #151515 !important;
}

.form-wrap {
	position: relative;
}

.form-wrap-inner {
	position: relative;
	/* min-height: 40px; */
}

.form-wrap-icon .icon {
	position: absolute;
	z-index: 10;
	top: 10px;
	left: 15px;
	color: #151515;
	line-height: 24px;
}

.form-wrap-icon .icon:not(.icon-custom-sizing) {
	font-size: 14px;
}

.form-wrap-icon .form-label,
.form-wrap-icon .form-input,
.form-wrap-icon .select2-container--default .select2-selection--single .select2-selection__rendered {
	padding-left: 35px !important;
}

.has-error .form-input,
.has-error .select2-container--default .select2-selection--single {
	border-color: #eb6a6a;
}

.has-focus .form-input,
.has-focus .select2-container--default .select2-selection--single {
	border-color: #0A043B;
}

/* .form-wrap+* { */
	/* margin-top: 30px; */
/* } */

.rd-form .form-wrap+.row {
	margin-top: 30px;
}

.form-input {
	display: block;
	width: 100%;
	min-height: 50px;
	padding: 10px 10px;
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	color: #151515;
	background-color: #ffffff;
	background-image: none;
	/* border-radius: 0; */
	-webkit-appearance: none;
	transition: .3s ease-in-out;
	border: 1px solid rgb(150, 145, 171);
}

.form-input:focus {
	outline: 0;
}

textarea.form-input {
	height: 175px;
	min-height: 50px;
	max-height: 298px;
	resize: vertical;
}

.form-label,
.form-label-outside {
	margin-bottom: 0;
	color: #9a9a9a;
	font-weight: 400;
}

.form-label {
	position: absolute;
	top: 10px;
	left: 0;
	right: 0;
	padding-left: 10px;
	padding-right: 26px;
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	color: #151515;
	pointer-events: none;
	text-align: left;
	/* z-index: 9; */
	transition: .25s;
	will-change: transform;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.candidate-form-label-keyword {
	color: #151515
}

.form-label-keyword {
	position: absolute;
	top: 170px;
	left: 0;
	right: 0;
	padding-left: 0px;
	padding-right: 26px;
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	color: #ffffff;
	pointer-events: none;
	text-align: left;
	z-index: 9;
	transition: .25s;
	will-change: transform;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@media (min-width: 321px) and  (max-width: 519px){
	.form-label-keyword {
		top: 127px;
	}
}

@media (min-width: 520px) and (max-width: 780px) {
	.form-label-keyword {
		top: 87px;
		left: 40px;
	}
}

@media (min-width: 781px) and (max-width: 1215px) {
	.form-label-keyword {
		top: 227px;
	}
}

.form-label.focus {
	opacity: 0;
}

.form-label-preselect-hide {
	opacity: 0;
}

.form-label.auto-fill {
	color: #151515;
}

.form-label-outside {
	display: inline-block;
	margin-bottom: 6px;
	letter-spacing: .025em;
	color: #151515;
	cursor: pointer;
}

@media (min-width: 768px) {
	.form-label-outside {
		position: static;
	}
	.form-label-outside,
	.form-label-outside.focus,
	.form-label-outside.auto-fill {
		-webkit-transform: none;
		        transform: none;
	}
}

.form-label-outside+.form-wrap-inner .form-label,
.form-label-outside+.form-wrap-inner .form-input,
.form-label-outside+.form-wrap-inner .select2-selection--single .select2-selection__rendered {
	color: black;
}

[data-x-mode='true'] .form-label {
	pointer-events: auto;
}

.form-label {
	font-style: italic;
	color: grey !important;
}

.form-validation {
	position: absolute;
	right: 8px;
	top: 0;
	z-index: 11;
	margin-top: 2px;
	font-size: 9px;
	font-weight: 400;
	line-height: 12px;
	letter-spacing: 0;
	color: #eb6a6a;
	transition: .3s;
}

.form-validation-left .form-validation {
	top: 100%;
	right: auto;
	left: 0;
}

#form-output-global {
	position: fixed;
	bottom: 30px;
	left: 15px;
	z-index: 2000;
	visibility: hidden;
	-webkit-transform: translate3d(-500px, 0, 0);
	        transform: translate3d(-500px, 0, 0);
	transition: .3s all ease;
}

#form-output-global.active {
	visibility: visible;
	-webkit-transform: translate3d(0, 0, 0);
	        transform: translate3d(0, 0, 0);
}

@media (min-width: 576px) {
	#form-output-global {
		left: 30px;
	}
}

.form-output {
	position: absolute;
	top: 100%;
	left: 0;
	font-size: 10px;
	font-weight: 400;
	line-height: 1.2;
	margin-top: 2px;
	transition: .3s;
	opacity: 0;
	visibility: hidden;
}

.form-output.active {
	opacity: 1;
	visibility: visible;
}

.form-output.error {
	color: #eb6a6a;
}

.form-output.success {
	color: #98bf44;
}

.radio .radio-custom,
.radio-inline .radio-custom,
.checkbox .checkbox-custom,
.checkbox-inline .checkbox-custom {
	opacity: 0;
}

.radio .radio-custom,
.radio .radio-custom-dummy,
.radio-inline .radio-custom,
.radio-inline .radio-custom-dummy,
.checkbox .checkbox-custom,
.checkbox .checkbox-custom-dummy,
.checkbox-inline .checkbox-custom,
.checkbox-inline .checkbox-custom-dummy {
	position: absolute;
	left: 0;
	width: 15px;
	height: 15px;
	outline: none;
	cursor: pointer;
	border: 1px solid #E7E7E7;
	box-sizing: border-box;
	border-radius: 4px;
}

.radio .radio-custom-dummy,
.radio-inline .radio-custom-dummy,
.checkbox .checkbox-custom-dummy,
.checkbox-inline .checkbox-custom-dummy {
	pointer-events: none;
}

.radio .radio-custom-dummy::after,
.radio-inline .radio-custom-dummy::after,
.checkbox .checkbox-custom-dummy::after,
.checkbox-inline .checkbox-custom-dummy::after {
	position: absolute;
	opacity: 0;
	transition: .22s;
}

.radio .radio-custom:focus,
.radio-inline .radio-custom:focus,
.checkbox .checkbox-custom:focus,
.checkbox-inline .checkbox-custom:focus {
	outline: none;
}

.radio,
.radio-inline,
.checkbox,
.checkbox-inline {
	position: relative;
	cursor: pointer;
	-webkit-user-select: none;
	        user-select: none;
}

.radio input,
.radio-inline input,
.checkbox input,
.checkbox-inline input {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

.radio-custom:checked+.radio-custom-dummy:after,
.checkbox-custom:checked+.checkbox-custom-dummy:after {
	opacity: 1;
}

.radio,
.radio-inline {
	padding-left: 28px;
}

.radio .radio-custom-dummy,
.radio-inline .radio-custom-dummy {
	top: 1px;
	left: 0;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	border: 1px solid #b7b7b7;
}

.radio .radio-custom-dummy::after,
.radio-inline .radio-custom-dummy::after {
	content: '';
	top: 3px;
	right: 3px;
	bottom: 3px;
	left: 3px;
	background: #2c343b;
	border-radius: inherit;
}

.checkbox {
	/* display: block; */
	display: inline;
}

.checkbox,
.checkbox-inline {
	padding-left: 30px;
	color: #151515;
	letter-spacing: .025em;
}

.checkbox .checkbox-custom-dummy,
.checkbox-inline .checkbox-custom-dummy {
	top: 1px;
	left: 0;
	width: 18px;
	height: 18px;
	margin: 0;
	border: 2px solid #e0e0e0;
}

.checkbox .checkbox-custom-dummy::after,
.checkbox-inline .checkbox-custom-dummy::after {
	content: '\f222';
	font-family: "Material Design Icons";
	position: absolute;
	top: -5px;
	left: -2px;
	font-size: 21px;
	line-height: 1;
	color: #151515;
}

.checkbox-inline {
	padding-left: 25px;
}

.checkbox_info {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
}

.checkbox_info>*+* {
	margin-left: 4px;
}

.checkbox_info .checkbox-meta {
	color: #9a9a9a;
}

.checkbox_info .checkbox-meta::before {
	content: '\0028';
}

.checkbox_info .checkbox-meta::after {
	content: '\0029';
}

.toggle-custom {
	padding-left: 60px;
	-webkit-appearance: none;
}

.toggle-custom:checked~.checkbox-custom-dummy::after {
	background: #0A043B;
	-webkit-transform: translate(20px, -50%);
	        transform: translate(20px, -50%);
}

.toggle-custom~.checkbox-custom-dummy {
	position: relative;
	display: inline-block;
	margin-top: -1px;
	width: 44px;
	height: 20px;
	background: #ffffff;
	cursor: pointer;
}

.toggle-custom~.checkbox-custom-dummy::after {
	content: '';
	position: absolute;
	display: inline-block;
	width: 16px;
	height: 16px;
	left: 0;
	top: 50%;
	background: #b7b7b7;
	-webkit-transform: translate(4px, -50%);
	        transform: translate(4px, -50%);
	opacity: 1;
	transition: .22s;
}

.form-inline {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	text-align: center;
	margin-bottom: -8px;
	margin-left: -30px;
}

.form-inline:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.form-inline>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 8px;
	margin-left: 30px;
}

.form-inline>* {
	margin-top: 0;
}

.form-inline .form-wrap {
	flex: 30 0;
	min-width: 220px;
}

.form-inline .form-label {
	display: block;
}

.form-inline .form-button {
	flex-grow: 1;
	min-height: 50px;
}

.form-inline .form-button .button {
	width: 100%;
	min-height: inherit;
}

@media (min-width: 576px) {
	.form-inline .button {
		display: block;
	}
}

.context-dark .form-inline .form-input,
.bg-gray-700 .form-inline .form-input,
.bg-blue-13 .form-inline .form-input,
.bg-blue-15 .form-inline .form-input,
.bg-primary .form-inline .form-input {
	border-color: #ffffff;
}

.form-inline.form-inline_condensed {
	margin-left: 0;
}

.form-inline.form-inline_condensed>* {
	margin-left: 0;
}

.form-sm .form-input,
.form-sm .button {
	padding-top: 9px;
	padding-bottom: 9px;
	min-height: 40px;
}

.form-sm .form-validation {
	top: -16px;
}

.form-sm .form-label {
	top: 20px;
}

.form-sm *+.button {
	margin-top: 10px;
}

.form-lg .form-input,
.form-lg .select2-selection.select2-selection--single .select2-selection__rendered {
	/* padding-top: 17px;
	padding-bottom: 17px; */
	padding-left: 10px;
}

.form-lg .form-input {
	min-height: 44px;
}

.form-lg .select2-selection.select2-selection--single .select2-selection__rendered {
	min-height: 58px;
}

.form-lg .form-button {
	min-height: 60px;
}

/* .form-lg .form-label {
	top: 18px;
} */

/* .form-lg .form-wrap-icon .icon {
	top: 18px;
} */

.form-centered .form-label,
.form-centered .form-input {
	text-align: center;
}

.form-centered .form-wrap+* {
	margin-top: 15px;
}

.form-light .form-input {
	box-shadow: 0 9px 21px 0 rgba(30, 30, 30, 0.38);
}

.context-dark .form-light .form-label,
.bg-gray-700 .form-light .form-label,
.bg-blue-13 .form-light .form-label,
.bg-blue-15 .form-light .form-label,
.bg-primary .form-light .form-label,
.context-dark .form-light .form-input,
.bg-gray-700 .form-light .form-input,
.bg-blue-13 .form-light .form-input,
.bg-blue-15 .form-light .form-input,
.bg-primary .form-light .form-input,
.context-dark .form-light .form-validation,
.bg-gray-700 .form-light .form-validation,
.bg-blue-13 .form-light .form-validation,
.bg-blue-15 .form-light .form-validation,
.bg-primary .form-light .form-validation {
	color: #ffffff;
}

.context-dark .form-light .form-input,
.bg-gray-700 .form-light .form-input,
.bg-blue-13 .form-light .form-input,
.bg-blue-15 .form-light .form-input,
.bg-primary .form-light .form-input {
	background: rgba(255, 255, 255, 0.47);
	border: 0;
}

.form-filled .form-input,
.form-filled .select2-container--default .select2-selection--single {
	background: #f3f4f9;
	border-color: #f3f4f9;
}

.select2-dropdown-filled.select2-dropdown {
	color: #2c343b;
	background: #f3f4f9;
	border-color: #f3f4f9;
}

.context-dark .form-filled .form-label,
.bg-gray-700 .form-filled .form-label,
.bg-blue-13 .form-filled .form-label,
.bg-blue-15 .form-filled .form-label,
.bg-primary .form-filled .form-label,
.context-dark .form-filled .form-input,
.bg-gray-700 .form-filled .form-input,
.bg-blue-13 .form-filled .form-input,
.bg-blue-15 .form-filled .form-input,
.bg-primary .form-filled .form-input,
.context-dark .form-filled .form-validation,
.bg-gray-700 .form-filled .form-validation,
.bg-blue-13 .form-filled .form-validation,
.bg-blue-15 .form-filled .form-validation,
.bg-primary .form-filled .form-validation {
	color: #ffffff;
}

.context-dark .form-filled .form-input,
.bg-gray-700 .form-filled .form-input,
.bg-blue-13 .form-filled .form-input,
.bg-blue-15 .form-filled .form-input,
.bg-primary .form-filled .form-input {
	background: rgba(255, 255, 255, 0.25);
	border-color: transparent;
}

.form-corporate {
	padding: 30px 20px;
	background: #f3f4f9;
}

.form-corporate textarea.form-input {
	height: 125px;
}

@media (min-width: 768px) {
	.form-corporate {
		padding: 30px;
	}
}

@media (min-width: 768px) {
	.form-corporate_sm {
		padding: 20px 15px;
	}
}

@media (min-width: 1200px) {
	/* .form-corporate { */
		/* padding: 35px 30px 50px; */
	/* } */
	.form-corporate h4+.form-wrap {
		margin-top: 30px;
	}
	.form-corporate_sm {
		padding: 35px 35px 50px;
	}
}

.rd-form .button-form-indicator {
	position: relative;
	min-width: 60px;
	padding: 8px;
	min-height: 50px;
}

.rd-form .button-form-indicator .icon {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	color: inherit;
}

.rd-form .button-form-indicator .form-icon-success {
	-webkit-transform: scale(0, 0);
	        transform: scale(0, 0);
	transition: .15s;
}

.rd-form.success .button-form-indicator .form-icon-default {
	-webkit-transform: scale(0, 0);
	        transform: scale(0, 0);
	transition: .15s;
}

.rd-form.success .button-form-indicator .form-icon-success {
	-webkit-transform: scale(1, 1);
	        transform: scale(1, 1);
	transition: .3s;
}

.rd-form.form-lg .button-form-indicator {
	min-width: 70px;
	min-height: 60px;
}

.form-compact .form-wrap+* {
	margin-top: 14px;
}

@media (max-height: 400px) {
	.form-compact .form-label,
	.form-compact .form-input {
		padding: 8px 15px;
		min-height: 36px;
	}
	.form-compact .form-label {
		top: 0;
		left: 0;
	}
	.form-compact .button {
		padding-top: 10px;
		padding-bottom: 10px;
		min-height: 36px;
	}
	.form-compact .form-wrap+* {
		margin-top: 8px;
	}
	.form-compact *+.form-wrap-ally {
		margin-top: 14px;
	}
}

.form-layout-search {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: -15px;
	margin-left: -15px;
}

.form-layout-search:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.form-layout-search>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 15px;
	margin-left: 15px;
}

.form-layout-search .form-wrap {
	flex: 20 0;
	min-width: 250px;
}

.form-layout-search .form-wrap-select {
	max-width: 100%;
}

.form-layout-search .form-wrap-button {
	display: inline-flex;
	flex-grow: 1;
}

.form-layout-search .form-wrap-button .button {
	width: 100%;
	min-height: inherit;
}

.form-layout-search .form-wrap-icon .icon:not(.icon-custom-sizing) {
	font-size: 20px;
}

.form-layout-search .select2-container--default .select2-selection--single .select2-selection__rendered {
	position: relative;
	top: 1px;
}

.form-layout-search .select2-container {
	display: block;
	min-width: 100%;
}

.form-layout-search.form-lg .form-label,
.form-layout-search.form-lg .form-input,
.form-layout-search.form-lg .select2-container--default .select2-selection--single .select2-selection__rendered {
	font-size: 14px;
	letter-spacing: .05em;
}

@media (min-width: 400px) {
	.form-layout-search .form-wrap {
		min-width: 270px;
	}
}

@media (min-width: 768px) {
	.form-layout-search .form-wrap-select {
		max-width: 310px;
	}
}

@media (min-width: 1200px) {
	.form-layout-search .form-wrap-button {
		min-width: 168px;
	}
	.form-layout-search .form-wrap-button-icon-only {
		min-width: 75px;
	}
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	.form-layout-search .form-wrap-button-search-lg-lg {
		font-size: 0;
		flex-grow: 0;
		min-width: 50px;
	}
	.form-layout-search .form-wrap-button-search-lg-lg .button {
		padding: 10px;
		font-size: 0;
	}
	.form-layout-search .form-wrap-button-search-lg-lg .button::before {
		content: '\e01c';
		font: 400 20px 'fl-budicons-launch';
	}
	.form-layout-search.form-lg .form-wrap-button-search-lg-lg {
		min-width: 60px;
	}
}

@media (min-width: 768px) and (max-width: 1199.98px) {
	.form-layout-search .form-wrap-button-search-md-lg {
		font-size: 0;
		flex-grow: 0;
		min-width: 50px;
	}
	.form-layout-search .form-wrap-button-search-md-lg .button {
		padding: 10px;
		font-size: 0;
	}
	.form-layout-search .form-wrap-button-search-md-lg .button::before {
		content: '\e01c';
		font: 400 20px 'fl-budicons-launch';
	}
	.form-layout-search.form-lg .form-wrap-button-search-md-lg {
		min-width: 60px;
	}
}

.form-layout-search+.row {
	margin-top: 0px;
}

.form-spacing-small *+.form-wrap {
	margin-top: 15px;
}

.form-creative-outer {
	padding: 24px 20px 20px;
	background: #f3f4f9;
}

@media (min-width: 768px) {
	.form-creative-outer .form-wrap-select {
		max-width: 280px;
	}
}

.context-dark .form-style-1 .form-label,
.bg-gray-700 .form-style-1 .form-label,
.bg-blue-13 .form-style-1 .form-label,
.bg-blue-15 .form-style-1 .form-label,
.bg-primary .form-style-1 .form-label,
.context-dark .form-style-1 .form-input,
.bg-gray-700 .form-style-1 .form-input,
.bg-blue-13 .form-style-1 .form-input,
.bg-blue-15 .form-style-1 .form-input,
.bg-primary .form-style-1 .form-input,
.context-dark .form-style-1 .form-validation,
.bg-gray-700 .form-style-1 .form-validation,
.bg-blue-13 .form-style-1 .form-validation,
.bg-blue-15 .form-style-1 .form-validation,
.bg-primary .form-style-1 .form-validation {
	color: #ffffff;
}

.context-dark .form-style-1 .form-validation,
.bg-gray-700 .form-style-1 .form-validation,
.bg-blue-13 .form-style-1 .form-validation,
.bg-blue-15 .form-style-1 .form-validation,
.bg-primary .form-style-1 .form-validation {
	opacity: .7;
}

.context-dark .form-style-1 .form-label,
.bg-gray-700 .form-style-1 .form-label,
.bg-blue-13 .form-style-1 .form-label,
.bg-blue-15 .form-style-1 .form-label,
.bg-primary .form-style-1 .form-label,
.context-dark .form-style-1 .form-input,
.bg-gray-700 .form-style-1 .form-input,
.bg-blue-13 .form-style-1 .form-input,
.bg-blue-15 .form-style-1 .form-input,
.bg-primary .form-style-1 .form-input {
	padding-left: 20px;
	padding-right: 20px;
}

.context-dark .form-style-1 .form-input,
.bg-gray-700 .form-style-1 .form-input,
.bg-blue-13 .form-style-1 .form-input,
.bg-blue-15 .form-style-1 .form-input,
.bg-primary .form-style-1 .form-input {
	background: #404851;
	border-color: #404851;
}


/*
*
* Posts
*/

.post-classic {
	padding-bottom: 20px;
	border-bottom: 1px solid #e0e0e0;
	transition: .22s ease-in;
	-webkit-transform: translate3d(0, 0, 0);
	        transform: translate3d(0, 0, 0);
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
}

.post-classic:hover {
	-webkit-transform: translate3d(0, -12px, 0);
	        transform: translate3d(0, -12px, 0);
}

.post-classic-media {
	position: relative;
	display: block;
}

.post-classic-media::after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(54, 67, 82, 0.2);
	transition: .22s ease-in;
	opacity: 0;
	visibility: hidden;
}

.post-classic-media:hover::after {
	opacity: 1;
	visibility: visible;
}

.post-classic-image {
	width: 100%;
}

.post-classic-time {
	display: block;
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 12px;
	font-weight: 600;
	line-height: 1.2;
	letter-spacing: .1em;
	color: #0A043B;
}

.post-classic-text {
	letter-spacing: .025em;
}

.post-classic-meta {
	color: #151515;
	-webkit-transform: translate3d(0, -5px, 0);
	        transform: translate3d(0, -5px, 0);
	margin-bottom: -5px;
	margin-left: -20px;
	margin-right: -20px;
}

.post-classic-meta>* {
	margin-top: 5px;
	padding-left: 20px;
	padding-right: 20px;
}

.post-classic-meta .icon {
	color: #0A043B;
}

.post-classic-meta>li {
	display: inline-block;
	line-height: 1.2;
	vertical-align: middle;
}

.post-classic-meta>li:not(:last-child) {
	border-right: 1px solid #e0e0e0;
}

.post-classic-meta>li a {
	display: block;
}

.post-classic-meta>li>a *+* {
	margin-left: 4px;
}

.post-classic-meta span {
	display: inline-block;
}

*+.post-classic-time {
	margin-top: 10px;
}

*+.post-classic-title {
	margin-top: 15px;
}

*+.post-classic-meta {
	margin-top: 15px;
}

*+.post-classic-text {
	margin-top: 16px;
}

@media (max-width: 767.98px) {
	.post-classic {
		max-width: 370px;
		margin-left: auto;
		margin-right: auto;
	}
}

@media (min-width: 768px) {
	*+.post-classic-time {
		margin-top: 12px;
	}
	*+.post-classic-title {
		margin-top: 20px;
	}
	*+.post-classic-text {
		margin-top: 24px;
	}
}

.post-creative p {
	letter-spacing: .05em;
}

.post-creative-image {
	width: 100%;
}

.post-creative-meta {
	justify-content: space-between;
	border-bottom: 1px solid #e0e0e0;
	padding: 20px 0;
}

.post-creative-meta p a {
	color: inherit;
}

.post-creative-meta p a:hover {
	color: #0A043B;
}

.post-creative-meta-inner {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: -20px;
	margin-left: -20px;
}

.post-creative-meta-inner:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.post-creative-meta-inner>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 20px;
	margin-left: 20px;
}

.post-creative-author {
	display: flex;
	align-items: center;
}

.post-creative-author>*+* {
	margin-top: 0;
	margin-left: 10px;
}

.post-creative-author-image {
	display: block;
	max-width: 36px;
	border-radius: 50%;
}

.post-creative-meta-list {
	-webkit-transform: translate3d(0, -5px, 0);
	        transform: translate3d(0, -5px, 0);
	margin-bottom: -5px;
	margin-left: -19px;
	margin-right: -19px;
	letter-spacing: .05em;
}

.post-creative-meta-list>* {
	margin-top: 5px;
	padding-left: 19px;
	padding-right: 19px;
}

.post-creative-meta-list>li {
	display: inline-block;
	vertical-align: middle;
	line-height: 1.2;
}

.post-creative-meta-list>li>* {
	display: inline-block;
	vertical-align: middle;
	line-height: 1.2;
}

.post-creative-meta-list>li>*+* {
	margin-left: 5px;
}

.post-creative-meta-list>li:not(:last-child) {
	border-right: 1px solid #e0e0e0;
}

.post-creative-meta-list .icon {
	position: relative;
	top: -1px;
	color: #151515;
}

.post-creative-meta-list .mdi-clock {
	font-size: 16px;
}

.post-creative-meta-list .fl-justicons-visible6 {
	font-size: 13px;
}

.post-creative-meta-list .mdi-message-outline {
	font-size: 15px;
}

.post-creative-footer {
	padding: 20px 25px;
	color: #151515;
	background: #f3f4f9;
}

.post-creative-footer-inner {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: -10px;
	margin-left: -10px;
}

.post-creative-footer-inner:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.post-creative-footer-inner>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 10px;
	margin-left: 10px;
}

.post-creative-footer-inner>* {
	margin-top: 0;
}

.post-creative-meta+* {
	margin-top: 20px;
}

*+.post-creative-footer {
	margin-top: 25px;
}

@media (min-width: 992px) {
	.post-creative-meta+* {
		margin-top: 32px;
	}
}

.post-light {
	display: flex;
	background: #f3f4f9;
	transition: .22s ease-in;
}

.post-light,
.post-light:active,
.post-light:focus,
.post-light:hover {
	color: #9a9a9a;
}

.post-light:hover {
	box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.1);
}

.post-light-media {
	position: relative;
	overflow: hidden;
	display: block;
	width: 106px;
	flex-shrink: 0;
}

.post-light-image {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	min-height: 100%;
	min-width: 100%;
	width: auto;
	height: auto;
	max-width: none;
}

@supports (object-fit: cover) {
	.post-light-image {
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		-webkit-transform: none;
		        transform: none;
		object-fit: cover;
		object-position: center center;
	}
}

.post-light-main {
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-grow: 1;
	padding: 18px;
}

.post-light-title {
	max-width: 170px;
	color: #151515;
}

.post-light-title a {
	color: inherit;
}

.post-light-time {
	display: block;
}

*+.post-light {
	margin-top: 16px;
}

*+.post-light-time {
	margin-top: 1px;
}

.post-minimal {
	background: #ffffff;
	transition: .3s;
}

.post-minimal p {
	letter-spacing: .025em;
}

.post-minimal:hover {
	-webkit-transform: translateY(-8px);
	        transform: translateY(-8px);
	box-shadow: 0 13px 16px 0 rgba(0, 0, 0, 0.13);
}

.post-minimal-media {
	position: relative;
	display: block;
}

.post-minimal-media::after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(255, 255, 255, 0.1);
	transition: .22s ease-in;
	opacity: 0;
	visibility: hidden;
}

.post-minimal-media:hover::after {
	opacity: 1;
	visibility: visible;
}

.post-minimal-image {
	width: 100%;
}

.post-minimal-main {
	padding: 25px 15px;
}

.post-minimal-title {
	max-width: 300px;
}

.post-minimal-title a {
	letter-spacing: 0;
}

.post-minimal-time {
	display: block;
	font: 500 14px/16px "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", rubiklight, "Helvetica Neue", Arial, sans-serif;
	color: #0A043B;
	letter-spacing: .1em;
}

@media (min-width: 576px) and (max-width: 991.98px) {
	.col-12.col-lg-4 .post-minimal {
		display: flex;
		max-width: 100%;
	}
	.col-12.col-lg-4 .post-minimal-media {
		position: relative;
		overflow: hidden;
		flex-shrink: 0;
		width: 100%;
		max-width: 44%;
	}
	.col-12.col-lg-4 .post-minimal-media img {
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		        transform: translate(-50%, -50%);
		min-height: 100%;
		min-width: 100%;
		width: auto;
		height: auto;
		max-width: none;
	}
	@supports (object-fit: cover) {
		.col-12.col-lg-4 .post-minimal-media img {
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			-webkit-transform: none;
			        transform: none;
			object-fit: cover;
			object-position: center center;
		}
	}
	.col-12.col-lg-4 .post-minimal-main {
		flex-grow: 1;
		align-self: center;
		padding: 40px 35px;
	}
}

*+.post-minimal-title {
	margin-top: 14px;
}

*+.post-minimal-time {
	margin-top: 14px;
}

@media (max-width: 575.98px) {
	.post-minimal {
		max-width: 370px;
		margin-left: auto;
		margin-right: auto;
	}
}

@media (min-width: 768px) {
	.post-minimal *+hr {
		margin-top: 25px;
	}
	.post-minimal-main {
		padding: 20px 35px 35px 40px;
	}
	*+.post-minimal-title {
		margin-top: 25px;
	}
	*+.post-minimal-time {
		margin-top: 20px;
	}
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	.post-minimal-main {
		padding: 20px 20px 30px 26px;
	}
}

.post-line {
	display: flex;
	max-width: 320px;
	align-items: flex-start;
	text-align: left;
	color: #9a9a9a;
}

.post-line-time {
	position: relative;
	display: block;
	min-width: 60px;
	padding: 3px 10px 3px 0;
	text-align: center;
}

.post-line-time::after {
	content: '';
	position: absolute;
	top: 2px;
	bottom: 8px;
	right: 0;
	width: 1px;
	background: #d6d6d6;
	pointer-events: none;
}

.post-line-time *+* {
	margin-top: 3px;
}

.post-line-time-day,
.post-line-time-month {
	display: block;
	line-height: 1;
}

.post-line-time-day {
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 33px;
	color: #151515;
}

.post-line-time-month {
	font-size: 10px;
	font-weight: 700;
	letter-spacing: .05em;
	text-transform: uppercase;
}

.post-line-text {
	padding: 3px 0 3px 20px;
	margin: 0;
	line-height: 1.71429;
	color: #151515;
	transition: .25s;
}

.post-line-text a {
	display: block;
	color: inherit;
}

.post-line-text a:hover {
	color: #0A043B;
}

*+.post-line {
	margin-top: 20px;
}

.context-dark .post-line:hover .post-line-text,
.bg-gray-700 .post-line:hover .post-line-text,
.bg-blue-13 .post-line:hover .post-line-text,
.bg-blue-15 .post-line:hover .post-line-text,
.bg-primary .post-line:hover .post-line-text {
	text-shadow: 1px 1px 10px rgba(255, 255, 255, 0.7);
}

.context-dark .post-line-text,
.bg-gray-700 .post-line-text,
.bg-blue-13 .post-line-text,
.bg-blue-15 .post-line-text,
.bg-primary .post-line-text {
	color: #ffffff;
}

.context-dark .post-line-time,
.bg-gray-700 .post-line-time,
.bg-blue-13 .post-line-time,
.bg-blue-15 .post-line-time,
.bg-primary .post-line-time {
	color: rgba(255, 255, 255, 0.5);
}

.context-dark .post-line-time::after,
.bg-gray-700 .post-line-time::after,
.bg-blue-13 .post-line-time::after,
.bg-blue-15 .post-line-time::after,
.bg-primary .post-line-time::after {
	background: rgba(255, 255, 255, 0.12);
}

.context-dark .post-line-time-day,
.bg-gray-700 .post-line-time-day,
.bg-blue-13 .post-line-time-day,
.bg-blue-15 .post-line-time-day,
.bg-primary .post-line-time-day {
	color: rgba(255, 255, 255, 0.7);
}


/*
*
* Quotes
*/

.quote-light {
	padding: 20px 15px;
	border-top: 1px solid #e0e0e0;
	border-bottom: 1px solid #e0e0e0;
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-weight: 300;
	font-size: 16px;
	line-height: 1.5;
	color: #151515;
	text-align: left;
}

.quote-light p {
	display: inline;
	margin: 0;
}

.quote-light-inner {
	position: relative;
	padding-left: 20px;
}

.quote-light-mark svg {
	fill: #e7e8ee;
}

.quote-light-mark-left {
	position: absolute;
	left: 0;
	top: 0;
	display: block;
}

.quote-light-mark-right {
	display: inline;
	vertical-align: middle;
	margin-left: 5px;
}

.quote-light-mark-right svg {
	-webkit-transform: scale(-1, -1);
	        transform: scale(-1, -1);
}

*+.quote-light {
	margin-top: 30px;
}

.quote-light+p {
	margin-top: 20px;
}

@media (max-width: 575.98px) {
	.quote-light-mark svg {
		width: 14px;
		height: 13px;
	}
}

@media (min-width: 576px) {
	.quote-light {
		font-size: 20px;
	}
	.quote-light-inner {
		padding-left: 40px;
	}
	.quote-light+p {
		margin-top: 26px;
	}
}

@media (min-width: 768px) {
	.quote-light {
		padding: 20px 30px;
		font-size: 22px;
	}
}

@media (min-width: 992px) {
	.quote-light {
		padding: 20px 45px;
		font-size: 24px;
	}
}

.quote-classic {
	position: relative;
	text-align: left;
	padding: 30px 20px;
	margin-bottom: 15px;
	box-shadow: 0 24px 21px 0 rgba(0, 0, 0, 0.08);
	background: #ffffff;
}

.quote-classic::after {
	content: '';
	position: absolute;
	left: 20px;
	bottom: -17px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 18px 26px 0 0;
	border-color: #ffffff transparent transparent transparent;
	pointer-events: none;
}

.quote-classic-mark {
	fill: #d2d4df;
}

.quote-classic-text {
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	color: #151515;
	letter-spacing: .01em;
}

.quote-classic-meta {
	display: flex;
	align-items: center;
}

.quote-classic-meta>*+* {
	margin-left: 20px;
}

.quote-classic-avatar {
	position: relative;
	display: block;
	border-radius: 50%;
	max-width: 75px;
	flex-shrink: 0;
}

.quote-classic-cite {
	display: block;
	font-weight: 500;
}

.quote-classic-info {
	flex-grow: 1;
}

.quote-classic-info>*+* {
	margin-top: 0;
}

.quote-classic-subtitle {
	letter-spacing: .025em;
	color: #0A043B;
}

*+.quote-classic-text {
	margin-top: 11px;
}

*+.quote-classic-meta {
	margin-top: 20px;
}

@media (max-width: 767.98px) {
	.quote-classic {
		padding: 30px 40px;
		max-width: 400px;
		margin-left: auto;
		margin-right: auto;
	}
	.quote-classic::after {
		left: 40px;
	}
}

@media (min-width: 768px) {
	.quote-classic-text {
		font-size: 15px;
		line-height: 1.625;
	}
}

@media (min-width: 992px) {
	.quote-classic {
		padding-left: 30px;
		padding-right: 35px;
	}
	.quote-classic::after {
		left: 30px;
	}
	.quote-classic-text {
		font-size: 14px;
	}
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	.quote-classic-avatar {
		max-width: 60px;
	}
}

@media (min-width: 1200px) {
	.quote-classic {
		padding: 40px 15px 40px 40px;
	}
	.quote-classic::after {
		left: 40px;
	}
	.quote-classic-text {
		font-size: 16px;
	}
}

.quote-modern {
	max-width: 560px;
	text-align: left;
}

.quote-modern-header {
	margin-bottom: -5px;
	margin-left: -15px;
}

.quote-modern-header:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.quote-modern-header>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 5px;
	margin-left: 15px;
}

.quote-modern-header>* {
	display: inline-block;
	vertical-align: middle;
}

.quote-modern-mark {
	position: absolute;
	top: -10px;
	left: 0;
	z-index: -1;
	fill: #d2d4df;
	opacity: .4;
}

.quote-modern-text {
	position: relative;
	z-index: 1;
	padding-left: 20px;
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	letter-spacing: .025em;
	line-height: 1.625;
	color: #151515;
}

*+.quote-modern-text {
	margin-top: 22px;
}

@media (min-width: 992px) {
	.quote-modern-mark {
		top: 8px;
		opacity: 1;
	}
	.quote-modern-text {
		padding-left: 70px;
		font-size: 15px;
	}
}

@media (min-width: 1200px) {
	.quote-modern-text {
		font-size: 16px;
	}
}

.quote-mary {
	position: relative;
	text-align: left;
	padding: 30px 20px;
	background: #f3f4f9;
}

.quote-mary-mark {
	fill: #d2d4df;
}

.quote-mary-text {
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	color: #151515;
	letter-spacing: .01em;
}

.quote-mary-meta {
	display: flex;
	align-items: center;
}

.quote-mary-meta>*+* {
	margin-left: 20px;
}

.quote-mary-avatar {
	position: relative;
	display: block;
	border-radius: 50%;
	max-width: 75px;
	flex-shrink: 0;
}

.quote-mary-cite {
	display: block;
	font-weight: 500;
}

.quote-mary-info {
	flex-grow: 1;
}

.quote-mary-info>*+* {
	margin-top: 0;
}

.quote-mary-subtitle {
	letter-spacing: .025em;
	color: #0A043B;
}

*+.quote-mary-text {
	margin-top: 11px;
}

*+.quote-mary-meta {
	margin-top: 20px;
}

.context-dark .quote-mary,
.bg-gray-700 .quote-mary,
.bg-blue-13 .quote-mary,
.bg-blue-15 .quote-mary,
.bg-primary .quote-mary {
	background: rgba(255, 255, 255, 0.1);
}

.context-dark .quote-mary-mark,
.bg-gray-700 .quote-mary-mark,
.bg-blue-13 .quote-mary-mark,
.bg-blue-15 .quote-mary-mark,
.bg-primary .quote-mary-mark {
	fill: #ffffff;
}

.context-dark .quote-mary-text,
.bg-gray-700 .quote-mary-text,
.bg-blue-13 .quote-mary-text,
.bg-blue-15 .quote-mary-text,
.bg-primary .quote-mary-text {
	color: #ffffff;
}

.context-dark .quote-mary-subtitle,
.bg-gray-700 .quote-mary-subtitle,
.bg-blue-13 .quote-mary-subtitle,
.bg-blue-15 .quote-mary-subtitle,
.bg-primary .quote-mary-subtitle {
	color: rgba(255, 255, 255, 0.6);
}

@media (max-width: 767.98px) {
	.quote-mary {
		padding: 30px 40px;
	}
}

@media (min-width: 576px) {
	.quote-mary-main {
		display: flex;
		align-items: flex-start;
	}
	.quote-mary-main>*+* {
		margin-top: 0;
		margin-left: 25px;
	}
	.quote-mary-mark {
		position: relative;
		top: 8px;
		flex-shrink: 0;
	}
}

@media (min-width: 768px) {
	.quote-mary-text {
		font-size: 15px;
		line-height: 1.625;
	}
}

@media (min-width: 992px) {
	.quote-mary {
		padding-left: 30px;
		padding-right: 35px;
	}
	.quote-mary-text {
		font-size: 14px;
	}
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	.quote-mary-avatar {
		max-width: 60px;
	}
}

@media (min-width: 1200px) {
	.quote-mary {
		padding: 50px 25px 40px 44px;
	}
	.quote-mary-text {
		font-size: 16px;
	}
	*+.quote-mary-meta {
		margin-top: 30px;
	}
}


/*
*
* Thumbnails
*/

.figure-light figcaption {
	padding-top: 10px;
	color: #b7b7b7;
}

.figure-responsive img {
	min-width: 100%;
}

.about-figure-light figcaption {
	padding-top: 10px;
	color: #b7b7b7;
}

.about-figure-responsive img {
	min-width: 100%;
}


@media (min-width: 992px) {
	.col-lg-6.height-fill .figure-responsive {
		position: relative;
		overflow: hidden;
		display: flex;
		justify-content: center;
	}
	.col-lg-6.height-fill .figure-responsive img {
		height: auto;
		width: auto;
		flex-shrink: 0;
		max-width: none;
	}

	.col-lg-6.height-fill .about-figure-responsive {
		position: relative;
		overflow: hidden;
		/* display: flex; */
		justify-content: center;
	}
	.col-lg-6.height-fill .about-figure-responsive img {
		/* height: auto; */
		/* width: auto; */
		flex-shrink: 0;
		max-width: none;
	}
}

.thumbnail-classic {
	position: relative;
	overflow: hidden;
	display: flex;
}

.thumbnail-classic-dummy,
.thumbnail-classic-caption {
	width: 100%;
	flex-shrink: 0;
}

.thumbnail-classic-dummy {
	visibility: hidden;
	opacity: 0;
}

.thumbnail-classic-dummy::before {
	content: '';
	display: block;
	padding-bottom: 72.01087%;
}

.thumbnail-classic-figure {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.thumbnail-classic-image {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	min-height: 100%;
	min-width: 100%;
	width: auto;
	height: auto;
	max-width: none;
}

@supports (object-fit: cover) {
	.thumbnail-classic-image {
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		-webkit-transform: none;
		        transform: none;
		object-fit: cover;
		object-position: center center;
	}
}

.thumbnail-classic-caption {
	position: relative;
	z-index: 1;
	align-self: flex-end;
	padding: 20px;
	text-align: center;
	background: rgba(16, 135, 235, 0.53);
}

.thumbnail-classic-title {
	color: #ffffff;
}

@media (max-width: 575.98px) {
	.thumbnail-classic {
		max-width: 370px;
		margin-left: auto;
		margin-right: auto;
	}
}

html:not(.tablet):not(.mobile) .thumbnail-classic-figure {
	transition: .44s;
}

html:not(.tablet):not(.mobile) .thumbnail-classic-caption {
	align-self: stretch;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	opacity: 0;
	visibility: hidden;
	transition: .22s;
}

html:not(.tablet):not(.mobile) .thumbnail-classic:hover .thumbnail-classic-figure {
	-webkit-transform: scale3d(1.03, 1.03, 1.03);
	        transform: scale3d(1.03, 1.03, 1.03);
}

.thumbnail-chico {
	display: block;
	position: relative;
	overflow: hidden;
	text-align: center;
	color: #ffffff;
}

.thumbnail-chico-image {
	width: 100%;
}

.thumbnail-chico-caption {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 30px;
	pointer-events: none;
	background: rgba(37, 45, 47, 0.3);
}

.thumbnail-chico-caption>* {
	max-width: 100%;
}

.thumbnail-chico-caption::before {
	content: '';
	position: absolute;
	top: 18px;
	right: 18px;
	bottom: 18px;
	left: 18px;
	border: 1px solid rgba(255, 255, 255, 0.62);
}

.thumbnail-chico-title {
	color: #ffffff;
	pointer-events: auto;
}

@media (min-width: 992px) {
	html:not(.tablet):not(.mobile) .thumbnail-chico {
		will-change: transform;
	}
	html:not(.tablet):not(.mobile) .thumbnail-chico-image {
		transition: .32s;
		will-change: transform;
		-webkit-transform: scale3d(1, 1, 1);
		        transform: scale3d(1, 1, 1);
	}
	html:not(.tablet):not(.mobile) .thumbnail-chico-caption {
		transition: .25s;
		opacity: 0;
		visibility: hidden;
		will-change: opacity;
	}
	html:not(.tablet):not(.mobile) .thumbnail-chico-caption .thumbnail-chico-title {
		transition: .3s ease;
		-webkit-transform: scale3d(0.9, 0.9, 0.9);
		        transform: scale3d(0.9, 0.9, 0.9);
	}
	html:not(.tablet):not(.mobile) .thumbnail-chico-caption::before {
		transition: .33s;
		-webkit-transform: scale3d(1.05, 1.05, 1.05);
		        transform: scale3d(1.05, 1.05, 1.05);
	}
	html:not(.tablet):not(.mobile) .thumbnail-chico:hover .thumbnail-chico-caption {
		opacity: 1;
		visibility: visible;
	}
	html:not(.tablet):not(.mobile) .thumbnail-chico:hover .thumbnail-chico-caption .thumbnail-chico-title,
	html:not(.tablet):not(.mobile) .thumbnail-chico:hover .thumbnail-chico-caption::before {
		-webkit-transform: scale3d(1, 1, 1);
		        transform: scale3d(1, 1, 1);
	}
	html:not(.tablet):not(.mobile) .thumbnail-chico:hover .thumbnail-chico-image {
		-webkit-transform: scale3d(1.03, 1.03, 1.03);
		        transform: scale3d(1.03, 1.03, 1.03);
	}
}


/*
*
* Breadcrumbs
*/

.breadcrumbs-custom+.bg-gray-100 {
	border-top: 1px solid #d6d6d6;
}

.breadcrumbs-custom-main {
	position: relative;
	padding: 5px 0;
	/* padding: 40px 0; */
	background-position: center center;
	background-size: cover;
	font-size: 28px !important;
	/* margin-top: 110px; */
}

.breadcrumbs-custom-main[style*='background-image']::before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	pointer-events: none;
	z-index: 1;
	background: rgba(54, 67, 82, 0.45);
}

.breadcrumbs-custom-main>* {
	position: relative;
	z-index: 2;
}

.breadcrumbs-custom-aside {
	padding: 15px 0;
	background: #f3f4f9;
}

.breadcrumbs-custom-path {
	margin-left: -15px;
	margin-right: -15px;
}

.breadcrumbs-custom-path>* {
	padding-left: 15px;
	padding-right: 15px;
}

.breadcrumbs-custom-path a {
	display: inline;
	vertical-align: middle;
}

.breadcrumbs-custom-path a,
.breadcrumbs-custom-path a:active,
.breadcrumbs-custom-path a:focus {
	color: #0A043B;
}

.breadcrumbs-custom-path li {
	position: relative;
	display: inline-block;
	line-height: 1.2;
	letter-spacing: .05em;
	vertical-align: middle;
}

.breadcrumbs-custom-path li::after {
	content: '';
	position: absolute;
	width: 1px;
	height: 100%;
	right: 0;
	top: 50%;
	-webkit-transform: translate3d(0, -50%, 0);
	        transform: translate3d(0, -50%, 0);
	background: rgb(150, 145, 171);
}

.breadcrumbs-custom-path li:last-child:after {
	display: none;
}

.breadcrumbs-custom-path a:hover,
.breadcrumbs-custom-path li.active {
	color: #9a9a9a;
}

@media (min-width: 576px) {
	.breadcrumbs-custom-aside {
		padding: 23px 0;
	}
}

@media (min-width: 768px) {
	.breadcrumbs-custom-main {
		margin-top: 110px;
	}
}


/*
*
* Pagination custom
*/

.pagination {
	display: inline-flex;
	flex-wrap: wrap;
	font-size: 0;
	line-height: 0;
	margin-bottom: -6px;
	margin-left: -6px;
}

.pagination:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.pagination>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 6px;
	margin-left: 6px;
}

.page-item {
	display: inline-flex;
	vertical-align: middle;
	text-align: center;
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: .05em;
	text-transform: uppercase;
}

.page-link {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 36px;
	padding: 5px 12px;
	border: 1px solid #e0e0e0;
	line-height: 2;
	background-color: #ffffff;
	color: #000000;
	transition: .15s;
}

.page-link:hover,
.page-link:focus,
.page-link:active {
	color: #ffffff;
	background-color: #0A043B;
	border-color: #0A043B;
	box-shadow: none;
}

.page-item.active>.page-link,
.page-item.active>.page-link:hover,
.page-item.active>.page-link:focus,
.page-item.active>.page-link:active {
	color: #ffffff;
	background-color: #0A043B;
	border-color: #0A043B;
}

.page-item.disabled>.page-link,
.page-item.disabled>.page-link:hover,
.page-item.disabled>.page-link:focus,
.page-item.disabled>.page-link:active {
	color: #b7b7b7;
	background-color: #e0e0e0;
	border-color: #e0e0e0;
}

.page-item-control .page-link {
	padding-left: 16px;
	padding-right: 16px;
}

*+.pagination-outer {
	margin-top: 30px;
}

@media (max-width: 767.98px) {
	.page-item-control {
		font-size: 0;
	}
	.page-item-control .page-link {
		padding-left: 10px;
		padding-right: 10px;
	}
	.page-item-control .page-link::before {
		font-family: "Material Design Icons";
		font-size: 18px;
		line-height: 1;
	}
	.page-item-control:first-child .page-link::before {
		content: '\f237';
	}
	.page-item-control:last-child .page-link::before {
		content: '\f238';
	}
}

@media (min-width: 768px) {
	*+.pagination-outer {
		margin-top: 50px;
	}
}


/*
*
* Snackbars
*/

.snackbars {
	padding: 9px 16px;
	margin-left: auto;
	margin-right: auto;
	color: #ffffff;
	text-align: left;
	background-color: #ca5217;
	border-radius: 0;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
	font-size: 14px;
}

.snackbars .icon-xxs {
	font-size: 18px;
}

.snackbars p span:last-child {
	padding-left: 14px;
}

.snackbars-left {
	display: inline-block;
	margin-bottom: 0;
}

.snackbars-right {
	display: inline-block;
	float: right;
	text-transform: uppercase;
}

.snackbars-right:hover {
	text-decoration: underline;
}

@media (min-width: 576px) {
	.snackbars {
		max-width: 540px;
		padding: 12px 15px;
		font-size: 15px;
	}
}


/*
*
* Footers
*/

.footer-classic p {
	color: #56616c;
}

.footer-classic .list-marked-1 {
	color: #ffffff;
}

.footer-classic .list-marked-1>li::before {
	border-color: transparent transparent transparent #4c5762;
}

.footer-classic-main {
	padding: 35px 0;
	background: #303841;
}

.footer-classic-main a {
	color: #ffffff;
}

.footer-classic-main a:hover {
	color: #0A043B;
}

.footer-classic-aside {
	padding: 25px 0;
	background: #29323a;
	text-align: center;
}

.footer-classic-aside * {
	color: #56616c;
}

.footer-classic-aside .rights a:hover {
	color: #0A043B;
}

.footer-classic-title {
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 16px;
	line-height: 1.4;
	letter-spacing: .025em;
}

p.footer-classic-title {
	color: #ffffff;
}

.footer-classic-divider {
	font-size: 0;
	line-height: 0;
	padding: 0 3px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

.footer-classic-divider::before {
	content: '';
	display: inline-block;
	height: 2px;
	width: 40px;
	margin-bottom: -1px;
	background: #0A043B;
}

*+.footer-classic-divider {
	margin-top: 16px;
}

.footer-classic .footer-classic-divider+* {
	margin-top: 16px;
}

@media (min-width: 768px) {
	.footer-classic-main {
		padding: 60px 0 70px;
	}
	.footer-classic-title {
		font-size: 20px;
	}
	*+.footer-classic-divider {
		margin-top: 20px;
	}
	.footer-classic .footer-classic-divider+* {
		margin-top: 24px;
	}
}

.footer-modern p {
	color: #56616c;
}

.footer-modern .list-marked-1 {
	color: #ffffff;
}

.footer-modern .list-marked-1>li::before {
	border-color: transparent transparent transparent #4c5762;
}

.footer-modern-main {
	padding: 35px 0;
	background: #303841;
}

.footer-modern-main a {
	color: #ffffff;
}

.footer-modern-main a:hover {
	color: #0A043B;
}

.footer-modern-title {
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 16px;
	line-height: 1.4;
	letter-spacing: .025em;
}

p.footer-modern-title {
	color: #ffffff;
}

.footer-modern-divider {
	font-size: 0;
	line-height: 0;
	padding: 0 3px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

.footer-modern-aside {
	padding: 25px 0;
	background: #29323a;
}

.footer-modern-aside * {
	color: #56616c;
}

.footer-modern-aside .rights a:hover {
	color: #0A043B;
}

.footer-modern-aside-inner {
	margin-bottom: -10px;
	text-align: center;
}

.footer-modern-aside-inner>* {
	margin-bottom: 10px;
}

*+.footer-modern-divider {
	margin-top: 16px;
}

.footer-modern .footer-modern-divider+* {
	margin-top: 16px;
}

@media (min-width: 576px) {
	.footer-modern-aside-inner {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		margin-left: -20px;
	}
	.footer-modern-aside-inner>* {
		margin-left: 20px;
	}
}

@media (min-width: 768px) {
	.footer-modern-main {
		padding: 70px 0 95px;
	}
	*+.footer-modern-divider {
		margin-top: 20px;
	}
	.footer-modern .footer-modern-divider+* {
		margin-top: 24px;
	}
}

@media (min-width: 1200px) {
	.footer-modern-title {
		font-size: 20px;
	}
}

.footer-creative .list-marked-1 {
	color: #ffffff;
}

.footer-creative .list-marked-1>li::before {
	border-color: transparent transparent transparent #ffffff;
}

.footer-creative .list-marked-1 a:hover,
.footer-creative .list-icons a:hover {
	color: #ffffff;
	text-shadow: 1px 1px 10px rgba(255, 255, 255, 0.7);
}

.footer-creative .list-marked-1 .icon,
.footer-creative .list-icons .icon {
	text-shadow: none;
}

.footer-creative .post-line-time,
.footer-creative .post-line-time-day {
	color: #ffffff;
}

.footer-creative-main {
	padding: 25px 0;
	background: #0A043B;
}

.footer-creative-main a {
	color: #ffffff;
}

.footer-creative-title {
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
	text-transform: uppercase;
	letter-spacing: .05em;
	text-align: center;
}

p.footer-creative-title {
	color: #ffffff;
}

.footer-creative-divider {
	font-size: 0;
	line-height: 0;
	padding: 0 3px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

.footer-creative-aside {
	padding: 15px 0;
	/* background: #0076d1; */
	background: #ffffff;
	text-align: center;
}

.footer-creative-aside * {
	color: rgba(255, 255, 255, 0.4);
}

.footer-creative-aside .rights a:hover {
	color: #ffffff;
}

*+.footer-creative-divider {
	margin-top: 16px;
}

.footer-creative .footer-creative-divider+* {
	margin-top: 16px;
}

@media (min-width: 768px) {
	.footer-creative-main {
		/* padding: 70px 0 95px; */
	}
	*+.footer-creative-divider {
		margin-top: 20px;
	}
	.footer-creative .footer-creative-divider+* {
		margin-top: 24px;
	}
}

@media (min-width: 1200px) {
	.footer-creative-title {
		font-size: 16px;
	}
}


/*
*
* Layouts
*/

.layout-bordered {
	text-align: center;
}

.layout-bordered-item {
	padding: 20px 0;
	border: 0 solid #e0e0e0;
}

.layout-bordered-item-inner {
	padding: 0 15px;
	margin-left: auto;
	margin-right: auto;
	text-align: left;
}

.layout-bordered-item-inner>*+* {
	margin-top: 10px;
}

*+.layout-bordered {
	margin-top: 40px;
}

.layout-bordered+.row {
	margin-top: 40px;
}

@media (max-width: 767.98px) {
	.layout-bordered-item {
		padding: 30px 0;
	}
	.layout-bordered-item:first-child {
		padding-top: 0;
	}
	.layout-bordered-item:last-child {
		padding-bottom: 0;
	}
	.layout-bordered-item .icon.unit-left {
		min-width: 30px;
	}
	.layout-bordered-item .unit-left.icon-lg {
		font-size: 20px;
	}
	.layout-bordered-item .unit-left.icon-xl {
		font-size: 25px;
	}
	.layout-bordered-item+.layout-bordered-item {
		border-top-width: 1px;
	}
}

@media (min-width: 768px) {
	.layout-bordered {
		display: flex;
	}
	.layout-bordered-item {
		padding: 0;
		flex-grow: 1;
	}
	.layout-bordered-item-inner>*+* {
		margin-top: 15px;
	}
	.layout-bordered-item+.layout-bordered-item {
		border-top-width: 0;
	}
	*+.layout-bordered {
		margin-top: 50px;
	}
	.layout-bordered+.row {
		margin-top: 50px;
	}
}

@media (min-width: 768px) {
	.layout-bordered-item {
		flex-basis: 33.333%;
	}
	.layout-bordered-item:last-child {
		border-right-width: 0;
	}
	.layout-bordered+.row {
		margin-top: 65px;
	}
}

@media (min-width: 992px) {
	.layout-bordered-item {
		border-right-width: 1px;
	}
	.layout-bordered-item-inner {
		max-width: 250px;
	}
}

.blog-layout-aside {
	margin-bottom: -35px;
}

.blog-layout-aside>* {
	margin-bottom: 35px;
}

.blog-layout-aside *+.rd-form {
	margin-top: 20px;
}

.blog-layout-aside-item .blog-layout-title+* {
	margin-top: 15px;
}

.blog-layout-aside-item .blog-layout-title+p {
	margin-top: 10px;
}

.blog-layout-title {
	font: 600 13px/18px "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", rubiklight, "Helvetica Neue", Arial, sans-serif;
	letter-spacing: .1em;
	text-transform: uppercase;
	line-height: 1.4;
	color: #151515;
}

*+.blog-layout-aside {
	margin-top: 55px;
}

@media (min-width: 768px) {
	*+.blog-layout-aside {
		margin-top: 70px;
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.blog-layout-aside {
		-webkit-columns: 2;
		        columns: 2;
		grid-column-gap: 30px;
		-webkit-column-gap: 30px;
		        column-gap: 30px;
		page-break-inside: avoid;
		-webkit-column-break-inside: avoid;
		        break-inside: avoid;
	}
	.blog-layout-aside-item {
		display: inline-block;
		width: 100%;
	}
	*+.blog-layout-aside-item {
		margin-top: 0;
	}
}

@media (min-width: 992px) {
	.blog-layout {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		margin-left: -30px;
	}
	.blog-layout>* {
		margin-left: 30px;
	}
	.blog-layout-main {
		flex-grow: 1;
	}
	.blog-layout-aside {
		flex-shrink: 0;
		width: 26.5%;
		margin-bottom: -45px;
	}
	.blog-layout-aside>* {
		margin-bottom: 45px;
	}
	*+.blog-layout-aside {
		margin-top: 0;
	}
}

@media (min-width: 1200px) {
	.blog-layout {
		margin-left: -70px;
	}
	.blog-layout>* {
		margin-left: 70px;
	}
}

.layout-info {
	padding-bottom: 20px;
	border-bottom: 1px solid rgb(150, 145, 171);
}

.layout-info>*+* {
	margin-top: 25PX;
}

.layout-info-aside {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: -8px;
	margin-left: -20px;
}

.layout-info-aside:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.layout-info-aside>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 8px;
	margin-left: 20px;
}

.layout-info-aside>*+* {
	margin-top: 0;
}

.layout-info-aside-item .link {
	letter-spacing: .05em;
}

@media (min-width: 576px) {
	.layout-info {
		display: flex;
	}
	.layout-info>*+* {
		position: relative;
		margin-top: 0;
	}
	.layout-info>*+*::before {
		content: '';
		position: absolute;
		left: 0;
		top: 50%;
		-webkit-transform: translate3d(0, -50%, 0);
		        transform: translate3d(0, -50%, 0);
		height: 50%;
		border-right: 1px solid rgb(150, 145, 171);
	}
	.layout-info-main,
	.layout-info-aside {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.layout-info-main {
		min-width: 64%;
	}
	.layout-info-aside {
		min-width: 36%;
		align-items: center;
	}
}

@media (min-width: 768px) {
	.layout-info-main {
		min-width: 52%;
	}
	.layout-info-aside {
		min-width: 36%;
	}
}

.layout-details {
	padding-bottom: 20px;
	border-bottom: 1px solid rgb(150, 145, 171);
}

.layout-details>*+* {
	margin-top: 25px;
}

.layout-1 {
	padding: 18px 25px;
	background: #f3f4f9;
}

.layout-1-inner {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	margin-bottom: -15px;
	margin-left: -15px;
}

.layout-1-inner:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.layout-1-inner>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 15px;
	margin-left: 15px;
}

.layout-1-inner>*+* {
	margin-top: 0;
}

*+.layout-1 {
	margin-top: 25px;
}

@media (min-width: 768px) {
	*+.layout-1 {
		margin-top: 30px;
	}
}

.layout-2 {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	margin-bottom: -65px;
	text-align: center;
	background-color: #FAFBFD;
}

.layout-2>* {
	margin-bottom: 35px;
}

.layout-2>* {
	flex-shrink: 0;
	max-width: 100%;
}

@media (min-width: 768px) {
	.layout-2 {
		flex-wrap: nowrap;
		justify-content: space-between;
		/* margin-left: -135px;
		margin-right: -100px; */
		margin-top: -30px;
		text-align: left;
	}
	.layout-2>* {
		margin-left: 35px;
	}
	.layout-2>* {
		flex-shrink: 1;
	}
	.layout-2-item-main {
		/* min-width: 425px; */
	}
}

.layout-3 {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	margin-bottom: -10px;
	margin-left: -10px;
}

.layout-3:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.layout-3>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 10px;
	margin-left: 10px;
}

.grid-1-outer {
	position: relative;
	overflow: hidden;
	border: 1px solid transparent;
}

.grid-1 {
	display: flex;
	flex-wrap: wrap;
	margin: -2px;
	width: calc(100% + 6px);
}

.grid-1-item {
	display: flex;
	width: 50%;
	border: 1px solid #d6d6d6;
	margin: -1px -1px 0 0;
}

.grid-1-item-link {
	display: flex;
	padding: 23px 20px;
	min-height: 100px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	transition: .6s;
}

.grid-1-item-link:hover {
	background: rgba(0, 0, 0, 0.03);
}

.grid-1-item-link img {
	max-width: 90%;
}

*+.grid-1-outer {
	margin-top: 30px;
}

@media (min-width: 576px) {
	.grid-1-item {
		width: 33.33333%;
	}
}

@media (min-width: 768px) {
	.grid-1-item-link {
		padding: 40px 20px 40px 30px;
	}
}

@media (min-width: 992px) {
	.grid-1-item {
		width: 25%;
	}
	*+.grid-1-outer {
		margin-top: 50px;
	}
}

@media (min-width: 1200px) {
	.grid-1-item-link {
		min-height: 170px;
	}
}


/*
*
* Pricing Tables
*/

.pt-title {
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 16px;
	line-height: 1.4;
	font-weight: 600;
	letter-spacing: .025em;
	text-transform: uppercase;
	color: #151515;
}

.pt-price {
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 36px;
	line-height: 1;
	font-weight: 300;
	color: #151515;
}

.pt-price-small {
	position: relative;
	top: .25em;
	vertical-align: top;
	font-size: 0.47143em;
}

.context-dark .pt-title,
.bg-gray-700 .pt-title,
.bg-blue-13 .pt-title,
.bg-blue-15 .pt-title,
.bg-primary .pt-title,
.context-dark .pt-price,
.bg-gray-700 .pt-price,
.bg-blue-13 .pt-price,
.bg-blue-15 .pt-price,
.bg-primary .pt-price {
	color: #ffffff;
}

*+.pt-price {
	margin-top: 16px;
}

@media (max-width: 767.98px) {
	.pt {
		max-width: 370px;
		margin-left: auto;
		margin-right: auto;
	}
}

@media (min-width: 768px) {
	.pt-price {
		font-size: 56px;
	}
}

@media (min-width: 1200px) {
	.pt-price {
		font-size: 70px;
	}
}

.pt-classic {
	border: 1px solid #e0e0e0;
	text-align: center;
}

.pt-classic-header {
	padding: 30px 20px;
	background: #f3f4f9;
}

.pt-classic-item {
	margin-bottom: -15px;
	margin-left: -15px;
}

.pt-classic-item:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.pt-classic-item>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 15px;
	margin-left: 15px;
}

.pt-classic-item>* {
	display: inline-block;
	vertical-align: middle;
}

.pt-classic-main {
	padding: 20px;
}

.pt-classic-main .button {
	padding-top: 18px;
	padding-bottom: 18px;
}

.pt-classic-features>li+li {
	margin-top: 12px;
}

@media (min-width: 1200px) {
	.pt-classic-header {
		padding: 38px;
	}
	.pt-classic-main {
		padding: 30px 40px 50px;
	}
}

.pt-creative {
	padding: 30px 20px;
	text-align: center;
	border: 4px solid rgba(16, 135, 235, 0.33);
}

.pt-creative .button {
	padding-top: 18px;
	padding-bottom: 18px;
}

.pt-creative .button-primary-outline:hover {
	box-shadow: 0 7px 18px 0 rgba(0, 0, 0, 0.18);
}

.pt-creative_preferred {
	border-color: #0A043B;
}

.pt-creative-features {
	text-align: center;
}

.pt-creative-features .list {
	display: inline-block;
}

.bg-primary .pt-creative {
	border-color: rgba(255, 255, 255, 0.33);
}

.bg-primary .pt-creative_preferred {
	border-color: #ffffff;
}

*+.pt-creative-group {
	margin-top: 40px;
}

*+.pt-creative {
	margin-top: 40px;
}

*+.pt-creative-features {
	margin-top: 15px;
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.pt-creative-group {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-bottom: -30px;
		margin-left: -30px;
	}
	.pt-creative-group:empty {
		margin-bottom: 0;
		margin-left: 0;
	}
	.pt-creative-group>* {
		display: inline-block;
		margin-top: 0;
		margin-bottom: 30px;
		margin-left: 30px;
	}
	.pt-creative-group .pt-creative {
		width: calc(50% - 30px);
	}
}

@media (min-width: 992px) {
	.pt-creative-group {
		display: flex;
		max-width: 1100px;
		margin-left: auto;
		margin-right: auto;
	}
	.pt-creative-group .pt-creative {
		width: calc(33.3% + 4px);
	}
	.pt-creative-group .pt.pt-creative {
		margin-top: 10px;
	}
	.pt-creative-group .pt.pt-creative_preferred {
		width: 34%;
		border-width: 6px;
		margin: 0 -6px -10px;
	}
	.pt-creative-group *+.pt-creative {
		margin-top: 0;
	}
	.pt-creative {
		padding: 30px 25px 40px;
	}
	.pt-creative *+.button {
		margin-top: 30px;
	}
	.pt-creative .pt-title+.pt-price {
		margin-top: 23px;
	}
	.pt-creative_preferred {
		padding: 40px 25px 30px;
	}
	*+.pt-creative-features {
		margin-top: 35px;
	}
}

@media (min-width: 1200px) {
	.pt-creative {
		padding: 40px 25px 50px;
	}
	.pt-creative_preferred {
		padding: 50px 25px 35px;
	}
}

.pt-modern {
	padding: 30px 20px;
	text-align: center;
	border: 1px solid #e0e0e0;
}

.pt-modern .button {
	padding-top: 18px;
	padding-bottom: 18px;
}

.pt-modern-item {
	margin-bottom: -15px;
	margin-left: -15px;
}

.pt-modern-item:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.pt-modern-item>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 15px;
	margin-left: 15px;
}

.pt-modern-item>* {
	display: inline-block;
	vertical-align: middle;
}

.pt-modern-features {
	text-align: center;
}

.pt-modern-features .list {
	position: relative;
	left: -10px;
	display: inline-block;
}

*+.pt-modern-group {
	margin-top: 40px;
}

*+.pt-modern {
	margin-top: 40px;
}

*+.pt-modern-features {
	margin-top: 15px;
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.pt-modern-group {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-bottom: -30px;
		margin-left: -30px;
	}
	.pt-modern-group:empty {
		margin-bottom: 0;
		margin-left: 0;
	}
	.pt-modern-group>* {
		display: inline-block;
		margin-top: 0;
		margin-bottom: 30px;
		margin-left: 30px;
	}
	.pt-modern-group .pt-modern {
		width: calc(50% - 30px);
	}
}

@media (min-width: 992px) {
	.pt-modern-group {
		display: flex;
		padding: 30px 0;
		border: 1px solid #e0e0e0;
	}
	.pt-modern-group .pt-modern {
		width: 33.3%;
		padding-top: 0;
		padding-bottom: 0;
		border-width: 0 1px 0 0;
	}
	.pt-modern-group .pt-modern:last-child {
		border-width: 0;
	}
	.pt-modern-group *+.pt-modern {
		margin-top: 0;
	}
	.pt-modern-group *+.pt-price {
		margin-top: 22px;
	}
	.pt-modern {
		padding: 30px 25px 40px;
	}
	.pt-modern *+.button {
		margin-top: 30px;
	}
	.pt-modern .pt-title+.pt-price {
		margin-top: 23px;
	}
	*+.pt-modern-features {
		margin-top: 35px;
	}
}

@media (min-width: 1200px) {
	.pt-modern-group {
		padding: 55px 0;
	}
	.pt-modern {
		padding: 40px 25px 50px;
	}
}


/*
*
* Badge Custom
*/

.badge {
	display: inline-block;
	padding: 3px 6px 4px;
	font: 500 12px/12px 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	text-transform: uppercase;
	letter-spacing: .1em;
	color: #ffffff;
	background: #0A043B;
	border-radius: 0;
	vertical-align: middle;
}

.badge-secondary {
	/* background: #ca5217; */
	background: #494970;
}

.badge-tertiary {
	/* background: #7959b6; */
	background: #9798AD;
}

.badge-blue-11 {
	background: #437685;
}

.badge-danger {
	background: #d2691e;
}

.badge-brand-color {
	background: #0A043B;
}

.badge-modern {
	position: relative;
	z-index: 1;
	padding-left: 15px;
	overflow: hidden;
	background-color: transparent;
}

.badge-modern::before {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 10px;
	z-index: -1;
	background: #0A043B;
	pointer-events: none;
}

.badge-modern span {
	position: absolute;
	top: 10px;
	bottom: 0;
	right: 0;
	left: 0;
	-webkit-transform-origin: 0 50%;
	        transform-origin: 0 50%;
	-webkit-transform: scale(1, 1.3);
	        transform: scale(1, 1.3);
	z-index: -1;
	pointer-events: none;
}

.badge-modern span::after {
	content: '';
	position: absolute;
	bottom: 0;
	left: -2px;
	width: 100px;
	height: 100px;
	-webkit-transform-origin: 12px 100%;
	        transform-origin: 12px 100%;
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
	background: #0A043B;
	z-index: -2;
}

.badge-modern.badge-secondary::before,
.badge-modern.badge-secondary span:after {
	background: #ca5217;
}

.badge-modern.badge-tertiary::before,
.badge-modern.badge-tertiary span:after {
	background: #7959b6;
}


/*
*
* Alert Custom
*/

.alert {
	background: rgba(16, 135, 235, 0.1);
	border: 1px solid #e0e0e0;
	border-radius: 0;
}

.alert-type-1 {
	padding: 20px 20px 20px 30px;
}

.alert-type-1 .alert-inner {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: -20px;
	margin-left: -20px;
}

.alert-type-1 .alert-inner:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.alert-type-1 .alert-inner>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 20px;
	margin-left: 20px;
}

.alert-type-1 .alert-main {
	max-width: 595px;
	letter-spacing: .025em;
	color: #151515;
}

@media (min-width: 768px) {
	.alert-type-1 .alert-main {
		font-size: 16px;
		line-height: 1.5;
	}
}

.comment {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	text-align: left;
	margin-bottom: -10px;
	margin-left: -20px;
}

.comment:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.comment>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 10px;
	margin-left: 20px;
}

.comment-group .comment-group {
	padding-left: 20px;
}

.comment-group .comment-group .comment-group {
	padding-left: 10px;
}

.comment-main {
	width: 100%;
}

.comment-image {
	max-width: 100px;
	border-radius: 50%;
}

.comment-title {
	font: 500 16px/24px "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", rubiklight, "Helvetica Neue", Arial, sans-serif;
	letter-spacing: .05em;
	color: #151515;
}

.comment-time {
	display: block;
	font-weight: 500;
	color: #151515;
}

.comment-list {
	-webkit-transform: translate3d(0, -10px, 0);
	        transform: translate3d(0, -10px, 0);
	margin-bottom: -10px;
	margin-left: -10px;
	margin-right: -10px;
	font-size: 13px;
	font-weight: 500;
	color: #151515;
}

.comment-list>* {
	margin-top: 10px;
	padding-left: 10px;
	padding-right: 10px;
}

.comment-list>li {
	display: inline-block;
	vertical-align: middle;
}

.comment-list>li * {
	vertical-align: middle;
}

.comment-list>li *+* {
	margin-left: 8px;
}

.comment-list>li>a {
	transition: color .22s;
}

.comment-list>li>a:hover,
.comment-list>li>a:hover .icon {
	color: #0A043B;
}

.comment-list .icon {
	margin-top: 1px;
	font-size: 16px;
	color: #9fa3a7;
	transition: color .22s;
}

.comment-list .mdi-comment-outline {
	margin-top: 2px;
}

.comment-link {
	letter-spacing: .1em;
	font-weight: 500;
	color: #151515;
}

.comment-group {
	position: relative;
	padding-bottom: 30px;
}

.comment-group::before {
	content: '';
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	border-bottom: 1px solid #e0e0e0;
}

.comment-group .comment-group {
	padding-bottom: 0;
}

.comment-group .comment-group::before {
	display: none;
}

.comment-group .comment-group .comment img {
	max-width: 52px;
}

*+.comment-text {
	margin-top: 8px;
}

*+.comment-footer {
	margin-top: 14px;
}

*+.comment-group {
	margin-top: 30px;
}

.comment+* {
	margin-top: 25px;
}

.comment-group+.comment-group {
	margin-top: 30px;
}

@media (min-width: 576px) {
	.comment-header {
		flex-grow: 1;
	}
	.comment-header-inner {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		-webkit-transform: translate3d(0, -10px, 0);
		        transform: translate3d(0, -10px, 0);
		margin-bottom: -10px;
		margin-left: -10px;
		margin-right: -10px;
	}
	.comment-header-inner>* {
		margin-top: 10px;
		padding-left: 10px;
		padding-right: 10px;
	}
}

@media (min-width: 768px) {
	.comment {
		position: relative;
		flex-direction: column;
		padding-left: 95px;
		margin-bottom: -15px;
		margin-left: 0;
	}
	.comment:empty {
		margin-bottom: 0;
		margin-left: 0;
	}
	.comment>* {
		display: inline-block;
		margin-top: 0;
		margin-bottom: 15px;
		margin-left: 0;
	}
	.comment .comment-aside {
		position: absolute;
		top: 0;
		left: 0;
	}
	.comment p {
		letter-spacing: .05em;
	}
	.comment-header {
		width: 100%;
	}
	.comment-group {
		max-width: 850px;
	}
	.comment-group::before {
		left: 100px;
	}
	.comment-group .comment-group {
		padding-left: 100px;
	}
	.comment-group .comment-group .comment {
		padding-left: 75px;
	}
	.comment-group .comment-group .comment .comment-aside {
		margin-top: 10px;
	}
	.comment-group .comment-group .comment-group {
		padding-left: 50px;
	}
	.comment-header {
		padding-right: 20px;
	}
	.comment-header>*+* {
		margin-left: 20px;
	}
	.comment-list {
		-webkit-transform: translate3d(0, -10px, 0);
		        transform: translate3d(0, -10px, 0);
		margin-bottom: -10px;
		margin-left: -13px;
		margin-right: -13px;
	}
	.comment-list>* {
		margin-top: 10px;
		padding-left: 13px;
		padding-right: 13px;
	}
}

@media (min-width: 1200px) {
	.comment-group {
		max-width: 850px;
		padding-bottom: 40px;
	}
	.comment-group .comment-group {
		padding-left: 135px;
	}
	.comment-group .comment-group .comment-group {
		padding-left: 50px;
	}
	.comment-group+.comment-group {
		margin-top: 45px;
	}
}

.comment-box {
	max-width: 830px;
}

.comment-box .unit-body {
	flex-grow: 1;
}

.comment-box textarea.form-input {
	height: 145px;
}

.comment-box *+.rd-mailform {
	margin-top: 20px;
}

.comment-box-image {
	border-radius: 50%;
}

*+.comment-box {
	margin-top: 30px;
}

@media (min-width: 768px) {
	*+.comment-box {
		margin-top: 42px;
	}
}


/*
*
* Profiles
*/

.profile-minimal {
	padding: 16px;
	border: 1px solid #e0e0e0;
}

.profile-minimal-inner {
	margin-bottom: -16px;
	margin-left: -16px;
}

.profile-minimal-inner:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.profile-minimal-inner>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 16px;
	margin-left: 16px;
}

.profile-minimal-main-outer {
	flex-grow: 1;
}

.profile-minimal-main {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: -16px;
	margin-left: -16px;
}

.profile-minimal-main:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.profile-minimal-main>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 16px;
	margin-left: 16px;
}

.profile-minimal-main p {
	letter-spacing: .025em;
}

.profile-minimal-image {
	max-width: 70px;
	flex-shrink: 0;
}

.profile-minimal-main-info>*+* {
	margin-top: 4px;
}

.profile-minimal-position {
	color: #0A043B;
}

p.profile-minimal-position {
	letter-spacing: .05em;
}

.profile-minimal-meta {
	min-width: 30%;
	letter-spacing: .05em;
	white-space: nowrap;
}

.profile-minimal-meta>* {
	display: inline-block;
	vertical-align: top;
	white-space: normal;
}

.profile-minimal-meta>*+* {
	margin-left: 4px;
}

.profile-minimal-meta .icon {
	position: relative;
	top: 2px;
	font-size: 16px;
	color: #0A043B;
}

.profile-minimal+.profile-minimal {
	border-top: 0;
	margin-top: 0;
}

*+.profile-minimal-position {
	margin-top: 0;
}

@media (min-width: 576px) {
	.profile-minimal-meta {
		max-width: 200px;
	}
}

@media (min-width: 576px) {
	.profile-minimal-inner {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
	}
}

@media (min-width: 768px) {
	.profile-minimal-image {
		min-width: 101px;
	}
}

.profile-classic {
	display: block;
	max-width: 265px;
	margin-left: auto;
	margin-right: auto;
	box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.15);
	background: #ffffff;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
	transition: .22s;
}

.profile-classic,
.profile-classic:active,
.profile-classic:focus,
.profile-classic:hover {
	color: #9a9a9a;
}

.profile-classic:hover {
	-webkit-transform: translateY(-10px);
	        transform: translateY(-10px);
	box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.15);
}

.profile-classic-image {
	width: 100%;
}

.profile-classic-main {
	padding: 20px 15px 25px;
}

.profile-classic-list {
	white-space: nowrap;
	letter-spacing: .05em;
}

.profile-classic-list>li>* {
	display: inline-block;
	vertical-align: top;
	white-space: normal;
}

.profile-classic-list>li>*+* {
	margin-left: 3px;
}

.profile-classic-list .icon {
	position: relative;
	top: 3px;
	font-size: 16px;
	color: #0A043B;
}

*+.profile-classic-list {
	margin-top: 12px;
}

@media (min-width: 992px) {
	.profile-classic-main {
		padding: 20px 20px 30px 20px;
	}
	.profile-classic-list .icon {
		font-size: 18px;
	}
}

@media (min-width: 1200px) {
	.profile-classic-main {
		padding: 25px 30px 35px;
	}
}

.profile-light {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	margin-bottom: -15px;
	margin-left: -25px;
	text-align: left;
}

.profile-light:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.profile-light>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 15px;
	margin-left: 25px;
}

.profile-light-image,
.profile-light-main {
	max-width: 100px;
}

.profile-light-image {
	/* width: 100%; */
	width: 90px;
	height: auto;
	flex-shrink: 0;
	border-radius: 5px;
	/* box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.24); */
}

.profile-light-main {
	flex-grow: 1;
	display: flex;
}

.profile-light-position {
	color: #0A043B;
}

.profile-light-divider {
	border-bottom: 1px solid #e0e0e0;
}

.profile-light-list>li {
	white-space: nowrap;
}

.profile-light-list>li>* {
	display: inline-block;
	vertical-align: middle;
	white-space: normal;
}

.profile-light-list>li>*+* {
	margin-left: 5px;
}

.profile-light-list .icon {
	color: #0A043B;
}

.profile-light-list>li+li {
	margin-top: 5px;
}

*+.profile-light-divider {
	margin-top: 18px;
}

*+.profile-light-list {
	/* margin-top: 18px; */
	margin-left: 20px;
	text-align: left;
}

.context-dark .profile-light-position,
.bg-gray-700 .profile-light-position,
.bg-blue-13 .profile-light-position,
.bg-blue-15 .profile-light-position,
.bg-primary .profile-light-position {
	color: #0A043B;
}

.context-dark .profile-light-divider,
.bg-gray-700 .profile-light-divider,
.bg-blue-13 .profile-light-divider,
.bg-blue-15 .profile-light-divider,
.bg-primary .profile-light-divider {
	border-bottom-color: rgba(255, 255, 255, 0.16);
}

.context-dark .profile-light-list,
.bg-gray-700 .profile-light-list,
.bg-blue-13 .profile-light-list,
.bg-blue-15 .profile-light-list,
.bg-primary .profile-light-list {
	color: rgba(255, 255, 255, 0.5);
}

.bg-primary .profile-light-position {
	color: #ffffff;
}

.bg-primary .profile-light-list .icon {
	color: #ffffff;
}

@media (min-width: 768px) {
	.profile-light {
		justify-content: flex-start;
		/* max-width: 400px; */
	}
	.profile-light-image {
		max-width: 178px;
	}
	.profile-light-main {
		max-width: 100%;
	}
}

.profile-modern {
	position: relative;
	overflow: hidden;
	display: block;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
	text-align: left;
}

.profile-modern,
.profile-modern:active,
.profile-modern:focus,
.profile-modern:hover {
	color: #9a9a9a;
}

.profile-modern:hover {
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.12);
}

.profile-modern:hover .profile-modern-title {
	color: #0A043B;
}

.profile-modern:hover .profile-modern-media {
	-webkit-transform: scale3d(1.05, 1.05, 1.05);
	        transform: scale3d(1.05, 1.05, 1.05);
}

.profile-modern p {
	letter-spacing: 0;
}

.profile-modern *+hr {
	margin-top: 14px;
}

.profile-modern-media {
	position: relative;
	display: block;
	-webkit-transform-origin: 50% 0;
	        transform-origin: 50% 0;
	transition: .33s;
}

.profile-modern-media::before {
	content: '';
	display: block;
	padding-top: 73.84196%;
}

.profile-modern-media img {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	min-height: 100%;
	min-width: 100%;
	width: auto;
	height: auto;
	max-width: none;
}

@supports (object-fit: cover) {
	.profile-modern-media img {
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		-webkit-transform: none;
		        transform: none;
		object-fit: cover;
		object-position: center center;
	}
}

.profile-modern-main {
	position: relative;
	z-index: 1;
	padding: 20px;
	background: #ffffff;
}

.profile-modern-title {
	transition: .22s;
}

.profile-modern-meta {
	color: #151515;
	letter-spacing: .025em;
}

.profile-modern-meta>li {
	white-space: nowrap;
}

.profile-modern-meta>li>* {
	display: inline-block;
	vertical-align: top;
	white-space: normal;
}

.profile-modern-meta>li>*+* {
	margin-left: 5px;
}

.profile-modern-meta .icon {
	position: relative;
	top: 3px;
	min-width: 20px;
	color: #0A043B;
}

.profile-modern-meta>li+li {
	margin-top: 6px;
}

*+.profile-modern-meta {
	margin-top: 14px;
}

@media (min-width: 768px) {
	.profile-modern p {
		letter-spacing: .05em;
	}
	.profile-modern *+hr {
		margin-top: 20px;
	}
	.profile-modern-main {
		padding: 35px 37px 40px;
	}
}


/*
*
* Companies
*/

.company-minimal {
	display: flex;
	align-items: center;
	margin-left: -20px;
	min-width: 240px;
}

.company-minimal>* {
	margin-left: 20px;
}

.company-minimal p {
	letter-spacing: .05em;
}

.company-minimal-figure {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
	width: 56px;
	height: 56px;
	background: #e2e4ee;
	flex-shrink: 0;
}

.company-minimal-image {
	max-width: 80%;
	max-height: 80%;
}

.company-minimal-main>*+* {
	margin-top: 3px;
}

.company-minimal-main p {
	line-height: 1.4;
}

@media (min-width: 768px) {
	.company-minimal-figure {
		width: 78px;
		height: 78px;
	}
}

.table-companies {
	width: 100%;
}

.table-companies tbody {
	width: 100%;
}

.table-companies tr {
	background: #ffffff;
	border: 1px solid #e0e0e0;
	transition: .22s;
}

.table-companies tr:hover {
	background: #f3f4f9;
}

.table-companies td {
	padding: 16px 20px;
}

.table-companies-main {
	width: 54%;
}

.table-companies-meta {
	width: 23%;
	white-space: nowrap;
	letter-spacing: .05em;
}

.table-companies-meta>* {
	display: inline-block;
	vertical-align: middle;
}

.table-companies-meta>*+* {
	margin-left: 4px;
}

.table-companies-meta .icon {
	position: relative;
	top: -1px;
	font-size: 16px;
	color: #0A043B;
}

.block-sort-options {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	text-transform: uppercase;
	letter-spacing: .05em;
	color: #151515;
	font-size: 0;
	line-height: 0;
}

.block-sort-options>* {
	position: relative;
	display: inline-block;
	width: 100%;
	max-width: 33.2px;
	flex-shrink: 0;
	font: 600 12px/16px "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", rubiklight, "Helvetica Neue", Arial, sans-serif;
	padding: 6px 8px;
	border: 1px solid #d6d6d6;
	text-align: center;
	margin: -1px 0 0 -1px;
	cursor: pointer;
	transition: .25s;
}

.block-sort-options>*:hover {
	z-index: 1;
	border-color: #0A043B;
}

.block-sort-options>.selected {
	position: relative;
	z-index: 1;
	padding: 6px 3px;
	border-color: #0A043B;
	color: #0A043B;
}

@media (min-width: 768px) {
	.block-sort-options>* {
		flex-grow: 1;
	}
}

.company-light {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: -24px;
	margin-left: -24px;
}

.company-light:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.company-light>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 24px;
	margin-left: 24px;
}

.company-light-logo {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 92px;
	height: 92px;
	border: 1px solid rgb(150, 145, 171);
	flex-shrink: 0;
	border-radius: 4px;
}

.company-light-figure {
	flex-shrink: 0;
	min-width: 58px;
	text-align: center;
}

.company-light-logo-image {
	max-width: 80%;
	max-height: 80%;
}

.company-light-main {
	flex-grow: 1;
}

.company-light-main>*+* {
	margin-top: 3px;
}

*+.company-light-info {
	margin-top: 16px;
}

@media (max-width: 575.98px) {
	.company-light-info {
		max-width: 95vw;
	}
}

@media (max-width: 767.98px) {
	.company-light_1 {
		align-items: flex-start;
	}
}

@media (min-width: 768px) {
	.company-light .row {
		margin-left: -30px;
		margin-right: -30px;
	}
	.company-light .row>[class*='col'] {
		padding-left: 30px;
		padding-right: 30px;
	}
}

@media (min-width: 1200px) {
	.company-light-logo {
		width: 125px;
		height: 120px;
	}
}


/*
*
* Messages
*/

.message-bubble {
	position: relative;
	color: #151515;
}

.message-bubble-inner {
	position: relative;
	display: flex;
	padding: 10px 15px 10px;
	border: 1px solid #e0e0e0;
	border-top: 0;
	margin-top: 12px;
	letter-spacing: .025em;
}

.message-bubble-inner>*+* {
	margin-left: 5px;
}

.message-bubble-inner::before {
	content: '';
	position: absolute;
	top: -12px;
	right: -1px;
	height: 12px;
	border-left: 1px solid #e0e0e0;
}

.message-bubble-inner::after {
	content: '';
	position: absolute;
	left: 15px;
	right: -1px;
	top: -12px;
	height: 12px;
	border: 1px solid #e0e0e0;
	border-left-width: 0;
	border-top-width: 0;
	-webkit-transform: skewX(-55deg);
	        transform: skewX(-55deg);
	-webkit-transform-origin: 0 0;
	        transform-origin: 0 0;
}

.message-bubble-inner p+p {
	margin-top: 2px;
}

*+.message-bubble {
	margin-top: 4px;
}

.message-inline {
	padding: 10px 3px;
	text-align: center;
}

.message-inline p+p {
	margin-top: 0;
}

.message-inline>*+* {
	margin-top: 3px;
}


/*
*
* Jumbotron Custom
*/

.jumbotron-modern {
	position: relative;
	display: flex;
	overflow: hidden;
	text-align: center;
}

.jumbotron-modern-inner {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	padding: 40px 0;
}

.jumbotron-modern-inner>.container {
	position: relative;
	z-index: 2;
}

.jumbotron-modern-image-left,
.jumbotron-modern-image-right {
	position: absolute;
	top: 30%;
	-webkit-transform: translate3d(0, -30%, 0);
	        transform: translate3d(0, -30%, 0);
	max-height: 96%;
	max-width: 40%;
	z-index: 1;
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	width: 100%;
	opacity: .5;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
	contain: paint;
}

.jumbotron-modern-image-left img,
.jumbotron-modern-image-right img {
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
}

.jumbotron-modern-image-left {
	left: 0;
	align-items: flex-start;
}

.jumbotron-modern-image-right {
	right: 0;
	align-items: flex-end;
}

@media (min-width: 768px) {
	.jumbotron-modern-inner {
		padding: 90px 0 100px;
		min-height: 30vw;
	}
	.jumbotron-modern-image-left,
	.jumbotron-modern-image-right {
		top: 50%;
		-webkit-transform: translate3d(0, -50%, 0);
		        transform: translate3d(0, -50%, 0);
		opacity: .8;
	}
}

@media (min-width: 1200px) {
	.jumbotron-modern-image-left,
	.jumbotron-modern-image-right {
		opacity: .9;
	}
}

@media (min-width: 1600px) {
	.jumbotron-modern-image-left,
	.jumbotron-modern-image-right {
		opacity: 1;
	}
}

.jumbotron-creative {
	display: flex;
	flex-direction: column;
	position: relative;
}

.jumbotron-creative>* {
	width: 100%;
}

.jumbotron-creative .form-layout-search {
	margin-bottom: -8px;
	margin-left: -8px;
}

.jumbotron-creative .form-layout-search>* {
	margin-bottom: 8px;
	margin-left: 8px;
}

.jumbotron-creative .rd-navbar-static:not(.rd-navbar--is-stuck) {
	background-color: transparent;
}

.jumbotron-creative *+.form-layout-search-outer {
	margin-top: 35px;
}

.jc-decoration {
	position: relative;
	max-width: 540px;
	padding: 0 15px 10%;
	margin-left: auto;
	margin-right: auto;
	z-index: 0;
}

.jc-decoration::after {
	content: '';
	position: absolute;
	bottom: 0;
	padding-bottom: 30%;
	width: 100%;
	border-radius: 50%;
	z-index: -1;
  background: #0A043B;
	/* background: #0A043B; */
}

.jc-decoration-item {
	display: none;
}

.jumbotron-creative-inner {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
}

.jumbotron-creative-main,
.jumbotron-creative-aside {
	position: relative;
	z-index: 1;
}

.jumbotron-creative-main {
	width: 100%;
	padding: 40px 0;
	text-align: center;
}

.jumbotron-creative-main .form-layout-search-outer {
	max-width: 420px;
	margin-left: auto;
	margin-right: auto;
}

.jumbotron-creative+.section.bg-default {
	padding-top: 30px;
}


@media (min-width: 768px) {
	.jumbotron-creative .form-layout-search .form-wrap {
		min-width: 240px;
	}
	.jumbotron-creative .form-layout-search .form-wrap-select {
		max-width: 240px;
	}
	.jumbotron-creative .form-layout-search .form-wrap-button {
		min-width: 75px;
	}
	.jc-decoration {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		max-width: 100%;
		width: 70%;
		padding: 0;
	}
	.jc-decoration::after {
		display: none;
	}
	.jc-decoration-item {
		position: absolute;
		display: block;
		min-width: 100%;
		min-height: 100%;
		padding-bottom: 67.0816%;
	}
	.jc-decoration-item svg {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.jc-decoration-item-1 {
		top: -5.5%;
		left: 1%;
		fill: #5eb6ff;
	}
	.jc-decoration-item-2 {
		top: -5.8%;
		left: 1.5%;
		fill: #0A043B;
	}
	.jc-decoration-image {
		position: absolute;
		top: 60%;
		left: calc(50% - 90px);
		max-width: 380px;
		-webkit-transform: translate3d(0, -60%, 0);
		        transform: translate3d(0, -60%, 0);
	}
	.jumbotron-creative-main {
		padding: 70px 0 120px;
		max-width: 360px;
		text-align: left;
	}
	.jumbotron-creative-main .form-layout-search-outer {
		max-width: 100%;
		margin-left: 0;
	}
	.jumbotron-creative-title {
		max-width: 360px;
	}
	.jumbotron-creative+.section.bg-default {
		padding-top: 0;
	}
}

.jumbotron-creative-title {
	color: #ffffff;
}

@media (min-width: 992px) {
	.jc-decoration {
		width: calc(100% - 120px);
	}
	.jc-decoration-image {
		max-width: 480px;
		left: calc(50% - 120px);
	}
}

@media (min-width: 1200px) {
	.jumbotron-creative-inner {
		/* min-height: 42.1875vw; */
		min-height: 30.1875vw;
	}
	.jc-decoration {
		width: calc(100% - 120px);
	}
	.jc-decoration-image {
		left: 50%;
	}
	.jumbotron-creative-main {
		max-width: 580px;
		padding: 50px 0 180px;
	}
	.jumbotron-creative-main .form-layout-search {
		max-width: inherit;
	}
	.jumbotron-creative-title {
		max-width: 100%;
		padding-right: 80px;
	}
}

@media (min-width: 1600px) {
	.jc-decoration-image {
		left: calc(50% - 150px);
		max-width: 750px;
	}
	.jc-decoration {
		width: calc(50% + 500px);
	}
	.jumbotron-creative-title {
		padding-right: 60px;
	}
	.jumbotron-creative-main {
		max-width: 590px;
	}
}


/*
* Page layout
*/

.page {
	position: relative;
	overflow: hidden;
	min-height: 100vh;
	opacity: 0;
}

.page.fadeIn {
	-webkit-animation-timing-function: ease-out;
	        animation-timing-function: ease-out;
}

.page.fadeOut {
	-webkit-animation-timing-function: ease-in;
	        animation-timing-function: ease-in;
}

[data-x-mode] .page {
	opacity: 1;
}

.page-header .rd-navbar {
	position: relative;
	z-index: 1030;
}


/*
*
* Text styling
*/

.text-italic {
	font-style: italic;
}

.text-normal {
	font-style: normal;
}

.text-underline {
	text-decoration: underline;
}

.text-strike {
	text-decoration: line-through;
}

.font-weight-thin {
	font-weight: 100;
}

.font-weight-light {
	font-weight: 300;
}

.font-weight-regular {
	font-weight: 400;
}

.font-weight-medium {
	font-weight: 500;
}

.font-weight-sbold {
	font-weight: 600;
}

.font-weight-ubold {
	font-weight: 900;
}

.text-spacing-0 {
	letter-spacing: 0;
}

.text-spacing-05 {
	letter-spacing: .05em;
}

html .text-primary {
	color: #0A043B;
}

html .text-secondary {
	color: #ca5217;
}

html .text-tertiary {
	color: #7959b6;
}

html .text-gray-3 {
	color: #e4e6ef;
}

html .text-gray-500 {
	color: #9a9a9a;
}

html .text-gray-800 {
	color: #151515;
}

html .text-gray-800 a,
html .text-gray-800 a:active,
html .text-gray-800 a:focus {
	color: #0A043B;
}

html .text-gray-800 a:hover {
	color: #ca5217;
}

html .text-blue-11 {
	color: #437685;
}

html .text-color-default {
	color: #9a9a9a;
}

html .text-white {
	color: #ffffff;
}

html .text-success {
	color: green;
}

html .text-white a,
html .text-white a:active,
html .text-white a:focus {
	color: #0A043B;
}

.text-white > a {
	color: #0A043B;
	background-color: #ffffff;
	padding: 5px 1px;
	font-weight: 500;
}

html .text-white a:hover {
	color: #ca5217;
}
/*
*
* Offsets
*/

*+p {
	margin-top: 16px;
}

.group+hr,
.button+hr {
	margin-top: 15px;
}

h2+p,
.heading-2+p {
	margin-top: 12px;
}

p+h2,
p+.heading-2 {
	margin-top: 15px;
}

h2+h3 {
	margin-top: 10px;
}

h4+hr,
.heading-4+hr {
	margin-top: 15px;
}

h3+p,
.heading-3+p {
	margin-top: 5px;
}

h3+.big,
.heading-3+.big {
	margin-top: 15px;
}

h2+h5,
h2+.heading-5,
.heading-2+h5,
.heading-2+.heading-5 {
	margin-top: 15px;
}

h3+h5,
h3+.heading-5,
.heading-3+h5,
.heading-3+.heading-5 {
	margin-top: 15px;
}

h2+h6,
h2+.heading-6,
.heading-2+h6,
.heading-2+.heading-6 {
	margin-top: 17px;
}

p+p {
	margin-top: 12px;
}

img+p {
	margin-top: 15px;
}

h3+img {
	margin-top: 42px;
}

*+.row {
	margin-top: 30px;
}

*+.big {
	margin-top: 20px;
}

*+.text-block {
	margin-top: 30px;
}

*+.button {
	margin-top: 30px;
}

*+.group {
	margin-top: 5px;
}

*+.list-inline-sm {
	margin-top: 18px;
}

*+.list-0 {
	margin-top: 10px;
}

*+.rd-mailform {
	margin-top: 30px;
}

*+.block-form {
	margin-top: 50px;
}

*+.heading-decorated_1 {
	margin-top: 25px;
}

*+.owl-carousel {
	margin-top: 30px;
}

*+.offset-top-20px {
	margin-top: 20px;
}

.row+h3,
.row+.heading-3 {
	margin-top: 80px;
}

h2+.button-lg,
.heading-2+.button-lg {
	margin-top: 20px;
}

/* hr+.rd-form {
	margin-top: 35px;
} */

.heading-8+hr {
	margin-top: 5px;
}

.heading-9+hr {
	margin-top: 10px;
}

hr+.list {
	margin-top: 17px;
}

hr+.form-wrap {
	margin-top: 18px;
}

hr+.text-style-1 {
	margin-top: 24px;
}

hr+.row {
	margin-top: 18px;
}

.container+.container {
	margin-top: 35px;
}

.row+.row {
	margin-top: 35px;
}

.form-wrap+.list {
	margin-top: 16px;
}

.big+.button-lg {
	margin-top: 35px;
}

html .page *+.offset-top-1 {
	margin-top: 50px;
}

html .page *+.offset-top-2 {
	margin-top: 35px;
}

html .page *+.offset-top-30px {
	margin-top: 30px;
}

@media (min-width: 768px) {
	p+p {
		margin-top: 24px;
	}
	h2+h5,
	h2+.heading-5,
	.heading-2+h5,
	.heading-2+.heading-5 {
		margin-top: 25px;
	}
	h3+.big,
	.heading-3+.big {
		margin-top: 30px;
	}
	.group+hr,
	.button+hr {
		margin-top: 30px;
	}
	hr+.group {
		margin-top: 30px;
	}
	hr+.button {
		margin-top: 50px;
	}
	*+.row {
		margin-top: 45px;
	}
	/* *+.group {
		margin-top: 40px;
	} */
	/* *+.block-form {
		margin-top: 35px;
	} */
	*+.heading-decorated_1 {
		margin-top: 35px;
	}
	*+.owl-carousel {
		margin-top: 40px;
	}
	html .page *+.offset-top-1 {
		margin-top: 70px;
	}
	html .page *+.offset-top-2 {
		margin-top: 45px;
	}
}

@media (min-width: 1200px) {
	h2+h3 {
		margin-top: 15px;
	}
	/* *+.rd-form {
		margin-top: 30px;
	} */
	.container+.container {
		margin-top: 60px;
	}
	.row+.row {
		margin-top: 60px;
	}
	h2+.button-lg,
	.heading-2+.button-lg {
		margin-top: 45px;
	}
}

@media (min-width: 1600px) {
	html .page *+.offset-top-1 {
		margin-top: 90px;
	}
}

.row:empty {
	margin-bottom: 0;
}

.row-0 {
	margin-bottom: 0;
}

.row-0>* {
	margin-bottom: 0;
}

.row-15 {
	margin-bottom: -15px;
}

.row-15>* {
	margin-bottom: 15px;
}

.row-20 {
	margin-bottom: -20px;
}

.row-20>* {
	margin-bottom: 20px;
}

.row-30 {
	margin-bottom: -30px;
}

.row-30>* {
	margin-bottom: 30px;
}

.row-40 {
	margin-bottom: -40px;
}

.row-40>* {
	margin-bottom: 10px;
}

.row-50 {
	margin-bottom: -50px;
}

.row-50>* {
	margin-bottom: 50px;
}

@media (min-width: 992px) {
	.row-lg-50 {
		margin-bottom: -50px;
	}
	.row-lg-50>* {
		margin-bottom: 50px;
	}
}

@media (min-width: 1200px) {
	.row-xl-70 {
		margin-bottom: -70px;
	}
	.row-xl-70>* {
		margin-bottom: 70px;
	}
}


/*
*
* Sections
*/

.section-xs {
	padding: 25px 0;
}

.section-xs.section-first {
	padding-top: 40px;
}

.section-xs.section-last {
	padding-bottom: 40px;
}

.section-sm,
.section-md,
.section-lg,
.section-xl {
	padding: 50px 0;
}

.section-collapse+.section-collapse {
	padding-top: 0;
}

.section-collapse:last-child {
	padding-bottom: 0;
}

.section-2 {
	padding: 30px 0;
}

html [class*='section-'].section-bottom-0 {
	padding-bottom: 0;
}

@media (max-width: 991.98px) {
	section.section-sm:first-of-type,
	section.section-md:first-of-type,
	section.section-lg:first-of-type,
	section.section-xl:first-of-type,
	section.section-xxl:first-of-type {
		padding-top: 40px;
	}
}

@media (min-width: 768px) {
	.section-xs.section-first {
		padding-top: 60px;
	}
	.section-xs.section-last {
		padding-bottom: 60px;
	}
	.section-sm {
		padding: 60px 0;
	}
	.section-sm.section-first {
		padding-top: 80px;
	}
	.section-sm.section-last {
		padding-bottom: 80px;
	}
	/* .section-md {
		padding: 70px 0;
	} */
	.section-lg {
		padding: 85px 0;
	}
	.section-2 {
		padding: 50px 0;
	}
}

@media (min-width: 992px) {
	.section-sm {
		padding: 56px 0 56px;
	}
	/* .section-md {
		padding: 90px 0 95px;
	} */
	.section-lg {
		padding: 100px 0;
	}
	.section-1 {
		padding: 25px 0;
	}
	.section-2 {
		padding: 70px 0;
	}
}

@media (min-width: 1200px) {
	.section-sm.section-first {
		padding-top: 90px;
	}
	.section-sm.section-last {
		padding-bottom: 90px;
	}
	.section-lg {
		padding: 115px 0;
	}
}

.section-single {
	display: flex;
	text-align: center;
}

.section-single .rd-mailform-wrap {
	max-width: 670px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

.section-single *+.rights {
	margin-top: 35px;
}

.section-single *+.block-6 {
	margin-top: 35px;
}

.section-single *+.rd-mailform-wrap {
	margin-top: 30px;
}

.section-single .block-6+* {
	margin-top: 20px;
}

@media (min-width: 992px) {
	.section-single *+.rights {
		margin-top: 60px;
	}
	.section-single *+.block-6 {
		margin-top: 35px;
	}
	.section-single *+.rd-mailform-wrap {
		margin-top: 40px;
	}
	.section-single .block-6+* {
		margin-top: 25px;
	}
}

.section-single-header {
	padding: calc(1em + 3vh) 0 calc(1em + 2vh);
}

.section-single-main {
	padding: calc(1em + 4vh) 0;
}

.section-single-footer {
	padding: calc(1em + 2vh) 0 calc(1em + 3vh);
}

.section-single-inner {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	min-height: 100vh;
}

.section-single-inner>* {
	width: 100%;
}

@supports (display: grid) {
	.section-single-inner {
		display: grid;
		justify-items: center;
		align-content: space-between;
		grid-template-columns: 1fr;
	}
}

@media (min-width: 1600px) {
	.section-single-header {
		padding-top: 100px;
		padding-bottom: 0;
	}
	.section-single-footer {
		padding-top: 0;
		padding-bottom: 60px;
	}
}


/*
*
* Grid modules
*/

.grid-demonstration {
	padding: 12px 8px;
	letter-spacing: 0;
	text-align: left;
	color: #151515;
}

.grid-demonstration .gd-title {
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 20px;
	letter-spacing: .05em;
}

@media (max-width: 1199.98px) {
	.grid-demonstration .gd-title {
		font-size: 14px;
	}
}

@media (max-width: 1599.98px) {
	.grid-demonstration p {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

@media (max-width: 991.98px) {
	.container-fluid.grid-demonstration .row {
		margin-left: 0;
		margin-right: 0;
	}
}

.container-fluid.grid-demonstration [class^='col'] {
	padding: 5px;
}

@media (max-width: 991.98px) {
	.container-fluid.grid-demonstration [class^='col'] p {
		font-size: 14px;
	}
	.container-fluid.grid-demonstration [class^='col'] *+p {
		margin-top: 10px;
	}
}

@media (min-width: 768px) {
	.container-fluid.grid-demonstration [class^='col'] {
		padding: 15px 10px;
	}
}

@media (min-width: 992px) {
	.container-fluid.grid-demonstration [class^='col'] {
		padding: 25px 15px;
	}
}

@media (min-width: 1200px) {
	.container-fluid.grid-demonstration [class^='col'] {
		padding: 30px 15px 30px 30px;
	}
}

@media (min-width: 1600px) {
	.container-fluid.grid-demonstration [class^='col'] {
		padding: 50px 100px;
	}
	.container-fluid.grid-demonstration [class^='col'] *+p {
		margin-top: 24px;
	}
}

@media (min-width: 1600px) {
	.container-fluid.grid-demonstration .row {
		margin-left: -70px;
		margin-right: -70px;
	}
}

*+.grid-demonstration {
	margin-top: 30px;
}

.grid-system-bordered [class*='col'] {
	padding-top: 15px;
	padding-bottom: 15px;
}

.grid-system-bordered .row {
	border-bottom: 1px solid #e0e0e0;
}

@media (min-width: 768px) {
	.grid-system-bordered [class*='col'] {
		padding-top: 25px;
		padding-bottom: 25px;
	}
}

@media (min-width: 1200px) {
	.grid-system-bordered [class*='col'] {
		padding-top: 45px;
		padding-bottom: 45px;
	}
}

.grid-system-outline .row {
	border: 1px solid #e0e0e0;
}

.grid-system-outline .row:not(:last-child) {
	border-bottom-width: 0;
}

.grid-system-outline [class*='col']:not(:last-child) {
	border-right: 1px solid #e0e0e0;
	margin-right: -1px;
}

.grid-system-outline *+.row {
	margin-top: 45px;
}

.grid-system-outline .row+.row {
	margin-top: 0;
}

@media (min-width: 1600px) {
	.container-wide {
		max-width: 1800px;
	}
}

@media (min-width: 1200px) {
	.container-fullwidth {
		max-width: 100%;
	}
}

@media (min-width: 1600px) {
	.container-fullwidth {
		max-width: 1870px;
	}
}

.row-x-14 {
	margin-left: -7px;
	margin-right: -7px;
}

.row-x-14>[class*='col'] {
	padding-left: 7px;
	padding-right: 7px;
}

@media (min-width: 576px) {
	.row-bordered .col-sm-6:not(.col-md-4) {
		border-left: 1px solid rgb(150, 145, 171);
	}
	.row-bordered .col-sm-6:not(.col-md-4):nth-child(2n + 1) {
		border-left: 0;
	}
}

@media (min-width: 768px) {
	.row-bordered .col-md-4 {
		padding-top: 15px;
		padding-bottom: 15px;
	}
}

@media (min-width: 992px) {
	.row-bordered .col-md-4 {
		border-left: 1px solid #e0e0e0;
	}
	.row-bordered .col-md-4:nth-child(3n + 1) {
		border-left: 0;
	}
}

.row-bordered-1-outer {
	position: relative;
	overflow: hidden;
}

.row-bordered-1 {
	margin-top: -38px;
	margin-bottom: -38px;
}

.row-bordered-1>[class*='col'] {
	padding: 38px 20px;
}

@media (max-width: 575.98px) {
	.row-bordered-1>[class*='col']:nth-child(n + 2)::before {
		content: '';
		position: absolute;
		top: 0;
		left: 30px;
		right: 30px;
		border-top: 1px solid #d6d6d6;
	}
}

@media (min-width: 576px) {
	.row-bordered-1 .col-sm-6 {
		position: relative;
	}
	.row-bordered-1 .col-sm-6:nth-child(n + 3)::before {
		content: '';
		position: absolute;
		top: 0;
		left: 30px;
		right: 30px;
		border-top: 1px solid rgb(150, 145, 171);
	}
	.row-bordered-1 .col-sm-6:nth-child(2n)::after {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		border-left: 1px solid #d6d6d6;
	}
}

.row-narrow {
	margin-left: -10px;
	margin-right: -10px;
}

.row-narrow>[class*='col'] {
	padding-left: 10px;
	padding-right: 10px;
}

@media (min-width: 1200px) {
	.row-x-xl-50 {
		margin-left: -25px;
		margin-right: -25px;
	}
	.row-x-xl-50>[class*='col'] {
		padding-left: 25px;
		padding-right: 25px;
	}
}


/*
*
* Backgrounds
*/

.context-dark,
.bg-gray-700,
.bg-blue-13,
.bg-blue-15,
.bg-primary,
.context-dark h1,
.bg-gray-700 h1,
.bg-blue-13 h1,
.bg-blue-15 h1,
.bg-primary h1,
.context-dark h2,
.bg-gray-700 h2,
.bg-blue-13 h2,
.bg-blue-15 h2,
.bg-primary h2,
.context-dark h3,
.bg-gray-700 h3,
.bg-blue-13 h3,
.bg-blue-15 h3,
.bg-primary h3,
.context-dark h4,
.bg-gray-700 h4,
.bg-blue-13 h4,
.bg-blue-15 h4,
.bg-primary h4,
.context-dark h5,
.bg-gray-700 h5,
.bg-blue-13 h5,
.bg-blue-15 h5,
.bg-primary h5,
.context-dark h6,
.bg-gray-700 h6,
.bg-blue-13 h6,
.bg-blue-15 h6,
.bg-primary h6,
.context-dark [class*='heading-'],
.bg-gray-700 [class*='heading-'],
.bg-blue-13 [class*='heading-'],
.bg-blue-15 [class*='heading-'],
.bg-primary [class*='heading-'] {
	color: #ffffff;
}

.context-dark hr,
.bg-gray-700 hr,
.bg-blue-13 hr,
.bg-blue-15 hr,
.bg-primary hr {
	border-top-color: rgba(255, 255, 255, 0.13);
}

.context-dark .brand .brand-logo-dark,
.bg-gray-700 .brand .brand-logo-dark,
.bg-blue-13 .brand .brand-logo-dark,
.bg-blue-15 .brand .brand-logo-dark,
.bg-primary .brand .brand-logo-dark {
	display: none;
}

.context-dark .brand .brand-logo-light,
.bg-gray-700 .brand .brand-logo-light,
.bg-blue-13 .brand .brand-logo-light,
.bg-blue-15 .brand .brand-logo-light,
.bg-primary .brand .brand-logo-light {
	display: block;
}

.context-dark .rights a,
.bg-gray-700 .rights a,
.bg-blue-13 .rights a,
.bg-blue-15 .rights a,
.bg-primary .rights a {
	color: inherit;
}

.context-dark .big a:hover,
.bg-gray-700 .big a:hover,
.bg-blue-13 .big a:hover,
.bg-blue-15 .big a:hover,
.bg-primary .big a:hover {
	color: #35a1ff;
}


/**
* Light Backgrounds
*/

.bg-default {
	background-color: #ffffff;
}

.bg-default+.bg-default {
	padding-top: 0;
}

.bg-gray-100 {
	background-color: #f3f4f9;
}

.bg-gray-100+.bg-gray-100 {
	padding-top: 0;
}

.bg-gray-200 {
	background-color: #e0e0e0;
}

.bg-gray-200+.bg-gray-200 {
	padding-top: 0;
}


/**
* Dark Backgrounds
*/

.bg-gray-700 {
	background-color: #2c343b;
}

.bg-gray-700+.bg-gray-700 {
	padding-top: 0;
}

.bg-blue-13 {
	background-color: #3b494c;
}

.bg-blue-13+.bg-blue-13 {
	padding-top: 0;
}

.bg-blue-15 {
	background-color: #45556c;
}

.bg-blue-15+.bg-blue-15 {
	padding-top: 0;
}


/** 
* Accent Backgrounds
*/

.bg-primary {
	background: #0A043B;
}

.bg-primary+.bg-primary {
	padding-top: 0;
}


/**
* Background Image
*/

[class*='bg-'] {
	background-size: cover;
	background-position: center center;
}

[class*='bg-overlay'] {
	position: relative;
}

[class*='bg-overlay']::before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	pointer-events: none;
	z-index: 1;
}

[class*='bg-overlay']>* {
	position: relative;
	z-index: 1;
}

.bg-overlay-1::before {
	background: rgba(16, 135, 235, 0.9);
}

.bg-overlay-2::before {
	background: rgba(60, 74, 84, 0.93);
}

.bg-overlay-3::before {
	background: rgba(22, 47, 69, 0.43);
}

.bg-overlay-4::before {
	background: rgba(22, 47, 69, 0.84);
}

.bg-overlay-5::before {
	background: rgba(65, 78, 90, 0.37);
}

.bg-overlay-6::before {
	background: rgba(72, 91, 120, 0.93);
}

.bg-overlay-7::before {
	background: rgba(52, 73, 94, 0.75);
}

.bg-overlay-8::before {
	background: #0A043B;
	opacity: 0.55;
	background: linear-gradient(to right, rgb(10, 4, 59) 10%, transparent 90%);
}

.bg-overlay-gradient-1::before {
	background: linear-gradient(to right, #3d485d 0%, transparent 100%);
	opacity: 0.522;
}

@media (min-width: 992px) {
	html:not(.tablet):not(.mobile) .bg-fixed {
		background-attachment: fixed;
	}
}

.bg-1 {
	background: #49555e url(/img/bg-blue-4-noise.jpg) repeat;
	padding-bottom: 10px;
}

@media (min-width: 576px) {
	.bg-1 {
		padding-bottom: 20px;
	}
}

@media (min-width: 768px) {
	.bg-1 {
		padding-bottom: 30px;
	}
}

@media (min-width: 992px) {
	.bg-1 {
		padding-bottom: 50px;
	}
}

@media (min-width: 1200px) {
	.bg-1 {
		padding-bottom: 80px;
	}
}


/*
*
* Utilities custom
*/

.height-fill {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

.height-fill>* {
	flex-grow: 1;
	flex-shrink: 0;
}

.object-inline {
	white-space: nowrap;
}

.object-inline>* {
	display: inline-block;
	min-width: 20px;
	vertical-align: top;
	white-space: normal;
}

.object-inline>*+* {
	margin-left: 5px;
}

.object-inline .icon-1,
.object-inline .icon-default {
	position: relative;
	top: 0px;
}

.object-inline a {
	color: inherit;
}

.object-inline a:hover {
	color: #0A043B;
}

.object-inline_sm>*+* {
	margin-left: 2px;
}

.oh {
	position: relative;
	overflow: hidden;
}

.text-width-small {
	max-width: 320px;
}

.text-decoration-lines {
	position: relative;
	overflow: hidden;
	width: 100%;
	text-align: center;
}

.text-decoration-lines-content {
	position: relative;
	display: inline-block;
	min-width: 170px;
	font-size: 13px;
	text-transform: uppercase;
}

.text-decoration-lines-content::before,
.text-decoration-lines-content::after {
	content: '';
	position: absolute;
	height: 1px;
	background: #e0e0e0;
	top: 50%;
	width: 100vw;
}

.text-decoration-lines-content::before {
	left: 0;
	-webkit-transform: translate3d(-100%, 0, 0);
	        transform: translate3d(-100%, 0, 0);
}

.text-decoration-lines-content::after {
	right: 0;
	-webkit-transform: translate3d(100%, 0, 0);
	        transform: translate3d(100%, 0, 0);
}

*+.text-decoration-lines {
	margin-top: 30px;
}

.text-decoration-lines+.rd-form {
	margin-top: 17px;
}

.rights a {
	text-decoration: underline;
	color: #000000;
}

.rights a:hover {
	text-decoration: none;
}

.text-block>* {
	margin-left: .125em;
	margin-right: .125em;
}

.footer-text {
	color: #808080;
}
.tip-mark {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	top: .2em;
	width: 2em;
	height: 2em;
	padding-left: 2px;
	margin-bottom: 5px;
	font-size: 14px;
	line-height: 2em;
	vertical-align: middle;
	text-align: center;
	border-radius: 50%;
	border: 2px solid #e0e0e0;
	color: #0A043B;
	cursor: pointer;
	-webkit-user-select: none;
	        user-select: none;
}

.context-dark .tip-mark,
.bg-gray-700 .tip-mark,
.bg-blue-13 .tip-mark,
.bg-blue-15 .tip-mark,
.bg-primary .tip-mark {
	color: #ffffff;
}

.placeholder-1 {
	height: 80px;
}

.inset-left-1 {
	padding-left: 20px;
}

@media (min-width: 768px) {
	.inset-left-1 {
		padding-left: 63px;
	}
}


/*
*
* Animate.css
*/

.animated {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	opacity: 1;
}

.animated.infinite {
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

.animated.hinge {
	-webkit-animation-duration: 2s;
	animation-duration: 2s;
}

html:not(.lt-ie10) .not-animated {
	opacity: 0;
}

@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.fadeIn {
	-webkit-animation-name: fadeIn;
	animation-name: fadeIn;
}

@-webkit-keyframes fadeInUp {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

@keyframes fadeInUp {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

.fadeInUp {
	-webkit-animation-name: fadeInUp;
	animation-name: fadeInUp;
}

@-webkit-keyframes fadeInDown {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

@keyframes fadeInDown {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

.fadeInDown {
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown;
}

@-webkit-keyframes fadeInLeft {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

@keyframes fadeInLeft {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

.fadeInLeft {
	-webkit-animation-name: fadeInLeft;
	animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInRight {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

@keyframes fadeInRight {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

.fadeInRight {
	-webkit-animation-name: fadeInRight;
	animation-name: fadeInRight;
}

@-webkit-keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.fadeOut {
	-webkit-animation-name: fadeOut;
	animation-name: fadeOut;
}

@-webkit-keyframes slideInDown {
	0% {
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
		visibility: visible;
	}
	100% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slideInDown {
	0% {
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
		visibility: visible;
	}
	100% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

.slideInDown {
	-webkit-animation-name: slideInDown;
	animation-name: slideInDown;
}

@-webkit-keyframes slideInLeft {
	0% {
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
		visibility: visible;
	}
	100% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slideInLeft {
	0% {
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
		visibility: visible;
	}
	100% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

.slideInLeft {
	-webkit-animation-name: slideInLeft;
	animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
	0% {
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
		visibility: visible;
	}
	100% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slideInRight {
	0% {
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
		visibility: visible;
	}
	100% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

.slideInRight {
	-webkit-animation-name: slideInRight;
	animation-name: slideInRight;
}

@-webkit-keyframes slideOutDown {
	0% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
	100% {
		visibility: hidden;
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
	}
}

@keyframes slideOutDown {
	0% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
	100% {
		visibility: hidden;
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
	}
}

.slideOutDown {
	-webkit-animation-name: slideOutDown;
	animation-name: slideOutDown;
}


/*
*
* Preloader
*/

.preloader {
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 9999999;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
	background: #ffffff;
	transition: .3s all ease;
}

.preloader.loaded {
	opacity: 0;
	visibility: hidden;
}

.preloader-body {
	text-align: center;
}

.preloader-item {
	display: block;
	margin-top: -4%;
}

.preloader-item .rect {
	fill: none;
	stroke-width: 8px;
	stroke: #0A043B;
	stroke-dasharray: 40;
	stroke-dashoffset: 50%;
	-webkit-animation: movedash 1.2s ease-in-out forwards 0s infinite;
	        animation: movedash 1.2s ease-in-out forwards 0s infinite;
}

@-webkit-keyframes movedash {
	to {
		stroke-dashoffset: 250%;
	}
}

@keyframes movedash {
	to {
		stroke-dashoffset: 250%;
	}
}


/*
*
* ToTop
*/

.ui-to-top {
	position: fixed;
	right: 15px;
	bottom: 15px;
	z-index: 100;
	width: 50px;
	height: 50px;
	font-size: 16px;
	line-height: 48px;
	color: #ffffff;
	background: #0A043B;
	box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.18);
	overflow: hidden;
	text-align: center;
	text-decoration: none;
	transition: .45s all ease-in-out;
	-webkit-transform: translate3d(0, 100px, 0);
	        transform: translate3d(0, 100px, 0);
	border-radius: 50%;
}

.ui-to-top:hover {
	color: #151515;
	background: #ffffff;
	text-decoration: none;
}

.ui-to-top:focus {
	color: #151515;
}

.ui-to-top.active {
	-webkit-transform: translate3d(0, 0, 0);
	        transform: translate3d(0, 0, 0);
}

html.mobile .ui-to-top,
html.tablet .ui-to-top {
	display: none !important;
}

@media (min-width: 576px) {
	.ui-to-top {
		right: 40px;
		bottom: 40px;
	}
}


/*
*
* RD Navbar
*/

@-webkit-keyframes rd-navbar-slide-down {
	0% {
		-webkit-transform: translateY(-100%);
		        transform: translateY(-100%);
	}
	100% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

@keyframes rd-navbar-slide-down {
	0% {
		-webkit-transform: translateY(-100%);
		        transform: translateY(-100%);
	}
	100% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

@-webkit-keyframes rd-navbar-slide-up {
	0% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
	100% {
		-webkit-transform: translateY(-100%);
		        transform: translateY(-100%);
	}
}

@keyframes rd-navbar-slide-up {
	0% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
	100% {
		-webkit-transform: translateY(-100%);
		        transform: translateY(-100%);
	}
}

.rd-navbar-wrap,
.rd-navbar,
.rd-menu,
.rd-navbar-nav,
.rd-navbar-panel,
.rd-navbar-static .rd-menu,
.rd-navbar-fixed .rd-navbar-nav-wrap,
.rd-navbar-fixed .rd-navbar-submenu {
	transition: 0.35s all cubic-bezier(0.65, 0.05, 0.36, 1);
}

.rd-navbar--no-transition,
.rd-navbar--no-transition * {
	transition: none !important;
}

.rd-navbar,
.rd-navbar.rd-navbar--is-clone {
	display: none;
}

.rd-navbar.rd-navbar-fixed+.rd-navbar.rd-navbar--is-clone,
.rd-navbar.rd-navbar-sidebar+.rd-navbar.rd-navbar--is-clone {
	display: none;
}

.rd-navbar {
	background: #ffffff;
}

.rd-navbar-toggle {
	display: inline-block;
	position: relative;
	width: 40px;
	height: 40px;
	line-height: 40px;
	cursor: pointer;
	color: #2c343b;
	background-color: transparent;
	border: none;
	display: none;
}

.rd-navbar-toggle span {
	position: relative;
	display: block;
	margin: auto;
	transition: .3s all ease;
}

.rd-navbar-toggle span::after,
.rd-navbar-toggle span::before {
	content: "";
	position: absolute;
	left: 0;
	top: -6px;
	transition: .3s all ease;
}

.rd-navbar-toggle span::after {
	top: 6px;
}

.rd-navbar-toggle span::after,
.rd-navbar-toggle span::before,
.rd-navbar-toggle span {
	width: 24px;
	height: 2px;
	background-color: #2c343b;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
	border-radius: 0;
}

.rd-navbar-toggle span::before,
.rd-navbar-toggle span::after {
	-webkit-transform-origin: 1px center;
	        transform-origin: 1px center;
}

.rd-navbar-toggle.active span::before,
.rd-navbar-toggle.active span::after {
	top: 0;
	width: 15px;
}

.rd-navbar-toggle.active span::before {
	-webkit-transform: rotate(-40deg);
	        transform: rotate(-40deg);
}

.rd-navbar-toggle.active span::after {
	-webkit-transform: rotate(40deg);
	        transform: rotate(40deg);
}

.rd-navbar-collapse-toggle {
	display: inline-block;
	position: relative;
	width: 40px;
	height: 40px;
	line-height: 40px;
	cursor: pointer;
	color: #151515;
	display: none;
}

.rd-navbar-collapse-toggle span {
	top: 50%;
	margin-top: -2.5px;
}

.rd-navbar-collapse-toggle span,
.rd-navbar-collapse-toggle span::before,
.rd-navbar-collapse-toggle span::after {
	position: absolute;
	width: 5px;
	height: 5px;
	line-height: 5px;
	text-align: center;
	background: #151515;
	left: 50%;
	margin-left: -2.5px;
	border-radius: 50%;
	transition: .3s all ease;
}

.rd-navbar-collapse-toggle span::before,
.rd-navbar-collapse-toggle span::after {
	content: '';
}

.rd-navbar-collapse-toggle span::before {
	bottom: 100%;
	margin-bottom: 2.5px;
}

.rd-navbar-collapse-toggle span::after {
	top: 100%;
	margin-top: 2.5px;
}

.rd-navbar-collapse-toggle.active span {
	-webkit-transform: scale(0.7);
	        transform: scale(0.7);
}

.rd-navbar-collapse-toggle.active span::before {
	-webkit-transform: translateY(15px);
	        transform: translateY(15px);
}

.rd-navbar-collapse-toggle.active span::after {
	-webkit-transform: translateY(-15px);
	        transform: translateY(-15px);
}

.rd-navbar-brand a {
	display: block;
	margin-left:-156px;
}

.rd-nav-link {
	font: 500 13px/20px 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	letter-spacing: .1em;
	text-transform: uppercase;
}

.rd-navbar-dropdown {
	display: none;
}

.rd-megamenu-title {
	font-size: 16px;
	font-weight: 600;
	letter-spacing: .025em;
}

.rd-navbar-popup {
	position: absolute;
	right: 0;
	z-index: 1030;
	padding: 15px 25px 20px 20px;
	width: calc(100vw - 10px);
	opacity: 0;
	visibility: hidden;
	transition: .2s;
	overflow: hidden;
	border: 1px solid #e0e0e0;
}

.rd-navbar-popup>* {
	position: relative;
	z-index: 1;
	opacity: 0;
	visibility: hidden;
	transition: .5s;
	-webkit-transform-origin: 50% 0;
	        transform-origin: 50% 0;
	-webkit-transform: scale3d(1, 0.95, 1);
	        transform: scale3d(1, 0.95, 1);
}

.rd-navbar-popup.active {
	opacity: 1;
	visibility: visible;
}

.rd-navbar-popup.active>* {
	opacity: 1;
	visibility: visible;
	-webkit-transform: scale3d(1, 1, 1);
	        transform: scale3d(1, 1, 1);
}

.rd-navbar-popup .rd-navbar-popup-title {
	color: #151515;
}

@media (min-width: 400px) {
	.rd-navbar-popup {
		width: 370px;
	}
}

.ios.landscape.mobile .rd-navbar-popup {
	width: 410px;
	padding: 30px 45px;
}

@media (min-width: 768px) {
	.rd-navbar-popup {
		padding: 30px;
	}
}

@media (max-height: 875px) {
	.rd-navbar-classic.rd-navbar-static .rd-navbar-popup{
		max-height: calc(100vh - 135px);
		overflow-y: auto;
	}
}

/*
* @subsection  RD Navbar Static
*/

.rd-navbar-static {
	display: block;
}

.rd-navbar-static .rd-nav-item {
	display: inline-block;
}

.rd-navbar-static .rd-nav-item.focus .rd-nav-link,
.rd-navbar-static .rd-nav-item.opened .rd-nav-link {
	color: #0A043B;
	background: transparent;
}

.rd-navbar-static .rd-nav-item.focus>.rd-navbar-submenu-toggle,
.rd-navbar-static .rd-nav-item.opened>.rd-navbar-submenu-toggle {
	color: #0A043B;
}

.rd-navbar-static .rd-nav-item.active .rd-nav-link {
	color: #0A043B;
	background: transparent;
}

.rd-navbar-static .rd-nav-item.active>.rd-navbar-submenu-toggle {
	color: #0A043B;
}

.rd-navbar-static .rd-nav-item.focus>.rd-navbar-submenu-toggle,
.rd-navbar-static .rd-nav-item.opened>.rd-navbar-submenu-toggle,
.rd-navbar-static .rd-nav-item .rd-nav-link:hover+.rd-navbar-submenu-toggle {
	color: #0A043B;
}

.rd-navbar-static .rd-nav-item.focus>.rd-navbar-submenu-toggle::before,
.rd-navbar-static .rd-nav-item.opened>.rd-navbar-submenu-toggle::before,
.rd-navbar-static .rd-nav-item .rd-nav-link:hover+.rd-navbar-submenu-toggle::before {
	-webkit-transform: rotate(180deg);
	        transform: rotate(180deg);
}

.rd-navbar-static .rd-nav-item>.rd-navbar-submenu-toggle {
	font-family: "Material Design Icons";
	font-size: 18px;
	cursor: pointer;
	color: #151515;
}

.rd-navbar-static .rd-nav-item>.rd-navbar-submenu-toggle::before {
	content: '\f236';
	position: relative;
	top: 2px;
	display: inline-block;
	transition: .22s;
}

.rd-navbar-static .rd-nav-item>.rd-navbar-submenu {
	margin-top: 20px;
}

.rd-navbar-static .rd-nav-item+.rd-nav-item {
	margin-left: 25px;
}

.rd-navbar-static .rd-nav-link {
	position: relative;
	display: inline-block;
	color: #151515;
	transition: .25s;
}

.rd-navbar-static .rd-nav-link:hover {
	color: #0A043B;
}

.rd-navbar-static .rd-menu {
	z-index: 15;
	position: absolute;
	display: block;
	visibility: hidden;
	opacity: 0;
	text-align: left;
	box-shadow: 0 13px 16px 0 rgba(0, 0, 0, 0.13);
	-webkit-transform: translate3d(0, 10px, 0);
	        transform: translate3d(0, 10px, 0);
}

.rd-navbar-static .rd-nav-item>.rd-navbar-dropdown::before {
	content: '';
	position: absolute;
	top: -12px;
	left: 0;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 12px 0 0 18px;
	border-color: transparent transparent transparent #ffffff;
}

.rd-navbar-static .rd-navbar-dropdown {
	position: absolute;
	top: 100%;
	left: 0;
	width: 270px;
	padding: 17px 30px 23px;
	background: #ffffff;
	z-index: 5;
}

.rd-navbar-static .rd-navbar-dropdown .rd-navbar-dropdown {
	top: -1px;
	left: 100%;
	z-index: 2;
	margin-top: -15px;
	margin-left: 15px;
	-webkit-transform: translate3d(10px, 0, 0);
	        transform: translate3d(10px, 0, 0);
}

.rd-navbar-static .rd-navbar-dropdown .rd-navbar-dropdown.rd-navbar-open-left {
	left: auto;
	right: 100%;
	margin-left: 0;
	margin-right: 15px;
	-webkit-transform: translate3d(-10px, 0, 0);
	        transform: translate3d(-10px, 0, 0);
}

.rd-navbar-static .rd-dropdown-item.focus .rd-dropdown-link,
.rd-navbar-static .rd-dropdown-item.opened .rd-dropdown-link {
	color: #0A043B;
	font-size: 16px !important;
}

.rd-navbar-static .rd-dropdown-link {
	color: #151515;
	font-size: 16px !important;
}

.rd-navbar-static .rd-dropdown-link:hover {
	color: #0A043B;
	font-size: 16px !important;
}

.rd-navbar-static .rd-navbar-dropdown li>a,
.rd-navbar-static .rd-megamenu-list li>a {
	position: relative;
	display: flex;
	padding: 2px 0 2px 17px;
	text-align: left;
}

.rd-navbar-static .rd-navbar-dropdown li>a,
.rd-navbar-static .rd-navbar-dropdown li>a:focus,
.rd-navbar-static .rd-navbar-dropdown li>a:active,
.rd-navbar-static .rd-megamenu-list li>a,
.rd-navbar-static .rd-megamenu-list li>a:focus,
.rd-navbar-static .rd-megamenu-list li>a:active {
	color: #151515;
}

.rd-navbar-static .rd-navbar-dropdown li>a:hover,
.rd-navbar-static .rd-megamenu-list li>a:hover {
	color: #0A043B;
}

.rd-navbar-static .rd-navbar-dropdown li>a,
.rd-navbar-static .rd-navbar-dropdown li>a::before,
.rd-navbar-static .rd-megamenu-list li>a,
.rd-navbar-static .rd-megamenu-list li>a::before {
	transition: .15s ease-in-out;
}

/* .menu-item::before {
	content: "";
	position: absolute;
	top: 14px;
	left: 0;
	display: inline-block;
	line-height: 0;
	font-size: 17px;
	color: black;
}

.menu-item {
	margin-right: 10px;
} */
/* .menu-item::before:nth-child(1){
	content: "\f2be";
	position: absolute;
	top: 15px;
	left: 0;
	display: inline-block;
	line-height: 0;
	font-size: 14px;
	color: black;
} */
/* .rd-navbar-static .rd-navbar-dropdown li>a::before,
.rd-navbar-static .rd-megamenu-list li>a::before {
	content: '>' !important;
	position: absolute;
	top: 10px;
	left: 0;
	display: inline-block;
	width: 0;
	height: 0;
	font-size: 0;
	line-height: 0;
	border-style: solid;
	border-width: 4px 0 4px 10px;
	border-color: transparent transparent transparent #b7b7b7;
} */

.rd-navbar-static .rd-navbar-dropdown li.focus>a::before,
.rd-navbar-static .rd-navbar-dropdown li.opened>a::before,
.rd-navbar-static .rd-navbar-dropdown li>a:hover::before,
.rd-navbar-static .rd-megamenu-list li.focus>a::before,
.rd-navbar-static .rd-megamenu-list li.opened>a::before,
.rd-navbar-static .rd-megamenu-list li>a:hover::before {
	border-left-color: #0A043B;
}

.rd-navbar-static .rd-navbar-dropdown li+li,
.rd-navbar-static .rd-megamenu-list li+li {
	margin-top: 8px;
}

.rd-navbar-static .rd-navbar-megamenu {
	display: flex;
	top: 100%;
	left: 0;
	width: 100%;
	max-width: 1170px;
	padding: 30px 40px;
	background: #ffffff;
}

.rd-navbar-static .rd-megamenu-item {
	flex-basis: 50%;
	flex-grow: 1;
}

.rd-navbar-static .rd-megamenu-item+.rd-megamenu-item {
	padding-left: 20px;
}

.rd-navbar-static .rd-megamenu-list-link {
	color: #000000;
	background: transparent;
	font-size: 16px;
}

.rd-navbar-static .rd-megamenu-list-link:hover {
	color: #0A043B;
	background: transparent;
}

.rd-navbar-static *+.rd-megamenu-list {
	margin-top: 17px;
}

.rd-navbar-static .rd-navbar-submenu.focus>.rd-menu,
.rd-navbar-static .rd-navbar-submenu.opened>.rd-menu {
	opacity: 1;
	visibility: visible;
	-webkit-transform: translate3d(0, 0, 0);
	        transform: translate3d(0, 0, 0);
}

@media (max-width: 1199.98px) {
	.rd-navbar-static .rd-nav-link {
		font-size: 12px;
	}
}

/* @media (min-width: 1200px) {
	.rd-navbar-static .rd-nav-item+.rd-nav-item {
		margin-left: 50px;
	}
} */

@media (min-width: 1600px) {
	.rd-navbar-static .rd-navbar-megamenu {
		padding: 30px 52px;
	}
	.rd-navbar-static .rd-megamenu-item+.rd-megamenu-item {
		margin-left: 56px;
	}
}

.rd-navbar-static.rd-navbar--is-clone {
	display: block;
	-webkit-transform: translate3d(0, -100%, 0);
	        transform: translate3d(0, -100%, 0);
}

.rd-navbar-static.rd-navbar--is-clone.rd-navbar--is-stuck {
	-webkit-transform: translate3d(0, 0, 0);
	        transform: translate3d(0, 0, 0);
}

.rd-navbar-static.rd-navbar--is-stuck,
.rd-navbar-static.rd-navbar--is-clone {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	z-index: 1030;
	background: #ffffff;
}

.rd-navbar-static .rd-navbar--has-dropdown {
	position: relative;
}

.context-dark .rd-navbar-static.rd-navbar--is-stuck,
.bg-gray-700 .rd-navbar-static.rd-navbar--is-stuck,
.bg-blue-13 .rd-navbar-static.rd-navbar--is-stuck,
.bg-blue-15 .rd-navbar-static.rd-navbar--is-stuck,
.bg-primary .rd-navbar-static.rd-navbar--is-stuck {
	background: #2c343b;
}


/*
*
* RD Navbar Fixed
*/

.rd-navbar-fixed {
	display: block;
}

.rd-navbar-fixed .rd-navbar-toggle {
	display: inline-block;
}

.ios .rd-navbar-fixed .rd-navbar-toggle {
	left: -7px;
}

.rd-navbar-fixed .rd-navbar-brand {
	position: relative;
	margin-left: 6px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	text-align: left;
	font-size: 22px;
	line-height: 46px;
}

.rd-navbar-fixed .rd-navbar-brand img {
	max-width: 100px;
	max-height: 100px;
	/* width: auto; */
	/* height: auto; */
}

/* @media (max-width: 374px) {
	.rd-navbar-fixed .rd-navbar-brand img {
		max-width: 60px;
	}
} */

.rd-navbar-fixed .rd-navbar-panel {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	z-index: 1030;
	display: flex;
	align-items: center;
	height: 56px;
	padding: 8px;
}

.rd-navbar-fixed .rd-navbar-nav-wrap {
	position: fixed;
	left: 0;
	top: -56px;
	bottom: -56px;
	z-index: 1029;
	width: 270px;
	padding: 112px 0 81px;
	overflow-y: auto;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
	font-size: 14px;
	line-height: 34px;
	-webkit-transform: translateX(-110%);
	        transform: translateX(-110%);
}

.rd-navbar-fixed .rd-navbar-nav-wrap::-webkit-scrollbar {
	width: 4px;
}

.rd-navbar-fixed .rd-navbar-nav-wrap::-webkit-scrollbar-thumb {
	background: #6cb6f5;
	border: none;
	border-radius: 0;
	opacity: .2;
}

.rd-navbar-fixed .rd-navbar-nav-wrap::-webkit-scrollbar-track {
	background: #0A043B;
	border: none;
	border-radius: 0;
}

.rd-navbar-fixed .rd-navbar-nav-wrap.active {
	-webkit-transform: translateX(0);
	        transform: translateX(0);
}

.rd-navbar-fixed .rd-navbar-nav {
	display: block;
	margin: 15px 0;
	height: auto;
	text-align: left;
}

.rd-navbar-fixed .rd-nav-item {
	text-align: left;
}

.rd-navbar-fixed .rd-nav-item+.rd-nav-item {
	margin-top: 4px;
}

.rd-navbar-fixed .rd-nav-link {
	display: block;
	padding: 14px 56px 14px 18px;
}

.rd-navbar-fixed li.opened>.rd-navbar-dropdown {
	padding: 4px 0 0;
}

.rd-navbar-fixed li.opened>.rd-navbar-megamenu {
	padding-top: 15px;
	padding-bottom: 15px;
}

.rd-navbar-fixed li.opened>.rd-menu {
	opacity: 1;
	height: auto;
}

.rd-navbar-fixed li.opened>.rd-navbar-submenu-toggle::after {
	top: -3px;
	-webkit-transform: rotate(180deg);
	        transform: rotate(180deg);
}

.rd-navbar-fixed .rd-menu {
	display: none;
	transition: opacity 0.3s, height 0.4s ease;
	opacity: 0;
	height: 0;
	overflow: hidden;
}

.rd-navbar-fixed .rd-navbar-submenu {
	position: relative;
}

.rd-navbar-fixed .rd-navbar-submenu .rd-navbar-dropdown .rd-navbar-submenu-toggle:after {
	height: 34px;
	line-height: 34px;
}

.rd-navbar-fixed .rd-navbar-submenu .rd-navbar-dropdown>li>a {
	padding-left: 30px;
}

.rd-navbar-fixed .rd-navbar-submenu .rd-navbar-dropdown li li>a,
.rd-navbar-fixed .rd-navbar-submenu .rd-navbar-megamenu ul li li>a {
	padding-left: 46px;
}

.rd-navbar-fixed .rd-navbar-submenu.opened>.rd-navbar-dropdown,
.rd-navbar-fixed .rd-navbar-submenu.opened>.rd-navbar-megamenu {
	display: block;
}

.rd-navbar-fixed .rd-megamenu-list>li>a,
.rd-navbar-fixed .rd-navbar-dropdown>li>a {
	display: block;
	padding: 9px 56px 9px 16px;
	font-size: 12px;
	line-height: 1.5;
}

.rd-navbar-fixed .rd-megamenu-list>li+li,
.rd-navbar-fixed .rd-navbar-dropdown>li+li {
	margin-top: 3px;
}

.rd-navbar-fixed .rd-megamenu-list>li>a {
	padding-left: 30px;
}

.rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title {
	position: relative;
	display: block;
	padding: 0 18px 7px;
	font-size: 15px;
}

.rd-navbar-fixed .rd-navbar-megamenu *+.rd-megamenu-list {
	margin-top: 11px;
}

.rd-navbar-fixed .rd-navbar-megamenu *+.rd-megamenu-title {
	margin-top: 20px;
}

.rd-navbar-fixed .rd-navbar-megamenu>li+li {
	margin-top: 20px;
}

.rd-navbar-fixed .rd-navbar-submenu-toggle {
	cursor: pointer;
}

.rd-navbar-fixed .rd-navbar-submenu-toggle::after {
	content: '\f107';
	position: absolute;
	top: 0;
	right: 0;
	width: 56px;
	height: 52px;
	font: 400 14px "FontAwesome";
	line-height: 48px;
	text-align: center;
	transition: 0.4s all ease;
	z-index: 2;
	cursor: pointer;
}

.rd-navbar-fixed .rd-navbar-collapse-toggle {
	display: block;
	top: 8px;
	z-index: 1031;
}

.rd-navbar-fixed .rd-navbar-collapse {
	position: fixed;
	right: 0;
	top: 56px;
	z-index: 1029;
	-webkit-transform: translate3d(0, -10px, 0);
	        transform: translate3d(0, -10px, 0);
	padding: 15px;
	width: auto;
	border-radius: 0;
	text-align: left;
	font-size: 14px;
	opacity: 0;
	visibility: hidden;
	transition: .3s;
}

.rd-navbar-fixed .rd-navbar-collapse.active {
	-webkit-transform: translate3d(0, 0, 0);
	        transform: translate3d(0, 0, 0);
	opacity: 1;
	visibility: visible;
}

.rd-navbar-fixed .rd-navbar-collapse .list-inline>li {
	display: block;
}

.rd-navbar-fixed .rd-navbar-collapse .list-inline>li+li {
	margin-top: 10px;
}

.rd-navbar-fixed .rd-navbar-main-element {
	position: absolute;
	float: left;
}

.rd-navbar-fixed .rd-navbar-search .rd-search {
	position: fixed;
	right: 0;
	left: 0;
	top: 56px;
	z-index: 1029;
	width: 100%;
	opacity: 0;
	visibility: hidden;
	transition: .3s;
	-webkit-transform: translate3d(0, -10px, 0);
	        transform: translate3d(0, -10px, 0);
}

.rd-navbar-fixed .rd-navbar-search .rd-search-results-live {
	display: none;
}

.rd-navbar-fixed .rd-navbar-search .form-input {
	padding-right: 50px;
}

.rd-navbar-fixed .rd-navbar-search .rd-search-form-submit {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	width: 50px;
	padding: 0;
	margin: 0;
	border: none;
	background-color: transparent;
}

.rd-navbar-fixed .rd-navbar-search.active .rd-search {
	opacity: 1;
	visibility: visible;
	-webkit-transform: none;
	        transform: none;
}

.rd-navbar-fixed .rd-navbar-search-toggle {
	display: inline-flex;
}

.rd-navbar-fixed .rd-navbar-search-toggle span {
	display: inline-block;
	position: relative;
	width: 48px;
	height: 48px;
	font-size: 26px;
	line-height: 48px;
	text-align: center;
	cursor: pointer;
	background: none;
	border: none;
	outline: none;
	padding: 0;
}

.rd-navbar-fixed .rd-navbar-search-toggle span,
.rd-navbar-fixed .rd-navbar-search-toggle span::before,
.rd-navbar-fixed .rd-navbar-search-toggle span::after {
	transition: .3s all ease-in-out;
}

.rd-navbar-fixed .rd-navbar-search-toggle span::before,
.rd-navbar-fixed .rd-navbar-search-toggle span::after {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.rd-navbar-fixed .rd-navbar-search-toggle span::before {
	content: "";
	-webkit-transform: rotate(0deg) scale(1);
	        transform: rotate(0deg) scale(1);
	opacity: 1;
	visibility: visible;
	font-family: "Material Design Icons";
}

.rd-navbar-fixed .rd-navbar-search-toggle span::after {
	content: "";
	-webkit-transform: rotate(-90deg) scale(0.4);
	        transform: rotate(-90deg) scale(0.4);
	opacity: 0;
	visibility: hidden;
	font-family: "Material Design Icons";
}

.rd-navbar-fixed .rd-navbar-search-toggle.active span::before {
	opacity: 0;
	visibility: hidden;
	-webkit-transform: rotate(90deg) scale(0.4);
	        transform: rotate(90deg) scale(0.4);
}

.rd-navbar-fixed .rd-navbar-search-toggle.active span::after {
	-webkit-transform: rotate(0deg) scale(1);
	        transform: rotate(0deg) scale(1);
	opacity: 1;
	visibility: visible;
}

.rd-navbar-fixed [class*='rd-navbar-fixed-element'] {
	position: fixed;
	top: 8px;
	z-index: 1032;
}

.rd-navbar-fixed .rd-navbar-fixed-element-1 {
	right: 0;
}

.rd-navbar-fixed .rd-navbar-fixed-element-2 {
	right: 46px;
}

.rd-navbar-fixed.rd-navbar--is-clone {
	display: none;
}

.rd-navbar-fixed .rd-navbar-fixed--visible {
	display: block;
}

.rd-navbar-fixed .rd-navbar-fixed--hidden {
	display: none;
}

.rd-navbar-fixed .rd-navbar-panel {
	color: #151515;
	box-shadow: none;
	background: #ffffff;
}

.rd-navbar-fixed .rd-navbar-nav-wrap {
	color: #ffffff;
	background: #ffffff;
	border: 1px solid #e0e0e0;
	box-shadow: none;
}

.rd-navbar-fixed .rd-nav-item:hover .rd-nav-link,
.rd-navbar-fixed .rd-nav-item.focus .rd-nav-link,
.rd-navbar-fixed .rd-nav-item.active .rd-nav-link,
.rd-navbar-fixed .rd-nav-item.opened .rd-nav-link {
	color: #ffffff;
	/* background: #0A043B; */
}

.rd-navbar-fixed .rd-nav-item:hover>.rd-navbar-submenu-toggle,
.rd-navbar-fixed .rd-nav-item.focus>.rd-navbar-submenu-toggle,
.rd-navbar-fixed .rd-nav-item.active>.rd-navbar-submenu-toggle,
.rd-navbar-fixed .rd-nav-item.opened>.rd-navbar-submenu-toggle {
	color: #150F3D;
}

.rd-navbar-fixed .rd-nav-link {
	color: #151515;
}

.rd-navbar-fixed .rd-megamenu-list>li>a,
.rd-navbar-fixed .rd-navbar-dropdown>li>a {
	color: #151515;
}

.rd-navbar-fixed .rd-megamenu-list>li:hover>a,
.rd-navbar-fixed .rd-megamenu-list>li.focus>a,
.rd-navbar-fixed .rd-megamenu-list>li.active>a,
.rd-navbar-fixed .rd-megamenu-list>li.opened>a,
.rd-navbar-fixed .rd-navbar-dropdown>li:hover>a,
.rd-navbar-fixed .rd-navbar-dropdown>li.focus>a,
.rd-navbar-fixed .rd-navbar-dropdown>li.active>a,
.rd-navbar-fixed .rd-navbar-dropdown>li.opened>a {
	color: #ffffff;
	background: #0A043B;
}

.rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title {
	color: #151515;
}

.rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title a,
.rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title a:focus,
.rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title a:active {
	color: inherit;
}

.rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title a:hover {
	color: #0A043B;
}

.rd-navbar-fixed .rd-navbar-submenu-toggle {
	color: #151515;
}

.rd-navbar-fixed .rd-navbar-search .rd-search-form-submit {
	color: #d6d6d6;
}

.rd-navbar-fixed .rd-navbar-search .rd-search-form-submit:hover {
	color: #0A043B;
}

.rd-navbar-fixed .rd-navbar-search-toggle {
	color: #151515;
}

.rd-navbar-fixed .rd-navbar-search-toggle:hover {
	color: #0A043B;
}

.rd-navbar-fixed .rd-navbar-collapse {
	background-color: #ffffff;
	box-shadow: 0 0 22px -4px rgba(0, 0, 0, 0.17);
}

.rd-navbar-fixed .rd-navbar-collapse,
.rd-navbar-fixed .rd-navbar-collapse a {
	color: #151515;
}

.context-dark .rd-navbar-fixed .rd-navbar-panel,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-panel,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-panel,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-panel,
.bg-primary .rd-navbar-fixed .rd-navbar-panel {
	background: #2c343b;
}

.context-dark .rd-navbar-fixed .rd-navbar-toggle,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle {
	display: inline-block;
	position: relative;
	width: 40px;
	height: 40px;
	line-height: 40px;
	cursor: pointer;
	color: #ffffff;
}

.context-dark .rd-navbar-fixed .rd-navbar-toggle span,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle span,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle span,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle span,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle span {
	position: relative;
	display: block;
	margin: auto;
	transition: .3s all ease;
}

.context-dark .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle span::after,
.context-dark .rd-navbar-fixed .rd-navbar-toggle span::before,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle span::before,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle span::before,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle span::before,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle span::before {
	content: "";
	position: absolute;
	left: 0;
	top: -6px;
	transition: .3s all ease;
}

.context-dark .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle span::after {
	top: 6px;
}

.context-dark .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle span::after,
.context-dark .rd-navbar-fixed .rd-navbar-toggle span::before,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle span::before,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle span::before,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle span::before,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle span::before,
.context-dark .rd-navbar-fixed .rd-navbar-toggle span,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle span,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle span,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle span,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle span {
	width: 24px;
	height: 2px;
	background-color: #ffffff;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
	border-radius: 0;
}

.context-dark .rd-navbar-fixed .rd-navbar-toggle span::before,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle span::before,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle span::before,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle span::before,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle span::before,
.context-dark .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle span::after {
	-webkit-transform-origin: 1px center;
	        transform-origin: 1px center;
}

.context-dark .rd-navbar-fixed .rd-navbar-toggle.active span::before,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle.active span::before,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle.active span::before,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle.active span::before,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle.active span::before,
.context-dark .rd-navbar-fixed .rd-navbar-toggle.active span::after,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle.active span::after,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle.active span::after,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle.active span::after,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle.active span::after {
	top: 0;
	width: 15px;
}

.context-dark .rd-navbar-fixed .rd-navbar-toggle.active span::before,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle.active span::before,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle.active span::before,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle.active span::before,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle.active span::before {
	-webkit-transform: rotate(-40deg);
	        transform: rotate(-40deg);
}

.context-dark .rd-navbar-fixed .rd-navbar-toggle.active span::after,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle.active span::after,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle.active span::after,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle.active span::after,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle.active span::after {
	-webkit-transform: rotate(40deg);
	        transform: rotate(40deg);
}

html.rd-navbar-fixed-linked .page {
	padding-top: 56px;
}

@media(max-width: 768px){
	html.rd-navbar-fixed-linked .page {
		padding-top: 0px !important;
	}	
}

/*
*
* RD Navbar Sidebar
*/

.rd-navbar-sidebar {
	display: block;
	background: #ffffff;
}

.rd-navbar-sidebar .rd-navbar-main-outer {
	padding-left: 15px;
	padding-right: 15px;
}

.rd-navbar-sidebar .rd-navbar-main {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 22px 60px 22px 0;
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
	transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.rd-navbar-sidebar .rd-navbar-main-element {
	display: flex;
	align-items: center;
}

.rd-navbar-sidebar .rd-navbar-main-element>*+* {
	margin-left: 20px;
}

.rd-navbar-sidebar .rd-navbar-toggle {
	position: absolute;
	right: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	display: block;
	z-index: 1033;
}

.rd-navbar-sidebar .rd-navbar-panel {
	min-width: 200px;
	text-align: center;
}

.rd-navbar-sidebar .rd-navbar-brand img {
	/* width: auto;
    height: auto; */
	max-width: 100px;
	max-height: 100px;
}

.rd-navbar-sidebar .rd-navbar-nav-wrap {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 1032;
	width: 450px;
	padding: 94px 0 0 0;
	background: #ffffff;
	transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
	-webkit-transform: translateX(100%);
	        transform: translateX(100%);
	box-shadow: 0 0 13px 0 rgba(214, 214, 214, 0.16);
}

.rd-navbar-sidebar .rd-navbar-nav-wrap.active {
	transition-delay: .1s;
	-webkit-transform: translateX(0);
	        transform: translateX(0);
}

.rd-navbar-sidebar .rd-navbar-nav {
	height: calc(100vh - 100px);
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	border-top: 1px solid #e0e0e0;
}

.rd-navbar-sidebar .rd-nav-item {
	position: relative;
	display: block;
	padding: 19px 30px 19px 40px;
}

.rd-navbar-sidebar .rd-nav-item.active .rd-nav-link {
	color: #0A043B;
}

.rd-navbar-sidebar .rd-nav-item .rd-nav-link,
.rd-navbar-sidebar .rd-nav-item>.rd-navbar-submenu-toggle {
	font-size: 18px;
}

.rd-navbar-sidebar .rd-nav-item+.rd-nav-item {
	border-top: 1px solid #e0e0e0;
}

.rd-navbar-sidebar .rd-nav-item:last-child {
	border-bottom: 1px solid #e0e0e0;
	margin-bottom: 30px;
}

.rd-navbar-sidebar .rd-nav-link {
	max-width: calc(100% - 30px);
	word-break: break-all;
	font-size: 18px;
	line-height: 1.4;
	text-transform: none;
	color: #d6d6d6;
}

.rd-navbar-sidebar .rd-nav-link:hover {
	color: #0A043B;
}

.rd-navbar-sidebar .rd-navbar-submenu .opened>.rd-navbar-submenu-toggle::after {
	-webkit-transform: rotate(180deg);
	        transform: rotate(180deg);
}

.rd-navbar-sidebar .rd-navbar-submenu>.rd-navbar-dropdown,
.rd-navbar-sidebar .rd-navbar-submenu>.rd-navbar-megamenu {
	display: none;
	opacity: 0;
	visibility: hidden;
	will-change: opacity, visibility;
	transition: opacity .2s;
}

.rd-navbar-sidebar .rd-navbar-submenu.opened>.rd-navbar-dropdown,
.rd-navbar-sidebar .rd-navbar-submenu.opened>.rd-navbar-megamenu {
	display: block;
	opacity: 1;
	visibility: visible;
}

.rd-navbar-sidebar .rd-navbar-submenu>.rd-navbar-submenu>.rd-navbar-dropdown,
.rd-navbar-sidebar .rd-navbar-submenu>.rd-navbar-submenu>.rd-navbar-megamenu {
	-webkit-transform: translateY(30px);
	        transform: translateY(30px);
}

.rd-navbar-sidebar .rd-navbar-submenu>.rd-navbar-submenu.opened>.rd-navbar-dropdown,
.rd-navbar-sidebar .rd-navbar-submenu>.rd-navbar-submenu.opened>.rd-navbar-megamenu {
	-webkit-transform: translateY(0);
	        transform: translateY(0);
}

.rd-navbar-sidebar .rd-navbar-submenu>.rd-navbar-submenu .rd-navbar-submenu>.rd-navbar-dropdown {
	-webkit-transform: translateX(-20px);
	        transform: translateX(-20px);
}

.rd-navbar-sidebar .rd-navbar-submenu>.rd-navbar-submenu .rd-navbar-submenu.opened>.rd-navbar-dropdown {
	-webkit-transform: translateX(0);
	        transform: translateX(0);
}

.rd-navbar-sidebar .rd-nav-item>.rd-navbar-submenu-toggle,
.rd-navbar-sidebar .rd-nav-item .rd-navbar--has-dropdown>.rd-navbar-submenu-toggle {
	position: absolute;
	top: 0;
	right: 0;
	padding-top: inherit;
	padding-right: inherit;
	padding-left: 10px;
	margin-bottom: inherit;
	display: inline-block;
	width: 30px;
	margin-left: 5px;
	text-align: center;
	cursor: pointer;
	color: #151515;
}

.rd-navbar-sidebar .rd-nav-item>.rd-navbar-submenu-toggle:hover,
.rd-navbar-sidebar .rd-nav-item .rd-navbar--has-dropdown>.rd-navbar-submenu-toggle:hover {
	color: #0A043B;
}

.rd-navbar-sidebar .rd-nav-item>.rd-navbar-submenu-toggle::after,
.rd-navbar-sidebar .rd-nav-item .rd-navbar--has-dropdown>.rd-navbar-submenu-toggle::after {
	content: '\f107';
	position: relative;
	display: inline-block;
	font-family: "FontAwesome";
	font-size: inherit;
	line-height: inherit;
	text-align: center;
	vertical-align: middle;
	transition: 0.4s all ease;
	z-index: 2;
	will-change: transform;
}

.rd-navbar-sidebar .rd-nav-item .rd-navbar--has-dropdown>.rd-navbar-submenu-toggle {
	font-size: 14px;
	color: #d6d6d6;
	margin-left: 7px;
}

.rd-navbar-sidebar .rd-menu {
	margin-top: 31px;
}

.rd-navbar-sidebar .rd-navbar-dropdown,
.rd-navbar-sidebar .rd-megamenu-list {
	font-size: 14px;
}

.rd-navbar-sidebar .rd-navbar-dropdown li>a,
.rd-navbar-sidebar .rd-megamenu-list li>a {
	position: relative;
	display: flex;
	padding: 2px 0 2px 17px;
	text-align: left;
}

.rd-navbar-sidebar .rd-navbar-dropdown li>a,
.rd-navbar-sidebar .rd-navbar-dropdown li>a:focus,
.rd-navbar-sidebar .rd-navbar-dropdown li>a:active,
.rd-navbar-sidebar .rd-megamenu-list li>a,
.rd-navbar-sidebar .rd-megamenu-list li>a:focus,
.rd-navbar-sidebar .rd-megamenu-list li>a:active {
	color: #151515;
}

.rd-navbar-sidebar .rd-navbar-dropdown li>a:hover,
.rd-navbar-sidebar .rd-megamenu-list li>a:hover {
	color: #0A043B;
}

.rd-navbar-sidebar .rd-navbar-dropdown li>a,
.rd-navbar-sidebar .rd-navbar-dropdown li>a::before,
.rd-navbar-sidebar .rd-megamenu-list li>a,
.rd-navbar-sidebar .rd-megamenu-list li>a::before {
	transition: .15s ease-in-out;
}

.rd-navbar-sidebar .rd-navbar-dropdown li>a::before,
.rd-navbar-sidebar .rd-megamenu-list li>a::before {
	content: '';
	position: absolute;
	top: 10px;
	left: 0;
	display: inline-block;
	width: 0;
	height: 0;
	font-size: 0;
	line-height: 0;
	border-style: solid;
	border-width: 4px 0 4px 10px;
	border-color: transparent transparent transparent #b7b7b7;
}

.rd-navbar-sidebar .rd-navbar-dropdown li.focus>a::before,
.rd-navbar-sidebar .rd-navbar-dropdown li.opened>a::before,
.rd-navbar-sidebar .rd-navbar-dropdown li>a:hover::before,
.rd-navbar-sidebar .rd-megamenu-list li.focus>a::before,
.rd-navbar-sidebar .rd-megamenu-list li.opened>a::before,
.rd-navbar-sidebar .rd-megamenu-list li>a:hover::before {
	border-left-color: #0A043B;
}

.rd-navbar-sidebar .rd-navbar-dropdown li+li,
.rd-navbar-sidebar .rd-megamenu-list li+li {
	margin-top: 8px;
}

.rd-navbar-sidebar .rd-navbar-megamenu {
	max-width: 450px;
	margin-bottom: -30px;
}

.rd-navbar-sidebar .rd-navbar-megamenu>* {
	margin-bottom: 30px;
}

.rd-navbar-sidebar .rd-navbar-megamenu>li {
	display: inline-block;
	vertical-align: top;
	width: 45%;
}

.rd-navbar-sidebar .rd-megamenu-title {
	display: none;
}

.rd-navbar-sidebar .rd-megamenu-list {
	margin-top: 0;
}

.rd-navbar-sidebar .rd-megamenu-list>li+li {
	margin-top: 10px;
}

.rd-navbar-sidebar .rd-navbar-dropdown .rd-navbar--has-dropdown>a {
	padding-right: 0;
}

.rd-navbar-sidebar .rd-navbar-dropdown .rd-navbar--has-dropdown>a::before {
	display: none;
}

.rd-navbar-sidebar .rd-navbar-dropdown .rd-navbar--has-dropdown>a:hover {
	padding-left: 0;
}

.rd-navbar-sidebar .rd-navbar-dropdown .rd-navbar--has-dropdown.focus>a {
	padding-left: 0;
}

.rd-navbar-sidebar .rd-navbar-dropdown .rd-navbar--has-dropdown.opened>.rd-navbar-submenu-toggle {
	color: #0A043B;
}

.rd-navbar-sidebar .rd-navbar-dropdown .rd-navbar--has-dropdown.opened>.rd-navbar-submenu-toggle::after {
	top: 1px;
}

.rd-navbar-sidebar .rd-navbar-dropdown>li+li {
	margin-top: 10px;
}

.rd-navbar-sidebar .rd-nav-item>.rd-navbar-dropdown {
	margin-top: 25px;
}

.rd-navbar-sidebar .rd-nav-item>.rd-navbar-dropdown .rd-navbar-dropdown {
	margin-top: 10px;
	padding-left: 15px;
}

.rd-navbar-sidebar .rd-nav-item>.rd-navbar-dropdown .rd-navbar-dropdown>li>a {
	font-size: 12px;
}

@media (min-width: 1200px) {
	.rd-navbar-sidebar .rd-navbar-megamenu {
		margin-bottom: -30px;
	}
	.rd-navbar-sidebar .rd-navbar-megamenu>* {
		margin-bottom: 30px;
	}
	.rd-navbar-sidebar .rd-navbar-dropdown>li+li,
	.rd-navbar-sidebar .rd-megamenu-list>li+li {
		margin-top: 18px;
	}
}

@media (min-width: 1200px) {
	.rd-navbar-sidebar .rd-navbar-nav-wrap {
		width: auto;
		left: calc(50% + 600px - 80px);
	}
}

.rd-navbar-sidebar.rd-navbar--is-clone {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1000;
	-webkit-transform: translateY(-101%);
	        transform: translateY(-101%);
}

.rd-navbar-sidebar.rd-navbar--is-clone.rd-navbar--is-stuck {
	-webkit-transform: translateY(0);
	        transform: translateY(0);
}

.rd-navbar-sidebar.rd-navbar--is-stuck {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 1000;
	box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.1);
}

.rd-navbar-sidebar.rd-navbar--is-stuck .rd-navbar-main {
	padding-top: 11px;
	padding-bottom: 11px;
}


/**
*
* RD Navbar Classic
*/

.rd-navbar-classic .rd-navbar-aside {
	white-space: nowrap;
}

.rd-navbar-classic .rd-navbar-aside>* {
	margin-top: 0;
}

.rd-navbar-classic .rd-navbar-aside>*+* {
	margin-left: 5px;
}

.rd-navbar-classic .rd-navbar-aside-item {
	display: inline-block;
	vertical-align: middle;
	white-space: normal;
}

.rd-navbar-classic.rd-navbar-static {
	transition: none;
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-main-outer {
	display: flex;
	padding-left: 15px;
	padding-right: 15px;
	-webkit-transform: translate3d(0, 0, 0);
	        transform: translate3d(0, 0, 0);
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-panel {
	flex-grow: 1;
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-main {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	max-width: 930px;
	min-height: 90px;
	padding: 16px 0;
	margin-left: auto;
	margin-right: auto;
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-aside-item {
	position: relative;
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-popup {
	margin-top: 35px;
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-brand {
	max-width: 150px;
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-brand img {
	/* width: auto;
    height: auto; */
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-main-element {
	display: flex;
	align-items: center;
	padding-left: 20px;
	padding-right: 20px;
}

.rd-navbar-classic.rd-navbar-static .rd-nav-item>.rd-navbar-dropdown {
	margin-top: 28px;
}

@media (min-width: 1200px) {
	.rd-navbar-classic.rd-navbar-static .rd-navbar-main {
		min-height: 110px;
		/* max-width: 1170px; */
	}
	.rd-navbar-classic.rd-navbar-static .rd-navbar-main-element {
		padding-left: 50px;
		padding-right: 50px;
	}
	.rd-navbar-classic.rd-navbar-static .rd-navbar-brand {
		max-width: 143px;
	}
}

@media (min-width: 1600px) {
	.rd-navbar-classic.rd-navbar-static .rd-navbar-popup {
		left: 50%;
		-webkit-transform: translate3d(-50%, 0, 0);
		        transform: translate3d(-50%, 0, 0);
		right: auto;
	}
}

.rd-navbar-classic.rd-navbar-static.rd-navbar--is-stuck,
.rd-navbar-classic.rd-navbar-static.rd-navbar--is-clone {
	box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.1);
}

.rd-navbar-classic.rd-navbar-static.rd-navbar--is-stuck .rd-navbar-main,
.rd-navbar-classic.rd-navbar-static.rd-navbar--is-clone .rd-navbar-main {
	min-height: 75px;
}

.rd-navbar-classic.rd-navbar-static.rd-navbar--is-stuck .rd-nav-item>.rd-navbar-dropdown,
.rd-navbar-classic.rd-navbar-static.rd-navbar--is-clone .rd-nav-item>.rd-navbar-dropdown {
	margin-top: 18px;
}

.rd-navbar-classic.rd-navbar-static.rd-navbar--is-stuck .rd-navbar-popup,
.rd-navbar-classic.rd-navbar-static.rd-navbar--is-clone .rd-navbar-popup {
	margin-top: 17px;
}

.rd-navbar-classic.rd-navbar-static.rd-navbar--is-stuck .rd-navbar-nav-item>.rd-navbar-submenu,
.rd-navbar-classic.rd-navbar-static.rd-navbar--is-clone .rd-navbar-nav-item>.rd-navbar-submenu {
	margin-top: 17px;
}

.rd-navbar-classic.rd-navbar-fixed .rd-navbar-panel {
	box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.1);
}

.rd-navbar-classic.rd-navbar-fixed .rd-navbar-brand {
	max-height: 52px;
	max-width: 160px;
}

.rd-navbar-classic.rd-navbar-fixed .rd-navbar-aside {
	position: fixed;
	right: 8px;
	top: 0;
	display: flex;
	align-items: center;
	height: 56px;
	z-index: 1030;
}

.rd-navbar-classic.rd-navbar-fixed .rd-navbar-aside .button-icon {
	padding: 5px;
	font-size: 0;
}

.rd-navbar-classic.rd-navbar-fixed .rd-navbar-aside .button-icon .icon {
	padding: 0;
	font-size: 20px;
}

.rd-navbar-classic.rd-navbar-fixed .rd-navbar-popup {
	position: absolute;
	margin-top: 11px;
	width: 100vw;
	right: -8px;
	overflow-y: auto;
	max-height: calc(100vh - 56px - 10px);
}

@media (min-width: 576px) {
	.rd-navbar-classic.rd-navbar-fixed .rd-navbar-popup {
		width: 360px;
	}
}

.context-dark .rd-navbar-classic .rd-navbar-aside-item>.button-primary:not(:hover),
.bg-gray-700 .rd-navbar-classic .rd-navbar-aside-item>.button-primary:not(:hover),
.bg-blue-13 .rd-navbar-classic .rd-navbar-aside-item>.button-primary:not(:hover),
.bg-blue-15 .rd-navbar-classic .rd-navbar-aside-item>.button-primary:not(:hover),
.bg-primary .rd-navbar-classic .rd-navbar-aside-item>.button-primary:not(:hover) {
	background: #5c6367;
	border-color: #5c6367;
}

.context-dark .rd-navbar-classic .rd-navbar-aside-item>.button-primary:not(:hover) .icon,
.bg-gray-700 .rd-navbar-classic .rd-navbar-aside-item>.button-primary:not(:hover) .icon,
.bg-blue-13 .rd-navbar-classic .rd-navbar-aside-item>.button-primary:not(:hover) .icon,
.bg-blue-15 .rd-navbar-classic .rd-navbar-aside-item>.button-primary:not(:hover) .icon,
.bg-primary .rd-navbar-classic .rd-navbar-aside-item>.button-primary:not(:hover) .icon {
	color: #0A043B;
}

.context-dark .rd-navbar-classic .rd-navbar-aside-item>.button-primary-outline:not(:hover),
.bg-gray-700 .rd-navbar-classic .rd-navbar-aside-item>.button-primary-outline:not(:hover),
.bg-blue-13 .rd-navbar-classic .rd-navbar-aside-item>.button-primary-outline:not(:hover),
.bg-blue-15 .rd-navbar-classic .rd-navbar-aside-item>.button-primary-outline:not(:hover),
.bg-primary .rd-navbar-classic .rd-navbar-aside-item>.button-primary-outline:not(:hover) {
	color: #ffffff;
	border-color: #5c6367;
}

.context-dark .rd-navbar-classic .rd-navbar-aside-item>.button-primary-outline:not(:hover) .icon,
.bg-gray-700 .rd-navbar-classic .rd-navbar-aside-item>.button-primary-outline:not(:hover) .icon,
.bg-blue-13 .rd-navbar-classic .rd-navbar-aside-item>.button-primary-outline:not(:hover) .icon,
.bg-blue-15 .rd-navbar-classic .rd-navbar-aside-item>.button-primary-outline:not(:hover) .icon,
.bg-primary .rd-navbar-classic .rd-navbar-aside-item>.button-primary-outline:not(:hover) .icon {
	color: #0A043B;
}

.context-dark .rd-navbar-static.rd-navbar-classic .rd-nav-link,
.bg-gray-700 .rd-navbar-static.rd-navbar-classic .rd-nav-link,
.bg-blue-13 .rd-navbar-static.rd-navbar-classic .rd-nav-link,
.bg-blue-15 .rd-navbar-static.rd-navbar-classic .rd-nav-link,
.bg-primary .rd-navbar-static.rd-navbar-classic .rd-nav-link,
.context-dark .rd-navbar-static.rd-navbar-classic .rd-navbar-submenu-toggle,
.bg-gray-700 .rd-navbar-static.rd-navbar-classic .rd-navbar-submenu-toggle,
.bg-blue-13 .rd-navbar-static.rd-navbar-classic .rd-navbar-submenu-toggle,
.bg-blue-15 .rd-navbar-static.rd-navbar-classic .rd-navbar-submenu-toggle,
.bg-primary .rd-navbar-static.rd-navbar-classic .rd-navbar-submenu-toggle {
	color: #ffffff;
}

.context-dark .rd-navbar-static.rd-navbar-classic .rd-nav-link:hover~.rd-navbar-submenu-toggle,
.bg-gray-700 .rd-navbar-static.rd-navbar-classic .rd-nav-link:hover~.rd-navbar-submenu-toggle,
.bg-blue-13 .rd-navbar-static.rd-navbar-classic .rd-nav-link:hover~.rd-navbar-submenu-toggle,
.bg-blue-15 .rd-navbar-static.rd-navbar-classic .rd-nav-link:hover~.rd-navbar-submenu-toggle,
.bg-primary .rd-navbar-static.rd-navbar-classic .rd-nav-link:hover~.rd-navbar-submenu-toggle,
.context-dark .rd-navbar-static.rd-navbar-classic .rd-nav-link:hover,
.bg-gray-700 .rd-navbar-static.rd-navbar-classic .rd-nav-link:hover,
.bg-blue-13 .rd-navbar-static.rd-navbar-classic .rd-nav-link:hover,
.bg-blue-15 .rd-navbar-static.rd-navbar-classic .rd-nav-link:hover,
.bg-primary .rd-navbar-static.rd-navbar-classic .rd-nav-link:hover,
.context-dark .rd-navbar-static.rd-navbar-classic .rd-nav-item.active .rd-nav-link,
.bg-gray-700 .rd-navbar-static.rd-navbar-classic .rd-nav-item.active .rd-nav-link,
.bg-blue-13 .rd-navbar-static.rd-navbar-classic .rd-nav-item.active .rd-nav-link,
.bg-blue-15 .rd-navbar-static.rd-navbar-classic .rd-nav-item.active .rd-nav-link,
.bg-primary .rd-navbar-static.rd-navbar-classic .rd-nav-item.active .rd-nav-link,
.context-dark .rd-navbar-static.rd-navbar-classic .rd-nav-item.active .rd-navbar-submenu-toggle,
.bg-gray-700 .rd-navbar-static.rd-navbar-classic .rd-nav-item.active .rd-navbar-submenu-toggle,
.bg-blue-13 .rd-navbar-static.rd-navbar-classic .rd-nav-item.active .rd-navbar-submenu-toggle,
.bg-blue-15 .rd-navbar-static.rd-navbar-classic .rd-nav-item.active .rd-navbar-submenu-toggle,
.bg-primary .rd-navbar-static.rd-navbar-classic .rd-nav-item.active .rd-navbar-submenu-toggle,
.context-dark .rd-navbar-static.rd-navbar-classic .rd-nav-item.focus .rd-nav-link,
.bg-gray-700 .rd-navbar-static.rd-navbar-classic .rd-nav-item.focus .rd-nav-link,
.bg-blue-13 .rd-navbar-static.rd-navbar-classic .rd-nav-item.focus .rd-nav-link,
.bg-blue-15 .rd-navbar-static.rd-navbar-classic .rd-nav-item.focus .rd-nav-link,
.bg-primary .rd-navbar-static.rd-navbar-classic .rd-nav-item.focus .rd-nav-link,
.context-dark .rd-navbar-static.rd-navbar-classic .rd-nav-item.focus .rd-navbar-submenu-toggle,
.bg-gray-700 .rd-navbar-static.rd-navbar-classic .rd-nav-item.focus .rd-navbar-submenu-toggle,
.bg-blue-13 .rd-navbar-static.rd-navbar-classic .rd-nav-item.focus .rd-navbar-submenu-toggle,
.bg-blue-15 .rd-navbar-static.rd-navbar-classic .rd-nav-item.focus .rd-navbar-submenu-toggle,
.bg-primary .rd-navbar-static.rd-navbar-classic .rd-nav-item.focus .rd-navbar-submenu-toggle {
	color: #0A043B;
}

.jumbotron-creative .rd-navbar-static.rd-navbar-classic:not(.rd-navbar--is-stuck) .rd-nav-link,
.jumbotron-creative .rd-navbar-static.rd-navbar-classic:not(.rd-navbar--is-stuck) .rd-navbar-submenu-toggle {
	color: #0A043B;
}

.jumbotron-creative .rd-navbar-static.rd-navbar-classic:not(.rd-navbar--is-stuck) .rd-nav-link:hover~.rd-navbar-submenu-toggle,
.jumbotron-creative .rd-navbar-static.rd-navbar-classic:not(.rd-navbar--is-stuck) .rd-nav-link:hover,
.jumbotron-creative .rd-navbar-static.rd-navbar-classic:not(.rd-navbar--is-stuck) .rd-nav-item.active .rd-nav-link,
.jumbotron-creative .rd-navbar-static.rd-navbar-classic:not(.rd-navbar--is-stuck) .rd-nav-item.active .rd-navbar-submenu-toggle,
.jumbotron-creative .rd-navbar-static.rd-navbar-classic:not(.rd-navbar--is-stuck) .rd-nav-item.focus .rd-nav-link,
.jumbotron-creative .rd-navbar-static.rd-navbar-classic:not(.rd-navbar--is-stuck) .rd-nav-item.focus .rd-navbar-submenu-toggle {
	/* color: rgba(255, 255, 255, 0.6); */
	color: #0A043B;
}

.jumbotron-creative .rd-navbar-static.rd-navbar-classic:not(.rd-navbar--is-stuck) .rd-navbar-aside-item>.button-primary-outline,
.jumbotron-creative .rd-navbar-static.rd-navbar-classic:not(.rd-navbar--is-stuck) .rd-navbar-aside-item>.button-primary-outline:focus {
	color: #0A043B;
	background-color: transparent;
	/* border-color: rgba(255, 255, 255, 0.2); */
	border-color: #0A043B;
}

.jumbotron-creative .rd-navbar-static.rd-navbar-classic:not(.rd-navbar--is-stuck) .rd-navbar-aside-item>.button-primary-outline:hover,
.jumbotron-creative .rd-navbar-static.rd-navbar-classic:not(.rd-navbar--is-stuck) .rd-navbar-aside-item>.button-primary-outline:active {
	color: #0A043B;
	background-color: #ffffff;
	border-color: #0A043B;
	/* border-color: #3f3a3a; */
}

.jumbotron-creative .rd-navbar-static.rd-navbar-classic:not(.rd-navbar--is-stuck) .rd-navbar-aside-item>.button-primary,
.jumbotron-creative .rd-navbar-static.rd-navbar-classic:not(.rd-navbar--is-stuck) .rd-navbar-aside-item>.button-primary:focus {
	color: #ffffff;
	background-color: #0A043B;
	border-color: #0A043B;
}

.jumbotron-creative .rd-navbar-static.rd-navbar-classic:not(.rd-navbar--is-stuck) .rd-navbar-aside-item>.button-primary:hover,
.jumbotron-creative .rd-navbar-static.rd-navbar-classic:not(.rd-navbar--is-stuck) .rd-navbar-aside-item>.button-primary:active {
	color: #ffffff;
	background-color: #ca5217;
	border-color: #ca5217;
}


/*
*
* RD Navbar Fullwidth
*/

.rd-navbar-fullwidth {
	display: block;
}

.rd-navbar-fullwidth .rd-navbar-nav>li+li {
	margin-left: 20px;
}

.rd-navbar-fullwidth.rd-navbar--is-stuck .rd-navbar-main,
.rd-navbar-fullwidth.rd-navbar--is-clone .rd-navbar-main {
	padding: 10px 0;
}

.context-dark .rd-navbar,
.bg-gray-700 .rd-navbar,
.bg-blue-13 .rd-navbar,
.bg-blue-15 .rd-navbar,
.bg-primary .rd-navbar {
	background-color: transparent;
}

.rd-navbar-wrap[style*='height: 0px;'] {
	margin-top: -1px;
}

.rd-navbar-wrap {
	height: 0px !important;
}

/*
*
* Search Results
*/

.rd-search-results-live {
	position: absolute;
	left: 0;
	right: 0;
	top: 100%;
	max-height: 600px;
	overflow-y: auto;
	margin: -3px 0 0;
	text-align: left;
	z-index: 998;
}

.rd-search-results-live #search-results {
	position: relative;
	padding: 16px 0 0;
	color: #151515;
	background: #ffffff;
	opacity: 0;
	visibility: hidden;
	-webkit-transform-origin: 50% 0;
	        transform-origin: 50% 0;
	-webkit-transform: scale(1, 0.9);
	        transform: scale(1, 0.9);
	transition: .3s all ease;
}

.rd-search-results-live #search-results::before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	pointer-events: none;
	z-index: -1;
	border: 1px solid #e0e0e0;
	border-top: 0;
}

.rd-search-results-live #search-results.active {
	opacity: 1;
	visibility: visible;
	-webkit-transform: scale(1, 1);
	        transform: scale(1, 1);
}

.rd-search-results-live .search-quick-result {
	padding-left: 15px;
	padding-right: 15px;
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 14px;
	font-weight: 700;
	line-height: 30px;
	text-transform: uppercase;
	color: #151515;
}

.rd-search-results-live .search-list {
	margin-top: 11px;
}

.rd-search-results-live .search-list li:only-child {
	padding: 0 15px 15px;
}

.rd-search-results-live .search-link {
	color: #d6d6d6;
}

.rd-search-results-live .search-link:hover {
	color: #0A043B;
}

.rd-search-results-live .search-error {
	font-size: 14px;
	line-height: 1.6;
}

.rd-search-results-live .search-title {
	position: relative;
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 14px;
	font-weight: 700;
	color: #151515;
}

.rd-search-results-live .search-title a:hover {
	color: #0A043B;
}

.rd-search-results-live .search-list-item-all {
	margin-top: 18px;
	width: 100%;
}

.rd-search-results-live .search-submit {
	position: relative;
	overflow: hidden;
	z-index: 0;
	display: block;
	padding: 8px;
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 14px;
	font-weight: 700;
	letter-spacing: 0.025em;
	text-transform: uppercase;
	text-align: center;
	color: #ffffff;
	background: #0A043B;
}

.rd-search-results-live .search-submit:hover {
	color: #ffffff;
	background: #d6d6d6;
}

.rd-search-results-live .match {
	display: none;
}

.not-empty~.rd-search-results-live {
	visibility: visible;
	opacity: 1;
}

.rd-search-results-live p {
	font-size: 11px;
}

.rd-search-results-live p * {
	margin: 0;
}

.rd-search-results-live .search-list-item {
	padding: 0 15px;
}

.rd-search-results-live *+p {
	margin-top: 5px;
}

.rd-search-results-live .search-list-item+.search-list-item {
	margin-top: 17px;
}

.rd-search-results .search-list {
	counter-reset: li;
	text-align: left;
	padding-left: 0;
	font-size: 18px;
	list-style-type: none;
	overflow: hidden;
}

.rd-search-results .search-list li div {
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
	white-space: nowrap;
}

.rd-search-results .search-list li:only-child::before {
	display: none;
}

.rd-search-results .search-list-item {
	position: relative;
	padding-left: 40px;
	font-size: 1rem;
	color: #9a9a9a;
}

.rd-search-results .search-list-item::before {
	content: counter(li, decimal-leading-zero) ".";
	counter-increment: li;
	position: absolute;
	left: 0;
	top: 0;
	font-size: 14px;
	line-height: inherit;
	font-weight: 300;
	color: #000000;
}

.rd-search-results .search-list-item+.search-list-item {
	margin-top: 40px;
}

.rd-search-results .search-title {
	font-size: 16px;
	color: #000000;
}

.rd-search-results .search {
	color: #ffffff;
	padding: 0 .25em;
	background: #0A043B;
}

.rd-search-results .match {
	padding: 5px;
	font-size: 12px;
	line-height: 1.7;
	letter-spacing: .1em;
	text-transform: uppercase;
	color: #000000;
}

.rd-search-results .match em {
	margin: 0;
	font-style: normal;
}

.rd-search-results p * {
	margin: 0;
}

.rd-search-results *+.match {
	margin-top: 10px;
}

@media (min-width: 768px) {
	.rd-search-results .search-title {
		font-size: 18px;
	}
	.rd-search-results .search-list-item::before {
		top: -1px;
		font-size: 16px;
	}
	.rd-search-results *+p {
		margin-top: 12px;
	}
}

@media (min-width: 992px) {
	.rd-search-results .search-list-item {
		padding-left: 40px;
	}
}

*+.rd-search-results {
	margin-top: 40px;
}

@media (min-width: 768px) {
	*+.rd-search-results {
		margin-top: 55px;
	}
}

.rd-search-classic {
	position: relative;
}

.rd-search-classic .form-input {
	color: #9a9a9a;
	padding-right: 60px;
}

.rd-search-classic .form-input,
.rd-search-classic .form-label {
	letter-spacing: 0;
}

.rd-search-classic .rd-search-submit {
	background: none;
	border: none;
	display: inline-block;
	padding: 0;
	outline: none;
	outline-offset: 0;
	cursor: pointer;
	-webkit-appearance: none;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	width: 50px;
	padding-top: 3px;
	padding-right: 2px;
	margin: 0;
	text-align: center;
	color: #151515;
	font-size: 0;
	line-height: 0;
}

.rd-search-classic .rd-search-submit::-moz-focus-inner {
	border: none;
	padding: 0;
}

.rd-search-classic .rd-search-submit:before {
	position: relative;
	top: -1px;
	content: '\e01c';
	font: 400 20px 'fl-budicons-launch';
	line-height: 1;
	transition: .33s all ease;
}

.rd-search-classic .rd-search-submit:hover {
	color: #0A043B;
}

.rd-search-classic.form-lg .form-input {
	padding-right: 70px;
}

.rd-search-classic.form-lg .rd-search-submit {
	width: 60px;
}

.rd-search.form-inline {
	position: relative;
}

.rd-search.form-inline .form-input {
	padding-right: 50px;
}

.rd-search.form-inline .button-link {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	width: 50px;
	right: 0;
	margin: 0;
	font-size: 21px;
	color: #d6d6d6;
	transition: .33s;
}

.rd-search.form-inline .button-link::before {
	display: block;
	margin: auto;
}

.rd-search.form-inline .button-link:hover {
	color: #0A043B;
}

.rd-search.form-inline.form-sm .form-input {
	padding-right: 40px;
}

.rd-search.form-inline.form-sm .button-link {
	width: 40px;
	font-size: 18px;
}

.rd-search.form-inline.form-lg .form-input {
	padding-right: 60px;
}

.rd-search.form-inline.form-lg .button-link {
	width: 60px;
}

.tabs-custom {
	text-align: left;
}

.tabs-custom .nav-tabs {
	display: block;
	font-size: 0;
	line-height: 0;
	word-spacing: 0;
	border: 0;
}

.tabs-custom .nav-tabs:before,
.tabs-custom .nav-tabs:after {
	display: none;
}

.tabs-custom .nav-item {
	float: none;
	border: 0;
	cursor: pointer;
	transition: .33s all ease;
}

.tabs-custom .nav-link {
	margin: 0;
	border-radius: 0;
	border: 0;
}

.tabs-custom .nav-link.active {
	cursor: default;
	border: 0;
}

.tab-content>.tab-pane {
	display: block;
	visibility: hidden;
	height: 0;
	overflow: hidden;
}

.tab-content>.active {
	visibility: visible;
	height: auto;
	overflow: visible;
}

*+.tabs-horizontal.tabs-minimal {
	margin-top: 35px;
}

*+.tabs-vertical.tabs-minimal {
	margin-top: 40px;
}

@media (min-width: 768px) {
	*+.tabs-vertical.tabs-minimal {
		margin-top: 60px;
	}
}

@media (min-width: 1200px) {
	*+.tabs-vertical.tabs-minimal {
		margin-top: 80px;
	}
}

.tabs-minimal .nav-tabs {
	margin-bottom: -15px;
	text-align: center;
}

.tabs-minimal .nav-tabs>* {
	margin-bottom: 15px;
}

.tabs-minimal .nav-item {
	display: inline-block;
	padding: 0 7px;
}

.tabs-minimal .nav-item:not(:last-child) {
	border-right: 1px solid #f3f4f9;
}

.tabs-minimal .nav-link {
	padding: 0 8px;
	font: 600 14px/20px "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", rubiklight, "Helvetica Neue", Arial, sans-serif;
	text-transform: uppercase;
	letter-spacing: .05em;
	color: #868e96;
	vertical-align: middle;
}

.tabs-minimal .nav-link:hover,
.tabs-minimal .nav-link.active {
	color: #0A043B;
}

.tabs-minimal .tab-content {
	padding: 33px 0 0;
}

@media (min-width: 768px) {
	.tabs-horizontal.tabs-minimal .nav-item {
		padding: 0 18px;
	}
	.tabs-vertical {
		display: flex;
		align-items: flex-start;
	}
	.tabs-vertical .nav-tabs {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		flex-shrink: 0;
		max-width: 50%;
	}
	.tabs-vertical .nav-item {
		border: 0;
		width: 100%;
		text-align: left;
	}
	.tabs-vertical .tab-content {
		flex-grow: 1;
	}
	.tabs-vertical.tabs-minimal .nav-tabs {
		width: auto;
		min-width: 180px;
		border: 0;
	}
	.tabs-vertical.tabs-minimal .nav-item {
		margin: 0;
		border: 0;
	}
	.tabs-vertical.tabs-minimal .nav-item+.nav-item {
		border-top: 1px solid #f3f4f9;
	}
	.tabs-vertical.tabs-minimal .nav-link {
		position: relative;
		padding: 15px 0;
		overflow: hidden;
		text-align: left;
	}
	.tabs-vertical.tabs-minimal .tab-content {
		padding: 0 0 0 30px;
	}
}

@media (min-width: 992px) {
	.tabs-vertical.tabs-minimal .nav-tabs {
		min-width: 200px;
	}
	.tabs-vertical.tabs-minimal .tab-content {
		padding: 0 0 0 70px;
	}
}

@media (min-width: 1200px) {
	.tabs-vertical.tabs-minimal .tab-content {
		padding: 0 0 0 105px;
	}
}

.card-group-custom {
	margin-bottom: 0;
}

.card-group-custom .card+.card {
	margin-top: 0;
}

.card-group-custom.card-group-corporate .card+.card {
	margin-top: 30px;
}

.card-custom {
	display: block;
	margin: 0;
	background: inherit;
	border: 0;
	border-radius: 0;
	box-shadow: none;
	text-align: left;
}

.card-custom a {
	display: block;
}

.card-custom .card-header {
	padding: 0;
	border-bottom: 0;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.card-custom .card-body {
	padding: 0;
	border: 0;
}

.card-title {
	margin-bottom: 0;
}

*+.card-group-custom {
	margin-top: 35px;
}

@media (min-width: 768px) {
	*+.card-group-custom {
		margin-top: 50px;
	}
}

.card-corporate {
	text-align: left;
	border: 1px solid #e0e0e0;
}

.card-corporate .card-title a,
.card-corporate .card-body {
	background: #ffffff;
}

.card-corporate .card-title a {
	position: relative;
	z-index: 1;
	padding: 21px 73px 21px 32px;
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-weight: 500;
	font-size: 16px;
	line-height: 1.3;
	letter-spacing: .05em;
	color: #2c343b;
	transition: 1.3s all ease;
}

.card-corporate .card-title a .card-arrow:after {
	opacity: 0;
	visibility: hidden;
}

.card-corporate .card-title a.collapsed .card-arrow:after {
	opacity: 1;
	visibility: visible;
}

.card-corporate .card-arrow {
	position: absolute;
	top: 20px;
	right: 20px;
	z-index: 2;
	height: 28px;
	width: 28px;
	background: #0A043B;
	border-radius: 0;
	transition: .33s all ease;
	text-align: center;
}

.card-corporate .card-arrow::before,
.card-corporate .card-arrow::after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 4;
	color: #ffffff;
	font-family: 'Material Design Icons';
	font-size: 24px;
	transition: .15s;
}

.card-corporate .card-arrow::before {
	content: '\f465';
}

.card-corporate .card-arrow::after {
	content: '\f504';
}

.card-corporate .collapse {
	position: relative;
	z-index: 1;
	color: #9a9a9a;
	border-radius: 0 0 0 0;
}

.card-corporate .card-body {
	border-top: 1px solid #e0e0e0;
	padding: 18px 44px 25px 32px;
}

@media (max-width: 991.98px) {
	.card-corporate .card-title a,
	.card-corporate .card-body {
		padding-left: 25px;
	}
}

@media (min-width: 768px) {
	.card-corporate .card-title a {
		font-size: 18px;
	}
}


/*
*
* Tooltop Custom
*/

.tooltip {
	font-size: 1rem;
	line-height: 1.2;
}

.tooltip-inner {
	background-color: #0A043B;
}

.tooltip.bs-tooltip-top .arrow::before {
	border-top-color: #0A043B;
}

.tooltip.bs-tooltip-right .arrow::before {
	border-right-color: #0A043B;
}

.tooltip.bs-tooltip-bottom .arrow::before {
	border-bottom-color: #0A043B;
}

.tooltip.bs-tooltip-left .arrow::before {
	border-left-color: #0A043B;
}


/*
*
* Counter
*/

.counter-classic {
	text-align: center;
}

.counter-classic-main {
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 40px;
	line-height: 1;
	font-weight: 300;
}

.counter-classic-main>* {
	display: inline-block;
	font: inherit;
	color: inherit;
}

.counter-classic-divider {
	font-size: 0;
	line-height: 0;
}

.counter-classic-divider::before {
	content: '';
	display: inline-block;
	width: 35px;
	height: 4px;
	background: #0A043B;
}

*+.counter-classic-divider {
	margin-top: 10px;
}

*+.counter-classic-title {
	margin-top: 12px;
}

@media (min-width: 1200px) {
	.counter-classic-main {
		font-size: 70px;
	}
}

.context-dark .counter-classic-main,
.bg-gray-700 .counter-classic-main,
.bg-blue-13 .counter-classic-main,
.bg-blue-15 .counter-classic-main,
.bg-primary .counter-classic-main,
.context-dark .counter-classic-title,
.bg-gray-700 .counter-classic-title,
.bg-blue-13 .counter-classic-title,
.bg-blue-15 .counter-classic-title,
.bg-primary .counter-classic-title {
	color: #ffffff;
}

.bg-primary .counter-classic-divider::before {
	background: #ffffff;
}

.counter-modern {
	display: flex;
	align-items: flex-start;
	margin-bottom: -15px;
	margin-left: -15px;
	text-align: left;
}

.counter-modern:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.counter-modern>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 15px;
	margin-left: 15px;
}

.counter-modern-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 56px;
	height: 56px;
	flex-shrink: 0;
	border-radius: 50%;
	border: 2px solid #e0e0e0;
	font-size: 24px;
	line-height: 1;
	color: #0A043B;
}

.counter-modern-main {
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 40px;
	line-height: 1;
	font-weight: 300;
	white-space: nowrap;
}

.counter-modern-main>* {
	display: inline-block;
	font: inherit;
	color: inherit;
}

.context-dark .counter-modern-icon,
.bg-gray-700 .counter-modern-icon,
.bg-blue-13 .counter-modern-icon,
.bg-blue-15 .counter-modern-icon,
.bg-primary .counter-modern-icon {
	border-color: #ffffff;
}

*+.counter-modern-title {
	margin-top: 8px;
}

@media (max-width: 767.98px) {
	.counter-modern {
		max-width: 270px;
		margin-left: auto;
		margin-right: auto;
	}
}

@media (min-width: 1200px) {
	.counter-modern-main {
		font-size: 70px;
	}
}


/*
*
* Progress Bars
*/

.progress-linear {
	position: relative;
	text-align: left;
}

.progress-linear .progress-linear-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	-webkit-transform: translate3d(0, -10px, 0);
	        transform: translate3d(0, -10px, 0);
	margin-bottom: -10px;
	margin-left: -5px;
	margin-right: -5px;
}

.progress-linear .progress-linear-header>* {
	margin-top: 10px;
	padding-left: 5px;
	padding-right: 5px;
}

.progress-linear .progress-linear-title {
	color: #0d0d0d;
}

.progress-linear .progress-linear-body {
	height: 3px;
	background: #e0e0e0;
}

.progress-linear .progress-linear-bar {
	position: relative;
	width: 0;
	height: inherit;
	background: #0A043B;
	transition: .5s all ease-in-out;
}

.progress-linear .progress-linear-bar::after {
	content: '';
	position: absolute;
	display: block;
	right: -8px;
	top: -3px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: inherit;
}

.progress-linear .progress-linear-counter {
	display: none;
	font-weight: 700;
	color: #0d0d0d;
}

.progress-linear .progress-linear-counter::after {
	content: "%";
}

*+.progress-linear-body {
	margin-top: 5px;
}

.progress-linear+.progress-linear {
	margin-top: 15px;
}

.progress-bar-color-1 .progress-linear-bar {
	background: #437685;
}

.progress-bar-secondary .progress-linear-bar {
	background: #ca5217;
}

.progress-bar-tertiary .progress-linear-bar {
	background: #7959b6;
}

@media (min-width: 992px) {
	.progress-linear+.progress-linear {
		margin-top: 25px;
	}
}


/** @section Progress Circle */

.progress-circle {
	display: inline-block;
	text-align: center;
	line-height: 1.2;
}

.progress-circle-block {
	position: relative;
}

.progress-circle-bg {
	fill: transparent;
	stroke: #f3f4f9;
	stroke-width: 2px;
}

.progress-circle-fg {
	fill: transparent;
	stroke: #0A043B;
	stroke-width: 3px;
}

.progress-circle-counter {
	position: absolute;
	top: 50%;
	left: 51%;
	font-size: 36px;
	line-height: 36px;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	color: #151515;
}

.progress-circle-counter::after {
	content: "%";
}

.progress-circle-title {
	font-size: 14px;
	letter-spacing: .05em;
	text-transform: uppercase;
}

*+.progress-bar-circle-title {
	margin-top: 12px;
}


/** @section Countdown */

.countdown {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: -5px;
	margin-right: -5px;
	line-height: 1.2;
	text-align: center;
}

.countdown-block {
	position: relative;
	flex: 0 1 auto;
	padding: 0 5px;
}

@media (min-width: 576px) {
	html:not(.ie-10):not(.ie-11):not(.edge) .countdown-block {
		width: 25%;
	}
}

.countdown-block-seconds {
	display: none;
}

.countdown-wrap {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.countdown-circle {
	fill: transparent;
}

html:not(.ie-11):not(.ie-10) .countdown-circle {
	width: 100%;
	height: auto;
}

.countdown-circle-bg {
	stroke: #f5f5f5;
	stroke-width: 2px;
}

.countdown-circle-fg {
	stroke: #0A043B;
	stroke-width: 3px;
}

.countdown-counter {
	font-size: 24px;
	font-weight: 400;
	line-height: 1;
	letter-spacing: 0.05em;
	color: #0d0d0d;
}

.countdown-title {
	font-size: 11px;
	line-height: 1.2;
	text-transform: uppercase;
	color: #868e96;
	letter-spacing: 0.05em;
}

@media (min-width: 576px) {
	.countdown-title {
		font-size: 14px;
	}
}

*+.countdown-title {
	margin-top: 5px;
}

*+.countdown {
	margin-top: 35px;
}

.countdown+* {
	margin-top: 35px;
}

@media (min-width: 576px) {
	.countdown-block-seconds {
		display: block;
	}
}

@media (min-width: 768px) {
	.countdown {
		margin-left: -15px;
		margin-right: -15px;
	}
	.countdown-block {
		padding: 0 15px;
	}
	.countdown-counter {
		font-size: 34px;
	}
	.countdown-circle-bg {
		stroke-width: 2px;
	}
	.countdown-circle-fg {
		stroke-width: 3px;
	}
}

@media (min-width: 992px) {
	.countdown-counter {
		font-size: 48px;
	}
}

.context-dark .countdown-counter,
.bg-gray-700 .countdown-counter,
.bg-blue-13 .countdown-counter,
.bg-blue-15 .countdown-counter,
.bg-primary .countdown-counter {
	color: #ffffff;
}

.context-dark .countdown-circle-bg,
.bg-gray-700 .countdown-circle-bg,
.bg-blue-13 .countdown-circle-bg,
.bg-blue-15 .countdown-circle-bg,
.bg-primary .countdown-circle-bg {
	stroke: rgba(255, 255, 255, 0.35);
}

.context-dark .countdown-circle-fg,
.bg-gray-700 .countdown-circle-fg,
.bg-blue-13 .countdown-circle-fg,
.bg-blue-15 .countdown-circle-fg,
.bg-primary .countdown-circle-fg {
	stroke: #ffffff;
	stroke-width: 4px;
}

.context-dark .countdown-title,
.bg-gray-700 .countdown-title,
.bg-blue-13 .countdown-title,
.bg-blue-15 .countdown-title,
.bg-primary .countdown-title {
	color: rgba(255, 255, 255, 0.8);
}


/*
* jQuery mousewheel plugin
*/


/*
------------------------------------------------------------------------------------------------------------------------
1. BASIC STYLE
------------------------------------------------------------------------------------------------------------------------
*/

.mCustomScrollbar {
	touch-action: pinch-zoom;
	/* direct pointer events to js */
}

.mCustomScrollbar.mCS_no_scrollbar,
.mCustomScrollbar.mCS_touch_action {
	touch-action: auto;
}

.mCustomScrollBox {
	/* contains plugin's markup */
	position: relative;
	overflow: hidden;
	height: 100%;
	max-width: 100%;
	outline: none;
	direction: ltr;
}

.mCSB_container {
	/* contains the original content */
	overflow: hidden;
	width: auto;
	height: auto;
}


/*
------------------------------------------------------------------------------------------------------------------------
2. VERTICAL SCROLLBAR
y-axis
------------------------------------------------------------------------------------------------------------------------
*/

.mCSB_inside>.mCSB_container {
	margin-right: 30px;
}

.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
	margin-right: 0;
}


/* non-visible scrollbar */

.mCS-dir-rtl-custom>.mCSB_inside>.mCSB_container {
	/* RTL direction/left-side scrollbar */
	margin-right: 0;
	margin-left: 30px;
}

.mCS-dir-rtl-custom>.mCSB_inside>.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
	margin-left: 0;
}


/* RTL direction/left-side scrollbar */

.mCSB_scrollTools {
	/* contains scrollbar markup (draggable element, dragger rail, buttons etc.) */
	position: absolute;
	width: 16px;
	height: auto;
	left: auto;
	top: 0;
	right: 0;
	bottom: 0;
}

.mCSB_outside+.mCSB_scrollTools {
	right: -26px;
}


/* scrollbar position: outside */

.mCS-dir-rtl-custom>.mCSB_inside>.mCSB_scrollTools,
.mCS-dir-rtl-custom>.mCSB_outside+.mCSB_scrollTools {
	/* RTL direction/left-side scrollbar */
	right: auto;
	left: 0;
}

.mCS-dir-rtl-custom>.mCSB_outside+.mCSB_scrollTools {
	left: -26px;
}


/* RTL direction/left-side scrollbar (scrollbar position: outside) */

.mCSB_scrollTools .mCSB_draggerContainer {
	/* contains the draggable element and dragger rail markup */
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	height: auto;
}

.mCSB_scrollTools a+.mCSB_draggerContainer {
	margin: 20px 0;
}

.mCSB_scrollTools .mCSB_draggerRail {
	width: 16px;
	height: 100%;
	margin: 0 auto;
	border-radius: 0;
}

.mCSB_scrollTools .mCSB_dragger {
	/* the draggable element */
	cursor: pointer;
	width: 100%;
	height: 30px;
	/* minimum dragger height */
	z-index: 1;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
	/* the dragger element */
	position: relative;
	width: 16px;
	height: 100%;
	margin: 0 auto;
	border-radius: 0;
	text-align: center;
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
	width: 12px;
	/* auto-expanded scrollbar */
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded+.mCSB_draggerRail,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
	width: 8px;
	/* auto-expanded scrollbar */
}

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown {
	display: block;
	position: absolute;
	height: 20px;
	width: 100%;
	overflow: hidden;
	margin: 0 auto;
	cursor: pointer;
}

.mCSB_scrollTools .mCSB_buttonDown {
	bottom: 0;
}


/*
------------------------------------------------------------------------------------------------------------------------
3. HORIZONTAL SCROLLBAR
x-axis
------------------------------------------------------------------------------------------------------------------------
*/

.mCSB_horizontal.mCSB_inside>.mCSB_container {
	margin-right: 0;
	margin-bottom: 30px;
}

.mCSB_horizontal.mCSB_outside>.mCSB_container {
	min-height: 100%;
}

.mCSB_horizontal>.mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
	margin-bottom: 0;
}


/* non-visible scrollbar */

.mCSB_scrollTools.mCSB_scrollTools_horizontal {
	width: auto;
	height: 16px;
	top: auto;
	right: 0;
	bottom: 0;
	left: 0;
}

.mCustomScrollBox+.mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox+.mCSB_scrollTools+.mCSB_scrollTools.mCSB_scrollTools_horizontal {
	bottom: -26px;
}


/* scrollbar position: outside */

.mCSB_scrollTools.mCSB_scrollTools_horizontal a+.mCSB_draggerContainer {
	margin: 0 20px;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
	width: 100%;
	height: 2px;
	margin: 7px 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
	width: 30px;
	/* minimum dragger width */
	height: 100%;
	left: 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
	width: 100%;
	height: 4px;
	margin: 6px auto;
}

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
	height: 12px;
	/* auto-expanded scrollbar */
	margin: 2px auto;
}

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded+.mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
	height: 8px;
	/* auto-expanded scrollbar */
	margin: 4px 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft,
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
	display: block;
	position: absolute;
	width: 20px;
	height: 100%;
	overflow: hidden;
	margin: 0 auto;
	cursor: pointer;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
	left: 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
	right: 0;
}


/*
------------------------------------------------------------------------------------------------------------------------
4. VERTICAL AND HORIZONTAL SCROLLBARS
yx-axis
------------------------------------------------------------------------------------------------------------------------
*/

.mCSB_container_wrapper {
	position: absolute;
	height: auto;
	width: auto;
	overflow: hidden;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin-right: 30px;
	margin-bottom: 30px;
}

.mCSB_container_wrapper>.mCSB_container {
	padding-right: 30px;
	padding-bottom: 30px;
	box-sizing: border-box;
}

.mCSB_vertical_horizontal>.mCSB_scrollTools.mCSB_scrollTools_vertical {
	bottom: 20px;
}

.mCSB_vertical_horizontal>.mCSB_scrollTools.mCSB_scrollTools_horizontal {
	right: 20px;
}


/* non-visible horizontal scrollbar */

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden+.mCSB_scrollTools.mCSB_scrollTools_vertical {
	bottom: 0;
}


/* non-visible vertical scrollbar/RTL direction/left-side scrollbar */

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden+.mCSB_scrollTools~.mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCS-dir-rtl-custom>.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside>.mCSB_scrollTools.mCSB_scrollTools_horizontal {
	right: 0;
}


/* RTL direction/left-side scrollbar */

.mCS-dir-rtl-custom>.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside>.mCSB_scrollTools.mCSB_scrollTools_horizontal {
	left: 20px;
}


/* non-visible scrollbar/RTL direction/left-side scrollbar */

.mCS-dir-rtl-custom>.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside>.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden+.mCSB_scrollTools~.mCSB_scrollTools.mCSB_scrollTools_horizontal {
	left: 0;
}

.mCS-dir-rtl-custom>.mCSB_inside>.mCSB_container_wrapper {
	/* RTL direction/left-side scrollbar */
	margin-right: 0;
	margin-left: 30px;
}

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden>.mCSB_container {
	padding-right: 0;
}

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden>.mCSB_container {
	padding-bottom: 0;
}

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside>.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
	margin-right: 0;
	/* non-visible scrollbar */
	margin-left: 0;
}


/* non-visible horizontal scrollbar */

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside>.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
	margin-bottom: 0;
}


/*
------------------------------------------------------------------------------------------------------------------------
5. TRANSITIONS
------------------------------------------------------------------------------------------------------------------------
*/

.mCSB_scrollTools,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
	transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail {
	transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
}


/*
------------------------------------------------------------------------------------------------------------------------
6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS
------------------------------------------------------------------------------------------------------------------------
*/


/*
----------------------------------------
6.1 THEMES
----------------------------------------
*/


/* default theme ("light") */

.mCSB_scrollTools .mCSB_draggerRail {
	background-color: transparent;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
	background-color: #f2f3f8;
}

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
	background-color: #d1d4e6;
}

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
	/* background-image: url(../img/mCSB_buttons.png); */
	/* css sprites */
	background-repeat: no-repeat;
	opacity: 0.4;
}

.mCSB_scrollTools .mCSB_buttonUp {
	background-position: 0 0;
}

.mCSB_scrollTools .mCSB_buttonDown {
	background-position: 0 -20px;
}

.mCSB_scrollTools .mCSB_buttonLeft {
	background-position: 0 -40px;
}

.mCSB_scrollTools .mCSB_buttonRight {
	background-position: 0 -56px;
}

.mCSB_scrollTools .mCSB_buttonUp:hover,
.mCSB_scrollTools .mCSB_buttonDown:hover,
.mCSB_scrollTools .mCSB_buttonLeft:hover,
.mCSB_scrollTools .mCSB_buttonRight:hover {
	opacity: 0.75;
}

.mCSB_scrollTools .mCSB_buttonUp:active,
.mCSB_scrollTools .mCSB_buttonDown:active,
.mCSB_scrollTools .mCSB_buttonLeft:active,
.mCSB_scrollTools .mCSB_buttonRight:active {
	opacity: 0.9;
}


/* theme: "dark" */

.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
	background-color: #000000;
	background-color: rgba(0, 0, 0, 0.15);
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
	background-color: #000000;
	background-color: rgba(0, 0, 0, 0.75);
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
	background-color: rgba(0, 0, 0, 0.85);
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
	background-color: rgba(0, 0, 0, 0.9);
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonUp {
	background-position: -80px 0;
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonDown {
	background-position: -80px -20px;
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonLeft {
	background-position: -80px -40px;
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonRight {
	background-position: -80px -56px;
}


/*
*
* Owl Carousel
*/

.owl-carousel .animated {
	-webkit-animation-duration: 1000ms;
	        animation-duration: 1000ms;
	-webkit-animation-fill-mode: both;
	        animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
	z-index: 0;
}

.owl-carousel .owl-animated-out {
	z-index: 1;
}

.owl-carousel .fadeOut {
	-webkit-animation-name: fadeOut;
	        animation-name: fadeOut;
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}


/* 
 * 	Owl Carousel - Auto Height Plugin
 */

.owl-height {
	transition: height 500ms ease-in-out;
}


/* 
 *  Core Owl Carousel CSS File
 */

.owl-carousel {
	display: none;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
	/* position relative and z-index fix webkit rendering fonts issue */
	position: relative;
	z-index: 1;
}

.owl-carousel .owl-stage {
	position: relative;
	-ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

.owl-carousel .owl-stage-outer {
	position: relative;
	overflow: hidden;
	/* fix for flashing background */
	-webkit-transform: translate3d(0px, 0px, 0px);
	display: flex;
	justify-content: center;
	align-items: center;
	width: 95%;
}

.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
	cursor: pointer;
	-webkit-user-select: none;
	        user-select: none;
}

.owl-carousel.owl-loaded {
	display: block;
}

.owl-carousel.owl-loading {
	opacity: 0;
	display: block;
}

.owl-carousel.owl-hidden {
	opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
	display: none;
}

.owl-carousel .owl-item {
	position: relative;
	min-height: 1px;
	float: left;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
}

.owl-carousel .owl-grab {
	cursor: move;
	cursor: grab;
}

.owl-carousel.owl-rtl {
	direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
	float: right;
}


/* No Js */

.no-js .owl-carousel {
	display: block;
}


/* 
 * 	Owl Carousel - Lazy Load Plugin
 */

.owl-carousel .owl-item .owl-lazy {
	opacity: 0;
	transition: opacity 400ms ease;
}


/* 
 * 	Owl Carousel - Video Plugin
 */

.owl-carousel .owl-video-wrapper {
	position: relative;
	height: 100%;
	background: #000000;
}

.owl-carousel .owl-video-play-icon {
	position: absolute;
	height: 80px;
	width: 80px;
	left: 50%;
	top: 50%;
	margin-left: -40px;
	margin-top: -40px;
	font: 400 40px/80px 'FontAwesome';
	cursor: pointer;
	z-index: 1;
	transition: scale 100ms ease;
}

.owl-carousel .owl-video-play-icon:before {
	content: '\f144';
}

.owl-carousel .owl-video-play-icon:hover {
	-webkit-transform: scale(1.3);
	        transform: scale(1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
	display: none;
}

.owl-carousel .owl-video-tn {
	opacity: 0;
	height: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
	position: relative;
	z-index: 1;
}


/*
 * Owl Navigation
 */

.owl-nav.disabled {
	display: none !important;
}

.owl-prev,
.owl-next {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	font: 600 40px/40px 'fl-budicons-free';
	color: #0A043B;
	cursor: pointer;
	transition: .33s;
}

.owl-prev:hover,
.owl-next:hover {
	color: #0A043B;
}

.owl-prev {
	left: 0;
}

.owl-prev::before {
	content: '\e016';
}

.owl-next {
	right: 0;
}

.owl-next::before {
	content: '\e025';
}


/*
 * Owl Pagination
 */

.owl-dots {
	text-align: center;
	margin-top: 30px;
}

.owl-dot {
	position: relative;
	display: inline-block;
	width: 16px;
	height: 16px;
	border: 2px solid #b7b7b7;
	border-radius: 50%;
	margin: 0 5px;
	text-align: center;
	outline: none;
	cursor: pointer;
	background-color: transparent;
	transition: .22s;
	box-shadow: none;
}

.owl-dot span {
	display: none;
}

.owl-dot::before {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate3d(-50%, -50%, 0);
	        transform: translate3d(-50%, -50%, 0);
	background: #b7b7b7;
	width: 6px;
	height: 6px;
	border-radius: inherit;
	transition: .22s;
	opacity: 0;
}

.owl-dot:only-child {
	display: none;
}

.owl-dot:hover,
.owl-dot.active {
	border-color: #0A043B;
}

.owl-dot.active::before {
	opacity: 1;
	background: #0A043B;
}

.context-dark .owl-dot,
.bg-gray-700 .owl-dot,
.bg-blue-13 .owl-dot,
.bg-blue-15 .owl-dot,
.bg-primary .owl-dot {
	border-color: rgba(255, 255, 255, 0.4);
}

.context-dark .owl-dot::before,
.bg-gray-700 .owl-dot::before,
.bg-blue-13 .owl-dot::before,
.bg-blue-15 .owl-dot::before,
.bg-primary .owl-dot::before {
	background: #ffffff;
}

.context-dark .owl-dot:hover,
.bg-gray-700 .owl-dot:hover,
.bg-blue-13 .owl-dot:hover,
.bg-blue-15 .owl-dot:hover,
.bg-primary .owl-dot:hover,
.context-dark .owl-dot.active,
.bg-gray-700 .owl-dot.active,
.bg-blue-13 .owl-dot.active,
.bg-blue-15 .owl-dot.active,
.bg-primary .owl-dot.active {
	border-color: #ffffff;
}

.owl-carousel-stretch .owl-stage,
.owl-carousel-stretch .owl-item {
	display: flex;
}

.owl-carousel-stretch .owl-item>* {
	width: 100%;
}

*+.owl-carousel-stretch {
	margin-top: 40px;
}

.owl-carousel-quote-classic .owl-stage-outer {
	padding: 10px 21px 45px;
	margin: -10px -21px -45px;
}

@media (min-width: 1600px) {
	.owl-carousel-quote-classic {
		max-width: 1720px;
		margin-left: auto;
		margin-right: auto;
	}
}

*+.owl-carousel-quote-classic {
	margin-top: 40px;
}

.owl-carousel-profile .owl-stage-outer {
	padding: 21px;
	margin: -21px;
	border: 1px solid transparent;
}

*+.owl-carousel-profile {
	margin-top: 40px;
}

.owl-carousel-wrap {
	position: relative;
}

.owl-nav-modern .owl-prev,
.owl-nav-modern .owl-next {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 70px;
	width: 40px;
	padding: 5px;
	overflow: hidden;
	color: #151515;
	background: #ffffff;
	box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.12);
}

.owl-nav-modern .owl-prev::before,
.owl-nav-modern .owl-next::before {
	z-index: 3;
	font-family: 'Material Design Icons';
	font-size: 20px;
}

.owl-nav-modern .owl-prev:hover,
.owl-nav-modern .owl-next:hover {
	color: #ffffff;
	background: #0A043B;
	width: 70px;
}

.owl-nav-modern .owl-prev {
	left: 0;
}

.owl-nav-modern .owl-prev:before {
	content: '\f14a';
}

.owl-nav-modern .owl-next {
	right: 0;
}

.owl-nav-modern .owl-next:before {
	content: '\f14f';
}

.owl-carousel_style-1 .owl-nav {
	display: none;
}

.owl-carousel_style-1 .owl-dots {
	margin-top: 10px;
	padding-bottom: 15px;
}

@media (min-width: 768px) {
	.owl-carousel_style-1 .owl-dots {
		display: none !important;
	}
}

@media (max-width: 575.98px) {
	.owl-carousel_style-1-outer-nav {
		display: none;
	}
}

.owl-carousel_style-1-outer-nav .owl-prev,
.owl-carousel_style-1-outer-nav .owl-next {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	z-index: 10;
}

.owl-carousel_style-1-outer-nav .owl-prev {
	left: 0;
}

.owl-carousel_style-1-outer-nav .owl-next {
	right: 0;
}

.owl-carousel-indigo {
	position: relative;
	overflow: hidden;
	max-width: 100vw;
	padding: 0 15px;
	margin-left: auto;
	margin-right: auto;
	pointer-events: none;
}

.owl-carousel-indigo,
.owl-carousel-indigo * {
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
}

.owl-carousel-indigo .owl-stage-outer {
	padding: 23px;
	margin: -23px;
}

.owl-carousel-indigo .owl-item.active {
	pointer-events: auto;
}

.owl-carousel-indigo .owl-dots {
	pointer-events: auto;
}

.owl-carousel-indigo *+.owl-dots {
	margin-top: 25px;
}

@media (max-width: 575.98px) {
	.owl-carousel-indigo .owl-dot {
		width: 11px;
		height: 11px;
		margin: 0 6px;
	}
}

@media (min-width: 768px) {
	.owl-carousel-indigo {
		padding: 0 0;
	}
	.owl-carousel-indigo *+.owl-dots {
		margin-top: 60px;
	}
}

.owl-carousel-dots-space .owl-dots {
	margin-bottom: 25px;
}


/*
*
* Material Parallax
*/

.parallax-content {
	position: relative;
	z-index: 1;
}

.parallax-content,
.parallax-content * {
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
}

.parallax-container {
	position: relative;
	overflow: hidden;
	background-position: center center;
	background-size: cover;
}

.material-parallax {
	position: absolute;
	top: -1px;
	left: -1px;
	right: -10vw;
	bottom: 0;
	z-index: 0;
	background-position: center center;
}

.ipad .parallax-container,
.iphone .parallax-container {
	background-attachment: scroll !important;
}

.material-parallax img {
	display: none;
	position: absolute;
	left: 50%;
	bottom: 0;
	top: -1px;
	min-width: 101%;
	min-height: 101%;
	max-width: 100%;
	-webkit-transform: translate3d(-50%, 0, 0) !important;
	        transform: translate3d(-50%, 0, 0) !important;
	will-change: transform;
}


/*
*
* Select 2 v4
*/

.select2-container {
	box-sizing: border-box;
	display: inline-block;
	margin: 0;
	position: relative;
	vertical-align: middle;
	text-align: left;
}

.select2-container .select2-selection--single {
	display: block;
	cursor: pointer;
	-webkit-user-select: none;
	        user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
	display: block;
	padding-left: 8px;
	padding-right: 20px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.select2-container .select2-selection--single .select2-selection__clear {
	position: relative;
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
	padding-right: 8px;
	padding-left: 20px;
}

.select2-container .select2-selection--multiple {
	display: block;
	cursor: pointer;
	min-height: 32px;
	user-select: none;
	-webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
	display: inline-block;
	overflow: hidden;
	padding-left: 8px;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.select2-container .select2-search--inline {
	float: left;
}

.select2-container .select2-search--inline .select2-search__field {
	border: none;
	font-size: 100%;
	margin-top: 5px;
	padding: 0;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
	-webkit-appearance: none;
}

.select2-dropdown {
	position: absolute;
	left: -100000px;
	z-index: 1000;
	display: block;
	overflow: hidden;
	width: 100%;
	border: 1px solid #e0e0e0;
	border-radius: 0;
	background-color: #ffffff;
}

.select2-results {
	display: block;
}

.select2-results__options {
	list-style: none;
	margin: 0;
	padding: 0;
}

.select2-results__option {
	padding: 3px 6px 3px 26px;
	user-select: none;
	-webkit-user-select: none;
}

.select2-results__option[aria-selected] {
	cursor: pointer;
}

.select2-container--open .select2-dropdown {
	left: 0;
}

.select2-container--open .select2-dropdown--above {
	border-bottom: none;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
	border-top: none;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.select2-search--dropdown {
	display: block;
	padding: 4px;
}

.select2-search--dropdown .select2-search__field {
	padding: 4px;
	width: 100%;
	box-sizing: border-box;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
	-webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
	display: none;
}

.select2-dropdown-borderless.select2-dropdown {
	color: #2c343b;
	border-color: transparent;
	box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}

.select2-close-mask {
	border: 0;
	margin: 0;
	padding: 0;
	display: block;
	position: fixed;
	left: 0;
	top: 0;
	min-height: 100%;
	min-width: 100%;
	height: auto;
	width: auto;
	opacity: 0;
	/* z-index: 99; */
	background-color: #ffffff;
	filter: alpha(opacity=0);
}

.select2-hidden-accessible {
	border: 0 !important;
	clip: rect(0 0 0 0) !important;
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
}

.select2-container--default .select2-selection--single {
	background-color: #ffffff;
	border: 1px solid #e0e0e0;
	border-radius: 0;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	display: block;
	padding: 12px 36px 12px 26px;
	color: #151515;
	font-size: 14px;
	line-height: 24px;
	font-weight: 400;
	min-height: 50px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
	cursor: pointer;
	float: right;
	font-weight: 700;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
	color: #151515;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
	position: absolute;
	top: 50%;
	-webkit-transform: translate3d(0, -50%, 0);
	        transform: translate3d(0, -50%, 0);
	right: 14px;
	width: 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
	pointer-events: none;
	color: #151515;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b::before {
	position: relative;
	display: inline-block;
	content: "";
	font-family: "Material Design Icons";
	font-size: 18px;
	-webkit-transform: rotate(0deg);
	        transform: rotate(0deg);
	transition: .1s;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
	float: left;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
	left: 1px;
	right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
	background-color: #f3f4f9;
	cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
	display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b::before {
	-webkit-transform: rotate(180deg);
	        transform: rotate(180deg);
}

.select2-container--default .select2-selection--multiple {
	background-color: #ffffff;
	border: 1px solid #aaa;
	border-radius: 0.3rem;
	cursor: text;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
	box-sizing: border-box;
	list-style: none;
	margin: 0;
	padding: 0 5px;
	width: 100%;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
	list-style: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
	color: #999;
	margin-top: 5px;
	float: left;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
	cursor: pointer;
	float: right;
	font-weight: bold;
	margin-top: 5px;
	margin-right: 10px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
	background-color: #e4e4e4;
	border: 1px solid #aaa;
	border-radius: 4px;
	cursor: default;
	float: left;
	margin-right: 5px;
	margin-top: 5px;
	padding: 0 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
	color: #999;
	cursor: pointer;
	display: inline-block;
	font-weight: bold;
	margin-right: 2px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
	color: #333;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice,
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder,
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
	float: right;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
	margin-left: 5px;
	margin-right: auto;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
	margin-left: 2px;
	margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
	border: solid black 1px;
	outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
	background-color: #eee;
	cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
	display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown {
	border-top: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
	border: 1px solid #e0e0e0;
}

.select2-container--default .select2-search--inline .select2-search__field {
	background: transparent;
	border: none;
	outline: 0;
	box-shadow: none;
	-webkit-appearance: textfield;
}

.select2-container--default .select2-results>.select2-results__options {
	max-height: 200px;
	overflow-y: auto;
}

.select2-container--default .select2-results__option[role=group] {
	padding: 0;
}

.select2-container--default .select2-results__option[aria-disabled=true] {
	color: #9a9a9a;
}

.select2-container--default .select2-results__option[aria-selected=true] {
	color: #ffffff;
	background-color: #3c9ff2;
}

.select2-container--default .select2-results__option .select2-results__option {
	padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
	padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
	margin-left: -1em;
	padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
	margin-left: -2em;
	padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
	margin-left: -3em;
	padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
	margin-left: -4em;
	padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
	margin-left: -5em;
	padding-left: 6em;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	color: #ffffff;
	background-color: #0A043B;
}

.select2-container--default .select2-results__group {
	cursor: default;
	display: block;
	padding: 6px;
}


/*
*
* Modal Custom
*/

.modal-tip {
	padding: 30px 15px 7vh;
	visibility: hidden;
}

.modal-tip .close {
	cursor: pointer;
	color: #b7b7b7;
	opacity: 0;
}

.modal-tip .close span {
	transition: .22s;
}

.modal-tip .close span::before {
	content: '\f24c';
	font-family: 'Material Design Icons';
}

.modal-tip .close:hover {
	color: #2c343b;
}

.modal-tip.show {
	display: flex !important;
	visibility: visible;
}

.modal-tip.show .close {
	opacity: 1;
}

.modal-tip.fade .modal-dialog {
	-webkit-transform: translate3d(0, -30px, 0);
	        transform: translate3d(0, -30px, 0);
}

.modal-tip.show .modal-dialog {
	-webkit-transform: translate3d(0, 0, 0);
	        transform: translate3d(0, 0, 0);
}

.modal-tip .modal-dialog {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	margin: 0 auto;
}

.modal-tip .modal-content {
	height: 100%;
	border-radius: 0;
}

.modal-tip .modal-body {
	height: 100%;
	overflow-y: auto;
}

@media (min-width: 768px) {
	.modal-tip {
		align-items: center;
	}
	.modal-tip .modal-content,
	.modal-tip .model-body {
		height: auto;
	}
}

@media (min-width: 768px) {
	.modal-tip .modal-dialog {
		max-width: 700px;
	}
	.modal-tip .modal-header,
	.modal-tip .modal-body {
		padding-left: 30px;
		padding-right: 30px;
	}
	.modal-tip .modal-header {
		padding-top: 20px;
		padding-bottom: 20px;
	}
	.modal-tip .modal-body {
		padding-top: 30px;
		padding-bottom: 30px;
	}
}


/*
*
* Google Maps
*/

.google-map-markers {
	display: none;
}

.google-map-container {
	width: 100%;
}

.google-map {
	width: 100%;
	height: 200px;
}

@media (min-width: 576px) {
	.google-map {
		height: 280px;
	}
}

@media (min-width: 992px) {
	.google-map {
		height: 360px;
	}
}

@media (min-width: 1200px) {
	.google-map {
		height: 440px;
	}
}

.gm-style-iw {
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 1rem;
	line-height: 1.71429;
	color: #151515;
}

.gm-wrapper>div>div:nth-child(1) {
	background: yellow !important;
}

.gm-wrapper>div>div>div:nth-child(2) {
	background-color: transparent;
	border-radius: 0 !important;
	box-shadow: none !important;
	display: none !important;
}

.gm-wrapper>div>div>div:nth-child(1) {
	display: none;
}

.gm-wrapper>div>div>div:nth-child(3) {
	z-index: 12;
}

.gm-wrapper>div>div>div:nth-child(3)>div {
	height: 20px !important;
}

.gm-wrapper>div>div>div:nth-child(3)>div:nth-child(1)>div {
	-webkit-transform: skewX(30.6deg) !important;
	        transform: skewX(30.6deg) !important;
}

.gm-wrapper>div>div>div:nth-child(3)>div:nth-child(2)>div {
	-webkit-transform: skewX(-30.6deg) !important;
	        transform: skewX(-30.6deg) !important;
}

.gm-wrapper>div>div>div:nth-child(3)>div>div {
	height: 16px !important;
}

.gm-wrapper>div>div>div:nth-child(3)>div>div {
	background: #ffffff !important;
	box-shadow: none !important;
}

.gm-wrapper>div>div>div:nth-child(4) {
	background: #ffffff !important;
	border: 1px solid #e0e0e0;
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.google-map_1 {
		height: 385px;
	}
}


/* GDPR Suite */

.checkbox-custom:checked+span+.checkbox-custom-dummy::after {
	opacity: 1;
}

.agreement_checkbox label {
	width: 100%;
	display: block;
	text-align: left;
	position: relative;
}

.agreement_checkbox .form-validation {
	top: initial;
	bottom: -15px;
}

.card-group .card-body a {
	display: inline-block;
}

.lead {
	font-size: 17px;
	line-height: 22px;
	font-weight: 600;
	color: #08c0c9;
	text-transform: uppercase;
	margin: 0;
	letter-spacing: 1px;
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
}

p.lead {
	font-size: 20px;
	margin-bottom: 13px;
}

.text-info {
	color: #333;
	font-weight: bold;
}

a.text-info {
	color: #292929;
}

a.text-info:hover {
	color: #ff4b3c;
}

.text-info2 {
	color: #292929;
}

.text-info3 {
	color: #08c0c9;
}

.hide-text {
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
}

.input-block-level {
	display: block;
	width: 100%;
	min-height: 28px;
	box-sizing: border-box;
}

.check_box {
	/* margin-top: 15px; */
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.check_box label {
	min-height: 30px;
}

.check_box label input {
	display: inline-block;
	/* margin-right: 5px; */
	width: 20px;
	height: 20px;
	-webkit-appearance: checkbox !important;
	appearance: checkbox !important;
}

.check_box .empty {
	right: 0;
}

@media all and (max-width:1199px) {
	.check_box .empty {
		top: 25px;
	}
}

@media all and (max-width:768px) {
	.check_box .empty {
		top: 35px;
	}
}


/* CSS from biden */

.job-wrapper {
	border: 1px solid #ebebeb;
	padding: 45px 50px;
	background: #fff;
	position: relative;
}

@media (max-width: 767px) {
	.job-wrapper {
		padding: 25px 25px;
	}
}

.job-tag {
	position: relative;
	z-index: 2;
}

.job-instructor-profile {
	position: relative;
	z-index: 2;
}

.job-content {
	position: relative;
	z-index: 2;
}

div.job-tag span {
	background: #f5f7fc;
	margin-right: 10px;
	padding: 5px 10px;
	border-radius: 5px;
	display: inline-block;
	font-size: 13px;
	text-transform: uppercase;
	font-weight: 600;
	color: #0A043B;
	line-height: 1;
	min-height: 25px;
}

.job-tag span.tag-urgent {
	background: #ff4545;
	color: #fff;
}

.job-tag span.tag-fea {
	background: #0A043B;
	color: #fff;
}

.job-instructor-img {
	margin-right: 20px;
	width: 80px;
	height: 80px;
}

.job-instructor-img-2 {
	position: relative;
	z-index: 1;
}

@media (max-width: 767px) {
	.job-instructor-img-2 {
		float: none;
		margin-bottom: 20px;
	}
}

.job-instructor-title {
	overflow: hidden;
	text-transform:capitalize;
}

.job-instructor-title h4 {
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 6px;
}

.job-instructor-title h4:hover {
	color: #0A043B;
}

.job-instructor-title h5:hover {
	color: #0A043B;
}

.job-instructor-title span {
	color: #667488;
	font-size: 14px;
}

.job-instructor-title span i {
	color: #0A043B;
	margin-right: 5px;
	font-size: 18px;
}

.job-content h4 {
	font-size: 17px;
	font-weight: 500;
	margin-bottom: 15px;
}

.job-content h4:hover {
	color: #0A043B;
}

.job-content p {
	margin-bottom: 25px;
}

.job-salary span {
	margin-right: 50px;
	font-weight: 500;
	font-size: 15px;
}

@media (max-width: 767px) {
	.job-salary span {
		margin-right: 10px;
	}
}

.job-salary i {
	color: #0A043B;
	margin-right: 5px;
	font-size: 18px;
}

.job-salary a {
	font-weight: 700;
	font-size: 14px;
	color: #0A043B;
}

.job-salary a:hover {
	color: #fe9703;
}

.job-salary a:hover i {
	color: #fe9703;
}

.job-salary a i {
	margin-left: 5px;
	-webkit-transform: translateY(0px);
	        transform: translateY(0px);
	display: inline-block;
	font-size: 13px;
}

.banck-icon {
	position: absolute;
	right: 30px;
	z-index: 1;
	top: 30px;
}

.banck-icon i {
	font-size: 100px;
	line-height: 1;
	color: #f7f7f7;
}

.job-meta span {
	margin-right: 15px;
}

.job-meta-2 span {
	display: block;
	margin-bottom: 15px;
}

.job-meta-2 span i {
	color: #0A043B;
}

.job-filter .nice-select {
	border-radius: 0;
	border: solid 1px #ebebeb;
	float: none;
	font-size: 14px;
	height: 55px;
	line-height: 52px;
	width: 100%;
	color: #1c2733;
	font-size: 15px;
	font-weight: 500;
}

.job-filter .nice-select::after {
	right: 20px;
}

.job-widget {
	margin-bottom: 10px;
	border-left: 3px solid #0A043B;
}

.job-widget .nice-select {
	border-radius: 0;
	border: 0;
	float: none;
	font-size: 14px;
	height: 55px;
	line-height: 52px;
	width: 100%;
	color: #1c2733;
	font-size: 15px;
	font-weight: 500;
}

.job-widget .nice-select::after {
	right: 20px;
}

.ui-widget.ui-widget-content {
	border: 0;
	background: #ddd;
	margin-bottom: 15px;
	height: 5px;
}

#slider-range span.ui-slider-handle {
	background: #0A043B;
	border-radius: 30px;
	border: 0;
	height: 15px;
	width: 15px;
}

.ui-slider-horizontal .ui-slider-range {
	top: 0;
	height: 100%;
	background: #0A043B;
}

.price-filter button {
	background: #222;
	color: #fff;
	border: 0;
	padding: 11px 18px;
	line-height: 1;
	font-size: 12px;
	float: left;
}

.price-filter input {
	width: 90px;
	border: 0;
	display: inline-block;
	background: none;
	margin-left: 5px;
	color: #1c2733;
	font-weight: 600;
}

.price-filter span {
	display: inline-block;
}

.price-widget {
	background: #fff;
	padding: 30px;
	margin: 30px 0;
}

.job-item {
	background: #fff;
	padding: 50px;
	border-bottom: 2px solid #fe9703;
}

@media (max-height: 1112px) {
	.job-item {
		background: #fff;
		padding: 10px;
		border-bottom: 2px solid #fe9703;
	}
}
.job-item-2 {
	border-top: 5px solid #fe9703;
	border-bottom: none;
	position: relative;
}

.job-item .job-wrapper {
	border: 0;
	padding: 0;
}

.job-item .banck-icon {
	position: absolute;
	right: 30px;
	z-index: 1;
	top: 0;
}

.job-item .banck-icon i {
	font-size: 100px;
	line-height: 1;
	color: #f7f7f7;
}

.job-item .job-instructor-title {
	position: relative;
	z-index: 2;
	width: 65%;
}

@media (min-width: 501px)  and (max-width: 1112px) {
	.job-item .job-instructor-title {
		width: 100%;
	}

	.job-instructor-profile {
		text-align: center;
	}

	.job-instructor-img {
		margin-left: 40%;
	}
}

@media (max-width: 500px) {
	.job-item .job-instructor-title {
		width: 100%;
	}

	.job-instructor-profile {
		text-align: center;
	}

	.job-instructor-img {
		margin-left: 35%;
	}
}

.map-area iframe {
	min-height: 500px;
	width: 100%;
	border: 0;
}

.job-details-wrapper {
	padding: 0px 50px;
	padding-bottom: 60px;
}

@media (max-width: 767px) {
	.job-details-wrapper {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.job-details-inner {
	padding-top: 60px;
	border-top: 1px solid #eaeaea;
}

.job-list h4 {
	font-size: 22px;
	margin-bottom: 25px;
}

.job-list ul li {
	padding-left: 32px;
	font-size: 15px;
	font-weight: 500;
	position: relative;
	margin-bottom: 16px;
}

.job-list ul li::after {
	position: absolute;
	content: "";
	left: 0;
	top: 0px;
	font-family: "Font Awesome 5 Pro";
	font-size: 15px;
	color: #0A043B;
}

.job-details-video {
	position: relative;
}

.job-details-video img {
	width: 100%;
}

.job-play {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	display: inline-block;
	width: 100px;
	height: 75px;
	line-height: 75px;
	text-align: center;
	background: #ed1c24;
	color: #ffffff;
	border-radius: 7px;
	-webkit-border-radius: 7px;
	-moz-border-radius: 7px;
	-ms-border-radius: 7px;
	-o-border-radius: 7px;
	z-index: 1;
}

.job-play:focus {
	color: #ffffff;
}

.job-play:hover {
	color: #ffffff;
}

.job-thumb img {
	width: 100%;
}

.job-upload {
	position: relative;
}

.job-upload input {
	width: 100%;
	background: #f5f7fc;
	height: 50px;
	line-height: 50px;
	border: none;
	-webkit-appearance: none;
	        appearance: none;
	-moz-appearance: none;
}

.job-upload button {
	position: absolute;
	top: 10px;
	right: 20%;
	border-radius: 5px;
	padding-top: 7px;
	padding-bottom: 7px;
	font-size: 13px;
}

.job-upload button::before {
	border-radius: 5px;
}

.job-tags span {
	font-weight: 600;
	color: #1c2733;
	font-size: 14px;
	margin-right: 15px;
	min-width: 95px;
}

.job-tags ul {
	display: inline-block;
}

.job-tags ul li {
	display: inline-block;
	margin-right: 4px;
}

.job-tags a {
	font-size: 14px;
	font-weight: 500;
	border: 2px solid #e3e3e3;
	height: 30px;
	line-height: 30px;
	padding: 0 13px;
	border-radius: 5px;
	text-transform: uppercase;
}

.job-tags a:hover {
	border-color: #0A043B;
	color: #0A043B;
}

.job-share span {
	font-weight: 600;
	color: #1c2733;
	font-size: 14px;
	margin-right: 15px;
	min-width: 95px;
}

.job-map {
	height: 380px;
	width: 100%;
}

.job-map iframe {
	width: 100%;
	height: 100%;
	border: none;
}

.related-job h4 {
	font-size: 24px;
	margin-bottom: 25px;
}

.related-job-single {
	border: 1px solid #e3e3e3;
}

.related-job-slider .owl-nav {
	display: flex;
	position: absolute;
	top: -60px;
	right: 0;
}

.related-job-slider .owl-nav div button {
	width: 35px;
	height: 35px;
	line-height: 32px;
	color: #1c2733;
	border: 1px solid #e3e3e3;
	background: transparent;
}

.related-job-slider .owl-nav div button:hover {
	background: #0A043B;
	color: #ffffff;
	border-color: #0A043B;
}

.related-job-slider .owl-nav div.owl-prev {
	margin-right: 8px;
}

.related-job-slider .job-instructor-img {
	position: relative;
	z-index: 1;
}

@media (max-width: 767px) {
	.related-job-slider .job-instructor-img {
		margin-bottom: 30px;
		float: none;
	}
}

@media (max-width: 767px) {
	.related-job-slider .job-meta span {
		display: block;
		margin-bottom: 10px;
	}
}

.job-contact {
	padding: 35px 40px;
	border: 1px solid #e3e3e3;
}

@media (max-width: 767px) {
	.job-contact {
		padding: 15px;
	}
}

.job-contact h4 {
	font-size: 22px;
	margin-bottom: 25px;
}

.job-input-2 {
	position: relative;
}

.job-input-2 i {
	position: absolute;
	top: 23px;
	right: 25px;
	color: #0A043B;
}

.job-input-2 input,
.job-input-2 textarea {
	width: 100%;
	background: #f5f7fc;
	border: none;
	height: 60px;
	line-height: 60px;
	padding: 0 25px;
	padding-right: 45px;
	margin-bottom: 20px;
	color: #1c2733;
}

.job-input-2 input::-webkit-input-placeholder, .job-input-2 textarea::-webkit-input-placeholder {
	color: #1c2733;
	font-weight: 600;
}

.job-input-2 input::placeholder,
.job-input-2 textarea::placeholder {
	color: #1c2733;
	font-weight: 600;
}

.job-input-2 input:focus,
.job-input-2 textarea:focus {
	border-color: #0A043B;
}

.job-input-2 input:focus::-webkit-input-placeholder, .job-input-2 textarea:focus::-webkit-input-placeholder {
	opacity: 0;
}

.job-input-2 input:focus::placeholder,
.job-input-2 textarea:focus::placeholder {
	opacity: 0;
}

.job-input-2 textarea {
	padding: 15px 20px;
	padding-right: 40px;
	height: 190px;
	resize: none;
	line-height: 28px;
}

.job-instructor-img img {
	width: inherit !important;
	display: inline-block !important;
}

.emp-sidebar {
	box-shadow: 0px 10px 30px 0px rgba(207, 207, 207, 0.25);
	padding: 30px;
}

.emp-sidebar .job-widget {
	border-left: 0;
	border: 1px solid #ebebeb;
}

.f-btn {
	color: #ffffff;
	font-weight: 700;
	font-size: 15px;
	background: #0A043B;
	line-height: 1;
	display: block;
	padding: 22px 30px;
	border-radius: 0;
	text-align: center;
}

.f-btn i {
	margin-left: 5px;
	font-size: 14px;
}

.emp-rating i {
	color: #fe9703;
	font-size: 12px;
}

.employer-item {
	/* box-shadow: 0px 10px 30px 0px rgba(207, 207, 207, 0.25); */
	border: solid 1px rgb(150, 145, 171);
}

@media (min-width: 1113px){
	.employer-item {
		padding: 20px;
	}
}

.empl-btn {
	position: absolute;
	top: 50%;
	bottom: 50%;
	right: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.empl-btn {
		position: static;
		margin-top: 20px;
		float: right;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.empl-btn {
		position: static;
		margin-top: 20px;
		float: right;
	}
}

@media (max-width: 767px) {
	.empl-btn {
		position: static;
		margin-top: 20px;
		float: right;
	}
}

.employer-item .banck-icon {
	right: 31%;
}

.job-meta span i {
	color: #0A043B;
}

.employer__inner {
	position: relative;
	z-index: 1;
}

.employer__inner::after {
	position: absolute;
	content: "";
	left: -115px;
	top: -65px;
	height: calc(100% + 110px);
	width: calc(100% + 230px);
	background: #ffffff;
	z-index: -1;
	border-top: 5px solid #fe9703;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
	.employer__inner::after {
		left: 0px;
		width: calc(100% + 0px);
	}
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.employer__inner::after {
		left: -15px;
		width: calc(100% + 30px);
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.employer__inner::after {
		left: -30px;
		width: calc(100% + 60px);
	}
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.employer__inner::after {
		left: -30px;
		width: calc(100% + 60px);
	}
}

@media (max-width: 767px) {
	.employer__inner::after {
		left: -15px;
		width: calc(100% + 30px);
	}
}

.employer__content h5 {
	margin-bottom: 0;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.employer__btn {
		margin-top: 20px;
	}
}

@media (max-width: 767px) {
	.employer__btn {
		margin-top: 20px;
	}
}

.employer__list ul li {
	font-size: 15px;
	font-weight: 500;
	position: relative;
	padding-left: 50px;
	margin-bottom: 33px;
	color: #1c2733;
	font-weight: 500;
}

.employer__list ul li::before {
	position: absolute;
	content: "";
	left: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	color: #0A043B;
	width: 35px;
	height: 35px;
	text-align: center;
	line-height: 31px;
	border: 2px solid #e0e0e0;
	font-family: "Font Awesome 5 Pro";
}

.employer__list ul li:hover {
	color: #0A043B;
}

.employer__list ul li:hover::before {
	background: #0A043B;
	border-color: #0A043B;
	color: #ffffff;
}

.employer__info-wrapper {
	border-top: 1px solid #e0e0e0;
}

.employer__info p {
	margin-bottom: 15px;
}

.employer__title h3 {
	font-size: 22px;
	position: relative;
	padding-bottom: 25px;
	margin-bottom: 20px;
}

.employer__title h3::after {
	position: absolute;
	content: "";
	left: 0;
	bottom: 0;
	width: 50px;
	height: 3px;
	background: #0A043B;
}

.employer__thumb img {
	width: 100%;
}

.employer__video {
	position: relative;
}

.employer__video::after {
	position: absolute;
	content: "";
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background: rgba(28, 39, 51, 0.3);
}

.employer__video img {
	width: 100%;
}

.employer__play {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	display: inline-block;
	width: 100px;
	height: 75px;
	line-height: 75px;
	text-align: center;
	background: #ed1c24;
	color: #ffffff;
	border-radius: 7px;
	-webkit-border-radius: 7px;
	-moz-border-radius: 7px;
	-ms-border-radius: 7px;
	-o-border-radius: 7px;
	z-index: 1;
}

.employer__play:focus {
	color: #ffffff;
}

.employer__play:hover {
	color: #ffffff;
}

.employer__faq .card {
	box-shadow: 0px 10px 30px 0px rgba(207, 207, 207, 0.25);
}

.employer__company {
	padding: 40px;
	border: 1px solid #e0e0e0;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.employer__company {
		margin-top: 50px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.employer__company {
		margin-top: 50px;
	}
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.employer__company {
		margin-top: 50px;
	}
}

@media (max-width: 767px) {
	.employer__company {
		margin-top: 50px;
	}
}

.employer__company h4 {
	font-size: 22px;
	margin-bottom: 25px;
}

.employer__company ul li {
	overflow: hidden;
	margin-bottom: 18px;
}

.employer__company ul li:last-child {
	margin-bottom: 0;
}

.employer__company ul li h5 {
	width: 50%;
	float: left;
	font-size: 15px;
	color: #667488;
	margin-bottom: 0;
	line-height: 23px;
}

@media (max-width: 767px) {
	.employer__company ul li h5 {
		float: none;
		width: 100%;
	}
}

.employer__company ul li h5 i {
	color: #0A043B;
	padding-right: 8px;
}

.employer__company ul li span {
	display: inline-block;
	width: 50%;
	float: right;
	font-weight: 500;
	color: #1c2733;
}

@media (max-width: 767px) {
	.employer__company ul li span {
		float: none;
		width: 100%;
	}
}

.employer__map {
	height: 420px;
	width: 100%;
}

.employer__map iframe {
	height: 100%;
	width: 100%;
	border: none;
}

.job-item-3 {
	border: 1px solid #e0e0e0;
	transition: all 0.3s ease-out 0s;
}

.job-item-3:hover {
	box-shadow: 0px 10px 30px 0px rgba(207, 207, 207, 0.25);
	border-color: #ffffff;
}

@media (max-width: 767px) {
	.job-item-3 .job-instructor-img {
		float: none;
		margin-bottom: 20px;
	}
}

.mb-30 {
	margin-bottom: 30px;
}

.f-left {
	float: left;
}

/* .empl-btn {
	position: absolute;
	top: 50%;
	bottom: 60%;
	right: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.empl-btn {
		position: static;
		margin-top: 20px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.empl-btn {
		position: static;
		margin-top: 20px;
	}
}

@media (max-width: 767px) {
	.empl-btn {
		position: static;
		margin-top: 20px;
	}
} */

.b-btn {
	color: #ffffff;
	font-weight: 700;
	font-size: 15px;
	background: #fe9703;
	line-height: 1;
	display: inline-block;
	padding: 17px 35px;
	border-radius: 30px;
	position: relative;
	border: none;
	outline: none;
	z-index: 1;
	overflow: hidden;
}

.b-btn i {
	margin-left: 5px;
}

.b-btn::before {
	top: 0px;
	width: 0px;
	left: auto;
	right: 0px;
	z-index: -1;
	bottom: 0px;
	content: "";
	position: absolute;
	transition: all 0.3s ease-in-out;
	background: #0A043B;
	border-radius: 30px;
}

.b-btn:hover {
	color: #ffffff;
}

.b-btn:hover::before {
	left: 0px;
	width: 100%;
	right: auto;
}

.b-btn-green {
	background: #26ae61;
}

.b-btn-green::before {
	background: #1c2733;
}

.b-btn-green-2::before {
	border-radius: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	-o-border-radius: 0;
}

.b-btn-green-2:hover {
	color: #1c2733;
}

.b-btn-green-2:hover::before {
	background: #ffffff;
}

.b-btn-brand {
	color: #ffffff;
	font-weight: 700;
	font-size: 15px;
	background: #fe9703;
	line-height: 1;
	display: inline-block;
	padding: 17px 35px;
	border-radius: 30px;
	position: relative;
	border: none;
	outline: none;
	z-index: 1;
	overflow: hidden;
}

.b-btn i {
	margin-left: 5px;
}

.b-btn-brand::before {
	top: 0px;
	width: 0px;
	left: auto;
	right: 0px;
	z-index: -1;
	bottom: 0px;
	content: "";
	position: absolute;
	transition: all 0.3s ease-in-out;
	border-radius: 30px;
}

.b-btn-green-brand {
	background: #0A043B;
}

.b-btn-green-brand:hover {
	background: #0A043B;
	color: #0A043B;
}

.b-btn-grey {
	background: #f5f7fc;
	color: #1c2733;
}

.b-btn-white:hover {
	background: #0A043B;
	color: #1c2733;
}

.b-btn-white:hover::before {
	background: #ffffff;
}

.b-btn-white-2 {
	background: #ffffff;
	color: #1c2733;
}

.b-btn-white-2::before {
	background: #1c2733;
}


/* basic pagination */

.basic-pagination ul {
	display: block;
}

.basic-pagination ul li {
	display: inline-block;
	margin-right: 8px;
}

.basic-pagination ul li.active a {
	background-color: #fe9703;
	color: #ffffff;
}

.basic-pagination ul li a {
	height: 45px;
	width: 45px;
	background: transparent;
	font-size: 15px;
	font-weight: 500;
	border-radius: 50%;
	line-height: 45px;
	margin: 0px;
	display: inline-block;
	text-align: center;
	position: relative;
	z-index: 1;
	background: #ffffff;
	color: #667488;
}

.basic-pagination ul li a:hover {
	background: #fe9703;
	color: #ffffff;
}

.basic-pagination-2 ul li a {
	background: #f5f7fc;
}

.mt-25 {
	margin-top: 25px;
}

.mt-30 {
	margin-top: 30px;
}

.mt-50 {
	margin-top: 50px;
}

.mb-25 {
	margin-bottom: 25px;
}

.mb-30 {
	margin-bottom: 30px;
}

.mb-75 {
	margin-bottom: 75px;
}

.pt-35 {
	padding-top: 35px;
}

.pb-140 {
	padding-bottom: 140px;
}


/* Candidate */

.grey-bg {
	background: #f5f7fc;
}

.candidate__item {
	padding: 30px 50px;
	/* padding-top: 50px; */
	padding-top: 20px;
	border: 1px solid rgb(10, 4, 59, 0.50);
	position: relative;
	z-index: 1;
	/* height: 460px; */
	/* new css added*/
	border-radius: 4px;
}

@media only screen and (max-width: 992px) {
	.candidate__item {
		padding-left: 10px;
		padding-right: 10px;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.candidate__item {
		padding-left: 10px;
		padding-right: 10px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.candidate__item {
		padding-left: 20px;
		padding-right: 20px;
	}
}

.candidate__item-2 {
	padding: 30px 50px;
	padding-top: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.candidate__item-2 {
		padding-left: 15px;
		padding-right: 15px;
	}
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.candidate__item-2 {
		padding-left: 15px;
		padding-right: 15px;
	}
}

@media (max-width: 767px) {
	.candidate__item-2 {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.candidate__inner {
	position: relative;
}

/* .candidate__inner::after {
	position: absolute;
	content: "";
	left: -115px;
	top: -60px;
	height: calc(100% + 120px);
	width: calc(100% + 150px);
	background: #ffffff;
	border-top: 5px solid red;
} */

/* @media only screen and (min-width: 1200px) and (max-width: 1600px) {
	.candidate__inner::after {
		left: -15px;
		width: calc(100% + 30px);
	}
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.candidate__inner::after {
		left: -30px;
		width: calc(100% + 60px);
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.candidate__inner::after {
		left: -30px;
		width: calc(100% + 60px);
	}
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.candidate__inner::after {
		left: -30px;
		width: calc(100% + 60px);
	}
}

@media (max-width: 767px) {
	.candidate__inner::after {
		left: -15px;
		width: calc(100% + 30px);
	}
} */

.candidate__content h5 {
	font-size: 20px;
	margin-bottom: 5px;
}

.candidate__content>span {
	color: #fe9703;
	font-weight: 500;
}

.candidate_desc > label {
	font-size: 16px;
}

.candidate_desc {
	text-overflow: ellipsis;
	height: 110px;
	overflow: hidden;
	/* white-space: nowrap; */
}

.candidate__info {
	/* new css added*/
}

.candidate__info span {
	display: block;
	margin-bottom: 5px;
}

.candidate__info span i {
	color: #0A043B;
	margin-right: 4px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.candidate__info-2 {
		margin-right: 0;
	}
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.candidate__info-2 {
		margin-right: 15px;
	}
}

@media (max-width: 767px) {
	.candidate__info-2 {
		margin-right: 15px;
		margin-bottom: 20px;
	}
}

.candidate__skill {
	/* new css added*/
	/* display: flex; */
}

.candidate__skill a {
	display: inline-block;
	height: 35px;
	line-height: 31px;
	padding: 0 10px;
	border: 2px solid #e3e3e3;
	border-radius: 18px;
	font-weight: 500;
	margin: 5px 5px;
}

.candidate__skill-2 a {
	margin: 0;
	margin-right: 10px;
}

@media (max-width: 767px) {
	.candidate__skill-2 a {
		margin-right: 5px;
	}
}

.candidate__btn {
	position: absolute;
	bottom: 0;
	left: 50%;
	-webkit-transform: translate(-50%, 50%);
	transform: translate(-50%, 50%);
	width: 100%;
}

.candidate__thumb {
	/* new css added*/
	display: flex;
	align-items: left;
	margin-top: 10px;
}

.candidate__thumb > img {
	width: 100px;
	height: 100px;
	border-radius: 50px;
}

.candidate_buttons_view {
	display: flex;
	justify-content: center;
	align-items: baseline;
}

.candidate_hold_reject_view {
	display: flex;
	align-items: baseline;
	justify-content: space-between;
}

.candidate-job-badge {
	text-align: right;
	width: 10%;
}


@media (max-width: 992px){
	.candidate__thumb {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.job_info_outer {
		text-align: center;
	}

	.candidate_desc {
		height: 50px;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.candidate_buttons_view {
		flex-direction: column;
		align-items: initial;
		margin-top: 10px;
	}

	.button-primary+.button {
		margin-top: 10px;
	}

	.candidate__item {
		/* height: 535px; */
	}

	.candidate-job-badge {
		text-align: center;
		width: auto;
	}
}

@media (min-width: 992px) and (max-width: 1225px){
	/* .candidate__thumb {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	} */

	/* .candidate__info_outer {
		text-align: center;
	} */

	.candidate_desc {
		height: 50px;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.candidate_buttons_view {
		flex-direction: column;
		align-items: initial;
		margin-top: 10px;
	}

	.button-primary+.button {
		margin-top: 10px;
	}

	.candidate__item {
		height: 480px;
	}

	/* .candidate-job-badge {
		text-align: center;
		width: auto;
	} */
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.candidate__thumb-2 {
		margin-right: 15px;
	}
}

@media (max-width: 767px) {
	.candidate__thumb-2 {
		margin-top: 20px;
	}
}

.div-margin {
	margin-left: -70px !important;
	margin-right: 30px !important;
}

/* Accordian */
.card1 {
    margin-left: 50px;
    z-index: 0;
	margin-top: 40px;
}

.card-top {
	margin-top: 135px;
	/* border: 2px solid rgb(150, 145, 171); */
	box-shadow: 0px 8px 60px -10px rgba(13,28,39,0.6);
	padding: 20px;
	margin-right: 20px;
}

/* @media screen and (max-width: 1000px) and (min-width: 700px) {
	.card-top {
		margin-left:50px;
	}
} */

#progressbar {
    position: absolute;
    left: 20px;
    overflow: hidden;
    color: #0A043B
}

#progressbar li {
    list-style-type: none;
    font-size: 8px;
    font-weight: 400;
}

#progressbar li:nth-child(3) {
    /* margin-bottom: 55px */
}

#progressbar .step0:before {
    content: "";
    color: #fff;
}

#progressbar li:before {
    width: 30px;
    height: 30px;
    line-height: 30px;
    display: block;
    font-size: 20px;
    background: #fff;
    border: 2px solid #0A043B;
    border-radius: 50%;
    margin: auto
}

#progressbar li:after {
    content: '';
    width: 3px;
    height: 66px;
    background: #BDBDBD;
    position: absolute;
    left: 58px;
    z-index: -1
}

#progressbar li.active:after {
    background: #0A043B
}

#progressbar li.active:before {
    background: #0A043B;
    font-family: FontAwesome;
    content: "\f00c"
}

.tick {
    width: 100px;
    height: 100px
}

.top-1 {
    margin-top: 10px
}

.top-2 {
    margin-top: 55px
}

.top-3 {
    margin-top: 55px
}

.top-4 {
    margin-top: 55px
}
@media screen and (max-width: 912px) {
    .card00 {
        padding-top: 30px
    }

    .card1 {
        border: none;
        margin-left: 50px
    }

    .card2 {
        border-bottom: 1px solid #F5F5F5;
        margin-bottom: 25px
    }

    #progressbar {
        left: -25px
    }
}

.candidate-list-outer-box:nth-child(odd) {
	padding-left: 0px;
}

@media (max-width: 768px){
	.candidate-list-outer-box {
		padding-left: 0px;
	}
}

@media (max-width: 321px){
	.page-item:nth-child(5) {
		display: none;
	}
}

.company_list_desc {
	/* width: 35vw; */
	/* padding-right: 20px; */
	/* overflow-wrap: break-word;
	word-wrap: break-word; */
}

.company_list_desc > label {
	text-align: justify;
}

.company-outer-view {
	padding-left: 0px;
}

.candidate__image {
	width: 100px;
	height: 100px;
}

.job-list-outer-box:nth-child(odd) {
	padding-left: 0px;
}

@media (max-width: 768px){
	.job-list-outer-box {
		padding-left: 0px;
	}
}

.job_item {
	padding: 30px 50px;
	/* padding-top: 50px; */
	padding-top: 20px;
	padding-left: 20px;
	padding-right: 20px;
	border: 1px solid rgb(10, 4, 59, 0.50);
	position: relative;
	z-index: 1;
	/* height: 460px; */
	/* min-height: 374px; */
	/* new css added*/
	min-height: 430px;
	/* max-height: 430px; */
	border-radius: 4px;
}

@media only screen and (max-width: 992px) {
	.job_item {
		padding-left: 10px;
		padding-right: 10px;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.job_item {
		padding-left: 10px;
		padding-right: 10px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.job_item {
		padding-left: 20px;
		padding-right: 20px;
	}
}

.job_item-2 {
	padding: 30px 50px;
	padding-top: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.job_item-2 {
		padding-left: 15px;
		padding-right: 15px;
	}
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.job_item-2 {
		padding-left: 15px;
		padding-right: 15px;
	}
}

@media (max-width: 767px) {
	.job_item-2 {
		padding-left: 15px;
		padding-right: 15px;
	}
}

/* Job styles Start */

.job_thumb {
	/* new css added*/
	display: flex;
	align-items: left;
	margin-top: 10px;
}

.job_thumb > img {
	width: 90px;
	height: 90px;
}

.candidate-job-badge {
	text-align: right;
	width: 10%;
}

.job_image {
	width: 100px;
	height: 100px;
}

.job_info {
	/* new css added*/
}

.job_info span {
	display: block;
	margin-bottom: 5px;
}

.job_info span i {
	color: #0A043B;
	margin-right: 4px;
}

.job_content h5 {
	font-size: 20px;
	margin-bottom: 5px;
}

.job_content>span {
	color: #fe9703;
	font-weight: 500;
}

.job_desc > label {
	font-size: 14px;
}

.job_desc {
	text-overflow: ellipsis;
	height: 72px;
	max-height: 72px;
	overflow: hidden;
	cursor: pointer;
	/* white-space: nowrap; */
}

.job_buttons_view {
	display: flex;
	align-items: baseline;
	justify-content: center;
}

.job-type-reject {
	display: flex;
	align-items: baseline;
	justify-content: center;
}

.job-badge {
	text-align: left;
	width: auto;
}

.job-btn-margin-left {
	margin-left: 10px;
}

@media (max-width: 992px){
	.candidate__thumb {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.job_info_outer {
		text-align: left;
	}

	.job_desc {
		/* height: 50px; */
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.job_buttons_view {
		flex-direction: column;
		align-items: initial;
		margin-top: 10px;
	}

	.job-type-reject {
		flex-direction: column;
		align-items: initial;
		margin-top: 10px;
	}

	.button-primary+.button {
		margin-top: 10px;
	}
	
	.job-badge {
		padding-left: 5%;
	}

	.job-btn-margin-left {
		margin-left: 0px;
	}
}

@media (min-width: 992px) and (max-width: 1225px){
	/* .candidate__thumb {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	} */

	/* .candidate__info_outer {
		text-align: center;
	} */

	.job_desc {
		/* height: 50px; */
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.job_buttons_view {
		flex-direction: column;
		align-items: initial;
		margin-top: 10px;
	}

	.job-type-reject {
		flex-direction: column;
		align-items: initial;
		margin-top: 10px;
	}

	.button-primary+.button {
		margin-top: 10px;
	}

	.candidate__item {
		height: 480px;
	}

	/* .candidate-job-badge {
		text-align: center;
		width: auto;
	} */
}

/* Job Styles End */

/* Resume Styles Start */
.profile-light-divider-vertical {
	border: 1px solid rgb(150, 145, 171);
	height: auto;
	width: 1px;
	margin-left: 10px;
	background-color: rgb(150, 145, 171);
}

.profile-light-divider-vertical-small {
	border: 1px solid rgb(150, 145, 171);
	height: 3vh;
	width: 1px;
	margin-left: 10px;
	margin-top: 4px;
	background-color: rgb(150, 145, 171);
}
                          
.candidate_name {
	padding-left: 10px;
}

.profile-light-name {
	min-width: 200px;
	text-transform: capitalize;
}

@media (max-width: 768px){
	.profile-light-divider-vertical {
		display: none;
	}

	.profile-light-divider-vertical-small {
		display: none;
	}

	.margins-certification{
		margin-left: 35px !important;
	}

	.profile-light-main{
		/* display: inline-block; */
		display: inline-grid;
	}

	.candidate_name {
		text-align: left;
		padding-left: 0px
	}

	.profile-light-position {
		text-align: letf;
	}

	.resume-job-title {
		justify-content: flex-start !important;
	}
}

@media (max-width:350px){
	.profile-light-main{
		display: inline-block;
	}
}
/* Resume Styles End */

/* Job Details Styles Start */
.location-date-font {
	font-size: 16px !important;
}

.company-title {
	display: flex;
	justify-content: space-between;
	vertical-align: middle;
}

.company-title > span {
	padding-top: 3px;
	font-size: 12px;
	font-style: italic;
	vertical-align: middle;
}

.company-title > span:hover {
	color: #0A043B;
	cursor: pointer;
}

.outer-button-view {
	text-align: center;
}

.apply-button-view {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
	vertical-align: middle;
	margin: 30px auto;
}

.apply-button-view > div {
	margin: 10px 10px;
}

.submit-form-view {
	display: flex;
	flex-wrap : nowrap;
	justify-content : flex-start;
}

@media only screen and (max-width: 768px) {
	.submit-form-view {
		display: inline-grid;
	}

	.job-submit-form-view {
		justify-content : flex-start;
	}
}	

.submit-form-view :nth-child(3n){
	/* margin-left: 20px; */
	/* font-weight: 500; */
}

/* About Us Page */
/* .founder-image-view {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	flex-shrink: 0;
	margin-top: 40px;
} */

.founder-image-view {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	flex-shrink: 0;
	margin-top: 79px;
	margin-left: 10vw;
	margin-right: 10vw;
}

.founder-image-view > img {
	height: 250px;
}

.founder-info-view {
	text-align: center;
}

.founder-info-view  > hr{
	margin-top: 50px;
	margin-bottom: 20px;
	width: 80%;
}

@media only screen and (max-width: 768px) {
	.founder-image-view :not( :first-child){
		margin-top: 20px;
	}

	.list-linked {
		margin-left: 0px;
		margin-right: 0px;
	}
} 

.timeline-classic-main-resume {
	position: relative;
	padding: 10px 0 30px 20px;
}

.timeline-classic-main-resume::before,
.timeline-classic-main-resume::after {
	content: '';
	position: absolute;
	pointer-events: none;
}

.timeline-classic-main-resume::before {
	left: 0;
	top: 0;
	bottom: 0;
	border-left: 1px solid rgb(150, 145, 171);
}

.timeline-classic-main-resume *+p {
	margin-top: 10px;
}

.timeline-classic-item:last-child .timeline-classic-main-resume {
	padding-bottom: 0;
}

.timeline-classic-item:last-child .timeline-classic-main-resume::before {
	bottom: 20px;
}

*+.timeline-classic {
	margin-top: 30px;
}

.timeline-classic-period-resume {
	padding-right: 20px;
	white-space: nowrap;
	letter-spacing: .025em;
}

@media (max-width: 767.98px) {
	.timeline-classic-period-resume {
		margin-left: -2px;
		color: green;
	}
}

@media (min-width: 768px) {
	.timeline-classic-main-resume {
		padding-bottom: 20px;
	}
	.timeline-classic-main-resume::after {
		top: 18px;
		left: -4px;
		width: 9px;
		height: 9px;
		background: green;
		border-radius: 50%;
	}
}

.select2-dropdown {
	border: 1px solid rgb(150, 145, 171)
}

.select2-container--default .select2-selection--single {
	border: 1px solid rgb(150, 145, 171)
}

.experience-and-eduction-div {
	border: 1px solid rgb(150, 145, 171);
}

.delete-icon-div {
	/* height: 50px; */
	/* width: 50px; */
	color: #ffffff;
	margin-bottom: 10px;
	cursor: pointer;
}

.resume :nth-child(2) > span{
	z-index: 0;
}

.Toastify__toast--success {
	background: #0A043B !important;
}

.modalStyles{
	background: #ffffff;
	margin-top: 20vh;
	margin-left: 5vw;
	margin-right: 5vw;
	height: 70vh;
	box-shadow: 2px 2px 10px 7px rgba(76,76,76,0.29);
	/* z-index: 99; */
	overflow-y: scroll;
}

.ReactModal__Overlay {
	z-index: 9999;
}

.job-application-tag-div{
	display: flex;
    justify-content: left;
    align-content: flex-start;
	flex-wrap: wrap;
	padding-left: 10px;
}

.job-application-label{
	font-size: 12px;
    font-style: italic;
    text-decoration: underline;
	cursor: pointer;
	margin-left: 10px;
	margin-top: 5px;
}

.error-message{
	color: #eb6a6a;
}

.Toastify__toast--error {
	background: #0A043B !important;
}

.Toastify__toast--warning {
	background: #0A043B !important;
}

.Toastify__toast--info {
	background: #0A043B !important;
}

.search-icon-only-btton{
	max-height: 60px;
}

.react-select__value-container{
	z-index: 999;
	background-color: #ffffff;
	opacity: 1;
	font-weight: 500;
}

.list-style{
	list-style-type: disc;
	padding: 0 0 23px 1em;
	line-height: 26px;
}

.signup-checkbox-label {
	margin-left: -5px;
	text-decoration: underline;
	color: #35a1ff;
}

.privacy-list-ordered {
	counter-reset: li;
	padding-left: 0px;
	text-align: justify;
	color: #151515;
}

.privacy-list-ordered>li {
	position: relative;
	list-style: none;
	padding-left: 25px;
}

.privacy-list-ordered>li:before {
	content: "(" counter(li, lower-alpha) ")";
	counter-increment: li;
	position: absolute;
	top: 0;
	left: 0;
	display: inline-block;
	width: 15px;
	color: #000000;
}

.privacy-list-ordered>li+li {
	margin-top: 10px;
}

*+.privacy-list-ordered {
	margin-top: 15px;
}

.privacy-center{
	margin-left: 400px;
}

.privacy-link-color{
	color: #0000ff !important;
}

.privacy-nested2-list{
	margin-top: 0px;
	margin-left:70px;
}
.privacy-nested-list {
	counter-reset: item;
	padding-left: 0px;
	text-align: justify;
	color: #151515;
	margin-left: 60px;
}

.privacy-nested-list>li {
	position: relative;
	list-style: none;
	padding-left: 25px;
}

.privacy-nested-list>li:before {
	content: "(" counter(item, lower-roman) ")";
	counter-increment: item;
	position: absolute;
	margin-right: 40px;
	top: 0;
	left: -13px;
	display: inline-block;
	width: 15px;
	color: #000000;
}

.privacy-nested-list>li+li {
	margin-top: 10px;
}

*+.privacy-nested-list {
	margin-top: 15px;
}

.terms-list-ordered {
	counter-reset: item;
	padding-left: 0px;
	text-align: justify;
	color: #151515;
	margin-left: 20px;
}
.terms-list-ordered>li {
	display: block;
	color: #000000;
}
.terms-list-ordered>li:before {
	content: counters(item, ".") " ";
	counter-increment: item;
	position: absolute;
	left:35px;
	width:15px;
  }
.terms-termination-margin{
	margin-left: 20px;
}

.terms-list-heading{
	margin-left: 20px;
}

.job--title {
	display: flex;
	justify-content: flex-start;
	flex-direction: row;
	flex-wrap: wrap;
}

.job--title > span {
	font-size: 12px;
	text-transform: uppercase;
	line-height: 2.2;
	/* margin-left: 5px; */
}

.resume-div {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	flex-direction: row;
	padding: 0px 10px;
	margin-top: 20px;
	width: 702px;
	background-color: #FFFFFF;
	box-shadow: 0px 20px 60px rgba(241, 244, 248, 0.5);
	border-radius: 16px;
}

@media (max-width: 320px) {
	.resume-div {
		width: 280px;
		margin-left: 1px !important;
	}

	.file-upload-div {
		padding-right: 10px;
	}
	
}
@media (max-width:320px){
	.privacy-responsive{
		width: 290px !important;
		padding:8px;
	}
	.privacy-center{
		margin-left: 11px;
	}
	.text-center{
		text-align: center;
	}
	.terms-responsive{
		margin-left: 35px;
	}
}

@media (max-width: 375px) {
	.resume-div {
		width: 330px;
		margin-left: 1px !important;
	}

	.file-upload-div {
		padding: 10px !important;
	}
}
@media screen and(max-width: 375px), screen and (min-width:321px){
	.privacy-responsive{
		width: 330px;
		padding: 10px;
	}
	.terms-responsive{
		margin-left: 35px;
	}
	.privacy-center{
		margin-left: 11px;
	}
	.text-center{
		text-align: center;
	}
	.react-date-picker__wrapper{
		padding-left:20px !important;
	}
}
@media screen and (max-width: 414px), screen and (min-width:376){
	.privacy-responsive{
		width: 390px;
		padding:12px;
	}
}
@media screen and (max-width: 786px), screen and (min-width:415px){
	.privacy-responsive{
		 width: 750px;
		 padding: 15px;
	}
}
@media screen and(max-width: 1200px), screen and (min-width: 787px){
	.privacy-responsive{
		width: 1150px;
		padding: 20px;
	}
	.text-center{
		text-align: center;
	}
	.react-date-picker__wrapper{
		padding-left:2px !important;
	}
}

@media (max-width: 768px) {
	.resume-div {
		width: 290px;
		margin: auto !important;
		margin-bottom: 1px !important;
		padding: 15px 0px;
	}

	.submit-btn-resp {
		margin-top: 20px;
	}

	.block-form {
		margin-left: 10px;
		padding-right: 10px;
	}

	.file-upload-div {
		padding-right: 10px;
	}

	.default-resume {
		margin-left: 10px;
	}
}

@media (min-width: 769px) {
	.resume-div {
		width: 752px;
		margin-left: 1px !important;
	}
}

/* ==================================== */
.resume-content-div {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
}

@media (max-width: 320px) {
	.resume-content-div {
		align-items: flex-start;
	}
}

@media (max-width: 375px) {
	.resume-content-div {
		align-items: flex-start;
	}
}
/* ==================================== */
.file-icon-view {
	margin: 10px 10px 15px 10px;
	padding: 0px 10px;
}

@media (max-width: 320px){
	.file-icon-view {
		margin: 10px -28px;
		text-align: center;
	}
}

@media (max-width: 375px){
	.file-icon-view {
		margin: 10px -20px;
		text-align: center;
	}
}

@media (max-width: 768px){
	.file-icon-view {
		text-align: center;
	}
}

/* ==================================== */
.resume-file-view{
	min-width: 260px;
}

@media (max-width: 320px){
	.resume-file-view  {
		margin-left: -10px;
	}
}

@media (max-width: 375px){
	.resume-file-view  {
		margin-left: 0px;
	}
}
/* ==================================== */
.resume-file-title {
	font-size: 16px;
	color: #000000;
	font-weight: 700;
}

.resume-icon-view {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

@media (max-width: 768px){
	.resume-icon-view  {
		justify-content: center;
		margin: auto;
	}
}

#default-flag {
	margin-top: -10px;
}

.delete-icon-view {
	height: 50px;
}

.resume-delete-icon{
	font-size: 18px;
	color: "red" !important;
}

.skills-delete-icon{
	font-size: 18px;
	color: "red" !important;
}

.resume-vertical-line {
	width: 2px;
	background-color: #151515;
	height: 120px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 10px;
	margin-top: 10px !important;
}

@media screen AND (max-width: 768px) {
	.resume-vertical-line {
		display: none !important;
	}
}

.icon-alignment-file-document{
	top: 5px !important;
}

@media only screen and (min-width: 280px) and (max-width: 653px) {
	.icon-3-company-edit {
		font-size: 20px !important;
		margin-left: 5% !important;
	}
}

.job-application-hold{
	background-color: #279f8d;
	/* font-size: 12px;
	cursor: pointer;
	margin-top: 5px;
	/* background: green; */
	/* padding: 4px 10px !important; */
	/* font-weight: 500; */
	/* border-radius: 0; */
  	/* vertical-align: middle; */
	/* text-transform: uppercase; */
  	/* letter-spacing: .1em; */
}

.job-application-reject{
	/* font-size: 12px; */
    /* font-style: italic; */
	/* cursor: pointer; */
	/* margin-left: 1vw; */
	margin-top: 5px;
	background: #FAA0A0	 !important;
	border: 1px solid grey;
	padding: 4px 10px !important;
	/* color: #000000; */
	font-weight: 500;
	background-color: red;
}

.job-application-rejected{
	/* font-size: 12px; */
    /* font-style: italic; */
    /* text-decoration: underline; */
	/* cursor: pointer; */
	/* margin-left: 60%; */
	/* margin-top: 5px; */
	background: #b4002a;
	/* border: none; */
	/* padding: 0!important; */
	/* color: red; */
	/* text-decoration: underline; */
}

/* .candidate-job-reject{
	font-size: 12px;
    font-style: italic;
    text-decoration: underline;
	cursor: pointer;
	margin-left: 48%;
	margin-top: 5px;
	background: none!important;
	border: none;
	padding: 0!important;
	color: #9a9a9a;
	text-decoration: underline;
}

.candidate-job-reject:hover {
	color: red;
} */

.candidate-job-reject :hover {
	cursor: pointer;
	background: #d2691e;
}

.candidate-job-rejected :hover{
	cursor: pointer;
	background: #437685;
}

.status{
	display: inline-block;
	padding: 3px 6px 4px;
	font: 500 12px/12px "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", rubiklight, "Helvetica Neue", Arial, sans-serif;
	text-transform: uppercase;
	letter-spacing: .1em;
	color: #ffffff;
	background: #0A043B;
	border-radius: 0;
	vertical-align: middle;
	margin-left: 5px;
}

.status-secondary{
	background: rgb(39, 159, 141);
}

.status-tertiary{
	background: rgb(180, 0, 42);
}

.forgot-text-div {
	cursor: pointer;
}

.form-label-outside.error {
	color: #eb6a6a;
}

.related-job-row {
	cursor: pointer;
}

.border-line {
	border-right: 1px solid #000;
}

@media (max-width: 990px) {
	.job-status-vertical-line{
		display: none;
	}
}

.company_list_desc-admin {
	padding-right: 25px;
  }
  
  .company_list_desc-admin > label {
	text-align: justify;
  }
  
  .reject-admin{
	margin-top: 0%;
	margin-left : -30%;
  }
  
  .approved-admin{
	margin-top: 0%;
	margin-left : -30%;
  }
  
  .drop-down-admin{
	width: 30%;
	align-items: center;
	margin-left: 20%;
  }
  
  .empl-btn-admin {
	position: absolute;
	top: 50%;
	bottom: 50%;
	right: 0;
  }
  
  @media only screen and (min-width: 276px) and (max-width: 653px) {
	.empl-btn-admin {
	  position: static;
	  margin-top: 20px;
	}
  
	.reject-admin{
	  margin-top: 0% !important;
	  margin-left: 4%;
	}
  
	.approved-admin{
	  margin-top: 0% !important;
	  margin-left: 4%;
	}
  }

  @media (min-width: 760px)  and (max-width: 1112px) {
	.empl-btn-admin {
	  position: static;
	  margin-top: 20px;
	}
  
	.reject-admin{
	  margin-top: 0% !important;
	  margin-left: 2%;
	}
  
	.approved-admin{
	  margin-top: 0% !important;
	  margin-left: 2%;
	}
  }
  
  .disableClick{
	  pointer-events: none;
  }

  .admin-employer-row {
	cursor: pointer;
}
.captialise-text{
	text-transform: capitalize !important;
}

.company-light-title {
	text-transform: capitalize;
}

.rejected_job_card{ 
	min-height: 350px;
}

.slider-skills {
	width: 70%;
}

.infobox-wrapper {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	width: 100%;
	background-color: #0A043B;
	opacity: 0.85;
	padding: 12px;
	color: #FFFFFF;
	border: solid 1px #FFFFFF;
	border-radius: 10px;
}

.infobox-wrapper :hover{
	cursor: pointer;
}

.seeker-infobox-wrapper {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	width: 100%;
	background-color: #FFFFFF;
	opacity: 0.85;
	padding: 12px;
	color: green;
	border: solid 1px #FFFFFF;
	border-radius: 10px;
}

.seeker-infobox-wrapper :hover{
	cursor: pointer;
}

.inner-info {
	width: 100%;
	font-size: 14px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	text-transform: capitalize;
}

.inner-info :first-child{
	font-size: 15px;
	font-weight: 500;
	width: 100%;
	line-height: 20px;
}

.inner-info :nth-child(2){
	margin-top: 10px;
	text-decoration: underline;
	float: right;
	margin-left: 100%;
	margin-right: 35%;
	font-size: 12;
	font-weight: 500;
	width: 70px;
	line-height: 20px;
}

.seeker-info-label {
	width: 100px !important;
}

.activity-row {
	text-transform: capitalize;
	height: auto !important;
}

.activity-header-row {
	height: 30px !important;
}

.comment-td p {
	width: 100%;
	color: gray;
	font-size: 12px;
}

.comment-td label {
	font-size: 12px;
	/* font-style: italic; */
	color: black;
	font-weight: 600;
}

.comment-td span label {
	font-size: 12px;
	font-style: italic;
	/* font-style: normal; */
	color: black;
	font-weight: 300;
	margin-left: 5px;
}
/* .activity-row :nth-child(1){
	width: 19%;
	color: grey;
}

.activity-row :nth-child(2){
	width: 15%;
	font-weight: 900;
	color: grey;
}

.activity-row :nth-child(3){
	width: 20%;
	color: grey;
}

.activity-row :nth-child(4){
	width: 20%;
	color: grey;
}

.activity-row :nth-child(5){
	width: 30%;
	font-weight: 900;
	color: #000000;
} */

.cc-color-override--314168688 .cc-btn {
	color: #FFFFFF !important;
	border-top-color: rgb(10, 4, 59) !important;
	background-color: rgb(10, 4, 59) !important;
}

.city-name-resume {
	text-transform: capitalize;
	font-size: 12px;
}

.contact-name-resume {
	text-transform: capitalize;
}

.job-title-job-details {
	text-transform: capitalize;
}

.text-danger-skill {
	color : red;
}

.submit-resume-select {
	text-transform: capitalize;
}

.job-post-defaultvalue {
	text-transform: capitalize;
}

.text-uppercase {
	text-transform: uppercase;
}

.text-danger {
	color: #d2691e;
}

@media (max-width: 768px) {
	.rd-navbar-classic.rd-navbar-fixed .rd-navbar-aside .button-icon{
		font-size: 12px;
	}
}

i {
	font-style: normal;
}

.no-data-map{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 70vh;
	font-size: 16px;
	font-weight: 500;
	color: darkgray;
}

input::-webkit-input-placeholder{
	text-transform: none !important;
}

input::placeholder{
	text-transform: none !important;
}

.passwordTick {
    position:absolute;
	margin-top: 7.5%;
	margin-left: 52%;
	color: green;
}

.passwordCrossTick {
    position:absolute;
	margin-top: 7.5%;
	margin-left: 52%;
	color: red;
}

.menu-item::before {
	content: "";
	position: absolute;
	top: 14px;
	left: 0;
	display: inline-block;
	line-height: 0;
	font-size: 17px;
	color: black;
}

.menu-item {
	margin-right: 10px;
}

@media(max-width: 768px){
	.menu-item::before {
		position: relative;
		top: 3px;
		font-size: 16px;
	}

	.rd-navbar-fixed .rd-navbar-submenu .rd-navbar-dropdown li li>a, .rd-navbar-fixed .rd-navbar-submenu .rd-navbar-megamenu ul li li>a {
		padding-left: 30px;
	}
}

input {
	min-height: 30px;
	border-color: rgb(150, 145, 171);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
}

.label-years-in {
    position:relative;
	margin-top: 1vw;
	margin-left: 10%;
	/* color: green; */
}

.home-textbox {
	min-height: 60px !important;
}

.home-placeholder {
	top: 18px;
	margin-left: 10px;
}

.login-placeholder input::-webkit-input-placeholder {
	margin-left: 10px;
}

.login-placeholder input::placeholder {
	margin-left: 10px;
}

.search-keyword-icon {
	top: 18px !important;
}
.edu-qua-vertical{
	display: flex;
	flex-wrap: wrap;
}

.edu-qua-paragraph{
	margin-left: 11vw;
	margin-top: 3px;
	margin-bottom: 3px;
}

.req-star {
	color: red;
	margin-left: 2px;
}

.card-Wrapper {
	padding: 10px 20px;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: row;
	flex-wrap: wrap;
}

.card-outer {
	background-color: #FFFFFF;
	min-height: 115px;
	max-height: 130px;
	box-shadow: 0px 20px 60px rgba(241, 244, 248, 0.5);
	border-radius: 16px;
  	transition: 0.3s;
}

.card-outer:hover {
	box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

@media screen and (max-width: 768px){
	.card-outer {
		max-height: 190px;
	}
}

.card-item-view {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: row !important;
	padding: 10px 10px;
	width: 40%;
	margin: 10px 10px;
	padding-bottom: 0px;
}

@media(max-width: 768px){
	.card-item-view {
		width: 100%;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
	}
}

.card-item-left {
	flex: 90% 1;
	margin-top: -5px;
	overflow: hidden;
}

.card-item-right {
	flex: 10% 1;
}

.card-title {
	font-size: 14px;
	font-weight: 700;
	/* text-transform: capitalize; */
	color: #000000;
}

.card-sub-item {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	padding-right: 10px;
}

.card-v-line {
	height: 15px;
	width: 1px;
	border: 0px solid lightgray;
	margin: 0px 10px;
	vertical-align: middle;
	margin-top: 2px;
	background-color: lightgray;
}

.card-sub-title {
	color: grey;
	font-size: 12px;
	font-weight: 600;
}

.card-desc {
	font-style: italic;
	font-size: 12px;
	color: grey;
	font-weight: 600;
	width: 327px;
	/* height: 100px; */
	overflow: hidden;
	text-overflow: ellipsis;
	max-height: 100px;
	white-space: nowrap;
	/* width: 100%; */
}

.card-icon-view {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex-direction: row;
	padding-top: 10px;
}

@media(max-width: 768px){
	.card-icon-view {
		margin-top: 7px;
		justify-content: center;
	}

	.card-icon-view-profile {
		margin-top: -10px;
		padding-top: 0px !important;
	}
}
@media(max-width:370px){
	.card-icon-view-profile{
		margin-top:-5px;
	}
}

.default-profile {
	font-size: 80px !important;
	color: #0A043B;
}

.react-datepicker__day--outside-month {
	background: none;
	color: transparent !important;
}

.upload-resume-btn-view {
	width: 240px;
}

@media (max-width: 768px){
	.upload-resume-btn-view{
		margin: 0px !important;
	}
}

.summary-input {
	min-height: 45px !important;
	height: auto !important;
	max-height: 90px !important;
	overflow-y: auto;
	resize: vertical !important;
}

.profile-pic-div {
	width: 50%;
}

.profile-pic-content-div {
	justify-content: space-between;
}

@media (max-width: 768px){
	.resume-div {
		justify-content: center;
	}

	.profile-pic-content-div {
		/* align-self: center;
		align-items: center; */
		justify-content: center;
	}
}

.resp-section-md {
	padding-top: 30px;
}

@media(max-width: 768px){
	.resp-section-md {
		padding-top: 0px;
	}
}

.sticky-pos {
	overflow-y: hidden;
}

.submit-btn {
	position: fixed;
	right: 115px;
	bottom: 30px;
	z-index: 100;
	box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.18);
	overflow: hidden;
	text-decoration: none;
}

.job-submit-btn {
	bottom: 45px;
}
.submit-btn-overlap {
	position: absolute;
	right: 0;
	bottom: 0;
	z-index: 100;
	box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.18);
	overflow: hidden;
	text-decoration: none;
}

.job-submit-btn-overlap {
	position: absolute;
	right: 6vw;
	bottom: 22vw !important;	
	z-index: 100;
	box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.18);
	overflow: hidden;
	text-decoration: none;
}

@media (max-width: 786px){
	.job-submit-btn-overlap{
		/* bottom: 88vw !important; */
		bottom: 330px !important;
	}
}

.job-provider-submit-btn-overlap {
	position: absolute;
	right: 8vw;
	bottom: 0;	
	z-index: 100;
	box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.18);
	overflow: hidden;
	text-decoration: none;
}

@media(max-width: 768px){
	.submit-btn {
		right: 25vw;
		bottom: 5px;
	}
	
	.submit-btn-overlap {
		right: 5%;
		bottom: 0px;
	}

	/* .job-submit-btn-overlap {
		right: 4vw;
		bottom: 12.5%;	
	} */

	.job-provider-submit-btn-overlap {
		right: 10%;
		bottom: 0.3%;	
	}
}

@media(min-width: 800px ) and (max-width: 1112px){
	.submit-btn {
		right: 115px;
		bottom: 30px;
	}

	.submit-btn-overlap {
		right: 115px;
		bottom: 30px;
	}

	.job-submit-btn-overlap {
		right: 6vw;
		bottom: 16vw;	
	}
}

.work-here-div {
	position: relative;
	top: -35px;
	height: 40px;
	display: inline-block !important;
	align-items: center;
	vertical-align: middle;
	padding: 10px;
}

/* Customize the label (the container) */
.work-here-div .container {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 16px;
	-webkit-user-select: none;
	user-select: none;
}
  
/* Hide the browser's default checkbox */
.work-here-div .container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}
  
/* Create a custom checkbox */
.work-here-div .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
	background-color: #ffffff;
}
  
/* On mouse-over, add a grey background color */
.work-here-div .container:hover input ~ .checkmark {
	background-color: #ccc;
}
  
/* When the checkbox is checked, add a blue background */
.work-here-div .container input:checked ~ .checkmark {
	background-color: rgb(10, 4, 59);
}
  
/* Create the checkmark/indicator (hidden when not checked) */
.work-here-div .checkmark:after {
	content: "";
	position: absolute;
	display: none;
}
  
/* Show the checkmark when checked */
.work-here-div .container input:checked ~ .checkmark:after {
	display: block;
}
  
/* Style the checkmark/indicator */
.work-here-div .container .checkmark:after {
	left: 8px;
	top: 0px;
	width: 10px;
	height: 20px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

.verify-phone-outer-wrapper{
	height: 100vh;
}

.padding-box{
	padding-bottom: 20px;
	padding-top: 10px;
}

.height-wid-box{
	width: 310px;
	max-height: 260px;
}

.img-resolution{
  width: 170px; 
  height:170px;
}

.height-row{
	padding:30px;
	width: calc(100vw - 31%);
	text-align: center;
	display: flex;
  	justify-content: space-between;
	margin-top: 0%;
}

@media (min-width: 275px) and (max-width: 360px) {
	.height-wid-box {
		width: 130px;
		max-height: 180px;
	}

	.img-resolution{
		width: 65px; 
		height: 65px;
  	}
}

@media (min-width: 360px) and (max-width: 375px) {
	.height-wid-box {
		width: 140px;
		max-height: 190px;
	}

	.img-resolution{
		width: 80px; 
		height: 80px;
  	}
}

@media (min-width: 376px) and (max-width: 768px) {
	.height-wid-box {
		width: 170px;
		max-height: 200px;
	}

	.img-resolution{
		width: 100px; 
		height: 100px;
  	}
}

@media (min-width: 769px) and (max-width: 1023px) {
	.height-wid-box {
		width: 160px;
		max-height: 160px;
	}

	.img-resolution{
		width: 55px;
		height: 55px;
  	}
}

@media(min-width: 1024px) and (max-width: 1280px){
	.height-wid-box {
		width: 160px;
		max-height: 160px;
	}

	.img-resolution{
		width: 60px;
		height: 60px;
  	}
}

@media (min-width: 1280px) {
	.height-wid-box {
		width: 240px;
		max-height: 284px;
	}

	.img-resolution{
		width: 210px;
		height: 210px;
  	}

	.height-row{
		width: 1150px;
	}
}

.div-center {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	flex-wrap: nowrap;
}



.header-login-view {
	display: flex;
	/* justify-content: space-between; */
	/* align-items: center; */
	flex-wrap: wrap;
	justify-content: flex-start;
	width:100%;
	height: auto ;
}

.login-outer-div {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30%;
	background-color: #f3f4f9;
	margin-top: 22px;
	height: auto;
}

@media (max-width: 321px) {
	.login-outer-div {
		width: 280px;
		padding: 0px 0px;
		margin-left: 10px;
		margin-right: 20px;
	}
}

@media (max-width: 768px) {
	.height-row{
		padding:0px;
		padding-left: 3%;
		padding-right: 5%;
		width: calc(100vw - 0%);
	}

	.login-outer-div {
		width: 350px;
		padding: 0px 0px;
	}
}

@media (max-width: 281px) {
	.height-row {
		padding: 0px;
		padding-left: 3%;
		padding-right: 12%;
		width: calc(113vw - 0%);
	}
}
/* @media (min-width: 769px) {
	.height-row{
		padding:0px;
		padding-left: 3%;
		padding-right: 5%;
		width: calc(100vw - 10%);
	}

	.login-outer-div {
		width: 350px;
		padding: 0px 0px;
	}
} */

.login-inner-div {
	padding: 30px 30px;
}

.login-inner-div label {
	justify-content: flex-start;
}

.login-inner-div input {
	min-height: 40px;
	height: 40px;
}

.login-btn-round {
	border-radius: 25px;
}

.privacy-p {
	font-size: 14px;
}

.breadcrumbs-custom-title {
	text-transform: capitalize !important;
	font-size: 28px !important;
}
.terms-custom-title{
	font-size: 28px !important;
}
.home-profile-pic{
	width: auto;
	height: 100px;
	/* border-radius: 50%; */
}

.candidate-home-profile-div {
	/* justify-content: space-between; */
}

.candidate-profile-home{
	flex: 0% 1;
}

.candidate-info-home{
	flex: 35% 1;
}

.candidate-btn-home{
	flex: 30% 1;
}

.resume-job-title {
	display: flex;
	justify-content: center;
	align-items: center;
}

.resume-edit-btn{
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 20px;
	/* margin-top: 15px; */
}

.candidate-basic-info-div{
	height: 90px;
	padding: 10px 10px;
}

.candidate-basic-info{
	text-transform: capitalize;
	font-size: 16px;
	color: #000000;
	font-weight: 600;
	line-height: 1.2;
}

.candidate-home-serarch-div{
	padding: 20px 130px;
}

.signup-link{
	background-color: transparent !important;
	color: white  !important;
	padding: 0px 0px  !important;
	font-weight: 500 !important;
	text-decoration: underline;
}

.center-div {
	display: flex;
	justify-content: center;
	align-items: center;
}

.resume-profile-info{
	box-shadow: 0px 20px 60px rgba(241, 244, 248, 0.5);
  	transition: 0.3s;
	padding: 20px;
	border-radius: 16px;
	background-color: #FFFFFF;
}

@media screen and (max-width: 768px){
	.resume-profile-info{
		padding: 10px 20px;
	}
}
@media screen and (max-width: 500px){
	.resume-profile-info{
		/* padding: 10px 20px; */
		width: 90vw ;
	}
}

.edit-profile-card {
	width: 30%;
}

@media (max-width: 768px){
	*+.profile-light-list{
		margin: 10px 20px;
	}

	.profile-container{
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.edit-profile-card{
		width: 50%;
	}

	.cand-home-prof-icon-view{
		margin-top: 50px !important;
	}
}

@media screen and (max-width: 415px) {
	.edit-profile-card{
		width: 100%;
	}
}

@media screen and (min-width: 769px) and (max-width: 1112px) {
	.edit-profile-card{
		width: 60%;
	}
}

.trouble-color{
  color: #0a043b;
}

.trouble-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.trouble-outer-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  flex-wrap: wrap;
}

@media(max-width:768px){
  .trouble-outer-div{
    justify-content: center;
  }
}

.trouble-inner-div{
  width: 500px;
  align-content: center;
}

.card-desc-text {
	max-height: 100px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
}

.section-sm, .section-md, .section-lg, .section-xl {
	padding: 30px 0px;
}

.show-more-less-text{
	font-size: 16px;
	color: #0A043B;
	text-decoration: underline;
	font-style: italic;
	font-weight: 600;
	margin-left: 10px;
	cursor: pointer;
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
}

.provider-reg-form-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
}
.provider-reg-form{
	width: calc(100vw - 65%);
}

@media(max-width: 768px){
	.provider-reg-form{
		width: calc(100vw - 35%);
	}
}

.editor-wrapper{
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	text-align:justify !important;
	font-size: inherit !important;
	color: #9a9a9a !important;
}

.editor-wrapper h1,
.editor-wrapper h2,
.editor-wrapper h3,
.editor-wrapper h4,
.editor-wrapper h5,
.editor-wrapper h6,
.editor-wrapper div,
.editor-wrapper span, 
.editor-wrapper p,
.editor-wrapper li,
.editor-wrapper ul,
.editor-wrapper ol,
.editor-wrapper strong,
.editor-wrapper b,
.editor-wrapper i {
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	text-align:justify !important;
	font-size: inherit !important;
	color: #9a9a9a !important;
}

.editor-wrapper span{
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	text-align:justify !important;
	font-size: inherit !important;
	color: #9a9a9a !important;
}

.editor-wrapper ul, ol{
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	font-size: inherit !important;
}
.editor-wrapper .tox{
	width: 100%;
	border: none;
	outline: 0;
	margin-bottom: 10px;
	overflow-y: hidden !important;
}

.editor-wrapper iframe {
    overflow:hidden !important;
}

.editor-wrapper ul, ol {
	list-style: disc !important;
	padding: 0;
	margin: 20px;
}
.editor-wrapper ul li, ol li {
	display: list-item;
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	text-align:justify !important;
	font-size: inherit !important;
}

.editor-wrapper .tinymce-content {
	white-space: normal !important; 
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	text-align:justify !important;
	font-size: inherit !important;
}
/* .popular-recruiter {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	padding: 0px 155px;
	margin: 0px 150px;
}
  
.popular-recruiter-item {
	display: flex;
	flex: 1 0 21%;
	margin: 5px;
	margin-bottom: 10px;
	height: auto;
	background-color: blue;
 	border: 1px black solid;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}

@media(max-width: 768px){
	.popular-recruiter {
		padding: 0px 0px;
		margin: 0px 0px;
	}
	.popular-recruiter-item {
		flex: 0 0 50%;
		min-width: 50%;
	}
} */

.skill-vertical{
	margin-bottom: 0px !important;
}

.fa-share-alt{
	color: #0A043B;
	cursor: pointer;
}

.fa-spinner {
	color: #0A043B;
}

.div-share{
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.remove-text-trans{
	text-transform: none !important;
}

.label-radius {
	border-radius: 4px;
}

.width-shortlist{
	width: 167px;
}

.letter-space-status{
	letter-spacing: .1em;
}

::-webkit-input-placeholder {
	font-style: italic;
}
:-moz-placeholder {
	font-style: italic;  
}
::-moz-placeholder {
	font-style: italic;  
}
:-ms-input-placeholder {  
	font-style: italic; 
}

@media screen and (max-width: 768px){
	.section-md{
		padding-top:none;
	}

	.row-50>*{
		margin-bottom: 20px;
	}

	.resume-button-view {
		margin: 0px 0px 55px 10px !important
	}

	.row-narrow {
		margin-right: 37px;
		margin-bottom: 10px;
	}

	.rd-navbar-fixed .rd-navbar-brand img {
		width: 50px;
	}

	.popular-recruiter-section {
		margin-top: 50px;
	}

	.parallax-image {
		background-repeat: no-repeat;
		background-position: right 26% top;
	}
}

.search-filter-btn {
	padding: 20px 0px;
	margin-top: 5px;
	max-height: 60px !important;
}

.search-filter-btn button {
	width: 70% !important;
	height: 60px !important;
}

.share-icons-styles{
	margin-left: -3% !important;
}

@media screen and (max-width: 768px){
	.share-icons-styles{
		margin-left: 0% !important;
	}
}

.candidateStatusHoverSelect {
	cursor: pointer;
	background: #279f8d;
	color: white;
	border-color: #279f8d;
}

@media screen and (max-width: 768px){
	.candidateStatusHoverSelect {
		margin-left: 14px !important;
	}

	.remove-width-mob {
		margin-left: 14px !important;
	}
}

.candidateStatusHoverReject{
	cursor: pointer;
	background:  #b4002a;
	color: white;
}

.applications-buttons {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.shortlisted-class {
	background : #0A043B !important;
}

.loadingCSS {
	height: 100px;
	/* width: 100%; */
	margin: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.background-company {
	background-color: rgb(221, 229, 237);	
}

.defailt-profile-div {
	margin-top: 0px;
}

.margin-companies-box {
	margin:auto;
}

/* .width-team {
	width:250px;
   	max-width:250px;
   	display: inline-block;
} */

.width-team {
	width: 268px;
   	height: 340px;
   	display: inline-block;
	box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
	border-radius: 24px;
}

.advisory-top {
	margin-top : -4%;
}

.link-class{
	text-decoration: underline;
	/* color:black; */
}

.link-class:hover{
	text-decoration: underline;
	color:forestgreen;
}

.view-password {
    position:absolute;
	margin-top: 7.5%;
	margin-left: 45%;
	color: #0A043B;
	cursor: pointer;	
}

.button-shortlisted {
	background: rgb(39, 159, 141);
	color: white;
	border-color: rgb(39, 159, 141);
}

.react-date-picker{
	min-height: 44px;
	/* width: 10vw; */
}

.react-date-picker__inputGroup {
	margin-left: 2vw;
	color: #000000;
}

.date-picker-div .react-calendar{
	z-index: 10px !important;
}

.react-calendar__tile--now {
	background-color: #0A043B !important;
	color: #FFFFFF;
}

.react-calendar__tile--hasActive {
	background-color: #0A043B !important;
	color: #FFFFFF;
}

/* .react-calendar__tile--active, .react-calendar__tile--active:enabled:focus {
	background-color: #0A043B !important;
	color: #FFFFFF;
} */

.react-calendar__month-view__days__day--weekend {
	color: #000000 !important;
}

.edit-file-upload {
	height: 28px !important;
	width: 27px !important;
	color: #ffffff;
	margin-bottom: 10px;
	cursor: pointer;
	
}

.icon-edit-photo {
	font-size: 16px;
	vertical-align: middle !important;
	/* margin-bottom: 0px !important; */
}

.profile-incomplete-overlay {
	position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
}

.profile-incomplete-modal {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
    position: absolute !important;
	flex-direction: column;
	width: 30vw;
	left: 35%;
	/* height: 30%; */
    top: 45%;
    
    right: auto;
    bottom: auto; 
    background-color: white;
	opacity: 1;
	box-shadow: 1px 4px 29px -4px rgba(18,17,17,0.82);
	-webkit-box-shadow: 1px 4px 29px -4px rgba(18,17,17,0.82);
	-moz-box-shadow: 1px 4px 29px -4px rgba(18,17,17,0.82);
}

@media (max-width: 768px){
	.profile-incomplete-modal {
		left: 3vw;
		width: 90vw;
	}
}

.incomplete-profile-container{
	background-color: white;
	opacity: 1;
}
  
.modal-dialog {
	margin: 0;
}

.edit-file-upload-empty-profile {
	border-radius: 4px;
	width: auto !important;
}

.card-icon-view-profile .button {
	overflow: inherit !important;
	line-height: 1;
}

.datepicker-icon {
	z-index: 1px !important;
}

input[type=checkbox] {
    -webkit-appearance: none;
    appearance: none;
}

.provider-profile-image-container{
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}

.provider-profile-info-text {
	font-size: 14px;
}

.shl-loader-div {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 60vh;
}

.csv-uploader textarea {
	height: 60px;
	overflow-y: auto;
}
/* Changes */
.top_margin{
	margin-top: -20px;
}
.bottom_margin{
	margin-bottom: 8px;
}

.header-bottom {
	margin-bottom: 50px;
}

@media screen and (max-width: 768px){
	.header-bottom {
		margin-bottom: 0px;
	}

	.view-password {
		position:absolute;
		margin-top: 14.5%;
		margin-left: 32vw;
	}
}
.forgotpassword-resendcode-button{
	margin-left: 220px;
	margin-top: 0px;
}

.text-justify{
	text-align: justify !important;
}

.cover-div {
	background-image: url('/img/blue-1634110.png');
	/* Full-screen */
	height: 100vh;
	/* Center the background image */
	background-position: center;
	/* Scale and zoom in the image */
	background-size: cover;
	/* Add position: relative to enable absolutely positioned elements inside the image (place text) */
	position: relative;
	/* Add a white text color to all elements inside the .bgimg container */
	color: white;
	/* Set the font-size to 25 pixels */
	font-size: 18px;
	overflow-y: auto;
}

.home-desc-text{
	font-size: 20px;
	width: 550px;
}

.cover-div h1 {
	/* Add a white text color to all elements inside the .bgimg container */
	color: white;
	font-weight: 700;
	letter-spacing: 3px;
	/* Add a font */
	/* font-family: "Courier New", Courier, monospace; */
	/* Set the font-size to 25 pixels */
	font-size: 55px;
}
  /* Position text in the middle */
.middle {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	text-align: center;
}

.input{
	font-size: 14px;
	font-weight: bold;
	float: left;
	border: 0;
border-radius: 3px;
	padding: 0 10px;
	/* height: 35px; */
}

.contact-label{
	font-weight: 500;
}

@media(max-width: 768px){
	.home-desc-text{
		width: 250px !important;
	}

	.cover-div h1 {
		letter-spacing: 2px;
		font-size: 35px;
	}

	.contact-label{
		font-size: 14px;
		width: 300px !important;
	}
	
}


.tinyMCEstyle {
	text-align:justify !important;
	font-size: 14px !important;
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
}

.tinyMCEstyle p {
	text-align:justify !important;
    font-size: 14px !important;
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	line-height: 1.71429 !important;
	background: transparent !important;
	color: #000000 !important;
	letter-spacing: normal !important;
}

.tinyMCEstyle span {
	text-align:justify !important;
    font-size: 14px !important;
    font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	background: transparent !important;
	line-height: 1.71429 !important;
	color: #000000 !important;
	letter-spacing: normal !important;
}

.skill-wrapper {
	text-align: center;
	min-width: 170px;
	/* padding: 10px; */
}

.skill-title-div {
	padding: 0px 20px 20px 20px;
}

.skill-title{
	font-size: 12px;
	color: #000000;
	font-weight: 500;
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	line-height: 1.2;
}

.skill-info-container {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px 20px;
}

.marks-get {
	font-size: 16px;
	font-weight: 600;
	color: #000000;
}

.skill-chart-div {
	display: flex;
	align-items: center;
	justify-content: center;
}

.out-of-marks {
	margin-left: 2px;
	font-size: 10px;
}

.skill-chart{
	height: 40px;
	width: 40px;
}

.skill-component-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: row;
	/* flex-wrap: wrap; */
	background-color: #F5F5F5;
	padding: 10px 10px 15px 10px;
	border: 1px solid lightgray;
	margin-left: 15px;
}

.skill-veritcal-line {
	width: 1px;
	height: 95%;
	vertical-align: middle;
	background-color: lightgray;
	margin: 0px 10px;
}

.resp-skill-horizontal-line {
	width: 95%;
	height: 1px;
	vertical-align: middle;
	background-color: lightgray;
	margin: 15px 10px 10px 10px;
	display: none;
}

@media(max-width: 768px){
	.skill-component-container {
		width: calc(100vw - 2.5rem);
		justify-content: center;
	}

	.skill-veritcal-line{
		display: none;
	}

	.skill-wrapper {
		min-width: 250px;
		padding: 15px;
	}

	.resp-skill-horizontal-line {
		display: block;
	}
}
.skill-horizontal-line {
	width: 95%;
	height: 1px;
	vertical-align: middle;
	background-color: lightgray;
	margin: 0px 10px 10px 10px;
}

.skill-info-wrapper{
	border: 1px solid lightgray;
	margin-left: 15px;
	width: 85%;
}

@media(max-width: 768px){
	.skill-info-wrapper{
		width: calc(100vw - 2.5rem);
	}
}

.title-container {
	background-color: #F5F5F5;
	width: 100%;
	height: 35px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 10px;
}

.main-skill-info-view {
	display: flex;
	padding: 10px;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
}

.info-marks-get {
	font-size: 10px;
	font-weight: 500;
	color: #000000;
	margin-left: 10px;
	font-family: "Rubik" !important;
}

.info-out-of-marks {
	margin-left: 2px;
	font-size: 10px;
}

.info-header-skill-bar-view {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 0px 10px;
}

.sub-skill-name {
	font-size: 10px;
	font-weight: 500;
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
}

.skill-info-with-slider-view {
	display: flex;
	align-items: baseline;
	margin-right: 10px;
}

.rangeslider {
	margin: 10px 0px !important;
}

.rangeslider-horizontal {
	width: 130px;
}

@media(max-width: 768px){
	.rangeslider-horizontal {
		width: 200px
	}
}

.rangeslider-horizontal .rangeslider__fill {
	background-color: #0A043B !important;
}

.rangeslider-horizontal {
	height: 5px !important;
}

.rangeslider-horizontal .rangeslider__handle:after {
	width: 10px !important;
	height: 10px !important;
	top: -1px !important;
	left: -1px !important;
}

.rangeslider-horizontal .rangeslider__handle {
	width: 10px !important;
	height: 10px !important;
}

.sub_module_name_div {
	padding-left: 15px;
}

.sub_module_name {
	font-size: 11px;
	font-weight: 600;
	color: #000000;
}

.rangeslider__labels .rangeslider__label-item {
	font-size: 8px !important;
	/* color: #000000 !important; */
	top: 5px !important;
}

.personality-slider-margin {
	margin-left: 20px;
	margin-right: 40px;
	margin-bottom: 20px;
}

.sub-skill-margin {
	margin-left: 20px;
}

.personality_icon_view {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.personality_icon_view label {
	font-size: 14px;
	color: #000000;
}

.personality_complete_icon {
	font-size: 35px !important;
	color: #0A043B;
	margin-right: 10px;
}

.assesment_info_chevron {
	 position: relative;
	 font-size: 60px !important;
	 color: #F5F5F5;
	/*top: 19vh;
	left: 20px;
	font-size: 60px !important;
	
	z-index: -1; */
}

.personality-slider-margin .rangeslider-horizontal .rangeslider__fill{
	background: transparent !important;
}
.personality-slider-margin .rangeslider{
	background: linear-gradient(to right, #6495ED, #0A043B) !important;
}

.arrow-up {
	width: 0; 
	height: 0; 
	border-left: 25px solid transparent;
	border-right: 25px solid transparent;
	border-bottom: 25px solid #F5F5F5;
	position: relative;
	top: 30px;
}
.display-inline{
	display:inline !important;
}
.display-inline-block{
	display:inline-block !important;
}
@media(max-width: 320px){
	.react-date-picker__wrapper{
		padding-left: 20px !important;
	}
	.education-date-responsive{
		width: 400px !important;
	}
}
@media screen and (max-width: 1100px), screen and(min-width:720px){
	.react-date-picker__wrapper{
		padding-left:13px;
	}
}
.react-date-picker__wrapper{
	border: 1px solid #9691ab !important;
	border-radius: 4px;
}
@media (max-width: 786px){
	.briefcase-responsive{
		margin-left:-19px ;
	}
}

@media screen and (max-width: 1100px), screen and(min-width:720px){
	.react-date-picker__wrapper{
		padding-left:13px;
	}
}
.react-date-picker__wrapper{
	border: 1px solid #9691ab !important;
	border-radius: 4px;
}

@media (max-width: 320px){
	.react-date-picker__wrapper{
		padding-left:22px;
	}
	.education-date-responsive{
		min-width: 400px;
	}
	.to-date-resposive .react-date-picker__inputGroup{
		margin-left: 15px !important;
		margin-right: -20px !important;
	}
	.work-here-responsive{
		margin-left: 6px !important;
		margin-top: 20px !important;
	}
}
@media screen and (max-width:375px), screen and (min-width:321px){
	.education-date-responsive{
		min-width: 400px;
	}
	.to-date-resposive .react-date-picker__inputGroup{
		margin-left: 15px !important;
		margin-right: -20px !important;
	}
	.work-here-responsive{
		margin-top: 100px !important;
		margin-left: -68px !important;
	}
}
@media screen and (max-width:699px),screen and(min-width:530px){
	 .education-date-responsive{
		width: 600px;
	}
	.work-here-responsive{
		margin-left:-75px;
	}
}
@media screen and(max-width:786px), screen and (min-width:700px){
	.too-date-responsive{
		margin-left: -220px !important;
	}
	.work-here-responsive{
		margin-top: 0px !important;
		margin-left: -68px !important;
	}
	.date-of-birth-responsive .react-date-picker__wrapper{
		padding-left:10px !important;
	}
} 
@media screen and(max-width:1200px), screen and (min-width:790px){
	.to-date-resposive .react-date-picker__inputGroup{
		margin-left: 36px !important;
		margin-right: -20px !important;
	}
}
@media(min-width:1210px){
	.date-of-birth-responsive .react-date-picker__wrapper{
		padding-left:4px !important;
	}
	.work-here-responsive{
		margin-left:-45px !important;
	}
}
.jumbotron-title-div{
	width: 568px;
    /* height: 173px; */
    margin-top: 50px;
    /* margin-left: -122px; */
    padding-right: 60px;
    text-align: left;
}
.font-rubik{
	font-family: 'rubiklight', Arial, sans-se rif;
}
.jumbotron-title-creative{
	font-family: 'Rubik', Arial, sans-se rif;
	font-style: normal;
	font-weight: bold !important;
	font-size: 44px !important;
	line-height: 126%;
	-webkit-font-feature-settings: 'pnum' on, 'lnum' on;
	        font-feature-settings: 'pnum' on, 'lnum' on;
	color: #FFFFFF;
}
.jumbotron-para{
	width:375px;
	height:50px;
	color: #FFFFFF;
	font-size: 15px;
	font-family: 'rubiklight', Arial, sans-se rif;
	line-height: 138%;
	font-weight: 550;
	letter-spacing: 0.09em;
	margin-left:4px;
}
.login-btn{
	 position:absolute; 
	background-color:#080838;
	margin-top: 88px;
    margin-left: -344px;
	width: 82px;
	height: 40px;
	padding:6px;
	border:1px solid #080838;
	border-radius:4px; 
	color:#FFFFFF;
}
.login-btn:hover, .btn-position:hover, .search-hover{
	color:#FFFFFF !important;
}
.btn-position{
	background-color: #B8B8B9;
	width:82px;
	height:40px;
	border:1px solid #B8B8B9;
	border-radius: 4px;
	margin-left: -234px;
	margin-top: 88px;
	color:#FFFFFF;
	position:absolute;
	padding:6px;
}
.screenshot-div{
	margin-left:62px;
}
.mobile-app-screenshot{
	/* position: absolute; */
	width:226px;
	height:454px;
	border:1px solid #FFFFFF;
	border-radius:4px;
	-webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
	        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.dedicated-platform-div{
	margin-left: 250px;
    margin-top: 102px;
	width: 481px;
	height: 238px;
}
.dedicated-platform-heading{
	/* width: 481px;
	font-size: 45px;
	letter-spacing: 0.9px;
	height: 108px;
	font-weight:700;
	text-align:left;
	line-height: 126%;
	font-family: 'Rubik'; */
	font-size:44px;
	text-align:left;
	line-height: 126%;
	font-weight: 700;
	-webkit-font-feature-settings: 'pnum' on, 'lnum' on;
	        font-feature-settings: 'pnum' on, 'lnum' on;
	color: #000000;
	font-family: 'Rubik';
}
.dedicated-platform-para{
	/* color: #616161;
    font-size: 14px;
    text-align: left;
    margin-top: 20px;
    line-height: 1.4em;
    letter-spacing: 1.8px;
	font-weight: 400; */
	margin-left: 4px;
	margin-top: 10px;
	font-size: 14px;
	line-height: 138.5%;
	letter-spacing: 0.08em;
	font-style: normal;
	font-weight: 400;
	color: #616161;
	text-align: left;
	-webkit-font-feature-settings: 'pnum' on, 'lnum' on;
	        font-feature-settings: 'pnum' on, 'lnum' on;
	font-family: 'Rubik';
}
.search-job-btn{
	margin-top: 30px;
    background-color: #080838;
    width: 160px;
    height: 40px;
    padding: 6px;
    border: 1px solid #080838;
    border-radius: 4px;
	color:#FFFFFF;
}
.user-partner-joblisting-div{
	margin-top: 120px;
    margin-left: 257px;
	width: 654px;
	height: 117px;
	background: #FFFFFF;
	box-shadow: 0px 2px 24px rgba(88, 126, 236, 0.15);
	border-radius: 16px;
	padding: 26px 96px 27px 64px;
	-webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
	        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))
}
.total-user-count-div{
	width:491px;
	height:61px;
	margin-left: 11px;
	/* padding:10px; */
}
.bg-color-hp{
	/* width: 1152px; */
	height: 1000px;
	background-color: #FAFBFD;
}
.total-user-label{
	color: #080838;
	font-weight: bold;
    font-size: 28px;
}
.user-label-position{
	margin-left: 0px;
   margin-right:200px;
}
.partner-label-position{
	margin-left:-69px;
	margin-right:130px;
}
.font-small{
	margin-top:-10px;
	font-size:12px !important;
	font-weight:500;
	color:#000000 !important;
	font-family: 'Rubik';
}
.position-absolute{
	margin-left: 1px;
	letter-spacing: 2px;
}
.user-label-div{
    margin-top: 9px;
    margin-left: 0px;
    letter-spacing: 3px;
	font-family:'Rubik';
}
.partners-position{
	margin-left:-82px;
}
.skill-tool-div{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 240px;
	margin-top:100px;
	flex-wrap: wrap;
}

.skill-tool-div :nth-child(1) {
	width: 28vw;
}

.font-rubik-bold{
	font-family:'Rubik';
}
.text-black{
	color:black !important;
}
.skill-heading{
	font-size:44px;
	text-align:left;
	line-height: 126%;
	font-weight: 700;
	-webkit-font-feature-settings: 'pnum' on, 'lnum' on;
	        font-feature-settings: 'pnum' on, 'lnum' on;
	color: #000000;
}
.skill-para{
	margin-left: 4px;
	margin-top: 10px;
	font-size: 14px;
	line-height: 138.5%;
    letter-spacing: 0.08em;
	font-style: normal;
	font-weight: 400;
	color: #616161;
	text-align: left;
	-webkit-font-feature-settings: 'pnum' on, 'lnum' on;
	        font-feature-settings: 'pnum' on, 'lnum' on;
}
.start-btn{
	margin-top: 25px !important;
    width: 144px !important;
}
.girl-img-div{
	width:354px;
	height:317px;
	margin-left: 150px;
	max-height: 317px;
    /* margin-top: -260px; */
}

.girl-img-div img {
	max-width:354px;
	max-height: 317px;
}
@media(max-width: 768px) {
	.girl-img-div{
		margin-left: 0;
		margin-top:30px;
	}
}

.service-hospitality-div{
	display: flex;
	flex-wrap:wrap;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 100px;
	/* margin-left:64px; */
}

@media(max-width: 768px){
	.service-hospitality-div{
		margin-top: 100px;
		/* width: 100%; */
	}
}

.target-icon-wrapper{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap:wrap;
	margin-top: 50px;
	/* flex-direction: column; */
}

.service-hospitality-heading-div{
	width: 989px;
	height: 167px;
	text-align:center;
	font-size:44px;
	color: #000000
}

.service-hospitality-heading-div p {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 700;
	font-size: 44px;
	line-height: 126%;
	/* or 55px */
	text-align: center;
	-webkit-font-feature-settings: 'pnum' on, 'lnum' on;
	        font-feature-settings: 'pnum' on, 'lnum' on;
	color: #000000;
}

@media(max-width: 768px){
	.service-hospitality-heading-div{
		width: 90vw;
		height: auto;
		font-size: 25px;
	}

	.service-hospitality-heading-div p{
		font-size: 25px;
	}
}

.target-icon-div{
	margin-top:20px;
	width: 279px;
	height: 277px;
	box-shadow: 0px 4px 18px rgba(88, 126, 236, 0.18);
	border-radius: 8px;
	/* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) */
}
.top-brand-icon{
	margin-left:65px;
}
.target-img{
	margin-top:35px;
}
.target-para{
	font-size: 19px;
    letter-spacing: 1px;
}
.new-candidate-div{
	border: 1px solid black;
	height:430px;
	margin-top:260px !important;
}
.new-candidate-heading{
	margin-top:50px;
	margin-bottom:50px;
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 700;
	font-size: 44px;
	line-height: 50px;
	text-align: center;
	color: #0B132A;
}
.new-candidates-list-hp{
	border: 1px solid blue;
	border-radius:4px;
	width:300px;
	height:200px
}
.bg-white{
	background-color:white !important;
}
.box-new-candidate {
	position: relative;
	display: flex;
	padding: 25px 14px;
	height: 240px !important;
	width: 400px !important;
	text-align: center;
}
.our-recruiter-heading{
	margin-top: 20px;
	margin-bottom:50px;
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 700;
	font-size: 44px;
	line-height: 50px;
	text-align: center;
	color: #0B132A;
	letter-spacing: 0;
}

.assesment-request-box {
	background-color: #0A043B;
	padding: 10px 20px;
	margin-top: 25px;
	border-radius: 4px;
	text-align: center;
	color: #FFFFFF;
	font-weight: 300;
	font-size: 15px;
	width: 25vw;
}

.assesment-request-box span{
	font-weight: 900;
}

.assesment-request-box :nth-child(2){
	cursor: pointer;
}

@media (max-width: 786px){
	.assesment-request-box {
		width: 80vw;
	}

	.resume-edit-btn-responsive{
		margin-left:-100px ;
	}
	.briefcase-responsive{
		margin-left:-19px ;
	}
}
@media(max-width:1200px) ,screen and (min-width:1001px){
	.work-here-responsive{
		margin-left:59px !important;
	}
	.to-date-responsive{
		margin-left:100px !important;
	}
}
@media(max-width:1000px){
	.to-date-responsive{
		margin-left:100px !important;
	}
	.work-here-responsive{
		margin-left:150px !important;
	}
}
@media(min-width:1210px){
	.to-date-responsive{
		margin-left:100px !important;
	}
	.work-here-responsive{
		margin-left:150px !important;
	}
}
@media(max-width: 320px){
	.react-date-picker__wrapper{
		padding-left: 20px !important;
	}
	.react-date-picker__inputGroup{
		margin-left:10px;
		margin-right:-10px;
	}
	.education-date-responsive{
		width: 400px !important;
	}
	.work-here-responsive{
		margin-top:22px !important;
		margin-left:6px !important;
	}
	.to-date-responsive{
		margin-left:0px !important;
	}
}
@media(max-width:414px){
	.education-date-responsive{
		width: 400px !important;
	}
	.work-here-responsive{
		margin-top:130px !important;
		margin-left:-70px !important;
	}
	.to-date-responsive{
		margin-left:0px !important;
	}
}
@media(max-width:780px){
	.education-date-responsive{
		width: 800px !important;
	}
	.work-here-responsive{
		margin-left:150px;
	}
	.to-date-responsive{
		margin-left:0px !important;
	}
}
.display-inline{
	display:inline !important;
}
.provider-home-corousel-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.provider-home-corousel-wrapper .owl-item {
	width: 100% !important;
}

.provider-home-corousel {
	width: 300px !important;
	height: 300px !important;
	background-color: #FFFFFF;
	display: flex;
	justify-content: center;
	align-items: center;
	/* width: 1152px !important; */
}

@media (max-width: 500px){
	.provider-home-corousel{
		width: 250px !important;
		height: 250px !important;
	}
}
.temp-corousel {
	width: 100%;
}

.courousel-and-recruiter-warapper {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
} 

.home-skill-wrapper {
	display: flex;
	justify-content: center;
	flex-direction:column;
	align-items: center;
	width: 100%;
	margin-bottom: 100px;
}

.assesment-report-table {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 90%;
	padding-left: 10%;
}

.header-btn-wrapper {
	margin-top: 20px;
	margin-left: 8vw;
}

.fa-down-arrow{
	color:#FFFFFF;
	/* margin-left:200px; */
}

.down-arrrow-div{
    margin-top: 70px;
    margin-left: 565px;
}

.get-in-touch-btn{
	padding: 8px 15px;
    background-color: #080838;
    border-radius: 4px;
	line-height: 12px;
}

.text-white{
	color: #ffffff;
}

.touch-btn-font{
	font-size:12px;
}

.line-height-30{
	line-height:30px;
}

.footer-signin-btn{
	border:1px solid #080838;
	width:170px;
	height:32px;
	padding: 5px;
	background-color: #ffffff;
	border-radius:4px;
	color:#000000;
}

.footer-sign-btn-pos{
	margin-top:22px;
}

.footer-signin-btn-pos{
	margin-left: 183px;
    margin-top: -32px;
}

.nav-list-post{
	margin-top:8px;
	margin-bottom:10px;
}

.footer-search-btn{
	border:1px solid #ffffff;
	width:170px;
	height:32px;
	padding:5px;
	background-color: #0a043b;
	border-radius:4px;
}

.footer-search-btn-pos{
	margin-top:10px;
}
.footer-signup-btn-pos{
	margin-left: 184px;
    margin-top: -32px;
}
.padding-left-15px{
	padding-left:12px;
}

.footer-btn-div{
	margin-top:-6px;
}

.text-placeholder::-webkit-input-placeholder{
	font-style:none;
	font-family: 'rubiklight', Arial, sans-se rif;
}

.text-placeholder::placeholder{
	font-style:none;
	font-family: 'rubiklight', Arial, sans-se rif;
}
.browse-jobs-btn{
	position:absolute; 
   background-color:#080838;
   margin-top: 88px;
   margin-left: -125px;
   display:flex;
   /* width: 82px;
   height: 40px; */
   padding:6px;
   border:1px solid #080838;
   border-radius:4px; 
   color:#FFFFFF;
}

.jumbotron-creative .rd-navbar-static.rd-navbar-classic:not(.rd-navbar--is-stuck) .rd-nav-link, .jumbotron-creative .rd-navbar-static.rd-navbar-classic:not(.rd-navbar--is-stuck) .rd-navbar-submenu-toggle{
	color:#ffffff !important;
}

.footer-search-button{
	text-align:left;
	padding-left:14px;
	border:1px solid white;
	width:170px;
	height:32px;
	border-radius:4px;
	background-color:#0a043b;
}
/* Grey Button Start */
.button-grey,
.button-grey:focus {
	color: #ffffff;
	background-color: #B8B8B9;
	border-color: #B8B8B9;
}

.button-grey:hover,
.button-grey:active {
	color: #ffffff;
	/* background-color: #ca5217;
	border-color: #ca5217; */
	background-color: #ca5217;
	border-color: #ca5217;
}

.bg-grey .button-grey,
.bg-grey .button-grey:focus {
	color: #151515;
	background-color: #ffffff;
	border-color: #ffffff;
}

.bg-grey .button-grey:hover,
.bg-grey .button-grey:active {
	color: #ffffff;
	background-color: #ca5217;
	border-color: #ca5217;
}
/* Grey Button Ends */


.provider-home-corousel-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.provider-home-corousel-wrapper .owl-item {
	width: 100% !important;
}

.provider-home-corousel {
	width: 300px !important;
	height: 300px !important;
	background-color: #FFFFFF;
	display: flex;
	justify-content: center;
	align-items: center;
	/* width: 1152px !important; */
}

.temp-corousel {
	width: 100%;
}

/* .courousel-and-recruiter-warapper {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
} */

.home-skill-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-bottom: 100px;
}

.header-btn-wrapper {
	margin-top: 20px;
	margin-left: 8vw;
}

.csv-import-button {
	display: flex;
	justify-content: center;
	align-items: center;
}
.csv-import-button button:focus,.csv-import-button button:hover {
	background-color:rgb(39, 159, 141) !important;
	border-color:rgb(39, 159, 141) !important;
}

.import-skill-div {
	margin-left: 10.7% !important;
	border-collapse: collapse;
	margin-top: 4% !important;
}

.assesment-report-table {
	/* width: 76%;
	margin-left: 12%; */
	margin-right: 12%;
	margin-top: -3%;
}

@media only screen and (max-width: 768px) {
	.import-skill-div {
		margin-left: 0px !important;
		max-width:-webkit-fit-content;
		max-width:-moz-fit-content;
		max-width:fit-content;
	}

	.skill-import-table {
        width: 100%;
        margin-bottom: 15px;
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        border: 1px solid #DDD;
    }

	.assesment-report-table {
		overflow-x: auto;
		width: 96%;
		margin-left: 2%;
		margin-right: 2%;
	}
}

/* New Job List Changes Starting from Here */
.job-list-section {
	background-color: #FAFBFD;
}

.new-section-bg {
	background-color: #FAFBFD;
}

.search-Wrapper {
	background-color: #FFFFFF;
	/* height: 135px; */
	width: 100%;
}

@media(max-width: 768px){
	.search-Wrapper{
		display: none;
	}
}

.inner-search-wrapper{
	padding: 20px;
	padding-bottom: 30px;
}

.search-title {
	padding-top: 14px;
}

.search-title-label {
	font-size: 14px;
	color: #181818;
	/* font-weight: 600; */
	font-weight: 400;
	line-height: 14px;
	text-transform: uppercase;
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
}

.search-title-label-mobile {
	font-size: 14px;
	/* color: #181818; */
	font-weight: 600;
	line-height: 14px;
	text-transform: uppercase;
	background-color: transparent;
}

.search-hr {
	width: 98%;
	border: 1px solid #F4F4F4;
	/* margin-left: calc(50% - 100%/2); */
}

.search-content {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 30px 0px;
	/* height: 30px; */
}

.search-input-wrapper {
	width: 85%;
}

.search-input-wrapper input {
	width: 100%;
	height: 100%;
	padding: 10px 0px;
	border: none;
	font-size: 18px;
	line-height: 21px;
}

.search-input-wrapper input::-webkit-input-placeholder {
	font-size: 18px;
	color: rgba(24, 24, 24, 0.2);
	font-style: normal;
	font-weight: normal;
}

.search-input-wrapper input::placeholder {
	font-size: 18px;
	color: rgba(24, 24, 24, 0.2);
	font-style: normal;
	font-weight: normal;
}

.search-slider-icon {
	height: 30px;
	width: 30px;
	opacity: 0.35;
	font-size: 30px !important;
	-webkit-transform: rotate(270deg);
	        transform: rotate(270deg);
	margin-top: 10px;
}

.search-slider-icon-mobile {
	display: none !important;
	/* height: 30px;
	width: 30px;
	opacity: 0.35;
	font-size: 30px !important;
	transform: rotate(270deg);
	margin-top: 10px; */
}

.search-icon-div-mobile {
	display: none;
}

.active-search-slider-icon {
	color: #0A043B !important;
	opacity: 1;
}

.active-search-slider-icon-mobile {
	/* color: #0A043B !important; */
	/* opacity: 1; */
	display: none;
}

.search-btn {
	padding: 30px;
	font-size: 16px !important;
	height: 35px;
	width: 35px;
	margin-right: 20px;
}

@media(max-width: 321px){
	.search-Wrapper {
		margin-top: 20px;
	}

	.search-btn {
		margin-right: 0px;
	}

	.search-input-wrapper {
		width: 55%;
	}
}

.search-Wrapper-mobile {
	display: none;
}

/* .search-wrapper-mobile-view {
	display: none;
} */

.inner-search-wrapper-mobile {
	display: none;
}

.filter-div {
	display: none;
}

.more-button {
	display: none;
}

.more-button-desktop {
	display: block !important;
	font-size: 12px !important;
}


@media (min-width: 322px) and (max-width: 768px){

	.more-button-desktop {
		display: none !important;
	}
	
	.more-button {
		display: block !important;
		margin-bottom: 30px;
	}

	.search-Wrapper {
		margin-top: 20px;
	}

	.search-btn {
		margin-right: 0px;
	}

	.filter-div {
		display: inline;
	}

	.inner-search-wrapper-mobile {
		/* position:fixed; */
		/* left:0; */
		/* bottom:0; */
		/* right:0; */
		/* padding: 20px; */
		padding-bottom: 0px;
		background-color: #0A043B;
		border-radius: 8px;
		min-height: 50px;
		width: 65vw;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		/* flex: 1; */
		/* position: absolute; */
		/* display: inline; */
	}

	/* .keyword-box-mobile {
		border: solid 1px !important;
		margin-top: 5px;
		margin-bottom: 5px;
	} */
	.keyword-box-mobile {
		border: solid 1px !important;
		margin-top: 10px;
		margin-bottom: 10px;
		height: 42px;
		font-family: 'rubik';
		padding-left: 10px;
	}
	
	.list li > label {
		text-transform: capitalize;
		color: black !important;
	}
	
	.inner-search-wrapper-mobile input {
		background: transparent;
		width: 100%;
		color: #FFFFFF;
		border: none;
		/* margin-bottom: 10px; */
	}
	
	.inner-search-wrapper-mobile input::-webkit-input-placeholder{
		color: #FFFFFF;
		font-weight: 600;
		font-style: normal;
		/* margin-left: 10px !impor; */
	}
	
	.inner-search-wrapper-mobile input::placeholder{
		color: #FFFFFF;
		font-weight: 600;
		font-style: normal;
		/* margin-left: 10px !impor; */
	}

	.jobs-mobile-vertical-small {
		border: 0.5px solid white;
		height: 46px;
		/* width: 1px; */
		margin-left: 20px;
		/* margin-top: 4px; */
		background-color: white;
	}

	.search-input-wrapper {
		width: 65%;
		background-color: #0A043B;
	}

	.search-input-wrapper-mobile {
		/* width: 65%; */
		background-color: transparent;
	}

	.search-input-wrapper-mobile input {
		background-color: #0A043B;
		border: none;
		color: white;
	}

	.search-input-wrapper-mobile input::-webkit-input-placeholder {
		color: white;
	}

	.search-input-wrapper-mobile input::placeholder {
		color: white;
	}

	.search-wrapper-mobile-view {
		/* background-color: #FFFFFF; */
		width: 90vw;
		position: fixed;
		bottom: 20px;
		/* right: 0px; */
		/* left: 0px; */
		z-index: 99;
		margin: 0px 5px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		background-color: #0A043B;
		border-radius: 10px;
	}

	.search-wrapper-mobile-view-absolute {
		width: 90vw;
		position: relative;
		bottom: 0px;
		z-index: 99;
		/* margin: 0px 10px; */
		display: flex;
		justify-content: flex-start;
		align-items: center;
		background-color: #0A043B;
		border-radius: 10px;
	}

	.search-slider-icon-mobile {
		height: 30px;
		width: 30px;
		opacity: 0.35;
		font-size: 30px !important;
		-webkit-transform: rotate(270deg);
		        transform: rotate(270deg);
		margin-top: 10px;	
	}

	.search-content-mobile {
		/* display: flex; */
		/* justify-content: space-between; */
		align-items: center;
		padding: 0px 5px 0px 0px;
		height: 30px;
		background-color: #0A043B;
		/* margin-top: -4%; */
		margin-right: 30%;
	}

	.search-icon-div-mobile {
		color: white;
		margin-left: 5px;
		display: inline;
		/* margin-top: -4%; */
		/* font-size: 20px; */
	}

	.font-size-search {
		font-size: 20px !important;
	}

	.search-title-mobile {
		background-color: white;
		width: 186%;
		margin-top: 10px;
	}
}

/* .search-input-wrapper-mobile {
	display: none;
} */

.job-list-first-sec {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 30px 0px;
	padding: 10px 0px;
}

.jobs-for-u-text {
	width: 100%; 
	text-align: center; 
	/* font-size: 15px; */
	font-size: 14px;
	/* font-weight: 600; */
	font-weight: 500 !important;
	border-bottom: 1px solid #E7E7E7; 
	line-height: 0.1em;
	margin: 10px 0 0px; 
	color: #181818;
	text-transform: uppercase;
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
 } 
 
 .jobs-for-u-text span { 
	 background: #FAFBFD; 
	 padding:0 70px; 
	 font-weight: 500 !important;
}

.job-list-outer-wrapper {
	margin-top: 10px;
}

.job-list-inner-wrapper {
	margin-top: 50px;
	height: 500px;
	overflow-y: scroll;
	padding-right: 10px;
}

.recruiter-list-inner-wrapper {
	height: auto;
	overflow-y: hidden;
}

.job-item-outer {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
	padding: 10px 20px;
	margin-bottom: 20px;
	background-color: #FFFFFF;
	box-shadow: 0px 20px 60px rgba(241, 244, 248, 0.5);
	border-radius: 16px;
	max-height: 150px;
}

@media (max-width: 768px){
	.job-item-outer {
		padding: 10px 10px;
		max-height: 230px;
	}
}
.job-logo-view {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 20px 10px;
	width: 10%;
}

.job-info-view {
	width: 70%;
	margin: 0px 0px;
}

.job-action-view {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 20%;
}

@media(max-width: 768px){
	.job-action-view {
		width: 100%;
		justify-content: flex-end;
		flex-direction: row;
		align-items: center;
		/* display: none; */
		height: 25px;
	}

	.job-logo-view {
		width: 20%;
		padding: 10px 0px;
	}

	.job-info-view {
		margin: 0px 5px;
	}

	.button-sm-resp {
		padding: 5px 10px;
		font-size: 12px;
	}
}

.job-logo {
	height: 55px;
	width: 55px;
	border-radius: 50%;
}

.job-upper-info-view {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@media(max-width: 768px){
	.job-upper-info-view {
		/* flex-wrap: wrap; */
	}
}

.job-title-view {
	min-width: 55%;
}

.job-title-label {
	font-style: normal;
	font-weight: 500;
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	font-size: 14px;
	line-height: 150%;
	color: #181818;
	text-transform: capitalize;
}

.simillar-jobs-details {
	margin-top: 13.9% !important;
}

@media(max-width:950px){
	.job-title-label {
		width: 100%;
	}

	.simillar-jobs-details {
		margin-bottom: 50px;
		margin-top: 0px !important;
	}

	.similar-job-item-outer {
		flex-wrap: nowrap;
	}

	.companypage-footer {
		margin-top: 25px;
	}

	.get-in-touch-btn {
		margin-left: 15px
	}
}

.job-tag-info-view {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
	min-width: 60%;
}

@media(max-width: 768px) {
	.job-tag-info-view {
		margin-left: 27px;
	}
}

.job-status-view {
	width: auto;
	background: rgba(39, 159, 109, 0.53);
	border-radius: 16px;
	padding: 5px 20px;
	margin: 10px 10px;
}

.job-status {
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	font-style: normal;
	/* font-weight: 600; */
	font-weight: 500;
	font-size: 10px;
	line-height: 12px;
	display: flex;
	align-items: center;
	text-align: center;
	text-transform: capitalize;
	color: #FFFFFF;
}

.job-tag-view {
	width: auto;
	background: #F4F4F4;
	border-radius: 16px;
	padding: 5px 20px;
	margin: 10px 10px;
}

.job-tag {
	font-style: normal;
	font-weight: 600;
	font-size: 10px;
	line-height: 12px;
	display: flex;
	align-items: center;
	text-align: center;
	text-transform: capitalize;
	color: #0A043B;
}

@media(max-width: 768px){
	.job-status-view{
		margin-left: -18px !important;
		padding: 5px 10px;
		margin: 5px 0px;
	}

	.job-tag-view {
		padding: 5px 10px;
		margin: 5px 5px;
	}

	.job-status {
		line-height: 10px;
	}

	.job-tag {
		line-height: 10px;
	}
}

.job-application-view {
	width: auto;
	background: #0A043B;
	border-radius: 16px;
	padding: 5px 20px;
	margin: 10px 10px;
	cursor: pointer;
}

.job-application {
	font-style: normal;
	font-weight: 600;
	font-size: 10px;
	line-height: 12px;
	display: flex;
	align-items: center;
	text-align: center;
	text-transform: capitalize;
	color: #FFFFFF;
}

.job-reject-view {
	width: auto;
	background: #D48E5B;
	border-radius: 16px;
	padding: 5px 20px;
	margin: 10px 10px;
	cursor: pointer;
}

.job-reject {
	font-style: normal;
	font-weight: 600;
	font-size: 10px;
	line-height: 12px;
	display: flex;
	align-items: center;
	text-align: center;
	text-transform: capitalize;
	color: #FFFFFF;
}

.additional-job-info {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	padding: 0;
}

.job-provider-name {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 150%;
	color: #181818;
	text-transform: capitalize;
}

.job-salary {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 11px;
	color: #9A9A9A;
	margin-left: 10px;
}

@media (max-width: 768px){
	.job-provider-name {
		margin-right: 10px;
		margin-bottom: 5px;
	}

	.job-salary {
		margin-left: 0px;
		margin-top:-3px;
	}
}

.job-desc-wrapper {
	margin-top: 10px;
	text-align: justify;
	line-height: 1.5em;
	height: 3em;
	overflow: hidden;
	background: -webkit-linear-gradient(#000000, #eee);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.job-desc-wrapper :first-line {
	color: #000000;
}

@media(max-width: 768px){
	.job-desc-wrapper {
		margin-top: 2px;
		line-height: 1.3em;
	}
}

.job-filter-wrapper {
	padding: 25px 15px;
	background: #FFFFFF;
	box-shadow: 0px 20px 60px rgba(241, 244, 248, 0.5);
	border-radius: 16px;
	margin-top: 42%;
}

@media(max-width: 768px){
	.job-filter-wrapper{
		margin: 20px 10px 10px 10px;
		display: none;
	}
}

.job-shortlist-label {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 14px;
	text-align: center;
	-webkit-text-decoration-line: underline;
	        text-decoration-line: underline;
	text-transform: uppercase;
	color: #0A043B;
	margin-top: 15px;
	cursor: pointer;
}

@media (max-width: 768px){
	.job-shortlist-label {
		margin: 0px 10px;
	}
}

.hr {
	height: 0;
	border: 1px solid #E7E7E7;
	margin-top: 10px;
	margin-bottom: 10px;
}

.new-job-logo {
	border-radius: 50%;
}

.new-job-item-outer {
	border-radius: 0px;
}

.job-list-login-wrapper {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	flex-wrap: wrap;
}

.joblist-login-overlay {
	/* position: absolute; */
	height: 100%;
	width: 100%;
	opacity: 0.25;
	background: #FFFFFF;
	/* background-color: rgba(255, 255, 255, 0.78); */
}

.login-btn-inner-view {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	opacity: 1;
	width: 100%;
	top: 45%;
}

@media(max-width: 768px){
	.login-btn-inner-view {
		top: 50%;
	}
}

.candidate-portfolio-btn {
	padding: 8px 15px;
	font-size: 12px;
}

.new-signin-btn-pos{
	margin-left: -488px;
	margin-top: 30px;
}   
.new-signup-btn-pos{
	margin-left: 30px;
    /* margin-top: 86px; */
}
.get-in-touch-button{
	margin-right: -154px;	
}
.start-now-btn-pos{
    margin-top: 15px; 
	margin-left: 0;
	text-align: center;
	font-weight:500;
}
.search-job-button-pos{
	margin-left: -313px;
    margin-top: 17px;
	font-weight: 500;
	color: #FCFDFE;
}

/* .primary-btn {
	font-weight: 800;
	font-size: 12px;
	line-height: 12px;
	text-align: center;
	letter-spacing: 0.14em;
	text-transform: uppercase;
	color: #FCFDFE;
} */

.browse-job-button{
	margin-top: 90px;
    margin-left: -554px;
	font-weight:600;
}
.find-can-button{
	margin-top: 90px;
    margin-left: -526px;
	font-weight: 600;
}
.sign-btn-div{
	margin-top: 20px;
    margin-left: 131px;
}

.home-start-now-div {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
	flex-wrap: wrap;
}

@media(max-width: 768px){
	.home-start-now-div {
		align-items: center;
		justify-content: center;
		width: calc(100% - 4rem);
		text-align: center;
	}
}
.header-spec-btn-wrapper {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	margin-top: 0px;
}

.alert-margin {
	/* margin-left: -10px !important; */
	text-align: left;
}

.about-heading {
	width: 350px;
	height: 59px;
	margin-left: 95px;
	font-family: Rubik;
	font-weight: bold;
	font-size: 44px;
	line-height: 126%;
	-webkit-font-feature-settings: 'pnum' on, 'lnum' on;
	        font-feature-settings: 'pnum' on, 'lnum' on;
	color: #000000;
	letter-spacing: .025em;
}

.about-welcome-para {
	margin-left: 95px;
	width: 375px;
	height: 177px;
	margin-top: 77px !important;
	font-family: rubiklight,Arial,sans-serif;
	font-size: 15px;
	line-height: 1.4em;
	letter-spacing: 0.08em;
	color: #616161;
	text-align: justify;
}

.welcome-img {
	left: 49.39%;
	right: 7.64%;
	top: 10.54%;
	bottom: 83.52%;
	-webkit-transform: matrix(1, 0, 0, 1, 0, 0);
	        transform: matrix(1, 0, 0, 1, 0, 0);
	border-radius: 10px;
}

.associate-recruiter-heading {
	font-size: 44px;
	font-family:'Rubik';
	/* margin-top: -20% !important; */
	color: #0B132A;
}

.about-team-head {
	/* width: 404px; */
	height: 52px;
	/* margin-left: 367px; */
	font-family: Rubik;
	font-weight: bold;
	font-size: 44px;
	line-height: 52px;
	/* font-feature-settings: 'pnum' on, 'lnum' on; */
	color: #0B132A;
}

.founder-name {
	font-size: 24px;
	line-height: 28px;
	color: #151515;
	/* margin-left: 53px; */
	/* margin-right: 62px; */
	margin-top: 19px;
}

.founder-title {
	/* font-family: Rubik; */
	/* font-style: normal; */
	/* font-weight: normal; */
	font-size: 14.6667px;
	line-height: 17px;
	text-align: center;
	color: #000000;
	margin-top: 20px;
}

.founder-desc {
	/* font-family: Rubik; */
	/* font-style: normal; */
	/* font-weight: normal; */
	font-size: 14.6667px;
	line-height: 17px;
	text-align: center;
	color: #000000;
	margin-top: 7px;
}

.signup-btn {
	height: 35px;
}

.new-bg {
	background: #FAFBFD;
}

.alert-width {
	width: 97% !important;
} 
.jumbotron-main-creative{
	display: flex;
	flex-direction: column;
    align-items: flex-start;
	justify-content: flex-start !important;
	flex-wrap:wrap;
	/* margin-top:116px; */
	margin-right: 10px;
	width: 37% !important;
} 
.header-heading-div{
    width: 100%;
    text-align: left;
}
.header-heading{
	font-size:38px;
	font-family: Rubik,Arial,sans-se rif;
	color:#ffffff;
	font-weight: 600;
}
.header-para{
	margin-top: 10px;
	font-size:15px;
	/* width:354px; */
	text-align:left;
	color:#ffffff;
	letter-spacing: 0.1em;
	font-weight: 400;
}
.signinup-btn-div{
	margin-top:15px;
}
.signup-btn-pos{
	margin-left: 30px;
    margin-top: 0px;
}

.new-candidate-heading p {
	font-size: 44px;
	font-weight:700;
}

@media (max-width: 768px){
	.mobile-view-btn-responsive{
		margin-top:0px;
		display: none;
	}
	.responsive-hidden{
		display:none;
	}
	.header-heading-div{
		width: 285px;
	}
	.header-heading{
		font-size:25px !important;
		text-align:center !important;
	}
	.jumbotron-main-creative{
		align-items: center;
		max-width:95vw;
		align-content: center;
	}
	.header-para{
		font-size: 12px;
    	width: 268px;
    	text-align: center;
	}
	.signup-btn-pos{
		margin-top:-1px !important;
	}
	.login-outer-div{
		margin-top: -78px;
    	/* margin-left: 20px; */
	}
	.hide-login-mobile{
		display:none !important;
	}
	.screenshot-div{
		justify-content: center;
		margin-left:0px !important;
	}
	.dedicated-platform-div{
		margin-left:0px !important;
		width:350px !important;
	}
	.dedicated-platform-heading{
		font-size: 25px !important;
		width: 267px !important;
		text-align: center ;
		margin-left:14px;
		margin-bottom: 30px;
	}
	.dedicated-platform-para{
		text-align: center;
    	font-size: 12px;
    	margin-top: -20px;
    	max-width: 90vw;
	}
	.search-job-button-pos{
		margin-left: -30px !important;
	}
	.skill-heading{
		font-size:25px !important;
	}
	.skill-para{
		text-align:center !important;
		font-size: 12px;
	}
	.rd-navbar-brand a{
		margin-left:0px!important;
	}
	.skill-tool-div :nth-child(1){
		width: calc(100% - 1rem);
		text-align: center;
	}

	.skill-tool-div {
		max-width: 94vw;
		height: auto;
	}
		
	.our-recruiter-heading{
		font-size:25px;
	}
	.top-brand-icon{
		margin-left:0px;
	}
	.new-candidate-heading p {
		font-size:25px;
	}
	.fast-access-div{
		display: flex;
		flex-direction: column;
		height: 140px;
		/* margin-left:15px; */
	}
	.rd-navbar-panel{
		display:flex;
		justify-content: space-between;
		flex-direction:row-reverse;
	}
}
@media(max-width:1240px){
	.signup-btn-responsive{
		margin-top:0px !important;
	}
}
@media(max-width:320px){
	.nav-list-post{
		font-size:10px;
	}
}
@media(max-width:1200px){
	.rd-navbar-panel{
		display:flex;
		justify-content: space-between;
		flex-direction:row-reverse;
	}
	.rd-navbar-brand a{
		margin-left:0px;
	}
	.brand-logo-dark{
		width:50px;
	}
}
@media(max-width:1000px){
	.screenshot-div{
		justify-content: center;
		margin-left:0px !important;
	}	
	/* .dedicated-platform-para{
		width: 486px;
	} */
	.top-brand-icon {
		margin-left:0px;
	}
}
@media(max-width:380px){
	.fast-access-div{
		display:flex;
		flex-direction:column;
		height:240px;
	}
	.footer-signin-btn-pos{
		position:absolute;
		margin-top:10px;
	}
	.footer-search-btn-pos{
		position: absolute;
    	margin-top: -26px;
	}
	.footer-signup-btn-pos{
		position:absolute;
		margin-left:0px;
		margin-top:85px;
	}
	.footer-signin-btn-responsive{
		margin-left: -183px;
		margin-top: 42px;
	}
	.footer-search-btn-responsive{
		margin-top:10px;
	}
	.footer-signup-btn-responsive{
		margin-top:10px;
	}
	.nav-list-post{
		font-size:11px;
	}
}

.experience-card p ::first-line{
	color: #000000;
}

.category-italic-header{
	margin-right: 5px;
	font-size: 10px;
	font-style: italic;
}

.submit-resume-skill{
	color:#000000;
	margin-left:0px;
}
.justify-content-sa{
	justify-content:space-around;
}
.footer-font-16{
	font-size:16px;
	letter-spacing: 0.04em;
	font-weight:600;
}
.quick-search{
	margin-top:7px;
}
.contact-page-email{
	margin-left:0px;
}

.resume-assesment-div{
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 10px 0px;
	padding: 10px 0px;
}

.resume-skill-para {
	width: 60%;
	text-align: justify;
}

.resume-bold {
	color: #000000;
	font-weight: 600;
}

.cursor {
	cursor: pointer;
}

.employer-details-text {
	margin-right: 8%;
}

.filter-div-top {
	margin-top: 18% !important;
}

.rec.rec-arrow {
    background-color: #0A043B;
	color: #FFFFFF;
}

.rec.rec-arrow:hover {
    background-color: rgb(39, 159, 141) !important;
	border-color: rgb(39, 159, 141) !important;
}

.rec.rec-arrow:focus {
    background-color: rgb(39, 159, 141) !important;
	border-color: rgb(39, 159, 141) !important;
}

.width-new-candidate {
	width: 98% !important;
	margin-left: 15px !important;
}

.team-img {
	width: 199px;
	border-radius: 12px;
}

@media only screen and (max-width: 768px) {
	.team-img {
		width: 155px;
	}

	.width-new-candidate {
		margin-left: 5px !important;
	}
}
@media only screen and (max-width: 1000px){
	.about-welcome-responsive{
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.about-welcome-para {
		margin-left: 10vw;
		height: auto;
		width: 80vw;
		margin-top: 20px !important;
	}
	.about-heading {
		margin-left: 0px;
		text-align: center;
		font-size: 25px;
		width: auto;
	}
	.about-team-head{
		font-size:25px !important;
	}
}
@media (max-width:500px){
	.welcome-img{
		width: 80vw !important;
	}
}

.new-candidate-carousel{
	margin-top:90px;
	margin-bottom:30px;
}
.our-recruiter-carsousel{
	margin-top:90px;
}
.one-stop-section{
	margin-top:90px;
}
.applied-btn{
	background-color: rgb(39, 159, 141) !important;
    border-color: rgb(39, 159, 141) !important;
	color:#FFFFFF;
}
.start-journey-div{
	display: flex;
	justify-content: center;
	align-items: center;
	padding:15px;
	margin-bottom:15px;
}
.sign-in-label{
	padding-left:5px;
}
.start-journey-font{
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif;
	font-size:15px;
	color:#0B132A;
	font-weight: 500;
}

.alert-center {
	text-align: center;
	max-width: 100% !important;
}

/* .editor-wrapper p span {
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif !important;
} */
.editor-wrapper p{
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	color:#9a9a9a !important;
	font-size: 14px !important;
	font-weight: 400 !important;
}
.editor-wrapper span{
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	color:#9a9a9a !important;
	font-size: 14px !important;
	font-weight: 400 !important;
}
.editor-wrapper strong{
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	color:#9a9a9a !important;
	font-size: 14px !important;
	font-weight: 400 !important;
}

.editor-wrapper li {
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	color:#9a9a9a !important;
	font-size: 14px !important;
	font-weight: 400 !important;
}

.checkbox-error-msg{
	margin-left:-25px;
	color:red;
}

.activity-section {
	min-height: 53vh;
}

.footer-div-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
}
.filters-mobile-view {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.footer-section-1{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 50%;
	margin-left: -3%;
}

.footer-section-1 ul{
	margin-left: -8px;
}

.footer-section-2 {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 50%;
}

/* .footer-section-2 ul{
	margin-left: -12px;
} */
/* .footer-section-2 p {
	margin-top: -47px;
} */

@media(max-width: 768px){
	.footer-div-container {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;
		flex-direction: row;
	}

	.footer-section-2 p {
		margin-top: 0px;
	}

	.footer-section-1 ul{
		margin-left: -20px;
	}
}



.header-btn {
	font-style: normal;
	font-weight: 500;
	line-height: 12px;
	letter-spacing: 0.14em;
	color: #FCFDFE;
}

.header-btn-group{
	margin-top: 15px;
}

@media(max-width: 768px){
	.header-login-btn {
		margin-top: 0px !important;
	}
}

.start-journey-btn{
	display: flex;
   justify-content: flex-start;
   flex-wrap: wrap;
   align-items: center;
   margin-left: 10px;
   flex-direction: row;
}
.start-journey-signup{
   margin-top:0px;
   margin-left:10px;
}
@media (min-width: 790px){
   .react-date-picker__inputGroup{
	   margin-left:3vw;
   }
}
@media (max-width:1300px){
   .start-journey-signup{
	   margin-top:0px !important;
   }
}
@media (max-width:400px){
   .start-journey-div{
	   flex-direction:column;
   }
}
@media (max-width:780px){
   .breadcrumbs-custom-title ,.terms-custom-title{
	   font-size:22px !important;
   }
   .about-heading-div{
	   margin-top:15px;
   }
}
@media (max-width:500px){
   .list-nested-responsive{
	   margin-left:-5vw;
   }
   .nested-list-terms-responsive{
	   margin-left:4vw;
   }
   .terms-list-heading-responsive{
	   margin-left:20px;
   }
}

.terms-of-use-wrapper strong{
   font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif;
}
.privacy-list-wrapper strong{
   font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif;
}

@media (min-width: 322px) and (max-width: 768px){
	.mobile-div-filter {
		/* display: flex; */
		/* justify-content: space-between; */
		align-items: center;
	}

	/* .align-center {
		text-align: center;
	} */

	.filter-parent-div {
		display: block !important;
	}

	.title-mobile {
		display: none;
	}

	input[type=checkbox] {
		-webkit-appearance: checkbox !important;
		appearance: checkbox !important;
	}

	.jobcount-font {
		font-family: 'Rubik', Arial, sans-se rif;
		color: #4c4c4d;
	}

	.relevance-color {
		color: #0c073b;
		text-transform: none;
	}

	.filter-color {
		color: white;
	}
}

.filter-parent-div {
	display: none;
}

.width-recur {
	width: 100%;
	margin-right: 70px !important;
}
.visibility-hidden{
	visibility:hidden;
}

.infinite-scroll-component__outerdiv {
	width: 100%;
}

.infinite-scroll-component {
	overflow-x: hidden !important;
}

.job-application-view:hover,
.job-application-view:active {
	background-color: rgb(39, 159, 141);
	border-color: rgb(39, 159, 141);
}
@media (max-width:786px){
	.job-title-view{
		min-width: 60%;
	}

	.filter-line-bars {
		width: 25px;
		height: 25px;
		margin-right: 15px;
	}
}

@media (max-width:786px){
	.margin-top-20-responsive{
		margin-top:-20px !important;
	}
	.margin-top10-responsive{
		margin-top: 10px !important;
	}
	.label-years-in{
		margin-left:0px;
	}
	.margin-top-10-responsive{
		margin-top: -10px !important;
	}
	.margin-left10-responsive{
		margin-left:10px !important;
	}
	.post-job-btn{
		right:6vw !important;
	}
	.margin-top20-responsive{
		margin-top: 20px !important;
	}
	.margin-top0-responsive{
		margin-top:0px !important;
	}
	.job-application-view{
		margin: 0px;
		padding: 5px 10px;
		margin-left: 5px;
	}
		@media(max-device-width:768px) {
			.job-application-view {
				padding: 4px 6px;
				margin-left: 4px;
			}
		}
	.additional-job-info {
		width: 250px;
	}
	.margin-left0-responsive{
		margin-left:0px !important;
	}
	.profile-btn-responsive{
		padding: 5px 10px;
		margin-top: 5px !important;
	}
	.candidate-list-div-responsive{
		height:40px;
	}
	.card-item-view-responsive{
		margin-left:18vw !important;
	}
	.resume-job-title-responsive{
		width: -webkit-fill-available;
	}
	.profile-pic-responsive{
		margin-left:-80px;
	}
}
.activity-counter-main-menu {
	/* height: 2px;
	width: 2px; */
	border-radius: 50%;
	background-color: #0A043B;
	color: #FFFFFF;
	margin-left: 2px;
	font-size: 8px;
	padding: 0px 1px;
}

/* .activity-dummy {
	height: 10px !important;
	width: 10px !important;
} */

.activity-counter {
	height: 25px;
	width: 25px;
	border-radius: 50%;
	background-color: #0A043B;
	color: #FFFFFF;
	margin-left: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px 10px;
	text-align: center;
	font-size: 12px;
	font-weight: 700;
}

@media (max-width:400px){
	.sbt-btn-responsive{
		margin-top: 90px !important;
	}
}

.check-opacity::after {
	opacity: 1 !important;
}

@media (max-width:786px){
	.activity-counter {
		display: inline;
		padding: 5px 8px;
	}
}

@media (max-width:400px){
	.job-status-view{
		/* margin-left:3vw !important; */
		padding:5px !important;
	}
}

.resend-code-button{
	margin-top: 0px;
	margin-left: 20px;
}

.menu-nav-link {
	font-style: normal;
	font-weight: 500;
	line-height: 13px;
	letter-spacing: 0.14em;
	font-size: 16px;
	color: #150F3D !important;
	text-transform: uppercase;
}

@media (max-width:500px){
	.scroll-table td{
		align-items: center;
		padding: 5px;
	}
	
	.table-job-listing-badge-responsive{
		width:100%;
	}
	.table-apply-btn{
		padding: 5px 20px !important;
	}
	.scroll-table tbody{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}
	
	.scroll-table .table-job-listing-main{
		min-width:200px !important;
	}
	.company-minimal-responsive{
		min-width:220px !important;
	}
	.padding-right-15{
		padding-right:15px;
	}
}

.border-0{
	border: 0px;
}

.comapny-page-info-icons {
	width: 20px !important;
}

.vacancy-open-count {
	font-size: 12px !important;
}

.width-17{
	width: 17px;
}

.width-18{
	width: 18px;
}
.margin-left-0{
	margin-left:0px;
}

.height-fit-content{
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
}

@media (max-width:786px){
	.skill-component-container{
		flex-wrap: wrap;
	}
}

.similar-job-logo-view {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* padding: 20px 10px; */
    width: 30%;
}

.similar-job-title-view {
    min-width: 100%;
}

.candidate-job-salary {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 11px;
	color: #9A9A9A;
	/* margin-left: 10px; */
}

.simillar-candidate-details {
	margin-top: 0;
}

@media (max-width:786px) {
    .similar-job-logo-view {
        width: 27%;
        padding: 10px 0px;
    }

	.simillar-candidate-details {
		margin-bottom: 50px;
	}
}

.job-filter-div{
	margin-top:41%;
}

.font-weight-300{
	font-weight: 300;
}

@media (max-width: 500px){
	.job-application-view{
		margin-right:5px;
	}
}

@media (max-width: 400px){
	.job-title-view{
		min-width: 60% !important;
	}
}

.empty-list-alert{
	margin-top: 51px !important;
}

.loader-loading-list{
	height:80vh;
}

.alert-width-div {
	max-width: 830px;
}

.big-location-name{
	max-width: 260px;
    display: inline-grid;
    margin-left: 3px;
}
@media (max-width: 786px){
	.big-location-name{
		max-width:220px;
		display: inline-flex;
	}
}

.india-presence-div{
	display: inline-grid;
	margin-left:-3px;
}

@media (max-width: 786px){
	.india-presence-div{
		width:300px;
	}

	.left-text {
		text-align: left !important;
		display: flex;
		justify-content: flex-start;
	}
}
@media (max-width: 400px){
	.india-presence-div{
		max-width: 260px;
	}
}

@media(max-width: 310px){
	.india-presence-div{
		max-width: 220px;
	}
}

.CircularProgressbar .CircularProgressbar-text {
	font-family: "Rubik" !important;
	font-weight: 500;
}

.tinyMCEstyle li {
	text-align:justify !important;
	display: inline  !important;
    font-size: 14px !important;
    font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif !important;
	background: transparent !important;
}

.tinyMCEstyle ul {
	text-align:justify !important;
	display: inline  !important;
    font-size: 14px !important;
    font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif !important;
	background: transparent !important;
}
.tinyMCEstyle ol {
	text-align:justify !important;
	display: inline  !important;
    font-size: 14px !important;
    font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif !important;
	background: transparent !important;
}

.tinyMCEstyle strong{
	text-align:justify !important;
	display: inline  !important;
    font-size: 14px !important;
    font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif !important;
	background: transparent !important;
}

.card-overflow {
	height: 50px;
	max-height:60px;
}

.tinyMCEstyle div {
	background: transparent !important;
}

.hyperlink {
	color: #0A043B;
}

.shortlisted-style {
	margin-top: 15px;
	cursor: pointer;
	padding: 9px 8px;
	font-size: 12px;
}

.remove-shortlisted-style {
	margin-top: 15px;
	cursor: pointer;
	padding: 8px 15px;
	font-size: 12px;
}

.wrapper-tick {
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #eee */
}

@media (max-width: 768px) {
	.shortlisted-style {
		margin-top: 0px !important;
		cursor: pointer;
		padding: 5px 8px;
		font-size: 12px;
		margin-right: 5px;
		margin-left: 5px;
	}

	.wrapper-tick {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 0px !important;
		margin-right: 5px;
		margin-left: 5px;
	}

	.remove-shortlisted-style {
		margin-top: 0px !important;
		cursor: pointer;
		padding: 5px 15px;
		font-size: 12px;
		margin-right: 5px;
		margin-left: 5px;
	}
}

.animated-check {
    height: 2em;
    width: 2em;
}

.animated-check path {
    fill: none;
    /* stroke: #7ac142; */
	stroke: rgb(39, 159, 141);
    stroke-width: 4;
    stroke-dasharray: 23;
    stroke-dashoffset: 23;
    -webkit-animation: draw 1s linear forwards;
            animation: draw 1s linear forwards;
    stroke-linecap: round;
    stroke-linejoin: round
}

@-webkit-keyframes draw {
    to {
        stroke-dashoffset: 0
    }
}

@keyframes draw {
    to {
        stroke-dashoffset: 0
    }
}
.search-component-chevron{
	font-size: 25px !important;
	color: #0A043B ;
}

.employer-carousel-div{
	width:250px !important;
	max-width: 250px !important;
	min-width: 250px !important;
	height: 250px !important;
}

@media(max-width: 400px){
	.employer-carousel-div{
		width: 220px !important;
		max-width: 220px !important;
		min-width: 220px !important;
		height: 220px !important;
		background-color: #ffffff;
		padding:0px !important;
	}
	.employer-carousel-div img{
		width:150px !important;
	}
}
@media (max-width: 350px){
	.employer-carousel-div{
		width:160px !important;
		max-width: 160px !important;
		min-width: 160px !important; 
		height:160px !important;
	}
}

.application-btn-div{
	margin-top:18px;
}

.application-btn{
	padding: 5px;
	font-size:12px;
	line-height:18px;
}
.more-btn-padding{
	padding: 5px 34.5px 5px 34.5px;
}

@media (max-width: 786px){
	.application-btn-div{
		margin-top:-30px;
		padding:5px;
	}	
	.application-btn{
		padding: 5px;
		font-size:12px;
		line-height: 15px;
	}
	.more-btn-mobile{
		padding: 5px !important;
	}
	
}

.website_com {
	word-wrap: break-word;
}

.editor-wrapper > *::first-letter {
	text-transform: uppercase !important;
}

.editor-wrapper div::first-letter {
	text-transform: uppercase !important;
}


.editor-wrapper p::first-letter {
	text-transform: uppercase !important;
}

.editor-wrapper li::first-letter {
	text-transform: uppercase !important;
}

.editor-wrapper table,
.editor-wrapper tbody,
.editor-wrapper tr,
.editor-wrapper table td {
	border: none !important;
	background-color: transparent !important;
}

.shortlist-candidate-outer-div {
	margin-top: 6%;
}

.shortlist-candidate-title-label {
    font-style: normal;
    font-weight: 500;
    font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
    font-size: 14px;
    line-height: 150%;
    color: #181818;
    text-transform: capitalize;
}

.company-website-link-div{
	overflow-wrap: break-word;
}

.job-details-icon-div {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	width: 7vw;
}
.ticker-container-div{
	width:100% !important;
	height:50px;
	display:flex;
	align-items: center !important;
	justify-content: center;
	color:#0A043B;
	background-color:transparent;
	white-space:nowrap;
}

.ticker-container-div .ticker{
	width:10000% !important;
	display: inline-flex !important;
}

.move-ticker-div{
	display: inline-flex !important;
}
.move-ticker{
	font-weight:500;
	font-size:16px;
	padding-right:5px;
}

.move-ticker-margin{
	margin-left:20px;
}
.ticker-margin-space{
	margin-left:10px;
}

ul#menu {
   width:100%;
   overflow:hidden;
   text-align:justify; /*Added this*/
   margin-top: 10px;
}

ul#menu li {
	margin-right: 10px !important;
	text-transform: capitalize;
	display:inline-block; 
}


@media (max-width: 786px){
	.nearby-button {
		width: 100%;
	}

	.nearby-button-div {
		background-color: hsl(0, 0%, 100%);
		margin-bottom: 0% !important;
	}

	ul#menu {
		background-color: white;
		margin-top: 5%;
		border-radius: 4px;
	}

	ul#menu li {
		padding: 5px;
	}
}


.job-disabled-modal {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
    position: absolute !important;
	flex-direction: column;
	width: 50vw;
	left: 25%;
    top: 25%;

    right: auto;
    bottom: auto; 
    background-color: white;
	opacity: 1;
	box-shadow: 1px 4px 29px -4px rgba(18,17,17,0.82);
	-webkit-box-shadow: 1px 4px 29px -4px rgba(18,17,17,0.82);
	-moz-box-shadow: 1px 4px 29px -4px rgba(18,17,17,0.82);
}

@media (max-width: 768px){
	.job-disabled-modal {
		left: 3vw;
		width: 90vw;
	}

	.job-details-icon-div {
		width: 24vw;
	}
}

.job-disabled-width {
	width: 100%;
}

.disable-btn {
	padding: 5px;
	font-size:12px;
	line-height:18px;
}

.disabled-input {
	width: 98%;
	height: 30%;
}

.reason-label {
	font-style: italic;
	color: red;
}

.disable-reason {
	min-height: 5px !important;
	margin-top: 20px;
}

.disable-btn-list{
	padding: 5px 25px;
	font-size:12px;
	line-height:18px;
}

.border-disable {
	border: 2px solid gray;
}

.border-disabled {
	background-color: #ddd;
}

@media (max-width: 768px){
	.disable-btn-list{
		padding: 5px;
		font-size:12px;
		line-height:15px;
		width: auto;
	}
}

.spinner-disable {
	margin-right: 40px;
}

.employer-noitem-msg-div{
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	width:100%;
}

.no-text-transform {
	text-transform: none;
}

.html2canvas-container { width: 3000px !important; height: 3000px !important; }

.border-selected-candidate {
	border: 3px solid rgba(39, 159, 109, 0.53);
	height: 65px;
	width: 65px;
	padding: 3px;
}

@media (max-width:786px){
	.reject-btn-responsive{
		margin-top: 0px !important;
		margin-left: 5px !important; 
	}
}

.select-btn-responsive{
	padding: 5px 10px;
	margin-top: 5px !important;
}

.select-btn-application {
	margin-top: 8px;
	cursor: pointer;
	padding: 9px 19px;
	font-size: 12px;
}

.selected-btn-application {
	margin-top: 8px;
	cursor: pointer;
	padding: 9px 10px;
	font-size: 12px;
	background: #279f8d;
	color: white;
	border-color: #279f8d;
}

.app-short-top {
	margin-top: 3%;
}
.loading-startnow-btn {
	height: 50px ;
	display: flex;
}
.loading-startnow-btn div{
	display: flex;
	align-items: center;
	height: 50px !important;
	width:50px !important;
	margin-top:20px;
	margin-left:20px;
}

.red-cross-image{
	height: 28px;
	width: 28px;
	margin-top: 3px;
}

.text-danger {
	color: #ff4444;
}

.margin-left10{
	margin-left:10px;
}
.share-icon-div{
	margin-top: 20px;
	display: flex;
	align-items: center;
}

@media (max-width: 768px){
	.share-icon-div{
		padding-bottom: 45px;
	}
}

.circular-percent-job-div{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 90px;
	width: 90px;
	padding: 15px;
}

.width-10-percent{
	width: 10% !important; 
}
@media( max-width: 780px){
	.circular-percent-job-responsive{
		height: 50px;
		width:50px;
		padding: 5px;
		margin-left:20px;
	}
	.reponsive-job-action-view{
		width: 70% !important;
	}
}

@media (max-width: 768px) {
    .top_margin {
        margin-top: 0px;
    }
}

.pop-up{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: flex-start;
	background-color: #fff;
	max-width: 250px;
	color: #0A043B;
	padding: 20px;
	position: absolute;
	float: right;
	border-radius: 10px;
	margin-top: -20px;
	margin-left: 30px;
	z-index: 11;
	opacity: 1;
	box-shadow: 1px 4px 29px -4px rgba(18,17,17,0.82);
	-webkit-box-shadow: 1px 4px 29px -4px rgba(18,17,17,0.82);
	-moz-box-shadow: 1px 4px 29px -4px rgba(18,17,17,0.82);
	box-sizing: border-box;
}

.pop-up.arrow-left:after {
  content: " ";
  position: absolute;
  left: -15px;
  top: 15px;
  border-top: 15px solid transparent;
  border-right: 15px solid white;
  border-left: none;
  border-bottom: 15px solid transparent;
}

.fa-info-font{
	font-size:18px !important;
	color: #0A043B !important;
	margin-top:-8px;
}

.fa-times-circle-font{
	font-size:20px !important;
	color: #DCDCDC;
	background-color: white !important;
	position: absolute;
	top:0;
	right: 0;
	margin-right: 5px;
}

.popup-msg-text{
	font-size: 14px;
	font-weight: 550;
	text-align: left;
	color: #0A043B;
}

/* .best-match{
	height: 45px;
} */

@media( max-width: 780px){
	.best-match {
		background-color: rgb(255, 255, 255);
		margin-bottom: 0% !important;
		color: #000000;
		width: 100%;
	}

	.sort-search {
		height: 90%;
	}

	.sort-search-icon {
		font-size: 20px !important;
	}
}

.text-transform-none{
	text-transform: none;
}

/* width */
::-webkit-scrollbar {
	width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
	background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 8px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
	background: var(--brand-color);
	width: 10px !important;
  }

@media (max-width: 768px){
	.padding-bottom-45{
		padding-bottom: 45px;
	}
}
	
.fa.fa-instagram {
	color: transparent;
	background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
	background: -webkit-radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
	background-clip: text;
	-webkit-background-clip: text;
	font-size: 27px;
	vertical-align: middle;
}

.list-inline-footer {
	display: flex;
	margin-left:25px !important;
	align-items: center;
	width: 100%;
}

.list-inline-footer li:hover {
	cursor: pointer;
}

.faq-search-div{
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-bottom:10px;
	flex-direction: row;
}

.faq-search-btn{
	margin-top: 0px;
}

.padding-right-20{
	padding-right: 20px;
}

mark{
    background: #fdff32;
	color: #9a9a9a;
}

.card-corporate .card-title a mark {
	font-size: 18px;
	color: #2c343b;
	font-weight: 300;
}

.slideUp {
	-webkit-animation-name: slideUp;
	        animation-name: slideUp;
	animation-name: slideUp;
	-webkit-animation-duration: 1s;
	        animation-duration: 1s;
	animation-duration: 1s;
	visibility: visible;
	top: 0 !important;
}

@-webkit-keyframes slideUp {
	0% {
	  opacity: 0;
	  -webkit-transform: translateY(70%);
	          transform: translateY(70%);
	} 
	100% {
	  opacity: 1;
	  -webkit-transform: translateY(0%);
	          transform: translateY(0%);
	}
}

@keyframes slideUp {
	0% {
	  opacity: 0;
	  -webkit-transform: translateY(70%);
	          transform: translateY(70%);
	} 
	100% {
	  opacity: 1;
	  -webkit-transform: translateY(0%);
	          transform: translateY(0%);
	}
}
.show-more {
	display: flex;
	justify-content: center;
	align-items: center;
}

/* .about-social {
	width: 35%;
} */

.about-social li:hover {
	cursor: pointer;
}

.about-social-heading {
	font-style: normal;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0.14em;
	color: #151515;
}

@media (max-width: 768px){
	.about-social-heading {
		height: auto;
		width: 80vw;
		margin-top: 20px !important;
	}
}

.contact-icon {
	font-size: 15px;
	margin-top: 7px !important;
}

.contact-icon-insta {
	font-size: 30px !important;
	height: 42px !important;
	cursor: pointer;
}

.email-icon {
	font-size: 26px;
	margin-top: 7px;
}

.row-social {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
}

.row-width {
	width: 100% !important;
	display: contents;
}	
.padding-0{
	padding: 0px !important;
}
.padding-top-10{
	padding-top: 10px !important;
}

.padding-bottom-0{
	padding-bottom: 0px !important;
}

.sort-by-percent-btn{
	padding:7px 15px;
}

.reject-btn{
	padding: 8px 20px;
}

.add-video-label{
	padding-right: 20px;
}

.more-btn-style{
	padding: 9px 26px;
}

.remove-btn-style{
	padding: 9px 18px;
}

@media (max-width: 768px){
	.disable-btn-div{
		padding-left: 0px;
	}
	.remove-btn-style{
		padding: 5px;
	}
}

.add-resume-btn{
	padding: 8px 21px;
}

.submit-otp-btn{
	padding: 8px 15px !important;
	min-height: auto !important;
}

.faq-input-textbox{
	height: 36px;
	padding-left:8px;
}

.admin-dashboard-table-div{
	overflow-x:scroll;
	width:70vw;
}

.admin-dashboard-table{
	overflow-x: scroll;
	-webkit-column-width: 30px !important;
	        column-width: 30px !important;
    grid-column-gap: 30px !important;
    -webkit-column-gap: 30px !important;
            column-gap: 30px !important;
	-webkit-column-rule: 2px solid black !important;
	        column-rule: 2px solid black !important;
}

.admin-candidiate-dashboard-div{
	display: flex;
	width: 100%;
	padding:20px;
	align-items: center;
	justify-content:center;
}

.candidate-total-div{
	display: flex;
    width: 90%;
	justify-content: space-around;
	padding: 12px;
	background-color: #ffffff;
}	

.candidate-total-inner-div{
	display: flex;
	width: 30%;
}

.admin-candidate-dashboard{
	display: flex;
	width: 95%;
	overflow-x: scroll;
}

.showmore-btn-admin-candidate{
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	padding: 20px;
}

.admin-candidate-name{
	color: #080838!important;
	cursor: pointer;
}

.padding-top-10{
	padding-top: 10px;
}

.infinite-admin-employer-div{
	height: 370px !important;
}

.admin-employer-dashboard-div{
	display: flex;
	flex-direction: row;
	background-color: #FFFFFF;
	height: auto;
	width: 100%;
	padding: 20px;
	border-radius: 8px;
}

.admin-employer-total{
	display: flex;
	/* justify-content: space-between; */
	flex-direction:column;
	align-items: self-start;
}

.admin-employer-total-inner{
	display: flex;
	justify-content: space-between;
}

.padding-right-27{
	padding-right:27px;
}

.padding-right-23{
	padding-right:23px;
}

.admin-dashboard-table th{
	padding:5px;
	padding-right:15px;
	text-align: center;
	white-space:normal;
}

.admin-dashboard-table td{
	padding:5px;
	text-align: center;
}

.admin-dashboard-table tr{
	padding:5px;
	text-align: center;
}

.left-admin-employer-div{
	display: flex;
	flex-direction: row;
	width:50%;
}

.admin-employer-total-row{
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: flex-end;
	width: 50%;
}

.right-admin-employer-div{
	display: flex;
	flex-direction: row;
	width: 50%;
}

.admin-candidate-total-name-div{
	display: flex;
	width: 81%;
	flex-direction: column;
}

.admin-candidate-total-value-div{
	display: flex;
	flex-direction: column;
	width: 15%;
}

.table-section-admin-candidate{
	padding-left:40px;
}

@media(max-width: 768px){
	.candidate-total-div{
		flex-direction: column;
	}
	.candidate-total-inner-div{
		width: 100%;
	}
	.left-admin-employer-div{
		width: 100%;
	}
	.admin-employer-dashboard-div{
		flex-direction: column;
	}
	.right-admin-employer-div{
		width: 100%;
	}
	.right-admin-employer-div{
		justify-content: space-between;
	}
	.admin-dashboard-table-div{
		width:30%;
	}
	.table-section-admin-candidate{
		padding-left:15px;
	}
}

.cursor-pointer{
	cursor:pointer !important;
}

.no-of-cv-input{
	width:50px;
	text-align: center;
}
.user-not-present-msg{
	color: red;
}

.padding-top-0{
	padding-top:0px;
}

.footer-browse-job-div{
	margin-left: 58px;
}
.quick-search-div{
	margin-left: -15px;
}

@media (max-width: 768px){
	.footer-browse-job-div{
		margin-left: 32px;
	}

	.footer-browse-li{
		line-height: initial;
	}

	.padding-bottom-5{
		padding-bottom: 5px;
	}
}

.padding-top-50{
	padding-top: 50px !important;
}

.job-n-company-profile-div {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.company-profile-heading {
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	font-weight: 500;
	font-style: italic;
	cursor: pointer;
	text-transform: none;
	text-decoration: underline;
}

.company-details-div {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	grid-gap: 10px;
	gap: 10px;
}

.brand-landing-job-btn {
	width: 135px;
	margin-left: 30px;
}

@media(max-width: 768px){
	.brand-landing-job-btn-div {
		grid-gap: 20px;
		gap: 20px
	}

	.brand-landing-job-btn-div, .button-primary+.button {
		margin-top: 0px;
	}

	.brand-landing-job-btn {
		width: auto;
		margin-left: 0px;
		display: block !important;
	}

	.brand-landing-share-icon-div {
		margin-bottom: 70px;
	}
}

.brand-banner {
	width: 100%;
	position: relative;
	margin-top: 100px;
}

.brand-banner-img {
	width: 100%;
	height: auto;
	/* position: relative; */
}

.brand-banner-btn {
	position: absolute;
	top: 75%;
	left: 25.5vw;
}

.brand-banner-btn:hover{
	background-color: #e76042;
}

.banner-job-logo {
	width: auto;
	height: auto;
}

.royal-orchid-banner-btn {
	background-color: #312B64;
	border-color: #312B64;
	top: 50%;
	left: 31.5vw;
}

.royal-orchid-banner-btn:hover {
	background-color: #312B64;
}

@media(max-width: 768px){
	.brand-banner {
		margin-top: 0px;
	}

	.brand-banner-img {
		height: auto;
		/* position: relative; */
	}

	.brand-banner-btn {
		position: absolute;
		top: 62%;
		left: 25vw;
		display: block !important;
		font-size: 8px !important;
		padding: 2px 5px !important;
	}

	.royal-orchid-banner-btn {
		top: 40%;
		left: 25.5vw;
	}
}

.open-vacancy .width-10-percent{
	width: 20% !important; 
}

.job-item-outer-brand-banner{
	max-height: 100%;
}

.job-description-brand-banner {
	margin-top: 10px;
	text-align: justify;
	line-height: 1.5em;
	height: 100%;
	color: blue;
	overflow: hidden;
	color: #000000 !important;
}

.job-description-brand-banner p {
	color: #000000 !important;
}

.assesment-disable-btn{
	font-size: 10px !important;
	padding:5px 10px !important;
	letter-spacing: .1em;
	line-height: 13px !important;
}

.padding-top-20{
	padding-top:20px;
}


.date-filter-div{
	display: flex;
    justify-content: center;
    align-items: center;
	padding-bottom: 20px;
}

.padding-right-10{
	padding-right: 10px;
}

.date-filter-search-div{
	display: flex;
	justify-content: left;
	align-items: center;
	padding-bottom: 0px;
}

.date-filter-div .react-date-picker__inputGroup{
	margin-left: 10px;
}

.date-filter-div .react-date-picker__inputGroup input{
	padding: 0px;
	min-width: 10px;
	font-size: 14px !important;
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
}

.date-filter-div .react-date-picker__inputGroup__input--hasLeadingZero{
	font-size: 14px !important;
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	padding-left: calc(1px + 0.54em) !important;
}

.react-date-picker__inputGroup__input--hasLeadingZero{
	margin-top:-1px;
}

.margin-left5{
	margin-left:5px !important;
}

.download-excel-candidate-btn-div{
	display: flex;
	flex-direction: row-reverse;
	width: 95%;
	padding: 10px;
}

.download-job-excelbtn-div{
	display: flex;
    width: 98%;
    flex-direction: row-reverse;
}

.admin-dashboard-row-approve-div{
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.accept-reject-employer-label-div{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.employer-descrition-div{
	width: 70%;
	cursor: pointer;
}

.employer-logo-class{
	width:14%;
	cursor: pointer;
}

.accept-reject-employer-label-div label{
	color: #080838 !important;
	cursor: pointer;
	margin-right: 10px;
}

.break-word-hypen{
	-webkit-hyphens: auto !important;
	        hyphens: auto !important;
}

.admin-dashboard-row-approve-div .wrapper-tick{
	height: 25px;
	width: 25px;
}

.admin-dashboard-row-approve-div .img{
	height: 25px;
	width: 25px;
}

.full-excel-btn-div{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.youtube-icon{
	color: red;
	font-size:31px !important;
}

.youtube-icon-li{
	margin-top: 5px !important;
}

.about-share-ul{
	display: flex !important;
	justify-content: flex-start !important;
	align-items: center !important;
}

.blog-banner-img-section{
	width: 90%;
	border: 2px dotted #FAFBFD;
	border-radius: 8px;
	height: 350px;
	padding: 10px;
	display: flex;
	justify-content: center;
	flex-direction: row;
	background-color: #ffffff;
}

.defalut-banner-img-div{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.banner-image-label-div{
	display: flex;
	justify-content: left;
	align-items: center;
}

.blog-banner-img-section img{
	width: 100% !important;
	height: 100% !important;
}

.blog-banner-edit-div{
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;
	width: 7%;
}

.blog-banner-div{
	display: flex;
	justify-content: center;
	align-items: center;
	width:90%;
	height:100%;
}

.blog-banner-img-section:hover{
	box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.width-50{
	width: 50% !important;
}

.width-80{
	width: 80% !important;
}

.blog-seo-title{
	font-style: italic !important;
}

.count-title-char-div{
	display: flex;
	width: 100%;
}

.blog-card-outer{
	display: flex;
    justify-content: center;
    max-height: 300px !important;
    height: 300px;
}

.blog-banner-img{
	width: 100% !important;
	height: 90% !important;
	max-width: 100% !important;
}

.width-100{
	width: 100%;
}

.blog-bothimage-div{
	display: flex;
	justify-content:center;
	width: 100%;
}

.width-30{
	width: 30% !important;
}

.width-70{
	width: 70% !important;
}

.blog-banner-img-div{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 350px;
	position: relative;
}

.blog-banner-img-div:hover{
	box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
	border:5px solid #FAFBFD;
	border-radius: 4px;
}

.background-color-white{
	background-color: #ffffff;
	border: 5px solid #FAFBFD;
}

.blog-edit-items{
	display: flex;
    height: 100%;
    position: absolute;
    width: 100%;
    align-items: flex-start;
	right: 2em;
    top: 2em;
}

.blog-both-label-div{
	display: flex;
	width: 100%;
}

.blog-banner-label-div{
	display: flex;
	flex-direction: flex-start;
	width: 52%;
}

.blog-feature-label-div{
	display: flex;
	width: 100%;
	flex-direction: flex-start;
}

.both-banner-feature-div{
	display: flex;
	flex-direction: row-reverse;
}

.title-blog-link{
	white-space: inherit !important;
}

.role-button {
	display: flex;
	justify-content: center;
	align-items: center;
}

@media(max-width: 768px){
	.role-button {
		justify-content: left;
		align-items: center;
	}
}

.delete-role {
	cursor: pointer;
}

.usercreate-button {
	display: flex;
	justify-content: center;
	align-items: center;
}

.edit-user {
	cursor: pointer;
}

.loader-users {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 90%;
	height: 55vh;
}

.roles-table {
	width: 80%;
}

.view-password-user {
    position:absolute;
	margin-top: 2.5%;
	margin-left: 94%;
	color: #0A043B;
	cursor: pointer;	
}

.tjp-user-div {
	background-image: url(/img/bg-image.jpg);
	height: 60vw;
}

.outer-login-div {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
	background-image: url(/img/bg-image.jpg);
}

.main_div {
    width: 365px;
    background: #fff;
    padding: 15px 30px 30px;
    border-radius: 5px;
    box-shadow: 5px 5px 10px rgb(0 0 0 / 15%);
}

.brand_logo_view {
    margin-top: 0;
    display: flex;
    justify-content: center;
}

.title {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    color: #0A043B;
    margin-top: 15px;
    text-transform: uppercase;
    text-decoration: none;
}

.input_box {
    height: 50px;
    width: 100%;
    position: relative;
    margin-top: 15px;
}

.view-password-login {
    position:absolute;
	margin-top: 4.5%;
	margin-left: 93%;
	color: #0A043B;
	cursor: pointer;	
}

.banner-div{
	display: flex;
	flex-direction: column;
	width: 100% !important;
	position: relative;
}

.blog-img-banner{
	display: flex;
	width:100%;
	justify-content: center;
	align-items: center;
	background-color: #ffffff;
	height: 350px;
	border-radius: 4px;
}

.blog-img-banner:hover{
	box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.edit-delete-btn-div{
	display: flex;
	position: absolute;
	top: 3rem;
	right:2rem;
	justify-content: flex-end;
	width: 100%;
}

.banner-img-new{
	height: 100% !important;
}

.blog-second-image-class{
	height: 100% !important;
	width: 70% !important;
}

.font-color-white{
	color: #ffffff;
}

.default-blog-author-img{
	font-size: 26px !important;
}

.edit-icon-blog-details{
	display: flex;
    width: 100%;
    position: absolute;
    align-items: flex-end;
	right: 5em;
}

@media (max-width: 768px) {
	.edit-delete-btn-div-responsive{
		right: 3em;
		margin-top: 70px;
	}
	.blog-edit-items-responsive{
		justify-content: flex-end !important;
	}
	.blog-banner-label-div{
		width: 100% !important;
	}
	.author-image-responsive{
		margin-left:10px !important;
		display: flex;
		justify-content: center;
		width: 100%;
	}
}

.both-feature-image-wrapper{
	display: flex;
	width: 100%;
	flex-direction: row;
}

.feature-image-wrapper{
	display: flex;
	width: 35%;
	flex-direction: column;
}

.second-feature-image-wrapper{
	display: flex;
	width: 65%;
	flex-direction: column;
}

@media (max-width: 768px){
	.feature-image-wrapper{
		width: 100%;
	}
	.second-feature-image-wrapper{
		width: 100%;
	}
	.both-feature-image-wrapper{
		flex-wrap: wrap;
	}
}

.add-photo-edit-icon{
	right: 0.5em !important;
	top: 0 !important;
}

.company-gallery-image-div{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100% !important;
	height: 100% !important;
}

.margin-top-10{
	margin-top:-10px !important; 
}

.blog-unsubscription-div{
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 200px;
}

.blog-unsubscription-div p{
	font-size: 40px;
	color: #0A043B;;
	font-weight: 600;
	padding-bottom: 30px;
}

.brand-banner-tata {
	width: 100%;
	position: relative;	
}

.tjp-banner {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 70vw;
	height: 165px;
	background-color: #15565d;
	padding: 20px;
	border-radius: 10px;
	margin-bottom:15px;
}
  
.logo {
	width: 15%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 10px;
	margin-right: 10px;
}
  
.logo img {
	width: 160px;
	height: auto;
}
  
.banner-divider {
	width: 1px;
	height: 100%;
	background-color: #ffffff;
	margin-left: 10px;
	margin-right: 10px;
}
  
.banner-content {
	width: 70%;
	height: 100%;
	padding-left: 10px;
	padding-right: 11%;
	text-align: left;
	margin-top: 2%;
}
  
.banner-content h5 {
	color: #ffffff;
	font-weight: 500 !important;
	font-size: 19px;
}
  
.banner-content p {
	color: #ffffff;
	font-weight: 400 !important;
}
  
.banner-btn-view {
	width: 90px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 30px;
}
  
.banner-btn-view button {
	color: #15565d;
	width: 100%;
	font-size: 10px;
	border-radius: 20px;
	background-color: #ffffff;
	outline: none;
	border: none;
	cursor: pointer;
	height: 36.9px;
	font-size: 14px !important;
}

@media (max-width: 768px) {
	.tjp-banner {
		display: grid;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 10%;
		background-color: #15565d;
		border-radius: 10px;
	}

	.logo {
		width: 100%;
		display: block;
	}

	.banner-divider {
		width: 100%;
		height: 1px;
		background-color: #ffffff;
		margin-left: 5px;
		margin-right: 5px;
		margin-top: 2%;
	}

	.banner-content {
		width: 110%;
		height: 100%;
		padding-left: 5px;
		text-align: center;
		margin-top: 5%;
	}

	.banner-content p {
		margin-top: 2% !important;
	}

	.banner-btn-view {
		width: 100% !important;
		display: flex;
		width: 90px;
		justify-content: center;
	}

	.banner-btn-view button {
		width: 30%;
	}
}


.plus-initiative-outer-wrapper{
	display: flex;
	width: 100%;
	flex-direction: row;
	height: 500px;
	justify-content: center;
	align-items: center;
	background-color: #ffffff;
}

.plus-image-div{
	display: flex;
	width: 50%;
	justify-content: center;
	align-items: center;
	padding: 10px;
}

.plus-initiative-para-outer-div{
	display: flex;
	width: 40%;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding:10px
}

.highlights-of-initiative-outer-div{
	display: flex;
	width: 100%;
	height: 350px;
	background-color: #FAFBFD;
	flex-direction: column;
}

.highlights-heading{
	display: flex;
	justify-content: left;
	width:100%;
	padding-top:25px;
	padding-left: 25px;
}

.highlights-box-div{
	display: flex;
	padding-top: 20px;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	width: 100%;
}

.highlights-second-box-div{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 200px;
	width:25%;
	border: 4px solid #D6D8DA;
	border-radius: 20px;
	box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
	background-color: #ffffff;
	margin-left: 22px;
	margin-right: 22px;
	flex-direction: column;
}
.hightlight-box-element{
	color: #66C9CB;
	font-size: 80px;
}

.box-element-text{
	font-size: 30px;
	color: #000000;
	margin-top:-22px;
	letter-spacing: -0.9px;
}

.highlights-first-box-div{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 20%;
	height: 160px;
	border-radius: 20px;
	box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
	background-color: #ffffff;
	flex-direction: column;
	border: 4px solid #D6D8DA;
}

.highlight-first-box-element{
	color: #66C9CB;
	font-size: 28px;
}

.first-box-element-text{
	font-size: 21px;
	color: #000000;
	padding-left: 35px;
	line-height:20.2px;
}

.first-box-element-text-div{
	width: 100%;
	justify-content: center;
	align-items: center;
}

.highlights-third-box-div{
	display: flex;
	justify-content: center;
	align-items: center;
	border: 4px solid #D6D8DA;
	border-radius: 20px;
	box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
	background-color: #ffffff;
	flex-direction: column;
	width: 20%;
	height: 160px;
}

.third-box-element-text{
	font-size: 21px;
	color: #000000;
	margin-top:-10px;
}

.vision-of-initiative-outer-wrapper{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	background-color: #ffffff;
	height: 400px;
}

.plus-heading-div{
	display: flex;
	justify-content: left;
	align-items: center;
	width:100%;
}

.plus-initiative-para{
	color: #000000;
	font-size: 14px;
}

.vision-image-div{
	display: flex;
	width: 55%;
	justify-content: center;
	align-items: center;
	padding: 10px;
}

.vision-para-outer{
	display: flex;
	width: 40%;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding-right:20px;
}

.vision-initiative-heading{
	display: flex;
	justify-content: left;
	width: 100%;
	padding-bottom: 20px;
}

.about-job-plus-wrapper{
	display: flex;
	justify-content: center;
	width: 100%;
	align-items: center;
}

.about-job-plus-para-outer{
	display: flex;
	justify-content: center;
	width: 55%;
	padding: 10px;
	flex-direction: column;
	padding-right:25px;
}

.about-job-plus-image-div{
	display: flex;
	justify-content:center;
	width: 30%;
	padding: 10px;
}

.tourism-hospitality-skill-wrapper{
	display: flex;
	width: 65%;
	justify-content: center;
}

.tourism-image-div{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30%;
	padding:10px;
}

.tourism-para-div{
	display:flex;
	width: 65%;
	justify-content: center;
	flex-direction: column;
	padding: 10px;
}

.plus-carousel-img{
	height: 200px !important;
	width: 275px !important;
}

/* .photo-gallery-heading{
	margin-left: 5vw;
} */

.plus-project-wrapper{
	display: flex;
	width: 100%;
	justify-content: center;
	height: 400px;
}

.plus-project-para-outer{
	display: flex;
	justify-content: center;
	width: 35%;
	padding:10px;
	flex-direction: column;
}

.plus-project-images-div{
	display: flex;
	justify-content: center;
	align-items: center;
	width:55%;
	padding: 10px;
}

.plus-oberoi-marriot-div{
	display: flex;
	width:100%;
	padding-top:20px;
}

.plus-oberoi-marriot-div img{
	width: 186px;
	height: auto !important;
}

.vertical-line-separtor{
	border-left: 2px solid #CCCCCC;
  	height: 85px;
	padding-right: 5px;
}

.padding-right-5{
	padding-right: 5px;
}

.plus-partner-button-div{
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	padding-top: 20px;
	height: 220px;
	background-color: #0A043B;
	flex-direction: column;
}

.plus-initiative-video-outer{
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	height: 450px !important;
	background-color: #FAFBFD;
}

.plus-initiative-video-outer div{
	display: flex;
	justify-content: center;
	align-items: center;
	width:100% !important;
	height:100% !important;
}

@media (max-width: 768px){
	.plus-initiative-outer-wrapper{
		flex-direction: column-reverse;
		height: auto;
	}
	.plus-image-div{
		width: 100%;
	}
	.plus-initiative-para-outer-div{
		width: 100%;
	}
	.highlights-box-div{
		flex-direction: column;
		height: auto;
	}
	.highlights-second-box-div{
		width: 90%;
		margin-bottom:10px;
	}
	.highlights-first-box-div{
		width:75%;
		margin-bottom:10px;
	}
	.highlights-third-box-div{
		width:75%;
		margin-bottom:10px;
	}
	.plus-project-para-outer{
		width: 100%;
	}
	.plus-project-wrapper{
		flex-direction: column;
		height: auto;
	}
	.highlights-of-initiative-outer-div{
		height: auto;
	}
	.plus-project-images-div{
		flex-direction: column;
		width:100%;
	}
	.vision-of-initiative-outer-wrapper{
		flex-direction: column-reverse;
		width: 100%;
		height: auto;
	}
	.vision-para-outer{
		width: 100%;
		padding-left:0px;
	}
	.vision-image-div{
		width: 100%;
	}
	.photo-gallery-heading{
		margin-left: 0;
	}
	.about-job-plus-wrapper{
		flex-direction: column;
	}
	.about-job-plus-para-outer{
		width:100%;
	}
	.about-job-plus-image-div{
		width: 100%;
	}
	.tourism-hospitality-skill-wrapper{
		flex-direction: row;
		width: 90%;
		margin-top: 10px;
	}
	.tourism-para-div{
		width: 100%;
	}
	.tourism-image-div{
		width: 50%;
	}
	.plus-westin-image{
		padding:0px;
		padding-bottom: 10px;
	}
	.plus-oberoi-marriot-div{
		justify-content: center;
	}
	.plus-dubai-image{
		padding: 0px;
	}
	.plus-initiative-video-outer{
		height: 250px;
	}
}

@media (max-width: 350px){
	.plus-oberoi-marriot-div{
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}

/* .plus-parallex-carousel-outer{
	display: flex;
	justify-content: center;
	align-items: center;
	width:100%;
}

.plus-parallex-carousel-outer .rec-dot_active{
	background-color: #0A043B !important;
	color: #0A043B !important;
	border-color: #0A043B !important;
} */

.section-parallex-carousel{
	margin-top: 100px;
}

.plus-carousel-first{
	width:100% !important;
	height: 400px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #FAFBFD;
}
.plus-carousel-logo{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 20px;
	background-color: #ffffff;
	border: 5px solid #0A043B;
	border-radius: 50%;
	height: 200px;
	width: 200px;
}

.plus-carousel-text{
	display: flex;
	justify-content:center;
	align-items: center;
	width: 55%;
	padding: 20px;
}

.initiative-logo-image{
	height: 50px !important;
	width: auto !important;
}

.plus-carousel-para{
	font-size: 24px;
	line-height: initial;
	color: #ffffff;
}

.plus-banner-text-div span{
	color: #ffffff;
	font-weight: 600!important;
	font-size: 24px;
}
.plus-parallex-carousel-outer .krmNah{
	box-shadow: 0 0 1px 3px #0A043B !important;
}

@media (max-width: 768px){
	.plus-carousel-para{
		font-size: 18px !important;
	}
	.plus-carousel-text span{
		font-size: 18px !important;
	}
	.initiative-logo-image {
		width:60px !important;
		height:60px !important;
	}
	.section-parallex-carousel{
		margin-top: 0px;
		padding:10px 0px 10px 0px;
	}
	/* .plus-carousel-first{
		height: 280px;
		flex-wrap: wrap;
	} */
	.plus-carousel-img{
		width: 250px !important;
		height: 180px !important;
	}
}

@media (max-width: 400px){
	.plus-photo-gallary .plus-carousel-img{
		width: 250px !important;
		height: 180px !important;
	}
}

.navbar-plus-initiative-menu{
	margin-left: -3px !important;
}

.plus-oberoi-marriot-outer-div{
	display: flex;
	width: 82%;
	padding-top:20px;
	height: 80px;
}

@media (max-width: 768px){
	.plus-oberoi-marriot-outer-div{
		width: 100%;
	}
}

.partner-us-modal {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
    position: absolute !important;
	flex-direction: column;
	width: 50vw;
	left: 25%;
    top: 0;
    right: auto;
    bottom: auto; 
    background-color: white;
	opacity: 1;
	box-shadow: 1px 4px 29px -4px rgba(18,17,17,0.82);
	-webkit-box-shadow: 1px 4px 29px -4px rgba(18,17,17,0.82);
	-moz-box-shadow: 1px 4px 29px -4px rgba(18,17,17,0.82);
}

@media (max-width: 768px) {
	.partner-us-modal {
		left: 5vw;
		width: 90vw;
	}

	.job-details-icon-div {
		width: 24vw;
	}

}

.padding-left15{
	padding-left: 15px;
}

.plus-thsc-para{
	padding-left: 27px;
}

@media (max-width: 768px){
	.highlights-div-box{
		display: flex;
		justify-content: center;
		align-items: center;
		height: 550px !important;
		width: 100% !important;	
	}
	.empty-highlight-div{
		margin-bottom: 0px!important;
	}
}

@media (max-width: 350px){
	.first-box-element-text {
		font-size: 16px;
	}
	.third-box-element-text{
		font-size: 16px;
	}
	.highlight-first-box-element{
		font-size: 25px;
	}
	.hightlight-box-element{
		font-size: 60px;
	}
	.box-element-text {
		font-size: 24px;
	}
}

.plus-enquiry-outer{
	display: flex;
	justify-content: center;
	align-items: center;
	width:100%;
	margin-bottom: 20px;
}

.brand-color{
	color: #0A043B !important;
}

@media (max-width: 768px){
	.plus-enquiry-table{
		width:50%;
		overflow-y: scroll;
	}
	.plus-enquiry-outer{
		display: block;
		overflow-x: scroll;
	}
	.partner-modal-div{
		height: auto !important;
	}
}

.plus-video-player{
	width: 100%;
	height: 300px;
	min-width: 100%;
	min-height: 100%;
}

@media (max-width: 350px){
	.partner-us-modal {
		top:5%;
		height: 500px;
		overflow-y: scroll;
	}
}

.blog-details-html-jsx {
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	font-size: 14px !important;
	font-style: normal !important;
	font-weight: 400 !important;
	text-align: justify !important;
}

.blog-details-html-jsx .mce-content-body .MsoNormal > *,
.blog-details-html-jsx .mce-content-body p > * {
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	font-size: 14px !important;
	font-weight: 400 !important;
	line-height: 1.5 !important;
}

.blog-details-html-jsx .mce-content-body .MsoNormal > span > span > a ,
.blog-details-html-jsx .mce-content-body p > span > span > a  {
	color: #3366CC !important;
}

.blog-details-html-jsx .mce-content-body h2, 
.blog-details-html-jsx .mce-content-body .heading-2 {
	font-weight: 600 !important;
	line-height: 1.20833 !important;
}

.blog-details-html-jsx .mce-content-body p+h2, 
.blog-details-html-jsx .mce-content-body p+.heading-2 {
	margin-top: 0px !important;
}

.blog-details-html-jsx .mce-content-body h2 > span > *, 
.blog-details-html-jsx .mce-content-body .heading-2 > span > *
{
	font-weight: 600 !important;
	font-size: 18px !important;
}

.blog-details-html-jsx .mce-content-body h3 > span > *, 
.blog-details-html-jsx .mce-content-body .heading-3 > span > *
{
	font-weight: 600 !important;
	font-size: 15px !important;
}

.blog-details-html-jsx a,
.blog-details-html-jsx a > * {
	color: #3366CC !important
}

.plus-heading-div h1{
	font-weight: 500;
    font-family: Rubik,Calibri,Trebuchet MS,sans-serif!important;
    font-size: 24px;
}
@media (max-width: 768px){
	.plus-heading-div h1{
		font-size: 18px;
	}
}
.plus-para-div{
	margin-top:3px;
}

.blog-details-html-jsx .mce-content-body h2 > span > span > *, 
.blog-details-html-jsx .mce-content-body .heading-2 > span > span >  *
{
	font-weight: 600 !important;
	font-size: 18px !important;
}

.blog-details-html-jsx .mce-content-body p > strong > span > * {
	font-weight: 600 !important;
}

.blog-details-html-jsx .mce-content-body h2 > strong > span > *, 
.blog-details-html-jsx .mce-content-body .heading-2 > strong > span >  *
{
	font-weight: 600 !important;
	font-size: 18px !important;
}

.plus-banner-outer-div{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 200px;
}

.plus-banner-div{
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	height: 120px;
	border: 2px solid #0A043B;
	background: #0A043B;
	padding: 10px;
}

.plus-banner-text-div{
	display: flex;
	justify-content: right;
	flex-direction: end;
	width: 75%;
	padding-right: 30px;
}

.plus-banner-combine-image-div{
	display: flex;
	justify-content: center;
	width: auto;
	align-items: center;
	padding-right: 25px;
}

.highlights-service-outer-div{
	display: flex;
	justify-content: space-between !important;
	width: 100%;
	align-items: center;
	padding: 15px;
}

.hightlight-div{
	display: flex;
	justify-content: center;
	padding:10px;
}

.hightlight-blue-text {
	display: flex;
	justify-content: center;
	width: 100%;
	align-items: center;
	flex-direction: column;
}

.highlight-blue-para{
	font-weight: 600 !important;
	font-size: 22px;
	color: #5B9BD6;
	text-align: center;
	line-height: normal;
}

.hightlight-service-text{
	color: #000000;
	text-align: center;
	margin-top:0px !important;
}

.tourism-hospitality-outer-wrapper{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.affiliations-div{
	display: flex;
	justify-content: center;
	align-items: center;
	padding-right: 25px;
}

.affiliations-div p{
	font-size: 22px;
	color: #000000;
	font-weight: 600 !important;
}

.tourism-para p{
	font-size: 13px !important;
}

.tourism-heading h4{
	font-size: 18px !important;
}

.affiliation-vertical-line{
	border: 2px solid #0A043B;
	height: 150px;
	background-color: #0A043B;
}

.plus-partner-text-div{
	padding-bottom:10px;
}

.plus-partner-text-div p{
	font-size: 22px;
	color: #ffffff;
	font-weight: 500;
}

.partnter-btn{
	color: #000000 !important;
	font-weight: 700 !important;
	padding: 15px 100px 15px 100px; 
	border-radius: 10px;
	background-color: #ffffff;
}

.btn-text-partner{
	font-weight: 500;
	font-size: 18px;
	text-transform: none;
}

.what-we-do-initiative-wrapper{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	flex-direction: row;
	padding: 10px;
}

.plus-clients-wrapper{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	flex-direction: row;
	padding: 10px;
}

.clients-images-div{
	display: flex;
	align-items: center;
	width: 70%;
	padding: 10px 10px 10px 5px;
}

.active-complete-project-outer-div{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 30%;
	padding: 10px;
}

.plus-westin-image{
	width: auto;
	height: 200px;
}

.plus-dubai-image{
	width: auto;
	height: 200px;
}
.active-complete-heading{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.active-complete-heading p{
	font-size: 22px;
	font-weight: 600;
	color: #000000;
	text-align: center;
	line-height: initial;
} 

.active-complete-para p{
	color: #000000;
	text-align:center;
}

.logo-class-oberoi{
	position: absolute;
}
.combine-image-class{
	height: 45px;
	width: auto;
}

.what-we-do-outer-div {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	background-color: #FAFBFD;
	flex-direction: column;
	min-height: 400px;
	padding: 20px;
}

.what-we-do-heading-div {
	display: flex;
	justify-content: flex-start;
	width: 100%;
}

.what-we-do-column-div{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	width: 100%;
}

.recruit-circle {
	border-radius: 10px;
	margin-bottom: 1.5%;
	width: 125px;
	height: 125px;
	border: 4px solid #0a043b;
	background-color: #0a043b;
	border-radius: 50%;
	text-align: center; 
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
}

.recruit-circle h4 {
	color: #FFFFFF;
}
.what-we-do-column-div ol {
	list-style-type: upper-roman !important;
}

.what-wedo-first-div li{
	padding-left:20px;
	color: #000000;
}

.what-we-do-column-div h3{
	text-align: center;
	font-weight: 600;
	margin: 10px o;
}

.what-wedo-first-div{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 33%;
	padding: 15px
}

.what-wedo-third-div{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 35%;
}

.what-wedo-third-div li{
	padding-left:20px;
	color: #000000;
}

.what-we-do-column-div h4{
	line-height: initial !important;
}

.plus-images-client-div{
	display: flex;
	justify-content: center;
	align-items: flex-end;
	flex-direction: column;
	/* position: relative; */
}

.comine-logo-class{
	display: flex;
	justify-content: center;
	/* position: absolute; */
	background-color: #ffffff;
	height: 45px;
	width: 100%;
	margin-bottom: 15px;
	padding-top:5px;
	padding-bottom: 5px;
	/* z-index: 99999; */
}

.client-oberoi-image-class{
	width: 130px;
	height: auto;
}

.client-mahindra-image-class{
	width: 65px;
	height: auto;
}

.client-marriott-image-class{
	width: 80px !important;
	height: auto !important;
}

@media (max-width: 768px){
	.vision-of-initiative-outer-wrapper{
		flex-direction: column;
	}
	.what-we-do-column-div{
		flex-direction: column;
	}
	.what-wedo-first-div{
		width: 100%;
		flex-direction: row;
		padding: 0px;
	}
	.what-wedo-third-div{
		width: 100%;
		flex-direction: row;
	}
	.recruit-circle{
		width: 75px !important;
		max-width: 75px !important;
		min-width: 75px !important;
		height: 75px !important;
		margin-right: 30px;
		margin-top: -60px !important;
	}
	.photo-gallery-heading-h4-div {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.what-we-do-column-div h4{
		font-size:15px;
	}
	.what-we-do-column-div ol{
		margin:10px 0px 10px 0px;
	}
	.what-wedo-first-div li {
		padding-left: 10px;
		line-height: normal !important;
	}
	.what-wedo-third-div li{
		padding-left:10px;
		line-height: normal !important;
	}
	.clients-images-div{
		width: 100%;
		padding:0px;
	}
	.plus-images-client-div{
		width:100% ;
	}
	.plus-clients-wrapper{
		flex-direction: column;
		padding-top: 10px;
	}
	.active-complete-project-outer-div{
		width:100%;
	}
	.active-complete-heading p {
		font-size: 14px;
		font-weight: 600;
	}
	.plus-westin-image{
		width:110px !important;
		height:110px;
		padding:2px;
	}
	.comine-logo-class{
		height:20px;
		padding:0px;
	}
	.plus-dubai-image{
		padding: 2px;
		width: 110px;
		height: 110px;
	}
	.client-oberoi-image-class{
		width: 80px;
	}
	.client-marriott-image-class{
		width: 50px !important;
	}
	.highlights-service-outer-div {
		flex-wrap: wrap;
		align-items: flex-start;
		padding: 10px 0px;
	}
	.plus-partner-text-div p {
		font-size: 16px;
		text-align: center;
	}
	.plus-partner-text-div{
		width:90%;
		padding-bottom:5px;
	}
	.partnter-btn{
		padding: 15px 35px 15px 35px;
	}
	.btn-text-partner {
		font-size:15px;
	}
	.affiliations-div{
		width:25%;
		padding-right:5px;
	}
	.affiliations-div p {
		font-size:14px;
	}
	.tourism-heading h4{
		font-size:12px !important;
	}
	.highlight-blue-para{
		font-size: 14px;
	}
	.hightlight-div{
		width:33%;
		padding: 10px 0px;
	}
	.hightlight-service-text{
		font-size:14px;
		line-height: normal;
		margin-top:5px !important;
	}
	.responsive-highlight-div{
		width: 50%;
	}
	.plus-carousel-para{
		font-size: 18px;
	}
	.plus-banner-text-div span{
		font-size: 18px;
	}
	.plus-carousel-logo {
		height: 100px;
		width:100px;
		border: 6px solid #0A043B;
	}
	.plus-banner-div {
		height: 180px;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 10px;
	}
	.plus-banner-combine-image-div {
		padding-right: 10px;
	}
	.plus-banner-text-div{
		padding:0px;
	}
	.plus-banner-outer-div{
		justify-content: flex-start;
		align-items: center;
	}
	.tourism-hospitality-outer-wrapper{
		flex-direction: column;
	}
	.tourism-para p{
		font-size: 11px !important;
	}
	.affiliation-vertical-line{
		border: 2px solid #0A043B;
		height: 2px;
		background-color:#0A043B;
		width:40%;
	}
	.padding-top-0 {
		padding-top: 0px !important;
	}

	.what-we-do-outer-div {
		padding: 5px;
	}
}

.partner-us-modal{
	top: 17%;
	height: 75vh;
	overflow-y: scroll;
	padding: 10px;
}

.section-background-color{
	background-color: #FAFBFD;
}

.partner-section{
	padding-bottom: 0px;
}

.what-we-do-heading{
	padding-left: 20px !important;
}

@media (max-width: 768px){
	.what-we-do-heading{
		padding-left: 0px !important;
	}	
	.vision-initiative-heading{
		padding-bottom: 0px;
		display:flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
	}
	.plus-client-section{
		padding-top: 25px;
		padding-bottom: 10px;
	}
	.plus-highlight-section{
		padding-top: 0px;
	}
	.plus-joint-image{
		padding-top:3px !important;
		padding-bottom: 0px !important;
	}
	.client-mahindra-image-class{
		width: 45px;
	}
}

.plus-inquiry-show-more{
	padding-bottom: 20px;
}

.partnter-btn:hover , .partnter-btn:active{
	background-color: #279f8d;
	color: #ffffff !important;
	border-color: #279f8d;
}

@media (min-width: 768px){
	.plus-banner-outer-div{
		height: 225px;
	}
	.jumbotron-creative-inner{
		margin-top: 100px;
	}
}

@media (max-width: 768px){
	.header-login-view{
		justify-content: center;
		align-items: center;
	}
}

.new-banner-login-btn{
	border: 3px solid #ffffff !important;
}

.sign-btn-p-text{
	font-weight: 500;
}

@media (min-width: 768px){
	.vertical-line-new-banner{
		width:3px;
		height: 300px;
		background-color: #FA5F2A;
		margin-right: 10px;
		margin-top: 100px;
	}
}

.password-view-new{
	margin-top: 11% !important;
	margin-left: 42% !important;
}

@media (max-width: 768px){
	.password-view-new{
		margin-top: 13% !important;
		margin-left: 41% !important;
	}
}

.contact-form-div {
	border-radius: 60px;
	border: 2px solid #D6D8DA;
	padding: 50px;
	width: 90%;
	margin-left: 30px;
}
.option-contact-div {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	padding: 20px;
	width: 100%;
}
.no-option-form {
	margin-top: 19%;
	margin-bottom: 19%;
	text-align: center;
}
.send-message-div {
	display: flex;
	align-items: center;
	justify-content: center;
}
.contact-form-outer-div{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}
.contact-form-input-wrap{
	display: flex !important;
}
.contact-form-input-wrap span{
	margin-top: -10px !important;
}
.radio-contact input {
	min-height: 23px;
}
#default-flag-contact {
	margin-top: -10px;
	min-height: 23px;
	cursor: pointer;
}
.resume-contact-div {
	display: flex;
}
.file-contact-view {
	padding: 0px 10px;
}
.resume-contact-title {
	font-size: 14px;
	color: #000000;
	font-weight: 700;
}
@media (max-width: 768px) {
	.option-contact-div {
		display: block;
		padding: 20px;
		width: 100%;		
	}
	.contact-form-div {
		padding: 25px;
		width: 100%;
		margin-left: 0px;
	}
}

.h4-contact {
	cursor: auto !important;
}

.job-card-border-class{
	border: 2px solid #0A043B;
}

.search-offline-toogle-div{
	color: #0A043B;
	font-size: 30px;
}

.offline-job-row-class{
	background-color:  #D3D3D3 !important;
}

.login-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	background-image: url(/img/bg-image.jpg);
	margin-top: 100px;
}

.logged-menu {
	font-size: 16px;
    text-transform: uppercase;
	color: #150F3D;
}
.blue-header {
	height: 217px;
	text-align: center;
}

.contact-heading-text {
	font-family: "Rubik";
	font-style: normal;
	color: #ffffff;
	font-size: 64px;
	font-weight: 600;
	text-align: center;
	line-height: 76px;
	/* width: 300px; */
	margin-top: 7%;
  }

  @media (max-width: 768px) {
	.contact-heading-text {
	  font-size: 40px;
	}

	.blue-header{
		height: auto;
	}
  }

  .hero-services {
	width: 1600px;
	max-width: 100%;
	margin-top: 7%;
	display: flex;
	justify-content: center;
	align-items: center;
  }
  @media (max-width: 768px) {
	.hero-services {
	  width: 100%;
	}
	.hero-services img {
		height: 280px !important;
	}
  }
  .hero-services img {
	width: 1600px;
	height: 350px;
	object-fit: cover;
  }
  .hero-services .hero-content-services {
	position: absolute;
	margin-top: 8%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	text-align: center;
	color: #FFFFFF !important;
  }
  @media (max-width: 768px) {
	.hero-services .hero-content-services {
	  margin-top: 45%;
	}
  }
  .hero-services .hero-content-services h1 {
	font-size: 64px;
	font-weight: 600;
	margin-bottom: 20px;
	line-height: 76px;
	color: #FFFFFF;
  }
  @media (max-width: 768px) {
	.hero-services .hero-content-services h1 {
	  font-size: 40px;
	  line-height: 40px;
	}
  }
  .hero-services .hero-content-services p {
	font-size: 24px;
	color: #FFFFFF;
	font-weight: 500;
	line-height: 156%;
  }
  @media (max-width: 768px) {
	.hero-services .hero-content-services p {
	  font-size: 16px;
	}

	.options-mobile {
		margin-left: 18px;
	}
  }

@media(max-height: 600px){
	.rd-navbar-static .rd-menu {
		height: 75vh;
		overflow-y: scroll;
	}
  }

  .verification-div {
	display: flex;
  	justify-content: left;
	margin-left: -8px;
  }

  .verification-div button {
	margin: 0 10px;
  }

@media(max-width:768px) {
	.job-title-label-one {
		width: 100% !important;
	}

	.recruiter-info-view {
		width: 75% !important;
	}

	.recruiter-item-info-view{
		flex-wrap: wrap !important;
	}
}
  .job-title-label-one {
	font-style: normal;
	font-weight: 500;
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	font-size: 14px;
	line-height: 150%;
	color: black;
	text-transform: capitalize;
}

.loadingCSS-login{
	display: flex;
	justify-content: center;
	align-items: center;

}

.clear-icon {
	font-size: 25px !important;
	color: #0A043B;
	cursor: pointer;
}

.employer-invitation-modal {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	position: absolute !important;
	flex-direction: column;
	width: 40vw;
	left: 30%;
	top: 25%;
	right: auto;
	bottom: auto;
	background-color: white;
	opacity: 1;
	box-shadow: 1px 4px 29px -4px rgba(18, 17, 17, 0.82);
	-webkit-box-shadow: 1px 4px 29px -4px rgba(18, 17, 17, 0.82);
	-moz-box-shadow: 1px 4px 29px -4px rgba(18, 17, 17, 0.82);
	z-index: 1000;
}

@media (max-width: 768px) {
	.employer-invitation-modal {
		left: 3vw;
		width: 90vw;
	}

	.job-details-icon-div {
		width: 24vw;
	}
}

.employer-invitation-form {
	background-color: white;
	width: 100%;
}

.input-div-invitation {
	width: 100%;
	padding: 30px;
}

.loadingCSS-invitation {
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal-footer-invitation {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1rem;
	border-top: 1px solid #e9ecef;
	margin-right: 3%;
}

.button-invitation {
	margin-top: 0px;
}

.whatsapp-invitation {
	cursor: pointer;
}

.title-bar-heading-div{
	margin-top: 0px !important;
	padding-top: 4px !important;
	padding-bottom: 4px !important;
}

.create-company-video-icons{
	position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
	width: 70%;
	margin-top: -23%;
	height: auto;
}

@media(min-height: 650px) and (max-height: 800px){
	.rd-navbar-static .rd-menu-admin {
		height: 75vh !important;
		overflow-y: scroll !important;
	}
}
/*
*
* Fonts
*/
/*
*
* Font Awesome Icons 4.7.0
* --------------------------------------------------
*/
@font-face {
	font-family: 'FontAwesome';
	src: url("/fonts/fontawesome-webfont.eot?v=4.7.0");
	src: url("/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0") format("embedded-opentype"), url("/fonts/fontawesome-webfont.woff2?v=4.7.0") format("woff2"), url("/fonts/fontawesome-webfont.woff?v=4.7.0") format("woff"), url("/fonts/fontawesome-webfont.ttf?v=4.7.0") format("truetype"), url("/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
	font-weight: normal;
	font-style: normal;
}
@font-face {
    font-family: 'rubiklight_italic';
    src: url('/fonts/rubik-italic-variablefont_wght-webfont.woff2') format('woff2'),
         url('/fonts/rubik-italic-variablefont_wght-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'rubiklight';
    src: url('/fonts/rubik-variablefont_wght-webfont.woff2') format('woff2'),
         url('/fonts/rubik-variablefont_wght-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
/* @font-face {
    font-family: 'Rubik';
    src: url('/fonts/Rubik-Bold.woff2') format('woff2'),
         url('/fonts/Rubik-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
} */

@font-face {
    font-family: 'Rubik';
    src: url('/fonts/Rubik-ExtraBoldItalic.woff2') format('woff2'),
        url('/fonts/Rubik-ExtraBoldItalic.woff') format('woff'),
        url('/fonts/Rubik-ExtraBoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('/fonts/Rubik-BlackItalic.woff2') format('woff2'),
        url('/fonts/Rubik-BlackItalic.woff') format('woff'),
        url('/fonts/Rubik-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('/fonts/Rubik-MediumItalic.woff2') format('woff2'),
        url('/fonts/Rubik-MediumItalic.woff') format('woff'),
        url('/fonts/Rubik-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('/fonts/Rubik-SemiBold.woff2') format('woff2'),
        url('/fonts/Rubik-SemiBold.woff') format('woff'),
        url('/fonts/Rubik-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('/fonts/Rubik-Bold.woff2') format('woff2'),
        url('/fonts/Rubik-Bold.woff') format('woff'),
        url('/fonts/Rubik-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('/fonts/Rubik-LightItalic.woff2') format('woff2'),
        url('/fonts/Rubik-LightItalic.woff') format('woff'),
        url('/fonts/Rubik-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('/fonts/Rubik-SemiBoldItalic.woff2') format('woff2'),
        url('/fonts/Rubik-SemiBoldItalic.woff') format('woff'),
        url('/fonts/Rubik-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('/fonts/Rubik-Black.woff2') format('woff2'),
        url('/fonts/Rubik-Black.woff') format('woff'),
        url('/fonts/Rubik-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('/fonts/Rubik-ExtraBold.woff2') format('woff2'),
        url('/fonts/Rubik-ExtraBold.woff') format('woff'),
        url('/fonts/Rubik-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('/fonts/Rubik-BoldItalic.woff2') format('woff2'),
        url('/fonts/Rubik-BoldItalic.woff') format('woff'),
        url('/fonts/Rubik-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('/fonts/Rubik-Italic.woff2') format('woff2'),
        url('/fonts/Rubik-Italic.woff') format('woff'),
        url('/fonts/Rubik-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('/fonts/Rubik-Regular.woff2') format('woff2'),
        url('/fonts/Rubik-Regular.woff') format('woff'),
        url('/fonts/Rubik-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('/fonts/Rubik-Medium.woff2') format('woff2'),
        url('/fonts/Rubik-Medium.woff') format('woff'),
        url('/fonts/Rubik-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('/fonts/Rubik-Light.woff2') format('woff2'),
        url('/fonts/Rubik-Light.woff') format('woff'),
        url('/fonts/Rubik-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

.fa {
	display: inline-block;
	font-family: 'FontAwesome';
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class*='fa-']:before {
	font-weight: 400;
	font-family: 'FontAwesome';
}

/* makes the font 33% larger relative to the icon container */
.fa-lg {
	font-size: 1.33333333em;
	line-height: 0.75em;
	vertical-align: -15%;
}

.fa-2x {
	font-size: 2em;
}

.fa-3x {
	font-size: 3em;
}

.fa-4x {
	font-size: 4em;
}

.fa-5x {
	font-size: 5em;
}

.fa-fw {
	width: 1.28571429em;
	text-align: center;
}

.fa-ul {
	padding-left: 0;
	margin-left: 2.14285714em;
	list-style-type: none;
}

.fa-ul > li {
	position: relative;
}

.fa-li {
	position: absolute;
	left: -2.14285714em;
	width: 2.14285714em;
	top: 0.14285714em;
	text-align: center;
}

.fa-li.fa-lg {
	left: -1.85714286em;
}

.fa-border {
	padding: .2em .25em .15em;
	border: solid 0.08em #eeeeee;
	border-radius: .1em;
}

.fa-pull-left {
	float: left;
}

.fa-pull-right {
	float: right;
}

.fa.fa-pull-left {
	margin-right: .3em;
}

.fa.fa-pull-right {
	margin-left: .3em;
}

/* Deprecated as of 4.4.0 */
.pull-right {
	float: right;
}

.pull-left {
	float: left;
}

.fa.pull-left {
	margin-right: .3em;
}

.fa.pull-right {
	margin-left: .3em;
}

.fa-spin {
	-webkit-animation: fa-spin 2s infinite linear;
	animation: fa-spin 2s infinite linear;
}

.fa-pulse {
	-webkit-animation: fa-spin 1s infinite steps(8);
	animation: fa-spin 1s infinite steps(8);
}

@-webkit-keyframes fa-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

@keyframes fa-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

.fa-rotate-90 {
	-ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
}

.fa-rotate-180 {
	-ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}

.fa-rotate-270 {
	-ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
	-webkit-transform: rotate(270deg);
	transform: rotate(270deg);
}

.fa-flip-horizontal {
	-ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
	-webkit-transform: scale(-1, 1);
	transform: scale(-1, 1);
}

.fa-flip-vertical {
	-ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
	-webkit-transform: scale(1, -1);
	transform: scale(1, -1);
}

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
	-webkit-filter: none;
	        filter: none;
}

.fa-stack {
	position: relative;
	display: inline-block;
	width: 2em;
	height: 2em;
	line-height: 2em;
	vertical-align: middle;
}

.fa-stack-1x,
.fa-stack-2x {
	position: absolute;
	left: 0;
	width: 100%;
	text-align: center;
}

.fa-stack-1x {
	line-height: inherit;
}

.fa-stack-2x {
	font-size: 2em;
}

.fa-inverse {
	color: #ffffff;
}

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */
.fa-glass:before {
	content: "\f000";
}

.fa-music:before {
	content: "\f001";
}

.fa-search:before {
	content: "\f002";
}

.fa-envelope-o:before {
	content: "\f003";
}

.fa-heart:before {
	content: "\f004";
}

.fa-star:before {
	content: "\f005";
}

.fa-star-o:before {
	content: "\f006";
}

.fa-user:before {
	content: "\f007";
}

.fa-film:before {
	content: "\f008";
}

.fa-th-large:before {
	content: "\f009";
}

.fa-th:before {
	content: "\f00a";
}

.fa-th-list:before {
	content: "\f00b";
}

.fa-check:before {
	content: "\f00c";
}

.fa-remove:before,
.fa-close:before,
.fa-times:before {
	content: "\f00d";
}

.fa-search-plus:before {
	content: "\f00e";
}

.fa-search-minus:before {
	content: "\f010";
}

.fa-power-off:before {
	content: "\f011";
}

.fa-signal:before {
	content: "\f012";
}

.fa-gear:before,
.fa-cog:before {
	content: "\f013";
}

.fa-trash-o:before {
	content: "\f014";
}

.fa-home:before {
	content: "\f015";
}

.fa-file-o:before {
	content: "\f016";
}

.fa-clock-o:before {
	content: "\f017";
}

.fa-road:before {
	content: "\f018";
}

.fa-download:before {
	content: "\f019";
}

.fa-arrow-circle-o-down:before {
	content: "\f01a";
}

.fa-arrow-circle-o-up:before {
	content: "\f01b";
}

.fa-inbox:before {
	content: "\f01c";
}

.fa-play-circle-o:before {
	content: "\f01d";
}

.fa-rotate-right:before,
.fa-repeat:before {
	content: "\f01e";
}

.fa-refresh:before {
	content: "\f021";
}

.fa-list-alt:before {
	content: "\f022";
}

.fa-lock:before {
	content: "\f023";
}

.fa-flag:before {
	content: "\f024";
}

.fa-headphones:before {
	content: "\f025";
}

.fa-volume-off:before {
	content: "\f026";
}

.fa-volume-down:before {
	content: "\f027";
}

.fa-volume-up:before {
	content: "\f028";
}

.fa-qrcode:before {
	content: "\f029";
}

.fa-barcode:before {
	content: "\f02a";
}

.fa-tag:before {
	content: "\f02b";
}

.fa-tags:before {
	content: "\f02c";
}

.fa-book:before {
	content: "\f02d";
}

.fa-bookmark:before {
	content: "\f02e";
}

.fa-print:before {
	content: "\f02f";
}

.fa-camera:before {
	content: "\f030";
}

.fa-font:before {
	content: "\f031";
}

.fa-bold:before {
	content: "\f032";
}

.fa-italic:before {
	content: "\f033";
}

.fa-text-height:before {
	content: "\f034";
}

.fa-text-width:before {
	content: "\f035";
}

.fa-align-left:before {
	content: "\f036";
}

.fa-align-center:before {
	content: "\f037";
}

.fa-align-right:before {
	content: "\f038";
}

.fa-align-justify:before {
	content: "\f039";
}

.fa-list:before {
	content: "\f03a";
}

.fa-dedent:before,
.fa-outdent:before {
	content: "\f03b";
}

.fa-indent:before {
	content: "\f03c";
}

.fa-video-camera:before {
	content: "\f03d";
}

.fa-photo:before,
.fa-image:before,
.fa-picture-o:before {
	content: "\f03e";
}

.fa-pencil:before {
	content: "\f040";
}

.fa-map-marker:before {
	content: "\f041";
}

.fa-adjust:before {
	content: "\f042";
}

.fa-tint:before {
	content: "\f043";
}

.fa-edit:before,
.fa-pencil-square-o:before {
	content: "\f044";
}

.fa-share-square-o:before {
	content: "\f045";
}

.fa-check-square-o:before {
	content: "\f046";
}

.fa-arrows:before {
	content: "\f047";
}

.fa-step-backward:before {
	content: "\f048";
}

.fa-fast-backward:before {
	content: "\f049";
}

.fa-backward:before {
	content: "\f04a";
}

.fa-play:before {
	content: "\f04b";
}

.fa-pause:before {
	content: "\f04c";
}

.fa-stop:before {
	content: "\f04d";
}

.fa-forward:before {
	content: "\f04e";
}

.fa-fast-forward:before {
	content: "\f050";
}

.fa-step-forward:before {
	content: "\f051";
}

.fa-eject:before {
	content: "\f052";
}

.fa-chevron-left:before {
	content: "\f053";
}

.fa-chevron-right:before {
	content: "\f054";
}

.fa-plus-circle:before {
	content: "\f055";
}

.fa-minus-circle:before {
	content: "\f056";
}

.fa-times-circle:before {
	content: "\f057";
}

.fa-check-circle:before {
	content: "\f058";
}

.fa-question-circle:before {
	content: "\f059";
}

.fa-info-circle:before {
	content: "\f05a";
}

.fa-crosshairs:before {
	content: "\f05b";
}

.fa-times-circle-o:before {
	content: "\f05c";
}

.fa-check-circle-o:before {
	content: "\f05d";
}

.fa-ban:before {
	content: "\f05e";
}

.fa-arrow-left:before {
	content: "\f060";
}

.fa-arrow-right:before {
	content: "\f061";
}

.fa-arrow-up:before {
	content: "\f062";
}

.fa-arrow-down:before {
	content: "\f063";
}

.fa-mail-forward:before,
.fa-share:before {
	content: "\f064";
}

.fa-expand:before {
	content: "\f065";
}

.fa-compress:before {
	content: "\f066";
}

.fa-plus:before {
	content: "\f067";
}

.fa-minus:before {
	content: "\f068";
}

.fa-asterisk:before {
	content: "\f069";
}

.fa-exclamation-circle:before {
	content: "\f06a";
}

.fa-gift:before {
	content: "\f06b";
}

.fa-leaf:before {
	content: "\f06c";
}

.fa-fire:before {
	content: "\f06d";
}

.fa-eye:before {
	content: "\f06e";
}

.fa-eye-slash:before {
	content: "\f070";
}

.fa-warning:before,
.fa-exclamation-triangle:before {
	content: "\f071";
}

.fa-plane:before {
	content: "\f072";
}

.fa-calendar:before {
	content: "\f073";
}

.fa-random:before {
	content: "\f074";
}

.fa-comment:before {
	content: "\f075";
}

.fa-magnet:before {
	content: "\f076";
}

.fa-chevron-up:before {
	content: "\f077";
}

.fa-chevron-down:before {
	content: "\f078";
}

.fa-retweet:before {
	content: "\f079";
}

.fa-shopping-cart:before {
	content: "\f07a";
}

.fa-folder:before {
	content: "\f07b";
}

.fa-folder-open:before {
	content: "\f07c";
}

.fa-arrows-v:before {
	content: "\f07d";
}

.fa-arrows-h:before {
	content: "\f07e";
}

.fa-bar-chart-o:before,
.fa-bar-chart:before {
	content: "\f080";
}

.fa-twitter-square:before {
	content: "\f081";
}

.fa-facebook-square:before {
	content: "\f082";
}

.fa-camera-retro:before {
	content: "\f083";
}

.fa-key:before {
	content: "\f084";
}

.fa-gears:before,
.fa-cogs:before {
	content: "\f085";
}

.fa-comments:before {
	content: "\f086";
}

.fa-thumbs-o-up:before {
	content: "\f087";
}

.fa-thumbs-o-down:before {
	content: "\f088";
}

.fa-star-half:before {
	content: "\f089";
}

.fa-heart-o:before {
	content: "\f08a";
}

.fa-sign-out:before {
	content: "\f08b";
}

.fa-linkedin-square:before {
	content: "\f08c";
}

.fa-thumb-tack:before {
	content: "\f08d";
}

.fa-external-link:before {
	content: "\f08e";
}

.fa-sign-in:before {
	content: "\f090";
}

.fa-trophy:before {
	content: "\f091";
}

.fa-github-square:before {
	content: "\f092";
}

.fa-upload:before {
	content: "\f093";
}

.fa-lemon-o:before {
	content: "\f094";
}

.fa-phone:before {
	content: "\f095";
}

.fa-square-o:before {
	content: "\f096";
}

.fa-bookmark-o:before {
	content: "\f097";
}

.fa-phone-square:before {
	content: "\f098";
}

.fa-twitter:before {
	content: "\f099";
}

.fa-facebook-f:before,
.fa-facebook:before {
	content: "\f09a";
}

.fa-github:before {
	content: "\f09b";
}

.fa-unlock:before {
	content: "\f09c";
}

.fa-credit-card:before {
	content: "\f09d";
}

.fa-feed:before,
.fa-rss:before {
	content: "\f09e";
}

.fa-hdd-o:before {
	content: "\f0a0";
}

.fa-bullhorn:before {
	content: "\f0a1";
}

.fa-bell:before {
	content: "\f0f3";
}

.fa-certificate:before {
	content: "\f0a3";
}

.fa-hand-o-right:before {
	content: "\f0a4";
}

.fa-hand-o-left:before {
	content: "\f0a5";
}

.fa-hand-o-up:before {
	content: "\f0a6";
}

.fa-hand-o-down:before {
	content: "\f0a7";
}

.fa-arrow-circle-left:before {
	content: "\f0a8";
}

.fa-arrow-circle-right:before {
	content: "\f0a9";
}

.fa-arrow-circle-up:before {
	content: "\f0aa";
}

.fa-arrow-circle-down:before {
	content: "\f0ab";
}

.fa-globe:before {
	content: "\f0ac";
}

.fa-wrench:before {
	content: "\f0ad";
}

.fa-tasks:before {
	content: "\f0ae";
}

.fa-filter:before {
	content: "\f0b0";
}

.fa-briefcase:before {
	content: "\f0b1";
}

.fa-arrows-alt:before {
	content: "\f0b2";
}

.fa-group:before,
.fa-users:before {
	content: "\f0c0";
}

.fa-chain:before,
.fa-link:before {
	content: "\f0c1";
}

.fa-cloud:before {
	content: "\f0c2";
}

.fa-flask:before {
	content: "\f0c3";
}

.fa-cut:before,
.fa-scissors:before {
	content: "\f0c4";
}

.fa-copy:before,
.fa-files-o:before {
	content: "\f0c5";
}

.fa-paperclip:before {
	content: "\f0c6";
}

.fa-save:before,
.fa-floppy-o:before {
	content: "\f0c7";
}

.fa-square:before {
	content: "\f0c8";
}

.fa-navicon:before,
.fa-reorder:before,
.fa-bars:before {
	content: "\f0c9";
}

.fa-list-ul:before {
	content: "\f0ca";
}

.fa-list-ol:before {
	content: "\f0cb";
}

.fa-strikethrough:before {
	content: "\f0cc";
}

.fa-underline:before {
	content: "\f0cd";
}

.fa-table:before {
	content: "\f0ce";
}

.fa-magic:before {
	content: "\f0d0";
}

.fa-truck:before {
	content: "\f0d1";
}

.fa-pinterest:before {
	content: "\f0d2";
}

.fa-pinterest-square:before {
	content: "\f0d3";
}

.fa-google-plus-square:before {
	content: "\f0d4";
}

.fa-google-plus:before {
	content: "\f0d5";
}

.fa-money:before {
	content: "\f0d6";
}

.fa-caret-down:before {
	content: "\f0d7";
}

.fa-caret-up:before {
	content: "\f0d8";
}

.fa-caret-left:before {
	content: "\f0d9";
}

.fa-caret-right:before {
	content: "\f0da";
}

.fa-columns:before {
	content: "\f0db";
}

.fa-unsorted:before,
.fa-sort:before {
	content: "\f0dc";
}

.fa-sort-down:before,
.fa-sort-desc:before {
	content: "\f0dd";
}

.fa-sort-up:before,
.fa-sort-asc:before {
	content: "\f0de";
}

.fa-envelope:before {
	content: "\f0e0";
}

.fa-linkedin:before {
	content: "\f0e1";
}

.fa-rotate-left:before,
.fa-undo:before {
	content: "\f0e2";
}

.fa-legal:before,
.fa-gavel:before {
	content: "\f0e3";
}

.fa-dashboard:before,
.fa-tachometer:before {
	content: "\f0e4";
}

.fa-comment-o:before {
	content: "\f0e5";
}

.fa-comments-o:before {
	content: "\f0e6";
}

.fa-flash:before,
.fa-bolt:before {
	content: "\f0e7";
}

.fa-sitemap:before {
	content: "\f0e8";
}

.fa-umbrella:before {
	content: "\f0e9";
}

.fa-paste:before,
.fa-clipboard:before {
	content: "\f0ea";
}

.fa-lightbulb-o:before {
	content: "\f0eb";
}

.fa-exchange:before {
	content: "\f0ec";
}

.fa-cloud-download:before {
	content: "\f0ed";
}

.fa-cloud-upload:before {
	content: "\f0ee";
}

.fa-user-md:before {
	content: "\f0f0";
}

.fa-stethoscope:before {
	content: "\f0f1";
}

.fa-suitcase:before {
	content: "\f0f2";
}

.fa-bell-o:before {
	content: "\f0a2";
}

.fa-coffee:before {
	content: "\f0f4";
}

.fa-cutlery:before {
	content: "\f0f5";
}

.fa-file-text-o:before {
	content: "\f0f6";
}

.fa-building-o:before {
	content: "\f0f7";
}

.fa-hospital-o:before {
	content: "\f0f8";
}

.fa-ambulance:before {
	content: "\f0f9";
}

.fa-medkit:before {
	content: "\f0fa";
}

.fa-fighter-jet:before {
	content: "\f0fb";
}

.fa-beer:before {
	content: "\f0fc";
}

.fa-h-square:before {
	content: "\f0fd";
}

.fa-plus-square:before {
	content: "\f0fe";
}

.fa-angle-double-left:before {
	content: "\f100";
}

.fa-angle-double-right:before {
	content: "\f101";
}

.fa-angle-double-up:before {
	content: "\f102";
}

.fa-angle-double-down:before {
	content: "\f103";
}

.fa-angle-left:before {
	content: "\f104";
}

.fa-angle-right:before {
	content: "\f105";
}

.fa-angle-up:before {
	content: "\f106";
}

.fa-angle-down:before {
	content: "\f107";
}

.fa-desktop:before {
	content: "\f108";
}

.fa-laptop:before {
	content: "\f109";
}

.fa-tablet:before {
	content: "\f10a";
}

.fa-mobile-phone:before,
.fa-mobile:before {
	content: "\f10b";
}

.fa-circle-o:before {
	content: "\f10c";
}

.fa-quote-left:before {
	content: "\f10d";
}

.fa-quote-right:before {
	content: "\f10e";
}

.fa-spinner:before {
	content: "\f110";
}

.fa-circle:before {
	content: "\f111";
}

.fa-mail-reply:before,
.fa-reply:before {
	content: "\f112";
}

.fa-github-alt:before {
	content: "\f113";
}

.fa-folder-o:before {
	content: "\f114";
}

.fa-folder-open-o:before {
	content: "\f115";
}

.fa-smile-o:before {
	content: "\f118";
}

.fa-frown-o:before {
	content: "\f119";
}

.fa-meh-o:before {
	content: "\f11a";
}

.fa-gamepad:before {
	content: "\f11b";
}

.fa-keyboard-o:before {
	content: "\f11c";
}

.fa-flag-o:before {
	content: "\f11d";
}

.fa-flag-checkered:before {
	content: "\f11e";
}

.fa-terminal:before {
	content: "\f120";
}

.fa-code:before {
	content: "\f121";
}

.fa-mail-reply-all:before,
.fa-reply-all:before {
	content: "\f122";
}

.fa-star-half-empty:before,
.fa-star-half-full:before,
.fa-star-half-o:before {
	content: "\f123";
}

.fa-location-arrow:before {
	content: "\f124";
}

.fa-crop:before {
	content: "\f125";
}

.fa-code-fork:before {
	content: "\f126";
}

.fa-unlink:before,
.fa-chain-broken:before {
	content: "\f127";
}

.fa-question:before {
	content: "\f128";
}

.fa-info:before {
	content: "\f129";
}

.fa-exclamation:before {
	content: "\f12a";
}

.fa-superscript:before {
	content: "\f12b";
}

.fa-subscript:before {
	content: "\f12c";
}

.fa-eraser:before {
	content: "\f12d";
}

.fa-puzzle-piece:before {
	content: "\f12e";
}

.fa-microphone:before {
	content: "\f130";
}

.fa-microphone-slash:before {
	content: "\f131";
}

.fa-shield:before {
	content: "\f132";
}

.fa-calendar-o:before {
	content: "\f133";
}

.fa-fire-extinguisher:before {
	content: "\f134";
}

.fa-rocket:before {
	content: "\f135";
}

.fa-maxcdn:before {
	content: "\f136";
}

.fa-chevron-circle-left:before {
	content: "\f137";
}

.fa-chevron-circle-right:before {
	content: "\f138";
}

.fa-chevron-circle-up:before {
	content: "\f139";
}

.fa-chevron-circle-down:before {
	content: "\f13a";
}

.fa-html5:before {
	content: "\f13b";
}

.fa-css3:before {
	content: "\f13c";
}

.fa-anchor:before {
	content: "\f13d";
}

.fa-unlock-alt:before {
	content: "\f13e";
}

.fa-bullseye:before {
	content: "\f140";
}

.fa-ellipsis-h:before {
	content: "\f141";
}

.fa-ellipsis-v:before {
	content: "\f142";
}

.fa-rss-square:before {
	content: "\f143";
}

.fa-play-circle:before {
	content: "\f144";
}

.fa-ticket:before {
	content: "\f145";
}

.fa-minus-square:before {
	content: "\f146";
}

.fa-minus-square-o:before {
	content: "\f147";
}

.fa-level-up:before {
	content: "\f148";
}

.fa-level-down:before {
	content: "\f149";
}

.fa-check-square:before {
	content: "\f14a";
}

.fa-pencil-square:before {
	content: "\f14b";
}

.fa-external-link-square:before {
	content: "\f14c";
}

.fa-share-square:before {
	content: "\f14d";
}

.fa-compass:before {
	content: "\f14e";
}

.fa-toggle-down:before,
.fa-caret-square-o-down:before {
	content: "\f150";
}

.fa-toggle-up:before,
.fa-caret-square-o-up:before {
	content: "\f151";
}

.fa-toggle-right:before,
.fa-caret-square-o-right:before {
	content: "\f152";
}

.fa-euro:before,
.fa-eur:before {
	content: "\f153";
}

.fa-gbp:before {
	content: "\f154";
}

.fa-dollar:before,
.fa-usd:before {
	content: "\f155";
}

.fa-rupee:before,
.fa-inr:before {
	content: "\f156";
}

.fa-cny:before,
.fa-rmb:before,
.fa-yen:before,
.fa-jpy:before {
	content: "\f157";
}

.fa-ruble:before,
.fa-rouble:before,
.fa-rub:before {
	content: "\f158";
}

.fa-won:before,
.fa-krw:before {
	content: "\f159";
}

.fa-bitcoin:before,
.fa-btc:before {
	content: "\f15a";
}

.fa-file:before {
	content: "\f15b";
}

.fa-file-text:before {
	content: "\f15c";
}

.fa-sort-alpha-asc:before {
	content: "\f15d";
}

.fa-sort-alpha-desc:before {
	content: "\f15e";
}

.fa-sort-amount-asc:before {
	content: "\f160";
}

.fa-sort-amount-desc:before {
	content: "\f161";
}

.fa-sort-numeric-asc:before {
	content: "\f162";
}

.fa-sort-numeric-desc:before {
	content: "\f163";
}

.fa-thumbs-up:before {
	content: "\f164";
}

.fa-thumbs-down:before {
	content: "\f165";
}

.fa-youtube-square:before {
	content: "\f166";
}

.fa-youtube:before {
	content: "\f167";
}

.fa-xing:before {
	content: "\f168";
}

.fa-xing-square:before {
	content: "\f169";
}

.fa-youtube-play:before {
	content: "\f16a";
}

.fa-dropbox:before {
	content: "\f16b";
}

.fa-stack-overflow:before {
	content: "\f16c";
}

.fa-instagram:before {
	content: "\f16d";
}

.fa-flickr:before {
	content: "\f16e";
}

.fa-adn:before {
	content: "\f170";
}

.fa-bitbucket:before {
	content: "\f171";
}

.fa-bitbucket-square:before {
	content: "\f172";
}

.fa-tumblr:before {
	content: "\f173";
}

.fa-tumblr-square:before {
	content: "\f174";
}

.fa-long-arrow-down:before {
	content: "\f175";
}

.fa-long-arrow-up:before {
	content: "\f176";
}

.fa-long-arrow-left:before {
	content: "\f177";
}

.fa-long-arrow-right:before {
	content: "\f178";
}

.fa-apple:before {
	content: "\f179";
}

.fa-windows:before {
	content: "\f17a";
}

.fa-android:before {
	content: "\f17b";
}

.fa-linux:before {
	content: "\f17c";
}

.fa-dribbble:before {
	content: "\f17d";
}

.fa-skype:before {
	content: "\f17e";
}

.fa-foursquare:before {
	content: "\f180";
}

.fa-trello:before {
	content: "\f181";
}

.fa-female:before {
	content: "\f182";
}

.fa-male:before {
	content: "\f183";
}

.fa-gittip:before,
.fa-gratipay:before {
	content: "\f184";
}

.fa-sun-o:before {
	content: "\f185";
}

.fa-moon-o:before {
	content: "\f186";
}

.fa-archive:before {
	content: "\f187";
}

.fa-bug:before {
	content: "\f188";
}

.fa-vk:before {
	content: "\f189";
}

.fa-weibo:before {
	content: "\f18a";
}

.fa-renren:before {
	content: "\f18b";
}

.fa-pagelines:before {
	content: "\f18c";
}

.fa-stack-exchange:before {
	content: "\f18d";
}

.fa-arrow-circle-o-right:before {
	content: "\f18e";
}

.fa-arrow-circle-o-left:before {
	content: "\f190";
}

.fa-toggle-left:before,
.fa-caret-square-o-left:before {
	content: "\f191";
}

.fa-dot-circle-o:before {
	content: "\f192";
}

.fa-wheelchair:before {
	content: "\f193";
}

.fa-vimeo-square:before {
	content: "\f194";
}

.fa-turkish-lira:before,
.fa-try:before {
	content: "\f195";
}

.fa-plus-square-o:before {
	content: "\f196";
}

.fa-space-shuttle:before {
	content: "\f197";
}

.fa-slack:before {
	content: "\f198";
}

.fa-envelope-square:before {
	content: "\f199";
}

.fa-wordpress:before {
	content: "\f19a";
}

.fa-openid:before {
	content: "\f19b";
}

.fa-institution:before,
.fa-bank:before,
.fa-university:before {
	content: "\f19c";
}

.fa-mortar-board:before,
.fa-graduation-cap:before {
	content: "\f19d";
}

.fa-yahoo:before {
	content: "\f19e";
}

.fa-google:before {
	content: "\f1a0";
}

.fa-reddit:before {
	content: "\f1a1";
}

.fa-reddit-square:before {
	content: "\f1a2";
}

.fa-stumbleupon-circle:before {
	content: "\f1a3";
}

.fa-stumbleupon:before {
	content: "\f1a4";
}

.fa-delicious:before {
	content: "\f1a5";
}

.fa-digg:before {
	content: "\f1a6";
}

.fa-pied-piper-pp:before {
	content: "\f1a7";
}

.fa-pied-piper-alt:before {
	content: "\f1a8";
}

.fa-drupal:before {
	content: "\f1a9";
}

.fa-joomla:before {
	content: "\f1aa";
}

.fa-language:before {
	content: "\f1ab";
}

.fa-fax:before {
	content: "\f1ac";
}

.fa-building:before {
	content: "\f1ad";
}

.fa-child:before {
	content: "\f1ae";
}

.fa-paw:before {
	content: "\f1b0";
}

.fa-spoon:before {
	content: "\f1b1";
}

.fa-cube:before {
	content: "\f1b2";
}

.fa-cubes:before {
	content: "\f1b3";
}

.fa-behance:before {
	content: "\f1b4";
}

.fa-behance-square:before {
	content: "\f1b5";
}

.fa-steam:before {
	content: "\f1b6";
}

.fa-steam-square:before {
	content: "\f1b7";
}

.fa-recycle:before {
	content: "\f1b8";
}

.fa-automobile:before,
.fa-car:before {
	content: "\f1b9";
}

.fa-cab:before,
.fa-taxi:before {
	content: "\f1ba";
}

.fa-tree:before {
	content: "\f1bb";
}

.fa-spotify:before {
	content: "\f1bc";
}

.fa-deviantart:before {
	content: "\f1bd";
}

.fa-soundcloud:before {
	content: "\f1be";
}

.fa-database:before {
	content: "\f1c0";
}

.fa-file-pdf-o:before {
	content: "\f1c1";
}

.fa-file-word-o:before {
	content: "\f1c2";
}

.fa-file-excel-o:before {
	content: "\f1c3";
}

.fa-file-powerpoint-o:before {
	content: "\f1c4";
}

.fa-file-photo-o:before,
.fa-file-picture-o:before,
.fa-file-image-o:before {
	content: "\f1c5";
}

.fa-file-zip-o:before,
.fa-file-archive-o:before {
	content: "\f1c6";
}

.fa-file-sound-o:before,
.fa-file-audio-o:before {
	content: "\f1c7";
}

.fa-file-movie-o:before,
.fa-file-video-o:before {
	content: "\f1c8";
}

.fa-file-code-o:before {
	content: "\f1c9";
}

.fa-vine:before {
	content: "\f1ca";
}

.fa-codepen:before {
	content: "\f1cb";
}

.fa-jsfiddle:before {
	content: "\f1cc";
}

.fa-life-bouy:before,
.fa-life-buoy:before,
.fa-life-saver:before,
.fa-support:before,
.fa-life-ring:before {
	content: "\f1cd";
}

.fa-circle-o-notch:before {
	content: "\f1ce";
}

.fa-ra:before,
.fa-resistance:before,
.fa-rebel:before {
	content: "\f1d0";
}

.fa-ge:before,
.fa-empire:before {
	content: "\f1d1";
}

.fa-git-square:before {
	content: "\f1d2";
}

.fa-git:before {
	content: "\f1d3";
}

.fa-y-combinator-square:before,
.fa-yc-square:before,
.fa-hacker-news:before {
	content: "\f1d4";
}

.fa-tencent-weibo:before {
	content: "\f1d5";
}

.fa-qq:before {
	content: "\f1d6";
}

.fa-wechat:before,
.fa-weixin:before {
	content: "\f1d7";
}

.fa-send:before,
.fa-paper-plane:before {
	content: "\f1d8";
}

.fa-send-o:before,
.fa-paper-plane-o:before {
	content: "\f1d9";
}

.fa-history:before {
	content: "\f1da";
}

.fa-circle-thin:before {
	content: "\f1db";
}

.fa-header:before {
	content: "\f1dc";
}

.fa-paragraph:before {
	content: "\f1dd";
}

.fa-sliders:before {
	content: "\f1de";
}

.fa-share-alt:before {
	content: "\f1e0";
}

.fa-share-alt-square:before {
	content: "\f1e1";
}

.fa-bomb:before {
	content: "\f1e2";
}

.fa-soccer-ball-o:before,
.fa-futbol-o:before {
	content: "\f1e3";
}

.fa-tty:before {
	content: "\f1e4";
}

.fa-binoculars:before {
	content: "\f1e5";
}

.fa-plug:before {
	content: "\f1e6";
}

.fa-slideshare:before {
	content: "\f1e7";
}

.fa-twitch:before {
	content: "\f1e8";
}

.fa-yelp:before {
	content: "\f1e9";
}

.fa-newspaper-o:before {
	content: "\f1ea";
}

.fa-wifi:before {
	content: "\f1eb";
}

.fa-calculator:before {
	content: "\f1ec";
}

.fa-paypal:before {
	content: "\f1ed";
}

.fa-google-wallet:before {
	content: "\f1ee";
}

.fa-cc-visa:before {
	content: "\f1f0";
}

.fa-cc-mastercard:before {
	content: "\f1f1";
}

.fa-cc-discover:before {
	content: "\f1f2";
}

.fa-cc-amex:before {
	content: "\f1f3";
}

.fa-cc-paypal:before {
	content: "\f1f4";
}

.fa-cc-stripe:before {
	content: "\f1f5";
}

.fa-bell-slash:before {
	content: "\f1f6";
}

.fa-bell-slash-o:before {
	content: "\f1f7";
}

.fa-trash:before {
	content: "\f1f8";
}

.fa-copyright:before {
	content: "\f1f9";
}

.fa-at:before {
	content: "\f1fa";
}

.fa-eyedropper:before {
	content: "\f1fb";
}

.fa-paint-brush:before {
	content: "\f1fc";
}

.fa-birthday-cake:before {
	content: "\f1fd";
}

.fa-area-chart:before {
	content: "\f1fe";
}

.fa-pie-chart:before {
	content: "\f200";
}

.fa-line-chart:before {
	content: "\f201";
}

.fa-lastfm:before {
	content: "\f202";
}

.fa-lastfm-square:before {
	content: "\f203";
}

.fa-toggle-off:before {
	content: "\f204";
}

.fa-toggle-on:before {
	content: "\f205";
}

.fa-bicycle:before {
	content: "\f206";
}

.fa-bus:before {
	content: "\f207";
}

.fa-ioxhost:before {
	content: "\f208";
}

.fa-angellist:before {
	content: "\f209";
}

.fa-cc:before {
	content: "\f20a";
}

.fa-shekel:before,
.fa-sheqel:before,
.fa-ils:before {
	content: "\f20b";
}

.fa-meanpath:before {
	content: "\f20c";
}

.fa-buysellads:before {
	content: "\f20d";
}

.fa-connectdevelop:before {
	content: "\f20e";
}

.fa-dashcube:before {
	content: "\f210";
}

.fa-forumbee:before {
	content: "\f211";
}

.fa-leanpub:before {
	content: "\f212";
}

.fa-sellsy:before {
	content: "\f213";
}

.fa-shirtsinbulk:before {
	content: "\f214";
}

.fa-simplybuilt:before {
	content: "\f215";
}

.fa-skyatlas:before {
	content: "\f216";
}

.fa-cart-plus:before {
	content: "\f217";
}

.fa-cart-arrow-down:before {
	content: "\f218";
}

.fa-diamond:before {
	content: "\f219";
}

.fa-ship:before {
	content: "\f21a";
}

.fa-user-secret:before {
	content: "\f21b";
}

.fa-motorcycle:before {
	content: "\f21c";
}

.fa-street-view:before {
	content: "\f21d";
}

.fa-heartbeat:before {
	content: "\f21e";
}

.fa-venus:before {
	content: "\f221";
}

.fa-mars:before {
	content: "\f222";
}

.fa-mercury:before {
	content: "\f223";
}

.fa-intersex:before,
.fa-transgender:before {
	content: "\f224";
}

.fa-transgender-alt:before {
	content: "\f225";
}

.fa-venus-double:before {
	content: "\f226";
}

.fa-mars-double:before {
	content: "\f227";
}

.fa-venus-mars:before {
	content: "\f228";
}

.fa-mars-stroke:before {
	content: "\f229";
}

.fa-mars-stroke-v:before {
	content: "\f22a";
}

.fa-mars-stroke-h:before {
	content: "\f22b";
}

.fa-neuter:before {
	content: "\f22c";
}

.fa-genderless:before {
	content: "\f22d";
}

.fa-facebook-official:before {
	content: "\f230";
}

.fa-pinterest-p:before {
	content: "\f231";
}

.fa-whatsapp:before {
	content: "\f232";
}

.fa-server:before {
	content: "\f233";
}

.fa-user-plus:before {
	content: "\f234";
}

.fa-user-times:before {
	content: "\f235";
}

.fa-hotel:before,
.fa-bed:before {
	content: "\f236";
}

.fa-viacoin:before {
	content: "\f237";
}

.fa-train:before {
	content: "\f238";
}

.fa-subway:before {
	content: "\f239";
}

.fa-medium:before {
	content: "\f23a";
}

.fa-yc:before,
.fa-y-combinator:before {
	content: "\f23b";
}

.fa-optin-monster:before {
	content: "\f23c";
}

.fa-opencart:before {
	content: "\f23d";
}

.fa-expeditedssl:before {
	content: "\f23e";
}

.fa-battery-4:before,
.fa-battery:before,
.fa-battery-full:before {
	content: "\f240";
}

.fa-battery-3:before,
.fa-battery-three-quarters:before {
	content: "\f241";
}

.fa-battery-2:before,
.fa-battery-half:before {
	content: "\f242";
}

.fa-battery-1:before,
.fa-battery-quarter:before {
	content: "\f243";
}

.fa-battery-0:before,
.fa-battery-empty:before {
	content: "\f244";
}

.fa-mouse-pointer:before {
	content: "\f245";
}

.fa-i-cursor:before {
	content: "\f246";
}

.fa-object-group:before {
	content: "\f247";
}

.fa-object-ungroup:before {
	content: "\f248";
}

.fa-sticky-note:before {
	content: "\f249";
}

.fa-sticky-note-o:before {
	content: "\f24a";
}

.fa-cc-jcb:before {
	content: "\f24b";
}

.fa-cc-diners-club:before {
	content: "\f24c";
}

.fa-clone:before {
	content: "\f24d";
}

.fa-balance-scale:before {
	content: "\f24e";
}

.fa-hourglass-o:before {
	content: "\f250";
}

.fa-hourglass-1:before,
.fa-hourglass-start:before {
	content: "\f251";
}

.fa-hourglass-2:before,
.fa-hourglass-half:before {
	content: "\f252";
}

.fa-hourglass-3:before,
.fa-hourglass-end:before {
	content: "\f253";
}

.fa-hourglass:before {
	content: "\f254";
}

.fa-hand-grab-o:before,
.fa-hand-rock-o:before {
	content: "\f255";
}

.fa-hand-stop-o:before,
.fa-hand-paper-o:before {
	content: "\f256";
}

.fa-hand-scissors-o:before {
	content: "\f257";
}

.fa-hand-lizard-o:before {
	content: "\f258";
}

.fa-hand-spock-o:before {
	content: "\f259";
}

.fa-hand-pointer-o:before {
	content: "\f25a";
}

.fa-hand-peace-o:before {
	content: "\f25b";
}

.fa-trademark:before {
	content: "\f25c";
}

.fa-registered:before {
	content: "\f25d";
}

.fa-creative-commons:before {
	content: "\f25e";
}

.fa-gg:before {
	content: "\f260";
}

.fa-gg-circle:before {
	content: "\f261";
}

.fa-tripadvisor:before {
	content: "\f262";
}

.fa-odnoklassniki:before {
	content: "\f263";
}

.fa-odnoklassniki-square:before {
	content: "\f264";
}

.fa-get-pocket:before {
	content: "\f265";
}

.fa-wikipedia-w:before {
	content: "\f266";
}

.fa-safari:before {
	content: "\f267";
}

.fa-chrome:before {
	content: "\f268";
}

.fa-firefox:before {
	content: "\f269";
}

.fa-opera:before {
	content: "\f26a";
}

.fa-internet-explorer:before {
	content: "\f26b";
}

.fa-tv:before,
.fa-television:before {
	content: "\f26c";
}

.fa-contao:before {
	content: "\f26d";
}

.fa-500px:before {
	content: "\f26e";
}

.fa-amazon:before {
	content: "\f270";
}

.fa-calendar-plus-o:before {
	content: "\f271";
}

.fa-calendar-minus-o:before {
	content: "\f272";
}

.fa-calendar-times-o:before {
	content: "\f273";
}

.fa-calendar-check-o:before {
	content: "\f274";
}

.fa-industry:before {
	content: "\f275";
}

.fa-map-pin:before {
	content: "\f276";
}

.fa-map-signs:before {
	content: "\f277";
}

.fa-map-o:before {
	content: "\f278";
}

.fa-map:before {
	content: "\f279";
}

.fa-commenting:before {
	content: "\f27a";
}

.fa-commenting-o:before {
	content: "\f27b";
}

.fa-houzz:before {
	content: "\f27c";
}

.fa-vimeo:before {
	content: "\f27d";
}

.fa-black-tie:before {
	content: "\f27e";
}

.fa-fonticons:before {
	content: "\f280";
}

.fa-reddit-alien:before {
	content: "\f281";
}

.fa-edge:before {
	content: "\f282";
}

.fa-credit-card-alt:before {
	content: "\f283";
}

.fa-codiepie:before {
	content: "\f284";
}

.fa-modx:before {
	content: "\f285";
}

.fa-fort-awesome:before {
	content: "\f286";
}

.fa-usb:before {
	content: "\f287";
}

.fa-product-hunt:before {
	content: "\f288";
}

.fa-mixcloud:before {
	content: "\f289";
}

.fa-scribd:before {
	content: "\f28a";
}

.fa-pause-circle:before {
	content: "\f28b";
}

.fa-pause-circle-o:before {
	content: "\f28c";
}

.fa-stop-circle:before {
	content: "\f28d";
}

.fa-stop-circle-o:before {
	content: "\f28e";
}

.fa-shopping-bag:before {
	content: "\f290";
}

.fa-shopping-basket:before {
	content: "\f291";
}

.fa-hashtag:before {
	content: "\f292";
}

.fa-bluetooth:before {
	content: "\f293";
}

.fa-bluetooth-b:before {
	content: "\f294";
}

.fa-percent:before {
	content: "\f295";
}

.fa-gitlab:before {
	content: "\f296";
}

.fa-wpbeginner:before {
	content: "\f297";
}

.fa-wpforms:before {
	content: "\f298";
}

.fa-envira:before {
	content: "\f299";
}

.fa-universal-access:before {
	content: "\f29a";
}

.fa-wheelchair-alt:before {
	content: "\f29b";
}

.fa-question-circle-o:before {
	content: "\f29c";
}

.fa-blind:before {
	content: "\f29d";
}

.fa-audio-description:before {
	content: "\f29e";
}

.fa-volume-control-phone:before {
	content: "\f2a0";
}

.fa-braille:before {
	content: "\f2a1";
}

.fa-assistive-listening-systems:before {
	content: "\f2a2";
}

.fa-asl-interpreting:before,
.fa-american-sign-language-interpreting:before {
	content: "\f2a3";
}

.fa-deafness:before,
.fa-hard-of-hearing:before,
.fa-deaf:before {
	content: "\f2a4";
}

.fa-glide:before {
	content: "\f2a5";
}

.fa-glide-g:before {
	content: "\f2a6";
}

.fa-signing:before,
.fa-sign-language:before {
	content: "\f2a7";
}

.fa-low-vision:before {
	content: "\f2a8";
}

.fa-viadeo:before {
	content: "\f2a9";
}

.fa-viadeo-square:before {
	content: "\f2aa";
}

.fa-snapchat:before {
	content: "\f2ab";
}

.fa-snapchat-ghost:before {
	content: "\f2ac";
}

.fa-snapchat-square:before {
	content: "\f2ad";
}

.fa-pied-piper:before {
	content: "\f2ae";
}

.fa-first-order:before {
	content: "\f2b0";
}

.fa-yoast:before {
	content: "\f2b1";
}

.fa-themeisle:before {
	content: "\f2b2";
}

.fa-google-plus-circle:before,
.fa-google-plus-official:before {
	content: "\f2b3";
}

.fa-fa:before,
.fa-font-awesome:before {
	content: "\f2b4";
}

.fa-handshake-o:before {
	content: "\f2b5";
}

.fa-envelope-open:before {
	content: "\f2b6";
}

.fa-envelope-open-o:before {
	content: "\f2b7";
}

.fa-linode:before {
	content: "\f2b8";
}

.fa-address-book:before {
	content: "\f2b9";
}

.fa-address-book-o:before {
	content: "\f2ba";
}

.fa-vcard:before,
.fa-address-card:before {
	content: "\f2bb";
}

.fa-vcard-o:before,
.fa-address-card-o:before {
	content: "\f2bc";
}

.fa-user-circle:before {
	content: "\f2bd";
}

.fa-user-circle-o:before {
	content: "\f2be";
}

.fa-user-o:before {
	content: "\f2c0";
}

.fa-id-badge:before {
	content: "\f2c1";
}

.fa-drivers-license:before,
.fa-id-card:before {
	content: "\f2c2";
}

.fa-drivers-license-o:before,
.fa-id-card-o:before {
	content: "\f2c3";
}

.fa-quora:before {
	content: "\f2c4";
}

.fa-free-code-camp:before {
	content: "\f2c5";
}

.fa-telegram:before {
	content: "\f2c6";
}

.fa-thermometer-4:before,
.fa-thermometer:before,
.fa-thermometer-full:before {
	content: "\f2c7";
}

.fa-thermometer-3:before,
.fa-thermometer-three-quarters:before {
	content: "\f2c8";
}

.fa-thermometer-2:before,
.fa-thermometer-half:before {
	content: "\f2c9";
}

.fa-thermometer-1:before,
.fa-thermometer-quarter:before {
	content: "\f2ca";
}

.fa-thermometer-0:before,
.fa-thermometer-empty:before {
	content: "\f2cb";
}

.fa-shower:before {
	content: "\f2cc";
}

.fa-bathtub:before,
.fa-s15:before,
.fa-bath:before {
	content: "\f2cd";
}

.fa-podcast:before {
	content: "\f2ce";
}

.fa-window-maximize:before {
	content: "\f2d0";
}

.fa-window-minimize:before {
	content: "\f2d1";
}

.fa-window-restore:before {
	content: "\f2d2";
}

.fa-times-rectangle:before,
.fa-window-close:before {
	content: "\f2d3";
}

.fa-times-rectangle-o:before,
.fa-window-close-o:before {
	content: "\f2d4";
}

.fa-bandcamp:before {
	content: "\f2d5";
}

.fa-grav:before {
	content: "\f2d6";
}

.fa-etsy:before {
	content: "\f2d7";
}

.fa-imdb:before {
	content: "\f2d8";
}

.fa-ravelry:before {
	content: "\f2d9";
}

.fa-eercast:before {
	content: "\f2da";
}

.fa-microchip:before {
	content: "\f2db";
}

.fa-snowflake-o:before {
	content: "\f2dc";
}

.fa-superpowers:before {
	content: "\f2dd";
}

.fa-wpexplorer:before {
	content: "\f2de";
}

.fa-meetup:before {
	content: "\f2e0";
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
	position: static;
	width: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	clip: auto;
}

/*
*
* Material Design Icons
* --------------------------------------------------
*/
@font-face {
	font-family: "Material Design Icons";
	src: url("/fonts/materialdesignicons-webfont.eot?v=1.4.57");
	src: url("/fonts/materialdesignicons-webfont.eot?#iefix&v=1.4.57") format("embedded-opentype"), url("/fonts/materialdesignicons-webfont.woff2?v=1.4.57") format("woff2"), url("/fonts/materialdesignicons-webfont.woff?v=1.4.57") format("woff"), url("/fonts/materialdesignicons-webfont.ttf?v=1.4.57") format("truetype"), url("/fonts/materialdesignicons-webfont.svg?v=1.4.57#materialdesigniconsregular") format("svg");
	font-weight: normal;
	font-style: normal;
}

.mdi {
	display: inline-block;
	font: normal normal normal 24px/1 "Material Design Icons";
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-transform: translate(0, 0);
	        transform: translate(0, 0);
}

.mdi-access-point:before {
	content: "\f101";
}

.mdi-access-point-network:before {
	content: "\f102";
}

.mdi-account:before {
	content: "\f103";
}

.mdi-account-alert:before {
	content: "\f104";
}

.mdi-account-box:before {
	content: "\f105";
}

.mdi-account-box-outline:before {
	content: "\f106";
}

.mdi-account-check:before {
	content: "\f107";
}

.mdi-account-circle:before {
	content: "\f108";
}

.mdi-account-convert:before {
	content: "\f109";
}

.mdi-account-key:before {
	content: "\f10a";
}

.mdi-account-location:before {
	content: "\f10b";
}

.mdi-account-minus:before {
	content: "\f10c";
}

.mdi-account-multiple:before {
	content: "\f10d";
}

.mdi-account-multiple-outline:before {
	content: "\f10e";
}

.mdi-account-multiple-plus:before {
	content: "\f10f";
}

.mdi-account-network:before {
	content: "\f110";
}

.mdi-account-off:before {
	content: "\f111";
}

.mdi-account-outline:before {
	content: "\f112";
}

.mdi-account-plus:before {
	content: "\f113";
}

.mdi-account-remove:before {
	content: "\f114";
}

.mdi-account-search:before {
	content: "\f115";
}

.mdi-account-star:before {
	content: "\f116";
}

.mdi-account-star-variant:before {
	content: "\f117";
}

.mdi-account-switch:before {
	content: "\f118";
}

.mdi-adjust:before {
	content: "\f119";
}

.mdi-air-conditioner:before {
	content: "\f11a";
}

.mdi-airballoon:before {
	content: "\f11b";
}

.mdi-airplane:before {
	content: "\f11c";
}

.mdi-airplane-off:before {
	content: "\f11d";
}

.mdi-airplay:before {
	content: "\f11e";
}

.mdi-alarm:before {
	content: "\f11f";
}

.mdi-alarm-check:before {
	content: "\f120";
}

.mdi-alarm-multiple:before {
	content: "\f121";
}

.mdi-alarm-off:before {
	content: "\f122";
}

.mdi-alarm-plus:before {
	content: "\f123";
}

.mdi-album:before {
	content: "\f124";
}

.mdi-alert:before {
	content: "\f125";
}

.mdi-alert-box:before {
	content: "\f126";
}

.mdi-alert-circle:before {
	content: "\f127";
}

.mdi-alert-octagon:before {
	content: "\f128";
}

.mdi-alert-outline:before {
	content: "\f129";
}

.mdi-alpha:before {
	content: "\f12a";
}

.mdi-alphabetical:before {
	content: "\f12b";
}

.mdi-amazon:before {
	content: "\f12c";
}

.mdi-amazon-clouddrive:before {
	content: "\f12d";
}

.mdi-ambulance:before {
	content: "\f12e";
}

.mdi-anchor:before {
	content: "\f12f";
}

.mdi-android:before {
	content: "\f130";
}

.mdi-android-debug-bridge:before {
	content: "\f131";
}

.mdi-android-studio:before {
	content: "\f132";
}

.mdi-apple:before {
	content: "\f133";
}

.mdi-apple-finder:before {
	content: "\f134";
}

.mdi-apple-ios:before {
	content: "\f135";
}

.mdi-apple-mobileme:before {
	content: "\f136";
}

.mdi-apple-safari:before {
	content: "\f137";
}

.mdi-appnet:before {
	content: "\f138";
}

.mdi-apps:before {
	content: "\f139";
}

.mdi-archive:before {
	content: "\f13a";
}

.mdi-arrange-bring-forward:before {
	content: "\f13b";
}

.mdi-arrange-bring-to-front:before {
	content: "\f13c";
}

.mdi-arrange-send-backward:before {
	content: "\f13d";
}

.mdi-arrange-send-to-back:before {
	content: "\f13e";
}

.mdi-arrow-all:before {
	content: "\f13f";
}

.mdi-arrow-bottom-drop-circle:before {
	content: "\f140";
}

.mdi-arrow-bottom-left:before {
	content: "\f141";
}

.mdi-arrow-bottom-right:before {
	content: "\f142";
}

.mdi-arrow-collapse:before {
	content: "\f143";
}

.mdi-arrow-down:before {
	content: "\f144";
}

.mdi-arrow-down-bold:before {
	content: "\f145";
}

.mdi-arrow-down-bold-circle:before {
	content: "\f146";
}

.mdi-arrow-down-bold-circle-outline:before {
	content: "\f147";
}

.mdi-arrow-down-bold-hexagon-outline:before {
	content: "\f148";
}

.mdi-arrow-expand:before {
	content: "\f149";
}

.mdi-arrow-left:before {
	content: "\f14a";
}

.mdi-arrow-left-bold:before {
	content: "\f14b";
}

.mdi-arrow-left-bold-circle:before {
	content: "\f14c";
}

.mdi-arrow-left-bold-circle-outline:before {
	content: "\f14d";
}

.mdi-arrow-left-bold-hexagon-outline:before {
	content: "\f14e";
}

.mdi-arrow-right:before {
	content: "\f14f";
}

.mdi-arrow-right-bold:before {
	content: "\f150";
}

.mdi-arrow-right-bold-circle:before {
	content: "\f151";
}

.mdi-arrow-right-bold-circle-outline:before {
	content: "\f152";
}

.mdi-arrow-right-bold-hexagon-outline:before {
	content: "\f153";
}

.mdi-arrow-top-left:before {
	content: "\f154";
}

.mdi-arrow-top-right:before {
	content: "\f155";
}

.mdi-arrow-up:before {
	content: "\f156";
}

.mdi-arrow-up-bold:before {
	content: "\f157";
}

.mdi-arrow-up-bold-circle:before {
	content: "\f158";
}

.mdi-arrow-up-bold-circle-outline:before {
	content: "\f159";
}

.mdi-arrow-up-bold-hexagon-outline:before {
	content: "\f15a";
}

.mdi-assistant:before {
	content: "\f15b";
}

.mdi-at:before {
	content: "\f15c";
}

.mdi-attachment:before {
	content: "\f15d";
}

.mdi-audiobook:before {
	content: "\f15e";
}

.mdi-auto-fix:before {
	content: "\f15f";
}

.mdi-auto-upload:before {
	content: "\f160";
}

.mdi-autorenew:before {
	content: "\f161";
}

.mdi-av-timer:before {
	content: "\f162";
}

.mdi-baby:before {
	content: "\f163";
}

.mdi-backburger:before {
	content: "\f164";
}

.mdi-backspace:before {
	content: "\f165";
}

.mdi-backup-restore:before {
	content: "\f166";
}

.mdi-bank:before {
	content: "\f167";
}

.mdi-barcode:before {
	content: "\f168";
}

.mdi-barcode-scan:before {
	content: "\f169";
}

.mdi-barley:before {
	content: "\f16a";
}

.mdi-barrel:before {
	content: "\f16b";
}

.mdi-basecamp:before {
	content: "\f16c";
}

.mdi-basket:before {
	content: "\f16d";
}

.mdi-basket-fill:before {
	content: "\f16e";
}

.mdi-basket-unfill:before {
	content: "\f16f";
}

.mdi-battery:before {
	content: "\f170";
}

.mdi-battery-10:before {
	content: "\f171";
}

.mdi-battery-20:before {
	content: "\f172";
}

.mdi-battery-30:before {
	content: "\f173";
}

.mdi-battery-40:before {
	content: "\f174";
}

.mdi-battery-50:before {
	content: "\f175";
}

.mdi-battery-60:before {
	content: "\f176";
}

.mdi-battery-70:before {
	content: "\f177";
}

.mdi-battery-80:before {
	content: "\f178";
}

.mdi-battery-90:before {
	content: "\f179";
}

.mdi-battery-alert:before {
	content: "\f17a";
}

.mdi-battery-charging:before {
	content: "\f17b";
}

.mdi-battery-charging-100:before {
	content: "\f17c";
}

.mdi-battery-charging-20:before {
	content: "\f17d";
}

.mdi-battery-charging-30:before {
	content: "\f17e";
}

.mdi-battery-charging-40:before {
	content: "\f17f";
}

.mdi-battery-charging-60:before {
	content: "\f180";
}

.mdi-battery-charging-80:before {
	content: "\f181";
}

.mdi-battery-charging-90:before {
	content: "\f182";
}

.mdi-battery-minus:before {
	content: "\f183";
}

.mdi-battery-negative:before {
	content: "\f184";
}

.mdi-battery-outline:before {
	content: "\f185";
}

.mdi-battery-plus:before {
	content: "\f186";
}

.mdi-battery-positive:before {
	content: "\f187";
}

.mdi-battery-unknown:before {
	content: "\f188";
}

.mdi-beach:before {
	content: "\f189";
}

.mdi-beaker:before {
	content: "\f18a";
}

.mdi-beaker-empty:before {
	content: "\f18b";
}

.mdi-beaker-empty-outline:before {
	content: "\f18c";
}

.mdi-beaker-outline:before {
	content: "\f18d";
}

.mdi-beats:before {
	content: "\f18e";
}

.mdi-beer:before {
	content: "\f18f";
}

.mdi-behance:before {
	content: "\f190";
}

.mdi-bell:before {
	content: "\f191";
}

.mdi-bell-off:before {
	content: "\f192";
}

.mdi-bell-outline:before {
	content: "\f193";
}

.mdi-bell-plus:before {
	content: "\f194";
}

.mdi-bell-ring:before {
	content: "\f195";
}

.mdi-bell-ring-outline:before {
	content: "\f196";
}

.mdi-bell-sleep:before {
	content: "\f197";
}

.mdi-beta:before {
	content: "\f198";
}

.mdi-bike:before {
	content: "\f199";
}

.mdi-bing:before {
	content: "\f19a";
}

.mdi-binoculars:before {
	content: "\f19b";
}

.mdi-bio:before {
	content: "\f19c";
}

.mdi-biohazard:before {
	content: "\f19d";
}

.mdi-bitbucket:before {
	content: "\f19e";
}

.mdi-black-mesa:before {
	content: "\f19f";
}

.mdi-blackberry:before {
	content: "\f1a0";
}

.mdi-blender:before {
	content: "\f1a1";
}

.mdi-blinds:before {
	content: "\f1a2";
}

.mdi-block-helper:before {
	content: "\f1a3";
}

.mdi-blogger:before {
	content: "\f1a4";
}

.mdi-bluetooth:before {
	content: "\f1a5";
}

.mdi-bluetooth-audio:before {
	content: "\f1a6";
}

.mdi-bluetooth-connect:before {
	content: "\f1a7";
}

.mdi-bluetooth-off:before {
	content: "\f1a8";
}

.mdi-bluetooth-settings:before {
	content: "\f1a9";
}

.mdi-bluetooth-transfer:before {
	content: "\f1aa";
}

.mdi-blur:before {
	content: "\f1ab";
}

.mdi-blur-linear:before {
	content: "\f1ac";
}

.mdi-blur-off:before {
	content: "\f1ad";
}

.mdi-blur-radial:before {
	content: "\f1ae";
}

.mdi-bone:before {
	content: "\f1af";
}

.mdi-book:before {
	content: "\f1b0";
}

.mdi-book-multiple:before {
	content: "\f1b1";
}

.mdi-book-multiple-variant:before {
	content: "\f1b2";
}

.mdi-book-open:before {
	content: "\f1b3";
}

.mdi-book-open-variant:before {
	content: "\f1b4";
}

.mdi-book-variant:before {
	content: "\f1b5";
}

.mdi-bookmark:before {
	content: "\f1b6";
}

.mdi-bookmark-check:before {
	content: "\f1b7";
}

.mdi-bookmark-music:before {
	content: "\f1b8";
}

.mdi-bookmark-outline:before {
	content: "\f1b9";
}

.mdi-bookmark-outline-plus:before {
	content: "\f1ba";
}

.mdi-bookmark-plus:before {
	content: "\f1bb";
}

.mdi-bookmark-remove:before {
	content: "\f1bc";
}

.mdi-border-all:before {
	content: "\f1bd";
}

.mdi-border-bottom:before {
	content: "\f1be";
}

.mdi-border-color:before {
	content: "\f1bf";
}

.mdi-border-horizontal:before {
	content: "\f1c0";
}

.mdi-border-inside:before {
	content: "\f1c1";
}

.mdi-border-left:before {
	content: "\f1c2";
}

.mdi-border-none:before {
	content: "\f1c3";
}

.mdi-border-outside:before {
	content: "\f1c4";
}

.mdi-border-right:before {
	content: "\f1c5";
}

.mdi-border-style:before {
	content: "\f1c6";
}

.mdi-border-top:before {
	content: "\f1c7";
}

.mdi-border-vertical:before {
	content: "\f1c8";
}

.mdi-bowling:before {
	content: "\f1c9";
}

.mdi-box:before {
	content: "\f1ca";
}

.mdi-box-cutter:before {
	content: "\f1cb";
}

.mdi-briefcase:before {
	content: "\f1cc";
}

.mdi-briefcase-check:before {
	content: "\f1cd";
}

.mdi-briefcase-download:before {
	content: "\f1ce";
}

.mdi-briefcase-upload:before {
	content: "\f1cf";
}

.mdi-brightness-1:before {
	content: "\f1d0";
}

.mdi-brightness-2:before {
	content: "\f1d1";
}

.mdi-brightness-3:before {
	content: "\f1d2";
}

.mdi-brightness-4:before {
	content: "\f1d3";
}

.mdi-brightness-5:before {
	content: "\f1d4";
}

.mdi-brightness-6:before {
	content: "\f1d5";
}

.mdi-brightness-7:before {
	content: "\f1d6";
}

.mdi-brightness-auto:before {
	content: "\f1d7";
}

.mdi-broom:before {
	content: "\f1d8";
}

.mdi-brush:before {
	content: "\f1d9";
}

.mdi-bug:before {
	content: "\f1da";
}

.mdi-bulletin-board:before {
	content: "\f1db";
}

.mdi-bullhorn:before {
	content: "\f1dc";
}

.mdi-bus:before {
	content: "\f1dd";
}

.mdi-cached:before {
	content: "\f1de";
}

.mdi-cake:before {
	content: "\f1df";
}

.mdi-cake-layered:before {
	content: "\f1e0";
}

.mdi-cake-variant:before {
	content: "\f1e1";
}

.mdi-calculator:before {
	content: "\f1e2";
}

.mdi-calendar:before {
	content: "\f1e3";
}

.mdi-calendar-blank:before {
	content: "\f1e4";
}

.mdi-calendar-check:before {
	content: "\f1e5";
}

.mdi-calendar-clock:before {
	content: "\f1e6";
}

.mdi-calendar-multiple:before {
	content: "\f1e7";
}

.mdi-calendar-multiple-check:before {
	content: "\f1e8";
}

.mdi-calendar-plus:before {
	content: "\f1e9";
}

.mdi-calendar-remove:before {
	content: "\f1ea";
}

.mdi-calendar-text:before {
	content: "\f1eb";
}

.mdi-calendar-today:before {
	content: "\f1ec";
}

.mdi-call-made:before {
	content: "\f1ed";
}

.mdi-call-merge:before {
	content: "\f1ee";
}

.mdi-call-missed:before {
	content: "\f1ef";
}

.mdi-call-received:before {
	content: "\f1f0";
}

.mdi-call-split:before {
	content: "\f1f1";
}

.mdi-camcorder:before {
	content: "\f1f2";
}

.mdi-camcorder-box:before {
	content: "\f1f3";
}

.mdi-camcorder-box-off:before {
	content: "\f1f4";
}

.mdi-camcorder-off:before {
	content: "\f1f5";
}

.mdi-camera:before {
	content: "\f1f6";
}

.mdi-camera-enhance:before {
	content: "\f1f7";
}

.mdi-camera-front:before {
	content: "\f1f8";
}

.mdi-camera-front-variant:before {
	content: "\f1f9";
}

.mdi-camera-iris:before {
	content: "\f1fa";
}

.mdi-camera-party-mode:before {
	content: "\f1fb";
}

.mdi-camera-rear:before {
	content: "\f1fc";
}

.mdi-camera-rear-variant:before {
	content: "\f1fd";
}

.mdi-camera-switch:before {
	content: "\f1fe";
}

.mdi-camera-timer:before {
	content: "\f1ff";
}

.mdi-candycane:before {
	content: "\f200";
}

.mdi-car:before {
	content: "\f201";
}

.mdi-car-battery:before {
	content: "\f202";
}

.mdi-car-connected:before {
	content: "\f203";
}

.mdi-car-wash:before {
	content: "\f204";
}

.mdi-carrot:before {
	content: "\f205";
}

.mdi-cart:before {
	content: "\f206";
}

.mdi-cart-outline:before {
	content: "\f207";
}

.mdi-cart-plus:before {
	content: "\f208";
}

.mdi-case-sensitive-alt:before {
	content: "\f209";
}

.mdi-cash:before {
	content: "\f20a";
}

.mdi-cash-100:before {
	content: "\f20b";
}

.mdi-cash-multiple:before {
	content: "\f20c";
}

.mdi-cash-usd:before {
	content: "\f20d";
}

.mdi-cast:before {
	content: "\f20e";
}

.mdi-cast-connected:before {
	content: "\f20f";
}

.mdi-castle:before {
	content: "\f210";
}

.mdi-cat:before {
	content: "\f211";
}

.mdi-cellphone:before {
	content: "\f212";
}

.mdi-cellphone-android:before {
	content: "\f213";
}

.mdi-cellphone-basic:before {
	content: "\f214";
}

.mdi-cellphone-dock:before {
	content: "\f215";
}

.mdi-cellphone-iphone:before {
	content: "\f216";
}

.mdi-cellphone-link:before {
	content: "\f217";
}

.mdi-cellphone-link-off:before {
	content: "\f218";
}

.mdi-cellphone-settings:before {
	content: "\f219";
}

.mdi-certificate:before {
	content: "\f21a";
}

.mdi-chair-school:before {
	content: "\f21b";
}

.mdi-chart-arc:before {
	content: "\f21c";
}

.mdi-chart-areaspline:before {
	content: "\f21d";
}

.mdi-chart-bar:before {
	content: "\f21e";
}

.mdi-chart-histogram:before {
	content: "\f21f";
}

.mdi-chart-line:before {
	content: "\f220";
}

.mdi-chart-pie:before {
	content: "\f221";
}

.mdi-check:before {
	content: "\f222";
}

.mdi-check-all:before {
	content: "\f223";
}

.mdi-checkbox-blank:before {
	content: "\f224";
}

.mdi-checkbox-blank-circle:before {
	content: "\f225";
}

.mdi-checkbox-blank-circle-outline:before {
	content: "\f226";
}

.mdi-checkbox-blank-outline:before {
	content: "\f227";
}

.mdi-checkbox-marked:before {
	content: "\f228";
}

.mdi-checkbox-marked-circle:before {
	content: "\f229";
}

.mdi-checkbox-marked-circle-outline:before {
	content: "\f22a";
}

.mdi-checkbox-marked-outline:before {
	content: "\f22b";
}

.mdi-checkbox-multiple-blank:before {
	content: "\f22c";
}

.mdi-checkbox-multiple-blank-outline:before {
	content: "\f22d";
}

.mdi-checkbox-multiple-marked:before {
	content: "\f22e";
}

.mdi-checkbox-multiple-marked-outline:before {
	content: "\f22f";
}

.mdi-checkerboard:before {
	content: "\f230";
}

.mdi-chemical-weapon:before {
	content: "\f231";
}

.mdi-chevron-double-down:before {
	content: "\f232";
}

.mdi-chevron-double-left:before {
	content: "\f233";
}

.mdi-chevron-double-right:before {
	content: "\f234";
}

.mdi-chevron-double-up:before {
	content: "\f235";
}

.mdi-chevron-down:before {
	content: "\f236";
}

.mdi-chevron-left:before {
	content: "\f237";
}

.mdi-chevron-right:before {
	content: "\f238";
}

.mdi-chevron-up:before {
	content: "\f239";
}

.mdi-church:before {
	content: "\f23a";
}

.mdi-cisco-webex:before {
	content: "\f23b";
}

.mdi-city:before {
	content: "\f23c";
}

.mdi-clipboard:before {
	content: "\f23d";
}

.mdi-clipboard-account:before {
	content: "\f23e";
}

.mdi-clipboard-alert:before {
	content: "\f23f";
}

.mdi-clipboard-arrow-down:before {
	content: "\f240";
}

.mdi-clipboard-arrow-left:before {
	content: "\f241";
}

.mdi-clipboard-check:before {
	content: "\f242";
}

.mdi-clipboard-outline:before {
	content: "\f243";
}

.mdi-clipboard-text:before {
	content: "\f244";
}

.mdi-clippy:before {
	content: "\f245";
}

.mdi-clock:before {
	content: "\f246";
}

.mdi-clock-end:before {
	content: "\f247";
}

.mdi-clock-fast:before {
	content: "\f248";
}

.mdi-clock-in:before {
	content: "\f249";
}

.mdi-clock-out:before {
	content: "\f24a";
}

.mdi-clock-start:before {
	content: "\f24b";
}

.mdi-close:before {
	content: "\f24c";
}

.mdi-close-box:before {
	content: "\f24d";
}

.mdi-close-box-outline:before {
	content: "\f24e";
}

.mdi-close-circle:before {
	content: "\f24f";
}

.mdi-close-circle-outline:before {
	content: "\f250";
}

.mdi-close-network:before {
	content: "\f251";
}

.mdi-close-octagon:before {
	content: "\f252";
}

.mdi-close-octagon-outline:before {
	content: "\f253";
}

.mdi-closed-caption:before {
	content: "\f254";
}

.mdi-cloud:before {
	content: "\f255";
}

.mdi-cloud-check:before {
	content: "\f256";
}

.mdi-cloud-circle:before {
	content: "\f257";
}

.mdi-cloud-download:before {
	content: "\f258";
}

.mdi-cloud-outline:before {
	content: "\f259";
}

.mdi-cloud-outline-off:before {
	content: "\f25a";
}

.mdi-cloud-print:before {
	content: "\f25b";
}

.mdi-cloud-print-outline:before {
	content: "\f25c";
}

.mdi-cloud-upload:before {
	content: "\f25d";
}

.mdi-code-array:before {
	content: "\f25e";
}

.mdi-code-braces:before {
	content: "\f25f";
}

.mdi-code-brackets:before {
	content: "\f260";
}

.mdi-code-equal:before {
	content: "\f261";
}

.mdi-code-greater-than:before {
	content: "\f262";
}

.mdi-code-greater-than-or-equal:before {
	content: "\f263";
}

.mdi-code-less-than:before {
	content: "\f264";
}

.mdi-code-less-than-or-equal:before {
	content: "\f265";
}

.mdi-code-not-equal:before {
	content: "\f266";
}

.mdi-code-not-equal-variant:before {
	content: "\f267";
}

.mdi-code-parentheses:before {
	content: "\f268";
}

.mdi-code-string:before {
	content: "\f269";
}

.mdi-code-tags:before {
	content: "\f26a";
}

.mdi-codepen:before {
	content: "\f26b";
}

.mdi-coffee:before {
	content: "\f26c";
}

.mdi-coffee-to-go:before {
	content: "\f26d";
}

.mdi-coin:before {
	content: "\f26e";
}

.mdi-color-helper:before {
	content: "\f26f";
}

.mdi-comment:before {
	content: "\f270";
}

.mdi-comment-account:before {
	content: "\f271";
}

.mdi-comment-account-outline:before {
	content: "\f272";
}

.mdi-comment-alert:before {
	content: "\f273";
}

.mdi-comment-alert-outline:before {
	content: "\f274";
}

.mdi-comment-check:before {
	content: "\f275";
}

.mdi-comment-check-outline:before {
	content: "\f276";
}

.mdi-comment-multiple-outline:before {
	content: "\f277";
}

.mdi-comment-outline:before {
	content: "\f278";
}

.mdi-comment-plus-outline:before {
	content: "\f279";
}

.mdi-comment-processing:before {
	content: "\f27a";
}

.mdi-comment-processing-outline:before {
	content: "\f27b";
}

.mdi-comment-question-outline:before {
	content: "\f27c";
}

.mdi-comment-remove-outline:before {
	content: "\f27d";
}

.mdi-comment-text:before {
	content: "\f27e";
}

.mdi-comment-text-outline:before {
	content: "\f27f";
}

.mdi-compare:before {
	content: "\f280";
}

.mdi-compass:before {
	content: "\f281";
}

.mdi-compass-outline:before {
	content: "\f282";
}

.mdi-console:before {
	content: "\f283";
}

.mdi-contact-mail:before {
	content: "\f284";
}

.mdi-content-copy:before {
	content: "\f285";
}

.mdi-content-cut:before {
	content: "\f286";
}

.mdi-content-duplicate:before {
	content: "\f287";
}

.mdi-content-paste:before {
	content: "\f288";
}

.mdi-content-save:before {
	content: "\f289";
}

.mdi-content-save-all:before {
	content: "\f28a";
}

.mdi-contrast:before {
	content: "\f28b";
}

.mdi-contrast-box:before {
	content: "\f28c";
}

.mdi-contrast-circle:before {
	content: "\f28d";
}

.mdi-cookie:before {
	content: "\f28e";
}

.mdi-cow:before {
	content: "\f28f";
}

.mdi-credit-card:before {
	content: "\f290";
}

.mdi-credit-card-multiple:before {
	content: "\f291";
}

.mdi-credit-card-scan:before {
	content: "\f292";
}

.mdi-crop:before {
	content: "\f293";
}

.mdi-crop-free:before {
	content: "\f294";
}

.mdi-crop-landscape:before {
	content: "\f295";
}

.mdi-crop-portrait:before {
	content: "\f296";
}

.mdi-crop-square:before {
	content: "\f297";
}

.mdi-crosshairs:before {
	content: "\f298";
}

.mdi-crosshairs-gps:before {
	content: "\f299";
}

.mdi-crown:before {
	content: "\f29a";
}

.mdi-cube:before {
	content: "\f29b";
}

.mdi-cube-outline:before {
	content: "\f29c";
}

.mdi-cube-send:before {
	content: "\f29d";
}

.mdi-cube-unfolded:before {
	content: "\f29e";
}

.mdi-cup:before {
	content: "\f29f";
}

.mdi-cup-water:before {
	content: "\f2a0";
}

.mdi-currency-btc:before {
	content: "\f2a1";
}

.mdi-currency-eur:before {
	content: "\f2a2";
}

.mdi-currency-gbp:before {
	content: "\f2a3";
}

.mdi-currency-inr:before {
	content: "\f2a4";
}

.mdi-currency-ngn:before {
	content: "\f2a5";
}

.mdi-currency-rub:before {
	content: "\f2a6";
}

.mdi-currency-try:before {
	content: "\f2a7";
}

.mdi-currency-usd:before {
	content: "\f2a8";
}

.mdi-cursor-default:before {
	content: "\f2a9";
}

.mdi-cursor-default-outline:before {
	content: "\f2aa";
}

.mdi-cursor-move:before {
	content: "\f2ab";
}

.mdi-cursor-pointer:before {
	content: "\f2ac";
}

.mdi-database:before {
	content: "\f2ad";
}

.mdi-database-minus:before {
	content: "\f2ae";
}

.mdi-database-plus:before {
	content: "\f2af";
}

.mdi-debug-step-into:before {
	content: "\f2b0";
}

.mdi-debug-step-out:before {
	content: "\f2b1";
}

.mdi-debug-step-over:before {
	content: "\f2b2";
}

.mdi-decimal-decrease:before {
	content: "\f2b3";
}

.mdi-decimal-increase:before {
	content: "\f2b4";
}

.mdi-delete:before {
	content: "\f2b5";
}

.mdi-delete-variant:before {
	content: "\f2b6";
}

.mdi-delta:before {
	content: "\f2b7";
}

.mdi-deskphone:before {
	content: "\f2b8";
}

.mdi-desktop-mac:before {
	content: "\f2b9";
}

.mdi-desktop-tower:before {
	content: "\f2ba";
}

.mdi-details:before {
	content: "\f2bb";
}

.mdi-deviantart:before {
	content: "\f2bc";
}

.mdi-diamond:before {
	content: "\f2bd";
}

.mdi-dice:before {
	content: "\f2be";
}

.mdi-dice-1:before {
	content: "\f2bf";
}

.mdi-dice-2:before {
	content: "\f2c0";
}

.mdi-dice-3:before {
	content: "\f2c1";
}

.mdi-dice-4:before {
	content: "\f2c2";
}

.mdi-dice-5:before {
	content: "\f2c3";
}

.mdi-dice-6:before {
	content: "\f2c4";
}

.mdi-directions:before {
	content: "\f2c5";
}

.mdi-disk-alert:before {
	content: "\f2c6";
}

.mdi-disqus:before {
	content: "\f2c7";
}

.mdi-disqus-outline:before {
	content: "\f2c8";
}

.mdi-division:before {
	content: "\f2c9";
}

.mdi-division-box:before {
	content: "\f2ca";
}

.mdi-dns:before {
	content: "\f2cb";
}

.mdi-domain:before {
	content: "\f2cc";
}

.mdi-dots-horizontal:before {
	content: "\f2cd";
}

.mdi-dots-vertical:before {
	content: "\f2ce";
}

.mdi-download:before {
	content: "\f2cf";
}

.mdi-drag:before {
	content: "\f2d0";
}

.mdi-drag-horizontal:before {
	content: "\f2d1";
}

.mdi-drag-vertical:before {
	content: "\f2d2";
}

.mdi-drawing:before {
	content: "\f2d3";
}

.mdi-drawing-box:before {
	content: "\f2d4";
}

.mdi-dribbble:before {
	content: "\f2d5";
}

.mdi-dribbble-box:before {
	content: "\f2d6";
}

.mdi-drone:before {
	content: "\f2d7";
}

.mdi-dropbox:before {
	content: "\f2d8";
}

.mdi-drupal:before {
	content: "\f2d9";
}

.mdi-duck:before {
	content: "\f2da";
}

.mdi-dumbbell:before {
	content: "\f2db";
}

.mdi-earth:before {
	content: "\f2dc";
}

.mdi-earth-off:before {
	content: "\f2dd";
}

.mdi-edge:before {
	content: "\f2de";
}

.mdi-eject:before {
	content: "\f2df";
}

.mdi-elevation-decline:before {
	content: "\f2e0";
}

.mdi-elevation-rise:before {
	content: "\f2e1";
}

.mdi-elevator:before {
	content: "\f2e2";
}

.mdi-email:before {
	content: "\f2e3";
}

.mdi-email-open:before {
	content: "\f2e4";
}

.mdi-email-outline:before {
	content: "\f2e5";
}

.mdi-email-secure:before {
	content: "\f2e6";
}

.mdi-emoticon:before {
	content: "\f2e7";
}

.mdi-emoticon-cool:before {
	content: "\f2e8";
}

.mdi-emoticon-devil:before {
	content: "\f2e9";
}

.mdi-emoticon-happy:before {
	content: "\f2ea";
}

.mdi-emoticon-neutral:before {
	content: "\f2eb";
}

.mdi-emoticon-poop:before {
	content: "\f2ec";
}

.mdi-emoticon-sad:before {
	content: "\f2ed";
}

.mdi-emoticon-tongue:before {
	content: "\f2ee";
}

.mdi-engine:before {
	content: "\f2ef";
}

.mdi-engine-outline:before {
	content: "\f2f0";
}

.mdi-equal:before {
	content: "\f2f1";
}

.mdi-equal-box:before {
	content: "\f2f2";
}

.mdi-eraser:before {
	content: "\f2f3";
}

.mdi-escalator:before {
	content: "\f2f4";
}

.mdi-ethernet:before {
	content: "\f2f5";
}

.mdi-ethernet-cable:before {
	content: "\f2f6";
}

.mdi-ethernet-cable-off:before {
	content: "\f2f7";
}

.mdi-etsy:before {
	content: "\f2f8";
}

.mdi-evernote:before {
	content: "\f2f9";
}

.mdi-exclamation:before {
	content: "\f2fa";
}

.mdi-exit-to-app:before {
	content: "\f2fb";
}

.mdi-export:before {
	content: "\f2fc";
}

.mdi-eye:before {
	content: "\f2fd";
}

.mdi-eye-off:before {
	content: "\f2fe";
}

.mdi-eyedropper:before {
	content: "\f2ff";
}

.mdi-eyedropper-variant:before {
	content: "\f300";
}

.mdi-facebook:before {
	content: "\f301";
}

.mdi-facebook-box:before {
	content: "\f302";
}

.mdi-facebook-messenger:before {
	content: "\f303";
}

.mdi-factory:before {
	content: "\f304";
}

.mdi-fan:before {
	content: "\f305";
}

.mdi-fast-forward:before {
	content: "\f306";
}

.mdi-fax:before {
	content: "\f307";
}

.mdi-ferry:before {
	content: "\f308";
}

.mdi-file:before {
	content: "\f309";
}

.mdi-file-chart:before {
	content: "\f30a";
}

.mdi-file-check:before {
	content: "\f30b";
}

.mdi-file-cloud:before {
	content: "\f30c";
}

.mdi-file-delimited:before {
	content: "\f30d";
}

.mdi-file-document:before {
	content: "\f30e";
}

.mdi-file-document-box:before {
	content: "\f30f";
}

.mdi-file-excel:before {
	content: "\f310";
}

.mdi-file-excel-box:before {
	content: "\f311";
}

.mdi-file-export:before {
	content: "\f312";
}

.mdi-file-find:before {
	content: "\f313";
}

.mdi-file-image:before {
	content: "\f314";
}

.mdi-file-import:before {
	content: "\f315";
}

.mdi-file-lock:before {
	content: "\f316";
}

.mdi-file-multiple:before {
	content: "\f317";
}

.mdi-file-music:before {
	content: "\f318";
}

.mdi-file-outline:before {
	content: "\f319";
}

.mdi-file-pdf:before {
	content: "\f31a";
}

.mdi-file-pdf-box:before {
	content: "\f31b";
}

.mdi-file-powerpoint:before {
	content: "\f31c";
}

.mdi-file-powerpoint-box:before {
	content: "\f31d";
}

.mdi-file-presentation-box:before {
	content: "\f31e";
}

.mdi-file-send:before {
	content: "\f31f";
}

.mdi-file-video:before {
	content: "\f320";
}

.mdi-file-word:before {
	content: "\f321";
}

.mdi-file-word-box:before {
	content: "\f322";
}

.mdi-file-xml:before {
	content: "\f323";
}

.mdi-film:before {
	content: "\f324";
}

.mdi-filmstrip:before {
	content: "\f325";
}

.mdi-filmstrip-off:before {
	content: "\f326";
}

.mdi-filter:before {
	content: "\f327";
}

.mdi-filter-outline:before {
	content: "\f328";
}

.mdi-filter-remove:before {
	content: "\f329";
}

.mdi-filter-remove-outline:before {
	content: "\f32a";
}

.mdi-filter-variant:before {
	content: "\f32b";
}

.mdi-fingerprint:before {
	content: "\f32c";
}

.mdi-fire:before {
	content: "\f32d";
}

.mdi-firefox:before {
	content: "\f32e";
}

.mdi-fish:before {
	content: "\f32f";
}

.mdi-flag:before {
	content: "\f330";
}

.mdi-flag-checkered:before {
	content: "\f331";
}

.mdi-flag-outline:before {
	content: "\f332";
}

.mdi-flag-outline-variant:before {
	content: "\f333";
}

.mdi-flag-triangle:before {
	content: "\f334";
}

.mdi-flag-variant:before {
	content: "\f335";
}

.mdi-flash:before {
	content: "\f336";
}

.mdi-flash-auto:before {
	content: "\f337";
}

.mdi-flash-off:before {
	content: "\f338";
}

.mdi-flashlight:before {
	content: "\f339";
}

.mdi-flashlight-off:before {
	content: "\f33a";
}

.mdi-flattr:before {
	content: "\f33b";
}

.mdi-flip-to-back:before {
	content: "\f33c";
}

.mdi-flip-to-front:before {
	content: "\f33d";
}

.mdi-floppy:before {
	content: "\f33e";
}

.mdi-flower:before {
	content: "\f33f";
}

.mdi-folder:before {
	content: "\f340";
}

.mdi-folder-account:before {
	content: "\f341";
}

.mdi-folder-download:before {
	content: "\f342";
}

.mdi-folder-google-drive:before {
	content: "\f343";
}

.mdi-folder-image:before {
	content: "\f344";
}

.mdi-folder-lock:before {
	content: "\f345";
}

.mdi-folder-lock-open:before {
	content: "\f346";
}

.mdi-folder-move:before {
	content: "\f347";
}

.mdi-folder-multiple:before {
	content: "\f348";
}

.mdi-folder-multiple-image:before {
	content: "\f349";
}

.mdi-folder-multiple-outline:before {
	content: "\f34a";
}

.mdi-folder-outline:before {
	content: "\f34b";
}

.mdi-folder-plus:before {
	content: "\f34c";
}

.mdi-folder-remove:before {
	content: "\f34d";
}

.mdi-folder-upload:before {
	content: "\f34e";
}

.mdi-food:before {
	content: "\f34f";
}

.mdi-food-apple:before {
	content: "\f350";
}

.mdi-food-variant:before {
	content: "\f351";
}

.mdi-football:before {
	content: "\f352";
}

.mdi-football-australian:before {
	content: "\f353";
}

.mdi-football-helmet:before {
	content: "\f354";
}

.mdi-format-align-center:before {
	content: "\f355";
}

.mdi-format-align-justify:before {
	content: "\f356";
}

.mdi-format-align-left:before {
	content: "\f357";
}

.mdi-format-align-right:before {
	content: "\f358";
}

.mdi-format-bold:before {
	content: "\f359";
}

.mdi-format-clear:before {
	content: "\f35a";
}

.mdi-format-color-fill:before {
	content: "\f35b";
}

.mdi-format-float-center:before {
	content: "\f35c";
}

.mdi-format-float-left:before {
	content: "\f35d";
}

.mdi-format-float-none:before {
	content: "\f35e";
}

.mdi-format-float-right:before {
	content: "\f35f";
}

.mdi-format-header-1:before {
	content: "\f360";
}

.mdi-format-header-2:before {
	content: "\f361";
}

.mdi-format-header-3:before {
	content: "\f362";
}

.mdi-format-header-4:before {
	content: "\f363";
}

.mdi-format-header-5:before {
	content: "\f364";
}

.mdi-format-header-6:before {
	content: "\f365";
}

.mdi-format-header-decrease:before {
	content: "\f366";
}

.mdi-format-header-equal:before {
	content: "\f367";
}

.mdi-format-header-increase:before {
	content: "\f368";
}

.mdi-format-header-pound:before {
	content: "\f369";
}

.mdi-format-indent-decrease:before {
	content: "\f36a";
}

.mdi-format-indent-increase:before {
	content: "\f36b";
}

.mdi-format-italic:before {
	content: "\f36c";
}

.mdi-format-line-spacing:before {
	content: "\f36d";
}

.mdi-format-list-bulleted:before {
	content: "\f36e";
}

.mdi-format-list-bulleted-type:before {
	content: "\f36f";
}

.mdi-format-list-numbers:before {
	content: "\f370";
}

.mdi-format-paint:before {
	content: "\f371";
}

.mdi-format-paragraph:before {
	content: "\f372";
}

.mdi-format-quote:before {
	content: "\f373";
}

.mdi-format-size:before {
	content: "\f374";
}

.mdi-format-strikethrough:before {
	content: "\f375";
}

.mdi-format-strikethrough-variant:before {
	content: "\f376";
}

.mdi-format-subscript:before {
	content: "\f377";
}

.mdi-format-superscript:before {
	content: "\f378";
}

.mdi-format-text:before {
	content: "\f379";
}

.mdi-format-textdirection-l-to-r:before {
	content: "\f37a";
}

.mdi-format-textdirection-r-to-l:before {
	content: "\f37b";
}

.mdi-format-underline:before {
	content: "\f37c";
}

.mdi-format-wrap-inline:before {
	content: "\f37d";
}

.mdi-format-wrap-square:before {
	content: "\f37e";
}

.mdi-format-wrap-tight:before {
	content: "\f37f";
}

.mdi-format-wrap-top-bottom:before {
	content: "\f380";
}

.mdi-forum:before {
	content: "\f381";
}

.mdi-forward:before {
	content: "\f382";
}

.mdi-foursquare:before {
	content: "\f383";
}

.mdi-fridge:before {
	content: "\f384";
}

.mdi-fridge-filled:before {
	content: "\f385";
}

.mdi-fridge-filled-bottom:before {
	content: "\f386";
}

.mdi-fridge-filled-top:before {
	content: "\f387";
}

.mdi-fullscreen:before {
	content: "\f388";
}

.mdi-fullscreen-exit:before {
	content: "\f389";
}

.mdi-function:before {
	content: "\f38a";
}

.mdi-gamepad:before {
	content: "\f38b";
}

.mdi-gamepad-variant:before {
	content: "\f38c";
}

.mdi-gas-station:before {
	content: "\f38d";
}

.mdi-gate:before {
	content: "\f38e";
}

.mdi-gauge:before {
	content: "\f38f";
}

.mdi-gavel:before {
	content: "\f390";
}

.mdi-gender-female:before {
	content: "\f391";
}

.mdi-gender-male:before {
	content: "\f392";
}

.mdi-gender-male-female:before {
	content: "\f393";
}

.mdi-gender-transgender:before {
	content: "\f394";
}

.mdi-ghost:before {
	content: "\f395";
}

.mdi-gift:before {
	content: "\f396";
}

.mdi-git:before {
	content: "\f397";
}

.mdi-github-box:before {
	content: "\f398";
}

.mdi-github-circle:before {
	content: "\f399";
}

.mdi-glass-flute:before {
	content: "\f39a";
}

.mdi-glass-mug:before {
	content: "\f39b";
}

.mdi-glass-stange:before {
	content: "\f39c";
}

.mdi-glass-tulip:before {
	content: "\f39d";
}

.mdi-glasses:before {
	content: "\f39e";
}

.mdi-gmail:before {
	content: "\f39f";
}

.mdi-google:before {
	content: "\f3a0";
}

.mdi-google-cardboard:before {
	content: "\f3a1";
}

.mdi-google-chrome:before {
	content: "\f3a2";
}

.mdi-google-circles:before {
	content: "\f3a3";
}

.mdi-google-circles-communities:before {
	content: "\f3a4";
}

.mdi-google-circles-extended:before {
	content: "\f3a5";
}

.mdi-google-circles-group:before {
	content: "\f3a6";
}

.mdi-google-controller:before {
	content: "\f3a7";
}

.mdi-google-controller-off:before {
	content: "\f3a8";
}

.mdi-google-drive:before {
	content: "\f3a9";
}

.mdi-google-earth:before {
	content: "\f3aa";
}

.mdi-google-glass:before {
	content: "\f3ab";
}

.mdi-google-nearby:before {
	content: "\f3ac";
}

.mdi-google-pages:before {
	content: "\f3ad";
}

.mdi-google-physical-web:before {
	content: "\f3ae";
}

.mdi-google-play:before {
	content: "\f3af";
}

.mdi-google-plus:before {
	content: "\f3b0";
}

.mdi-google-plus-box:before {
	content: "\f3b1";
}

.mdi-google-translate:before {
	content: "\f3b2";
}

.mdi-google-wallet:before {
	content: "\f3b3";
}

.mdi-grid:before {
	content: "\f3b4";
}

.mdi-grid-off:before {
	content: "\f3b5";
}

.mdi-group:before {
	content: "\f3b6";
}

.mdi-guitar:before {
	content: "\f3b7";
}

.mdi-guitar-pick:before {
	content: "\f3b8";
}

.mdi-guitar-pick-outline:before {
	content: "\f3b9";
}

.mdi-hand-pointing-right:before {
	content: "\f3ba";
}

.mdi-hanger:before {
	content: "\f3bb";
}

.mdi-hangouts:before {
	content: "\f3bc";
}

.mdi-harddisk:before {
	content: "\f3bd";
}

.mdi-headphones:before {
	content: "\f3be";
}

.mdi-headphones-box:before {
	content: "\f3bf";
}

.mdi-headphones-settings:before {
	content: "\f3c0";
}

.mdi-headset:before {
	content: "\f3c1";
}

.mdi-headset-dock:before {
	content: "\f3c2";
}

.mdi-headset-off:before {
	content: "\f3c3";
}

.mdi-heart:before {
	content: "\f3c4";
}

.mdi-heart-box:before {
	content: "\f3c5";
}

.mdi-heart-box-outline:before {
	content: "\f3c6";
}

.mdi-heart-broken:before {
	content: "\f3c7";
}

.mdi-heart-outline:before {
	content: "\f3c8";
}

.mdi-help:before {
	content: "\f3c9";
}

.mdi-help-circle:before {
	content: "\f3ca";
}

.mdi-hexagon:before {
	content: "\f3cb";
}

.mdi-hexagon-outline:before {
	content: "\f3cc";
}

.mdi-history:before {
	content: "\f3cd";
}

.mdi-hololens:before {
	content: "\f3ce";
}

.mdi-home:before {
	content: "\f3cf";
}

.mdi-home-modern:before {
	content: "\f3d0";
}

.mdi-home-variant:before {
	content: "\f3d1";
}

.mdi-hops:before {
	content: "\f3d2";
}

.mdi-hospital:before {
	content: "\f3d3";
}

.mdi-hospital-building:before {
	content: "\f3d4";
}

.mdi-hospital-marker:before {
	content: "\f3d5";
}

.mdi-hotel:before {
	content: "\f3d6";
}

.mdi-houzz:before {
	content: "\f3d7";
}

.mdi-houzz-box:before {
	content: "\f3d8";
}

.mdi-human:before {
	content: "\f3d9";
}

.mdi-human-child:before {
	content: "\f3da";
}

.mdi-human-male-female:before {
	content: "\f3db";
}

.mdi-image:before {
	content: "\f3dc";
}

.mdi-image-album:before {
	content: "\f3dd";
}

.mdi-image-area:before {
	content: "\f3de";
}

.mdi-image-area-close:before {
	content: "\f3df";
}

.mdi-image-broken:before {
	content: "\f3e0";
}

.mdi-image-broken-variant:before {
	content: "\f3e1";
}

.mdi-image-filter:before {
	content: "\f3e2";
}

.mdi-image-filter-black-white:before {
	content: "\f3e3";
}

.mdi-image-filter-center-focus:before {
	content: "\f3e4";
}

.mdi-image-filter-center-focus-weak:before {
	content: "\f3e5";
}

.mdi-image-filter-drama:before {
	content: "\f3e6";
}

.mdi-image-filter-frames:before {
	content: "\f3e7";
}

.mdi-image-filter-hdr:before {
	content: "\f3e8";
}

.mdi-image-filter-none:before {
	content: "\f3e9";
}

.mdi-image-filter-tilt-shift:before {
	content: "\f3ea";
}

.mdi-image-filter-vintage:before {
	content: "\f3eb";
}

.mdi-image-multiple:before {
	content: "\f3ec";
}

.mdi-import:before {
	content: "\f3ed";
}

.mdi-inbox:before {
	content: "\f3ee";
}

.mdi-information:before {
	content: "\f3ef";
}

.mdi-information-outline:before {
	content: "\f3f0";
}

.mdi-instagram:before {
	content: "\f3f1";
}

.mdi-instapaper:before {
	content: "\f3f2";
}

.mdi-internet-explorer:before {
	content: "\f3f3";
}

.mdi-invert-colors:before {
	content: "\f3f4";
}

.mdi-jeepney:before {
	content: "\f3f5";
}

.mdi-jira:before {
	content: "\f3f6";
}

.mdi-jsfiddle:before {
	content: "\f3f7";
}

.mdi-keg:before {
	content: "\f3f8";
}

.mdi-key:before {
	content: "\f3f9";
}

.mdi-key-change:before {
	content: "\f3fa";
}

.mdi-key-minus:before {
	content: "\f3fb";
}

.mdi-key-plus:before {
	content: "\f3fc";
}

.mdi-key-remove:before {
	content: "\f3fd";
}

.mdi-key-variant:before {
	content: "\f3fe";
}

.mdi-keyboard:before {
	content: "\f3ff";
}

.mdi-keyboard-backspace:before {
	content: "\f400";
}

.mdi-keyboard-caps:before {
	content: "\f401";
}

.mdi-keyboard-close:before {
	content: "\f402";
}

.mdi-keyboard-off:before {
	content: "\f403";
}

.mdi-keyboard-return:before {
	content: "\f404";
}

.mdi-keyboard-tab:before {
	content: "\f405";
}

.mdi-keyboard-variant:before {
	content: "\f406";
}

.mdi-label:before {
	content: "\f407";
}

.mdi-label-outline:before {
	content: "\f408";
}

.mdi-lan:before {
	content: "\f409";
}

.mdi-lan-connect:before {
	content: "\f40a";
}

.mdi-lan-disconnect:before {
	content: "\f40b";
}

.mdi-lan-pending:before {
	content: "\f40c";
}

.mdi-language-csharp:before {
	content: "\f40d";
}

.mdi-language-css3:before {
	content: "\f40e";
}

.mdi-language-html5:before {
	content: "\f40f";
}

.mdi-language-javascript:before {
	content: "\f410";
}

.mdi-language-php:before {
	content: "\f411";
}

.mdi-language-python:before {
	content: "\f412";
}

.mdi-language-python-text:before {
	content: "\f413";
}

.mdi-laptop:before {
	content: "\f414";
}

.mdi-laptop-chromebook:before {
	content: "\f415";
}

.mdi-laptop-mac:before {
	content: "\f416";
}

.mdi-laptop-windows:before {
	content: "\f417";
}

.mdi-lastfm:before {
	content: "\f418";
}

.mdi-launch:before {
	content: "\f419";
}

.mdi-layers:before {
	content: "\f41a";
}

.mdi-layers-off:before {
	content: "\f41b";
}

.mdi-leaf:before {
	content: "\f41c";
}

.mdi-led-off:before {
	content: "\f41d";
}

.mdi-led-on:before {
	content: "\f41e";
}

.mdi-led-outline:before {
	content: "\f41f";
}

.mdi-led-variant-off:before {
	content: "\f420";
}

.mdi-led-variant-on:before {
	content: "\f421";
}

.mdi-led-variant-outline:before {
	content: "\f422";
}

.mdi-library:before {
	content: "\f423";
}

.mdi-library-books:before {
	content: "\f424";
}

.mdi-library-music:before {
	content: "\f425";
}

.mdi-library-plus:before {
	content: "\f426";
}

.mdi-lightbulb:before {
	content: "\f427";
}

.mdi-lightbulb-outline:before {
	content: "\f428";
}

.mdi-link:before {
	content: "\f429";
}

.mdi-link-off:before {
	content: "\f42a";
}

.mdi-link-variant:before {
	content: "\f42b";
}

.mdi-link-variant-off:before {
	content: "\f42c";
}

.mdi-linkedin:before {
	content: "\f42d";
}

.mdi-linkedin-box:before {
	content: "\f42e";
}

.mdi-linux:before {
	content: "\f42f";
}

.mdi-lock:before {
	content: "\f430";
}

.mdi-lock-open:before {
	content: "\f431";
}

.mdi-lock-open-outline:before {
	content: "\f432";
}

.mdi-lock-outline:before {
	content: "\f433";
}

.mdi-login:before {
	content: "\f434";
}

.mdi-logout:before {
	content: "\f435";
}

.mdi-looks:before {
	content: "\f436";
}

.mdi-loupe:before {
	content: "\f437";
}

.mdi-lumx:before {
	content: "\f438";
}

.mdi-magnet:before {
	content: "\f439";
}

.mdi-magnet-on:before {
	content: "\f43a";
}

.mdi-magnify:before {
	content: "\f43b";
}

.mdi-magnify-minus:before {
	content: "\f43c";
}

.mdi-magnify-plus:before {
	content: "\f43d";
}

.mdi-mail-ru:before {
	content: "\f43e";
}

.mdi-map:before {
	content: "\f43f";
}

.mdi-map-marker:before {
	content: "\f440";
}

.mdi-map-marker-circle:before {
	content: "\f441";
}

.mdi-map-marker-multiple:before {
	content: "\f442";
}

.mdi-map-marker-off:before {
	content: "\f443";
}

.mdi-map-marker-radius:before {
	content: "\f444";
}

.mdi-margin:before {
	content: "\f445";
}

.mdi-markdown:before {
	content: "\f446";
}

.mdi-marker-check:before {
	content: "\f447";
}

.mdi-martini:before {
	content: "\f448";
}

.mdi-material-ui:before {
	content: "\f449";
}

.mdi-math-compass:before {
	content: "\f44a";
}

.mdi-maxcdn:before {
	content: "\f44b";
}

.mdi-medium:before {
	content: "\f44c";
}

.mdi-memory:before {
	content: "\f44d";
}

.mdi-menu:before {
	content: "\f44e";
}

.mdi-menu-down:before {
	content: "\f44f";
}

.mdi-menu-left:before {
	content: "\f450";
}

.mdi-menu-right:before {
	content: "\f451";
}

.mdi-menu-up:before {
	content: "\f452";
}

.mdi-message:before {
	content: "\f453";
}

.mdi-message-alert:before {
	content: "\f454";
}

.mdi-message-draw:before {
	content: "\f455";
}

.mdi-message-image:before {
	content: "\f456";
}

.mdi-message-outline:before {
	content: "\f457";
}

.mdi-message-processing:before {
	content: "\f458";
}

.mdi-message-reply:before {
	content: "\f459";
}

.mdi-message-reply-text:before {
	content: "\f45a";
}

.mdi-message-text:before {
	content: "\f45b";
}

.mdi-message-text-outline:before {
	content: "\f45c";
}

.mdi-message-video:before {
	content: "\f45d";
}

.mdi-microphone:before {
	content: "\f45e";
}

.mdi-microphone-off:before {
	content: "\f45f";
}

.mdi-microphone-outline:before {
	content: "\f460";
}

.mdi-microphone-settings:before {
	content: "\f461";
}

.mdi-microphone-variant:before {
	content: "\f462";
}

.mdi-microphone-variant-off:before {
	content: "\f463";
}

.mdi-microsoft:before {
	content: "\f464";
}

.mdi-minus:before {
	content: "\f465";
}

.mdi-minus-box:before {
	content: "\f466";
}

.mdi-minus-circle:before {
	content: "\f467";
}

.mdi-minus-circle-outline:before {
	content: "\f468";
}

.mdi-minus-network:before {
	content: "\f469";
}

.mdi-monitor:before {
	content: "\f46a";
}

.mdi-monitor-multiple:before {
	content: "\f46b";
}

.mdi-more:before {
	content: "\f46c";
}

.mdi-motorbike:before {
	content: "\f46d";
}

.mdi-mouse:before {
	content: "\f46e";
}

.mdi-mouse-off:before {
	content: "\f46f";
}

.mdi-mouse-variant:before {
	content: "\f470";
}

.mdi-mouse-variant-off:before {
	content: "\f471";
}

.mdi-movie:before {
	content: "\f472";
}

.mdi-multiplication:before {
	content: "\f473";
}

.mdi-multiplication-box:before {
	content: "\f474";
}

.mdi-music-box:before {
	content: "\f475";
}

.mdi-music-box-outline:before {
	content: "\f476";
}

.mdi-music-circle:before {
	content: "\f477";
}

.mdi-music-note:before {
	content: "\f478";
}

.mdi-music-note-eighth:before {
	content: "\f479";
}

.mdi-music-note-half:before {
	content: "\f47a";
}

.mdi-music-note-off:before {
	content: "\f47b";
}

.mdi-music-note-quarter:before {
	content: "\f47c";
}

.mdi-music-note-sixteenth:before {
	content: "\f47d";
}

.mdi-music-note-whole:before {
	content: "\f47e";
}

.mdi-nature:before {
	content: "\f47f";
}

.mdi-nature-people:before {
	content: "\f480";
}

.mdi-navigation:before {
	content: "\f481";
}

.mdi-needle:before {
	content: "\f482";
}

.mdi-nest-protect:before {
	content: "\f483";
}

.mdi-nest-thermostat:before {
	content: "\f484";
}

.mdi-newspaper:before {
	content: "\f485";
}

.mdi-nfc:before {
	content: "\f486";
}

.mdi-nfc-tap:before {
	content: "\f487";
}

.mdi-nfc-variant:before {
	content: "\f488";
}

.mdi-nodejs:before {
	content: "\f489";
}

.mdi-note:before {
	content: "\f48a";
}

.mdi-note-outline:before {
	content: "\f48b";
}

.mdi-note-plus:before {
	content: "\f48c";
}

.mdi-note-plus-outline:before {
	content: "\f48d";
}

.mdi-note-text:before {
	content: "\f48e";
}

.mdi-notification-clear-all:before {
	content: "\f48f";
}

.mdi-numeric:before {
	content: "\f490";
}

.mdi-numeric-0-box:before {
	content: "\f491";
}

.mdi-numeric-0-box-multiple-outline:before {
	content: "\f492";
}

.mdi-numeric-0-box-outline:before {
	content: "\f493";
}

.mdi-numeric-1-box:before {
	content: "\f494";
}

.mdi-numeric-1-box-multiple-outline:before {
	content: "\f495";
}

.mdi-numeric-1-box-outline:before {
	content: "\f496";
}

.mdi-numeric-2-box:before {
	content: "\f497";
}

.mdi-numeric-2-box-multiple-outline:before {
	content: "\f498";
}

.mdi-numeric-2-box-outline:before {
	content: "\f499";
}

.mdi-numeric-3-box:before {
	content: "\f49a";
}

.mdi-numeric-3-box-multiple-outline:before {
	content: "\f49b";
}

.mdi-numeric-3-box-outline:before {
	content: "\f49c";
}

.mdi-numeric-4-box:before {
	content: "\f49d";
}

.mdi-numeric-4-box-multiple-outline:before {
	content: "\f49e";
}

.mdi-numeric-4-box-outline:before {
	content: "\f49f";
}

.mdi-numeric-5-box:before {
	content: "\f4a0";
}

.mdi-numeric-5-box-multiple-outline:before {
	content: "\f4a1";
}

.mdi-numeric-5-box-outline:before {
	content: "\f4a2";
}

.mdi-numeric-6-box:before {
	content: "\f4a3";
}

.mdi-numeric-6-box-multiple-outline:before {
	content: "\f4a4";
}

.mdi-numeric-6-box-outline:before {
	content: "\f4a5";
}

.mdi-numeric-7-box:before {
	content: "\f4a6";
}

.mdi-numeric-7-box-multiple-outline:before {
	content: "\f4a7";
}

.mdi-numeric-7-box-outline:before {
	content: "\f4a8";
}

.mdi-numeric-8-box:before {
	content: "\f4a9";
}

.mdi-numeric-8-box-multiple-outline:before {
	content: "\f4aa";
}

.mdi-numeric-8-box-outline:before {
	content: "\f4ab";
}

.mdi-numeric-9-box:before {
	content: "\f4ac";
}

.mdi-numeric-9-box-multiple-outline:before {
	content: "\f4ad";
}

.mdi-numeric-9-box-outline:before {
	content: "\f4ae";
}

.mdi-numeric-9-plus-box:before {
	content: "\f4af";
}

.mdi-numeric-9-plus-box-multiple-outline:before {
	content: "\f4b0";
}

.mdi-numeric-9-plus-box-outline:before {
	content: "\f4b1";
}

.mdi-nutrition:before {
	content: "\f4b2";
}

.mdi-octagon:before {
	content: "\f4b3";
}

.mdi-octagon-outline:before {
	content: "\f4b4";
}

.mdi-odnoklassniki:before {
	content: "\f4b5";
}

.mdi-office:before {
	content: "\f4b6";
}

.mdi-oil:before {
	content: "\f4b7";
}

.mdi-oil-temperature:before {
	content: "\f4b8";
}

.mdi-omega:before {
	content: "\f4b9";
}

.mdi-onedrive:before {
	content: "\f4ba";
}

.mdi-open-in-app:before {
	content: "\f4bb";
}

.mdi-open-in-new:before {
	content: "\f4bc";
}

.mdi-opera:before {
	content: "\f4bd";
}

.mdi-ornament:before {
	content: "\f4be";
}

.mdi-ornament-variant:before {
	content: "\f4bf";
}

.mdi-outbox:before {
	content: "\f4c0";
}

.mdi-owl:before {
	content: "\f4c1";
}

.mdi-package:before {
	content: "\f4c2";
}

.mdi-package-down:before {
	content: "\f4c3";
}

.mdi-package-up:before {
	content: "\f4c4";
}

.mdi-package-variant:before {
	content: "\f4c5";
}

.mdi-package-variant-closed:before {
	content: "\f4c6";
}

.mdi-palette:before {
	content: "\f4c7";
}

.mdi-palette-advanced:before {
	content: "\f4c8";
}

.mdi-panda:before {
	content: "\f4c9";
}

.mdi-pandora:before {
	content: "\f4ca";
}

.mdi-panorama:before {
	content: "\f4cb";
}

.mdi-panorama-fisheye:before {
	content: "\f4cc";
}

.mdi-panorama-horizontal:before {
	content: "\f4cd";
}

.mdi-panorama-vertical:before {
	content: "\f4ce";
}

.mdi-panorama-wide-angle:before {
	content: "\f4cf";
}

.mdi-paper-cut-vertical:before {
	content: "\f4d0";
}

.mdi-paperclip:before {
	content: "\f4d1";
}

.mdi-parking:before {
	content: "\f4d2";
}

.mdi-pause:before {
	content: "\f4d3";
}

.mdi-pause-circle:before {
	content: "\f4d4";
}

.mdi-pause-circle-outline:before {
	content: "\f4d5";
}

.mdi-pause-octagon:before {
	content: "\f4d6";
}

.mdi-pause-octagon-outline:before {
	content: "\f4d7";
}

.mdi-paw:before {
	content: "\f4d8";
}

.mdi-pen:before {
	content: "\f4d9";
}

.mdi-pencil:before {
	content: "\f4da";
}

.mdi-pencil-box:before {
	content: "\f4db";
}

.mdi-pencil-box-outline:before {
	content: "\f4dc";
}

.mdi-pencil-lock:before {
	content: "\f4dd";
}

.mdi-pencil-off:before {
	content: "\f4de";
}

.mdi-percent:before {
	content: "\f4df";
}

.mdi-pharmacy:before {
	content: "\f4e0";
}

.mdi-phone:before {
	content: "\f4e1";
}

.mdi-phone-bluetooth:before {
	content: "\f4e2";
}

.mdi-phone-forward:before {
	content: "\f4e3";
}

.mdi-phone-hangup:before {
	content: "\f4e4";
}

.mdi-phone-in-talk:before {
	content: "\f4e5";
}

.mdi-phone-incoming:before {
	content: "\f4e6";
}

.mdi-phone-locked:before {
	content: "\f4e7";
}

.mdi-phone-log:before {
	content: "\f4e8";
}

.mdi-phone-missed:before {
	content: "\f4e9";
}

.mdi-phone-outgoing:before {
	content: "\f4ea";
}

.mdi-phone-paused:before {
	content: "\f4eb";
}

.mdi-phone-settings:before {
	content: "\f4ec";
}

.mdi-phone-voip:before {
	content: "\f4ed";
}

.mdi-pi:before {
	content: "\f4ee";
}

.mdi-pi-box:before {
	content: "\f4ef";
}

.mdi-pig:before {
	content: "\f4f0";
}

.mdi-pill:before {
	content: "\f4f1";
}

.mdi-pin:before {
	content: "\f4f2";
}

.mdi-pin-off:before {
	content: "\f4f3";
}

.mdi-pine-tree:before {
	content: "\f4f4";
}

.mdi-pine-tree-box:before {
	content: "\f4f5";
}

.mdi-pinterest:before {
	content: "\f4f6";
}

.mdi-pinterest-box:before {
	content: "\f4f7";
}

.mdi-pizza:before {
	content: "\f4f8";
}

.mdi-play:before {
	content: "\f4f9";
}

.mdi-play-box-outline:before {
	content: "\f4fa";
}

.mdi-play-circle:before {
	content: "\f4fb";
}

.mdi-play-circle-outline:before {
	content: "\f4fc";
}

.mdi-play-pause:before {
	content: "\f4fd";
}

.mdi-play-protected-content:before {
	content: "\f4fe";
}

.mdi-playlist-minus:before {
	content: "\f4ff";
}

.mdi-playlist-play:before {
	content: "\f500";
}

.mdi-playlist-plus:before {
	content: "\f501";
}

.mdi-playlist-remove:before {
	content: "\f502";
}

.mdi-playstation:before {
	content: "\f503";
}

.mdi-plus:before {
	content: "\f504";
}

.mdi-plus-box:before {
	content: "\f505";
}

.mdi-plus-circle:before {
	content: "\f506";
}

.mdi-plus-circle-multiple-outline:before {
	content: "\f507";
}

.mdi-plus-circle-outline:before {
	content: "\f508";
}

.mdi-plus-network:before {
	content: "\f509";
}

.mdi-plus-one:before {
	content: "\f50a";
}

.mdi-pocket:before {
	content: "\f50b";
}

.mdi-pokeball:before {
	content: "\f50c";
}

.mdi-polaroid:before {
	content: "\f50d";
}

.mdi-poll:before {
	content: "\f50e";
}

.mdi-poll-box:before {
	content: "\f50f";
}

.mdi-polymer:before {
	content: "\f510";
}

.mdi-popcorn:before {
	content: "\f511";
}

.mdi-pound:before {
	content: "\f512";
}

.mdi-pound-box:before {
	content: "\f513";
}

.mdi-power:before {
	content: "\f514";
}

.mdi-power-settings:before {
	content: "\f515";
}

.mdi-power-socket:before {
	content: "\f516";
}

.mdi-presentation:before {
	content: "\f517";
}

.mdi-presentation-play:before {
	content: "\f518";
}

.mdi-printer:before {
	content: "\f519";
}

.mdi-printer-3d:before {
	content: "\f51a";
}

.mdi-printer-alert:before {
	content: "\f51b";
}

.mdi-professional-hexagon:before {
	content: "\f51c";
}

.mdi-projector:before {
	content: "\f51d";
}

.mdi-projector-screen:before {
	content: "\f51e";
}

.mdi-pulse:before {
	content: "\f51f";
}

.mdi-puzzle:before {
	content: "\f520";
}

.mdi-qrcode:before {
	content: "\f521";
}

.mdi-qrcode-scan:before {
	content: "\f522";
}

.mdi-quadcopter:before {
	content: "\f523";
}

.mdi-quality-high:before {
	content: "\f524";
}

.mdi-quicktime:before {
	content: "\f525";
}

.mdi-radar:before {
	content: "\f526";
}

.mdi-radiator:before {
	content: "\f527";
}

.mdi-radio:before {
	content: "\f528";
}

.mdi-radio-handheld:before {
	content: "\f529";
}

.mdi-radio-tower:before {
	content: "\f52a";
}

.mdi-radioactive:before {
	content: "\f52b";
}

.mdi-radiobox-blank:before {
	content: "\f52c";
}

.mdi-radiobox-marked:before {
	content: "\f52d";
}

.mdi-raspberrypi:before {
	content: "\f52e";
}

.mdi-ray-end:before {
	content: "\f52f";
}

.mdi-ray-end-arrow:before {
	content: "\f530";
}

.mdi-ray-start:before {
	content: "\f531";
}

.mdi-ray-start-arrow:before {
	content: "\f532";
}

.mdi-ray-start-end:before {
	content: "\f533";
}

.mdi-ray-vertex:before {
	content: "\f534";
}

.mdi-rdio:before {
	content: "\f535";
}

.mdi-read:before {
	content: "\f536";
}

.mdi-readability:before {
	content: "\f537";
}

.mdi-receipt:before {
	content: "\f538";
}

.mdi-record:before {
	content: "\f539";
}

.mdi-record-rec:before {
	content: "\f53a";
}

.mdi-recycle:before {
	content: "\f53b";
}

.mdi-reddit:before {
	content: "\f53c";
}

.mdi-redo:before {
	content: "\f53d";
}

.mdi-redo-variant:before {
	content: "\f53e";
}

.mdi-refresh:before {
	content: "\f53f";
}

.mdi-regex:before {
	content: "\f540";
}

.mdi-relative-scale:before {
	content: "\f541";
}

.mdi-reload:before {
	content: "\f542";
}

.mdi-remote:before {
	content: "\f543";
}

.mdi-rename-box:before {
	content: "\f544";
}

.mdi-repeat:before {
	content: "\f545";
}

.mdi-repeat-off:before {
	content: "\f546";
}

.mdi-repeat-once:before {
	content: "\f547";
}

.mdi-replay:before {
	content: "\f548";
}

.mdi-reply:before {
	content: "\f549";
}

.mdi-reply-all:before {
	content: "\f54a";
}

.mdi-reproduction:before {
	content: "\f54b";
}

.mdi-resize-bottom-right:before {
	content: "\f54c";
}

.mdi-responsive:before {
	content: "\f54d";
}

.mdi-rewind:before {
	content: "\f54e";
}

.mdi-ribbon:before {
	content: "\f54f";
}

.mdi-road:before {
	content: "\f550";
}

.mdi-road-variant:before {
	content: "\f551";
}

.mdi-rocket:before {
	content: "\f552";
}

.mdi-rotate-3d:before {
	content: "\f553";
}

.mdi-rotate-left:before {
	content: "\f554";
}

.mdi-rotate-left-variant:before {
	content: "\f555";
}

.mdi-rotate-right:before {
	content: "\f556";
}

.mdi-rotate-right-variant:before {
	content: "\f557";
}

.mdi-router-wireless:before {
	content: "\f558";
}

.mdi-routes:before {
	content: "\f559";
}

.mdi-rss:before {
	content: "\f55a";
}

.mdi-rss-box:before {
	content: "\f55b";
}

.mdi-ruler:before {
	content: "\f55c";
}

.mdi-run:before {
	content: "\f55d";
}

.mdi-sale:before {
	content: "\f55e";
}

.mdi-satellite:before {
	content: "\f55f";
}

.mdi-satellite-variant:before {
	content: "\f560";
}

.mdi-scale:before {
	content: "\f561";
}

.mdi-scale-bathroom:before {
	content: "\f562";
}

.mdi-school:before {
	content: "\f563";
}

.mdi-screen-rotation:before {
	content: "\f564";
}

.mdi-screen-rotation-lock:before {
	content: "\f565";
}

.mdi-screwdriver:before {
	content: "\f566";
}

.mdi-script:before {
	content: "\f567";
}

.mdi-sd:before {
	content: "\f568";
}

.mdi-seal:before {
	content: "\f569";
}

.mdi-seat-flat:before {
	content: "\f56a";
}

.mdi-seat-flat-angled:before {
	content: "\f56b";
}

.mdi-seat-individual-suite:before {
	content: "\f56c";
}

.mdi-seat-legroom-extra:before {
	content: "\f56d";
}

.mdi-seat-legroom-normal:before {
	content: "\f56e";
}

.mdi-seat-legroom-reduced:before {
	content: "\f56f";
}

.mdi-seat-recline-extra:before {
	content: "\f570";
}

.mdi-seat-recline-normal:before {
	content: "\f571";
}

.mdi-security:before {
	content: "\f572";
}

.mdi-security-network:before {
	content: "\f573";
}

.mdi-select:before {
	content: "\f574";
}

.mdi-select-all:before {
	content: "\f575";
}

.mdi-select-inverse:before {
	content: "\f576";
}

.mdi-select-off:before {
	content: "\f577";
}

.mdi-selection:before {
	content: "\f578";
}

.mdi-send:before {
	content: "\f579";
}

.mdi-server:before {
	content: "\f57a";
}

.mdi-server-minus:before {
	content: "\f57b";
}

.mdi-server-network:before {
	content: "\f57c";
}

.mdi-server-network-off:before {
	content: "\f57d";
}

.mdi-server-off:before {
	content: "\f57e";
}

.mdi-server-plus:before {
	content: "\f57f";
}

.mdi-server-remove:before {
	content: "\f580";
}

.mdi-server-security:before {
	content: "\f581";
}

.mdi-settings:before {
	content: "\f582";
}

.mdi-settings-box:before {
	content: "\f583";
}

.mdi-shape-plus:before {
	content: "\f584";
}

.mdi-share:before {
	content: "\f585";
}

.mdi-share-variant:before {
	content: "\f586";
}

.mdi-shield:before {
	content: "\f587";
}

.mdi-shield-outline:before {
	content: "\f588";
}

.mdi-shopping:before {
	content: "\f589";
}

.mdi-shopping-music:before {
	content: "\f58a";
}

.mdi-shredder:before {
	content: "\f58b";
}

.mdi-shuffle:before {
	content: "\f58c";
}

.mdi-shuffle-disabled:before {
	content: "\f58d";
}

.mdi-shuffle-variant:before {
	content: "\f58e";
}

.mdi-sigma:before {
	content: "\f58f";
}

.mdi-sign-caution:before {
	content: "\f590";
}

.mdi-signal:before {
	content: "\f591";
}

.mdi-silverware:before {
	content: "\f592";
}

.mdi-silverware-fork:before {
	content: "\f593";
}

.mdi-silverware-spoon:before {
	content: "\f594";
}

.mdi-silverware-variant:before {
	content: "\f595";
}

.mdi-sim:before {
	content: "\f596";
}

.mdi-sim-alert:before {
	content: "\f597";
}

.mdi-sim-off:before {
	content: "\f598";
}

.mdi-sitemap:before {
	content: "\f599";
}

.mdi-skip-backward:before {
	content: "\f59a";
}

.mdi-skip-forward:before {
	content: "\f59b";
}

.mdi-skip-next:before {
	content: "\f59c";
}

.mdi-skip-previous:before {
	content: "\f59d";
}

.mdi-skype:before {
	content: "\f59e";
}

.mdi-skype-business:before {
	content: "\f59f";
}

.mdi-slack:before {
	content: "\f5a0";
}

.mdi-sleep:before {
	content: "\f5a1";
}

.mdi-sleep-off:before {
	content: "\f5a2";
}

.mdi-smoking:before {
	content: "\f5a3";
}

.mdi-smoking-off:before {
	content: "\f5a4";
}

.mdi-snapchat:before {
	content: "\f5a5";
}

.mdi-snowman:before {
	content: "\f5a6";
}

.mdi-sofa:before {
	content: "\f5a7";
}

.mdi-sort:before {
	content: "\f5a8";
}

.mdi-sort-alphabetical:before {
	content: "\f5a9";
}

.mdi-sort-ascending:before {
	content: "\f5aa";
}

.mdi-sort-descending:before {
	content: "\f5ab";
}

.mdi-sort-numeric:before {
	content: "\f5ac";
}

.mdi-sort-variant:before {
	content: "\f5ad";
}

.mdi-soundcloud:before {
	content: "\f5ae";
}

.mdi-source-fork:before {
	content: "\f5af";
}

.mdi-source-pull:before {
	content: "\f5b0";
}

.mdi-speaker:before {
	content: "\f5b1";
}

.mdi-speaker-off:before {
	content: "\f5b2";
}

.mdi-speedometer:before {
	content: "\f5b3";
}

.mdi-spellcheck:before {
	content: "\f5b4";
}

.mdi-spotify:before {
	content: "\f5b5";
}

.mdi-spotlight:before {
	content: "\f5b6";
}

.mdi-spotlight-beam:before {
	content: "\f5b7";
}

.mdi-square-inc:before {
	content: "\f5b8";
}

.mdi-square-inc-cash:before {
	content: "\f5b9";
}

.mdi-stackoverflow:before {
	content: "\f5ba";
}

.mdi-stairs:before {
	content: "\f5bb";
}

.mdi-star:before {
	content: "\f5bc";
}

.mdi-star-circle:before {
	content: "\f5bd";
}

.mdi-star-half:before {
	content: "\f5be";
}

.mdi-star-off:before {
	content: "\f5bf";
}

.mdi-star-outline:before {
	content: "\f5c0";
}

.mdi-steam:before {
	content: "\f5c1";
}

.mdi-steering:before {
	content: "\f5c2";
}

.mdi-step-backward:before {
	content: "\f5c3";
}

.mdi-step-backward-2:before {
	content: "\f5c4";
}

.mdi-step-forward:before {
	content: "\f5c5";
}

.mdi-step-forward-2:before {
	content: "\f5c6";
}

.mdi-stethoscope:before {
	content: "\f5c7";
}

.mdi-stocking:before {
	content: "\f5c8";
}

.mdi-stop:before {
	content: "\f5c9";
}

.mdi-store:before {
	content: "\f5ca";
}

.mdi-store-24-hour:before {
	content: "\f5cb";
}

.mdi-stove:before {
	content: "\f5cc";
}

.mdi-subway:before {
	content: "\f5cd";
}

.mdi-sunglasses:before {
	content: "\f5ce";
}

.mdi-swap-horizontal:before {
	content: "\f5cf";
}

.mdi-swap-vertical:before {
	content: "\f5d0";
}

.mdi-swim:before {
	content: "\f5d1";
}

.mdi-switch:before {
	content: "\f5d2";
}

.mdi-sword:before {
	content: "\f5d3";
}

.mdi-sync:before {
	content: "\f5d4";
}

.mdi-sync-alert:before {
	content: "\f5d5";
}

.mdi-sync-off:before {
	content: "\f5d6";
}

.mdi-tab:before {
	content: "\f5d7";
}

.mdi-tab-unselected:before {
	content: "\f5d8";
}

.mdi-table:before {
	content: "\f5d9";
}

.mdi-table-column-plus-after:before {
	content: "\f5da";
}

.mdi-table-column-plus-before:before {
	content: "\f5db";
}

.mdi-table-column-remove:before {
	content: "\f5dc";
}

.mdi-table-column-width:before {
	content: "\f5dd";
}

.mdi-table-edit:before {
	content: "\f5de";
}

.mdi-table-large:before {
	content: "\f5df";
}

.mdi-table-row-height:before {
	content: "\f5e0";
}

.mdi-table-row-plus-after:before {
	content: "\f5e1";
}

.mdi-table-row-plus-before:before {
	content: "\f5e2";
}

.mdi-table-row-remove:before {
	content: "\f5e3";
}

.mdi-tablet:before {
	content: "\f5e4";
}

.mdi-tablet-android:before {
	content: "\f5e5";
}

.mdi-tablet-ipad:before {
	content: "\f5e6";
}

.mdi-tag:before {
	content: "\f5e7";
}

.mdi-tag-faces:before {
	content: "\f5e8";
}

.mdi-tag-multiple:before {
	content: "\f5e9";
}

.mdi-tag-outline:before {
	content: "\f5ea";
}

.mdi-tag-text-outline:before {
	content: "\f5eb";
}

.mdi-target:before {
	content: "\f5ec";
}

.mdi-taxi:before {
	content: "\f5ed";
}

.mdi-teamviewer:before {
	content: "\f5ee";
}

.mdi-telegram:before {
	content: "\f5ef";
}

.mdi-television:before {
	content: "\f5f0";
}

.mdi-television-guide:before {
	content: "\f5f1";
}

.mdi-temperature-celsius:before {
	content: "\f5f2";
}

.mdi-temperature-fahrenheit:before {
	content: "\f5f3";
}

.mdi-temperature-kelvin:before {
	content: "\f5f4";
}

.mdi-tennis:before {
	content: "\f5f5";
}

.mdi-tent:before {
	content: "\f5f6";
}

.mdi-terrain:before {
	content: "\f5f7";
}

.mdi-text-to-speech:before {
	content: "\f5f8";
}

.mdi-text-to-speech-off:before {
	content: "\f5f9";
}

.mdi-texture:before {
	content: "\f5fa";
}

.mdi-theater:before {
	content: "\f5fb";
}

.mdi-theme-light-dark:before {
	content: "\f5fc";
}

.mdi-thermometer:before {
	content: "\f5fd";
}

.mdi-thermometer-lines:before {
	content: "\f5fe";
}

.mdi-thumb-down:before {
	content: "\f5ff";
}

.mdi-thumb-down-outline:before {
	content: "\f600";
}

.mdi-thumb-up:before {
	content: "\f601";
}

.mdi-thumb-up-outline:before {
	content: "\f602";
}

.mdi-thumbs-up-down:before {
	content: "\f603";
}

.mdi-ticket:before {
	content: "\f604";
}

.mdi-ticket-account:before {
	content: "\f605";
}

.mdi-ticket-confirmation:before {
	content: "\f606";
}

.mdi-tie:before {
	content: "\f607";
}

.mdi-timelapse:before {
	content: "\f608";
}

.mdi-timer:before {
	content: "\f609";
}

.mdi-timer-10:before {
	content: "\f60a";
}

.mdi-timer-3:before {
	content: "\f60b";
}

.mdi-timer-off:before {
	content: "\f60c";
}

.mdi-timer-sand:before {
	content: "\f60d";
}

.mdi-timetable:before {
	content: "\f60e";
}

.mdi-toggle-switch:before {
	content: "\f60f";
}

.mdi-toggle-switch-off:before {
	content: "\f610";
}

.mdi-tooltip:before {
	content: "\f611";
}

.mdi-tooltip-edit:before {
	content: "\f612";
}

.mdi-tooltip-image:before {
	content: "\f613";
}

.mdi-tooltip-outline:before {
	content: "\f614";
}

.mdi-tooltip-outline-plus:before {
	content: "\f615";
}

.mdi-tooltip-text:before {
	content: "\f616";
}

.mdi-tor:before {
	content: "\f617";
}

.mdi-traffic-light:before {
	content: "\f618";
}

.mdi-train:before {
	content: "\f619";
}

.mdi-tram:before {
	content: "\f61a";
}

.mdi-transcribe:before {
	content: "\f61b";
}

.mdi-transcribe-close:before {
	content: "\f61c";
}

.mdi-transfer:before {
	content: "\f61d";
}

.mdi-tree:before {
	content: "\f61e";
}

.mdi-trello:before {
	content: "\f61f";
}

.mdi-trending-down:before {
	content: "\f620";
}

.mdi-trending-neutral:before {
	content: "\f621";
}

.mdi-trending-up:before {
	content: "\f622";
}

.mdi-triangle:before {
	content: "\f623";
}

.mdi-triangle-outline:before {
	content: "\f624";
}

.mdi-trophy:before {
	content: "\f625";
}

.mdi-trophy-award:before {
	content: "\f626";
}

.mdi-trophy-outline:before {
	content: "\f627";
}

.mdi-trophy-variant:before {
	content: "\f628";
}

.mdi-trophy-variant-outline:before {
	content: "\f629";
}

.mdi-truck:before {
	content: "\f62a";
}

.mdi-truck-delivery:before {
	content: "\f62b";
}

.mdi-tshirt-crew:before {
	content: "\f62c";
}

.mdi-tshirt-v:before {
	content: "\f62d";
}

.mdi-tumblr:before {
	content: "\f62e";
}

.mdi-tumblr-reblog:before {
	content: "\f62f";
}

.mdi-twitch:before {
	content: "\f630";
}

.mdi-twitter:before {
	content: "\f631";
}

.mdi-twitter-box:before {
	content: "\f632";
}

.mdi-twitter-circle:before {
	content: "\f633";
}

.mdi-twitter-retweet:before {
	content: "\f634";
}

.mdi-ubuntu:before {
	content: "\f635";
}

.mdi-umbraco:before {
	content: "\f636";
}

.mdi-umbrella:before {
	content: "\f637";
}

.mdi-umbrella-outline:before {
	content: "\f638";
}

.mdi-undo:before {
	content: "\f639";
}

.mdi-undo-variant:before {
	content: "\f63a";
}

.mdi-unfold-less:before {
	content: "\f63b";
}

.mdi-unfold-more:before {
	content: "\f63c";
}

.mdi-ungroup:before {
	content: "\f63d";
}

.mdi-untappd:before {
	content: "\f63e";
}

.mdi-upload:before {
	content: "\f63f";
}

.mdi-usb:before {
	content: "\f640";
}

.mdi-vector-arrange-above:before {
	content: "\f641";
}

.mdi-vector-arrange-below:before {
	content: "\f642";
}

.mdi-vector-circle:before {
	content: "\f643";
}

.mdi-vector-circle-variant:before {
	content: "\f644";
}

.mdi-vector-combine:before {
	content: "\f645";
}

.mdi-vector-curve:before {
	content: "\f646";
}

.mdi-vector-difference:before {
	content: "\f647";
}

.mdi-vector-difference-ab:before {
	content: "\f648";
}

.mdi-vector-difference-ba:before {
	content: "\f649";
}

.mdi-vector-intersection:before {
	content: "\f64a";
}

.mdi-vector-line:before {
	content: "\f64b";
}

.mdi-vector-point:before {
	content: "\f64c";
}

.mdi-vector-polygon:before {
	content: "\f64d";
}

.mdi-vector-polyline:before {
	content: "\f64e";
}

.mdi-vector-selection:before {
	content: "\f64f";
}

.mdi-vector-square:before {
	content: "\f650";
}

.mdi-vector-triangle:before {
	content: "\f651";
}

.mdi-vector-union:before {
	content: "\f652";
}

.mdi-verified:before {
	content: "\f653";
}

.mdi-vibrate:before {
	content: "\f654";
}

.mdi-video:before {
	content: "\f655";
}

.mdi-video-off:before {
	content: "\f656";
}

.mdi-video-switch:before {
	content: "\f657";
}

.mdi-view-agenda:before {
	content: "\f658";
}

.mdi-view-array:before {
	content: "\f659";
}

.mdi-view-carousel:before {
	content: "\f65a";
}

.mdi-view-column:before {
	content: "\f65b";
}

.mdi-view-dashboard:before {
	content: "\f65c";
}

.mdi-view-day:before {
	content: "\f65d";
}

.mdi-view-grid:before {
	content: "\f65e";
}

.mdi-view-headline:before {
	content: "\f65f";
}

.mdi-view-list:before {
	content: "\f660";
}

.mdi-view-module:before {
	content: "\f661";
}

.mdi-view-quilt:before {
	content: "\f662";
}

.mdi-view-stream:before {
	content: "\f663";
}

.mdi-view-week:before {
	content: "\f664";
}

.mdi-vimeo:before {
	content: "\f665";
}

.mdi-vine:before {
	content: "\f666";
}

.mdi-vk:before {
	content: "\f667";
}

.mdi-vk-box:before {
	content: "\f668";
}

.mdi-vk-circle:before {
	content: "\f669";
}

.mdi-voicemail:before {
	content: "\f66a";
}

.mdi-volume-high:before {
	content: "\f66b";
}

.mdi-volume-low:before {
	content: "\f66c";
}

.mdi-volume-medium:before {
	content: "\f66d";
}

.mdi-volume-off:before {
	content: "\f66e";
}

.mdi-vpn:before {
	content: "\f66f";
}

.mdi-walk:before {
	content: "\f670";
}

.mdi-wallet:before {
	content: "\f671";
}

.mdi-wallet-giftcard:before {
	content: "\f672";
}

.mdi-wallet-membership:before {
	content: "\f673";
}

.mdi-wallet-travel:before {
	content: "\f674";
}

.mdi-wan:before {
	content: "\f675";
}

.mdi-watch:before {
	content: "\f676";
}

.mdi-watch-export:before {
	content: "\f677";
}

.mdi-watch-import:before {
	content: "\f678";
}

.mdi-water:before {
	content: "\f679";
}

.mdi-water-off:before {
	content: "\f67a";
}

.mdi-water-percent:before {
	content: "\f67b";
}

.mdi-water-pump:before {
	content: "\f67c";
}

.mdi-weather-cloudy:before {
	content: "\f67d";
}

.mdi-weather-fog:before {
	content: "\f67e";
}

.mdi-weather-hail:before {
	content: "\f67f";
}

.mdi-weather-lightning:before {
	content: "\f680";
}

.mdi-weather-night:before {
	content: "\f681";
}

.mdi-weather-partlycloudy:before {
	content: "\f682";
}

.mdi-weather-pouring:before {
	content: "\f683";
}

.mdi-weather-rainy:before {
	content: "\f684";
}

.mdi-weather-snowy:before {
	content: "\f685";
}

.mdi-weather-sunny:before {
	content: "\f686";
}

.mdi-weather-sunset:before {
	content: "\f687";
}

.mdi-weather-sunset-down:before {
	content: "\f688";
}

.mdi-weather-sunset-up:before {
	content: "\f689";
}

.mdi-weather-windy:before {
	content: "\f68a";
}

.mdi-weather-windy-variant:before {
	content: "\f68b";
}

.mdi-web:before {
	content: "\f68c";
}

.mdi-webcam:before {
	content: "\f68d";
}

.mdi-weight:before {
	content: "\f68e";
}

.mdi-weight-kilogram:before {
	content: "\f68f";
}

.mdi-whatsapp:before {
	content: "\f690";
}

.mdi-wheelchair-accessibility:before {
	content: "\f691";
}

.mdi-white-balance-auto:before {
	content: "\f692";
}

.mdi-white-balance-incandescent:before {
	content: "\f693";
}

.mdi-white-balance-irradescent:before {
	content: "\f694";
}

.mdi-white-balance-sunny:before {
	content: "\f695";
}

.mdi-wifi:before {
	content: "\f696";
}

.mdi-wifi-off:before {
	content: "\f697";
}

.mdi-wii:before {
	content: "\f698";
}

.mdi-wikipedia:before {
	content: "\f699";
}

.mdi-window-close:before {
	content: "\f69a";
}

.mdi-window-closed:before {
	content: "\f69b";
}

.mdi-window-maximize:before {
	content: "\f69c";
}

.mdi-window-minimize:before {
	content: "\f69d";
}

.mdi-window-open:before {
	content: "\f69e";
}

.mdi-window-restore:before {
	content: "\f69f";
}

.mdi-windows:before {
	content: "\f6a0";
}

.mdi-wordpress:before {
	content: "\f6a1";
}

.mdi-worker:before {
	content: "\f6a2";
}

.mdi-wrap:before {
	content: "\f6a3";
}

.mdi-wrench:before {
	content: "\f6a4";
}

.mdi-wunderlist:before {
	content: "\f6a5";
}

.mdi-xbox:before {
	content: "\f6a6";
}

.mdi-xbox-controller:before {
	content: "\f6a7";
}

.mdi-xbox-controller-off:before {
	content: "\f6a8";
}

.mdi-xda:before {
	content: "\f6a9";
}

.mdi-xing:before {
	content: "\f6aa";
}

.mdi-xing-box:before {
	content: "\f6ab";
}

.mdi-xing-circle:before {
	content: "\f6ac";
}

.mdi-xml:before {
	content: "\f6ad";
}

.mdi-yeast:before {
	content: "\f6ae";
}

.mdi-yelp:before {
	content: "\f6af";
}

.mdi-youtube-play:before {
	content: "\f6b0";
}

.mdi-zip-box:before {
	content: "\f6b1";
}

.mdi-18px {
	font-size: 18px;
}

.mdi-24px {
	font-size: 24px;
}

.mdi-36px {
	font-size: 36px;
}

.mdi-48px {
	font-size: 48px;
}

.mdi-dark {
	color: rgba(0, 0, 0, 0.54);
}

.mdi-dark.mdi-inactive {
	color: rgba(0, 0, 0, 0.26);
}

.mdi-light {
	color: white;
}

.mdi-light.mdi-inactive {
	color: rgba(255, 255, 255, 0.3);
}

@font-face {
	font-family: 'Mercury';
	src: url("/fonts/Mercury-Regular.eot?62418065");
	src: url("/fonts/Mercury-Regular.eot?62418065#iefix") format("embedded-opentype"), url("/fonts/Mercury-Regular.woff?62418065") format("woff"), url("/fonts/Mercury-Regular.ttf?62418065") format("truetype"), url("/fonts/Mercury-Regular.svg?62418065#mercury") format("svg");
	font-weight: 400;
	font-style: normal;
}

[class^="mercury-icon-"]:before, [class*="mercury-icon-"]:before,
.mercury-ico {
	font-family: 'Mercury';
	font-weight: 400;
	font-style: normal;
	font-size: inherit;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.mercury-icon-time:before {
	content: '\e800';
}

.mercury-icon-angle-up:before {
	content: '\e801';
}

.mercury-icon-angle-left:before {
	content: '\e802';
}

.mercury-icon-angle-bottom:before {
	content: '\e803';
}

.mercury-icon-angle-right:before {
	content: '\e804';
}

.mercury-icon-bars:before {
	content: '\e805';
}

.mercury-icon-satellite:before {
	content: '\e806';
}

.mercury-icon-group:before {
	content: '\e807';
}

.mercury-icon-social:before {
	content: '\e808';
}

.mercury-icon-pin:before {
	content: '\e809';
}

.mercury-icon-target:before {
	content: '\e80a';
}

.mercury-icon-chart-up:before {
	content: '\e80b';
}

.mercury-icon-key:before {
	content: '\e80c';
}

.mercury-icon-search:before {
	content: '\e80d';
}

.mercury-icon-card:before {
	content: '\e80e';
}

.mercury-icon-gears:before {
	content: '\e80f';
}

.mercury-icon-touch:before {
	content: '\e810';
}

.mercury-icon-partners:before {
	content: '\e811';
}

.mercury-icon-money:before {
	content: '\e812';
}

.mercury-icon-chart:before {
	content: '\e813';
}

.mercury-icon-note:before {
	content: '\e814';
}

.mercury-icon-books:before {
	content: '\e815';
}

.mercury-icon-pie-chart:before {
	content: '\e816';
}

.mercury-icon-phone:before {
	content: '\e817';
}

.mercury-icon-phone-24:before {
	content: '\e818';
}

.mercury-icon-pencil:before {
	content: '\e819';
}

.mercury-icon-mobile:before {
	content: '\e81a';
}

.mercury-icon-presentation:before {
	content: '\e81b';
}

.mercury-icon-note-2:before {
	content: '\e81c';
}

.mercury-icon-time-back:before {
	content: '\e81d';
}

.mercury-icon-presentation-2:before {
	content: '\e81e';
}

.mercury-icon-tools:before {
	content: '\e81f';
}

.mercury-icon-news:before {
	content: '\e820';
}

.mercury-icon-cup:before {
	content: '\e821';
}

.mercury-icon-search-font:before {
	content: '\e822';
}

.mercury-icon-clock:before {
	content: '\e823';
}

.mercury-icon-users:before {
	content: '\e824';
}

.mercury-icon-user:before {
	content: '\e825';
}

.mercury-icon-chart-seacrh:before {
	content: '\e826';
}

.mercury-icon-screen:before {
	content: '\e827';
}

.mercury-icon-lightbulb:before {
	content: '\e828';
}

.mercury-icon-tag:before {
	content: '\e829';
}

.mercury-icon-chat:before {
	content: '\e82a';
}

.mercury-icon-window:before {
	content: '\e82b';
}

.mercury-icon-tablet:before {
	content: '\e82c';
}

.mercury-icon-lib:before {
	content: '\e82d';
}

.mercury-icon-wallet:before {
	content: '\e82e';
}

.mercury-icon-pointer:before {
	content: '\e82f';
}

.mercury-icon-speak:before {
	content: '\e830';
}

.mercury-icon-globe:before {
	content: '\e831';
}

.mercury-icon-calc:before {
	content: '\e832';
}

.mercury-icon-desktop:before {
	content: '\e833';
}

.mercury-icon-pointer-left:before {
	content: '\e834';
}

.mercury-icon-chart-up-2:before {
	content: '\e835';
}

.mercury-icon-scales:before {
	content: '\e836';
}

.mercury-icon-cloud:before {
	content: '\e837';
}

.mercury-icon-desktop-chart:before {
	content: '\e838';
}

.mercury-icon-calendar:before {
	content: '\e839';
}

.mercury-icon-e-mail-o:before {
	content: '\e83a';
}

.mercury-icon-gear:before {
	content: '\e83b';
}

.mercury-icon-lightbulb-gears:before {
	content: '\e83c';
}

.mercury-icon-presentation-3:before {
	content: '\e83d';
}

.mercury-icon-money-2:before {
	content: '\e83e';
}

.mercury-icon-print:before {
	content: '\e83f';
}

.mercury-icon-time-sand:before {
	content: '\e840';
}

.mercury-icon-e-mail:before {
	content: '\e841';
}

.mercury-icon-paper:before {
	content: '\e842';
}

.mercury-icon-lock:before {
	content: '\e843';
}

.mercury-icon-case:before {
	content: '\e844';
}

.mercury-icon-money-3:before {
	content: '\e845';
}

.mercury-icon-jobs:before {
	content: '\e846';
}

.mercury-icon-document-search:before {
	content: '\e847';
}

.mercury-icon-globe-marker:before {
	content: '\e848';
}

.mercury-icon-folder:before {
	content: '\e849';
}

.mercury-icon-briefcase:before {
	content: '\e84a';
}

.mercury-icon-target-2:before {
	content: '\e84b';
}

.mercury-icon-cloud-2:before {
	content: '\e84c';
}

.mercury-icon-house:before {
	content: '\e84d';
}

@font-face {
	font-family: 'Thin Regular';
	src: url("/fonts/Thin-Regular.eot?66413286");
	src: url("/fonts/Thin-Regular.eot?66413286#iefix") format("embedded-opentype"), url("/fonts/Thin-Regular.woff?66413286") format("woff"), url("/fonts/Thin-Regular.ttf?66413286") format("truetype"), url("/fonts/Thin-Regular.svg?66413286#thin") format("svg");
	font-weight: normal;
	font-style: normal;
}

[class^="thin-icon-"]:before,
[class*=" thin-icon-"]:before,
.thin-ico {
	font-family: "Thin Regular";
	font-weight: 400;
	font-style: normal;
	font-size: inherit;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.thin-icon-volume-on:before {
	content: '\e800';
}

.thin-icon-gift:before {
	content: '\e801';
}

.thin-icon-cup:before {
	content: '\e802';
}

.thin-icon-folder:before {
	content: '\e803';
}

.thin-icon-dublicate:before {
	content: '\e804';
}

.thin-icon-tag:before {
	content: '\e805';
}

.thin-icon-chat:before {
	content: '\e806';
}

.thin-icon-clock:before {
	content: '\e807';
}

.thin-icon-microphone:before {
	content: '\e808';
}

.thin-icon-map-marker:before {
	content: '\e809';
}

.thin-icon-mobile:before {
	content: '\e80a';
}

.thin-icon-cloud-charge:before {
	content: '\e80b';
}

.thin-icon-resize:before {
	content: '\e80c';
}

.thin-icon-cake:before {
	content: '\e80d';
}

.thin-icon-case:before {
	content: '\e80e';
}

.thin-icon-address:before {
	content: '\e80f';
}

.thin-icon-phone-support:before {
	content: '\e810';
}

.thin-icon-fullscreen:before {
	content: '\e811';
}

.thin-icon-db:before {
	content: '\e812';
}

.thin-icon-music:before {
	content: '\e813';
}

.thin-icon-network:before {
	content: '\e814';
}

.thin-icon-db-network:before {
	content: '\e815';
}

.thin-icon-dropbox-upload:before {
	content: '\e816';
}

.thin-icon-phone-call:before {
	content: '\e817';
}

.thin-icon-briefcase-2:before {
	content: '\e818';
}

.thin-icon-card:before {
	content: '\e819';
}

.thin-icon-support:before {
	content: '\e81a';
}

.thin-icon-pull:before {
	content: '\e81b';
}

.thin-icon-desktop:before {
	content: '\e81c';
}

.thin-icon-pass:before {
	content: '\e81d';
}

.thin-icon-picture:before {
	content: '\e81e';
}

.thin-icon-email:before {
	content: '\e81f';
}

.thin-icon-push:before {
	content: '\e820';
}

.thin-icon-house:before {
	content: '\e821';
}

.thin-icon-download:before {
	content: '\e822';
}

.thin-icon-storage:before {
	content: '\e823';
}

.thin-icon-milk:before {
	content: '\e824';
}

.thin-icon-external-right:before {
	content: '\e825';
}

.thin-icon-email-open:before {
	content: '\e826';
}

.thin-icon-planet:before {
	content: '\e827';
}

.thin-icon-pointer:before {
	content: '\e828';
}

.thin-icon-email-search:before {
	content: '\e829';
}

.thin-icon-external-left:before {
	content: '\e82a';
}

.thin-icon-shirt:before {
	content: '\e82b';
}

.thin-icon-document-edit:before {
	content: '\e82c';
}

.thin-icon-document-delete:before {
	content: '\e82d';
}

.thin-icon-money:before {
	content: '\e82e';
}

.thin-icon-eye:before {
	content: '\e82f';
}

.thin-icon-settings:before {
	content: '\e830';
}

.thin-icon-arrow-bottom-right:before {
	content: '\e831';
}

.thin-icon-arrow-right:before {
	content: '\e832';
}

.thin-icon-flag:before {
	content: '\e833';
}

.thin-icon-star:before {
	content: '\e834';
}

.thin-icon-calculator:before {
	content: '\e835';
}

.thin-icon-safe:before {
	content: '\e836';
}

.thin-icon-cart:before {
	content: '\e837';
}

.thin-icon-bullhorn:before {
	content: '\e838';
}

.thin-icon-anchor:before {
	content: '\e839';
}

.thin-icon-globe:before {
	content: '\e83a';
}

.thin-icon-statistics:before {
	content: '\e83b';
}

.thin-icon-thumb-up:before {
	content: '\e83c';
}

.thin-icon-headphones:before {
	content: '\e83d';
}

.thin-icon-bell:before {
	content: '\e83e';
}

.thin-icon-study:before {
	content: '\e83f';
}

.thin-icon-cart-add:before {
	content: '\e840';
}

.thin-icon-cart-delete:before {
	content: '\e841';
}

.thin-icon-satelite:before {
	content: '\e842';
}

.thin-icon-home:before {
	content: '\e843';
}

.thin-icon-time:before {
	content: '\e844';
}

.thin-icon-book:before {
	content: '\e845';
}

.thin-icon-bookmark:before {
	content: '\e846';
}

.thin-icon-key:before {
	content: '\e847';
}

.thin-icon-timer:before {
	content: '\e848';
}

.thin-icon-saturn:before {
	content: '\e849';
}

.thin-icon-notes:before {
	content: '\e84a';
}

.thin-icon-ambulance:before {
	content: '\e84b';
}

.thin-icon-briefcase:before {
	content: '\e84c';
}

.thin-icon-layers:before {
	content: '\e84d';
}

.thin-icon-delivery:before {
	content: '\e84e';
}

.thin-icon-tint:before {
	content: '\e84f';
}

.thin-icon-trash:before {
	content: '\e850';
}

.thin-icon-lightbulb:before {
	content: '\e851';
}

.thin-icon-calendar:before {
	content: '\e852';
}

.thin-icon-chart:before {
	content: '\e853';
}

.thin-icon-documents:before {
	content: '\e854';
}

.thin-icon-checklist:before {
	content: '\e855';
}

.thin-icon-camera-web:before {
	content: '\e856';
}

.thin-icon-camera:before {
	content: '\e857';
}

.thin-icon-lock:before {
	content: '\e858';
}

.thin-icon-umbrella:before {
	content: '\e859';
}

.thin-icon-user:before {
	content: '\e85a';
}

.thin-icon-love:before {
	content: '\e85b';
}

.thin-icon-hanger:before {
	content: '\e85c';
}

.thin-icon-car:before {
	content: '\e85d';
}

.thin-icon-cloth:before {
	content: '\e85e';
}

.thin-icon-box:before {
	content: '\e85f';
}

.thin-icon-attachment:before {
	content: '\e860';
}

.thin-icon-cd:before {
	content: '\e861';
}

.thin-icon-love-broken:before {
	content: '\e862';
}

.thin-icon-volume-off:before {
	content: '\e863';
}

/**
 * Title: Budicons free Flaticon Pack
 * Author: Budi Tanrim
 * Source: http://www.flaticon.com/packs/budicons-free
 * License:  CC BY 3.0 (http://creativecommons.org/licenses/by/3.0/)
 */
@font-face {
	font-family: "fl-budicons-free";
	src: url("/fonts/fl-budicons-free.eot");
	src: url("/fonts/fl-budicons-free.eot#iefix") format("embedded-opentype"), url("/fonts/fl-budicons-free.woff") format("woff"), url("/fonts/fl-budicons-free.ttf") format("truetype"), url("/fonts/fl-budicons-free.svg") format("svg");
	font-weight: normal;
	font-style: normal;
}

.fl-budicons-free-ico,
[class^="fl-budicons-free-"]:before, [class*=" fl-budicons-free-"]:before,
[class^="fl-budicons-free-"]:after, [class*=" fl-budicons-free-"]:after {
	font-family: 'fl-budicons-free';
	font-size: inherit;
	font-weight: 400;
	font-style: normal;
}

.fl-budicons-free-add156:before {
	content: "\e000";
}

.fl-budicons-free-analogic3:before {
	content: "\e001";
}

.fl-budicons-free-bag32:before {
	content: "\e002";
}

.fl-budicons-free-bookmark31:before {
	content: "\e003";
}

.fl-budicons-free-chat58:before {
	content: "\e004";
}

.fl-budicons-free-circle95:before {
	content: "\e005";
}

.fl-budicons-free-closet:before {
	content: "\e006";
}

.fl-budicons-free-cloud262:before {
	content: "\e007";
}

.fl-budicons-free-cold11:before {
	content: "\e008";
}

.fl-budicons-free-comment46:before {
	content: "\e009";
}

.fl-budicons-free-cross84:before {
	content: "\e00a";
}

.fl-budicons-free-delete70:before {
	content: "\e00b";
}

.fl-budicons-free-design22:before {
	content: "\e00c";
}

.fl-budicons-free-down67:before {
	content: "\e00d";
}

.fl-budicons-free-download149:before {
	content: "\e00e";
}

.fl-budicons-free-electrical18:before {
	content: "\e00f";
}

.fl-budicons-free-email75:before {
	content: "\e010";
}

.fl-budicons-free-file72:before {
	content: "\e011";
}

.fl-budicons-free-folder174:before {
	content: "\e012";
}

.fl-budicons-free-folder175:before {
	content: "\e013";
}

.fl-budicons-free-heart284:before {
	content: "\e014";
}

.fl-budicons-free-label26:before {
	content: "\e015";
}

.fl-budicons-free-left161:before {
	content: "\e016";
}

.fl-budicons-free-lifeline14:before {
	content: "\e017";
}

.fl-budicons-free-location37:before {
	content: "\e018";
}

.fl-budicons-free-logout12:before {
	content: "\e019";
}

.fl-budicons-free-music223:before {
	content: "\e01a";
}

.fl-budicons-free-musical102:before {
	content: "\e01b";
}

.fl-budicons-free-notebook47:before {
	content: "\e01c";
}

.fl-budicons-free-paper124:before {
	content: "\e01d";
}

.fl-budicons-free-person308:before {
	content: "\e01e";
}

.fl-budicons-free-photo186:before {
	content: "\e01f";
}

.fl-budicons-free-photo187:before {
	content: "\e020";
}

.fl-budicons-free-placeholder22:before {
	content: "\e021";
}

.fl-budicons-free-portfolio27:before {
	content: "\e022";
}

.fl-budicons-free-quit2:before {
	content: "\e023";
}

.fl-budicons-free-rain31:before {
	content: "\e024";
}

.fl-budicons-free-right163:before {
	content: "\e025";
}

.fl-budicons-free-shape6:before {
	content: "\e026";
}

.fl-budicons-free-share29:before {
	content: "\e027";
}

.fl-budicons-free-speech102:before {
	content: "\e028";
}

.fl-budicons-free-tag49:before {
	content: "\e029";
}

.fl-budicons-free-two327:before {
	content: "\e02a";
}

.fl-budicons-free-up119:before {
	content: "\e02b";
}

.fl-budicons-free-upload96:before {
	content: "\e02c";
}

.fl-budicons-free-verify5:before {
	content: "\e02d";
}

.fl-budicons-free-verify6:before {
	content: "\e02e";
}

.fl-budicons-free-wheel29:before {
	content: "\e02f";
}

/**
 * Title: Budicons launch Flaticon Pack
 * Author: Budi Tanrim
 * Source: http://www.flaticon.com/packs/budicons-launch
 * License:  CC BY 3.0 (http://creativecommons.org/licenses/by/3.0/)
 */
@font-face {
	font-family: "fl-budicons-launch";
	src: url("/fonts/fl-budicons-launch.eot");
	src: url("/fonts/fl-budicons-launch.eot#iefix") format("embedded-opentype"), url("/fonts/fl-budicons-launch.woff") format("woff"), url("/fonts/fl-budicons-launch.ttf") format("truetype"), url("/fonts/fl-budicons-launch.svg") format("svg");
	font-weight: normal;
	font-style: normal;
}

.fl-budicons-launch-ico,
[class^="fl-budicons-launch-"]:before, [class*=" fl-budicons-launch-"]:before,
[class^="fl-budicons-launch-"]:after, [class*=" fl-budicons-launch-"]:after {
	font-family: 'fl-budicons-launch';
	font-size: inherit;
	font-weight: 400;
	font-style: normal;
}

.fl-budicons-launch-arrows92:before {
	content: "\e000";
}

.fl-budicons-launch-center12:before {
	content: "\e001";
}

.fl-budicons-launch-chat57:before {
	content: "\e002";
}

.fl-budicons-launch-checkmark17:before {
	content: "\e003";
}

.fl-budicons-launch-croos:before {
	content: "\e004";
}

.fl-budicons-launch-cropping2:before {
	content: "\e005";
}

.fl-budicons-launch-drink47:before {
	content: "\e006";
}

.fl-budicons-launch-email74:before {
	content: "\e007";
}

.fl-budicons-launch-equalizer29:before {
	content: "\e008";
}

.fl-budicons-launch-file71:before {
	content: "\e009";
}

.fl-budicons-launch-folder177:before {
	content: "\e00a";
}

.fl-budicons-launch-images22:before {
	content: "\e00b";
}

.fl-budicons-launch-images23:before {
	content: "\e00c";
}

.fl-budicons-launch-left162:before {
	content: "\e00d";
}

.fl-budicons-launch-left163:before {
	content: "\e00e";
}

.fl-budicons-launch-map90:before {
	content: "\e00f";
}

.fl-budicons-launch-menu47:before {
	content: "\e010";
}

.fl-budicons-launch-minus87:before {
	content: "\e011";
}

.fl-budicons-launch-more15:before {
	content: "\e012";
}

.fl-budicons-launch-padlock42:before {
	content: "\e013";
}

.fl-budicons-launch-paper123:before {
	content: "\e014";
}

.fl-budicons-launch-photo185:before {
	content: "\e015";
}

.fl-budicons-launch-placeholder21:before {
	content: "\e016";
}

.fl-budicons-launch-plus72:before {
	content: "\e017";
}

.fl-budicons-launch-printer80:before {
	content: "\e018";
}

.fl-budicons-launch-recycling12:before {
	content: "\e019";
}

.fl-budicons-launch-right162:before {
	content: "\e01a";
}

.fl-budicons-launch-right164:before {
	content: "\e01b";
}

.fl-budicons-launch-search81:before {
	content: "\e01c";
}

.fl-budicons-launch-speedometer31:before {
	content: "\e01d";
}

.fl-budicons-launch-squares24:before {
	content: "\e01e";
}

.fl-budicons-launch-sun82:before {
	content: "\e01f";
}

.fl-budicons-launch-user146:before {
	content: "\e020";
}

.fl-budicons-launch-visualization5:before {
	content: "\e021";
}

.fl-budicons-launch-web34:before {
	content: "\e022";
}

/**
 * Title:  Bigmug Line Flaticon Pack
 * Author: Catalin Fertu
 * Source: http://www.flaticon.com/packs/bigmug-line
 * License:  CC BY 3.0 (http://creativecommons.org/licenses/by/3.0/)
 */
@font-face {
	font-family: "fl-bigmug-line";
	src: url("/fonts/fl-bigmug-line.eot");
	src: url("/fonts/fl-bigmug-line.eot#iefix") format("embedded-opentype"), url("/fonts/fl-bigmug-line.woff") format("woff"), url("/fonts/fl-bigmug-line.ttf") format("truetype"), url("/fonts/fl-bigmug-line.svg") format("svg");
	font-weight: normal;
	font-style: normal;
}

.fl-bigmug-line-ico,
[class^="fl-bigmug-line-"]:before, [class*=" fl-bigmug-line-"]:before,
[class^="fl-bigmug-line-"]:after, [class*=" fl-bigmug-line-"]:after {
	font-family: 'fl-bigmug-line';
	font-size: inherit;
	font-weight: 400;
	font-style: normal;
}

.fl-bigmug-line-add137:before {
	content: "\e000";
}

.fl-bigmug-line-add139:before {
	content: "\e001";
}

.fl-bigmug-line-add149:before {
	content: "\e002";
}

.fl-bigmug-line-airplane86:before {
	content: "\e003";
}

.fl-bigmug-line-alarm31:before {
	content: "\e004";
}

.fl-bigmug-line-arrow592:before {
	content: "\e005";
}

.fl-bigmug-line-attach8:before {
	content: "\e006";
}

.fl-bigmug-line-attachment15:before {
	content: "\e007";
}

.fl-bigmug-line-audio46:before {
	content: "\e008";
}

.fl-bigmug-line-back44:before {
	content: "\e009";
}

.fl-bigmug-line-back46:before {
	content: "\e00a";
}

.fl-bigmug-line-big104:before {
	content: "\e00b";
}

.fl-bigmug-line-book188:before {
	content: "\e00c";
}

.fl-bigmug-line-bookmark28:before {
	content: "\e00d";
}

.fl-bigmug-line-bottle34:before {
	content: "\e00e";
}

.fl-bigmug-line-button5:before {
	content: "\e00f";
}

.fl-bigmug-line-buttons5:before {
	content: "\e010";
}

.fl-bigmug-line-cellphone55:before {
	content: "\e011";
}

.fl-bigmug-line-cellular9:before {
	content: "\e012";
}

.fl-bigmug-line-center10:before {
	content: "\e013";
}

.fl-bigmug-line-chat51:before {
	content: "\e014";
}

.fl-bigmug-line-chat55:before {
	content: "\e015";
}

.fl-bigmug-line-checkmark14:before {
	content: "\e016";
}

.fl-bigmug-line-checkmark15:before {
	content: "\e017";
}

.fl-bigmug-line-checkmark16:before {
	content: "\e018";
}

.fl-bigmug-line-circular220:before {
	content: "\e019";
}

.fl-bigmug-line-circular224:before {
	content: "\e01a";
}

.fl-bigmug-line-circular228:before {
	content: "\e01b";
}

.fl-bigmug-line-circular229:before {
	content: "\e01c";
}

.fl-bigmug-line-clipboard68:before {
	content: "\e01d";
}

.fl-bigmug-line-close42:before {
	content: "\e01e";
}

.fl-bigmug-line-cloud255:before {
	content: "\e01f";
}

.fl-bigmug-line-cloud260:before {
	content: "\e020";
}

.fl-bigmug-line-cocktail26:before {
	content: "\e021";
}

.fl-bigmug-line-code30:before {
	content: "\e022";
}

.fl-bigmug-line-collapse5:before {
	content: "\e023";
}

.fl-bigmug-line-comment45:before {
	content: "\e024";
}

.fl-bigmug-line-compass80:before {
	content: "\e025";
}

.fl-bigmug-line-contract5:before {
	content: "\e026";
}

.fl-bigmug-line-copy23:before {
	content: "\e027";
}

.fl-bigmug-line-crescent23:before {
	content: "\e028";
}

.fl-bigmug-line-cropping1:before {
	content: "\e029";
}

.fl-bigmug-line-cross81:before {
	content: "\e02a";
}

.fl-bigmug-line-cross83:before {
	content: "\e02b";
}

.fl-bigmug-line-cube29:before {
	content: "\e02c";
}

.fl-bigmug-line-double97:before {
	content: "\e02d";
}

.fl-bigmug-line-double98:before {
	content: "\e02e";
}

.fl-bigmug-line-double99:before {
	content: "\e02f";
}

.fl-bigmug-line-down55:before {
	content: "\e030";
}

.fl-bigmug-line-down56:before {
	content: "\e031";
}

.fl-bigmug-line-down58:before {
	content: "\e032";
}

.fl-bigmug-line-down59:before {
	content: "\e033";
}

.fl-bigmug-line-down64:before {
	content: "\e034";
}

.fl-bigmug-line-download136:before {
	content: "\e035";
}

.fl-bigmug-line-download142:before {
	content: "\e036";
}

.fl-bigmug-line-download146:before {
	content: "\e037";
}

.fl-bigmug-line-download147:before {
	content: "\e038";
}

.fl-bigmug-line-download148:before {
	content: "\e039";
}

.fl-bigmug-line-electrical17:before {
	content: "\e03a";
}

.fl-bigmug-line-electronic57:before {
	content: "\e03b";
}

.fl-bigmug-line-email64:before {
	content: "\e03c";
}

.fl-bigmug-line-email67:before {
	content: "\e03d";
}

.fl-bigmug-line-equalization3:before {
	content: "\e03e";
}

.fl-bigmug-line-equalizer26:before {
	content: "\e03f";
}

.fl-bigmug-line-event6:before {
	content: "\e040";
}

.fl-bigmug-line-expand25:before {
	content: "\e041";
}

.fl-bigmug-line-expanding2:before {
	content: "\e042";
}

.fl-bigmug-line-fast33:before {
	content: "\e043";
}

.fl-bigmug-line-favourites5:before {
	content: "\e044";
}

.fl-bigmug-line-file68:before {
	content: "\e045";
}

.fl-bigmug-line-file69:before {
	content: "\e046";
}

.fl-bigmug-line-film57:before {
	content: "\e047";
}

.fl-bigmug-line-flag53:before {
	content: "\e048";
}

.fl-bigmug-line-fog10:before {
	content: "\e049";
}

.fl-bigmug-line-foggy3:before {
	content: "\e04a";
}

.fl-bigmug-line-folder173:before {
	content: "\e04b";
}

.fl-bigmug-line-fork34:before {
	content: "\e04c";
}

.fl-bigmug-line-four87:before {
	content: "\e04d";
}

.fl-bigmug-line-full40:before {
	content: "\e04e";
}

.fl-bigmug-line-games32:before {
	content: "\e04f";
}

.fl-bigmug-line-gear30:before {
	content: "\e050";
}

.fl-bigmug-line-giftbox54:before {
	content: "\e051";
}

.fl-bigmug-line-graphical8:before {
	content: "\e052";
}

.fl-bigmug-line-headphones32:before {
	content: "\e053";
}

.fl-bigmug-line-hot67:before {
	content: "\e054";
}

.fl-bigmug-line-images21:before {
	content: "\e055";
}

.fl-bigmug-line-ink12:before {
	content: "\e056";
}

.fl-bigmug-line-label25:before {
	content: "\e057";
}

.fl-bigmug-line-left144:before {
	content: "\e058";
}

.fl-bigmug-line-left145:before {
	content: "\e059";
}

.fl-bigmug-line-left146:before {
	content: "\e05a";
}

.fl-bigmug-line-left148:before {
	content: "\e05b";
}

.fl-bigmug-line-left152:before {
	content: "\e05c";
}

.fl-bigmug-line-left153:before {
	content: "\e05d";
}

.fl-bigmug-line-left158:before {
	content: "\e05e";
}

.fl-bigmug-line-left159:before {
	content: "\e05f";
}

.fl-bigmug-line-like51:before {
	content: "\e060";
}

.fl-bigmug-line-link52:before {
	content: "\e061";
}

.fl-bigmug-line-list63:before {
	content: "\e062";
}

.fl-bigmug-line-list65:before {
	content: "\e063";
}

.fl-bigmug-line-lock64:before {
	content: "\e064";
}

.fl-bigmug-line-login12:before {
	content: "\e065";
}

.fl-bigmug-line-login9:before {
	content: "\e066";
}

.fl-bigmug-line-map87:before {
	content: "\e067";
}

.fl-bigmug-line-megaphone11:before {
	content: "\e068";
}

.fl-bigmug-line-men25:before {
	content: "\e069";
}

.fl-bigmug-line-menu40:before {
	content: "\e06a";
}

.fl-bigmug-line-menu41:before {
	content: "\e06b";
}

.fl-bigmug-line-microphone76:before {
	content: "\e06c";
}

.fl-bigmug-line-microphone77:before {
	content: "\e06d";
}

.fl-bigmug-line-minus79:before {
	content: "\e06e";
}

.fl-bigmug-line-minus80:before {
	content: "\e06f";
}

.fl-bigmug-line-minus83:before {
	content: "\e070";
}

.fl-bigmug-line-minus86:before {
	content: "\e071";
}

.fl-bigmug-line-monitor74:before {
	content: "\e072";
}

.fl-bigmug-line-music218:before {
	content: "\e073";
}

.fl-bigmug-line-music219:before {
	content: "\e074";
}

.fl-bigmug-line-music221:before {
	content: "\e075";
}

.fl-bigmug-line-musical100:before {
	content: "\e076";
}

.fl-bigmug-line-musical98:before {
	content: "\e077";
}

.fl-bigmug-line-mute34:before {
	content: "\e078";
}

.fl-bigmug-line-new83:before {
	content: "\e079";
}

.fl-bigmug-line-nine16:before {
	content: "\e07a";
}

.fl-bigmug-line-note35:before {
	content: "\e07b";
}

.fl-bigmug-line-notebook41:before {
	content: "\e07c";
}

.fl-bigmug-line-notification4:before {
	content: "\e07d";
}

.fl-bigmug-line-notification5:before {
	content: "\e07e";
}

.fl-bigmug-line-opened25:before {
	content: "\e07f";
}

.fl-bigmug-line-oval34:before {
	content: "\e080";
}

.fl-bigmug-line-paintbrush9:before {
	content: "\e081";
}

.fl-bigmug-line-paper122:before {
	content: "\e082";
}

.fl-bigmug-line-pause37:before {
	content: "\e083";
}

.fl-bigmug-line-pencil85:before {
	content: "\e084";
}

.fl-bigmug-line-phone351:before {
	content: "\e085";
}

.fl-bigmug-line-photo181:before {
	content: "\e086";
}

.fl-bigmug-line-pin42:before {
	content: "\e087";
}

.fl-bigmug-line-planetary2:before {
	content: "\e088";
}

.fl-bigmug-line-play83:before {
	content: "\e089";
}

.fl-bigmug-line-portfolio23:before {
	content: "\e08a";
}

.fl-bigmug-line-print34:before {
	content: "\e08b";
}

.fl-bigmug-line-radio46:before {
	content: "\e08c";
}

.fl-bigmug-line-rain30:before {
	content: "\e08d";
}

.fl-bigmug-line-rectangular78:before {
	content: "\e08e";
}

.fl-bigmug-line-recycling10:before {
	content: "\e08f";
}

.fl-bigmug-line-rewind37:before {
	content: "\e090";
}

.fl-bigmug-line-right139:before {
	content: "\e091";
}

.fl-bigmug-line-right141:before {
	content: "\e092";
}

.fl-bigmug-line-right142:before {
	content: "\e093";
}

.fl-bigmug-line-right144:before {
	content: "\e094";
}

.fl-bigmug-line-right148:before {
	content: "\e095";
}

.fl-bigmug-line-right153:before {
	content: "\e096";
}

.fl-bigmug-line-right154:before {
	content: "\e097";
}

.fl-bigmug-line-right156:before {
	content: "\e098";
}

.fl-bigmug-line-rounded51:before {
	content: "\e099";
}

.fl-bigmug-line-sand14:before {
	content: "\e09a";
}

.fl-bigmug-line-save15:before {
	content: "\e09b";
}

.fl-bigmug-line-search74:before {
	content: "\e09c";
}

.fl-bigmug-line-search78:before {
	content: "\e09d";
}

.fl-bigmug-line-share27:before {
	content: "\e09e";
}

.fl-bigmug-line-shopping198:before {
	content: "\e09f";
}

.fl-bigmug-line-shopping199:before {
	content: "\e0a0";
}

.fl-bigmug-line-shopping202:before {
	content: "\e0a1";
}

.fl-bigmug-line-shopping204:before {
	content: "\e0a2";
}

.fl-bigmug-line-shuffle17:before {
	content: "\e0a3";
}

.fl-bigmug-line-sort47:before {
	content: "\e0a4";
}

.fl-bigmug-line-sort48:before {
	content: "\e0a5";
}

.fl-bigmug-line-speaker75:before {
	content: "\e0a6";
}

.fl-bigmug-line-speaker80:before {
	content: "\e0a7";
}

.fl-bigmug-line-speaker81:before {
	content: "\e0a8";
}

.fl-bigmug-line-speaker86:before {
	content: "\e0a9";
}

.fl-bigmug-line-speaker87:before {
	content: "\e0aa";
}

.fl-bigmug-line-speech96:before {
	content: "\e0ab";
}

.fl-bigmug-line-square152:before {
	content: "\e0ac";
}

.fl-bigmug-line-square156:before {
	content: "\e0ad";
}

.fl-bigmug-line-square160:before {
	content: "\e0ae";
}

.fl-bigmug-line-store10:before {
	content: "\e0af";
}

.fl-bigmug-line-sun81:before {
	content: "\e0b0";
}

.fl-bigmug-line-sunrise3:before {
	content: "\e0b1";
}

.fl-bigmug-line-switch23:before {
	content: "\e0b2";
}

.fl-bigmug-line-switch24:before {
	content: "\e0b3";
}

.fl-bigmug-line-tag47:before {
	content: "\e0b4";
}

.fl-bigmug-line-television20:before {
	content: "\e0b5";
}

.fl-bigmug-line-text108:before {
	content: "\e0b6";
}

.fl-bigmug-line-text109:before {
	content: "\e0b7";
}

.fl-bigmug-line-three142:before {
	content: "\e0b8";
}

.fl-bigmug-line-timer35:before {
	content: "\e0b9";
}

.fl-bigmug-line-tool16:before {
	content: "\e0ba";
}

.fl-bigmug-line-triangle33:before {
	content: "\e0bb";
}

.fl-bigmug-line-trophy55:before {
	content: "\e0bc";
}

.fl-bigmug-line-two311:before {
	content: "\e0bd";
}

.fl-bigmug-line-two316:before {
	content: "\e0be";
}

.fl-bigmug-line-two317:before {
	content: "\e0bf";
}

.fl-bigmug-line-two319:before {
	content: "\e0c0";
}

.fl-bigmug-line-two323:before {
	content: "\e0c1";
}

.fl-bigmug-line-unlocked27:before {
	content: "\e0c2";
}

.fl-bigmug-line-up100:before {
	content: "\e0c3";
}

.fl-bigmug-line-up102:before {
	content: "\e0c4";
}

.fl-bigmug-line-up103:before {
	content: "\e0c5";
}

.fl-bigmug-line-up104:before {
	content: "\e0c6";
}

.fl-bigmug-line-up107:before {
	content: "\e0c7";
}

.fl-bigmug-line-up111:before {
	content: "\e0c8";
}

.fl-bigmug-line-up112:before {
	content: "\e0c9";
}

.fl-bigmug-line-up114:before {
	content: "\e0ca";
}

.fl-bigmug-line-up98:before {
	content: "\e0cb";
}

.fl-bigmug-line-up99:before {
	content: "\e0cc";
}

.fl-bigmug-line-upload91:before {
	content: "\e0cd";
}

.fl-bigmug-line-upload92:before {
	content: "\e0ce";
}

.fl-bigmug-line-upper8:before {
	content: "\e0cf";
}

.fl-bigmug-line-user143:before {
	content: "\e0d0";
}

.fl-bigmug-line-user144:before {
	content: "\e0d1";
}

.fl-bigmug-line-video163:before {
	content: "\e0d2";
}

.fl-bigmug-line-wallet26:before {
	content: "\e0d3";
}

.fl-bigmug-line-weather21:before {
	content: "\e0d4";
}

.fl-bigmug-line-weekly14:before {
	content: "\e0d5";
}

.fl-bigmug-line-weekly15:before {
	content: "\e0d6";
}

.fl-bigmug-line-wind24:before {
	content: "\e0d7";
}

.fl-bigmug-line-window50:before {
	content: "\e0d8";
}

.fl-bigmug-line-winds4:before {
	content: "\e0d9";
}

.fl-bigmug-line-wrench66:before {
	content: "\e0da";
}

.fl-bigmug-line-zoom60:before {
	content: "\e0db";
}

/**
 * Title: Justicons Flaticon Pack
 * Author: Rami McMin
 * Source: http://www.flaticon.com/packs/justicons
 * License:  CC BY 3.0 (http://creativecommons.org/licenses/by/3.0/)
 */
@font-face {
	font-family: "fl-justicons";
	src: url("/fonts/fl-justicons.eot");
	src: url("/fonts/fl-justicons.eot#iefix") format("embedded-opentype"), url("/fonts/fl-justicons.woff") format("woff"), url("/fonts/fl-justicons.ttf") format("truetype"), url("/fonts/fl-justicons.svg") format("svg");
	font-weight: normal;
	font-style: normal;
}

.fl-justicons-ico,
[class^="fl-justicons-"]:before, [class*=" fl-justicons-"]:before,
[class^="fl-justicons-"]:after, [class*=" fl-justicons-"]:after {
	font-family: fl-justicons;
	font-size: inherit;
	font-weight: 400;
	font-style: normal;
}

.fl-justicons-acceptance:before {
	content: "\e000";
}

.fl-justicons-add133:before {
	content: "\e001";
}

.fl-justicons-airplane82:before {
	content: "\e002";
}

.fl-justicons-anchor31:before {
	content: "\e003";
}

.fl-justicons-attachment13:before {
	content: "\e004";
}

.fl-justicons-attention5:before {
	content: "\e005";
}

.fl-justicons-back36:before {
	content: "\e006";
}

.fl-justicons-barbecue6:before {
	content: "\e007";
}

.fl-justicons-bars48:before {
	content: "\e008";
}

.fl-justicons-basketball38:before {
	content: "\e009";
}

.fl-justicons-bell37:before {
	content: "\e00a";
}

.fl-justicons-blank25:before {
	content: "\e00b";
}

.fl-justicons-bookmark26:before {
	content: "\e00c";
}

.fl-justicons-brush22:before {
	content: "\e00d";
}

.fl-justicons-chef24:before {
	content: "\e00e";
}

.fl-justicons-chronometer17:before {
	content: "\e00f";
}

.fl-justicons-circular210:before {
	content: "\e010";
}

.fl-justicons-circular212:before {
	content: "\e011";
}

.fl-justicons-clipboard66:before {
	content: "\e012";
}

.fl-justicons-clockwise2:before {
	content: "\e013";
}

.fl-justicons-close40:before {
	content: "\e014";
}

.fl-justicons-cloud246:before {
	content: "\e015";
}

.fl-justicons-cloud247:before {
	content: "\e016";
}

.fl-justicons-cloud248:before {
	content: "\e017";
}

.fl-justicons-cloud249:before {
	content: "\e018";
}

.fl-justicons-cloudy17:before {
	content: "\e019";
}

.fl-justicons-cloudy19:before {
	content: "\e01a";
}

.fl-justicons-cloudy20:before {
	content: "\e01b";
}

.fl-justicons-cloudy21:before {
	content: "\e01c";
}

.fl-justicons-cloudy22:before {
	content: "\e01d";
}

.fl-justicons-cold10:before {
	content: "\e01e";
}

.fl-justicons-commercial26:before {
	content: "\e01f";
}

.fl-justicons-compass79:before {
	content: "\e020";
}

.fl-justicons-cooking20:before {
	content: "\e021";
}

.fl-justicons-cupcake13:before {
	content: "\e022";
}

.fl-justicons-danger8:before {
	content: "\e023";
}

.fl-justicons-database43:before {
	content: "\e024";
}

.fl-justicons-diaphragm9:before {
	content: "\e025";
}

.fl-justicons-down53:before {
	content: "\e026";
}

.fl-justicons-download131:before {
	content: "\e027";
}

.fl-justicons-edit41:before {
	content: "\e028";
}

.fl-justicons-electrical14:before {
	content: "\e029";
}

.fl-justicons-empty31:before {
	content: "\e02a";
}

.fl-justicons-empty32:before {
	content: "\e02b";
}

.fl-justicons-equalization2:before {
	content: "\e02c";
}

.fl-justicons-favorites2:before {
	content: "\e02d";
}

.fl-justicons-file60:before {
	content: "\e02e";
}

.fl-justicons-first40:before {
	content: "\e02f";
}

.fl-justicons-flash22:before {
	content: "\e030";
}

.fl-justicons-flower109:before {
	content: "\e031";
}

.fl-justicons-focus7:before {
	content: "\e032";
}

.fl-justicons-fog8:before {
	content: "\e033";
}

.fl-justicons-folded32:before {
	content: "\e034";
}

.fl-justicons-folder167:before {
	content: "\e035";
}

.fl-justicons-game51:before {
	content: "\e036";
}

.fl-justicons-global24:before {
	content: "\e037";
}

.fl-justicons-hazelnut1:before {
	content: "\e038";
}

.fl-justicons-headphones30:before {
	content: "\e039";
}

.fl-justicons-heart279:before {
	content: "\e03a";
}

.fl-justicons-helm1:before {
	content: "\e03b";
}

.fl-justicons-home133:before {
	content: "\e03c";
}

.fl-justicons-information51:before {
	content: "\e03d";
}

.fl-justicons-internet33:before {
	content: "\e03e";
}

.fl-justicons-leaf57:before {
	content: "\e03f";
}

.fl-justicons-link49:before {
	content: "\e040";
}

.fl-justicons-liquid11:before {
	content: "\e041";
}

.fl-justicons-lock63:before {
	content: "\e042";
}

.fl-justicons-magnetic1:before {
	content: "\e043";
}

.fl-justicons-magnifier32:before {
	content: "\e044";
}

.fl-justicons-magnifier33:before {
	content: "\e045";
}

.fl-justicons-maps7:before {
	content: "\e046";
}

.fl-justicons-medal54:before {
	content: "\e047";
}

.fl-justicons-megaphone10:before {
	content: "\e048";
}

.fl-justicons-minus75:before {
	content: "\e049";
}

.fl-justicons-moon144:before {
	content: "\e04a";
}

.fl-justicons-musical96:before {
	content: "\e04b";
}

.fl-justicons-new78:before {
	content: "\e04c";
}

.fl-justicons-note32:before {
	content: "\e04d";
}

.fl-justicons-note33:before {
	content: "\e04e";
}

.fl-justicons-parabolic11:before {
	content: "\e04f";
}

.fl-justicons-peace5:before {
	content: "\e050";
}

.fl-justicons-pen57:before {
	content: "\e051";
}

.fl-justicons-photo171:before {
	content: "\e052";
}

.fl-justicons-piano21:before {
	content: "\e053";
}

.fl-justicons-picture27:before {
	content: "\e054";
}

.fl-justicons-pill7:before {
	content: "\e055";
}

.fl-justicons-pin39:before {
	content: "\e056";
}

.fl-justicons-pine8:before {
	content: "\e057";
}

.fl-justicons-portfolio21:before {
	content: "\e058";
}

.fl-justicons-power96:before {
	content: "\e059";
}

.fl-justicons-precious1:before {
	content: "\e05a";
}

.fl-justicons-print31:before {
	content: "\e05b";
}

.fl-justicons-prohibition17:before {
	content: "\e05c";
}

.fl-justicons-rain27:before {
	content: "\e05d";
}

.fl-justicons-rainy11:before {
	content: "\e05e";
}

.fl-justicons-rainy12:before {
	content: "\e05f";
}

.fl-justicons-right132:before {
	content: "\e060";
}

.fl-justicons-right133:before {
	content: "\e061";
}

.fl-justicons-right134:before {
	content: "\e062";
}

.fl-justicons-rocket64:before {
	content: "\e063";
}

.fl-justicons-saturn12:before {
	content: "\e064";
}

.fl-justicons-shield82:before {
	content: "\e065";
}

.fl-justicons-shopping189:before {
	content: "\e066";
}

.fl-justicons-skull26:before {
	content: "\e067";
}

.fl-justicons-spam7:before {
	content: "\e068";
}

.fl-justicons-speaker73:before {
	content: "\e069";
}

.fl-justicons-speech92:before {
	content: "\e06a";
}

.fl-justicons-sports36:before {
	content: "\e06b";
}

.fl-justicons-sun79:before {
	content: "\e06c";
}

.fl-justicons-sunrise2:before {
	content: "\e06d";
}

.fl-justicons-sunset2:before {
	content: "\e06e";
}

.fl-justicons-switches:before {
	content: "\e06f";
}

.fl-justicons-tag44:before {
	content: "\e070";
}

.fl-justicons-telephone95:before {
	content: "\e071";
}

.fl-justicons-temperature11:before {
	content: "\e072";
}

.fl-justicons-thermometer40:before {
	content: "\e073";
}

.fl-justicons-thermometer41:before {
	content: "\e074";
}

.fl-justicons-three140:before {
	content: "\e075";
}

.fl-justicons-tool15:before {
	content: "\e076";
}

.fl-justicons-tray15:before {
	content: "\e077";
}

.fl-justicons-two298:before {
	content: "\e078";
}

.fl-justicons-umbrella40:before {
	content: "\e079";
}

.fl-justicons-university13:before {
	content: "\e07a";
}

.fl-justicons-upload81:before {
	content: "\e07b";
}

.fl-justicons-upload82:before {
	content: "\e07c";
}

.fl-justicons-upload83:before {
	content: "\e07d";
}

.fl-justicons-upper4:before {
	content: "\e07e";
}

.fl-justicons-user140:before {
	content: "\e07f";
}

.fl-justicons-video158:before {
	content: "\e080";
}

.fl-justicons-visible6:before {
	content: "\e081";
}

.fl-justicons-voice19:before {
	content: "\e082";
}

.fl-justicons-wheel24:before {
	content: "\e083";
}

.fl-justicons-wheel25:before {
	content: "\e084";
}

.fl-justicons-white83:before {
	content: "\e085";
}

.fl-justicons-wifi74:before {
	content: "\e086";
}

.fl-justicons-wind23:before {
	content: "\e087";
}

.fl-justicons-winds2:before {
	content: "\e088";
}

.fl-justicons-winter23:before {
	content: "\e089";
}

.fl-justicons-wrench64:before {
	content: "\e08a";
}

.fl-justicons-zig5:before {
	content: "\e08b";
}

/*!

 =========================================================
 * Gulvel Web React - v1.0.0 based on Gulvel Web - v2.2.0
 =========================================================

 * Product Page: https://www.gulvel.com/product/job-plus-2
 * Copyright 2019 Gulvel Inc (http://www.gulvel.com)

 * Designed by www.invisionapp.com Coded by www.gulvel.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
/*      light colors - used for select dropdown         */
.navbar,
.navbar > .container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-left: 0px; }
  @media (max-width: 768px) {
    .navbar,
    .navbar > .container {
      display: block;
      width: 100vw; } }

.navbar {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  padding: 1rem 1.15rem;
  border-bottom: 1px solid #eceef3;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  /*
  |-----------------------------------
  | Start navbar logo or brand etc..
  |-----------------------------------
  */
  /*
  |-----------------------------------
  | Start navbar menu
  |-----------------------------------
  */ }
  @media (min-width: 576px) {
    .navbar .container {
      max-width: 540px;
      margin-top: 0px !important; } }
  @media (min-width: 768px) {
    .navbar .container {
      max-width: 720px;
      margin-top: 0px !important; } }
  @media (min-width: 992px) {
    .navbar .container {
      max-width: 960px;
      margin-top: 0px !important; } }
  @media (min-width: 1200px) {
    .navbar .container {
      max-width: 1140px;
      margin-top: 0px !important; } }
  .navbar .navbar-header {
    display: flex;
    align-items: center; }
    @media (max-width: 768px) {
      .navbar .navbar-header img {
        width: 60px;
        height: 60px; } }
    @media (max-width: 768px) {
      .navbar .navbar-header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row-reverse; } }
    .navbar .navbar-header .navbar-toggler {
      cursor: pointer;
      border: none;
      display: none;
      outline: none; }
      @media (max-width: 768px) {
        .navbar .navbar-header .navbar-toggler {
          display: block; } }
      .navbar .navbar-header .navbar-toggler span {
        height: 2px;
        width: 22px;
        background-color: #595959;
        display: block; }
      .navbar .navbar-header .navbar-toggler span:not(:last-child) {
        margin-bottom: 0.2rem; }
    .navbar .navbar-header > a {
      font-weight: 500;
      color: #000000; }
  .navbar .navbar-menu {
    display: flex;
    align-items: center;
    flex-basis: auto;
    flex-grow: 1; }
    @media (max-width: 768px) {
      .navbar .navbar-menu {
        display: none;
        text-align: center; } }
    .navbar .navbar-menu.active {
      display: flex !important; }
    .navbar .navbar-menu .navbar-nav {
      margin-left: auto;
      flex-direction: row;
      display: flex;
      padding-left: 0;
      margin-bottom: 0;
      list-style: none; }
      @media (max-width: 768px) {
        .navbar .navbar-menu .navbar-nav {
          width: 100%;
          display: flex;
          border-top: 1px solid #EEE;
          margin-top: 1rem;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column; } }
      .navbar .navbar-menu .navbar-nav > li > ul > li > a {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-transform: capitalize;
        color: #66615b;
        opacity: 0.5;
        color: #000000;
        text-decoration: none;
        display: inline-block;
        padding: 0.5rem 1rem; }
        .navbar .navbar-menu .navbar-nav > li > ul > li > a > i {
          width: 30px; }
        .navbar .navbar-menu .navbar-nav > li > ul > li > a:hover {
          color: #66615b;
          opacity: 1; }
      .navbar .navbar-menu .navbar-nav > li > a {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-transform: capitalize;
        color: #66615b;
        opacity: 0.5;
        color: #000000;
        text-decoration: none;
        display: inline-block;
        padding: 0.5rem 1rem; }
        .navbar .navbar-menu .navbar-nav > li > a:hover {
          color: #66615b;
          opacity: 1; }
      .navbar .navbar-menu .navbar-nav > li.active a {
        color: #150F3D;
        opacity: 1; }
      .navbar .navbar-menu .navbar-nav > li + li {
        margin-left: 5px;
        margin-right: 5px; }
        @media (max-width: 768px) {
          .navbar .navbar-menu .navbar-nav > li + li {
            margin-left: 0px;
            margin-right: 0px;
            text-align: left; } }
      .navbar .navbar-menu .navbar-nav .home-menu {
        margin-right: 9px; }
      .navbar .navbar-menu .navbar-nav .navbar-dropdown .dropdown {
        list-style: none;
        position: absolute;
        top: 164%;
        left: 0;
        background-color: #fff;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        min-width: 160px;
        width: auto;
        white-space: nowrap;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
        z-index: 99999;
        border-radius: 0.75rem;
        display: none; }
        @media (max-width: 768px) {
          .navbar .navbar-menu .navbar-nav .navbar-dropdown .dropdown {
            position: relative;
            box-shadow: none;
            text-align: left; } }
        .navbar .navbar-menu .navbar-nav .navbar-dropdown .dropdown li a {
          color: #000000;
          padding: 0.25rem 1rem;
          display: block; }
        .navbar .navbar-menu .navbar-nav .navbar-dropdown .dropdown.show {
          display: block !important; }
      .navbar .navbar-menu .navbar-nav .dropdown > .separator {
        height: 1px;
        width: 100%;
        margin-top: 9px;
        margin-bottom: 9px;
        background-color: #eceef3; }
  .navbar .navbar-dropdown {
    position: relative; }

.navbar .navbar-header > a span {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: #66615b;
  opacity: 0.5; }

.navbar .navbar-header h4 {
  font-weight: 500;
  font-size: 1.25rem; }
  @media (max-width: 768px) {
    .navbar .navbar-header h4 {
      font-size: 1.05rem; } }

.header-sign-btn-li {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px !important; }

.navbar-menu-span {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: #000000;
  text-decoration: none;
  display: inline-block;
  padding: 0.5rem 1rem;
  color: #150F3D; }

.navbar-menu-span:hover {
  cursor: pointer; }

.home-row-container {
  padding: 20px; }

.dashboar-outer-section {
  background-color: #FAFBFD; }

.search-div {
  width: 119%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 4rem;
  background-color: #FAFBFD; }
  @media screen and (max-width: 768px) {
    .search-div {
      margin-left: 20px;
      width: 109%; } }
  .search-div input::-webkit-input-placeholder {
    color: #000000 !important;
    text-align: left;
    margin-left: 10px;
    text-transform: uppercase !important;
    font-style: normal; }
  .search-div input::placeholder {
    color: #000000 !important;
    text-align: left;
    margin-left: 10px;
    text-transform: uppercase !important;
    font-style: normal; }
  .search-div input:focus::-webkit-input-placeholder {
    opacity: 0; }
  .search-div input:focus::placeholder {
    opacity: 0; }
  .search-div .search-input {
    width: 80%;
    text-align: left;
    color: #000000;
    font-size: 14px;
    border: none;
    padding-left: 10px; }

.dashboard {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #FAFBFD; }
  @media screen and (max-width: 768px) {
    .dashboard {
      flex-wrap: wrap-reverse;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 0px;
      flex-direction: column;
      margin: 0px; } }
  .dashboard .left-panel {
    height: 100%;
    width: 30vw;
    text-align: center;
    margin: 10px;
    padding: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column; }
    @media screen and (max-width: 768px) {
      .dashboard .left-panel {
        width: 100%;
        margin: 0px; } }
    .dashboard .left-panel .active-job-div {
      width: 100%;
      padding: 10px;
      text-align: left;
      margin-top: 20px;
      cursor: pointer; }
      @media screen and (max-width: 768px) {
        .dashboard .left-panel .active-job-div {
          margin-top: 0px;
          height: auto; } }
      .dashboard .left-panel .active-job-div .active-job-label {
        color: #000000;
        font-size: 14px;
        font-weight: 450;
        margin-bottom: 15px;
        font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
        text-transform: uppercase; }
    .dashboard .left-panel .complete-job-list {
      width: 100%;
      padding: 10px;
      text-align: left;
      margin-top: 20px;
      cursor: pointer; }
      @media screen and (max-width: 768px) {
        .dashboard .left-panel .complete-job-list {
          margin-top: 0px;
          height: auto; } }
      .dashboard .left-panel .complete-job-list .complete-job-label {
        color: #000000;
        font-weight: 450;
        margin-bottom: 15px;
        font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
        text-transform: uppercase; }
    .dashboard .left-panel .infinte-scroll-div {
      height: 300px;
      overflow-y: auto;
      padding-bottom: 20px; }
      @media screen and (max-width: 500px) {
        .dashboard .left-panel .infinte-scroll-div {
          height: 40vh;
          overflow-y: scroll;
          padding-bottom: 5px; } }
      @media screen and (max-width: 350px) {
        .dashboard .left-panel .infinte-scroll-div {
          height: 30vh;
          overflow-y: scroll;
          padding-bottom: 5px; } }
  .dashboard .right-panel {
    height: 100%;
    width: 70vw;
    text-align: center;
    margin: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column; }
    @media screen and (max-width: 768px) {
      .dashboard .right-panel {
        width: 98%;
        height: 100%;
        margin: 0px; } }
    .dashboard .right-panel .visit-job-post-div {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background-color: #FFFFFF !important;
      margin: 15px;
      padding: 0px 10px;
      align-self: flex-end; }
      .dashboard .right-panel .visit-job-post-div label {
        font-size: 13px;
        color: #0A043B;
        font-style: italic;
        font-weight: 500;
        font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
        margin-right: 20px; }
      .dashboard .right-panel .visit-job-post-div i {
        font-size: 10px;
        color: #0A043B;
        font-weight: 400; }
    .dashboard .right-panel .summary-application-div {
      background-color: #FFFFFF;
      height: auto;
      width: 100%;
      padding: 20px;
      border-radius: 8px; }
      @media screen and (max-width: 768px) {
        .dashboard .right-panel .summary-application-div {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          overflow-x: scroll;
          flex-wrap: wrap;
          height: 100%;
          padding: 10px; } }
      .dashboard .right-panel .summary-application-div .summary-top-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        padding: 0px 20px;
        flex-wrap: wrap;
        flex: 50% 1; }
        @media screen and (max-width: 768px) {
          .dashboard .right-panel .summary-application-div .summary-top-div {
            padding: 0px;
            flex: 100% 1;
            justify-content: flex-start;
            align-items: flex-start; } }
        .dashboard .right-panel .summary-application-div .summary-top-div .summary-left-div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
          padding: 0px 20px;
          flex-wrap: wrap;
          flex: 50% 1; }
          @media screen and (max-width: 768px) {
            .dashboard .right-panel .summary-application-div .summary-top-div .summary-left-div {
              justify-content: space-between;
              margin-right: 10px;
              flex-wrap: nowrap;
              width: 50%;
              padding: 0px 0px 10px 0px; } }
          .dashboard .right-panel .summary-application-div .summary-top-div .summary-left-div label {
            font-size: 13px;
            color: #9A9A9A;
            font-weight: 500;
            font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
            line-height: 22px; }
            @media screen and (max-width: 768px) {
              .dashboard .right-panel .summary-application-div .summary-top-div .summary-left-div label {
                text-align: left; } }
        .dashboard .right-panel .summary-application-div .summary-top-div .summary-right-div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
          padding: 0px 20px;
          flex-wrap: wrap;
          flex: 50% 1; }
          @media screen and (max-width: 768px) {
            .dashboard .right-panel .summary-application-div .summary-top-div .summary-right-div {
              justify-content: space-between;
              margin-right: 10px;
              flex-wrap: nowrap;
              padding: 0px 0px 10px 0px;
              width: 50%; } }
          .dashboard .right-panel .summary-application-div .summary-top-div .summary-right-div label {
            font-size: 13px;
            color: #9A9A9A;
            font-weight: 500;
            font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
            line-height: 22px; }
            @media screen and (max-width: 768px) {
              .dashboard .right-panel .summary-application-div .summary-top-div .summary-right-div label {
                text-align: left; } }
      .dashboard .right-panel .summary-application-div .summary-middle-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        padding: 0px 20px;
        flex-wrap: wrap;
        flex: 50% 1; }
        @media screen and (max-width: 768px) {
          .dashboard .right-panel .summary-application-div .summary-middle-div {
            padding: 0px;
            flex: 100% 1;
            justify-content: flex-start;
            align-items: flex-start; } }
        .dashboard .right-panel .summary-application-div .summary-middle-div .summary-left-div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
          padding: 0px 20px;
          flex-wrap: wrap;
          flex: 50% 1; }
          @media screen and (max-width: 768px) {
            .dashboard .right-panel .summary-application-div .summary-middle-div .summary-left-div {
              justify-content: space-between;
              margin-right: 10px;
              flex-wrap: nowrap;
              width: 50%;
              padding: 0px 0px 10px 0px; } }
          .dashboard .right-panel .summary-application-div .summary-middle-div .summary-left-div label {
            font-size: 13px;
            color: #9A9A9A;
            font-weight: 500;
            font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
            line-height: 22px; }
            @media screen and (max-width: 768px) {
              .dashboard .right-panel .summary-application-div .summary-middle-div .summary-left-div label {
                text-align: left; } }
        .dashboard .right-panel .summary-application-div .summary-middle-div .summary-right-div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
          padding: 0px 20px;
          flex-wrap: wrap;
          flex: 50% 1; }
          @media screen and (max-width: 768px) {
            .dashboard .right-panel .summary-application-div .summary-middle-div .summary-right-div {
              justify-content: space-between;
              margin-right: 10px;
              flex-wrap: nowrap;
              padding: 0px 0px 10px 0px;
              width: 50%; } }
          .dashboard .right-panel .summary-application-div .summary-middle-div .summary-right-div label {
            font-size: 13px;
            color: #9A9A9A;
            font-weight: 500;
            font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
            line-height: 22px; }
            @media screen and (max-width: 768px) {
              .dashboard .right-panel .summary-application-div .summary-middle-div .summary-right-div label {
                text-align: left; } }
      .dashboard .right-panel .summary-application-div .summary-bottom-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        padding: 0px 20px;
        flex-wrap: wrap;
        flex: 50% 1; }
        @media screen and (max-width: 768px) {
          .dashboard .right-panel .summary-application-div .summary-bottom-div {
            padding: 0px;
            flex: 100% 1;
            justify-content: flex-start;
            align-items: flex-start; } }
        .dashboard .right-panel .summary-application-div .summary-bottom-div .summary-left-div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
          padding: 0px 20px;
          flex-wrap: wrap;
          flex: 50% 1; }
          @media screen and (max-width: 768px) {
            .dashboard .right-panel .summary-application-div .summary-bottom-div .summary-left-div {
              justify-content: space-between;
              margin-right: 10px;
              flex-wrap: nowrap;
              padding: 0px 0px 10px 0px;
              width: 50%; } }
          .dashboard .right-panel .summary-application-div .summary-bottom-div .summary-left-div label {
            font-size: 13px;
            color: #9A9A9A;
            font-weight: 500;
            font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
            line-height: 22px; }
            @media screen and (max-width: 768px) {
              .dashboard .right-panel .summary-application-div .summary-bottom-div .summary-left-div label {
                text-align: left; } }
          .dashboard .right-panel .summary-application-div .summary-bottom-div .summary-left-div .action-label {
            cursor: pointer;
            color: #0A043B; }
        .dashboard .right-panel .summary-application-div .summary-bottom-div .active-inactive-div {
          flex: 0% 1 !important;
          width: 15% !important; }
          @media screen and (max-width: 768px) {
            .dashboard .right-panel .summary-application-div .summary-bottom-div .active-inactive-div {
              justify-content: space-between;
              margin-right: 10px;
              flex-wrap: nowrap;
              width: 100% !important;
              padding: 0px 0px 10px 0px;
              flex: 100% 1 !important; } }
          .dashboard .right-panel .summary-application-div .summary-bottom-div .active-inactive-div .active-inactive-label {
            font-weight: 400;
            line-height: 12px;
            font-size: 15px !important;
            color: #000000;
            font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
            cursor: pointer; }
            @media screen and (max-width: 768px) {
              .dashboard .right-panel .summary-application-div .summary-bottom-div .active-inactive-div .active-inactive-label {
                text-align: left; } }
        .dashboard .right-panel .summary-application-div .summary-bottom-div .summary-right-div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
          padding: 0px 20px;
          flex-wrap: wrap;
          flex: 50% 1; }
          @media screen and (max-width: 768px) {
            .dashboard .right-panel .summary-application-div .summary-bottom-div .summary-right-div {
              justify-content: space-between;
              margin-right: 10px;
              flex-wrap: nowrap;
              padding: 0px 0px 10px 0px;
              width: 50%; } }
          .dashboard .right-panel .summary-application-div .summary-bottom-div .summary-right-div label {
            font-size: 13px;
            color: #9A9A9A;
            font-weight: 500;
            font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
            line-height: 22px; }
            @media screen and (max-width: 768px) {
              .dashboard .right-panel .summary-application-div .summary-bottom-div .summary-right-div label {
                text-align: left; } }
          .dashboard .right-panel .summary-application-div .summary-bottom-div .summary-right-div .visit-job-arraow-div {
            background-color: transparent;
            width: 100%;
            padding: 5px 10px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-size: 14px;
            color: #000000;
            font-weight: 600;
            cursor: pointer; }
            .dashboard .right-panel .summary-application-div .summary-bottom-div .summary-right-div .visit-job-arraow-div label {
              margin-right: 10px;
              cursor: pointer; }
              .dashboard .right-panel .summary-application-div .summary-bottom-div .summary-right-div .visit-job-arraow-div label:hover {
                color: #0A043B; }
            .dashboard .right-panel .summary-application-div .summary-bottom-div .summary-right-div .visit-job-arraow-div img {
              width: 25px;
              height: 25px;
              cursor: pointer; }
    .dashboard .right-panel .detail-application-div {
      width: 100%;
      padding: 20px 0px;
      text-transform: capitalize; }
      .dashboard .right-panel .detail-application-div .no-record-found-div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size: 16px;
        color: #000000; }
      .dashboard .right-panel .detail-application-div .table-loading-div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%; }
      .dashboard .right-panel .detail-application-div .candidate-list-div {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        height: 500px;
        margin-top: 10px;
        padding-top: 10px;
        overflow-y: auto; }
        .dashboard .right-panel .detail-application-div .candidate-list-div .candidate-list-inner-div {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          width: 100%;
          min-height: 250px; }
        .dashboard .right-panel .detail-application-div .candidate-list-div .candidate-row {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: row;
          width: 100%;
          height: auto;
          padding: 5px 10px;
          background-color: #FFFFFF;
          margin-right: 10px;
          border-radius: 4px;
          margin-bottom: 10px;
          flex-wrap: wrap; }
          @media screen and (max-width: 768px) {
            .dashboard .right-panel .detail-application-div .candidate-list-div .candidate-row {
              height: 100%; } }
          .dashboard .right-panel .detail-application-div .candidate-list-div .candidate-row .candidate-name-div {
            flex-basis: 40%;
            text-align: left;
            cursor: pointer;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center; }
            @media screen and (max-width: 768px) {
              .dashboard .right-panel .detail-application-div .candidate-list-div .candidate-row .candidate-name-div {
                flex-basis: 85%;
                max-height: 35px; } }
            @media screen and (min-width: 1325px) {
              .dashboard .right-panel .detail-application-div .candidate-list-div .candidate-row .candidate-name-div {
                flex-basis: 45%; } }
            .dashboard .right-panel .detail-application-div .candidate-list-div .candidate-row .candidate-name-div label {
              font-weight: 600;
              line-height: 12px;
              font-size: 13px !important;
              color: #000000;
              font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
              font-style: italic;
              cursor: pointer; }
          .dashboard .right-panel .detail-application-div .candidate-list-div .candidate-row .candidate-percent-div {
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px; }
          .dashboard .right-panel .detail-application-div .candidate-list-div .candidate-row .provider-action-div {
            width: 30%;
            justify-content: flex-end;
            align-items: center;
            padding: 0px 20px; }
            @media screen and (max-width: 768px) {
              .dashboard .right-panel .detail-application-div .candidate-list-div .candidate-row .provider-action-div {
                flex-basis: 45%;
                stify-content: flex-start;
                padding: 0px 0px;
                margin-right: 10px; } }
            .dashboard .right-panel .detail-application-div .candidate-list-div .candidate-row .provider-action-div .status-select-div {
              width: 70%;
              align-self: flex-end;
              float: right; }
              @media screen and (max-width: 768px) {
                .dashboard .right-panel .detail-application-div .candidate-list-div .candidate-row .provider-action-div .status-select-div {
                  width: 100%;
                  float: left;
                  text-align: left; } }
              .dashboard .right-panel .detail-application-div .candidate-list-div .candidate-row .provider-action-div .status-select-div label {
                font-weight: 600;
                line-height: 12px;
                font-size: 13px !important;
                color: #000000;
                font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
                font-style: italic; }
          .dashboard .right-panel .detail-application-div .candidate-list-div .candidate-row .assesment-div {
            flex-basis: 20%;
            text-align: left; }
            @media screen and (max-width: 768px) {
              .dashboard .right-panel .detail-application-div .candidate-list-div .candidate-row .assesment-div {
                float: right;
                justify-self: end; } }
            .dashboard .right-panel .detail-application-div .candidate-list-div .candidate-row .assesment-div .assesment-button {
              padding: 5px 10px;
              font-size: 10px; }
            .dashboard .right-panel .detail-application-div .candidate-list-div .candidate-row .assesment-div label {
              font-weight: 600;
              line-height: 12px;
              font-size: 13px !important;
              color: #000000;
              font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
              font-style: italic; }

.employer-home-page-outer-div {
  width: 100%;
  height: 100vh;
  height: 10vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #FAFBFD; }
  @media screen and (max-width: 768px) {
    .employer-home-page-outer-div {
      flex-wrap: wrap;
      height: 100%; } }

.margin-top0 {
  margin-top: 0px !important; }

.dashboard-row-margin {
  margin: 28px 10px 10px 0px !important;
  height: 40px !important;
  width: 100% !important; }

.title-dashboard-arrow-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-right: 10px; }
  @media screen and (max-width: 768px) {
    .title-dashboard-arrow-div {
      margin-top: 0px;
      margin-right: 0px;
      flex-wrap: wrap;
      height: 100%; } }
  .title-dashboard-arrow-div .employer-homepage-title-div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 60px; }
    @media screen and (max-width: 768px) {
      .title-dashboard-arrow-div .employer-homepage-title-div {
        width: 100%;
        height: 100%;
        justify-content: flex-start; } }
    .title-dashboard-arrow-div .employer-homepage-title-div .employer-homepage-title {
      font-size: 30px;
      color: #000;
      font-weight: 450; }
      @media screen and (max-width: 768px) {
        .title-dashboard-arrow-div .employer-homepage-title-div .employer-homepage-title {
          font-size: 20px; } }
  .title-dashboard-arrow-div .dashboard-arrow-div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
    background-color: #FAFBFD;
    height: 60px;
    padding: 5px 15px 5px 5px;
    flex-direction: row; }
    .title-dashboard-arrow-div .dashboard-arrow-div .view-dashboard {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      background-color: #FFFFFF !important;
      padding: 1px 5px; }
    @media screen and (max-width: 768px) {
      .title-dashboard-arrow-div .dashboard-arrow-div {
        width: 100%;
        height: 100%;
        flex-direction: row;
        justify-content: flex-end;
        margin: 0px 5px;
        margin-bottom: 10px; } }
    .title-dashboard-arrow-div .dashboard-arrow-div i {
      font-size: 10px;
      color: #0A043B;
      font-weight: 400; }
    .title-dashboard-arrow-div .dashboard-arrow-div .visit-dashboard-text {
      font-size: 15px;
      color: #000;
      padding-right: 10px;
      font-weight: 400 !important;
      font-style: italic; }
      .title-dashboard-arrow-div .dashboard-arrow-div .visit-dashboard-text:hover {
        color: #0A043B;
        cursor: pointer; }
    .title-dashboard-arrow-div .dashboard-arrow-div .bold-image {
      width: 15px;
      height: 20px; }
      @media screen and (max-width: 768px) {
        .title-dashboard-arrow-div .dashboard-arrow-div .bold-image {
          width: 25px;
          height: 25px; } }

.notification-active-job-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  color: #000; }
  @media screen and (max-width: 768px) {
    .notification-active-job-div {
      width: 100%;
      flex-wrap: wrap-reverse;
      height: 100%; } }
  .notification-active-job-div .notification-board-div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 50%;
    height: 80vh;
    background-color: #ffffff;
    padding: 20px; }
    @media screen and (max-width: 768px) {
      .notification-active-job-div .notification-board-div {
        width: 100%;
        margin-right: 0px;
        padding: 0px;
        margin-top: 30px;
        height: 50vh; } }
    .notification-active-job-div .notification-board-div .notification-board-heading {
      margin-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      width: 100%;
      padding-bottom: 10px; }
      @media screen and (max-width: 768px) {
        .notification-active-job-div .notification-board-div .notification-board-heading {
          justify-content: left;
          padding-left: 0px;
          padding-bottom: 0px;
          padding-left: 15px; } }
      .notification-active-job-div .notification-board-div .notification-board-heading p {
        font-size: 18px !important;
        font-weight: 500;
        color: #000000; }
    .notification-active-job-div .notification-board-div p {
      font-size: 14px !important;
      color: gray; }
      @media screen and (max-width: 768px) {
        .notification-active-job-div .notification-board-div p {
          font-size: 20px; } }
    .notification-active-job-div .notification-board-div .list-for-notification-board {
      margin-top: 20px;
      padding: 0px 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 55vh;
      width: 100%;
      overflow-y: scroll; }
      .notification-active-job-div .notification-board-div .list-for-notification-board ol {
        height: 100%; }
      .notification-active-job-div .notification-board-div .list-for-notification-board p,
      .notification-active-job-div .notification-board-div .list-for-notification-board span {
        font-size: 12px !important;
        line-height: 15px;
        color: #9A9A9A;
        font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important; }
        @media screen and (max-width: 768px) {
          .notification-active-job-div .notification-board-div .list-for-notification-board p,
          .notification-active-job-div .notification-board-div .list-for-notification-board span {
            font-size: 14px; } }
      .notification-active-job-div .notification-board-div .list-for-notification-board span {
        color: #0A043B;
        font-style: italic; }
        .notification-active-job-div .notification-board-div .list-for-notification-board span:hover {
          cursor: pointer; }
      .notification-active-job-div .notification-board-div .list-for-notification-board .title-name-text {
        font-weight: 600;
        line-height: 12px;
        font-size: 13px !important;
        color: #000000;
        font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important; }
  .notification-active-job-div .active-job-post-dashboard-div {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 50%;
    height: 80vh;
    background-color: #ffffff;
    padding: 9px;
    margin-left: -17px; }
    .notification-active-job-div .active-job-post-dashboard-div .margin-top-100 {
      margin-top: 100px; }
    @media screen and (max-width: 768px) {
      .notification-active-job-div .active-job-post-dashboard-div {
        width: 100%;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin-left: 0px;
        padding: 8px; } }
    .notification-active-job-div .active-job-post-dashboard-div p {
      font-size: 25px; }
      @media screen and (max-width: 768px) {
        .notification-active-job-div .active-job-post-dashboard-div p {
          font-size: 20px; } }
    .notification-active-job-div .active-job-post-dashboard-div .active-job-post-heading {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: row;
      width: 100%;
      margin-left: -15px;
      padding-bottom: 20px; }
      @media screen and (max-width: 768px) {
        .notification-active-job-div .active-job-post-dashboard-div .active-job-post-heading {
          justify-content: left;
          padding-left: 15px; } }
      @media screen and (max-width: 375px) {
        .notification-active-job-div .active-job-post-dashboard-div .active-job-post-heading {
          margin-top: 0px;
          padding-bottom: 0px; } }
      .notification-active-job-div .active-job-post-dashboard-div .active-job-post-heading p {
        font-size: 18px !important;
        font-weight: 500; }
    .notification-active-job-div .active-job-post-dashboard-div .loading-center {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0px; }
    .notification-active-job-div .active-job-post-dashboard-div .job-row-component-div {
      height: 55vh;
      width: 100%;
      margin-top: 10px;
      overflow-y: scroll; }
      @media screen and (max-width: 768px) {
        .notification-active-job-div .active-job-post-dashboard-div .job-row-component-div {
          height: 40vh; } }
      @media screen and (max-width: 350px) {
        .notification-active-job-div .active-job-post-dashboard-div .job-row-component-div {
          height: 30vh; } }
      .notification-active-job-div .active-job-post-dashboard-div .job-row-component-div .padding-bottom-10 {
        padding-bottom: 10px; }
      @media screen and (max-width: 768px) {
        .notification-active-job-div .active-job-post-dashboard-div .job-row-component-div p {
          font-size: 12px; } }

.dashboar-row-outer-main-div {
  height: 40px;
  margin: 0;
  padding: 0;
  width: 100%;
  margin: 10px 10px 10px 10px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px; }

.dashboar-row-outer-div {
  width: 95%;
  height: 40px;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: row;
  margin: 10px 10px 10px 10px;
  border: 1px solid #9691ab;
  padding: 0;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px; }
  .dashboar-row-outer-div:focus {
    background-color: #0A043B; }
  .dashboar-row-outer-div .circle {
    width: 55px;
    height: 40px;
    float: left;
    background-color: #0A043B;
    border-radius: 50%;
    overflow: hidden;
    top: 0;
    position: relative;
    margin-right: 10px; }
  .dashboar-row-outer-div .dashboard-circle {
    width: 40px !important;
    height: 40px !important; }
  .dashboar-row-outer-div .job-title {
    font-size: 13px;
    text-transform: capitalize; }
    @media screen and (max-width: 768px) {
      .dashboar-row-outer-div .job-title {
        font-size: 11px !important; }
        .dashboar-row-outer-div .job-titlep {
          font-size: 11px !important; } }
  .dashboar-row-outer-div .job-create-date-label {
    font-size: 13px; }
    @media screen and (max-width: 768px) {
      .dashboar-row-outer-div .job-create-date-label {
        font-size: 11px; } }
  .dashboar-row-outer-div .main-content-div-if-notification {
    flex-basis: 50%;
    border-right: 1px solid #9691ab;
    height: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center; }
  .dashboar-row-outer-div .date-div-if-notification {
    flex-basis: 15%;
    border-right: 1px solid #9691ab;
    height: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100px; }
  .dashboar-row-outer-div .main-content-no-notification {
    flex-basis: 20rem;
    border-right: 1px solid #9691ab;
    height: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center; }
  .dashboar-row-outer-div .date-no-notification {
    flex-basis: 15%;
    border-right: 1px solid #9691ab;
    height: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: none;
    min-width: 90px; }
  .dashboar-row-outer-div .notification-div {
    flex-basis: 27%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; }
    .dashboar-row-outer-div .notification-div label {
      font-family: Rubik, Calibri, "Trebuchet MS", sans-serif;
      width: 100%;
      letter-spacing: 1px; }
  .dashboar-row-outer-div label {
    font-size: 18px;
    font-weight: 600;
    color: #000000; }

.dashboard-onclick-class {
  border: 2px solid #0A043B; }
  .dashboard-onclick-class label {
    color: #9A9A9A !important; }
  .dashboard-onclick-class .accept-reject-employer-label-div label {
    color: #080838 !important; }

.admin-dashboard-onclick-class {
  border: 2px solid #0A043B;
  background-color: #0A043B; }
  .admin-dashboard-onclick-class label {
    color: #ffffff !important; }

.dashboard-hover:hover {
  background-color: #0A043B !important; }
  .dashboard-hover:hover label {
    color: #FFFFFF !important; }

.new-dashboard-outer-div {
  display: flex;
  justify-content: center !important;
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding: 5px 5px 5px 15px;
  height: auto;
  background-color: #FFFFFF;
  margin-bottom: 10px;
  border-radius: 8px; }
  .new-dashboard-outer-div label,
  .new-dashboard-outer-div p,
  .new-dashboard-outer-div div,
  .new-dashboard-outer-div span {
    font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important; }
  .new-dashboard-outer-div:hover {
    border: 2px solid #0A043B;
    border-radius: 8px;
    height: auto; }
  .new-dashboard-outer-div .first-dashboard-item {
    display: flex;
    align-items: center;
    width: 100%;
    height: auto; }
  @media screen and (max-width: 768px) {
    .new-dashboard-outer-div {
      width: 98%; } }

.job-description-div {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 5px;
  padding-left: 15px; }
  .job-description-div p {
    font-weight: 600;
    line-height: 12px;
    font-size: 13px !important;
    color: #000000;
    font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important; }
  .job-description-div label {
    font-size: 12px;
    line-height: 15px;
    text-align: left;
    color: #9A9A9A;
    font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important; }

.dashboard-job-logo {
  border-radius: 50%;
  width: 35px;
  height: 35px; }
  @media screen and (max-width: 500px) {
    .dashboard-job-logo {
      min-height: 25px;
      min-width: 25px;
      max-height: 25px;
      max-width: 25px;
      height: 25px !important;
      width: 25px !important; } }

.double-job-component {
  margin-top: -80px;
  margin-left: 285px; }

.new-dashboard-outer-div-class {
  display: flex;
  width: 48%; }
  .new-dashboard-outer-div-class:hover {
    border: 2px solid #0A043B;
    border-radius: 8px; }
  .new-dashboard-outer-div-class .job-desciption {
    margin-top: -13px; }
  .new-dashboard-outer-div-class .second-dashboard-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px; }

@media screen and (min-width: 768px) {
  .filters-style {
    margin-left: 5px;
    margin-right: 5px; } }

.filter-dashboard {
  margin-left: 80%; }

.dashboard-width {
  width: 100%; }

.filter-label {
  text-align: left; }

.search-content-dashboard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  text-align: left;
  margin-bottom: 20px; }

.filter-div-width {
  margin-right: 5%;
  cursor: pointer; }
  @media screen and (max-width: 768px) {
    .filter-div-width {
      margin-right: 0px;
      width: 38% !important; } }

.search-div-width {
  width: 80%; }

.label-filter {
  font-size: 14px;
  cursor: pointer; }
  @media screen and (max-width: 768px) {
    .label-filter {
      margin-left: 10px !important; } }

.disable-btn {
  opacity: 0.70; }
  .disable-btn:hover {
    background-color: #0A043B;
    border: 1px solid #0A043B; }

.auto-suggest-component-outer-wrapper .react-autosuggest__suggestions-container {
  max-height: 150px;
  overflow-y: scroll;
  cursor: pointer; }

.display-flex-justify-aligned-center-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; }

.admin-candidate-list-loading {
  margin: 0;
  height: 50px; }

.ordered-list-faq-class {
  list-style: decimal !important; }

.latest-blog-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #FAFBFD;
  width: 100%;
  height: 400px;
  border-radius: 8px;
  overflow: hidden; }
  .latest-blog-div .latest-blog-image {
    height: 100%;
    width: 75%;
    flex: 1.5 1;
    border-radius: 8px; }
    .latest-blog-div .latest-blog-image img {
      width: auto;
      height: 100%;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px; }
    .latest-blog-div .latest-blog-image .blog-edit-icon {
      position: absolute;
      left: calc(100% - 70.5rem);
      margin-top: 10px;
      color: #0A043B;
      background-color: #FFFFFF;
      font-size: 20px;
      visibility: hidden;
      cursor: pointer;
      padding: 0px 10px; }
    .latest-blog-div .latest-blog-image:hover .blog-edit-icon {
      visibility: visible; }
  .latest-blog-div .latest-blog-info-div {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 15px;
    flex: 1 1;
    overflow: hidden;
    height: 100%; }
    .latest-blog-div .latest-blog-info-div p {
      display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 9 !important;
      -webkit-box-orient: vertical;
      overflow: hidden; }

.post-top-text {
  font-size: 13px;
  font-weight: 500;
  color: #0A043B;
  text-transform: uppercase;
  font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
  letter-spacing: 0.15rem;
  position: relative;
  margin-top: 20px; }
  .post-top-text::before {
    content: " ";
    height: 2px;
    width: 50%;
    background: #0A043B;
    position: absolute;
    margin: 0;
    left: 0;
    right: 0;
    top: 20px; }

.post-date-label {
  font-size: 12px;
  font-weight: 500;
  color: #0A043B;
  text-transform: uppercase;
  font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
  margin-top: 10px;
  margin-bottom: 10px;
  font-style: italic; }

h4 {
  font-weight: 500;
  font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
  cursor: pointer; }

.blog-list-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 50px;
  padding: 10px;
  border-radius: 8px; }

.blog-item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 1;
  width: 25%;
  max-width: 33%;
  flex-direction: column;
  margin: 10px;
  background-color: #FAFBFD;
  border-radius: 8px;
  position: relative;
  height: 600px; }
  .blog-item label, .blog-item p, .blog-item h4 {
    margin: 10px; }
  .blog-item img {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    align-self: center;
    max-height: 200px;
    width: 100%;
    min-height: 200px; }
  .blog-item .blog-edit-icon {
    position: absolute;
    left: 87%;
    top: 10px;
    background-color: #FFFFFF;
    color: #0A043B;
    font-size: 20px;
    visibility: hidden;
    cursor: pointer;
    padding: 0px 10px; }
  .blog-item:hover .blog-edit-icon {
    visibility: visible; }
  .blog-item p {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 9 !important;
    -webkit-box-orient: vertical;
    overflow: hidden; }

.blog-details-div {
  padding: 20px; }
  .blog-details-div h1 {
    text-transform: capitalize;
    font-weight: 500;
    font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
    margin-top: 20px;
    font-size: 44px; }
  .blog-details-div p {
    -webkit-line-clamp: unset;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
    text-align: justify;
    color: #999; }

.main-div {
  min-height: 50vh;
  height: 100%;
  width: 80vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto; }
  .main-div .item-outer-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 5px 10px;
    padding: 0px 20px 20px 20px;
    background-color: #fff;
    width: 90%;
    border: 1px solid #9691ab; }
    @media (max-width: 768px) {
      .main-div .item-outer-div {
        width: 100%;
        margin: 0 0px 10px; } }
    .main-div .item-outer-div .heading {
      clear: left;
      padding: 15px 20px;
      font-weight: 500;
      border-bottom: 1px solid #9691ab;
      font-size: 14px;
      color: #0A043B;
      width: 100%;
      font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
      margin-bottom: 20px; }
    .main-div .item-outer-div .inner-div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      width: 100%; }
    .main-div .item-outer-div .item-div {
      width: 20%;
      max-width: 20%;
      padding: 5px 15px;
      font-size: 12px; }
      @media (max-width: 768px) {
        .main-div .item-outer-div .item-div {
          width: 100%;
          max-width: 100%; } }
      .main-div .item-outer-div .item-div a {
        word-break: break-word;
        width: 100%;
        font-weight: 400;
        margin-right: 20px;
        text-transform: capitalize;
        color: #0A043B;
        font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important; }

.error-boundry-outer-view {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 90vh; }
  .error-boundry-outer-view .img-div {
    display: flex;
    justify-content: center;
    align-items: center; }
    .error-boundry-outer-view .img-div img {
      width: 350px;
      height: 350px; }
      @media (max-width: 768px) {
        .error-boundry-outer-view .img-div img {
          margin-right: 10px; } }
  .error-boundry-outer-view .header-div {
    display: flex;
    justify-content: center;
    align-items: center; }
    .error-boundry-outer-view .header-div h4 {
      font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
      color: #0A043B; }
  .error-boundry-outer-view .sub-header-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10px; }
    .error-boundry-outer-view .sub-header-div p {
      margin: 0;
      font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
      font-weight: 500;
      color: #66615b;
      font-size: 14px; }

.blog {
  font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important; }
  .blog .section-top-start {
    margin-top: 7rem !important; }
    @media (max-width: 640px) {
      .blog .section-top-start {
        margin-top: 0rem !important; } }
    @media (min-width: 640px) and (max-width: 768px) {
      .blog .section-top-start {
        margin-top: 7rem !important; } }
  .blog .p-4 {
    padding: 1rem !important; }
  .blog .p-3 {
    padding: .75rem !important; }
  .blog .p-12 {
    padding: 3rem !important; }
  .blog .p-5 {
    padding: 1.25rem !important; }
  .blog .p-6 {
    padding: 1.5rem !important; }
  .blog .p-8 {
    padding: 2rem !important; }
  .blog .py-3 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important; }
  .blog .py-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important; }
  .blog .px-3 {
    padding-left: .75rem !important;
    padding-right: .75rem !important; }
  .blog .py-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .blog .px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important; }
  .blog .px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important; }
  .blog .px-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important; }
  .blog .py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .blog .px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important; }
  .blog .px-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important; }
  .blog .py-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .blog .py-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .blog .py-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important; }
  .blog .px-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important; }
  .blog .py-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .blog .px-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important; }
  .blog .py-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .blog .py-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .blog .py-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important; }
  .blog .pt-4 {
    padding-top: 1rem !important; }
  .blog .pb-4 {
    padding-bottom: 1rem !important; }
  .blog .pl-5 {
    padding-left: 1.25rem !important; }
  .blog .pt-6 {
    padding-top: 1.5rem !important; }
  .blog .pt-20 {
    padding-top: 5rem !important; }
  .blog .pb-32 {
    padding-bottom: 8rem !important; }
  .blog .pl-3 {
    padding-left: .75rem !important; }
  .blog .pt-40 {
    padding-top: 10rem !important; }
  .blog .pt-24 {
    padding-top: 6rem !important; }
  .blog .pb-10 {
    padding-bottom: 2.5rem !important; }
  .blog .pb-2 {
    padding-bottom: .5rem !important; }
  .blog .pt-8 {
    padding-top: 2rem !important; }
  .blog .pb-16 {
    padding-bottom: 4rem !important; }
  .blog .pb-8 {
    padding-bottom: 2rem !important; }
  .blog .pb-20 {
    padding-bottom: 5rem !important; }
  .blog .pb-12 {
    padding-bottom: 3rem !important; }
  .blog .pl-4 {
    padding-left: 1rem !important; }
  .blog .pt-5 {
    padding-top: 1.25rem !important; }
  .blog .pl-6 {
    padding-left: 1.5rem !important; }
  .blog .pb-40 {
    padding-bottom: 10rem !important; }
  .blog .pt-3 {
    padding-top: .75rem !important; }
  .blog .pt-px {
    padding-top: 1px !important; }
  .blog .pt-12 {
    padding-top: 3rem !important; }
  .blog .pr-4 {
    padding-right: 1rem !important; }
  .blog .pt-16 {
    padding-top: 4rem !important; }
  .blog .pb-6 {
    padding-bottom: 1.5rem !important; }
  .blog .pt-10 {
    padding-top: 2.5rem !important; }
  .blog .pb-5 {
    padding-bottom: 1.25rem !important; }
  .blog .pr-10 {
    padding-right: 2.5rem !important; }
  .blog .pr-3 {
    padding-right: .75rem !important; }
  .blog .pb-3 {
    padding-bottom: .75rem !important; }
  .blog .pr-12 {
    padding-right: 3rem !important; }
  .blog .pb-24 {
    padding-bottom: 6rem !important; }
  .blog .my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .blog .mx-auto {
    margin-left: auto !important;
    margin-right: auto !important; }
  .blog .-mx-4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important; }
  .blog .my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .blog .-mx-2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important; }
  .blog .-mx-3 {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important; }
  .blog .-mx-5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important; }
  .blog .my-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important; }
  .blog .mx-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important; }
  .blog .my-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important; }
  .blog .my-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important; }
  .blog .-mx-8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important; }
  .blog .my-3 {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important; }
  .blog .my-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .blog .my-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .blog .mt-4 {
    margin-top: 1rem !important; }
  .blog .mb-8 {
    margin-bottom: 2rem !important; }
  .blog .mr-auto {
    margin-right: auto !important; }
  .blog .mb-1 {
    margin-bottom: .25rem !important; }
  .blog .mb-3 {
    margin-bottom: .75rem !important; }
  .blog .mb-2 {
    margin-bottom: .5rem !important; }
  .blog .mt-auto {
    margin-top: auto !important; }
  .blog .mb-4 {
    margin-bottom: 1rem !important; }
  .blog .mb-12 {
    margin-bottom: 3rem !important; }
  .blog .mb-6 {
    margin-bottom: 1.5rem !important; }
  .blog .ml-auto {
    margin-left: auto !important; }
  .blog .mt-12 {
    margin-top: 3rem !important; }
  .blog .mb-16 {
    margin-bottom: 4rem !important; }
  .blog .-mt-24 {
    margin-top: -6rem !important; }
  .blog .mt-3 {
    margin-top: .75rem !important; }
  .blog .ml-2 {
    margin-left: .5rem !important; }
  .blog .-mb-6 {
    margin-bottom: -1.5rem !important; }
  .blog .mt-2 {
    margin-top: .5rem !important; }
  .blog .mt-6 {
    margin-top: 1.5rem !important; }
  .blog .mr-4 {
    margin-right: 1rem !important; }
  .blog .mr-1 {
    margin-right: .25rem !important; }
  .blog .mb-5 {
    margin-bottom: 1.25rem !important; }
  .blog .ml-1 {
    margin-left: .25rem !important; }
  .blog .mr-3 {
    margin-right: .75rem !important; }
  .blog .mt-8 {
    margin-top: 2rem !important; }
  .blog .mr-2 {
    margin-right: .5rem !important; }
  .blog .mb-10 {
    margin-bottom: 2.5rem !important; }
  .blog .mr-8 {
    margin-right: 2rem !important; }
  .blog .ml-4 {
    margin-left: 1rem !important; }
  .blog .mb-20 {
    margin-bottom: 5rem !important; }
  .blog .mr-5 {
    margin-right: 1.25rem !important; }
  .blog .mt-32 {
    margin-top: 8rem !important; }
  .blog .mt-24 {
    margin-top: 6rem !important; }
  .blog .mt-16 {
    margin-top: 4rem !important; }
  .blog .mt-5 {
    margin-top: 1.25rem !important; }
  .blog .-ml-12 {
    margin-left: -3rem !important; }
  .blog .-mt-12 {
    margin-top: -3rem !important; }
  .blog .-mr-12 {
    margin-right: -3rem !important; }
  .blog .-mb-12 {
    margin-bottom: -3rem !important; }
  .blog .mb-24 {
    margin-bottom: 6rem !important; }
  .blog .-mr-6 {
    margin-right: -1.5rem !important; }
  .blog .font-heading {
    font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important; }
  .blog .text-center {
    text-align: center !important; }
  .blog .text-3xl {
    font-size: 1.875rem !important; }
    .blog .text-3xl span {
      font-size: 1.875rem !important; }
  .blog .text-sm {
    font-size: .875rem !important; }
  .blog .text-xs {
    font-size: .75rem !important; }
  .blog .text-4xl {
    font-size: 2.25rem !important;
    line-height: 3.2rem !important; }
  .blog .text-lg {
    font-size: 1.125rem !important; }
  .blog .text-2xl {
    font-size: 1.5rem !important; }
  .blog .text-xl {
    font-size: 1.25rem !important; }
  .blog .text-base {
    font-size: 1rem !important; }
  .blog .font-semibold {
    font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
    font-weight: 600 !important; }
  .blog .font-bold {
    font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
    font-weight: 600 !important; }
  .blog .text-5xl {
    font-size: 3rem !important;
    line-height: 4rem !important; }
  .blog .flex {
    display: flex !important; }
  .blog .flex-wrap {
    flex-wrap: wrap !important; }
  .blog .justify-center {
    justify-content: center !important; }
  .blog .-mx-3 {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important; }
  .blog .w-full {
    width: 100% !important; }
  .blog .width-half {
    width: 50% !important; }
  .blog .width-two-third {
    width: 66.666667% !important; }
    @media (max-width: 640px) {
      .blog .width-two-third {
        width: 100% !important; } }
  .blog .width-one-third {
    width: 33.333333% !important; }
    @media (max-width: 640px) {
      .blog .width-one-third {
        width: 100% !important; } }
  .blog .width-one-fourth {
    width: 25% !important; }
  .blog .width-two-fifth {
    width: 40% !important; }
  .blog .width-three-fifth {
    width: 60% !important; }
  .blog .width-one-fifth {
    width: 20% !important; }
  .blog .w-12 {
    width: 3rem !important; }
  .blog .w-8 {
    width: 2rem !important; }
  .blog .w-24 {
    width: 6rem !important; }
  .blog .w-auto {
    width: auto !important; }
  .blog .w-16 {
    width: 4rem !important; }
  .blog .w-5 {
    width: 1.25rem !important; }
  .blog .w-40 {
    width: 10rem !important; }
  .blog .w-64 {
    width: 16rem !important; }
  .blog .max-w-2xl {
    max-width: 42rem !important; }
  .blog .max-w-xl {
    max-width: 36rem !important; }
  .blog .px-3 {
    padding-left: .75rem !important;
    padding-right: .75rem !important; }
  .blog .mb-12 {
    margin-bottom: 3rem !important; }
  .blog .hover-up-5,
  .blog .hover-up-5:hover {
    transition: .3s; }
  .blog .hover-up-5:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px); }
  .blog .h-10 {
    height: 2.5rem !important; }
  .blog .h-4 {
    height: 1rem !important; }
  .blog .h-6 {
    height: 1.5rem !important; }
  .blog .h-12 {
    height: 3rem !important; }
  .blog .h-8 {
    height: 2rem !important; }
  .blog .h-48 {
    height: 12rem !important; }
  .blog .h-36 {
    height: 9rem !important; }
  .blog .h-24 {
    height: 6rem !important; }
  .blog .h-5 {
    height: 1.25rem !important; }
  .blog .h-80 {
    height: 20rem !important; }
  .blog .h-16 {
    height: 4rem !important; }
  .blog .h-32 {
    height: 8rem !important; }
  .blog .h-full {
    height: 100% !important; }
  .blog .h-20 {
    height: 5rem !important; }
  .blog .h-64 {
    height: 16rem !important; }
  .blog .h-128 {
    height: 32rem !important; }
  .blog .object-cover {
    object-fit: cover !important; }
  .blog .rounded {
    border-radius: .25rem !important; }
  .blog .rounded-xl {
    border-radius: .75rem !important; }
  .blog .rounded-full {
    border-radius: 9999px !important; }
  .blog .rounded-lg {
    border-radius: .5rem !important; }
  .blog .rounded-l {
    border-top-left-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important; }
  .blog .rounded-r {
    border-top-right-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important; }
  .blog .text-blue-200 {
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important; }
  .blog .text-blue-300 {
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important; }
  .blog .text-blue-400 {
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important; }
  .blog .text-blue-600 {
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important; }
  .blog .text-blueGray-200 {
    --tw-text-opacity: 1 !important;
    color: rgba(226, 232, 240, var(--tw-text-opacity)) !important; }
  .blog .text-blueGray-100 {
    --tw-text-opacity: 1 !important;
    color: rgba(241, 245, 249, var(--tw-text-opacity)) !important; }
  .blog .text-gray-800 {
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important; }
  .blog .inline-block {
    display: inline-block !important; }
  .blog .bg-blue-100,
  .blog .bg-blue-200 {
    --tw-bg-opacity: 1 !important; }
  .blog .bg-blue-100 {
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important; }
  .blog .bg-blueGray-500 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(100, 116, 139, var(--tw-bg-opacity)) !important; }
  .blog .bg-blue-500 {
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important; }
  .blog .bg-blue-50, .blog .bg-blue-500 {
    --tw-bg-opacity:1 !important; }
  .blog .bg-blue-50 {
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important; }
  .blog .bg-white {
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important; }
  .blog .bg-blueGray-800, .blog .bg-white {
    --tw-bg-opacity:1 !important; }
  .blog .focus-bg-white:focus {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important; }
  .blog .bg-gray-100 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important; }
  .blog .border {
    border-width: 1px !important; }
  .blog .border-r {
    border-right-width: 1px !important; }
  .blog .border-t {
    border-top-width: 1px !important; }
  .blog .border-l-4 {
    border-left-width: 4px !important; }
  .blog .border-b {
    border-bottom-width: 1px !important; }
  .blog .border-blue-200 {
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important; }
  .blog .border-blueGray-100 {
    --tw-border-opacity: 1 !important;
    border-color: rgba(241, 245, 249, var(--tw-border-opacity)) !important; }
  .blog .border-gray-100 {
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important; }
  .blog .border-blueGray-200 {
    --tw-border-opacity: 1 !important;
    border-color: rgba(226, 232, 240, var(--tw-border-opacity)) !important; }
  .blog .border-blue-300 {
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important; }
  .blog .border-blue-300, .blog .border-white {
    --tw-border-opacity:1 !important; }
  .blog .border-white {
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important; }
  .blog .border-gray-200 {
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important; }
  .blog .border-blue-500 {
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important; }
  .blog .hover-border-gray-200:hover {
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important; }
  .blog .text-white {
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important; }
  .blog .text-blue-800,
  .blog .text-white {
    --tw-text-opacity: 1 !important; }
  .blog .bg-cover {
    background-size: cover !important; }
  @media (min-width: 768px) {
    .blog .author-banner-div {
      margin-top: -20px; } }
  .blog .bg-center {
    background-position: 50% !important; }
  .blog .bg-top {
    background-position: top !important; }
  .blog .bg-no-repeat {
    background-repeat: no-repeat !important; }
  .blog .text-blueGray-400 {
    --tw-text-opacity: 1 !important;
    color: rgba(148, 163, 184, var(--tw-text-opacity)) !important; }
  .blog .text-blue-500:hover {
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important; }
  .blog .leading-none {
    line-height: 1 !important; }
  .blog .leading-loose {
    line-height: 2 !important; }
  .blog .leading-normal {
    line-height: 1.5 !important; }
  .blog .hover-underline:hover {
    text-decoration: underline !important; }
  .blog .shadow {
    --tw-shadow: 0px 2px 4px rgba(148, 163, 184, 0.05), 0px 6px 24px rgba(235, 238, 251, 0.4) !important;
    box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important; }
  .blog .antialiased {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important; }
  .blog .max-w-screen-sm {
    max-width: 640px !important; }
  .blog .space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(.75rem * var(--tw-space-y-reverse)) !important; }
  .blog .space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1rem * var(--tw-space-y-reverse)) !important; }
  .blog .-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-.5rem * calc(1 - var(--tw-space-x-reverse))) !important; }
  .blog .space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(3rem * var(--tw-space-y-reverse)) !important; }
  .blog .space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse))) !important; }
  .blog .space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(.5rem * var(--tw-space-y-reverse)) !important; }
  .blog .flex-1 {
    flex: 1 1 !important; }
  .blog .flex-shrink-0 {
    flex-shrink: 0 !important; }
  .blog .leading-relaxed {
    line-height: 1.625 !important; }
  .blog .items-center {
    align-items: center !important; }
  .blog .resize-none {
    resize: none !important; }
  .blog .outline-none {
    outline: none !important; }
    .blog .outline-none input {
      border: none !important;
      outline: none !important;
      border-color: transparent !important;
      border-width: 0px !important;
      border-radius: 0px !important;
      border-style: none !important; }
  .blog .focus-outline-none:focus {
    outline: 2px solid transparent !important;
    outline-offset: 2px !important; }
  .blog .placeholder-white::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1 !important;
    color: rgba(24, 24, 24, var(--tw-placeholder-opacity)) !important; }
  .blog .placeholder-white::placeholder {
    --tw-placeholder-opacity: 1 !important;
    color: rgba(24, 24, 24, var(--tw-placeholder-opacity)) !important; }
  .blog .input-div {
    height: 4rem;
    margin-bottom: 20px; }
  .blog .subscribe-button {
    width: 9rem !important;
    margin-top: auto;
    margin-bottom: auto; }
  .blog strong {
    font-weight: 600 !important; }
  .blog .blog-item-div {
    position: relative; }
  .blog .blog-edit-icon {
    position: absolute;
    right: 1.5rem;
    margin-top: 10px;
    color: #0A043B;
    background-color: #FFFFFF;
    font-size: 20px;
    visibility: visible;
    cursor: pointer;
    padding: 0px 10px; }
  .blog .blog-details-banner {
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.3); }
    @media (min-width: 1275px) and (max-width: 1300px) {
      .blog .blog-details-banner {
        height: 300px !important; } }
  @media (min-width: 640px) {
    .blog .sm-w-8 {
      width: 2rem !important; }
    .blog .sm-w-10 {
      width: 2.5rem !important; }
    .blog .sm-h-8 {
      height: 2rem !important; }
    .blog .sm-h-10 {
      height: 2.5rem !important; }
    .blog .sm-px-6 {
      padding-left: 1.5rem !important;
      padding-right: 1.5rem !important; }
    .blog .sm-py-4 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important; } }
  @media (min-width: 768px) {
    .blog .md-w-full {
      width: 100% !important; }
    .blog .md-width-two-third {
      width: 66.666667% !important; }
    .blog .md-text-lg {
      font-size: 1.125rem !important; }
    .blog .md-mb-0 {
      margin-bottom: 0 !important; }
    .blog .md-mr-6 {
      margin-right: 1.5rem !important; } }
  @media (min-width: 1024px) {
    .blog .lg-text-3xl {
      font-size: 1.875rem !important; } }
  .blog .flex-direction-coloum {
    flex-direction: column;
    align-items: flex-start !important; }
  .blog .reply-icon-div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    color: #0A043B; }
  .blog .default-comment-author {
    font-size: 30px !important; }
  .blog .default-comment-reply-author {
    font-size: 21px !important; }
  .blog .default-reply-author {
    font-size: 18px !important;
    margin-top: -4px !important; }
  .blog .title-blog-link:hover {
    color: #0A043B !important; }
  @media (max-width: 768px) {
    .blog .tag-item {
      font-size: 8px !important; } }
  @media (max-width: 768px) {
    .blog .blog-title-responsive {
      font-size: 12px !important;
      line-height: 14px !important; } }
  @media (min-width: 1275px) and (max-width: 1300px) {
    .blog .blog-title-responsive {
      font-size: 2.5rem !important;
      line-height: 3rem !important; } }
  @media (max-width: 768px) {
    .blog .blog-details-banner {
      padding-top: 10px !important;
      padding-bottom: 0px !important;
      margin-bottom: 10px !important;
      margin-top: 90px !important; } }
  @media (min-width: 1275px) and (max-width: 1300px) {
    .blog .blog-margin-10 {
      margin-top: -15px; } }

.hero-banner-image-outer-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  margin-top: 100px;
  height: auto;
  padding-bottom: 10px; }
  @media (max-width: 768px) {
    .hero-banner-image-outer-wrapper {
      margin-top: 0px;
      padding-top: 0px;
      height: 400px;
      background-image: url(/img/figma/tjp-web-banner.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: right 18% top; } }
  @media (max-width: 768px) {
    .hero-banner-image-outer-wrapper .outer-banner-mobile-view-height-reset {
      height: 350px; } }

@media (max-width: 768px) {
  .outer-banner-mobile-view-height-set {
    height: 540px !important; } }

@media (max-width: 768px) {
  .login-signup-form-margin-mobile {
    margin-top: 100px; } }

@media (max-width: 768px) {
  .jumbotron-main-creative {
    margin-top: 0px; } }

@media (max-width: 768px) {
  .jumbotron-main-creative .header-heading-div {
    margin-left: 0px;
    margin-top: 0px; } }

.hero-image-class {
  height: auto;
  width: 100%; }
  @media (max-width: 768px) {
    .hero-image-class {
      display: none; } }

.hero-banner-outer-div {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 999;
  width: 100%;
  height: auto; }
  @media (max-width: 768px) {
    .hero-banner-outer-div {
      position: static; } }

.login-validation-height {
  height: 650px !important; }
  @media (max-width: 768px) {
    .login-validation-height {
      height: 650px !important; } }

.login-validation-height-reset {
  height: 540px !important; }

.signup-validation-height {
  height: 550px; }

.user-notpresent-height {
  height: 600px !important; }

@media (min-width: 768px) {
  .user-notpresent-validation-height {
    height: 670px !important; } }

.option-contact-div input:is([type="radio"], [type="checkbox"]):after {
  width: 19px;
  height: 19px;
  border-radius: 50%;
  position: relative;
  background-color: #d1d3d1;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
  top: 15%;
  margin-left: -2px; }

.option-contact-div input:is([type="radio"], [type="checkbox"]):checked:after {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
  background-color: #26ae61;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
  top: 15%;
  margin-left: -2px; }

.option-contact-div .contact-radio-label {
  font-size: 16px;
  color: #000000;
  font-weight: 700;
  margin-top: -8px;
  position: absolute;
  margin-left: 15px; }

.contact-option-other-div {
  margin-left: 4.5%; }
  @media (max-width: 768px) {
    .contact-option-other-div {
      margin-left: 0; } }

.p-5 {
  padding: 1.25rem; }

.justify-center {
  justify-content: center; }

.items-center {
  align-items: center; }

@media (min-width: 1024px) {
  .lg-max-w-3xl {
    max-width: 48rem; } }

@media (min-width: 1024px) {
  .lg-p-12 {
    padding: 3rem; } }

.bg-color {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 251, 253, var(--tw-bg-opacity)); }

.min-h-screen {
  min-height: 70vh; }

.flex {
  display: flex; }

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow); }

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity)); }

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity)); }

.p-8 {
  padding: 2rem; }

.rounded-3xl {
  border-radius: 1.5rem; }

.max-w-xl {
  max-width: 36rem; }

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem; }

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem; }

.mt-4 {
  margin-top: 1rem; }

.font-awesome-icon-class {
  font-size: 50px;
  color: #008000; }

.margin-top-100 {
  margin-top: 100px; }
  @media (max-width: 768px) {
    .margin-top-100 {
      margin-top: 0px; } }

.email-edm-checkbox-outer-div {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  width: 40%; }
  .email-edm-checkbox-outer-div[type="checkbox"] {
    display: visible; }
    .email-edm-checkbox-outer-div[type="checkbox"]:checked + label:before {
      background: #222;
      display: visible; }

.email-edm-checkbox {
  min-width: 20px !important;
  min-height: 20px !important; }

.save-email-button-outer-div {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  flex-direction: row; }

.test-disable-btn {
  opacity: 0.70 !important; }

.email-edm-item-icon {
  display: flex;
  justify-content: space-evenly;
  align-items: center; }

.email-edm-showmore-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px; }

.email-subject-td {
  text-align: left !important; }

.admin-email-edm-list-table {
  width: 100%;
  padding: 30px; }

.email-edm-csv-btn-div {
  display: flex;
  justify-content: center;
  align-items: center; }

.sign-in-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out; }

.sign-in-overlay.visible {
  opacity: 1;
  pointer-events: auto; }

.sign-in-popup {
  position: relative;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  padding: 20px;
  text-align: center; }
  @media (max-width: 768px) {
    .sign-in-popup {
      width: 90%; } }

.sign-in-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #150F3D;
  font-weight: 600; }

.sign-in-close:hover {
  color: #279f8d; }

.sign-in-popup h2 {
  margin-top: 0;
  margin-top: 50px;
  margin-bottom: 20px;
  width: 30vw;
  color: #150F3D;
  font-family: "Rubik";
  font-weight: 500;
  font-size: 24px;
  line-height: 150%; }
  @media (max-width: 768px) {
    .sign-in-popup h2 {
      width: 90%; } }

.popup-sign-in-btn {
  color: #FFFFFF !important;
  margin-bottom: 20px; }

