.latest-blog-div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: $bg-color;
    width: 100%;
    height: 400px;
    border-radius: 8px;
    overflow: hidden;

    & .latest-blog-image {
        height: 100%;
        width: 75%;
        flex: 1.5 1;
        border-radius: 8px;

        & img {
            width: auto;
            height: 100%;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }
        & .blog-edit-icon {
            position: absolute;
            left: calc(100% - 70.5rem);
            margin-top: 10px;
            color: $brand-color;
            background-color: #FFFFFF;
            font-size: 20px;
            visibility:hidden;
            cursor: pointer;
            padding: 0px 10px;
        }
    
        &:hover .blog-edit-icon {
            visibility:visible;
        }
    }


    & .latest-blog-info-div {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        padding: 15px;
        flex: 1;
        overflow: hidden;
        height: 100%;

        & p {
            display: -webkit-box;
            max-width: 100%;
            -webkit-line-clamp: 9 !important;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
}

.post-top-text {
    font-size: 13px;
    font-weight: 500;
    color: $brand-color;
    text-transform: uppercase;
    font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
    letter-spacing: 0.15rem;
    position: relative;
    margin-top: 20px;

    &::before {
        content: " ";
        height: 2px;
        width: 50%;
        background: $brand-color;
        position: absolute;
        margin: 0;
        left: 0;
        right: 0;
        top: 20px
    }
}

.post-date-label {
    font-size: 12px;
    font-weight: 500;
    color: $brand-color;
    text-transform: uppercase;
    font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
    margin-top: 10px;
    margin-bottom: 10px;
    font-style: italic;
}

h4 {
    font-weight: 500;
    font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
    cursor: pointer;
}

.blog-list-div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 50px;
    padding: 10px;
    border-radius: 8px;
}

.blog-item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-grow: 1;
    width: 25%;
    max-width: 33%;
    flex-direction: column;
    margin: 10px;
    background-color: $bg-color;
    border-radius: 8px;
    position: relative;
    height: 600px;

    & label, p, h4 {
        margin: 10px;
    }

    & img {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        align-self: center;
        max-height: 200px;
        width: 100%;
        min-height: 200px;
    }

    & .blog-edit-icon {
        position: absolute;
        left: 87%;
        top: 10px;
       background-color: #FFFFFF;
        color: $brand-color;
        font-size: 20px;
        visibility:hidden;
        cursor: pointer;
        padding: 0px 10px;
    }

    &:hover .blog-edit-icon {
        visibility:visible;
    }

    & p {
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 9 !important;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

.blog-details-div {
    padding: 20px;

    & h1 {
        text-transform: capitalize;
        font-weight: 500;
        font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
        margin-top: 20px;
        font-size: 44px;
    }

    & p {
        // text-indent: 50px;
        -webkit-line-clamp: unset;
        font-size: 16px;
        font-weight: 400;
        font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
        text-align: justify;
        color: #999;
    }
}