.email-edm-checkbox-outer-div{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    width: 40%;

    &[type="checkbox"] {
        display: visible;
    
        &:checked + label:before {
            background: #222;
            display: visible;
        }
    }
}

.email-edm-checkbox{
    min-width: 20px !important;
    min-height: 20px !important;
}

.save-email-button-outer-div{
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
    flex-direction: row;
}

.test-disable-btn{
    opacity: 0.70 !important;
}

.email-edm-item-icon{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.email-edm-showmore-div{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top:20px;
}

.email-subject-td{
    text-align: left !important;
}

.admin-email-edm-list-table{
    width: 100%;
    padding:30px;
}

.email-edm-csv-btn-div{
    display: flex;
    justify-content: center;
    align-items: center;
}