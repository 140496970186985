.option-contact-div {
    & input:is([type="radio"], [type="checkbox"]):after {
        width: 19px;
        height: 19px;
        border-radius: 50%;
        // top: -2px;
        // left: -1px;
        position: relative;
        background-color: #d1d3d1;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
        top: 15%;
        margin-left: -2px;
    } 
    
    & input:is([type="radio"], [type="checkbox"]):checked:after {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        // top: -2px;
        // left: -1px;
        position: relative;
        background-color: #26ae61;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
        top: 15%;
        margin-left: -2px;
    }

    & .contact-radio-label {
        font-size: 16px;
        color: #000000;
        font-weight: 700;
        margin-top: -8px;
        position: absolute;
        margin-left: 15px;
    }
}

.contact-option-other-div{
    margin-left: 4.5%;

    @media (max-width: 768px){
        margin-left: 0;
    }
}