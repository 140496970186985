.home-row-container {
    padding: 20px;
}

.dashboar-outer-section {
    background-color: #FAFBFD;
}

.search-div {
    width: 119%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 4rem;
    background-color: #FAFBFD;

    @media screen and (max-width: 768px) {
        margin-left: 20px;
        width: 109%;
    }

    & input::placeholder {
        color: #000000 !important;
        text-align: left;
        margin-left: 10px;
        // padding-left: 10px;
        text-transform: uppercase !important;
        font-style: normal;
    }

    & input:focus::placeholder {
        opacity: 0;
    }

    & .search-input {
        width: 80%;
        text-align: left;
        color: #000000;
        font-size: 14px;
        border: none;
        padding-left: 10px;
    }
}

.dashboard {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #FAFBFD;

    @media screen and (max-width: 768px) {
        flex-wrap: wrap-reverse;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 0px;
        flex-direction: column;
        margin: 0px;
    }

    & .left-panel {
        height: 100%;
        width: 30vw;
        text-align: center;
        margin: 10px;
        padding: 15px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;

        @media screen and (max-width: 768px) {
            width: 100%;
            margin: 0px;
        }

        & .active-job-div {
            width: 100%;
            padding: 10px;
            text-align: left;
            margin-top: 20px;
            // height: 300px;
            // overflow-y: scroll;
            cursor: pointer;

            @media screen and (max-width: 768px) {
                margin-top: 0px;
                // height: 330px;
                height: auto;
            }

            & .active-job-label {
                color: #000000;
                font-size: 14px;
                font-weight: 450;
                margin-bottom: 15px;
                font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
                text-transform: uppercase;
            }
        }

        & .complete-job-list {
            width: 100%;
            padding: 10px;
            text-align: left;
            margin-top: 20px;
            // height: 300px;
            // overflow-y: scroll;
            cursor: pointer;

            @media screen and (max-width: 768px) {
                margin-top: 0px;
                // height: 330px;
                height: auto;
            }

            & .complete-job-label {
                color: #000000;
                font-weight: 450;
                margin-bottom: 15px;
                font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
                text-transform: uppercase;
            }
        }

        & .infinte-scroll-div {
            height: 300px;
            overflow-y: auto;
            padding-bottom: 20px;

            @media screen and (max-width: 500px) {
                height: 40vh;
                overflow-y: scroll;
                padding-bottom: 5px;
            }
            @media screen and (max-width: 350px) {
                height: 30vh;
                overflow-y: scroll;
                padding-bottom: 5px;
            }
        }
    }

    & .right-panel {
        height: 100%;
        width: 70vw;
        text-align: center;
        margin: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;

        @media screen and (max-width: 768px) {
            width: 98%;
            height: 100%;
            margin: 0px;
        }

        & .visit-job-post-div {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            // width: 100%;
            background-color: #FFFFFF !important;
            margin: 15px;
            padding: 0px 10px;
            align-self: flex-end;

            & label {
                font-size: 13px;
                color: $brand-color;
                font-style: italic;
                font-weight: 500;
                font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
                margin-right: 20px;
            }

            & i {
                font-size: 10px;
                color: $brand-color;
                font-weight: 400;
            }
        }

        & .summary-application-div {
            background-color: #FFFFFF;
            height: auto;
            width: 100%;
            padding: 20px;
            border-radius: 8px;

            @media screen and (max-width: 768px) {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;
                overflow-x: scroll;
                flex-wrap: wrap;
                height: 100%;
                padding: 10px;
            }

            & .summary-top-div {
                @include summary-div();

                @media screen and (max-width: 768px) {
                    padding: 0px;
                    flex: 100% 1;
                    justify-content: flex-start;
                    align-items: flex-start;
                }

                & .summary-left-div {
                    @include summary-div();

                    @media screen and (max-width: 768px) {
                        justify-content: space-between;
                        margin-right: 10px;
                        flex-wrap: nowrap;
                        width: 50%;
                        padding: 0px 0px 10px 0px;
                    }

                    & label {
                        @include summary-label();

                        @media screen and (max-width: 768px) {
                            text-align: left;
                        }
                    }

                    // & .bordered-div {
                    //     @include summary-inner-div();
                    // }
                }

                & .summary-right-div {
                    @include summary-div();

                    @media screen and (max-width: 768px) {
                        justify-content: space-between;
                        margin-right: 10px;
                        flex-wrap: nowrap;
                        padding: 0px 0px 10px 0px;
                        width: 50%;
                    }

                    & label {
                        @include summary-label();

                        @media screen and (max-width: 768px) {
                            text-align: left;
                        }
                    }

                    // & .bordered-div {
                    //     @include summary-inner-div();
                    // }
                }
            }

            & .summary-middle-div {
                @include summary-div();

                @media screen and (max-width: 768px) {
                    padding: 0px;
                    flex: 100% 1;
                    justify-content: flex-start;
                    align-items: flex-start;
                }

                & .summary-left-div {
                    @include summary-div();

                    @media screen and (max-width: 768px) {
                        justify-content: space-between;
                        margin-right: 10px;
                        flex-wrap: nowrap;
                        width: 50%;
                        padding: 0px 0px 10px 0px;
                    }

                    & label {
                        @include summary-label();

                        @media screen and (max-width: 768px) {
                            text-align: left;
                        }
                    }

                    // & .bordered-div {
                    //     @include summary-inner-div();
                    // }
                }

                & .summary-right-div {
                    @include summary-div();

                    @media screen and (max-width: 768px) {
                        justify-content: space-between;
                        margin-right: 10px;
                        flex-wrap: nowrap;
                        padding: 0px 0px 10px 0px;
                        width: 50%;
                    }

                    & label {
                        @include summary-label();

                        @media screen and (max-width: 768px) {
                            text-align: left;
                        }
                    }

                    // & .bordered-div {
                    //     @include summary-inner-div();
                    // }
                }
            }

            & .summary-bottom-div {
                @include summary-div();

                @media screen and (max-width: 768px) {
                    padding: 0px;
                    flex: 100% 1;
                    justify-content: flex-start;
                    align-items: flex-start;
                }

                & .summary-left-div {
                    @include summary-div();

                    @media screen and (max-width: 768px) {
                        justify-content: space-between;
                        margin-right: 10px;
                        flex-wrap: nowrap;
                        padding: 0px 0px 10px 0px;
                        width: 50%;
                    }

                    & label {
                        @include summary-label();

                        @media screen and (max-width: 768px) {
                            text-align: left;
                        }
                    }

                    & .action-label {
                        cursor: pointer;
                        color: $brand-color
                    }

                    // & .bordered-div {
                    //     @include summary-inner-div();
                    // }
                }

                & .active-inactive-div {
                    flex: 0% 1 !important;
                    width: 15% !important;

                    @media screen and (max-width: 768px) {
                        justify-content: space-between;
                        margin-right: 10px;
                        flex-wrap: nowrap;
                        width: 100% !important;
                        padding: 0px 0px 10px 0px;
                        flex: 100% 1 !important;
                    }

                    & .active-inactive-label {
                        font-weight: 400;
                        line-height: 12px;
                        font-size: 15px !important;
                        color: #000000;
                        font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
                        cursor: pointer;

                        @media screen and (max-width: 768px) {
                            text-align: left;
                        }
                    }
                }

                & .summary-right-div {
                    @include summary-div();

                    @media screen and (max-width: 768px) {
                        justify-content: space-between;
                        margin-right: 10px;
                        flex-wrap: nowrap;
                        padding: 0px 0px 10px 0px;
                        width: 50%;
                    }
                    & label {
                        @include summary-label();
                        @media screen and (max-width: 768px) {
                            text-align: left;
                        }
                    }

                    // & .bordered-div {
                    //     @include summary-inner-div();
                    // }

                    & .visit-job-arraow-div {
                        background-color: transparent;
                        width: 100%;
                        padding: 5px 10px;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        font-size: 14px;
                        color: #000000;
                        font-weight: 600;
                        cursor: pointer;

                        & label {
                            margin-right: 10px;
                            cursor: pointer;

                            &:hover {
                                color: $brand-color;
                            }
                        }

                        & img {
                            width: 25px;
                            height: 25px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        & .detail-application-div {
            width: 100%;
            padding: 20px 0px;
            text-transform: capitalize;

            & .no-record-found-div {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                font-size: 16px;
                color: #000000;
            }

            & .table-loading-div {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
            }

            & .candidate-list-div {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;
                width: 100%;
                height: 500px;
                margin-top: 10px;
                padding-top: 10px;
                overflow-y: auto;

                & .candidate-list-inner-div {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-direction: column;
                    width: 100%;
                    min-height: 250px;
                }
                
                & .candidate-row {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-direction: row;
                    width: 100%;
                    height: auto;
                    padding: 5px 10px;
                    background-color: #FFFFFF;
                    margin-right: 10px;
                    border-radius: 4px;
                    margin-bottom: 10px;
                    flex-wrap: wrap;

                    @media screen and (max-width: 768px) {
                        height: 100%;
                    }

                    & .candidate-name-div {
                        flex-basis: 40%;
                        text-align: left;
                        cursor: pointer;
                        height: 100%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        @media screen and (max-width: 768px) {
                            flex-basis: 85%;
                            max-height: 35px;
                        }

                        @media screen and (min-width: 1325px) {
                            flex-basis: 45%;
                        }

                        & label {
                            font-weight: 600;
                            line-height: 12px;
                            font-size: 13px !important;
                            color: #000000;
                            font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
                            font-style: italic;
                            cursor: pointer;
                        }
                    }

                    & .candidate-percent-div {
                        justify-content: center;
                        align-items: center;
                        width: 40px;
                        height: 40px;
                    }

                    & .provider-action-div {
                        width: 30%;
                        justify-content: flex-end;
                        align-items: center;
                        padding: 0px 20px;

                        @media screen and (max-width: 768px) {
                            flex-basis: 45%;
                            stify-content: flex-start;
                            padding: 0px 0px;
                            margin-right: 10px;
                        }

                        & .status-select-div {
                            width: 70%;
                            align-self: flex-end;
                            float: right;

                            @media screen and (max-width: 768px) {
                                width: 100%;
                                float: left;
                                text-align: left;
                            }

                            & label {
                                font-weight: 600;
                                line-height: 12px;
                                font-size: 13px !important;
                                color: #000000;
                                font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
                                font-style: italic;
                            }
                        }
                    }

                    & .assesment-div {
                        flex-basis: 20%;
                        text-align: left;

                        @media screen and (max-width: 768px) {
                            float: right;
                            justify-self: end;
                        }
                        & .assesment-button {
                            padding: 5px 10px;
                            font-size: 10px;
                        }

                        & label{
                            font-weight: 600;
                            line-height: 12px;
                            font-size: 13px !important;
                            color: #000000;
                            font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
                            font-style: italic;
                        }
                    }
                }
            }
        }
    }
}

.employer-home-page-outer-div {
    width: 100%;
    height: 100vh;
    // padding: 10px;
    // margin: 10px;
    height: 10vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #FAFBFD;

    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        height: 100%;
    }
}

.margin-top0 {
    margin-top: 0px !important;
    ;
}

.dashboard-row-margin {
    margin: 28px 10px 10px 0px !important;
    height: 40px !important;
    width: 100% !important;
}

.title-dashboard-arrow-div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-right: 10px;

    @media screen and (max-width: 768px) {
        margin-top: 0px;
        margin-right: 0px;
        flex-wrap: wrap;
        height: 100%;
    }

    & .employer-homepage-title-div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 60px;

        @media screen and (max-width: 768px) {
            width: 100%;
            height: 100%;
            justify-content: flex-start;
        }

        & .employer-homepage-title {
            font-size: 30px;
            color: #000;
            font-weight: 450;

            @media screen and (max-width: 768px) {
                font-size: 20px;
            }
        }
    }

    & .dashboard-arrow-div {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 50%;
        background-color: #FAFBFD;
        height: 60px;
        padding: 5px 15px 5px 5px;
        flex-direction: row;

        & .view-dashboard{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            background-color: #FFFFFF !important;
            padding: 1px 5px;
        }

        @media screen and (max-width: 768px) {
            width: 100%;
            height: 100%;
            flex-direction: row;
            justify-content: flex-end;
            margin: 0px 5px;
            margin-bottom: 10px;
        }

        & i {
            font-size: 10px;
            color: $brand-color;
            font-weight: 400;
        }
        
        & .visit-dashboard-text {
            font-size: 15px;
            color: #000;
            padding-right: 10px;
            font-weight: 400 !important;
            font-style: italic;

            &:hover {
                color: $brand-color;
                cursor: pointer;
            }
        }

        & .bold-image {
            width: 15px;
            height: 20px;

            @media screen and (max-width: 768px) {
                width: 25px;
                height: 25px;
            }
        }
    }
}

.notification-active-job-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    color: #000;

    @media screen and (max-width: 768px) {
        width: 100%;
        flex-wrap: wrap-reverse;
        height: 100%;
    }

    & .notification-board-div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        width: 50%;
        height: 80vh;
        background-color: #ffffff;
        padding: 20px;
        // margin-right:30px;

        @media screen and (max-width: 768px) {
            width: 100%;
            margin-right: 0px;
            padding: 0px;
            margin-top: 30px;
            height: 50vh;
        }

        & .notification-board-heading {
            margin-top: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            width: 100%;
            padding-bottom:10px;

            @media screen and (max-width: 768px){
                justify-content: left;
                padding-left: 0px;
                padding-bottom: 0px;
                padding-left: 15px;
            }

            & p {
                font-size: 18px !important;
                font-weight: 500;
                color: #000000;
            }
        }

        & p {
            font-size: 14px !important;
            color: gray;

            @media screen and (max-width: 768px) {
                font-size: 20px;
            }
        }

        & .list-for-notification-board {
            margin-top: 20px;
            padding: 0px 20px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 55vh;
            width: 100%;
            overflow-y: scroll;

            & ol {
                height: 100%;
            }

            & p,
            span {
                font-size: 12px !important;
                line-height: 15px;
                color: $secondary-font-color;
                font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;

                @media screen and (max-width: 768px) {
                    font-size: 14px;
                }
            }

            & span {
                color: $brand-color;
                font-style: italic;

                &:hover {
                    cursor: pointer;
                }
            }

            & .title-name-text {
                font-weight: 600;
                line-height: 12px;
                font-size: 13px !important;
                color: #000000;
                font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
            }
        }
    }

    & .active-job-post-dashboard-div {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        width: 50%;
        height: 80vh;
        background-color: #ffffff;
        padding: 9px;
        margin-left: -17px;

        & .margin-top-100{
            margin-top:100px;
        }

        @media screen and (max-width: 768px) {
            width: 100%;
            justify-content: center;
            align-items: center;
            height: 100%;
            margin-left: 0px;
            padding: 8px;
        }

        & p {
            font-size: 25px;

            @media screen and (max-width: 768px) {
                font-size: 20px;
            }
        }

        & .active-job-post-heading {
            margin-top: 20px;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: row;
            width: 100%;
            margin-left: -15px;
            padding-bottom: 20px;

            @media screen and (max-width: 768px){
                justify-content: left;
                padding-left: 15px; 
            }

            @media screen and (max-width: 375px){
                margin-top: 0px;
                padding-bottom: 0px;
            }

            & p {
                font-size: 18px !important;
                font-weight: 500;
            }

        }

        & .loading-center {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0px;
        }

        & .job-row-component-div {
            height: 55vh;
            width: 100%;
            margin-top: 10px;
            overflow-y: scroll;

            @media screen and (max-width:768px){
                height: 40vh;
            }
            @media screen and (max-width:350px) {
                height: 30vh;
            }

            & .padding-bottom-10 {
                padding-bottom: 10px;
            }

            @media screen and (max-width:768px) {
                & p {
                    font-size: 12px;
                }

            }
        }
    }
}

.dashboar-row-outer-main-div {
    height: 40px;
    margin: 0;
    padding: 0;
    width: 100%;
    margin: 10px 10px 10px 10px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

.dashboar-row-outer-div {
    width: 95%;
    height: 40px;
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: row;
    margin: 10px 10px 10px 10px;
    border: 1px solid $brand-border-color;
    padding: 0;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;

    &:focus {
        background-color: $brand-color;
    }

    & .circle {
        width: 55px;
        height: 40px;
        float: left;
        background-color: $brand-color;
        border-radius: 50%;
        overflow: hidden;
        top: 0;
        position: relative;
        margin-right: 10px;
    }

    & .dashboard-circle {
        width: 40px !important;
        height: 40px !important;
    }

    & .job-title {
        font-size: 13px;
        text-transform: capitalize;

        @media screen and (max-width: 768px) {
            font-size: 11px !important;

            &p {
                font-size: 11px !important;
            }
        }
    }

    & .job-create-date-label {
        font-size: 13px;

        @media screen and (max-width: 768px) {
            font-size: 11px;
        }
    }

    & .main-content-div-if-notification {
        @include row-div(50%);
    }

    & .date-div-if-notification {
        @include row-div(15%);
        max-width: 100px;
    }

    & .main-content-no-notification {
        @include row-div(20rem);
    }

    & .date-no-notification {
        @include row-div(15%);
        border-right: none;
        min-width: 90px;
    }

    & .notification-div {
        flex-basis: 27%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        & label {
            font-family: Rubik, Calibri, "Trebuchet MS", sans-serif;
            width: 100%;
            letter-spacing: 1px;
        }
    }

    label {
        font-size: 18px;
        font-weight: 600;
        color: #000000;
    }
}

.dashboard-onclick-class {
    border: 2px solid $brand-color;
    & label {
        color: $secondary-font-color !important;
    }
    & .accept-reject-employer-label-div{
        & label {
            color : #080838 !important;
        }
    }
}

.admin-dashboard-onclick-class {
    border: 2px solid $brand-color;
    background-color: $brand-color;
    & label {
        color: #ffffff !important;
    }
}

.dashboard-hover {
    &:hover {
        background-color: $brand-color  !important;

        & label {
            color: #FFFFFF !important;
        }
    }
}

.new-dashboard-outer-div {
    display: flex;
    justify-content: center !important;
    align-items: center;
    flex-direction: row;
    width: 100%;
    padding: 5px 5px 5px 15px;
    height: auto;
    background-color: #FFFFFF;
    margin-bottom: 10px;
    border-radius: 8px;

    & label,
    p,
    div,
    span {
        font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
    }

    &:hover {
        border: 2px solid $brand-color;
        border-radius: 8px;
        height: auto;
    }

    & .first-dashboard-item {
        display: flex;
        // justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: auto;

        &.img-logo-class {
            // display: flex;
            // justify-content: flex-start;
            // align-items: center;
        }

    }

    @media screen and (max-width: 768px) {
        width: 98%;
    }
}

.job-description-div {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 5px;
    padding-left: 15px;

    & p {
        font-weight: 600;
        line-height: 12px;
        font-size: 13px !important;
        color: #000000;
        font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
    }

    & label {
        font-size: 12px;
        line-height: 15px;
        text-align: left;
        color: $secondary-font-color;
        font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
    }
}

.dashboard-job-logo {
    border-radius: 50%;
    width: 35px;
    height: 35px;

    @media screen and (max-width:500px) {
        min-height: 25px;
        min-width: 25px;
        max-height: 25px;
        max-width: 25px;
        height: 25px !important;
        width: 25px !important;
    }
}

.double-job-component {
    margin-top: -80px;
    margin-left: 285px;
}

.new-dashboard-outer-div-class {
    display: flex;
    // justify-content: flex-end;
    // align-items: center;
    // flex-direction: row;
    width: 48%;
    // margin-top:-80px;
    // margin-left: 285px;

    &:hover {
        border: 2px solid $brand-color;
        border-radius: 8px;
    }

    & .job-desciption {
        margin-top: -13px;
    }

    & .second-dashboard-item {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80px;
    }
}

.filters-style {
    @media screen and (min-width: 768px) {
        margin-left: 5px;
        margin-right: 5px;
    }
}

.filter-dashboard {
    margin-left: 80%;
}

.dashboard-width {
    width: 100%;
}

.filter-label {
    text-align: left;
}

.search-content-dashboard {
	display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    text-align: left;
    margin-bottom: 20px;
}

.filter-div-width {
    margin-right: 5%;
    cursor: pointer;
    @media screen and (max-width: 768px) {
        margin-right: 0px;
        width: 38% !important;
    }
}

.search-div-width {
    width: 80%;
}

.label-filter {
    font-size: 14px;
    cursor: pointer;
    @media screen and (max-width: 768px) {
        margin-left: 10px !important;
    }
}

.disable-btn{
    opacity: 0.70;
    &:hover{
        background-color: $brand-color;
        border: 1px solid $brand-color;
    }
}

.auto-suggest-component-outer-wrapper{

    & .react-autosuggest__suggestions-container{
        max-height: 150px;
        overflow-y: scroll;
        cursor: pointer;
    }
}

.display-flex-justify-aligned-center-div{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.admin-candidate-list-loading{
    margin: 0;
    height: 50px;
}

.ordered-list-faq-class{
    list-style: decimal !important;
}