.p-5 {
    padding: 1.25rem;
}

.justify-center {
    justify-content: center;
}

.items-center{
	align-items: center;
}

@media (min-width: 1024px){
	.lg-max-w-3xl {
		max-width: 48rem;
	}
}

@media (min-width: 1024px){
	.lg-p-12 {
		padding: 3rem;
	}
}

.bg-color {
    --tw-bg-opacity: 1;
    background-color: rgba(250,251,253,var(--tw-bg-opacity));
}

.min-h-screen {
    min-height: 70vh;
}

.flex{
	display: flex;
}

.shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
}

.text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31,41,55,var(--tw-text-opacity));
}

.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255,255,255,var(--tw-bg-opacity));
}

.p-8 {
    padding: 2rem;
}

.rounded-3xl {
    border-radius: 1.5rem;
}

.max-w-xl {
    max-width: 36rem;
}

.text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
}

.text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
}

.mt-4 {
    margin-top: 1rem;
}

.font-awesome-icon-class{
	font-size: 50px;
	color:  #008000;
}

.margin-top-100{
	margin-top:100px;

	@media (max-width: 768px){
		margin-top: 0px;
	}
}