.sign-in-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(128, 128, 128, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
}

.sign-in-overlay.visible {
    opacity: 1;
    pointer-events: auto;
}

.sign-in-popup {
    position: relative;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    padding: 20px;
    text-align: center;

    @media(max-width:768px){
        width:90%;
    }
}

.sign-in-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #150F3D;
    font-weight: 600;
    
}

.sign-in-close:hover {
    color: rgb(39, 159, 141);
}

.sign-in-popup h2 {
    margin-top: 0;
    margin-top: 50px;
    margin-bottom: 20px;
    width: 30vw;
    color: #150F3D;
    font-family: "Rubik";
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;

    @media(max-width: 768px){
        width: 90%;
    }
}

.popup-sign-in-btn {
    color: #FFFFFF !important;
    margin-bottom: 20px;
}