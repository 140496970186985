.hero-banner-image-outer-wrapper{
	display: flex;
	justify-content: center;
	width: 100%;
	position: relative;
	margin-top:100px;
	height: auto;
	padding-bottom: 10px;

    @media(max-width: 768px){
        margin-top: 0px;
        padding-top: 0px;
        height: 400px;
        background-image: url(/img/figma/tjp-web-banner.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right 18% top;
    }

    & .outer-banner-mobile-view-height-reset {
        @media(max-width: 768px){
            height: 350px;
        }
    }
}

.outer-banner-mobile-view-height-set {
    @media(max-width: 768px){
        height: 540px !important;
    }
}

.login-signup-form-margin-mobile {
    @media(max-width: 768px){
        margin-top: 100px;
    }
}

.jumbotron-main-creative {
    @media(max-width: 768px){
        margin-top: 0px;
    }

    & .header-heading-div {
        @media(max-width: 768px){
            margin-left: 0px;
            margin-top: 0px;
        }
    }
}

.hero-image-class{
	height: auto;
	width: 100%;

    @media(max-width: 768px){
        display: none;
    }
}

.hero-banner-outer-div{
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	z-index: 999;
	width:100%;
	height: auto;
    @media(max-width: 768px){
        position: static;
    }
}

.login-validation-height {
    height: 650px !important;

    @media(max-width: 768px){
        height: 650px !important;
    }
}

.login-validation-height-reset {
    height: 540px !important;
}

.signup-validation-height{
    height:550px;
}

.user-notpresent-height{
    height: 600px !important;
}

.user-notpresent-validation-height{

    @media (min-width: 768px){
        height: 670px !important;
    }
}