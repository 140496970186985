@mixin row-div($width) {
    flex-basis: $width;
    border-right: 1px solid $brand-border-color;
    height: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin summary-div() {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    padding: 0px 20px;
    flex-wrap: wrap;
    flex: 50%;
}

@mixin summary-label() {
    font-size: 13px;
    color: $secondary-font-color;
    font-weight: 500;
    font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
    line-height: 22px;
}