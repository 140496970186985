@charset "UTF-8";
@import 'https://cdnjs.cloudflare.com/ajax/libs/cookieconsent2/3.1.0/cookieconsent.min.css';
@import "~react-datepicker/dist/react-datepicker.css";
@import "~react-toastify/dist/ReactToastify.css";


/*
* Trunk version 2.0.1
*/
* {
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
}

a:focus,
button:focus {
	outline: none !important;
}

button::-moz-focus-inner {
	border: 0;
}

*:focus {
	outline: none;
}

blockquote {
	padding: 0;
	margin: 0;
}

input,
button,
select,
textarea {
	outline: none;
	border-color: rgb(150, 145, 171);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
}

label {
	margin-bottom: 0;
}

p {
	margin: 0;
}

ul,
ol {
	list-style: none;
	padding: 0;
	margin: 0;
}

ul li,
ol li {
	display: block;
}

dl {
	margin: 0;
}

dt,
dd {
	line-height: inherit;
}

dt {
	font-weight: inherit;
}

dd {
	margin-bottom: 0;
}

cite {
	font-style: normal;
}

form {
	margin-bottom: 0;
}

blockquote {
	padding-left: 0;
	border-left: 0;
}

address {
	margin-top: 0;
	margin-bottom: 0;
}

figure {
	margin-bottom: 0;
}

html p a:hover {
	text-decoration: none;
}


/*
*
* Typography
*/

body {
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 14px;
	line-height: 1.71429;
	font-weight: 400;
	color: #9a9a9a;
	background-color: #ffffff;
	-webkit-text-size-adjust: none;
	-webkit-font-smoothing: subpixel-antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6,
[class*='heading-'] {
	margin-top: 0;
	margin-bottom: 0;
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-weight: 300;
	color: #151515;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
[class*='heading-'] a {
	color: inherit;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
[class*='heading-'] a:hover {
	color: #0A043B;
}

h1 [style*='max-width'],
h2 [style*='max-width'],
h3 [style*='max-width'],
h4 [style*='max-width'],
h5 [style*='max-width'],
h6 [style*='max-width'],
[class*='heading-'] [style*='max-width'] {
	display: inline-block;
}

h1,
.heading-1 {
	font-size: 32px;
	line-height: 1.5;
	font-weight: 300;
}

@media (min-width: 768px) {
	h1,
	.heading-1 {
		font-size: 38px;
	}
}

@media (min-width: 1200px) {
	h1,
	.heading-1 {
		font-size: 70px;
		line-height: 1.08571;
	}
}

h2,
.heading-2 {
	font-size: 28px;
	line-height: 1.4;
}

@media (min-width: 768px) {
	h2,
	.heading-2 {
		font-size: 35px;
	}
}

@media (min-width: 1200px) {
	h2,
	.heading-2 {
		font-size: 48px;
		line-height: 1.20833;
	}
	h2.h2-smaller,
	.heading-2.h2-smaller {
		font-size: 40px;
	}
}

h3,
.heading-3 {
	font-size: 24px;
	line-height: 1.5;
	letter-spacing: .025em;
}

@media (min-width: 1200px) {
	h3,
	.heading-3 {
		font-size: 36px;
		line-height: 1.33333;
	}
}

h4,
.heading-4 {
	font-size: 18px;
	line-height: 1.45;
	font-weight: 400;
}

@media (min-width: 768px) {
	h4,
	.heading-4 {
		font-size: 20px;
	}
}

@media (min-width: 1200px) {
	h4,
	.heading-4 {
		font-size: 24px;
		line-height: 1.41667;
	}
}

h5,
.heading-5 {
	font-size: 16px;
	line-height: 1.5;
	font-weight: 400;
}

@media (min-width: 768px) {
	h5,
	.heading-5 {
		font-size: 18px;
		line-height: 1.38889;
	}
}

h6,
.heading-6 {
	font-size: 14px;
	line-height: 1.7;
	font-weight: 400;
	letter-spacing: .025em;
}

label {
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif !important;
}

@media (min-width: 768px) {
	h6,
	.heading-6 {
		font-size: 16px;
		line-height: 1.5;
	}
}

.heading-7 {
	line-height: 1.5;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: .025em;
}

.heading-8 {
	/* font: 500 14px/24px "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", rubiklight, "Helvetica Neue", Arial, sans-serif; */
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif;
	line-height: 1.5;
	text-transform: uppercase;
	letter-spacing: .05em;
	font-weight: 400;
}

.heading-9 {
	font-size: 13px;
	line-height: 1.2;
	letter-spacing: .1em;
	font-weight: 500;
	text-transform: uppercase;
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif;
}

.heading-decorated_1::before {
	content: '';
	display: inline-block;
	vertical-align: middle;
	margin-right: 16px;
	width: 24px;
	height: 3px;
	background: #0A043B;
}

.text-style-1 {
	letter-spacing: .025em;
	/* color: #151515; */
}

small,
.small {
	display: block;
	font-size: 12px;
	line-height: 1.5;
}

mark,
.mark {
	padding: 3px 5px;
	color: #ffffff;
	background: #0A043B;
}

strong {
	color: #151515;
}

.context-dark strong,
.bg-gray-700 strong,
.bg-blue-13 strong,
.bg-blue-15 strong,
.bg-primary strong {
	color: #ffffff;
}

.big {
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 18px;
	line-height: 1.44444;
}

.big a {
	color: inherit;
}

.big a:hover {
	color: #0A043B;
}

.big a[href*='callto'] {
	white-space: nowrap;
}

.lead {
	font-size: 24px;
	line-height: 34px;
	font-weight: 300;
}

code {
	padding: 3px 10px;
	border-radius: 0.2rem;
	font-size: 90%;
	color: #151515;
	background: #f3f4f9;
}

p [data-toggle='tooltip'] {
	padding-left: .25em;
	padding-right: .25em;
	color: #0A043B;
}

p [style*='max-width'] {
	display: inline-block;
}


/*
*
* Brand
*/

.brand {
	display: inline-block;
}

.brand .brand-logo-light {
	display: none;
}

.brand .brand-logo-dark {
	display: block;
}


/*
*
* Links
*/

a {
	color: inherit;
	letter-spacing: .025em;
	transition: all 0.3s ease-in-out;
}

a,
a:focus,
a:active,
a:hover {
	text-decoration: none;
}

a[href*='tel'],
a[href*='mailto'] {
	white-space: nowrap;
}

a:hover {
	color: #0A043B;
}

p a {
	/* color: #0A043B; */
}

p a:active,
p a:focus {
	color: #0A043B;
}

p a:hover {
	color: #0A043B;
}

p .link-hover {
	color: #0A043B;
}

p .link-press {
	color: #0A043B;
}

.privacy-link {
	display: inline-block;
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 14px;
	line-height: 1.7;
}

*+.privacy-link {
	margin-top: 30px;
}

@media (min-width: 768px) {
	.privacy-link {
		font-size: 16px;
		line-height: 1.5;
	}
	*+.privacy-link {
		margin-top: 45px;
	}
}

.link-corporate {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 27px;
	border: 1px solid #d6d6d6;
	transition: .2s ease-in-out;
}

.link-corporate img {
	max-width: 90%;
}

.link-corporate::before,
.link-corporate::after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	border: 1px solid #0A043B;
	transition: .25s ease-out;
	contain: paint;
}

.link-corporate::before {
	transform: scale3d(0, 1, 1);
	border-width: 1px 0 1px 0;
}

.link-corporate::after {
	transform: scale3d(1, 0, 1);
	border-width: 0 1px 0 1px;
}

.link-corporate:hover {
	border-color: rgba(214, 214, 214, 0);
}

.link-corporate:hover::before,
.link-corporate:hover::after {
	transform: scale3d(1, 1, 1);
}

.context-dark .link-corporate,
.bg-gray-700 .link-corporate,
.bg-blue-13 .link-corporate,
.bg-blue-15 .link-corporate,
.bg-primary .link-corporate {
	border-color: rgba(255, 255, 255, 0.4);
}

.context-dark .link-corporate:hover,
.bg-gray-700 .link-corporate:hover,
.bg-blue-13 .link-corporate:hover,
.bg-blue-15 .link-corporate:hover,
.bg-primary .link-corporate:hover {
	border-color: transparent;
}

.context-dark .link-corporate:hover img,
.bg-gray-700 .link-corporate:hover img,
.bg-blue-13 .link-corporate:hover img,
.bg-blue-15 .link-corporate:hover img,
.bg-primary .link-corporate:hover img {
	filter: saturate(0.45378) hue-rotate(137.22222deg);
}

.bg-primary .link-corporate:hover {
	border-color: #ffffff;
}

@media (min-width: 576px) {
	.owl-item .link-corporate {
		margin-left: 2px;
		margin-right: 2px;
	}
}

@media (min-width: 1200px) {
	.link-corporate {
		min-height: 145px;
	}
}


/*
*
* Blocks
*/

.block-1 {
	max-width: 512px;
	margin-left: auto;
	margin-right: auto;
}

.block-2 {
	max-width: 640px;
	margin-left: auto;
	margin-right: auto;
}

@media (min-width: 768px) {
	.block-3 {
		max-width: 400px;
	}
}

.block-4 {
	display: inline-block;
	max-width: 400px;
}

@media (min-width: 992px) {
	.block-4 {
		max-width: 800px;
	}
}

@media (min-width: 992px) {
	.block-5 {
		max-width: 540px;
	}
}

.block-6 {
	max-width: 800px;
	margin-left: auto;
	margin-right: auto;
	padding-bottom: 20px;
}


/*
*
* Boxes
*/

.box-line {
	max-width: 250px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	cursor: default;
}

@media (max-width: 575.98px) {
	.owl-carousel-stretch .owl-item>.box-line {
		flex-shrink: 0;
		max-width: 400px;
	}
}

.box-line_corporate {
	max-width: 100%;
	padding: 30px 20px 40px;
	border-radius: 3px;
	background: #0A043B;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
}

.box-line_corporate .box-line-inner {
	max-width: 280px;
	margin-left: auto;
	margin-right: auto;
}

.box-line_corporate .box-line-title,
.box-line_corporate .icon.box-line-icon {
	color: #ffffff;
}

.box-line_corporate .box-line-divider {
	border-bottom-color: rgba(255, 255, 255, 0.22);
}

.box-line_corporate .box-line-title {
	padding-left: 20px;
	padding-right: 20px;
}

@media (min-width: 768px) {
	.box-line_corporate {
		padding: 65px 30px 80px;
	}
}

.box-line_creative {
	max-width: 100%;
	padding: 25px 17px 35px;
	background: #f3f4f9;
}

.box-line_creative .box-line-inner {
	max-width: 320px;
	margin-left: auto;
	margin-right: auto;
}

.box-line_creative .box-line-divider {
	border-bottom-color: rgba(21, 21, 21, 0.22);
}

.box-line_creative .box-line-title {
	padding-left: 20px;
	padding-right: 20px;
}

.box-line_creative:hover .box-line-icon {
	color: #0A043B;
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.box-line_creative .box-line-title {
		padding-left: 10px;
		padding-right: 10px;
	}
}

@media (min-width: 1200px) {
	.box-line_creative {
		padding: 40px 30px 45px;
	}
}

.icon.box-line-icon {
	font-size: 48px;
	line-height: 1;
	color: #0A043B;
	transition: .22s;
}

.box-line-title {
	color: #151515;
	transition: .22s;
}

.box-line-divider {
	position: relative;
	transition: .22s;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	border-bottom: 1px solid #d6d6d6;
}

*+.box-line-divider {
	margin-top: 20px;
}

*+.box-line-title {
	margin-top: 10px;
}

.box-line-icon+.box-line-title {
	margin-top: 8px;
}

@media (min-width: 768px) {
	.icon.box-line-icon {
		font-size: 74px;
	}
	.box-line_sm .icon.box-line-icon {
		font-size: 70px;
	}
	*+.box-line-title {
		margin-top: 18px;
	}
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	.box-line_sm .box-line-title {
		font-size: 15px;
	}
}

@media (min-width: 1200px) {
	.icon.box-line-icon {
		font-size: 88px;
	}
}

.box-info {
	position: relative;
	color: #151515;
	letter-spacing: .025em;
}

@media (min-width: 992px) {
	.box-info {
		margin-left: 25px;
	}
}

@media (min-width: 1200px) {
	.box-info {
		padding: 35px 50px 50px 40px;
	}
	.box-info *+p {
		margin-top: 20px;
	}
}

.box-info-2 {
	position: relative;
	padding: 20px 20px 40px;
	color: #151515;
	background: #ffffff;
	letter-spacing: .025em;
}

@media (min-width: 768px) {
	.box-info-2 {
		padding: 35px 30px 40px;
	}
}

@media (min-width: 992px) {
	.box-info-2 {
		margin-left: 24px;
		border-left-width: 0;
	}
	.box-info-2::before {
		content: '';
		position: absolute;
		top: 0;
		left: -23px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 24px 40px 0;
		border-color: transparent #ffffff transparent transparent;
	}
}

@media (min-width: 1200px) {
	.box-info-2 {
		padding: 35px 50px 50px 40px;
	}
}

.box-info-3 {
	width: 100%;
	position: relative;
	padding: 20px 30px 25px 34px;
	background: #ffffff;
	letter-spacing: .025em;
	text-align: center;
}

.box-info-3-title-divider {
	display: block;
	font-size: 0;
	line-height: 0;
}

.box-info-3-title-divider::before {
	content: '';
	display: inline-block;
	width: 43px;
	height: 2px;
	background: #0A043B;
}

*+.box-info-3-title-divider {
	margin-top: 8px;
}

@media (max-width: 767.98px) {
	.box-info-3 {
		max-width: 320px;
	}
	.height-fill .box-info-3 {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}

@media (min-width: 576px) {
	.box-info-3 {
		text-align: left;
	}
	.box-info-3-title {
		position: relative;
		text-indent: 55px;
	}
	.box-info-3-title-divider {
		position: absolute;
		top: -.22em;
		left: 0;
		display: inline-block;
		margin: 0;
		vertical-align: middle;
		font-size: inherit;
		line-height: inherit;
		text-indent: 0;
	}
}

@media (max-width: 1199.98px) {
	.box-info-3 {
		margin-bottom: 16px;
	}
	.box-info-3::before {
		content: '';
		position: absolute;
		left: 50%;
		margin-left: -9px;
		bottom: -18px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 18px 18px 0 18px;
		border-color: #ffffff transparent transparent transparent;
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	.box-info-3 {
		margin-bottom: 0;
	}
	.box-info-3::before {
		display: none;
	}
}

@media (min-width: 768px) {
	.box-info-3::before {
		left: 10%;
		margin-left: 0;
	}
}

@media (min-width: 1200px) {
	.box-info-3 {
		margin-right: 17px;
		margin-bottom: 0;
	}
	.box-info-3::before {
		content: '';
		position: absolute;
		top: 0;
		left: auto;
		right: -17px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 0 26px 17px;
		border-color: transparent transparent transparent #ffffff;
	}
}

.box-creative {
	position: relative;
	display: flex;
	padding: 25px 15px;
	text-align: center;
	border: 1px solid #d6d6d6;
	transition: .2s ease-in-out;
	transform: translate3d(0, 0, 0);
}

.box-new-candidate {
	border: 2px solid #160E4D;
	box-sizing: border-box;
	border-radius: 10px;
	color: #0B132A;
	position: relative;
	display: flex;
	padding: 25px 15px;
	height: 300px;
	width: 300px;
	text-align: center;
}
.new-candidate-img{
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}
.new-candidate-sm-pic{
	margin-left: 0px;
}
.new-candidate-name-div{
    margin-left: 20px;
    line-height: 8px;
	text-transform: capitalize;
}

.testimonial-img{
	/* min-width: 50px; */
	min-height: 55px;
	/* max-width: 50px; */
	max-height: 55px;
	border-radius: 50%;
	width: 55px;
	/* height: auto; */
}

@media (max-width: 768px){
	.testimonial-img{
		min-height: 45px;
		max-height: 45px;
		height: 45px !important;
		width: 45px !important;
	}
	.new-candidate-name-div{
		margin-left:10px;
	}
}

.new-candidate-img-name{
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 30px;
	display: flex;
	align-items: center;
	color: #0B132A;
}

@media (max-width: 450px){
	.new-candidate-img-name{
		line-height: 18px !important;
		margin-top: 7px;
	}
}

.new-candidate-place{
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 30px;
	display: flex;
	align-items: center;
	color: #4F5665;
	margin-top: -15px;
}
.box-new-candidate:nth-child(odd){
	margin-left: 50px;
	margin-right: 50px;
}
.box-new-candidate-inner {
	display: flex;
	flex-direction: column;
	/* align-items: center; */
	/* justify-content: center; */
}
.text-align-left{
	text-align:left!important;
}

.new-candidate-company-title {
	font-size: 20px;
	font-weight: 500;
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
}

.new-candidate-join-desc {
	text-align:justify;
	margin-top:15px;
	font-size: 16px;
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	color: #0B132A;
}

@media (max-width: 500px){
	.new-candidate-join-desc{
		line-height: 16px !important;
	}
}
@media (max-width: 350px){
	.new-candidate-join-desc{
		font-size: 11px !important;
	}
}

@media (min-width:1200px) and (max-width: 1300px){
	.new-candidate-join-desc{
		font-size: 14px !important;
	}
}

@media (min-width: 320px) and (max-width: 1023px){
	.box-new-candidate:nth-child(odd){
		margin-left: 0px;
		margin-right: 0px;
	}
}

@media (min-width: 1040px) and (max-width: 1220px){
	.box-new-candidate:nth-child(odd){
		margin-left: 0px;
		margin-right: 0px;
	}
}

.box-creative,
.box-creative:active,
.box-creative:focus,
.box-creative:hover {
	color: #9a9a9a;
}

.box-creative p+p {
	margin-top: 3px;
}

.box-creative::before,
.box-creative::after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	border: 1px solid #0A043B;
	transition: .25s ease-out;
	will-change: transform;
	contain: paint;
}

.box-creative::before {
	transform: scale3d(0, 1, 1);
	border-width: 1px 0 1px 0;
}

.box-creative::after {
	transform: scale3d(1, 0, 1);
	border-width: 0 1px 0 1px;
}

.box-creative:hover {
	border-color: rgba(214, 214, 214, 0);
	background: rgba(0, 0, 0, 0.01);
}

.box-creative:hover::before,
.box-creative:hover::after {
	transform: scale3d(0.9999, 0.9999, 0.9999);
}

.box-creative-inner,
.box-new-candidate-inner,
.box-creative-dummy {
	width: 100%;
	flex-shrink: 0;
}

.box-creative-inner {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.box-creative-dummy {
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
}

.box-creative-dummy::before {
	content: '';
	display: block;
	padding-top: 89.65517%;
}

.box-creative-icon {
	font-size: 59px;
	line-height: 1;
	color: #0A043B;
}

.box-creative-title {
	font-size: 14px;
	line-height: 1.4;
	color: #151515;
}

*+.box-creative-title {
	margin-top: 6px;
}

@media (max-width: 575.98px) {
	.box-creative {
		max-width: 280px;
		margin-left: auto;
		margin-right: auto;
	}
}

@media (min-width: 576px) {
	.owl-item .box-creative {
		margin-left: 2px;
		margin-right: 2px;
	}
}

@media (min-width: 992px) {
	.box-creative-title {
		font-size: 16px;
	}
}


/*
* Element groups
*/

html .group {
	margin-bottom: -20px;
	margin-left: -15px;
}

html .group:empty {
	margin-bottom: 0;
	margin-left: 0;
}

html .group>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 10px;
	margin-left: 15px;
}

html .group-sm {
	margin-bottom: -15px;
	margin-left: -20px;
}

html .group-sm:empty {
	margin-bottom: 0;
	margin-left: 0;
}

html .group-sm>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 15px;
	margin-left: 20px;
}

html .group-xl {
	margin-bottom: -20px;
	margin-left: -30px;
}

html .group-xl:empty {
	margin-bottom: 0;
	margin-left: 0;
}

html .group-xl>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 20px;
	margin-left: 30px;
}

@media (min-width: 992px) {
	html .group-xl {
		margin-bottom: -20px;
		margin-left: -45px;
	}
	html .group-xl>* {
		margin-bottom: 20px;
		margin-left: 45px;
	}
}

html .group-justify {
	margin-bottom: -15px;
	margin-left: -13px;
}

html .group-justify:empty {
	margin-bottom: 0;
	margin-left: 0;
}

html .group-justify>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 15px;
	margin-left: 13px;
}

html .group-justify .button {
	padding-left: 20px;
	padding-right: 20px;
}

@media (min-width: 768px) {
	html .group-justify {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	html .group-justify>* {
		flex-grow: 1;
		flex-shrink: 0;
	}
	html .group-justify .button {
		min-width: 130px;
		padding-left: 20px;
		padding-right: 20px;
	}
}

html .group-middle {
	display: inline-table;
	vertical-align: middle;
}

html .group-middle>* {
	vertical-align: middle;
}

*+.group-justify {
	margin-top: 30px;
}

*+.group-sm {
	margin-top: 30px;
}

*+.group-xl {
	margin-top: 20px;
}

@media (min-width: 992px) {
	*+.group-justify {
		margin-top: 40px;
	}
}


/*
*
* Responsive units
*/

.unit {
	display: flex;
	flex: 0 1 100%;
	margin-bottom: -30px;
	margin-left: -20px;
}

.unit>* {
	margin-bottom: 30px;
	margin-left: 20px;
}

.unit:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.unit-body {
	flex: 0 1 auto;
}

.unit-left,
.unit-right {
	flex: 0 0 auto;
	max-width: 100%;
}

.unit-spacing-xxs {
	margin-bottom: -10px;
	margin-left: -10px;
}

.unit-spacing-xxs>* {
	margin-bottom: 10px;
	margin-left: 10px;
}

.unit-spacing-xs {
	margin-bottom: -15px;
	margin-left: -7px;
}

.unit-spacing-xs>* {
	margin-bottom: 15px;
	margin-left: 7px;
}

.unit-spacing-sm {
	margin-bottom: -15px;
	margin-left: -15px;
}

.unit-spacing-sm>* {
	margin-bottom: 15px;
	margin-left: 15px;
}


/*
*
* Lists
*/

.list>li+li {
	margin-top: 10px;
}

.list-xs>li+li {
	margin-top: 5px;
}

.list-sm>li+li {
	margin-top: 7px;
}

.list-md>li+li {
	margin-top: 18px;
}

.list-lg>li+li {
	margin-top: 25px;
}

.list-xl>li+li {
	margin-top: 25px;
}

.list-1>li+li {
	margin-top: 14px;
}

@media (min-width: 768px) {
	.list-xl>li+li {
		margin-top: 60px;
	}
}

.list-inline>li {
	display: inline-block;
}

html .list-inline-xs {
	transform: translate3d(0, -5px, 0);
	margin-bottom: -5px;
	margin-left: -4px;
	margin-right: -4px;
}

html .list-inline-xs>* {
	margin-top: 5px;
	padding-left: 4px;
	padding-right: 4px;
}

html .list-inline-sm {
	transform: translate3d(0, -8px, 0);
	margin-bottom: -8px;
	margin-left: -8px;
	margin-right: -8px;
}

html .list-inline-sm>* {
	margin-top: 8px;
	padding-left: 8px;
	padding-right: 8px;
}

html .list-inline-md {
	transform: translate3d(0, -8px, 0);
	margin-bottom: -8px;
	margin-left: -10px;
	margin-right: -10px;
}

html .list-inline-md>* {
	margin-top: 8px;
	padding-left: 10px;
	padding-right: 10px;
}

.list-terms dd {
	color: #686868;
}

.list-terms dt+dd {
	margin-top: 15px;
}

.list-terms dd+dt {
	margin-top: 30px;
}

.list li > label {
	text-transform: capitalize;
}

@media (min-width: 768px) {
	.list-terms dd+dt {
		margin-top: 35px;
	}
}

@media (min-width: 1200px) {
	.list-terms dd+dt {
		margin-top: 45px;
	}
}

*+.list-terms {
	margin-top: 25px;
}

.list-terms-inline dt,
.list-terms-inline dd {
	display: inline;
}

.list-terms-inline dt::after {
	content: ':';
	display: inline-block;
}

.list-terms-inline a {
	color: inherit;
}

.list-terms-inline a:hover {
	color: #0A043B;
}

.index-list {
	counter-reset: li;
}

.index-list>li .list-index-counter:before {
	content: counter(li, decimal-leading-zero);
	counter-increment: li;
}

.list-marked {
	text-align: left;
	letter-spacing: .025em;
}

.list-marked>li {
	text-indent: -25px;
	padding-left: 40px;
}

.list-marked>li::before {
	position: relative;
	display: inline-block;
	left: 20px;
	top: -1px;
	min-width: 25px;
	content: '\e02d';
	font-family: 'fl-budicons-free';
	font-size: 11px;
	color: #0A043B;
}

.list-marked>li+li {
	margin-top: 11px;
}

.context-dark .list-marked,
.bg-gray-700 .list-marked,
.bg-blue-13 .list-marked,
.bg-blue-15 .list-marked,
.bg-primary .list-marked {
	color: #ffffff;
}

.bg-primary .list-marked>li::before {
	color: #ffffff;
}

*+.list-marked {
	margin-top: 20px;
}

.list-marked-1 {
	padding-left: 29px;
	text-align: left;
}

.list-marked-1>li {
	text-indent: -20px;
}

.list-marked-1>li::before {
	content: '';
	position: relative;
	top: -1px;
	display: inline-block;
	margin-right: 9px;
	vertical-align: middle;
	width: 0;
	height: 0;
	font-size: 0;
	line-height: 0;
	border-style: solid;
	border-width: 4px 0 4px 10px;
	border-color: transparent transparent transparent #0A043B;
}

.list-marked-1 a {
	color: inherit;
}

.list-marked-1 a:hover {
	color: #0A043B;
}

.list-marked-1>li+li {
	margin-top: 13px;
}

.list-marked-2 {
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif;
	color: #151515;
	text-align: left;
}

.list-marked-2>li {
	text-indent: -28px;
	padding-left: 40px;
}

.list-marked-2>li::before {
	position: relative;
	display: inline-block;
	left: 20px;
	top: -1px;
	min-width: 28px;
	content: '\e02d';
	font-family: 'fl-budicons-free';
	color: #0A043B;
}

.list-marked-2>li+li {
	margin-top: 10px;
}

*+.list-marked-2 {
	margin-top: 20px;
}

.list-marked-2+p {
	margin-top: 20px;
}

@media (min-width: 768px) {
	.list-marked-2 {
		font-size: 16px;
	}
	.list-marked-2>li+li {
		margin-top: 17px;
	}
	*+.list-marked-2 {
		margin-top: 38px;
	}
	.list-marked-2+p {
		margin-top: 38px;
	}
}

.list-marked-3 {
	color: #151515;
}

.list-marked-3>li {
	position: relative;
	padding-left: 30px;
	letter-spacing: .025em;
}

.list-marked-3>li::before {
	content: '';
	position: absolute;
	top: .65em;
	left: 0;
	display: inline-block;
	width: 18px;
	height: 3px;
	vertical-align: middle;
	background: #0A043B;
}

.list-marked-3>li+li {
	margin-top: 10px;
}

*+.list-marked-3 {
	margin-top: 15px;
}

@media (min-width: 768px) {
	.list-marked-3 {
		max-width: 340px;
	}
	.list-marked-3>li+li {
		margin-top: 18px;
	}
}

.list-ordered {
	counter-reset: li;
	padding-left: 25px;
	text-align: left;
	color: #151515;
}

.list-ordered>li {
	position: relative;
	padding-left: 25px;
}

.list-ordered>li:before {
	content: counter(li, decimal) ".";
	counter-increment: li;
	position: absolute;
	top: 0;
	left: 0;
	display: inline-block;
	width: 15px;
	color: #b7b7b7;
}

.list-ordered>li+li {
	margin-top: 10px;
}

*+.list-ordered {
	margin-top: 15px;
}

.list-categories {
	padding: 25px 30px;
	border: 1px solid #e0e0e0;
}

.list-categories>li>a {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
}

.list-categories>li.active {
	color: #0A043B;
}

.list-categories>li+li {
	margin-top: 11px;
}

.timeline-classic p {
	letter-spacing: .025em;
}

.timeline-classic-period {
	padding-right: 20px;
	white-space: nowrap;
	letter-spacing: .025em;
}

.timeline-classic-title {
	position: relative;
	padding-left: 35px;
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif;
}

.timeline-classic-title-sub {
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif;
	font-weight: 400;
}

.timeline-classic-title::before {
	content: '';
	position: absolute;
	left: 0;
	top: .65em;
	display: inline-block;
	width: 25px;
	height: 2px;
	vertical-align: middle;
	background: #0A043B;
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif;
}

.timeline-classic-main {
	position: relative;
	padding: 10px 0 30px 20px;
}

.timeline-classic-main::before,
.timeline-classic-main::after {
	content: '';
	position: absolute;
	pointer-events: none;
}

.timeline-classic-main::before {
	left: 0;
	top: 0;
	bottom: 0;
	border-left: 1px solid rgb(150, 145, 171);
}

.timeline-classic-main *+p {
	margin-top: 10px;
}

.timeline-classic-item:last-child .timeline-classic-main {
	padding-bottom: 0;
}

.timeline-classic-item:last-child .timeline-classic-main::before {
	bottom: 20px;
}

*+.timeline-classic {
	margin-top: 30px;
}

@media (max-width: 767.98px) {
	.timeline-classic-period {
		margin-left: -2px;
	}
}

@media (min-width: 768px) {
	.timeline-classic {
		display: table;
	}
	.timeline-classic-item {
		display: table-row;
	}
	.timeline-classic-item>* {
		display: table-cell;
	}
	.timeline-classic-main {
		padding-bottom: 20px;
	}
	.timeline-classic-main::after {
		top: 18px;
		left: -4px;
		width: 9px;
		height: 9px;
		background: #e0e0e0;
		border-radius: 50%;
	}
}

.timeline-classic-period label {
	width: 90px !important
}

.list-linked {
	display: flex;
	flex-wrap: wrap;
	counter-reset: list-linked-counter;
	text-align: center;
	margin-left: 100px;
	margin-right: 100px;
	margin-bottom: -30px;
}

.list-linked>* {
	margin-bottom: 30px;
}

.ll-item {
	padding: 0 15px;
	max-width: 240px;
	margin-left: auto;
	margin-right: auto;
}

.ll-item *+p {
	margin-top: 5px;
}

.ll-item:hover .ll-item-icon-inner {
	stroke-dashoffset: 0;
	opacity: 1;
}

.ll-item-icon {
	position: relative;
	align-items: center;
	justify-content: center;
	width: 100px;
	height: 100px;
	margin-left: auto;
	margin-right: auto;
	border-radius: 50%;
	font-size: 40px;
	line-height: 1;
	background: #ffffff;
	color: #0A043B;
	box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.06);
}

.ll-item-icon::after {
	content: '';
	position: absolute;
	top: 7px;
	right: 7px;
	bottom: 7px;
	left: 7px;
	border-radius: inherit;
	/* border: 1px solid rgba(16, 135, 235, 0.43); */
	border: 1px solid rgba(10, 4, 59, 0.43);
}

.ll-item-icon.icon {
	display: flex;
}

.ll-item-icon-inner {
	position: absolute;
	top: 7px;
	right: 7px;
	bottom: 7px;
	left: 7px;
	width: calc(100% - 14px);
	height: calc(100% - 14px);
	pointer-events: none;
	stroke: #0A043B;
	stroke-width: 2px;
	stroke-dasharray: 1000;
	stroke-dashoffset: 1000;
	opacity: 0;
	transition: opacity .22s, stroke-dashoffset .8s ease-in-out;
}

.ll-item-icon-sm {
	font-size: 26.66667;
}

.ll-item-title {
	font-weight: 500;
}

.ll-item-title::before {
	display: inline-block;
	margin-right: 10px;
	content: counter(list-linked-counter) ".";
	counter-increment: list-linked-counter;
}

*+.list-linked {
	margin-top: 35px;
}

*+.ll-item-main {
	margin-top: 20px;
}

@media (min-width: 400px) {
	.ll-item {
		width: 50%;
	}
}

@media (min-width: 768px) {
	.ll-item {
		position: relative;
		width: 25%;
		max-width: 100%;
	}
	.ll-item::after {
		content: '';
		position: absolute;
		left: calc(50% - -5px);
		z-index: 10;
		width: calc(100% - 50px);
		padding-bottom: 20.48193%;
		margin-left: 50px;
		background-repeat: no-repeat;
		background-position: center center;
		pointer-events: none;
	}
	.ll-item:nth-child(odd)::after {
		top: 10px;
		background-image: url(/img/divider-line-top.png);
	}
	.ll-item:nth-child(even)::after {
		top: 60px;
		background-image: url(/img/divider-line-bottom.png);
	}
	.ll-item:nth-child(3n)::after {
		display: none;
	}
}

@media (min-width: 1200px) {
	.ll-item::after {
		margin-left: 90px;
		width: calc(100% - 100px);
	}
	.ll-item:nth-child(even)::after {
		top: 100px;
	}
	.ll-item-icon {
		width: 160px;
		height: 160px;
		font-size: 66px;
	}
	.ll-item-icon::after {
		position: absolute;
		top: 13px;
		right: 13px;
		bottom: 13px;
		left: 13px;
	}
	.ll-item-icon-sm {
		font-size: 55px;
	}
	.ll-item-icon-inner {
		top: 13px;
		right: 13px;
		bottom: 13px;
		left: 13px;
		width: calc(100% - 26px);
		height: calc(100% - 26px);
	}
}

.list-icons>li,
.list-icons>li>a {
	white-space: nowrap;
}

.list-icons>li *,
.list-icons>li>a * {
	white-space: normal;
}

.list-icons>li>*,
.list-icons>li>a>* {
	display: inline-block;
	max-width: 92%;
	vertical-align: top;
}

.list-icons>li>*+*,
.list-icons>li>a>*+* {
	margin-left: 5px;
}

.list-progress {
	counter-reset: list-progress-counter;
}

.lp-item {
	padding: 20px 22px 25px 34px;
	max-width: 270px;
	margin-left: auto;
	margin-right: auto;
	background: #ffffff;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.19);
	transition: .22s;
}

.lp-item:hover {
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.22);
}

.lp-item p {
	letter-spacing: .05em;
}

.icon.lp-item-icon {
	font-size: 40px;
	line-height: 40px;
	color: #0A043B;
}

.lp-item-icon-sm {
	font-size: 32px;
}

.lp-item-header {
	display: flex;
	justify-content: space-between;
}

.lp-item-counter {
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 33px;
	line-height: 1;
	font-weight: 500;
	letter-spacing: .025em;
	color: #e7e9f0;
}

.lp-item-counter::before {
	content: counter(list-progress-counter, decimal-leading-zero);
	counter-increment: list-progress-counter;
}

.lp-item-title {
	font-weight: 500;
}

.bg-gray-100 .lp-item {
	box-shadow: none;
}

*+.lp-item-title {
	margin-top: 15px;
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.lp-item {
		padding: 20px 22px 25px 25px;
	}
}

@media (min-width: 768px) {
	.icon.lp-item-icon {
		font-size: 60px;
		line-height: 60px;
	}
	.icon.lp-item-icon-sm {
		font-size: 48px;
	}
	*+.lp-item-title {
		margin-top: 5px;
	}
}


/*
*
* Images
*/

img {
	display: inline-block;
	max-width: 100%;
	height: auto;
}

.img-responsive {
	width: 100%;
}


/*
*
* Icons
*/

.icon {
	display: inline-block;
	line-height: 1.1;
}

.icon::before {
	position: relative;
	display: inline-block;
	font-weight: 400;
	font-style: normal;
	speak: none;
	text-transform: none;
}

.icon-style-brand[class*='facebook']:hover {
	color: #4d70a8;
}

.icon-style-brand[class*='twitter']:hover {
	color: #02bcf3;
}

.icon-style-brand[class*='google']:hover {
	color: #e2411e;
}

.icon-style-brand[class*='pinterest']:hover {
	color: #c96867;
}

.icon-filled {
	display: inline-block;
	height: 2em;
	width: 2em;
	line-height: 2em;
	text-align: center;
}

.icon-filled-brand {
	position: relative;
}

.icon-filled-brand,
.icon-filled-brand:active,
.icon-filled-brand:focus,
.icon-filled-brand:hover {
	color: #ffffff;
}

.icon-filled-brand[class*='facebook'] {
	background: #5776b7;
}

.icon-filled-brand[class*='facebook']:hover {
	background: #6585b9;
}

.icon-filled-brand[class*='twitter'] {
	background: #88bdee;
}

.icon-filled-brand[class*='twitter']:hover {
	background: #21cbfd;
}

.icon-filled-brand[class*='google'] {
	background: #d46975;
}

.icon-filled-brand[class*='google']:hover {
	background: #e76042;
}

.icon-filled-brand[class*='pinterest'] {
	background: #c96867;
}

.icon-filled-brand[class*='pinterest']:hover {
	background: #d48685;
}

html a.icon-filled-brand:active,
html a.icon-filled-brand:focus,
html a.icon-filled-brand:hover {
	color: #ffffff;
}

.icon-circle {
	border-radius: 50%;
}

.icon-xxs {
	font-size: 13px;
}

.icon-xs {
	font-size: 15px;
}

.icon-sm {
	font-size: 19px;
}

.icon-lg {
	font-size: 30px;
}

.icon-xlg {
	font-size: 30px;
	line-height: 42px;
}

.icon-xl {
	font-size: 42px;
}

.icon-xxl {
	font-size: 60px;
}

@media (min-width: 768px) {
	.icon-xxl {
		font-size: 78px;
	}
}

.icon-default {
	font-size: 1rem;
}

.icon-1 {
	font-size: 14px !important;
}

.icon-2 {
	font-size: 22px;
}

.icon-3 {
	font-size: 26px;
}

.icon-3-company-edit {
	/* font-size: 26px !important; */
	margin-top: 1px !important;
	margin-left: 5% !important;
}

.icon-4 {
	font-size: 33px;
}


/*
*
* Tables custom
*/

.table {
	text-align: left;
	background: #ffffff;
	border-collapse: collapse;
}

.table tr {
	transition: .5s;
}

.table th,
.table td {
	color: #2c343b;
	background: #ffffff;
}

.table th {
	padding: 17px 24px;
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-weight: 600;
	letter-spacing: .05em;
	text-transform: uppercase;
	background: #f5f5f5;
	white-space: nowrap;
}

@media (max-width: 991.98px) {
	.table th {
		padding-top: 20px;
		padding-bottom: 20px;
	}
}

.table td {
	padding: 17px 24px;
	background-color: transparent;
}

.table tbody tr:first-child td {
	border-top: 0;
}

.table tr td {
	border-bottom: 1px solid #f5f5f5;
}

.table tr td:first-child {
	border-left: 1px solid #f5f5f5;
}

.table tr td:last-child {
	border-right: 1px solid #f5f5f5;
}

.table tfoot td {
	color: #34495e;
}

*+[class*='table-responsive'] {
	margin-top: 30px;
}

@media (min-width: 768px) {
	*+[class*='table-responsive'] {
		margin-top: 40px;
	}
}

.table.table-primary thead th {
	color: #ffffff;
	background: #0A043B;
	border: 0;
}

.table.table-primary tbody tr:hover {
	background: #f5f5f5;
}

.table.table-gray-700 thead th {
	color: #ffffff;
	background: #2c343b;
	border: 0;
}

.table.table-gray-700 tbody tr:hover {
	background: #f5f5f5;
}

.table.table-bordered tr td:first-child {
	border-left: 0;
}

.table.table-bordered tr td:last-child {
	border-right: 0;
}

.table.table-bordered td {
	border: 1px solid #f5f5f5;
}

.table.table-bordered tbody>tr:first-of-type>td {
	border-top: 0;
}

.table.table-striped {
	border-bottom: 1px solid #f5f5f5;
}

.table.table-striped tbody tr:nth-of-type(odd) {
	background: transparent;
}

.table.table-striped tbody tr:nth-of-type(even) {
	background: #f5f5f5;
}

.table.table-striped tbody td {
	border: 0;
}

.table.table-striped tfoot td:not(:first-child) {
	border-left: 0;
}

.table-job-listing {
	width: 100%;
	border-radius: 4px;
}

.table-job-listing tbody {
	width: 100%;
}

.table-job-listing tr {
	background-color: transparent;
	border: 1px solid rgb(150, 145, 171);
	transition: .22s;
}

.table-job-listing tr:hover {
	background: #f3f4f9;
}

.table-job-listing tr:hover .company-minimal-figure {
	background: #ffffff;
}

.table-job-listing td {
	padding: 16px 20px;
}

.table-job-listing .company-minimal-figure {
	transition: .22s;
}

.bg-gray-100 tr:hover {
	background: #ebedf3;
}

.table-job-listing_lg td {
	padding: 16px 30px;
}

.table-job-listing-main {
	min-width: 360px;
	width: 71.5%;
}

.table-job-listing-date {
	white-space: nowrap;
}

.table-job-listing-badge {
	text-align: right;
	width: 10%;
}

.heading-decorated_1+.table-job-listing {
	margin-top: 10px;
}

@media (min-width: 768px) {
	.table-job-listing tr {
		height: 110px;
	}
}

.table-job-offers {
	letter-spacing: .025em;
	color: #151515;
}

.table-job-offers tbody {
	width: 100%;
}

.table-job-offers tr {
	border-top: 1px solid #d6d6d6;
}

.table-job-offers tr:last-child {
	border-bottom: 1px solid #d6d6d6;
}

.table-job-offers td {
	padding: 14px 20px;
}

.table-job-offers.table-responsive {
	overflow-y: hidden;
}

.table-job-offers-main {
	min-width: 360px;
	width: 71.5%;
}

.table-job-offers-date:first-child {
	width: 16%;
	padding: 0;
}

.table-job-offers-date:first-child>* {
	display: block;
	padding: 16px 18px 16px 30px;
	border-right: 1px solid #d6d6d6;
}

.table-job-offers-date,
.table-job-offers-meta .object-inline>* {
	white-space: nowrap;
}

.table-job-offers-badge {
	text-align: right;
	width: 10%;
}

*+.table-job-offers-outer,
*+.table-job-offers {
	margin-top: 30px;
}

.table-job-offers-outer+.pagination-outer,
.table-job-offers+.pagination-outer {
	margin-top: 40px;
}

@media (min-width: 768px) {
	.table-job-offers tr {
		height: 102px;
	}
}

@media (min-width: 992px) {
	.table-job-offers-outer {
		position: relative;
		overflow: hidden;
		padding: 6px;
		margin-left: -12px;
		margin-right: -12px;
		margin-bottom: -6px;
	}
	.table-job-offers {
		padding: 6px;
		width: calc(100% + 6px);
	}
	.table-job-offers tr {
		transition: .35s ease-in-out;
	}
	.table-job-offers tr:hover {
		background: #f3f4f9;
		box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.15);
		border-color: #f3f4f9;
	}
	.table-job-offers tr:hover+tr {
		border-color: #f3f4f9;
	}
	.table-job-offers tr:last-child:hover {
		border-color: #f3f4f9;
	}
	.ie-10 .table-job-offers tr:hover,
	.ie-10 .table-job-offers tr:hover+tr,
	.ie-11 .table-job-offers tr:hover,
	.ie-11 .table-job-offers tr:hover+tr {
		border-color: #d6d6d6;
	}
}

@media (min-width: 1200px) {
	.table-job-offers-badge {
		width: 15%;
	}
}

.table-job-listing-2 {
	width: 100%;
	border-collapse: separate;
	margin: -10px 0;
	border-spacing: 0 10px;
}

.table-job-listing-2 tbody {
	width: 100%;
}

.table-job-listing-2 tr {
	background: #ffffff;
	transition: .22s;
}

.table-job-listing-2 td {
	padding: 16px 25px 16px 20px;
}

.table-job-listing-2-main {
	min-width: 360px;
	width: 50%;
}

.table-job-listing-2-location {
	width: 20%;
	white-space: nowrap;
}

.table-job-listing-2-badge {
	text-align: right;
	width: 10%;
}

.heading-decorated_1+.table-job-listing-2 {
	margin-top: 10px;
}

@media (min-width: 768px) {
	.table-job-listing-2 {
		margin: -20px 0;
		border-spacing: 0 20px;
	}
	.table-job-listing-2 tr {
		height: 110px;
	}
	.table-job-listing-2 .company-minimal-figure {
		min-width: 100px;
	}
	.table-job-listing-2-location {
		width: 30%;
	}
}


/*
*
* Dividers
*/

hr {
	margin-top: 0;
	margin-bottom: 0;
	border-top: 1px solid rgb(150, 145, 171);
}

.divider {
	font-size: 0;
	line-height: 0;
}

.divider::after {
	content: '';
	display: inline-block;
	width: 60px;
	height: 2px;
	background-color: #0A043B;
}

.divider-responsive {
	width: 100%;
	border-bottom: 1px solid #e0e0e0;
	margin-left: auto;
	margin-right: auto;
}

@media (min-width: 992px) {
	.col-lg-1 .divider-responsive {
		width: 0;
		border-bottom: 0;
		border-right: 1px solid #e0e0e0;
	}
}

.divider-1 {
	height: 20px;
	width: 1px;
	margin-left: auto;
	margin-right: auto;
	background: #d6d6d6;
}

.context-dark .divider-1,
.bg-gray-700 .divider-1,
.bg-blue-13 .divider-1,
.bg-blue-15 .divider-1,
.bg-primary .divider-1 {
	background: rgba(255, 255, 255, 0.46);
}

*+.divider-1 {
	margin-top: 15px;
}

.divider-1+.button {
	margin-top: 25px;
}

@media (min-width: 768px) {
	.divider-1 {
		height: 45px;
	}
}


/*
*
* Buttons
*/

.button {
	position: relative;
	overflow: hidden;
	display: inline-block;
	padding: 14px 35px;
	font-size: 14px;
	line-height: 1.3;
	border: 2px solid;
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-weight: 700;
	letter-spacing: 0.025em;
	text-transform: uppercase;
	white-space: nowrap;
	text-overflow: ellipsis;
	text-align: center;
	cursor: pointer;
	vertical-align: middle;
	user-select: none;
	transition: 250ms all ease-in-out;
}

.button-block {
	display: block;
	width: 100%;
}

.button-default,
.button-default:focus {
	color: #d6d6d6;
	background-color: #2c343b;
	border-color: #2c343b;
}

.button-default:hover,
.button-default:active {
	color: #ffffff;
	background-color: #0A043B;
	border-color: #0A043B;
}

.button-primary,
.button-primary:focus {
	color: #ffffff;
	background-color: #0A043B;
	border-color: #0A043B;
}

.button-primary:hover,
.button-primary:active {
	color: #ffffff;
	/* background-color: #ca5217;
	border-color: #ca5217; */
	background-color: rgb(39, 159, 141);
	border-color: rgb(39, 159, 141);
}

.bg-primary .button-primary,
.bg-primary .button-primary:focus {
	color: #151515;
	background-color: #ffffff;
	border-color: #ffffff;
}

.bg-primary .button-primary:hover,
.bg-primary .button-primary:active {
	color: #ffffff;
	background-color: #ca5217;
	border-color: #ca5217;
}

/* Grey Button Start */
.button-grey,
.button-grey:focus {
	color: #ffffff;
	background-color: #B8B8B9;
	border-color: #B8B8B9;
}

.button-grey:hover,
.button-grey:active {
	color: #ffffff;
	/* background-color: #ca5217;
	border-color: #ca5217; */
	background-color: #ca5217;
	border-color: #ca5217;
}

.bg-grey .button-grey,
.bg-grey .button-grey:focus {
	color: #151515;
	background-color: #ffffff;
	border-color: #ffffff;
}

.bg-grey .button-grey:hover,
.bg-grey .button-grey:active {
	color: #ffffff;
	background-color: #ca5217;
	border-color: #ca5217;
}
/* Grey Button Ends */

.button-gray-100,
.button-gray-100:focus {
	color: #151515;
	background-color: #f3f4f9;
	border-color: #f3f4f9;
}

.button-gray-100:hover,
.button-gray-100:active {
	color: #ffffff;
	background-color: #0A043B;
	border-color: #0A043B;
}

.button-secondary,
.button-secondary:focus {
	color: #ffffff;
	background-color: #ca5217;
	border-color: #ca5217;
}

.button-secondary:hover,
.button-secondary:active {
	color: #ffffff;
	background-color: #0A043B;
	border-color: #0A043B;
}

.button-blue-9,
.button-blue-9:focus {
	color: #ffffff;
	background-color: #49555e;
	border-color: #49555e;
}

.button-blue-9:hover,
.button-blue-9:active {
	color: #ffffff;
	background-color: #0A043B;
	border-color: #0A043B;
}

.button-blue-9 .icon,
.button-blue-9:active .icon,
.button-blue-9:focus .icon {
	color: #0A043B;
}

.button-blue-9:hover .icon {
	color: #ffffff;
}

.button-default-outline,
.button-default-outline:focus {
	color: #151515;
	background-color: transparent;
	border-color: #e0e0e0;
}

.button-default-outline:hover,
.button-default-outline:active {
	color: #ffffff;
	background-color: #0A043B;
	border-color: #0A043B;
}

.button-primary-outline,
.button-primary-outline:focus {
	color: #0A043B;
	background-color: transparent;
	border-color: #0A043B;
}

.button-primary-outline:hover,
.button-primary-outline:active {
	color: #ffffff;
	background-color: #0A043B;
	border-color: #0A043B;
}

.bg-primary .button-primary-outline,
.bg-primary .button-primary-outline:focus {
	color: #ffffff;
	background-color: transparent;
	border-color: #ffffff;
}

.bg-primary .button-primary-outline:hover,
.bg-primary .button-primary-outline:active {
	color: #151515;
	background-color: #ffffff;
	border-color: #ffffff;
}

.button-gray-800-outline,
.button-gray-800-outline:focus {
	color: #151515;
	background-color: transparent;
	border-color: #151515;
}

.button-gray-800-outline:hover,
.button-gray-800-outline:active {
	color: #ffffff;
	background-color: #151515;
	border-color: #151515;
}

.button-ghost {
	border: 0;
	background-color: transparent;
}

.button-ghost:hover {
	color: #ffffff;
	background: #0A043B;
}

.button-facebook,
.button-facebook:focus {
	color: #ffffff;
	background-color: #4d70a8;
	border-color: #4d70a8;
}

.button-facebook:hover,
.button-facebook:active {
	color: #ffffff;
	background-color: #6585b9;
	border-color: #6585b9;
}

.button-twitter,
.button-twitter:focus {
	color: #ffffff;
	background-color: #02bcf3;
	border-color: #02bcf3;
}

.button-twitter:hover,
.button-twitter:active {
	color: #ffffff;
	background-color: #21cbfd;
	border-color: #21cbfd;
}

.button-google,
.button-google:focus {
	color: #ffffff;
	background-color: #e2411e;
	border-color: #e2411e;
}

.button-google:hover,
.button-google:active {
	color: #ffffff;
	background-color: #e76042;
	border-color: #e76042;
}

.button-primary-hold {
	margin-left: 50%; 
	font-size: 12px;
}

.button-primary-hold,
.button-primary-hold:focus {
  color: #0A043B;
  background-color: transparent;
  border-color: #0A043B;
}

.button-primary-hold:hover,
.button-primary-hold:active {
  color: #ffffff;
  background-color: #151515;
  border-color: #151515;
}

.bg-primary .button-primary-hold,
.bg-primary .button-primary-hold:focus {
  color: #ffffff;
  background-color: transparent;
  border-color: #ffffff;
}

.bg-primary .button-primary-hold:hover,
.bg-primary .button-primary-hold:active {
  color: #151515;
  background-color: #ffffff;
  border-color: #ffffff;
}

.button-primary-reject {
	margin-left: 10px; 
	font-size: 12px;
}

.button-primary-reject,
.button-primary-reject:focus {
  color: #ffffff;
  background-color: red;
  border-color: red;
}

.button-primary-reject:hover,
.button-primary-reject:active {
  color: #ffffff;
  background-color: red;
  border-color: #151515;
}

.bg-primary .button-primary-reject,
.bg-primary .button-primary-reject:focus {
  color: #151515;
  background-color: #ffffff;
  border-color: #ffffff;
}

.bg-primary .button-primary-reject:hover,
.bg-primary .button-primary-reject:active {
  color: #ffffff;
  background-color: red;
  border-color: #151515;
}

.button-shadow {
	box-shadow: 0 9px 21px 0 rgba(214, 214, 214, 0.6);
}

.button-shadow:hover {
	box-shadow: 0 9px 10px 0 rgba(214, 214, 214, 0.6);
}

.button-shadow:focus,
.button-shadow:active {
	box-shadow: none;
}

.button-primary-approved,
.button-primary-approved:focus {
  color: #ffffff;
  background-color: darkgreen;
  border-color: darkgreen;
}

.button-primary-approved:hover,
.button-primary-approved:active {
  color: #ffffff;
  background-color: green;
  border-color: green;
}

.button-shadow-1 {
	box-shadow: 0 7px 18px 0 rgba(0, 0, 0, 0.18);
}

.button-xs {
	padding: 8px 15px;
	font-size: 13px;
	font-weight: 500;
	letter-spacing: .1em;
	border-radius: 4px;
}

.button-sm {
	font-weight: 500;
	letter-spacing: .1em;
	padding: 10px 25px;
	font-size: 13px;
	line-height: 1.5;
	border-radius: 4px;
}

.button-lg {
	font-weight: 500;
	letter-spacing: .05em;
	padding: 17px 40px;
	font-size: 14px;
	line-height: 1.5;
}

.button-xl {
	letter-spacing: .075em;
	padding: 16px 50px;
	font-size: 16px;
	line-height: 28px;
}

@media (min-width: 992px) {
	.button-xl {
		padding: 20px 80px;
	}
}

.button-circle {
	border-radius: 30px;
}

.button-round-1 {
	border-radius: 5px;
}

.button-round-2 {
	border-radius: 10px;
}

.button.button-icon,
.button-icon .button-inner {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	vertical-align: middle;
}

.button-icon-right,
.button-icon-right .button-inner {
	flex-direction: row-reverse;
}

.button-icon .icon {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	color: inherit;
	font-size: 1.55em;
	line-height: 1em;
	transition: color inherit;
}

.button-icon-left .icon {
	padding-right: 11px;
}

.button-icon-right .icon {
	padding-left: 11px;
}

.button-icon.button-xs .icon {
	padding-right: 5px;
}

.button-icon.button-xs .button-icon-right .icon {
	padding-left: 5px;
}

.button-icon.button-sm .icon {
	top: .05em;
	font-size: 1.2em;
	padding-right: 8px;
}

.button-icon.button-sm .button-icon-right .icon {
	padding-left: 8px;
}

.button.button-icon-only {
	padding: 5px;
}

.btn-primary {
	border-radius: 3px;
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-weight: 700;
	letter-spacing: .05em;
	text-transform: uppercase;
	transition: .33s;
}

.btn-primary,
.btn-primary:active,
.btn-primary:focus {
	color: #ffffff;
	background: #0A043B;
	border-color: #0A043B;
}

.btn-primary:hover {
	color: #ffffff;
	background: #000000;
	border-color: #000000;
}

.button-group {
	display: flex;
}

.button-group .button {
	margin: 0 2px;
	flex-grow: 1;
}

.button-group .button:not(:first-child) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.button-group .button:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.group .button-fixed-size {
	display: inline-flex;
}

.button-fixed-size {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 60px;
	padding: 5px;
}

.button-fixed-size img {
	width: auto;
	height: auto;
	max-width: 90%;
}

@media (min-width: 576px) {
	.button-fixed-size {
		width: 177px;
	}
}

label.button input {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}


/*
*
* Form styles
*/

.rd-form {
	position: relative;
	text-align: left;
}

.rd-form .button {
	padding-top: 10px;
	padding-bottom: 10px;
	min-height: 50px;
}

*+.rd-form {
	margin-top: 20px;
}

input:-webkit-autofill~label,
input:-webkit-autofill~.form-validation {
	color: #151515 !important;
}

.form-wrap {
	position: relative;
}

.form-wrap-inner {
	position: relative;
	/* min-height: 40px; */
}

.form-wrap-icon .icon {
	position: absolute;
	z-index: 10;
	top: 10px;
	left: 15px;
	color: #151515;
	line-height: 24px;
}

.form-wrap-icon .icon:not(.icon-custom-sizing) {
	font-size: 14px;
}

.form-wrap-icon .form-label,
.form-wrap-icon .form-input,
.form-wrap-icon .select2-container--default .select2-selection--single .select2-selection__rendered {
	padding-left: 35px !important;
}

.has-error .form-input,
.has-error .select2-container--default .select2-selection--single {
	border-color: #eb6a6a;
}

.has-focus .form-input,
.has-focus .select2-container--default .select2-selection--single {
	border-color: #0A043B;
}

/* .form-wrap+* { */
	/* margin-top: 30px; */
/* } */

.rd-form .form-wrap+.row {
	margin-top: 30px;
}

.form-input {
	display: block;
	width: 100%;
	min-height: 50px;
	padding: 10px 10px;
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	color: #151515;
	background-color: #ffffff;
	background-image: none;
	/* border-radius: 0; */
	-webkit-appearance: none;
	transition: .3s ease-in-out;
	border: 1px solid rgb(150, 145, 171);
}

.form-input:focus {
	outline: 0;
}

textarea.form-input {
	height: 175px;
	min-height: 50px;
	max-height: 298px;
	resize: vertical;
}

.form-label,
.form-label-outside {
	margin-bottom: 0;
	color: #9a9a9a;
	font-weight: 400;
}

.form-label {
	position: absolute;
	top: 10px;
	left: 0;
	right: 0;
	padding-left: 10px;
	padding-right: 26px;
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	color: #151515;
	pointer-events: none;
	text-align: left;
	/* z-index: 9; */
	transition: .25s;
	will-change: transform;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.candidate-form-label-keyword {
	color: #151515
}

.form-label-keyword {
	position: absolute;
	top: 170px;
	left: 0;
	right: 0;
	padding-left: 0px;
	padding-right: 26px;
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	color: #ffffff;
	pointer-events: none;
	text-align: left;
	z-index: 9;
	transition: .25s;
	will-change: transform;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@media (min-width: 321px) and  (max-width: 519px){
	.form-label-keyword {
		top: 127px;
	}
}

@media (min-width: 520px) and (max-width: 780px) {
	.form-label-keyword {
		top: 87px;
		left: 40px;
	}
}

@media (min-width: 781px) and (max-width: 1215px) {
	.form-label-keyword {
		top: 227px;
	}
}

.form-label.focus {
	opacity: 0;
}

.form-label-preselect-hide {
	opacity: 0;
}

.form-label.auto-fill {
	color: #151515;
}

.form-label-outside {
	display: inline-block;
	margin-bottom: 6px;
	letter-spacing: .025em;
	color: #151515;
	cursor: pointer;
}

@media (min-width: 768px) {
	.form-label-outside {
		position: static;
	}
	.form-label-outside,
	.form-label-outside.focus,
	.form-label-outside.auto-fill {
		transform: none;
	}
}

.form-label-outside+.form-wrap-inner .form-label,
.form-label-outside+.form-wrap-inner .form-input,
.form-label-outside+.form-wrap-inner .select2-selection--single .select2-selection__rendered {
	color: black;
}

[data-x-mode='true'] .form-label {
	pointer-events: auto;
}

.form-label {
	font-style: italic;
	color: grey !important;
}

.form-validation {
	position: absolute;
	right: 8px;
	top: 0;
	z-index: 11;
	margin-top: 2px;
	font-size: 9px;
	font-weight: 400;
	line-height: 12px;
	letter-spacing: 0;
	color: #eb6a6a;
	transition: .3s;
}

.form-validation-left .form-validation {
	top: 100%;
	right: auto;
	left: 0;
}

#form-output-global {
	position: fixed;
	bottom: 30px;
	left: 15px;
	z-index: 2000;
	visibility: hidden;
	transform: translate3d(-500px, 0, 0);
	transition: .3s all ease;
}

#form-output-global.active {
	visibility: visible;
	transform: translate3d(0, 0, 0);
}

@media (min-width: 576px) {
	#form-output-global {
		left: 30px;
	}
}

.form-output {
	position: absolute;
	top: 100%;
	left: 0;
	font-size: 10px;
	font-weight: 400;
	line-height: 1.2;
	margin-top: 2px;
	transition: .3s;
	opacity: 0;
	visibility: hidden;
}

.form-output.active {
	opacity: 1;
	visibility: visible;
}

.form-output.error {
	color: #eb6a6a;
}

.form-output.success {
	color: #98bf44;
}

.radio .radio-custom,
.radio-inline .radio-custom,
.checkbox .checkbox-custom,
.checkbox-inline .checkbox-custom {
	opacity: 0;
}

.radio .radio-custom,
.radio .radio-custom-dummy,
.radio-inline .radio-custom,
.radio-inline .radio-custom-dummy,
.checkbox .checkbox-custom,
.checkbox .checkbox-custom-dummy,
.checkbox-inline .checkbox-custom,
.checkbox-inline .checkbox-custom-dummy {
	position: absolute;
	left: 0;
	width: 15px;
	height: 15px;
	outline: none;
	cursor: pointer;
	border: 1px solid #E7E7E7;
	box-sizing: border-box;
	border-radius: 4px;
}

.radio .radio-custom-dummy,
.radio-inline .radio-custom-dummy,
.checkbox .checkbox-custom-dummy,
.checkbox-inline .checkbox-custom-dummy {
	pointer-events: none;
}

.radio .radio-custom-dummy::after,
.radio-inline .radio-custom-dummy::after,
.checkbox .checkbox-custom-dummy::after,
.checkbox-inline .checkbox-custom-dummy::after {
	position: absolute;
	opacity: 0;
	transition: .22s;
}

.radio .radio-custom:focus,
.radio-inline .radio-custom:focus,
.checkbox .checkbox-custom:focus,
.checkbox-inline .checkbox-custom:focus {
	outline: none;
}

.radio,
.radio-inline,
.checkbox,
.checkbox-inline {
	position: relative;
	cursor: pointer;
	user-select: none;
}

.radio input,
.radio-inline input,
.checkbox input,
.checkbox-inline input {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

.radio-custom:checked+.radio-custom-dummy:after,
.checkbox-custom:checked+.checkbox-custom-dummy:after {
	opacity: 1;
}

.radio,
.radio-inline {
	padding-left: 28px;
}

.radio .radio-custom-dummy,
.radio-inline .radio-custom-dummy {
	top: 1px;
	left: 0;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	border: 1px solid #b7b7b7;
}

.radio .radio-custom-dummy::after,
.radio-inline .radio-custom-dummy::after {
	content: '';
	top: 3px;
	right: 3px;
	bottom: 3px;
	left: 3px;
	background: #2c343b;
	border-radius: inherit;
}

.checkbox {
	/* display: block; */
	display: inline;
}

.checkbox,
.checkbox-inline {
	padding-left: 30px;
	color: #151515;
	letter-spacing: .025em;
}

.checkbox .checkbox-custom-dummy,
.checkbox-inline .checkbox-custom-dummy {
	top: 1px;
	left: 0;
	width: 18px;
	height: 18px;
	margin: 0;
	border: 2px solid #e0e0e0;
}

.checkbox .checkbox-custom-dummy::after,
.checkbox-inline .checkbox-custom-dummy::after {
	content: '\f222';
	font-family: "Material Design Icons";
	position: absolute;
	top: -5px;
	left: -2px;
	font-size: 21px;
	line-height: 1;
	color: #151515;
}

.checkbox-inline {
	padding-left: 25px;
}

.checkbox_info {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
}

.checkbox_info>*+* {
	margin-left: 4px;
}

.checkbox_info .checkbox-meta {
	color: #9a9a9a;
}

.checkbox_info .checkbox-meta::before {
	content: '\0028';
}

.checkbox_info .checkbox-meta::after {
	content: '\0029';
}

.toggle-custom {
	padding-left: 60px;
	-webkit-appearance: none;
}

.toggle-custom:checked~.checkbox-custom-dummy::after {
	background: #0A043B;
	transform: translate(20px, -50%);
}

.toggle-custom~.checkbox-custom-dummy {
	position: relative;
	display: inline-block;
	margin-top: -1px;
	width: 44px;
	height: 20px;
	background: #ffffff;
	cursor: pointer;
}

.toggle-custom~.checkbox-custom-dummy::after {
	content: '';
	position: absolute;
	display: inline-block;
	width: 16px;
	height: 16px;
	left: 0;
	top: 50%;
	background: #b7b7b7;
	transform: translate(4px, -50%);
	opacity: 1;
	transition: .22s;
}

.form-inline {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	text-align: center;
	margin-bottom: -8px;
	margin-left: -30px;
}

.form-inline:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.form-inline>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 8px;
	margin-left: 30px;
}

.form-inline>* {
	margin-top: 0;
}

.form-inline .form-wrap {
	flex: 30 0;
	min-width: 220px;
}

.form-inline .form-label {
	display: block;
}

.form-inline .form-button {
	flex-grow: 1;
	min-height: 50px;
}

.form-inline .form-button .button {
	width: 100%;
	min-height: inherit;
}

@media (min-width: 576px) {
	.form-inline .button {
		display: block;
	}
}

.context-dark .form-inline .form-input,
.bg-gray-700 .form-inline .form-input,
.bg-blue-13 .form-inline .form-input,
.bg-blue-15 .form-inline .form-input,
.bg-primary .form-inline .form-input {
	border-color: #ffffff;
}

.form-inline.form-inline_condensed {
	margin-left: 0;
}

.form-inline.form-inline_condensed>* {
	margin-left: 0;
}

.form-sm .form-input,
.form-sm .button {
	padding-top: 9px;
	padding-bottom: 9px;
	min-height: 40px;
}

.form-sm .form-validation {
	top: -16px;
}

.form-sm .form-label {
	top: 20px;
}

.form-sm *+.button {
	margin-top: 10px;
}

.form-lg .form-input,
.form-lg .select2-selection.select2-selection--single .select2-selection__rendered {
	/* padding-top: 17px;
	padding-bottom: 17px; */
	padding-left: 10px;
}

.form-lg .form-input {
	min-height: 44px;
}

.form-lg .select2-selection.select2-selection--single .select2-selection__rendered {
	min-height: 58px;
}

.form-lg .form-button {
	min-height: 60px;
}

/* .form-lg .form-label {
	top: 18px;
} */

/* .form-lg .form-wrap-icon .icon {
	top: 18px;
} */

.form-centered .form-label,
.form-centered .form-input {
	text-align: center;
}

.form-centered .form-wrap+* {
	margin-top: 15px;
}

.form-light .form-input {
	box-shadow: 0 9px 21px 0 rgba(30, 30, 30, 0.38);
}

.context-dark .form-light .form-label,
.bg-gray-700 .form-light .form-label,
.bg-blue-13 .form-light .form-label,
.bg-blue-15 .form-light .form-label,
.bg-primary .form-light .form-label,
.context-dark .form-light .form-input,
.bg-gray-700 .form-light .form-input,
.bg-blue-13 .form-light .form-input,
.bg-blue-15 .form-light .form-input,
.bg-primary .form-light .form-input,
.context-dark .form-light .form-validation,
.bg-gray-700 .form-light .form-validation,
.bg-blue-13 .form-light .form-validation,
.bg-blue-15 .form-light .form-validation,
.bg-primary .form-light .form-validation {
	color: #ffffff;
}

.context-dark .form-light .form-input,
.bg-gray-700 .form-light .form-input,
.bg-blue-13 .form-light .form-input,
.bg-blue-15 .form-light .form-input,
.bg-primary .form-light .form-input {
	background: rgba(255, 255, 255, 0.47);
	border: 0;
}

.form-filled .form-input,
.form-filled .select2-container--default .select2-selection--single {
	background: #f3f4f9;
	border-color: #f3f4f9;
}

.select2-dropdown-filled.select2-dropdown {
	color: #2c343b;
	background: #f3f4f9;
	border-color: #f3f4f9;
}

.context-dark .form-filled .form-label,
.bg-gray-700 .form-filled .form-label,
.bg-blue-13 .form-filled .form-label,
.bg-blue-15 .form-filled .form-label,
.bg-primary .form-filled .form-label,
.context-dark .form-filled .form-input,
.bg-gray-700 .form-filled .form-input,
.bg-blue-13 .form-filled .form-input,
.bg-blue-15 .form-filled .form-input,
.bg-primary .form-filled .form-input,
.context-dark .form-filled .form-validation,
.bg-gray-700 .form-filled .form-validation,
.bg-blue-13 .form-filled .form-validation,
.bg-blue-15 .form-filled .form-validation,
.bg-primary .form-filled .form-validation {
	color: #ffffff;
}

.context-dark .form-filled .form-input,
.bg-gray-700 .form-filled .form-input,
.bg-blue-13 .form-filled .form-input,
.bg-blue-15 .form-filled .form-input,
.bg-primary .form-filled .form-input {
	background: rgba(255, 255, 255, 0.25);
	border-color: transparent;
}

.form-corporate {
	padding: 30px 20px;
	background: #f3f4f9;
}

.form-corporate textarea.form-input {
	height: 125px;
}

@media (min-width: 768px) {
	.form-corporate {
		padding: 30px;
	}
}

@media (min-width: 768px) {
	.form-corporate_sm {
		padding: 20px 15px;
	}
}

@media (min-width: 1200px) {
	/* .form-corporate { */
		/* padding: 35px 30px 50px; */
	/* } */
	.form-corporate h4+.form-wrap {
		margin-top: 30px;
	}
	.form-corporate_sm {
		padding: 35px 35px 50px;
	}
}

.rd-form .button-form-indicator {
	position: relative;
	min-width: 60px;
	padding: 8px;
	min-height: 50px;
}

.rd-form .button-form-indicator .icon {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	color: inherit;
}

.rd-form .button-form-indicator .form-icon-success {
	transform: scale(0, 0);
	transition: .15s;
}

.rd-form.success .button-form-indicator .form-icon-default {
	transform: scale(0, 0);
	transition: .15s;
}

.rd-form.success .button-form-indicator .form-icon-success {
	transform: scale(1, 1);
	transition: .3s;
}

.rd-form.form-lg .button-form-indicator {
	min-width: 70px;
	min-height: 60px;
}

.form-compact .form-wrap+* {
	margin-top: 14px;
}

@media (max-height: 400px) {
	.form-compact .form-label,
	.form-compact .form-input {
		padding: 8px 15px;
		min-height: 36px;
	}
	.form-compact .form-label {
		top: 0;
		left: 0;
	}
	.form-compact .button {
		padding-top: 10px;
		padding-bottom: 10px;
		min-height: 36px;
	}
	.form-compact .form-wrap+* {
		margin-top: 8px;
	}
	.form-compact *+.form-wrap-ally {
		margin-top: 14px;
	}
}

.form-layout-search {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: -15px;
	margin-left: -15px;
}

.form-layout-search:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.form-layout-search>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 15px;
	margin-left: 15px;
}

.form-layout-search .form-wrap {
	flex: 20 0;
	min-width: 250px;
}

.form-layout-search .form-wrap-select {
	max-width: 100%;
}

.form-layout-search .form-wrap-button {
	display: inline-flex;
	flex-grow: 1;
}

.form-layout-search .form-wrap-button .button {
	width: 100%;
	min-height: inherit;
}

.form-layout-search .form-wrap-icon .icon:not(.icon-custom-sizing) {
	font-size: 20px;
}

.form-layout-search .select2-container--default .select2-selection--single .select2-selection__rendered {
	position: relative;
	top: 1px;
}

.form-layout-search .select2-container {
	display: block;
	min-width: 100%;
}

.form-layout-search.form-lg .form-label,
.form-layout-search.form-lg .form-input,
.form-layout-search.form-lg .select2-container--default .select2-selection--single .select2-selection__rendered {
	font-size: 14px;
	letter-spacing: .05em;
}

@media (min-width: 400px) {
	.form-layout-search .form-wrap {
		min-width: 270px;
	}
}

@media (min-width: 768px) {
	.form-layout-search .form-wrap-select {
		max-width: 310px;
	}
}

@media (min-width: 1200px) {
	.form-layout-search .form-wrap-button {
		min-width: 168px;
	}
	.form-layout-search .form-wrap-button-icon-only {
		min-width: 75px;
	}
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	.form-layout-search .form-wrap-button-search-lg-lg {
		font-size: 0;
		flex-grow: 0;
		min-width: 50px;
	}
	.form-layout-search .form-wrap-button-search-lg-lg .button {
		padding: 10px;
		font-size: 0;
	}
	.form-layout-search .form-wrap-button-search-lg-lg .button::before {
		content: '\e01c';
		font: 400 20px 'fl-budicons-launch';
	}
	.form-layout-search.form-lg .form-wrap-button-search-lg-lg {
		min-width: 60px;
	}
}

@media (min-width: 768px) and (max-width: 1199.98px) {
	.form-layout-search .form-wrap-button-search-md-lg {
		font-size: 0;
		flex-grow: 0;
		min-width: 50px;
	}
	.form-layout-search .form-wrap-button-search-md-lg .button {
		padding: 10px;
		font-size: 0;
	}
	.form-layout-search .form-wrap-button-search-md-lg .button::before {
		content: '\e01c';
		font: 400 20px 'fl-budicons-launch';
	}
	.form-layout-search.form-lg .form-wrap-button-search-md-lg {
		min-width: 60px;
	}
}

.form-layout-search+.row {
	margin-top: 0px;
}

.form-spacing-small *+.form-wrap {
	margin-top: 15px;
}

.form-creative-outer {
	padding: 24px 20px 20px;
	background: #f3f4f9;
}

@media (min-width: 768px) {
	.form-creative-outer .form-wrap-select {
		max-width: 280px;
	}
}

.context-dark .form-style-1 .form-label,
.bg-gray-700 .form-style-1 .form-label,
.bg-blue-13 .form-style-1 .form-label,
.bg-blue-15 .form-style-1 .form-label,
.bg-primary .form-style-1 .form-label,
.context-dark .form-style-1 .form-input,
.bg-gray-700 .form-style-1 .form-input,
.bg-blue-13 .form-style-1 .form-input,
.bg-blue-15 .form-style-1 .form-input,
.bg-primary .form-style-1 .form-input,
.context-dark .form-style-1 .form-validation,
.bg-gray-700 .form-style-1 .form-validation,
.bg-blue-13 .form-style-1 .form-validation,
.bg-blue-15 .form-style-1 .form-validation,
.bg-primary .form-style-1 .form-validation {
	color: #ffffff;
}

.context-dark .form-style-1 .form-validation,
.bg-gray-700 .form-style-1 .form-validation,
.bg-blue-13 .form-style-1 .form-validation,
.bg-blue-15 .form-style-1 .form-validation,
.bg-primary .form-style-1 .form-validation {
	opacity: .7;
}

.context-dark .form-style-1 .form-label,
.bg-gray-700 .form-style-1 .form-label,
.bg-blue-13 .form-style-1 .form-label,
.bg-blue-15 .form-style-1 .form-label,
.bg-primary .form-style-1 .form-label,
.context-dark .form-style-1 .form-input,
.bg-gray-700 .form-style-1 .form-input,
.bg-blue-13 .form-style-1 .form-input,
.bg-blue-15 .form-style-1 .form-input,
.bg-primary .form-style-1 .form-input {
	padding-left: 20px;
	padding-right: 20px;
}

.context-dark .form-style-1 .form-input,
.bg-gray-700 .form-style-1 .form-input,
.bg-blue-13 .form-style-1 .form-input,
.bg-blue-15 .form-style-1 .form-input,
.bg-primary .form-style-1 .form-input {
	background: #404851;
	border-color: #404851;
}


/*
*
* Posts
*/

.post-classic {
	padding-bottom: 20px;
	border-bottom: 1px solid #e0e0e0;
	transition: .22s ease-in;
	transform: translate3d(0, 0, 0);
	backface-visibility: hidden;
}

.post-classic:hover {
	transform: translate3d(0, -12px, 0);
}

.post-classic-media {
	position: relative;
	display: block;
}

.post-classic-media::after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(54, 67, 82, 0.2);
	transition: .22s ease-in;
	opacity: 0;
	visibility: hidden;
}

.post-classic-media:hover::after {
	opacity: 1;
	visibility: visible;
}

.post-classic-image {
	width: 100%;
}

.post-classic-time {
	display: block;
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 12px;
	font-weight: 600;
	line-height: 1.2;
	letter-spacing: .1em;
	color: #0A043B;
}

.post-classic-text {
	letter-spacing: .025em;
}

.post-classic-meta {
	color: #151515;
	transform: translate3d(0, -5px, 0);
	margin-bottom: -5px;
	margin-left: -20px;
	margin-right: -20px;
}

.post-classic-meta>* {
	margin-top: 5px;
	padding-left: 20px;
	padding-right: 20px;
}

.post-classic-meta .icon {
	color: #0A043B;
}

.post-classic-meta>li {
	display: inline-block;
	line-height: 1.2;
	vertical-align: middle;
}

.post-classic-meta>li:not(:last-child) {
	border-right: 1px solid #e0e0e0;
}

.post-classic-meta>li a {
	display: block;
}

.post-classic-meta>li>a *+* {
	margin-left: 4px;
}

.post-classic-meta span {
	display: inline-block;
}

*+.post-classic-time {
	margin-top: 10px;
}

*+.post-classic-title {
	margin-top: 15px;
}

*+.post-classic-meta {
	margin-top: 15px;
}

*+.post-classic-text {
	margin-top: 16px;
}

@media (max-width: 767.98px) {
	.post-classic {
		max-width: 370px;
		margin-left: auto;
		margin-right: auto;
	}
}

@media (min-width: 768px) {
	*+.post-classic-time {
		margin-top: 12px;
	}
	*+.post-classic-title {
		margin-top: 20px;
	}
	*+.post-classic-text {
		margin-top: 24px;
	}
}

.post-creative p {
	letter-spacing: .05em;
}

.post-creative-image {
	width: 100%;
}

.post-creative-meta {
	justify-content: space-between;
	border-bottom: 1px solid #e0e0e0;
	padding: 20px 0;
}

.post-creative-meta p a {
	color: inherit;
}

.post-creative-meta p a:hover {
	color: #0A043B;
}

.post-creative-meta-inner {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: -20px;
	margin-left: -20px;
}

.post-creative-meta-inner:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.post-creative-meta-inner>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 20px;
	margin-left: 20px;
}

.post-creative-author {
	display: flex;
	align-items: center;
}

.post-creative-author>*+* {
	margin-top: 0;
	margin-left: 10px;
}

.post-creative-author-image {
	display: block;
	max-width: 36px;
	border-radius: 50%;
}

.post-creative-meta-list {
	transform: translate3d(0, -5px, 0);
	margin-bottom: -5px;
	margin-left: -19px;
	margin-right: -19px;
	letter-spacing: .05em;
}

.post-creative-meta-list>* {
	margin-top: 5px;
	padding-left: 19px;
	padding-right: 19px;
}

.post-creative-meta-list>li {
	display: inline-block;
	vertical-align: middle;
	line-height: 1.2;
}

.post-creative-meta-list>li>* {
	display: inline-block;
	vertical-align: middle;
	line-height: 1.2;
}

.post-creative-meta-list>li>*+* {
	margin-left: 5px;
}

.post-creative-meta-list>li:not(:last-child) {
	border-right: 1px solid #e0e0e0;
}

.post-creative-meta-list .icon {
	position: relative;
	top: -1px;
	color: #151515;
}

.post-creative-meta-list .mdi-clock {
	font-size: 16px;
}

.post-creative-meta-list .fl-justicons-visible6 {
	font-size: 13px;
}

.post-creative-meta-list .mdi-message-outline {
	font-size: 15px;
}

.post-creative-footer {
	padding: 20px 25px;
	color: #151515;
	background: #f3f4f9;
}

.post-creative-footer-inner {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: -10px;
	margin-left: -10px;
}

.post-creative-footer-inner:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.post-creative-footer-inner>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 10px;
	margin-left: 10px;
}

.post-creative-footer-inner>* {
	margin-top: 0;
}

.post-creative-meta+* {
	margin-top: 20px;
}

*+.post-creative-footer {
	margin-top: 25px;
}

@media (min-width: 992px) {
	.post-creative-meta+* {
		margin-top: 32px;
	}
}

.post-light {
	display: flex;
	background: #f3f4f9;
	transition: .22s ease-in;
}

.post-light,
.post-light:active,
.post-light:focus,
.post-light:hover {
	color: #9a9a9a;
}

.post-light:hover {
	box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.1);
}

.post-light-media {
	position: relative;
	overflow: hidden;
	display: block;
	width: 106px;
	flex-shrink: 0;
}

.post-light-image {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	min-height: 100%;
	min-width: 100%;
	width: auto;
	height: auto;
	max-width: none;
}

@supports (object-fit: cover) {
	.post-light-image {
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		transform: none;
		object-fit: cover;
		object-position: center center;
	}
}

.post-light-main {
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-grow: 1;
	padding: 18px;
}

.post-light-title {
	max-width: 170px;
	color: #151515;
}

.post-light-title a {
	color: inherit;
}

.post-light-time {
	display: block;
}

*+.post-light {
	margin-top: 16px;
}

*+.post-light-time {
	margin-top: 1px;
}

.post-minimal {
	background: #ffffff;
	transition: .3s;
}

.post-minimal p {
	letter-spacing: .025em;
}

.post-minimal:hover {
	transform: translateY(-8px);
	box-shadow: 0 13px 16px 0 rgba(0, 0, 0, 0.13);
}

.post-minimal-media {
	position: relative;
	display: block;
}

.post-minimal-media::after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(255, 255, 255, 0.1);
	transition: .22s ease-in;
	opacity: 0;
	visibility: hidden;
}

.post-minimal-media:hover::after {
	opacity: 1;
	visibility: visible;
}

.post-minimal-image {
	width: 100%;
}

.post-minimal-main {
	padding: 25px 15px;
}

.post-minimal-title {
	max-width: 300px;
}

.post-minimal-title a {
	letter-spacing: 0;
}

.post-minimal-time {
	display: block;
	font: 500 14px/16px "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", rubiklight, "Helvetica Neue", Arial, sans-serif;
	color: #0A043B;
	letter-spacing: .1em;
}

@media (min-width: 576px) and (max-width: 991.98px) {
	.col-12.col-lg-4 .post-minimal {
		display: flex;
		max-width: 100%;
	}
	.col-12.col-lg-4 .post-minimal-media {
		position: relative;
		overflow: hidden;
		flex-shrink: 0;
		width: 100%;
		max-width: 44%;
	}
	.col-12.col-lg-4 .post-minimal-media img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		min-height: 100%;
		min-width: 100%;
		width: auto;
		height: auto;
		max-width: none;
	}
	@supports (object-fit: cover) {
		.col-12.col-lg-4 .post-minimal-media img {
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			transform: none;
			object-fit: cover;
			object-position: center center;
		}
	}
	.col-12.col-lg-4 .post-minimal-main {
		flex-grow: 1;
		align-self: center;
		padding: 40px 35px;
	}
}

*+.post-minimal-title {
	margin-top: 14px;
}

*+.post-minimal-time {
	margin-top: 14px;
}

@media (max-width: 575.98px) {
	.post-minimal {
		max-width: 370px;
		margin-left: auto;
		margin-right: auto;
	}
}

@media (min-width: 768px) {
	.post-minimal *+hr {
		margin-top: 25px;
	}
	.post-minimal-main {
		padding: 20px 35px 35px 40px;
	}
	*+.post-minimal-title {
		margin-top: 25px;
	}
	*+.post-minimal-time {
		margin-top: 20px;
	}
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	.post-minimal-main {
		padding: 20px 20px 30px 26px;
	}
}

.post-line {
	display: flex;
	max-width: 320px;
	align-items: flex-start;
	text-align: left;
	color: #9a9a9a;
}

.post-line-time {
	position: relative;
	display: block;
	min-width: 60px;
	padding: 3px 10px 3px 0;
	text-align: center;
}

.post-line-time::after {
	content: '';
	position: absolute;
	top: 2px;
	bottom: 8px;
	right: 0;
	width: 1px;
	background: #d6d6d6;
	pointer-events: none;
}

.post-line-time *+* {
	margin-top: 3px;
}

.post-line-time-day,
.post-line-time-month {
	display: block;
	line-height: 1;
}

.post-line-time-day {
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 33px;
	color: #151515;
}

.post-line-time-month {
	font-size: 10px;
	font-weight: 700;
	letter-spacing: .05em;
	text-transform: uppercase;
}

.post-line-text {
	padding: 3px 0 3px 20px;
	margin: 0;
	line-height: 1.71429;
	color: #151515;
	transition: .25s;
}

.post-line-text a {
	display: block;
	color: inherit;
}

.post-line-text a:hover {
	color: #0A043B;
}

*+.post-line {
	margin-top: 20px;
}

.context-dark .post-line:hover .post-line-text,
.bg-gray-700 .post-line:hover .post-line-text,
.bg-blue-13 .post-line:hover .post-line-text,
.bg-blue-15 .post-line:hover .post-line-text,
.bg-primary .post-line:hover .post-line-text {
	text-shadow: 1px 1px 10px rgba(255, 255, 255, 0.7);
}

.context-dark .post-line-text,
.bg-gray-700 .post-line-text,
.bg-blue-13 .post-line-text,
.bg-blue-15 .post-line-text,
.bg-primary .post-line-text {
	color: #ffffff;
}

.context-dark .post-line-time,
.bg-gray-700 .post-line-time,
.bg-blue-13 .post-line-time,
.bg-blue-15 .post-line-time,
.bg-primary .post-line-time {
	color: rgba(255, 255, 255, 0.5);
}

.context-dark .post-line-time::after,
.bg-gray-700 .post-line-time::after,
.bg-blue-13 .post-line-time::after,
.bg-blue-15 .post-line-time::after,
.bg-primary .post-line-time::after {
	background: rgba(255, 255, 255, 0.12);
}

.context-dark .post-line-time-day,
.bg-gray-700 .post-line-time-day,
.bg-blue-13 .post-line-time-day,
.bg-blue-15 .post-line-time-day,
.bg-primary .post-line-time-day {
	color: rgba(255, 255, 255, 0.7);
}


/*
*
* Quotes
*/

.quote-light {
	padding: 20px 15px;
	border-top: 1px solid #e0e0e0;
	border-bottom: 1px solid #e0e0e0;
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-weight: 300;
	font-size: 16px;
	line-height: 1.5;
	color: #151515;
	text-align: left;
}

.quote-light p {
	display: inline;
	margin: 0;
}

.quote-light-inner {
	position: relative;
	padding-left: 20px;
}

.quote-light-mark svg {
	fill: #e7e8ee;
}

.quote-light-mark-left {
	position: absolute;
	left: 0;
	top: 0;
	display: block;
}

.quote-light-mark-right {
	display: inline;
	vertical-align: middle;
	margin-left: 5px;
}

.quote-light-mark-right svg {
	transform: scale(-1, -1);
}

*+.quote-light {
	margin-top: 30px;
}

.quote-light+p {
	margin-top: 20px;
}

@media (max-width: 575.98px) {
	.quote-light-mark svg {
		width: 14px;
		height: 13px;
	}
}

@media (min-width: 576px) {
	.quote-light {
		font-size: 20px;
	}
	.quote-light-inner {
		padding-left: 40px;
	}
	.quote-light+p {
		margin-top: 26px;
	}
}

@media (min-width: 768px) {
	.quote-light {
		padding: 20px 30px;
		font-size: 22px;
	}
}

@media (min-width: 992px) {
	.quote-light {
		padding: 20px 45px;
		font-size: 24px;
	}
}

.quote-classic {
	position: relative;
	text-align: left;
	padding: 30px 20px;
	margin-bottom: 15px;
	box-shadow: 0 24px 21px 0 rgba(0, 0, 0, 0.08);
	background: #ffffff;
}

.quote-classic::after {
	content: '';
	position: absolute;
	left: 20px;
	bottom: -17px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 18px 26px 0 0;
	border-color: #ffffff transparent transparent transparent;
	pointer-events: none;
}

.quote-classic-mark {
	fill: #d2d4df;
}

.quote-classic-text {
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	color: #151515;
	letter-spacing: .01em;
}

.quote-classic-meta {
	display: flex;
	align-items: center;
}

.quote-classic-meta>*+* {
	margin-left: 20px;
}

.quote-classic-avatar {
	position: relative;
	display: block;
	border-radius: 50%;
	max-width: 75px;
	flex-shrink: 0;
}

.quote-classic-cite {
	display: block;
	font-weight: 500;
}

.quote-classic-info {
	flex-grow: 1;
}

.quote-classic-info>*+* {
	margin-top: 0;
}

.quote-classic-subtitle {
	letter-spacing: .025em;
	color: #0A043B;
}

*+.quote-classic-text {
	margin-top: 11px;
}

*+.quote-classic-meta {
	margin-top: 20px;
}

@media (max-width: 767.98px) {
	.quote-classic {
		padding: 30px 40px;
		max-width: 400px;
		margin-left: auto;
		margin-right: auto;
	}
	.quote-classic::after {
		left: 40px;
	}
}

@media (min-width: 768px) {
	.quote-classic-text {
		font-size: 15px;
		line-height: 1.625;
	}
}

@media (min-width: 992px) {
	.quote-classic {
		padding-left: 30px;
		padding-right: 35px;
	}
	.quote-classic::after {
		left: 30px;
	}
	.quote-classic-text {
		font-size: 14px;
	}
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	.quote-classic-avatar {
		max-width: 60px;
	}
}

@media (min-width: 1200px) {
	.quote-classic {
		padding: 40px 15px 40px 40px;
	}
	.quote-classic::after {
		left: 40px;
	}
	.quote-classic-text {
		font-size: 16px;
	}
}

.quote-modern {
	max-width: 560px;
	text-align: left;
}

.quote-modern-header {
	margin-bottom: -5px;
	margin-left: -15px;
}

.quote-modern-header:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.quote-modern-header>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 5px;
	margin-left: 15px;
}

.quote-modern-header>* {
	display: inline-block;
	vertical-align: middle;
}

.quote-modern-mark {
	position: absolute;
	top: -10px;
	left: 0;
	z-index: -1;
	fill: #d2d4df;
	opacity: .4;
}

.quote-modern-text {
	position: relative;
	z-index: 1;
	padding-left: 20px;
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	letter-spacing: .025em;
	line-height: 1.625;
	color: #151515;
}

*+.quote-modern-text {
	margin-top: 22px;
}

@media (min-width: 992px) {
	.quote-modern-mark {
		top: 8px;
		opacity: 1;
	}
	.quote-modern-text {
		padding-left: 70px;
		font-size: 15px;
	}
}

@media (min-width: 1200px) {
	.quote-modern-text {
		font-size: 16px;
	}
}

.quote-mary {
	position: relative;
	text-align: left;
	padding: 30px 20px;
	background: #f3f4f9;
}

.quote-mary-mark {
	fill: #d2d4df;
}

.quote-mary-text {
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	color: #151515;
	letter-spacing: .01em;
}

.quote-mary-meta {
	display: flex;
	align-items: center;
}

.quote-mary-meta>*+* {
	margin-left: 20px;
}

.quote-mary-avatar {
	position: relative;
	display: block;
	border-radius: 50%;
	max-width: 75px;
	flex-shrink: 0;
}

.quote-mary-cite {
	display: block;
	font-weight: 500;
}

.quote-mary-info {
	flex-grow: 1;
}

.quote-mary-info>*+* {
	margin-top: 0;
}

.quote-mary-subtitle {
	letter-spacing: .025em;
	color: #0A043B;
}

*+.quote-mary-text {
	margin-top: 11px;
}

*+.quote-mary-meta {
	margin-top: 20px;
}

.context-dark .quote-mary,
.bg-gray-700 .quote-mary,
.bg-blue-13 .quote-mary,
.bg-blue-15 .quote-mary,
.bg-primary .quote-mary {
	background: rgba(255, 255, 255, 0.1);
}

.context-dark .quote-mary-mark,
.bg-gray-700 .quote-mary-mark,
.bg-blue-13 .quote-mary-mark,
.bg-blue-15 .quote-mary-mark,
.bg-primary .quote-mary-mark {
	fill: #ffffff;
}

.context-dark .quote-mary-text,
.bg-gray-700 .quote-mary-text,
.bg-blue-13 .quote-mary-text,
.bg-blue-15 .quote-mary-text,
.bg-primary .quote-mary-text {
	color: #ffffff;
}

.context-dark .quote-mary-subtitle,
.bg-gray-700 .quote-mary-subtitle,
.bg-blue-13 .quote-mary-subtitle,
.bg-blue-15 .quote-mary-subtitle,
.bg-primary .quote-mary-subtitle {
	color: rgba(255, 255, 255, 0.6);
}

@media (max-width: 767.98px) {
	.quote-mary {
		padding: 30px 40px;
	}
}

@media (min-width: 576px) {
	.quote-mary-main {
		display: flex;
		align-items: flex-start;
	}
	.quote-mary-main>*+* {
		margin-top: 0;
		margin-left: 25px;
	}
	.quote-mary-mark {
		position: relative;
		top: 8px;
		flex-shrink: 0;
	}
}

@media (min-width: 768px) {
	.quote-mary-text {
		font-size: 15px;
		line-height: 1.625;
	}
}

@media (min-width: 992px) {
	.quote-mary {
		padding-left: 30px;
		padding-right: 35px;
	}
	.quote-mary-text {
		font-size: 14px;
	}
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	.quote-mary-avatar {
		max-width: 60px;
	}
}

@media (min-width: 1200px) {
	.quote-mary {
		padding: 50px 25px 40px 44px;
	}
	.quote-mary-text {
		font-size: 16px;
	}
	*+.quote-mary-meta {
		margin-top: 30px;
	}
}


/*
*
* Thumbnails
*/

.figure-light figcaption {
	padding-top: 10px;
	color: #b7b7b7;
}

.figure-responsive img {
	min-width: 100%;
}

.about-figure-light figcaption {
	padding-top: 10px;
	color: #b7b7b7;
}

.about-figure-responsive img {
	min-width: 100%;
}


@media (min-width: 992px) {
	.col-lg-6.height-fill .figure-responsive {
		position: relative;
		overflow: hidden;
		display: flex;
		justify-content: center;
	}
	.col-lg-6.height-fill .figure-responsive img {
		height: auto;
		width: auto;
		flex-shrink: 0;
		max-width: none;
	}

	.col-lg-6.height-fill .about-figure-responsive {
		position: relative;
		overflow: hidden;
		/* display: flex; */
		justify-content: center;
	}
	.col-lg-6.height-fill .about-figure-responsive img {
		/* height: auto; */
		/* width: auto; */
		flex-shrink: 0;
		max-width: none;
	}
}

.thumbnail-classic {
	position: relative;
	overflow: hidden;
	display: flex;
}

.thumbnail-classic-dummy,
.thumbnail-classic-caption {
	width: 100%;
	flex-shrink: 0;
}

.thumbnail-classic-dummy {
	visibility: hidden;
	opacity: 0;
}

.thumbnail-classic-dummy::before {
	content: '';
	display: block;
	padding-bottom: 72.01087%;
}

.thumbnail-classic-figure {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.thumbnail-classic-image {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	min-height: 100%;
	min-width: 100%;
	width: auto;
	height: auto;
	max-width: none;
}

@supports (object-fit: cover) {
	.thumbnail-classic-image {
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		transform: none;
		object-fit: cover;
		object-position: center center;
	}
}

.thumbnail-classic-caption {
	position: relative;
	z-index: 1;
	align-self: flex-end;
	padding: 20px;
	text-align: center;
	background: rgba(16, 135, 235, 0.53);
}

.thumbnail-classic-title {
	color: #ffffff;
}

@media (max-width: 575.98px) {
	.thumbnail-classic {
		max-width: 370px;
		margin-left: auto;
		margin-right: auto;
	}
}

html:not(.tablet):not(.mobile) .thumbnail-classic-figure {
	transition: .44s;
}

html:not(.tablet):not(.mobile) .thumbnail-classic-caption {
	align-self: stretch;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	opacity: 0;
	visibility: hidden;
	transition: .22s;
}

html:not(.tablet):not(.mobile) .thumbnail-classic:hover .thumbnail-classic-figure {
	transform: scale3d(1.03, 1.03, 1.03);
}

.thumbnail-chico {
	display: block;
	position: relative;
	overflow: hidden;
	text-align: center;
	color: #ffffff;
}

.thumbnail-chico-image {
	width: 100%;
}

.thumbnail-chico-caption {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 30px;
	pointer-events: none;
	background: rgba(37, 45, 47, 0.3);
}

.thumbnail-chico-caption>* {
	max-width: 100%;
}

.thumbnail-chico-caption::before {
	content: '';
	position: absolute;
	top: 18px;
	right: 18px;
	bottom: 18px;
	left: 18px;
	border: 1px solid rgba(255, 255, 255, 0.62);
}

.thumbnail-chico-title {
	color: #ffffff;
	pointer-events: auto;
}

@media (min-width: 992px) {
	html:not(.tablet):not(.mobile) .thumbnail-chico {
		will-change: transform;
	}
	html:not(.tablet):not(.mobile) .thumbnail-chico-image {
		transition: .32s;
		will-change: transform;
		transform: scale3d(1, 1, 1);
	}
	html:not(.tablet):not(.mobile) .thumbnail-chico-caption {
		transition: .25s;
		opacity: 0;
		visibility: hidden;
		will-change: opacity;
	}
	html:not(.tablet):not(.mobile) .thumbnail-chico-caption .thumbnail-chico-title {
		transition: .3s ease;
		transform: scale3d(0.9, 0.9, 0.9);
	}
	html:not(.tablet):not(.mobile) .thumbnail-chico-caption::before {
		transition: .33s;
		transform: scale3d(1.05, 1.05, 1.05);
	}
	html:not(.tablet):not(.mobile) .thumbnail-chico:hover .thumbnail-chico-caption {
		opacity: 1;
		visibility: visible;
	}
	html:not(.tablet):not(.mobile) .thumbnail-chico:hover .thumbnail-chico-caption .thumbnail-chico-title,
	html:not(.tablet):not(.mobile) .thumbnail-chico:hover .thumbnail-chico-caption::before {
		transform: scale3d(1, 1, 1);
	}
	html:not(.tablet):not(.mobile) .thumbnail-chico:hover .thumbnail-chico-image {
		transform: scale3d(1.03, 1.03, 1.03);
	}
}


/*
*
* Breadcrumbs
*/

.breadcrumbs-custom+.bg-gray-100 {
	border-top: 1px solid #d6d6d6;
}

.breadcrumbs-custom-main {
	position: relative;
	padding: 5px 0;
	/* padding: 40px 0; */
	background-position: center center;
	background-size: cover;
	font-size: 28px !important;
	/* margin-top: 110px; */
}

.breadcrumbs-custom-main[style*='background-image']::before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	pointer-events: none;
	z-index: 1;
	background: rgba(54, 67, 82, 0.45);
}

.breadcrumbs-custom-main>* {
	position: relative;
	z-index: 2;
}

.breadcrumbs-custom-aside {
	padding: 15px 0;
	background: #f3f4f9;
}

.breadcrumbs-custom-path {
	margin-left: -15px;
	margin-right: -15px;
}

.breadcrumbs-custom-path>* {
	padding-left: 15px;
	padding-right: 15px;
}

.breadcrumbs-custom-path a {
	display: inline;
	vertical-align: middle;
}

.breadcrumbs-custom-path a,
.breadcrumbs-custom-path a:active,
.breadcrumbs-custom-path a:focus {
	color: #0A043B;
}

.breadcrumbs-custom-path li {
	position: relative;
	display: inline-block;
	line-height: 1.2;
	letter-spacing: .05em;
	vertical-align: middle;
}

.breadcrumbs-custom-path li::after {
	content: '';
	position: absolute;
	width: 1px;
	height: 100%;
	right: 0;
	top: 50%;
	transform: translate3d(0, -50%, 0);
	background: rgb(150, 145, 171);
}

.breadcrumbs-custom-path li:last-child:after {
	display: none;
}

.breadcrumbs-custom-path a:hover,
.breadcrumbs-custom-path li.active {
	color: #9a9a9a;
}

@media (min-width: 576px) {
	.breadcrumbs-custom-aside {
		padding: 23px 0;
	}
}

@media (min-width: 768px) {
	.breadcrumbs-custom-main {
		margin-top: 110px;
	}
}


/*
*
* Pagination custom
*/

.pagination {
	display: inline-flex;
	flex-wrap: wrap;
	font-size: 0;
	line-height: 0;
	margin-bottom: -6px;
	margin-left: -6px;
}

.pagination:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.pagination>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 6px;
	margin-left: 6px;
}

.page-item {
	display: inline-flex;
	vertical-align: middle;
	text-align: center;
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: .05em;
	text-transform: uppercase;
}

.page-link {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 36px;
	padding: 5px 12px;
	border: 1px solid #e0e0e0;
	line-height: 2;
	background-color: #ffffff;
	color: #000000;
	transition: .15s;
}

.page-link:hover,
.page-link:focus,
.page-link:active {
	color: #ffffff;
	background-color: #0A043B;
	border-color: #0A043B;
	box-shadow: none;
}

.page-item.active>.page-link,
.page-item.active>.page-link:hover,
.page-item.active>.page-link:focus,
.page-item.active>.page-link:active {
	color: #ffffff;
	background-color: #0A043B;
	border-color: #0A043B;
}

.page-item.disabled>.page-link,
.page-item.disabled>.page-link:hover,
.page-item.disabled>.page-link:focus,
.page-item.disabled>.page-link:active {
	color: #b7b7b7;
	background-color: #e0e0e0;
	border-color: #e0e0e0;
}

.page-item-control .page-link {
	padding-left: 16px;
	padding-right: 16px;
}

*+.pagination-outer {
	margin-top: 30px;
}

@media (max-width: 767.98px) {
	.page-item-control {
		font-size: 0;
	}
	.page-item-control .page-link {
		padding-left: 10px;
		padding-right: 10px;
	}
	.page-item-control .page-link::before {
		font-family: "Material Design Icons";
		font-size: 18px;
		line-height: 1;
	}
	.page-item-control:first-child .page-link::before {
		content: '\f237';
	}
	.page-item-control:last-child .page-link::before {
		content: '\f238';
	}
}

@media (min-width: 768px) {
	*+.pagination-outer {
		margin-top: 50px;
	}
}


/*
*
* Snackbars
*/

.snackbars {
	padding: 9px 16px;
	margin-left: auto;
	margin-right: auto;
	color: #ffffff;
	text-align: left;
	background-color: #ca5217;
	border-radius: 0;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
	font-size: 14px;
}

.snackbars .icon-xxs {
	font-size: 18px;
}

.snackbars p span:last-child {
	padding-left: 14px;
}

.snackbars-left {
	display: inline-block;
	margin-bottom: 0;
}

.snackbars-right {
	display: inline-block;
	float: right;
	text-transform: uppercase;
}

.snackbars-right:hover {
	text-decoration: underline;
}

@media (min-width: 576px) {
	.snackbars {
		max-width: 540px;
		padding: 12px 15px;
		font-size: 15px;
	}
}


/*
*
* Footers
*/

.footer-classic p {
	color: #56616c;
}

.footer-classic .list-marked-1 {
	color: #ffffff;
}

.footer-classic .list-marked-1>li::before {
	border-color: transparent transparent transparent #4c5762;
}

.footer-classic-main {
	padding: 35px 0;
	background: #303841;
}

.footer-classic-main a {
	color: #ffffff;
}

.footer-classic-main a:hover {
	color: #0A043B;
}

.footer-classic-aside {
	padding: 25px 0;
	background: #29323a;
	text-align: center;
}

.footer-classic-aside * {
	color: #56616c;
}

.footer-classic-aside .rights a:hover {
	color: #0A043B;
}

.footer-classic-title {
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 16px;
	line-height: 1.4;
	letter-spacing: .025em;
}

p.footer-classic-title {
	color: #ffffff;
}

.footer-classic-divider {
	font-size: 0;
	line-height: 0;
	padding: 0 3px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

.footer-classic-divider::before {
	content: '';
	display: inline-block;
	height: 2px;
	width: 40px;
	margin-bottom: -1px;
	background: #0A043B;
}

*+.footer-classic-divider {
	margin-top: 16px;
}

.footer-classic .footer-classic-divider+* {
	margin-top: 16px;
}

@media (min-width: 768px) {
	.footer-classic-main {
		padding: 60px 0 70px;
	}
	.footer-classic-title {
		font-size: 20px;
	}
	*+.footer-classic-divider {
		margin-top: 20px;
	}
	.footer-classic .footer-classic-divider+* {
		margin-top: 24px;
	}
}

.footer-modern p {
	color: #56616c;
}

.footer-modern .list-marked-1 {
	color: #ffffff;
}

.footer-modern .list-marked-1>li::before {
	border-color: transparent transparent transparent #4c5762;
}

.footer-modern-main {
	padding: 35px 0;
	background: #303841;
}

.footer-modern-main a {
	color: #ffffff;
}

.footer-modern-main a:hover {
	color: #0A043B;
}

.footer-modern-title {
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 16px;
	line-height: 1.4;
	letter-spacing: .025em;
}

p.footer-modern-title {
	color: #ffffff;
}

.footer-modern-divider {
	font-size: 0;
	line-height: 0;
	padding: 0 3px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

.footer-modern-aside {
	padding: 25px 0;
	background: #29323a;
}

.footer-modern-aside * {
	color: #56616c;
}

.footer-modern-aside .rights a:hover {
	color: #0A043B;
}

.footer-modern-aside-inner {
	margin-bottom: -10px;
	text-align: center;
}

.footer-modern-aside-inner>* {
	margin-bottom: 10px;
}

*+.footer-modern-divider {
	margin-top: 16px;
}

.footer-modern .footer-modern-divider+* {
	margin-top: 16px;
}

@media (min-width: 576px) {
	.footer-modern-aside-inner {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		margin-left: -20px;
	}
	.footer-modern-aside-inner>* {
		margin-left: 20px;
	}
}

@media (min-width: 768px) {
	.footer-modern-main {
		padding: 70px 0 95px;
	}
	*+.footer-modern-divider {
		margin-top: 20px;
	}
	.footer-modern .footer-modern-divider+* {
		margin-top: 24px;
	}
}

@media (min-width: 1200px) {
	.footer-modern-title {
		font-size: 20px;
	}
}

.footer-creative .list-marked-1 {
	color: #ffffff;
}

.footer-creative .list-marked-1>li::before {
	border-color: transparent transparent transparent #ffffff;
}

.footer-creative .list-marked-1 a:hover,
.footer-creative .list-icons a:hover {
	color: #ffffff;
	text-shadow: 1px 1px 10px rgba(255, 255, 255, 0.7);
}

.footer-creative .list-marked-1 .icon,
.footer-creative .list-icons .icon {
	text-shadow: none;
}

.footer-creative .post-line-time,
.footer-creative .post-line-time-day {
	color: #ffffff;
}

.footer-creative-main {
	padding: 25px 0;
	background: #0A043B;
}

.footer-creative-main a {
	color: #ffffff;
}

.footer-creative-title {
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
	text-transform: uppercase;
	letter-spacing: .05em;
	text-align: center;
}

p.footer-creative-title {
	color: #ffffff;
}

.footer-creative-divider {
	font-size: 0;
	line-height: 0;
	padding: 0 3px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

.footer-creative-aside {
	padding: 15px 0;
	/* background: #0076d1; */
	background: #ffffff;
	text-align: center;
}

.footer-creative-aside * {
	color: rgba(255, 255, 255, 0.4);
}

.footer-creative-aside .rights a:hover {
	color: #ffffff;
}

*+.footer-creative-divider {
	margin-top: 16px;
}

.footer-creative .footer-creative-divider+* {
	margin-top: 16px;
}

@media (min-width: 768px) {
	.footer-creative-main {
		/* padding: 70px 0 95px; */
	}
	*+.footer-creative-divider {
		margin-top: 20px;
	}
	.footer-creative .footer-creative-divider+* {
		margin-top: 24px;
	}
}

@media (min-width: 1200px) {
	.footer-creative-title {
		font-size: 16px;
	}
}


/*
*
* Layouts
*/

.layout-bordered {
	text-align: center;
}

.layout-bordered-item {
	padding: 20px 0;
	border: 0 solid #e0e0e0;
}

.layout-bordered-item-inner {
	padding: 0 15px;
	margin-left: auto;
	margin-right: auto;
	text-align: left;
}

.layout-bordered-item-inner>*+* {
	margin-top: 10px;
}

*+.layout-bordered {
	margin-top: 40px;
}

.layout-bordered+.row {
	margin-top: 40px;
}

@media (max-width: 767.98px) {
	.layout-bordered-item {
		padding: 30px 0;
	}
	.layout-bordered-item:first-child {
		padding-top: 0;
	}
	.layout-bordered-item:last-child {
		padding-bottom: 0;
	}
	.layout-bordered-item .icon.unit-left {
		min-width: 30px;
	}
	.layout-bordered-item .unit-left.icon-lg {
		font-size: 20px;
	}
	.layout-bordered-item .unit-left.icon-xl {
		font-size: 25px;
	}
	.layout-bordered-item+.layout-bordered-item {
		border-top-width: 1px;
	}
}

@media (min-width: 768px) {
	.layout-bordered {
		display: flex;
	}
	.layout-bordered-item {
		padding: 0;
		flex-grow: 1;
	}
	.layout-bordered-item-inner>*+* {
		margin-top: 15px;
	}
	.layout-bordered-item+.layout-bordered-item {
		border-top-width: 0;
	}
	*+.layout-bordered {
		margin-top: 50px;
	}
	.layout-bordered+.row {
		margin-top: 50px;
	}
}

@media (min-width: 768px) {
	.layout-bordered-item {
		flex-basis: 33.333%;
	}
	.layout-bordered-item:last-child {
		border-right-width: 0;
	}
	.layout-bordered+.row {
		margin-top: 65px;
	}
}

@media (min-width: 992px) {
	.layout-bordered-item {
		border-right-width: 1px;
	}
	.layout-bordered-item-inner {
		max-width: 250px;
	}
}

.blog-layout-aside {
	margin-bottom: -35px;
}

.blog-layout-aside>* {
	margin-bottom: 35px;
}

.blog-layout-aside *+.rd-form {
	margin-top: 20px;
}

.blog-layout-aside-item .blog-layout-title+* {
	margin-top: 15px;
}

.blog-layout-aside-item .blog-layout-title+p {
	margin-top: 10px;
}

.blog-layout-title {
	font: 600 13px/18px "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", rubiklight, "Helvetica Neue", Arial, sans-serif;
	letter-spacing: .1em;
	text-transform: uppercase;
	line-height: 1.4;
	color: #151515;
}

*+.blog-layout-aside {
	margin-top: 55px;
}

@media (min-width: 768px) {
	*+.blog-layout-aside {
		margin-top: 70px;
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.blog-layout-aside {
		columns: 2;
		column-gap: 30px;
		break-inside: avoid;
	}
	.blog-layout-aside-item {
		display: inline-block;
		width: 100%;
	}
	*+.blog-layout-aside-item {
		margin-top: 0;
	}
}

@media (min-width: 992px) {
	.blog-layout {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		margin-left: -30px;
	}
	.blog-layout>* {
		margin-left: 30px;
	}
	.blog-layout-main {
		flex-grow: 1;
	}
	.blog-layout-aside {
		flex-shrink: 0;
		width: 26.5%;
		margin-bottom: -45px;
	}
	.blog-layout-aside>* {
		margin-bottom: 45px;
	}
	*+.blog-layout-aside {
		margin-top: 0;
	}
}

@media (min-width: 1200px) {
	.blog-layout {
		margin-left: -70px;
	}
	.blog-layout>* {
		margin-left: 70px;
	}
}

.layout-info {
	padding-bottom: 20px;
	border-bottom: 1px solid rgb(150, 145, 171);
}

.layout-info>*+* {
	margin-top: 25PX;
}

.layout-info-aside {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: -8px;
	margin-left: -20px;
}

.layout-info-aside:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.layout-info-aside>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 8px;
	margin-left: 20px;
}

.layout-info-aside>*+* {
	margin-top: 0;
}

.layout-info-aside-item .link {
	letter-spacing: .05em;
}

@media (min-width: 576px) {
	.layout-info {
		display: flex;
	}
	.layout-info>*+* {
		position: relative;
		margin-top: 0;
	}
	.layout-info>*+*::before {
		content: '';
		position: absolute;
		left: 0;
		top: 50%;
		transform: translate3d(0, -50%, 0);
		height: 50%;
		border-right: 1px solid rgb(150, 145, 171);
	}
	.layout-info-main,
	.layout-info-aside {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.layout-info-main {
		min-width: 64%;
	}
	.layout-info-aside {
		min-width: 36%;
		align-items: center;
	}
}

@media (min-width: 768px) {
	.layout-info-main {
		min-width: 52%;
	}
	.layout-info-aside {
		min-width: 36%;
	}
}

.layout-details {
	padding-bottom: 20px;
	border-bottom: 1px solid rgb(150, 145, 171);
}

.layout-details>*+* {
	margin-top: 25px;
}

.layout-1 {
	padding: 18px 25px;
	background: #f3f4f9;
}

.layout-1-inner {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	margin-bottom: -15px;
	margin-left: -15px;
}

.layout-1-inner:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.layout-1-inner>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 15px;
	margin-left: 15px;
}

.layout-1-inner>*+* {
	margin-top: 0;
}

*+.layout-1 {
	margin-top: 25px;
}

@media (min-width: 768px) {
	*+.layout-1 {
		margin-top: 30px;
	}
}

.layout-2 {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	margin-bottom: -65px;
	text-align: center;
	background-color: #FAFBFD;
}

.layout-2>* {
	margin-bottom: 35px;
}

.layout-2>* {
	flex-shrink: 0;
	max-width: 100%;
}

@media (min-width: 768px) {
	.layout-2 {
		flex-wrap: nowrap;
		justify-content: space-between;
		/* margin-left: -135px;
		margin-right: -100px; */
		margin-top: -30px;
		text-align: left;
	}
	.layout-2>* {
		margin-left: 35px;
	}
	.layout-2>* {
		flex-shrink: 1;
	}
	.layout-2-item-main {
		/* min-width: 425px; */
	}
}

.layout-3 {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	margin-bottom: -10px;
	margin-left: -10px;
}

.layout-3:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.layout-3>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 10px;
	margin-left: 10px;
}

.grid-1-outer {
	position: relative;
	overflow: hidden;
	border: 1px solid transparent;
}

.grid-1 {
	display: flex;
	flex-wrap: wrap;
	margin: -2px;
	width: calc(100% + 6px);
}

.grid-1-item {
	display: flex;
	width: 50%;
	border: 1px solid #d6d6d6;
	margin: -1px -1px 0 0;
}

.grid-1-item-link {
	display: flex;
	padding: 23px 20px;
	min-height: 100px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	transition: .6s;
}

.grid-1-item-link:hover {
	background: rgba(0, 0, 0, 0.03);
}

.grid-1-item-link img {
	max-width: 90%;
}

*+.grid-1-outer {
	margin-top: 30px;
}

@media (min-width: 576px) {
	.grid-1-item {
		width: 33.33333%;
	}
}

@media (min-width: 768px) {
	.grid-1-item-link {
		padding: 40px 20px 40px 30px;
	}
}

@media (min-width: 992px) {
	.grid-1-item {
		width: 25%;
	}
	*+.grid-1-outer {
		margin-top: 50px;
	}
}

@media (min-width: 1200px) {
	.grid-1-item-link {
		min-height: 170px;
	}
}


/*
*
* Pricing Tables
*/

.pt-title {
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 16px;
	line-height: 1.4;
	font-weight: 600;
	letter-spacing: .025em;
	text-transform: uppercase;
	color: #151515;
}

.pt-price {
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 36px;
	line-height: 1;
	font-weight: 300;
	color: #151515;
}

.pt-price-small {
	position: relative;
	top: .25em;
	vertical-align: top;
	font-size: 0.47143em;
}

.context-dark .pt-title,
.bg-gray-700 .pt-title,
.bg-blue-13 .pt-title,
.bg-blue-15 .pt-title,
.bg-primary .pt-title,
.context-dark .pt-price,
.bg-gray-700 .pt-price,
.bg-blue-13 .pt-price,
.bg-blue-15 .pt-price,
.bg-primary .pt-price {
	color: #ffffff;
}

*+.pt-price {
	margin-top: 16px;
}

@media (max-width: 767.98px) {
	.pt {
		max-width: 370px;
		margin-left: auto;
		margin-right: auto;
	}
}

@media (min-width: 768px) {
	.pt-price {
		font-size: 56px;
	}
}

@media (min-width: 1200px) {
	.pt-price {
		font-size: 70px;
	}
}

.pt-classic {
	border: 1px solid #e0e0e0;
	text-align: center;
}

.pt-classic-header {
	padding: 30px 20px;
	background: #f3f4f9;
}

.pt-classic-item {
	margin-bottom: -15px;
	margin-left: -15px;
}

.pt-classic-item:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.pt-classic-item>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 15px;
	margin-left: 15px;
}

.pt-classic-item>* {
	display: inline-block;
	vertical-align: middle;
}

.pt-classic-main {
	padding: 20px;
}

.pt-classic-main .button {
	padding-top: 18px;
	padding-bottom: 18px;
}

.pt-classic-features>li+li {
	margin-top: 12px;
}

@media (min-width: 1200px) {
	.pt-classic-header {
		padding: 38px;
	}
	.pt-classic-main {
		padding: 30px 40px 50px;
	}
}

.pt-creative {
	padding: 30px 20px;
	text-align: center;
	border: 4px solid rgba(16, 135, 235, 0.33);
}

.pt-creative .button {
	padding-top: 18px;
	padding-bottom: 18px;
}

.pt-creative .button-primary-outline:hover {
	box-shadow: 0 7px 18px 0 rgba(0, 0, 0, 0.18);
}

.pt-creative_preferred {
	border-color: #0A043B;
}

.pt-creative-features {
	text-align: center;
}

.pt-creative-features .list {
	display: inline-block;
}

.bg-primary .pt-creative {
	border-color: rgba(255, 255, 255, 0.33);
}

.bg-primary .pt-creative_preferred {
	border-color: #ffffff;
}

*+.pt-creative-group {
	margin-top: 40px;
}

*+.pt-creative {
	margin-top: 40px;
}

*+.pt-creative-features {
	margin-top: 15px;
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.pt-creative-group {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-bottom: -30px;
		margin-left: -30px;
	}
	.pt-creative-group:empty {
		margin-bottom: 0;
		margin-left: 0;
	}
	.pt-creative-group>* {
		display: inline-block;
		margin-top: 0;
		margin-bottom: 30px;
		margin-left: 30px;
	}
	.pt-creative-group .pt-creative {
		width: calc(50% - 30px);
	}
}

@media (min-width: 992px) {
	.pt-creative-group {
		display: flex;
		max-width: 1100px;
		margin-left: auto;
		margin-right: auto;
	}
	.pt-creative-group .pt-creative {
		width: calc(33.3% + 4px);
	}
	.pt-creative-group .pt.pt-creative {
		margin-top: 10px;
	}
	.pt-creative-group .pt.pt-creative_preferred {
		width: 34%;
		border-width: 6px;
		margin: 0 -6px -10px;
	}
	.pt-creative-group *+.pt-creative {
		margin-top: 0;
	}
	.pt-creative {
		padding: 30px 25px 40px;
	}
	.pt-creative *+.button {
		margin-top: 30px;
	}
	.pt-creative .pt-title+.pt-price {
		margin-top: 23px;
	}
	.pt-creative_preferred {
		padding: 40px 25px 30px;
	}
	*+.pt-creative-features {
		margin-top: 35px;
	}
}

@media (min-width: 1200px) {
	.pt-creative {
		padding: 40px 25px 50px;
	}
	.pt-creative_preferred {
		padding: 50px 25px 35px;
	}
}

.pt-modern {
	padding: 30px 20px;
	text-align: center;
	border: 1px solid #e0e0e0;
}

.pt-modern .button {
	padding-top: 18px;
	padding-bottom: 18px;
}

.pt-modern-item {
	margin-bottom: -15px;
	margin-left: -15px;
}

.pt-modern-item:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.pt-modern-item>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 15px;
	margin-left: 15px;
}

.pt-modern-item>* {
	display: inline-block;
	vertical-align: middle;
}

.pt-modern-features {
	text-align: center;
}

.pt-modern-features .list {
	position: relative;
	left: -10px;
	display: inline-block;
}

*+.pt-modern-group {
	margin-top: 40px;
}

*+.pt-modern {
	margin-top: 40px;
}

*+.pt-modern-features {
	margin-top: 15px;
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.pt-modern-group {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-bottom: -30px;
		margin-left: -30px;
	}
	.pt-modern-group:empty {
		margin-bottom: 0;
		margin-left: 0;
	}
	.pt-modern-group>* {
		display: inline-block;
		margin-top: 0;
		margin-bottom: 30px;
		margin-left: 30px;
	}
	.pt-modern-group .pt-modern {
		width: calc(50% - 30px);
	}
}

@media (min-width: 992px) {
	.pt-modern-group {
		display: flex;
		padding: 30px 0;
		border: 1px solid #e0e0e0;
	}
	.pt-modern-group .pt-modern {
		width: 33.3%;
		padding-top: 0;
		padding-bottom: 0;
		border-width: 0 1px 0 0;
	}
	.pt-modern-group .pt-modern:last-child {
		border-width: 0;
	}
	.pt-modern-group *+.pt-modern {
		margin-top: 0;
	}
	.pt-modern-group *+.pt-price {
		margin-top: 22px;
	}
	.pt-modern {
		padding: 30px 25px 40px;
	}
	.pt-modern *+.button {
		margin-top: 30px;
	}
	.pt-modern .pt-title+.pt-price {
		margin-top: 23px;
	}
	*+.pt-modern-features {
		margin-top: 35px;
	}
}

@media (min-width: 1200px) {
	.pt-modern-group {
		padding: 55px 0;
	}
	.pt-modern {
		padding: 40px 25px 50px;
	}
}


/*
*
* Badge Custom
*/

.badge {
	display: inline-block;
	padding: 3px 6px 4px;
	font: 500 12px/12px 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	text-transform: uppercase;
	letter-spacing: .1em;
	color: #ffffff;
	background: #0A043B;
	border-radius: 0;
	vertical-align: middle;
}

.badge-secondary {
	/* background: #ca5217; */
	background: #494970;
}

.badge-tertiary {
	/* background: #7959b6; */
	background: #9798AD;
}

.badge-blue-11 {
	background: #437685;
}

.badge-danger {
	background: #d2691e;
}

.badge-brand-color {
	background: #0A043B;
}

.badge-modern {
	position: relative;
	z-index: 1;
	padding-left: 15px;
	overflow: hidden;
	background-color: transparent;
}

.badge-modern::before {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 10px;
	z-index: -1;
	background: #0A043B;
	pointer-events: none;
}

.badge-modern span {
	position: absolute;
	top: 10px;
	bottom: 0;
	right: 0;
	left: 0;
	transform-origin: 0 50%;
	transform: scale(1, 1.3);
	z-index: -1;
	pointer-events: none;
}

.badge-modern span::after {
	content: '';
	position: absolute;
	bottom: 0;
	left: -2px;
	width: 100px;
	height: 100px;
	transform-origin: 12px 100%;
	transform: rotate(45deg);
	background: #0A043B;
	z-index: -2;
}

.badge-modern.badge-secondary::before,
.badge-modern.badge-secondary span:after {
	background: #ca5217;
}

.badge-modern.badge-tertiary::before,
.badge-modern.badge-tertiary span:after {
	background: #7959b6;
}


/*
*
* Alert Custom
*/

.alert {
	background: rgba(16, 135, 235, 0.1);
	border: 1px solid #e0e0e0;
	border-radius: 0;
}

.alert-type-1 {
	padding: 20px 20px 20px 30px;
}

.alert-type-1 .alert-inner {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: -20px;
	margin-left: -20px;
}

.alert-type-1 .alert-inner:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.alert-type-1 .alert-inner>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 20px;
	margin-left: 20px;
}

.alert-type-1 .alert-main {
	max-width: 595px;
	letter-spacing: .025em;
	color: #151515;
}

@media (min-width: 768px) {
	.alert-type-1 .alert-main {
		font-size: 16px;
		line-height: 1.5;
	}
}

.comment {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	text-align: left;
	margin-bottom: -10px;
	margin-left: -20px;
}

.comment:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.comment>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 10px;
	margin-left: 20px;
}

.comment-group .comment-group {
	padding-left: 20px;
}

.comment-group .comment-group .comment-group {
	padding-left: 10px;
}

.comment-main {
	width: 100%;
}

.comment-image {
	max-width: 100px;
	border-radius: 50%;
}

.comment-title {
	font: 500 16px/24px "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", rubiklight, "Helvetica Neue", Arial, sans-serif;
	letter-spacing: .05em;
	color: #151515;
}

.comment-time {
	display: block;
	font-weight: 500;
	color: #151515;
}

.comment-list {
	transform: translate3d(0, -10px, 0);
	margin-bottom: -10px;
	margin-left: -10px;
	margin-right: -10px;
	font-size: 13px;
	font-weight: 500;
	color: #151515;
}

.comment-list>* {
	margin-top: 10px;
	padding-left: 10px;
	padding-right: 10px;
}

.comment-list>li {
	display: inline-block;
	vertical-align: middle;
}

.comment-list>li * {
	vertical-align: middle;
}

.comment-list>li *+* {
	margin-left: 8px;
}

.comment-list>li>a {
	transition: color .22s;
}

.comment-list>li>a:hover,
.comment-list>li>a:hover .icon {
	color: #0A043B;
}

.comment-list .icon {
	margin-top: 1px;
	font-size: 16px;
	color: #9fa3a7;
	transition: color .22s;
}

.comment-list .mdi-comment-outline {
	margin-top: 2px;
}

.comment-link {
	letter-spacing: .1em;
	font-weight: 500;
	color: #151515;
}

.comment-group {
	position: relative;
	padding-bottom: 30px;
}

.comment-group::before {
	content: '';
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	border-bottom: 1px solid #e0e0e0;
}

.comment-group .comment-group {
	padding-bottom: 0;
}

.comment-group .comment-group::before {
	display: none;
}

.comment-group .comment-group .comment img {
	max-width: 52px;
}

*+.comment-text {
	margin-top: 8px;
}

*+.comment-footer {
	margin-top: 14px;
}

*+.comment-group {
	margin-top: 30px;
}

.comment+* {
	margin-top: 25px;
}

.comment-group+.comment-group {
	margin-top: 30px;
}

@media (min-width: 576px) {
	.comment-header {
		flex-grow: 1;
	}
	.comment-header-inner {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		transform: translate3d(0, -10px, 0);
		margin-bottom: -10px;
		margin-left: -10px;
		margin-right: -10px;
	}
	.comment-header-inner>* {
		margin-top: 10px;
		padding-left: 10px;
		padding-right: 10px;
	}
}

@media (min-width: 768px) {
	.comment {
		position: relative;
		flex-direction: column;
		padding-left: 95px;
		margin-bottom: -15px;
		margin-left: 0;
	}
	.comment:empty {
		margin-bottom: 0;
		margin-left: 0;
	}
	.comment>* {
		display: inline-block;
		margin-top: 0;
		margin-bottom: 15px;
		margin-left: 0;
	}
	.comment .comment-aside {
		position: absolute;
		top: 0;
		left: 0;
	}
	.comment p {
		letter-spacing: .05em;
	}
	.comment-header {
		width: 100%;
	}
	.comment-group {
		max-width: 850px;
	}
	.comment-group::before {
		left: 100px;
	}
	.comment-group .comment-group {
		padding-left: 100px;
	}
	.comment-group .comment-group .comment {
		padding-left: 75px;
	}
	.comment-group .comment-group .comment .comment-aside {
		margin-top: 10px;
	}
	.comment-group .comment-group .comment-group {
		padding-left: 50px;
	}
	.comment-header {
		padding-right: 20px;
	}
	.comment-header>*+* {
		margin-left: 20px;
	}
	.comment-list {
		transform: translate3d(0, -10px, 0);
		margin-bottom: -10px;
		margin-left: -13px;
		margin-right: -13px;
	}
	.comment-list>* {
		margin-top: 10px;
		padding-left: 13px;
		padding-right: 13px;
	}
}

@media (min-width: 1200px) {
	.comment-group {
		max-width: 850px;
		padding-bottom: 40px;
	}
	.comment-group .comment-group {
		padding-left: 135px;
	}
	.comment-group .comment-group .comment-group {
		padding-left: 50px;
	}
	.comment-group+.comment-group {
		margin-top: 45px;
	}
}

.comment-box {
	max-width: 830px;
}

.comment-box .unit-body {
	flex-grow: 1;
}

.comment-box textarea.form-input {
	height: 145px;
}

.comment-box *+.rd-mailform {
	margin-top: 20px;
}

.comment-box-image {
	border-radius: 50%;
}

*+.comment-box {
	margin-top: 30px;
}

@media (min-width: 768px) {
	*+.comment-box {
		margin-top: 42px;
	}
}


/*
*
* Profiles
*/

.profile-minimal {
	padding: 16px;
	border: 1px solid #e0e0e0;
}

.profile-minimal-inner {
	margin-bottom: -16px;
	margin-left: -16px;
}

.profile-minimal-inner:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.profile-minimal-inner>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 16px;
	margin-left: 16px;
}

.profile-minimal-main-outer {
	flex-grow: 1;
}

.profile-minimal-main {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: -16px;
	margin-left: -16px;
}

.profile-minimal-main:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.profile-minimal-main>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 16px;
	margin-left: 16px;
}

.profile-minimal-main p {
	letter-spacing: .025em;
}

.profile-minimal-image {
	max-width: 70px;
	flex-shrink: 0;
}

.profile-minimal-main-info>*+* {
	margin-top: 4px;
}

.profile-minimal-position {
	color: #0A043B;
}

p.profile-minimal-position {
	letter-spacing: .05em;
}

.profile-minimal-meta {
	min-width: 30%;
	letter-spacing: .05em;
	white-space: nowrap;
}

.profile-minimal-meta>* {
	display: inline-block;
	vertical-align: top;
	white-space: normal;
}

.profile-minimal-meta>*+* {
	margin-left: 4px;
}

.profile-minimal-meta .icon {
	position: relative;
	top: 2px;
	font-size: 16px;
	color: #0A043B;
}

.profile-minimal+.profile-minimal {
	border-top: 0;
	margin-top: 0;
}

*+.profile-minimal-position {
	margin-top: 0;
}

@media (min-width: 576px) {
	.profile-minimal-meta {
		max-width: 200px;
	}
}

@media (min-width: 576px) {
	.profile-minimal-inner {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
	}
}

@media (min-width: 768px) {
	.profile-minimal-image {
		min-width: 101px;
	}
}

.profile-classic {
	display: block;
	max-width: 265px;
	margin-left: auto;
	margin-right: auto;
	box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.15);
	background: #ffffff;
	backface-visibility: hidden;
	transition: .22s;
}

.profile-classic,
.profile-classic:active,
.profile-classic:focus,
.profile-classic:hover {
	color: #9a9a9a;
}

.profile-classic:hover {
	transform: translateY(-10px);
	box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.15);
}

.profile-classic-image {
	width: 100%;
}

.profile-classic-main {
	padding: 20px 15px 25px;
}

.profile-classic-list {
	white-space: nowrap;
	letter-spacing: .05em;
}

.profile-classic-list>li>* {
	display: inline-block;
	vertical-align: top;
	white-space: normal;
}

.profile-classic-list>li>*+* {
	margin-left: 3px;
}

.profile-classic-list .icon {
	position: relative;
	top: 3px;
	font-size: 16px;
	color: #0A043B;
}

*+.profile-classic-list {
	margin-top: 12px;
}

@media (min-width: 992px) {
	.profile-classic-main {
		padding: 20px 20px 30px 20px;
	}
	.profile-classic-list .icon {
		font-size: 18px;
	}
}

@media (min-width: 1200px) {
	.profile-classic-main {
		padding: 25px 30px 35px;
	}
}

.profile-light {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	margin-bottom: -15px;
	margin-left: -25px;
	text-align: left;
}

.profile-light:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.profile-light>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 15px;
	margin-left: 25px;
}

.profile-light-image,
.profile-light-main {
	max-width: 100px;
}

.profile-light-image {
	/* width: 100%; */
	width: 90px;
	height: auto;
	flex-shrink: 0;
	border-radius: 5px;
	/* box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.24); */
}

.profile-light-main {
	flex-grow: 1;
	display: flex;
}

.profile-light-position {
	color: #0A043B;
}

.profile-light-divider {
	border-bottom: 1px solid #e0e0e0;
}

.profile-light-list>li {
	white-space: nowrap;
}

.profile-light-list>li>* {
	display: inline-block;
	vertical-align: middle;
	white-space: normal;
}

.profile-light-list>li>*+* {
	margin-left: 5px;
}

.profile-light-list .icon {
	color: #0A043B;
}

.profile-light-list>li+li {
	margin-top: 5px;
}

*+.profile-light-divider {
	margin-top: 18px;
}

*+.profile-light-list {
	/* margin-top: 18px; */
	margin-left: 20px;
	text-align: left;
}

.context-dark .profile-light-position,
.bg-gray-700 .profile-light-position,
.bg-blue-13 .profile-light-position,
.bg-blue-15 .profile-light-position,
.bg-primary .profile-light-position {
	color: #0A043B;
}

.context-dark .profile-light-divider,
.bg-gray-700 .profile-light-divider,
.bg-blue-13 .profile-light-divider,
.bg-blue-15 .profile-light-divider,
.bg-primary .profile-light-divider {
	border-bottom-color: rgba(255, 255, 255, 0.16);
}

.context-dark .profile-light-list,
.bg-gray-700 .profile-light-list,
.bg-blue-13 .profile-light-list,
.bg-blue-15 .profile-light-list,
.bg-primary .profile-light-list {
	color: rgba(255, 255, 255, 0.5);
}

.bg-primary .profile-light-position {
	color: #ffffff;
}

.bg-primary .profile-light-list .icon {
	color: #ffffff;
}

@media (min-width: 768px) {
	.profile-light {
		justify-content: flex-start;
		/* max-width: 400px; */
	}
	.profile-light-image {
		max-width: 178px;
	}
	.profile-light-main {
		max-width: 100%;
	}
}

.profile-modern {
	position: relative;
	overflow: hidden;
	display: block;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
	text-align: left;
}

.profile-modern,
.profile-modern:active,
.profile-modern:focus,
.profile-modern:hover {
	color: #9a9a9a;
}

.profile-modern:hover {
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.12);
}

.profile-modern:hover .profile-modern-title {
	color: #0A043B;
}

.profile-modern:hover .profile-modern-media {
	transform: scale3d(1.05, 1.05, 1.05);
}

.profile-modern p {
	letter-spacing: 0;
}

.profile-modern *+hr {
	margin-top: 14px;
}

.profile-modern-media {
	position: relative;
	display: block;
	transform-origin: 50% 0;
	transition: .33s;
}

.profile-modern-media::before {
	content: '';
	display: block;
	padding-top: 73.84196%;
}

.profile-modern-media img {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	min-height: 100%;
	min-width: 100%;
	width: auto;
	height: auto;
	max-width: none;
}

@supports (object-fit: cover) {
	.profile-modern-media img {
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		transform: none;
		object-fit: cover;
		object-position: center center;
	}
}

.profile-modern-main {
	position: relative;
	z-index: 1;
	padding: 20px;
	background: #ffffff;
}

.profile-modern-title {
	transition: .22s;
}

.profile-modern-meta {
	color: #151515;
	letter-spacing: .025em;
}

.profile-modern-meta>li {
	white-space: nowrap;
}

.profile-modern-meta>li>* {
	display: inline-block;
	vertical-align: top;
	white-space: normal;
}

.profile-modern-meta>li>*+* {
	margin-left: 5px;
}

.profile-modern-meta .icon {
	position: relative;
	top: 3px;
	min-width: 20px;
	color: #0A043B;
}

.profile-modern-meta>li+li {
	margin-top: 6px;
}

*+.profile-modern-meta {
	margin-top: 14px;
}

@media (min-width: 768px) {
	.profile-modern p {
		letter-spacing: .05em;
	}
	.profile-modern *+hr {
		margin-top: 20px;
	}
	.profile-modern-main {
		padding: 35px 37px 40px;
	}
}


/*
*
* Companies
*/

.company-minimal {
	display: flex;
	align-items: center;
	margin-left: -20px;
	min-width: 240px;
}

.company-minimal>* {
	margin-left: 20px;
}

.company-minimal p {
	letter-spacing: .05em;
}

.company-minimal-figure {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
	width: 56px;
	height: 56px;
	background: #e2e4ee;
	flex-shrink: 0;
}

.company-minimal-image {
	max-width: 80%;
	max-height: 80%;
}

.company-minimal-main>*+* {
	margin-top: 3px;
}

.company-minimal-main p {
	line-height: 1.4;
}

@media (min-width: 768px) {
	.company-minimal-figure {
		width: 78px;
		height: 78px;
	}
}

.table-companies {
	width: 100%;
}

.table-companies tbody {
	width: 100%;
}

.table-companies tr {
	background: #ffffff;
	border: 1px solid #e0e0e0;
	transition: .22s;
}

.table-companies tr:hover {
	background: #f3f4f9;
}

.table-companies td {
	padding: 16px 20px;
}

.table-companies-main {
	width: 54%;
}

.table-companies-meta {
	width: 23%;
	white-space: nowrap;
	letter-spacing: .05em;
}

.table-companies-meta>* {
	display: inline-block;
	vertical-align: middle;
}

.table-companies-meta>*+* {
	margin-left: 4px;
}

.table-companies-meta .icon {
	position: relative;
	top: -1px;
	font-size: 16px;
	color: #0A043B;
}

.block-sort-options {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	text-transform: uppercase;
	letter-spacing: .05em;
	color: #151515;
	font-size: 0;
	line-height: 0;
}

.block-sort-options>* {
	position: relative;
	display: inline-block;
	width: 100%;
	max-width: 33.2px;
	flex-shrink: 0;
	font: 600 12px/16px "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", rubiklight, "Helvetica Neue", Arial, sans-serif;
	padding: 6px 8px;
	border: 1px solid #d6d6d6;
	text-align: center;
	margin: -1px 0 0 -1px;
	cursor: pointer;
	transition: .25s;
}

.block-sort-options>*:hover {
	z-index: 1;
	border-color: #0A043B;
}

.block-sort-options>.selected {
	position: relative;
	z-index: 1;
	padding: 6px 3px;
	border-color: #0A043B;
	color: #0A043B;
}

@media (min-width: 768px) {
	.block-sort-options>* {
		flex-grow: 1;
	}
}

.company-light {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: -24px;
	margin-left: -24px;
}

.company-light:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.company-light>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 24px;
	margin-left: 24px;
}

.company-light-logo {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 92px;
	height: 92px;
	border: 1px solid rgb(150, 145, 171);
	flex-shrink: 0;
	border-radius: 4px;
}

.company-light-figure {
	flex-shrink: 0;
	min-width: 58px;
	text-align: center;
}

.company-light-logo-image {
	max-width: 80%;
	max-height: 80%;
}

.company-light-main {
	flex-grow: 1;
}

.company-light-main>*+* {
	margin-top: 3px;
}

*+.company-light-info {
	margin-top: 16px;
}

@media (max-width: 575.98px) {
	.company-light-info {
		max-width: 95vw;
	}
}

@media (max-width: 767.98px) {
	.company-light_1 {
		align-items: flex-start;
	}
}

@media (min-width: 768px) {
	.company-light .row {
		margin-left: -30px;
		margin-right: -30px;
	}
	.company-light .row>[class*='col'] {
		padding-left: 30px;
		padding-right: 30px;
	}
}

@media (min-width: 1200px) {
	.company-light-logo {
		width: 125px;
		height: 120px;
	}
}


/*
*
* Messages
*/

.message-bubble {
	position: relative;
	color: #151515;
}

.message-bubble-inner {
	position: relative;
	display: flex;
	padding: 10px 15px 10px;
	border: 1px solid #e0e0e0;
	border-top: 0;
	margin-top: 12px;
	letter-spacing: .025em;
}

.message-bubble-inner>*+* {
	margin-left: 5px;
}

.message-bubble-inner::before {
	content: '';
	position: absolute;
	top: -12px;
	right: -1px;
	height: 12px;
	border-left: 1px solid #e0e0e0;
}

.message-bubble-inner::after {
	content: '';
	position: absolute;
	left: 15px;
	right: -1px;
	top: -12px;
	height: 12px;
	border: 1px solid #e0e0e0;
	border-left-width: 0;
	border-top-width: 0;
	transform: skewX(-55deg);
	transform-origin: 0 0;
}

.message-bubble-inner p+p {
	margin-top: 2px;
}

*+.message-bubble {
	margin-top: 4px;
}

.message-inline {
	padding: 10px 3px;
	text-align: center;
}

.message-inline p+p {
	margin-top: 0;
}

.message-inline>*+* {
	margin-top: 3px;
}


/*
*
* Jumbotron Custom
*/

.jumbotron-modern {
	position: relative;
	display: flex;
	overflow: hidden;
	text-align: center;
}

.jumbotron-modern-inner {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	padding: 40px 0;
}

.jumbotron-modern-inner>.container {
	position: relative;
	z-index: 2;
}

.jumbotron-modern-image-left,
.jumbotron-modern-image-right {
	position: absolute;
	top: 30%;
	transform: translate3d(0, -30%, 0);
	max-height: 96%;
	max-width: 40%;
	z-index: 1;
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	width: 100%;
	opacity: .5;
	backface-visibility: hidden;
	contain: paint;
}

.jumbotron-modern-image-left img,
.jumbotron-modern-image-right img {
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
}

.jumbotron-modern-image-left {
	left: 0;
	align-items: flex-start;
}

.jumbotron-modern-image-right {
	right: 0;
	align-items: flex-end;
}

@media (min-width: 768px) {
	.jumbotron-modern-inner {
		padding: 90px 0 100px;
		min-height: 30vw;
	}
	.jumbotron-modern-image-left,
	.jumbotron-modern-image-right {
		top: 50%;
		transform: translate3d(0, -50%, 0);
		opacity: .8;
	}
}

@media (min-width: 1200px) {
	.jumbotron-modern-image-left,
	.jumbotron-modern-image-right {
		opacity: .9;
	}
}

@media (min-width: 1600px) {
	.jumbotron-modern-image-left,
	.jumbotron-modern-image-right {
		opacity: 1;
	}
}

.jumbotron-creative {
	display: flex;
	flex-direction: column;
	position: relative;
}

.jumbotron-creative>* {
	width: 100%;
}

.jumbotron-creative .form-layout-search {
	margin-bottom: -8px;
	margin-left: -8px;
}

.jumbotron-creative .form-layout-search>* {
	margin-bottom: 8px;
	margin-left: 8px;
}

.jumbotron-creative .rd-navbar-static:not(.rd-navbar--is-stuck) {
	background-color: transparent;
}

.jumbotron-creative *+.form-layout-search-outer {
	margin-top: 35px;
}

.jc-decoration {
	position: relative;
	max-width: 540px;
	padding: 0 15px 10%;
	margin-left: auto;
	margin-right: auto;
	z-index: 0;
}

.jc-decoration::after {
	content: '';
	position: absolute;
	bottom: 0;
	padding-bottom: 30%;
	width: 100%;
	border-radius: 50%;
	z-index: -1;
  background: #0A043B;
	/* background: #0A043B; */
}

.jc-decoration-item {
	display: none;
}

.jumbotron-creative-inner {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
}

.jumbotron-creative-main,
.jumbotron-creative-aside {
	position: relative;
	z-index: 1;
}

.jumbotron-creative-main {
	width: 100%;
	padding: 40px 0;
	text-align: center;
}

.jumbotron-creative-main .form-layout-search-outer {
	max-width: 420px;
	margin-left: auto;
	margin-right: auto;
}

.jumbotron-creative+.section.bg-default {
	padding-top: 30px;
}


@media (min-width: 768px) {
	.jumbotron-creative .form-layout-search .form-wrap {
		min-width: 240px;
	}
	.jumbotron-creative .form-layout-search .form-wrap-select {
		max-width: 240px;
	}
	.jumbotron-creative .form-layout-search .form-wrap-button {
		min-width: 75px;
	}
	.jc-decoration {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		max-width: 100%;
		width: 70%;
		padding: 0;
	}
	.jc-decoration::after {
		display: none;
	}
	.jc-decoration-item {
		position: absolute;
		display: block;
		min-width: 100%;
		min-height: 100%;
		padding-bottom: 67.0816%;
	}
	.jc-decoration-item svg {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.jc-decoration-item-1 {
		top: -5.5%;
		left: 1%;
		fill: #5eb6ff;
	}
	.jc-decoration-item-2 {
		top: -5.8%;
		left: 1.5%;
		fill: #0A043B;
	}
	.jc-decoration-image {
		position: absolute;
		top: 60%;
		left: calc(50% - 90px);
		max-width: 380px;
		transform: translate3d(0, -60%, 0);
	}
	.jumbotron-creative-main {
		padding: 70px 0 120px;
		max-width: 360px;
		text-align: left;
	}
	.jumbotron-creative-main .form-layout-search-outer {
		max-width: 100%;
		margin-left: 0;
	}
	.jumbotron-creative-title {
		max-width: 360px;
	}
	.jumbotron-creative+.section.bg-default {
		padding-top: 0;
	}
}

.jumbotron-creative-title {
	color: #ffffff;
}

@media (min-width: 992px) {
	.jc-decoration {
		width: calc(100% - 120px);
	}
	.jc-decoration-image {
		max-width: 480px;
		left: calc(50% - 120px);
	}
}

@media (min-width: 1200px) {
	.jumbotron-creative-inner {
		/* min-height: 42.1875vw; */
		min-height: 30.1875vw;
	}
	.jc-decoration {
		width: calc(100% - 120px);
	}
	.jc-decoration-image {
		left: 50%;
	}
	.jumbotron-creative-main {
		max-width: 580px;
		padding: 50px 0 180px;
	}
	.jumbotron-creative-main .form-layout-search {
		max-width: inherit;
	}
	.jumbotron-creative-title {
		max-width: 100%;
		padding-right: 80px;
	}
}

@media (min-width: 1600px) {
	.jc-decoration-image {
		left: calc(50% - 150px);
		max-width: 750px;
	}
	.jc-decoration {
		width: calc(50% + 500px);
	}
	.jumbotron-creative-title {
		padding-right: 60px;
	}
	.jumbotron-creative-main {
		max-width: 590px;
	}
}


/*
* Page layout
*/

.page {
	position: relative;
	overflow: hidden;
	min-height: 100vh;
	opacity: 0;
}

.page.fadeIn {
	animation-timing-function: ease-out;
}

.page.fadeOut {
	animation-timing-function: ease-in;
}

[data-x-mode] .page {
	opacity: 1;
}

.page-header .rd-navbar {
	position: relative;
	z-index: 1030;
}


/*
*
* Text styling
*/

.text-italic {
	font-style: italic;
}

.text-normal {
	font-style: normal;
}

.text-underline {
	text-decoration: underline;
}

.text-strike {
	text-decoration: line-through;
}

.font-weight-thin {
	font-weight: 100;
}

.font-weight-light {
	font-weight: 300;
}

.font-weight-regular {
	font-weight: 400;
}

.font-weight-medium {
	font-weight: 500;
}

.font-weight-sbold {
	font-weight: 600;
}

.font-weight-ubold {
	font-weight: 900;
}

.text-spacing-0 {
	letter-spacing: 0;
}

.text-spacing-05 {
	letter-spacing: .05em;
}

html .text-primary {
	color: #0A043B;
}

html .text-secondary {
	color: #ca5217;
}

html .text-tertiary {
	color: #7959b6;
}

html .text-gray-3 {
	color: #e4e6ef;
}

html .text-gray-500 {
	color: #9a9a9a;
}

html .text-gray-800 {
	color: #151515;
}

html .text-gray-800 a,
html .text-gray-800 a:active,
html .text-gray-800 a:focus {
	color: #0A043B;
}

html .text-gray-800 a:hover {
	color: #ca5217;
}

html .text-blue-11 {
	color: #437685;
}

html .text-color-default {
	color: #9a9a9a;
}

html .text-white {
	color: #ffffff;
}

html .text-success {
	color: green;
}

html .text-white a,
html .text-white a:active,
html .text-white a:focus {
	color: #0A043B;
}

.text-white > a {
	color: #0A043B;
	background-color: #ffffff;
	padding: 5px 1px;
	font-weight: 500;
}

html .text-white a:hover {
	color: #ca5217;
}
/*
*
* Offsets
*/

*+p {
	margin-top: 16px;
}

.group+hr,
.button+hr {
	margin-top: 15px;
}

h2+p,
.heading-2+p {
	margin-top: 12px;
}

p+h2,
p+.heading-2 {
	margin-top: 15px;
}

h2+h3 {
	margin-top: 10px;
}

h4+hr,
.heading-4+hr {
	margin-top: 15px;
}

h3+p,
.heading-3+p {
	margin-top: 5px;
}

h3+.big,
.heading-3+.big {
	margin-top: 15px;
}

h2+h5,
h2+.heading-5,
.heading-2+h5,
.heading-2+.heading-5 {
	margin-top: 15px;
}

h3+h5,
h3+.heading-5,
.heading-3+h5,
.heading-3+.heading-5 {
	margin-top: 15px;
}

h2+h6,
h2+.heading-6,
.heading-2+h6,
.heading-2+.heading-6 {
	margin-top: 17px;
}

p+p {
	margin-top: 12px;
}

img+p {
	margin-top: 15px;
}

h3+img {
	margin-top: 42px;
}

*+.row {
	margin-top: 30px;
}

*+.big {
	margin-top: 20px;
}

*+.text-block {
	margin-top: 30px;
}

*+.button {
	margin-top: 30px;
}

*+.group {
	margin-top: 5px;
}

*+.list-inline-sm {
	margin-top: 18px;
}

*+.list-0 {
	margin-top: 10px;
}

*+.rd-mailform {
	margin-top: 30px;
}

*+.block-form {
	margin-top: 50px;
}

*+.heading-decorated_1 {
	margin-top: 25px;
}

*+.owl-carousel {
	margin-top: 30px;
}

*+.offset-top-20px {
	margin-top: 20px;
}

.row+h3,
.row+.heading-3 {
	margin-top: 80px;
}

h2+.button-lg,
.heading-2+.button-lg {
	margin-top: 20px;
}

/* hr+.rd-form {
	margin-top: 35px;
} */

.heading-8+hr {
	margin-top: 5px;
}

.heading-9+hr {
	margin-top: 10px;
}

hr+.list {
	margin-top: 17px;
}

hr+.form-wrap {
	margin-top: 18px;
}

hr+.text-style-1 {
	margin-top: 24px;
}

hr+.row {
	margin-top: 18px;
}

.container+.container {
	margin-top: 35px;
}

.row+.row {
	margin-top: 35px;
}

.form-wrap+.list {
	margin-top: 16px;
}

.big+.button-lg {
	margin-top: 35px;
}

html .page *+.offset-top-1 {
	margin-top: 50px;
}

html .page *+.offset-top-2 {
	margin-top: 35px;
}

html .page *+.offset-top-30px {
	margin-top: 30px;
}

@media (min-width: 768px) {
	p+p {
		margin-top: 24px;
	}
	h2+h5,
	h2+.heading-5,
	.heading-2+h5,
	.heading-2+.heading-5 {
		margin-top: 25px;
	}
	h3+.big,
	.heading-3+.big {
		margin-top: 30px;
	}
	.group+hr,
	.button+hr {
		margin-top: 30px;
	}
	hr+.group {
		margin-top: 30px;
	}
	hr+.button {
		margin-top: 50px;
	}
	*+.row {
		margin-top: 45px;
	}
	/* *+.group {
		margin-top: 40px;
	} */
	/* *+.block-form {
		margin-top: 35px;
	} */
	*+.heading-decorated_1 {
		margin-top: 35px;
	}
	*+.owl-carousel {
		margin-top: 40px;
	}
	html .page *+.offset-top-1 {
		margin-top: 70px;
	}
	html .page *+.offset-top-2 {
		margin-top: 45px;
	}
}

@media (min-width: 1200px) {
	h2+h3 {
		margin-top: 15px;
	}
	/* *+.rd-form {
		margin-top: 30px;
	} */
	.container+.container {
		margin-top: 60px;
	}
	.row+.row {
		margin-top: 60px;
	}
	h2+.button-lg,
	.heading-2+.button-lg {
		margin-top: 45px;
	}
}

@media (min-width: 1600px) {
	html .page *+.offset-top-1 {
		margin-top: 90px;
	}
}

.row:empty {
	margin-bottom: 0;
}

.row-0 {
	margin-bottom: 0;
}

.row-0>* {
	margin-bottom: 0;
}

.row-15 {
	margin-bottom: -15px;
}

.row-15>* {
	margin-bottom: 15px;
}

.row-20 {
	margin-bottom: -20px;
}

.row-20>* {
	margin-bottom: 20px;
}

.row-30 {
	margin-bottom: -30px;
}

.row-30>* {
	margin-bottom: 30px;
}

.row-40 {
	margin-bottom: -40px;
}

.row-40>* {
	margin-bottom: 10px;
}

.row-50 {
	margin-bottom: -50px;
}

.row-50>* {
	margin-bottom: 50px;
}

@media (min-width: 992px) {
	.row-lg-50 {
		margin-bottom: -50px;
	}
	.row-lg-50>* {
		margin-bottom: 50px;
	}
}

@media (min-width: 1200px) {
	.row-xl-70 {
		margin-bottom: -70px;
	}
	.row-xl-70>* {
		margin-bottom: 70px;
	}
}


/*
*
* Sections
*/

.section-xs {
	padding: 25px 0;
}

.section-xs.section-first {
	padding-top: 40px;
}

.section-xs.section-last {
	padding-bottom: 40px;
}

.section-sm,
.section-md,
.section-lg,
.section-xl {
	padding: 50px 0;
}

.section-collapse+.section-collapse {
	padding-top: 0;
}

.section-collapse:last-child {
	padding-bottom: 0;
}

.section-2 {
	padding: 30px 0;
}

html [class*='section-'].section-bottom-0 {
	padding-bottom: 0;
}

@media (max-width: 991.98px) {
	section.section-sm:first-of-type,
	section.section-md:first-of-type,
	section.section-lg:first-of-type,
	section.section-xl:first-of-type,
	section.section-xxl:first-of-type {
		padding-top: 40px;
	}
}

@media (min-width: 768px) {
	.section-xs.section-first {
		padding-top: 60px;
	}
	.section-xs.section-last {
		padding-bottom: 60px;
	}
	.section-sm {
		padding: 60px 0;
	}
	.section-sm.section-first {
		padding-top: 80px;
	}
	.section-sm.section-last {
		padding-bottom: 80px;
	}
	/* .section-md {
		padding: 70px 0;
	} */
	.section-lg {
		padding: 85px 0;
	}
	.section-2 {
		padding: 50px 0;
	}
}

@media (min-width: 992px) {
	.section-sm {
		padding: 56px 0 56px;
	}
	/* .section-md {
		padding: 90px 0 95px;
	} */
	.section-lg {
		padding: 100px 0;
	}
	.section-1 {
		padding: 25px 0;
	}
	.section-2 {
		padding: 70px 0;
	}
}

@media (min-width: 1200px) {
	.section-sm.section-first {
		padding-top: 90px;
	}
	.section-sm.section-last {
		padding-bottom: 90px;
	}
	.section-lg {
		padding: 115px 0;
	}
}

.section-single {
	display: flex;
	text-align: center;
}

.section-single .rd-mailform-wrap {
	max-width: 670px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

.section-single *+.rights {
	margin-top: 35px;
}

.section-single *+.block-6 {
	margin-top: 35px;
}

.section-single *+.rd-mailform-wrap {
	margin-top: 30px;
}

.section-single .block-6+* {
	margin-top: 20px;
}

@media (min-width: 992px) {
	.section-single *+.rights {
		margin-top: 60px;
	}
	.section-single *+.block-6 {
		margin-top: 35px;
	}
	.section-single *+.rd-mailform-wrap {
		margin-top: 40px;
	}
	.section-single .block-6+* {
		margin-top: 25px;
	}
}

.section-single-header {
	padding: calc(1em + 3vh) 0 calc(1em + 2vh);
}

.section-single-main {
	padding: calc(1em + 4vh) 0;
}

.section-single-footer {
	padding: calc(1em + 2vh) 0 calc(1em + 3vh);
}

.section-single-inner {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	min-height: 100vh;
}

.section-single-inner>* {
	width: 100%;
}

@supports (display: grid) {
	.section-single-inner {
		display: grid;
		justify-items: center;
		align-content: space-between;
		grid-template-columns: 1fr;
	}
}

@media (min-width: 1600px) {
	.section-single-header {
		padding-top: 100px;
		padding-bottom: 0;
	}
	.section-single-footer {
		padding-top: 0;
		padding-bottom: 60px;
	}
}


/*
*
* Grid modules
*/

.grid-demonstration {
	padding: 12px 8px;
	letter-spacing: 0;
	text-align: left;
	color: #151515;
}

.grid-demonstration .gd-title {
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 20px;
	letter-spacing: .05em;
}

@media (max-width: 1199.98px) {
	.grid-demonstration .gd-title {
		font-size: 14px;
	}
}

@media (max-width: 1599.98px) {
	.grid-demonstration p {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

@media (max-width: 991.98px) {
	.container-fluid.grid-demonstration .row {
		margin-left: 0;
		margin-right: 0;
	}
}

.container-fluid.grid-demonstration [class^='col'] {
	padding: 5px;
}

@media (max-width: 991.98px) {
	.container-fluid.grid-demonstration [class^='col'] p {
		font-size: 14px;
	}
	.container-fluid.grid-demonstration [class^='col'] *+p {
		margin-top: 10px;
	}
}

@media (min-width: 768px) {
	.container-fluid.grid-demonstration [class^='col'] {
		padding: 15px 10px;
	}
}

@media (min-width: 992px) {
	.container-fluid.grid-demonstration [class^='col'] {
		padding: 25px 15px;
	}
}

@media (min-width: 1200px) {
	.container-fluid.grid-demonstration [class^='col'] {
		padding: 30px 15px 30px 30px;
	}
}

@media (min-width: 1600px) {
	.container-fluid.grid-demonstration [class^='col'] {
		padding: 50px 100px;
	}
	.container-fluid.grid-demonstration [class^='col'] *+p {
		margin-top: 24px;
	}
}

@media (min-width: 1600px) {
	.container-fluid.grid-demonstration .row {
		margin-left: -70px;
		margin-right: -70px;
	}
}

*+.grid-demonstration {
	margin-top: 30px;
}

.grid-system-bordered [class*='col'] {
	padding-top: 15px;
	padding-bottom: 15px;
}

.grid-system-bordered .row {
	border-bottom: 1px solid #e0e0e0;
}

@media (min-width: 768px) {
	.grid-system-bordered [class*='col'] {
		padding-top: 25px;
		padding-bottom: 25px;
	}
}

@media (min-width: 1200px) {
	.grid-system-bordered [class*='col'] {
		padding-top: 45px;
		padding-bottom: 45px;
	}
}

.grid-system-outline .row {
	border: 1px solid #e0e0e0;
}

.grid-system-outline .row:not(:last-child) {
	border-bottom-width: 0;
}

.grid-system-outline [class*='col']:not(:last-child) {
	border-right: 1px solid #e0e0e0;
	margin-right: -1px;
}

.grid-system-outline *+.row {
	margin-top: 45px;
}

.grid-system-outline .row+.row {
	margin-top: 0;
}

@media (min-width: 1600px) {
	.container-wide {
		max-width: 1800px;
	}
}

@media (min-width: 1200px) {
	.container-fullwidth {
		max-width: 100%;
	}
}

@media (min-width: 1600px) {
	.container-fullwidth {
		max-width: 1870px;
	}
}

.row-x-14 {
	margin-left: -7px;
	margin-right: -7px;
}

.row-x-14>[class*='col'] {
	padding-left: 7px;
	padding-right: 7px;
}

@media (min-width: 576px) {
	.row-bordered .col-sm-6:not(.col-md-4) {
		border-left: 1px solid rgb(150, 145, 171);
	}
	.row-bordered .col-sm-6:not(.col-md-4):nth-child(2n + 1) {
		border-left: 0;
	}
}

@media (min-width: 768px) {
	.row-bordered .col-md-4 {
		padding-top: 15px;
		padding-bottom: 15px;
	}
}

@media (min-width: 992px) {
	.row-bordered .col-md-4 {
		border-left: 1px solid #e0e0e0;
	}
	.row-bordered .col-md-4:nth-child(3n + 1) {
		border-left: 0;
	}
}

.row-bordered-1-outer {
	position: relative;
	overflow: hidden;
}

.row-bordered-1 {
	margin-top: -38px;
	margin-bottom: -38px;
}

.row-bordered-1>[class*='col'] {
	padding: 38px 20px;
}

@media (max-width: 575.98px) {
	.row-bordered-1>[class*='col']:nth-child(n + 2)::before {
		content: '';
		position: absolute;
		top: 0;
		left: 30px;
		right: 30px;
		border-top: 1px solid #d6d6d6;
	}
}

@media (min-width: 576px) {
	.row-bordered-1 .col-sm-6 {
		position: relative;
	}
	.row-bordered-1 .col-sm-6:nth-child(n + 3)::before {
		content: '';
		position: absolute;
		top: 0;
		left: 30px;
		right: 30px;
		border-top: 1px solid rgb(150, 145, 171);
	}
	.row-bordered-1 .col-sm-6:nth-child(2n)::after {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		border-left: 1px solid #d6d6d6;
	}
}

.row-narrow {
	margin-left: -10px;
	margin-right: -10px;
}

.row-narrow>[class*='col'] {
	padding-left: 10px;
	padding-right: 10px;
}

@media (min-width: 1200px) {
	.row-x-xl-50 {
		margin-left: -25px;
		margin-right: -25px;
	}
	.row-x-xl-50>[class*='col'] {
		padding-left: 25px;
		padding-right: 25px;
	}
}


/*
*
* Backgrounds
*/

.context-dark,
.bg-gray-700,
.bg-blue-13,
.bg-blue-15,
.bg-primary,
.context-dark h1,
.bg-gray-700 h1,
.bg-blue-13 h1,
.bg-blue-15 h1,
.bg-primary h1,
.context-dark h2,
.bg-gray-700 h2,
.bg-blue-13 h2,
.bg-blue-15 h2,
.bg-primary h2,
.context-dark h3,
.bg-gray-700 h3,
.bg-blue-13 h3,
.bg-blue-15 h3,
.bg-primary h3,
.context-dark h4,
.bg-gray-700 h4,
.bg-blue-13 h4,
.bg-blue-15 h4,
.bg-primary h4,
.context-dark h5,
.bg-gray-700 h5,
.bg-blue-13 h5,
.bg-blue-15 h5,
.bg-primary h5,
.context-dark h6,
.bg-gray-700 h6,
.bg-blue-13 h6,
.bg-blue-15 h6,
.bg-primary h6,
.context-dark [class*='heading-'],
.bg-gray-700 [class*='heading-'],
.bg-blue-13 [class*='heading-'],
.bg-blue-15 [class*='heading-'],
.bg-primary [class*='heading-'] {
	color: #ffffff;
}

.context-dark hr,
.bg-gray-700 hr,
.bg-blue-13 hr,
.bg-blue-15 hr,
.bg-primary hr {
	border-top-color: rgba(255, 255, 255, 0.13);
}

.context-dark .brand .brand-logo-dark,
.bg-gray-700 .brand .brand-logo-dark,
.bg-blue-13 .brand .brand-logo-dark,
.bg-blue-15 .brand .brand-logo-dark,
.bg-primary .brand .brand-logo-dark {
	display: none;
}

.context-dark .brand .brand-logo-light,
.bg-gray-700 .brand .brand-logo-light,
.bg-blue-13 .brand .brand-logo-light,
.bg-blue-15 .brand .brand-logo-light,
.bg-primary .brand .brand-logo-light {
	display: block;
}

.context-dark .rights a,
.bg-gray-700 .rights a,
.bg-blue-13 .rights a,
.bg-blue-15 .rights a,
.bg-primary .rights a {
	color: inherit;
}

.context-dark .big a:hover,
.bg-gray-700 .big a:hover,
.bg-blue-13 .big a:hover,
.bg-blue-15 .big a:hover,
.bg-primary .big a:hover {
	color: #35a1ff;
}


/**
* Light Backgrounds
*/

.bg-default {
	background-color: #ffffff;
}

.bg-default+.bg-default {
	padding-top: 0;
}

.bg-gray-100 {
	background-color: #f3f4f9;
}

.bg-gray-100+.bg-gray-100 {
	padding-top: 0;
}

.bg-gray-200 {
	background-color: #e0e0e0;
}

.bg-gray-200+.bg-gray-200 {
	padding-top: 0;
}


/**
* Dark Backgrounds
*/

.bg-gray-700 {
	background-color: #2c343b;
}

.bg-gray-700+.bg-gray-700 {
	padding-top: 0;
}

.bg-blue-13 {
	background-color: #3b494c;
}

.bg-blue-13+.bg-blue-13 {
	padding-top: 0;
}

.bg-blue-15 {
	background-color: #45556c;
}

.bg-blue-15+.bg-blue-15 {
	padding-top: 0;
}


/** 
* Accent Backgrounds
*/

.bg-primary {
	background: #0A043B;
}

.bg-primary+.bg-primary {
	padding-top: 0;
}


/**
* Background Image
*/

[class*='bg-'] {
	background-size: cover;
	background-position: center center;
}

[class*='bg-overlay'] {
	position: relative;
}

[class*='bg-overlay']::before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	pointer-events: none;
	z-index: 1;
}

[class*='bg-overlay']>* {
	position: relative;
	z-index: 1;
}

.bg-overlay-1::before {
	background: rgba(16, 135, 235, 0.9);
}

.bg-overlay-2::before {
	background: rgba(60, 74, 84, 0.93);
}

.bg-overlay-3::before {
	background: rgba(22, 47, 69, 0.43);
}

.bg-overlay-4::before {
	background: rgba(22, 47, 69, 0.84);
}

.bg-overlay-5::before {
	background: rgba(65, 78, 90, 0.37);
}

.bg-overlay-6::before {
	background: rgba(72, 91, 120, 0.93);
}

.bg-overlay-7::before {
	background: rgba(52, 73, 94, 0.75);
}

.bg-overlay-8::before {
	background: #0A043B;
	opacity: 0.55;
	background: linear-gradient(to right, rgb(10, 4, 59) 10%, transparent 90%);
}

.bg-overlay-gradient-1::before {
	background: linear-gradient(to right, #3d485d 0%, transparent 100%);
	opacity: 0.522;
}

@media (min-width: 992px) {
	html:not(.tablet):not(.mobile) .bg-fixed {
		background-attachment: fixed;
	}
}

.bg-1 {
	background: #49555e url(/img/bg-blue-4-noise.jpg) repeat;
	padding-bottom: 10px;
}

@media (min-width: 576px) {
	.bg-1 {
		padding-bottom: 20px;
	}
}

@media (min-width: 768px) {
	.bg-1 {
		padding-bottom: 30px;
	}
}

@media (min-width: 992px) {
	.bg-1 {
		padding-bottom: 50px;
	}
}

@media (min-width: 1200px) {
	.bg-1 {
		padding-bottom: 80px;
	}
}


/*
*
* Utilities custom
*/

.height-fill {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

.height-fill>* {
	flex-grow: 1;
	flex-shrink: 0;
}

.object-inline {
	white-space: nowrap;
}

.object-inline>* {
	display: inline-block;
	min-width: 20px;
	vertical-align: top;
	white-space: normal;
}

.object-inline>*+* {
	margin-left: 5px;
}

.object-inline .icon-1,
.object-inline .icon-default {
	position: relative;
	top: 0px;
}

.object-inline a {
	color: inherit;
}

.object-inline a:hover {
	color: #0A043B;
}

.object-inline_sm>*+* {
	margin-left: 2px;
}

.oh {
	position: relative;
	overflow: hidden;
}

.text-width-small {
	max-width: 320px;
}

.text-decoration-lines {
	position: relative;
	overflow: hidden;
	width: 100%;
	text-align: center;
}

.text-decoration-lines-content {
	position: relative;
	display: inline-block;
	min-width: 170px;
	font-size: 13px;
	text-transform: uppercase;
}

.text-decoration-lines-content::before,
.text-decoration-lines-content::after {
	content: '';
	position: absolute;
	height: 1px;
	background: #e0e0e0;
	top: 50%;
	width: 100vw;
}

.text-decoration-lines-content::before {
	left: 0;
	transform: translate3d(-100%, 0, 0);
}

.text-decoration-lines-content::after {
	right: 0;
	transform: translate3d(100%, 0, 0);
}

*+.text-decoration-lines {
	margin-top: 30px;
}

.text-decoration-lines+.rd-form {
	margin-top: 17px;
}

.rights a {
	text-decoration: underline;
	color: #000000;
}

.rights a:hover {
	text-decoration: none;
}

.text-block>* {
	margin-left: .125em;
	margin-right: .125em;
}

.footer-text {
	color: #808080;
}
.tip-mark {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	top: .2em;
	width: 2em;
	height: 2em;
	padding-left: 2px;
	margin-bottom: 5px;
	font-size: 14px;
	line-height: 2em;
	vertical-align: middle;
	text-align: center;
	border-radius: 50%;
	border: 2px solid #e0e0e0;
	color: #0A043B;
	cursor: pointer;
	user-select: none;
}

.context-dark .tip-mark,
.bg-gray-700 .tip-mark,
.bg-blue-13 .tip-mark,
.bg-blue-15 .tip-mark,
.bg-primary .tip-mark {
	color: #ffffff;
}

.placeholder-1 {
	height: 80px;
}

.inset-left-1 {
	padding-left: 20px;
}

@media (min-width: 768px) {
	.inset-left-1 {
		padding-left: 63px;
	}
}


/*
*
* Animate.css
*/

.animated {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	opacity: 1;
}

.animated.infinite {
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

.animated.hinge {
	-webkit-animation-duration: 2s;
	animation-duration: 2s;
}

html:not(.lt-ie10) .not-animated {
	opacity: 0;
}

@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.fadeIn {
	-webkit-animation-name: fadeIn;
	animation-name: fadeIn;
}

@-webkit-keyframes fadeInUp {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

@keyframes fadeInUp {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

.fadeInUp {
	-webkit-animation-name: fadeInUp;
	animation-name: fadeInUp;
}

@-webkit-keyframes fadeInDown {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

@keyframes fadeInDown {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

.fadeInDown {
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown;
}

@-webkit-keyframes fadeInLeft {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

@keyframes fadeInLeft {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

.fadeInLeft {
	-webkit-animation-name: fadeInLeft;
	animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInRight {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

@keyframes fadeInRight {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

.fadeInRight {
	-webkit-animation-name: fadeInRight;
	animation-name: fadeInRight;
}

@-webkit-keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.fadeOut {
	-webkit-animation-name: fadeOut;
	animation-name: fadeOut;
}

@-webkit-keyframes slideInDown {
	0% {
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
		visibility: visible;
	}
	100% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slideInDown {
	0% {
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
		visibility: visible;
	}
	100% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

.slideInDown {
	-webkit-animation-name: slideInDown;
	animation-name: slideInDown;
}

@-webkit-keyframes slideInLeft {
	0% {
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
		visibility: visible;
	}
	100% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slideInLeft {
	0% {
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
		visibility: visible;
	}
	100% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

.slideInLeft {
	-webkit-animation-name: slideInLeft;
	animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
	0% {
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
		visibility: visible;
	}
	100% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slideInRight {
	0% {
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
		visibility: visible;
	}
	100% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

.slideInRight {
	-webkit-animation-name: slideInRight;
	animation-name: slideInRight;
}

@-webkit-keyframes slideOutDown {
	0% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
	100% {
		visibility: hidden;
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
	}
}

@keyframes slideOutDown {
	0% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
	100% {
		visibility: hidden;
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
	}
}

.slideOutDown {
	-webkit-animation-name: slideOutDown;
	animation-name: slideOutDown;
}


/*
*
* Preloader
*/

.preloader {
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 9999999;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
	background: #ffffff;
	transition: .3s all ease;
}

.preloader.loaded {
	opacity: 0;
	visibility: hidden;
}

.preloader-body {
	text-align: center;
}

.preloader-item {
	display: block;
	margin-top: -4%;
}

.preloader-item .rect {
	fill: none;
	stroke-width: 8px;
	stroke: #0A043B;
	stroke-dasharray: 40;
	stroke-dashoffset: 50%;
	animation: movedash 1.2s ease-in-out forwards 0s infinite;
}

@keyframes movedash {
	to {
		stroke-dashoffset: 250%;
	}
}


/*
*
* ToTop
*/

.ui-to-top {
	position: fixed;
	right: 15px;
	bottom: 15px;
	z-index: 100;
	width: 50px;
	height: 50px;
	font-size: 16px;
	line-height: 48px;
	color: #ffffff;
	background: #0A043B;
	box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.18);
	overflow: hidden;
	text-align: center;
	text-decoration: none;
	transition: .45s all ease-in-out;
	transform: translate3d(0, 100px, 0);
	border-radius: 50%;
}

.ui-to-top:hover {
	color: #151515;
	background: #ffffff;
	text-decoration: none;
}

.ui-to-top:focus {
	color: #151515;
}

.ui-to-top.active {
	transform: translate3d(0, 0, 0);
}

html.mobile .ui-to-top,
html.tablet .ui-to-top {
	display: none !important;
}

@media (min-width: 576px) {
	.ui-to-top {
		right: 40px;
		bottom: 40px;
	}
}


/*
*
* RD Navbar
*/

@keyframes rd-navbar-slide-down {
	0% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes rd-navbar-slide-up {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(-100%);
	}
}

.rd-navbar-wrap,
.rd-navbar,
.rd-menu,
.rd-navbar-nav,
.rd-navbar-panel,
.rd-navbar-static .rd-menu,
.rd-navbar-fixed .rd-navbar-nav-wrap,
.rd-navbar-fixed .rd-navbar-submenu {
	transition: 0.35s all cubic-bezier(0.65, 0.05, 0.36, 1);
}

.rd-navbar--no-transition,
.rd-navbar--no-transition * {
	transition: none !important;
}

.rd-navbar,
.rd-navbar.rd-navbar--is-clone {
	display: none;
}

.rd-navbar.rd-navbar-fixed+.rd-navbar.rd-navbar--is-clone,
.rd-navbar.rd-navbar-sidebar+.rd-navbar.rd-navbar--is-clone {
	display: none;
}

.rd-navbar {
	background: #ffffff;
}

.rd-navbar-toggle {
	display: inline-block;
	position: relative;
	width: 40px;
	height: 40px;
	line-height: 40px;
	cursor: pointer;
	color: #2c343b;
	background-color: transparent;
	border: none;
	display: none;
}

.rd-navbar-toggle span {
	position: relative;
	display: block;
	margin: auto;
	transition: .3s all ease;
}

.rd-navbar-toggle span::after,
.rd-navbar-toggle span::before {
	content: "";
	position: absolute;
	left: 0;
	top: -6px;
	transition: .3s all ease;
}

.rd-navbar-toggle span::after {
	top: 6px;
}

.rd-navbar-toggle span::after,
.rd-navbar-toggle span::before,
.rd-navbar-toggle span {
	width: 24px;
	height: 2px;
	background-color: #2c343b;
	backface-visibility: hidden;
	border-radius: 0;
}

.rd-navbar-toggle span::before,
.rd-navbar-toggle span::after {
	transform-origin: 1px center;
}

.rd-navbar-toggle.active span::before,
.rd-navbar-toggle.active span::after {
	top: 0;
	width: 15px;
}

.rd-navbar-toggle.active span::before {
	transform: rotate(-40deg);
}

.rd-navbar-toggle.active span::after {
	transform: rotate(40deg);
}

.rd-navbar-collapse-toggle {
	display: inline-block;
	position: relative;
	width: 40px;
	height: 40px;
	line-height: 40px;
	cursor: pointer;
	color: #151515;
	display: none;
}

.rd-navbar-collapse-toggle span {
	top: 50%;
	margin-top: -2.5px;
}

.rd-navbar-collapse-toggle span,
.rd-navbar-collapse-toggle span::before,
.rd-navbar-collapse-toggle span::after {
	position: absolute;
	width: 5px;
	height: 5px;
	line-height: 5px;
	text-align: center;
	background: #151515;
	left: 50%;
	margin-left: -2.5px;
	border-radius: 50%;
	transition: .3s all ease;
}

.rd-navbar-collapse-toggle span::before,
.rd-navbar-collapse-toggle span::after {
	content: '';
}

.rd-navbar-collapse-toggle span::before {
	bottom: 100%;
	margin-bottom: 2.5px;
}

.rd-navbar-collapse-toggle span::after {
	top: 100%;
	margin-top: 2.5px;
}

.rd-navbar-collapse-toggle.active span {
	transform: scale(0.7);
}

.rd-navbar-collapse-toggle.active span::before {
	transform: translateY(15px);
}

.rd-navbar-collapse-toggle.active span::after {
	transform: translateY(-15px);
}

.rd-navbar-brand a {
	display: block;
	margin-left:-156px;
}

.rd-nav-link {
	font: 500 13px/20px 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	letter-spacing: .1em;
	text-transform: uppercase;
}

.rd-navbar-dropdown {
	display: none;
}

.rd-megamenu-title {
	font-size: 16px;
	font-weight: 600;
	letter-spacing: .025em;
}

.rd-navbar-popup {
	position: absolute;
	right: 0;
	z-index: 1030;
	padding: 15px 25px 20px 20px;
	width: calc(100vw - 10px);
	opacity: 0;
	visibility: hidden;
	transition: .2s;
	overflow: hidden;
	border: 1px solid #e0e0e0;
}

.rd-navbar-popup>* {
	position: relative;
	z-index: 1;
	opacity: 0;
	visibility: hidden;
	transition: .5s;
	transform-origin: 50% 0;
	transform: scale3d(1, 0.95, 1);
}

.rd-navbar-popup.active {
	opacity: 1;
	visibility: visible;
}

.rd-navbar-popup.active>* {
	opacity: 1;
	visibility: visible;
	transform: scale3d(1, 1, 1);
}

.rd-navbar-popup .rd-navbar-popup-title {
	color: #151515;
}

@media (min-width: 400px) {
	.rd-navbar-popup {
		width: 370px;
	}
}

.ios.landscape.mobile .rd-navbar-popup {
	width: 410px;
	padding: 30px 45px;
}

@media (min-width: 768px) {
	.rd-navbar-popup {
		padding: 30px;
	}
}

@media (max-height: 875px) {
	.rd-navbar-classic.rd-navbar-static .rd-navbar-popup{
		max-height: calc(100vh - 135px);
		overflow-y: auto;
	}
}

/*
* @subsection  RD Navbar Static
*/

.rd-navbar-static {
	display: block;
}

.rd-navbar-static .rd-nav-item {
	display: inline-block;
}

.rd-navbar-static .rd-nav-item.focus .rd-nav-link,
.rd-navbar-static .rd-nav-item.opened .rd-nav-link {
	color: #0A043B;
	background: transparent;
}

.rd-navbar-static .rd-nav-item.focus>.rd-navbar-submenu-toggle,
.rd-navbar-static .rd-nav-item.opened>.rd-navbar-submenu-toggle {
	color: #0A043B;
}

.rd-navbar-static .rd-nav-item.active .rd-nav-link {
	color: #0A043B;
	background: transparent;
}

.rd-navbar-static .rd-nav-item.active>.rd-navbar-submenu-toggle {
	color: #0A043B;
}

.rd-navbar-static .rd-nav-item.focus>.rd-navbar-submenu-toggle,
.rd-navbar-static .rd-nav-item.opened>.rd-navbar-submenu-toggle,
.rd-navbar-static .rd-nav-item .rd-nav-link:hover+.rd-navbar-submenu-toggle {
	color: #0A043B;
}

.rd-navbar-static .rd-nav-item.focus>.rd-navbar-submenu-toggle::before,
.rd-navbar-static .rd-nav-item.opened>.rd-navbar-submenu-toggle::before,
.rd-navbar-static .rd-nav-item .rd-nav-link:hover+.rd-navbar-submenu-toggle::before {
	transform: rotate(180deg);
}

.rd-navbar-static .rd-nav-item>.rd-navbar-submenu-toggle {
	font-family: "Material Design Icons";
	font-size: 18px;
	cursor: pointer;
	color: #151515;
}

.rd-navbar-static .rd-nav-item>.rd-navbar-submenu-toggle::before {
	content: '\f236';
	position: relative;
	top: 2px;
	display: inline-block;
	transition: .22s;
}

.rd-navbar-static .rd-nav-item>.rd-navbar-submenu {
	margin-top: 20px;
}

.rd-navbar-static .rd-nav-item+.rd-nav-item {
	margin-left: 25px;
}

.rd-navbar-static .rd-nav-link {
	position: relative;
	display: inline-block;
	color: #151515;
	transition: .25s;
}

.rd-navbar-static .rd-nav-link:hover {
	color: #0A043B;
}

.rd-navbar-static .rd-menu {
	z-index: 15;
	position: absolute;
	display: block;
	visibility: hidden;
	opacity: 0;
	text-align: left;
	box-shadow: 0 13px 16px 0 rgba(0, 0, 0, 0.13);
	transform: translate3d(0, 10px, 0);
}

.rd-navbar-static .rd-nav-item>.rd-navbar-dropdown::before {
	content: '';
	position: absolute;
	top: -12px;
	left: 0;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 12px 0 0 18px;
	border-color: transparent transparent transparent #ffffff;
}

.rd-navbar-static .rd-navbar-dropdown {
	position: absolute;
	top: 100%;
	left: 0;
	width: 270px;
	padding: 17px 30px 23px;
	background: #ffffff;
	z-index: 5;
}

.rd-navbar-static .rd-navbar-dropdown .rd-navbar-dropdown {
	top: -1px;
	left: 100%;
	z-index: 2;
	margin-top: -15px;
	margin-left: 15px;
	transform: translate3d(10px, 0, 0);
}

.rd-navbar-static .rd-navbar-dropdown .rd-navbar-dropdown.rd-navbar-open-left {
	left: auto;
	right: 100%;
	margin-left: 0;
	margin-right: 15px;
	transform: translate3d(-10px, 0, 0);
}

.rd-navbar-static .rd-dropdown-item.focus .rd-dropdown-link,
.rd-navbar-static .rd-dropdown-item.opened .rd-dropdown-link {
	color: #0A043B;
	font-size: 16px !important;
}

.rd-navbar-static .rd-dropdown-link {
	color: #151515;
	font-size: 16px !important;
}

.rd-navbar-static .rd-dropdown-link:hover {
	color: #0A043B;
	font-size: 16px !important;
}

.rd-navbar-static .rd-navbar-dropdown li>a,
.rd-navbar-static .rd-megamenu-list li>a {
	position: relative;
	display: flex;
	padding: 2px 0 2px 17px;
	text-align: left;
}

.rd-navbar-static .rd-navbar-dropdown li>a,
.rd-navbar-static .rd-navbar-dropdown li>a:focus,
.rd-navbar-static .rd-navbar-dropdown li>a:active,
.rd-navbar-static .rd-megamenu-list li>a,
.rd-navbar-static .rd-megamenu-list li>a:focus,
.rd-navbar-static .rd-megamenu-list li>a:active {
	color: #151515;
}

.rd-navbar-static .rd-navbar-dropdown li>a:hover,
.rd-navbar-static .rd-megamenu-list li>a:hover {
	color: #0A043B;
}

.rd-navbar-static .rd-navbar-dropdown li>a,
.rd-navbar-static .rd-navbar-dropdown li>a::before,
.rd-navbar-static .rd-megamenu-list li>a,
.rd-navbar-static .rd-megamenu-list li>a::before {
	transition: .15s ease-in-out;
}

/* .menu-item::before {
	content: "";
	position: absolute;
	top: 14px;
	left: 0;
	display: inline-block;
	line-height: 0;
	font-size: 17px;
	color: black;
}

.menu-item {
	margin-right: 10px;
} */
/* .menu-item::before:nth-child(1){
	content: "\f2be";
	position: absolute;
	top: 15px;
	left: 0;
	display: inline-block;
	line-height: 0;
	font-size: 14px;
	color: black;
} */
/* .rd-navbar-static .rd-navbar-dropdown li>a::before,
.rd-navbar-static .rd-megamenu-list li>a::before {
	content: '>' !important;
	position: absolute;
	top: 10px;
	left: 0;
	display: inline-block;
	width: 0;
	height: 0;
	font-size: 0;
	line-height: 0;
	border-style: solid;
	border-width: 4px 0 4px 10px;
	border-color: transparent transparent transparent #b7b7b7;
} */

.rd-navbar-static .rd-navbar-dropdown li.focus>a::before,
.rd-navbar-static .rd-navbar-dropdown li.opened>a::before,
.rd-navbar-static .rd-navbar-dropdown li>a:hover::before,
.rd-navbar-static .rd-megamenu-list li.focus>a::before,
.rd-navbar-static .rd-megamenu-list li.opened>a::before,
.rd-navbar-static .rd-megamenu-list li>a:hover::before {
	border-left-color: #0A043B;
}

.rd-navbar-static .rd-navbar-dropdown li+li,
.rd-navbar-static .rd-megamenu-list li+li {
	margin-top: 8px;
}

.rd-navbar-static .rd-navbar-megamenu {
	display: flex;
	top: 100%;
	left: 0;
	width: 100%;
	max-width: 1170px;
	padding: 30px 40px;
	background: #ffffff;
}

.rd-navbar-static .rd-megamenu-item {
	flex-basis: 50%;
	flex-grow: 1;
}

.rd-navbar-static .rd-megamenu-item+.rd-megamenu-item {
	padding-left: 20px;
}

.rd-navbar-static .rd-megamenu-list-link {
	color: #000000;
	background: transparent;
	font-size: 16px;
}

.rd-navbar-static .rd-megamenu-list-link:hover {
	color: #0A043B;
	background: transparent;
}

.rd-navbar-static *+.rd-megamenu-list {
	margin-top: 17px;
}

.rd-navbar-static .rd-navbar-submenu.focus>.rd-menu,
.rd-navbar-static .rd-navbar-submenu.opened>.rd-menu {
	opacity: 1;
	visibility: visible;
	transform: translate3d(0, 0, 0);
}

@media (max-width: 1199.98px) {
	.rd-navbar-static .rd-nav-link {
		font-size: 12px;
	}
}

/* @media (min-width: 1200px) {
	.rd-navbar-static .rd-nav-item+.rd-nav-item {
		margin-left: 50px;
	}
} */

@media (min-width: 1600px) {
	.rd-navbar-static .rd-navbar-megamenu {
		padding: 30px 52px;
	}
	.rd-navbar-static .rd-megamenu-item+.rd-megamenu-item {
		margin-left: 56px;
	}
}

.rd-navbar-static.rd-navbar--is-clone {
	display: block;
	transform: translate3d(0, -100%, 0);
}

.rd-navbar-static.rd-navbar--is-clone.rd-navbar--is-stuck {
	transform: translate3d(0, 0, 0);
}

.rd-navbar-static.rd-navbar--is-stuck,
.rd-navbar-static.rd-navbar--is-clone {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	z-index: 1030;
	background: #ffffff;
}

.rd-navbar-static .rd-navbar--has-dropdown {
	position: relative;
}

.context-dark .rd-navbar-static.rd-navbar--is-stuck,
.bg-gray-700 .rd-navbar-static.rd-navbar--is-stuck,
.bg-blue-13 .rd-navbar-static.rd-navbar--is-stuck,
.bg-blue-15 .rd-navbar-static.rd-navbar--is-stuck,
.bg-primary .rd-navbar-static.rd-navbar--is-stuck {
	background: #2c343b;
}


/*
*
* RD Navbar Fixed
*/

.rd-navbar-fixed {
	display: block;
}

.rd-navbar-fixed .rd-navbar-toggle {
	display: inline-block;
}

.ios .rd-navbar-fixed .rd-navbar-toggle {
	left: -7px;
}

.rd-navbar-fixed .rd-navbar-brand {
	position: relative;
	margin-left: 6px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	text-align: left;
	font-size: 22px;
	line-height: 46px;
}

.rd-navbar-fixed .rd-navbar-brand img {
	max-width: 100px;
	max-height: 100px;
	/* width: auto; */
	/* height: auto; */
}

/* @media (max-width: 374px) {
	.rd-navbar-fixed .rd-navbar-brand img {
		max-width: 60px;
	}
} */

.rd-navbar-fixed .rd-navbar-panel {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	z-index: 1030;
	display: flex;
	align-items: center;
	height: 56px;
	padding: 8px;
}

.rd-navbar-fixed .rd-navbar-nav-wrap {
	position: fixed;
	left: 0;
	top: -56px;
	bottom: -56px;
	z-index: 1029;
	width: 270px;
	padding: 112px 0 81px;
	overflow-y: auto;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
	font-size: 14px;
	line-height: 34px;
	transform: translateX(-110%);
}

.rd-navbar-fixed .rd-navbar-nav-wrap::-webkit-scrollbar {
	width: 4px;
}

.rd-navbar-fixed .rd-navbar-nav-wrap::-webkit-scrollbar-thumb {
	background: #6cb6f5;
	border: none;
	border-radius: 0;
	opacity: .2;
}

.rd-navbar-fixed .rd-navbar-nav-wrap::-webkit-scrollbar-track {
	background: #0A043B;
	border: none;
	border-radius: 0;
}

.rd-navbar-fixed .rd-navbar-nav-wrap.active {
	transform: translateX(0);
}

.rd-navbar-fixed .rd-navbar-nav {
	display: block;
	margin: 15px 0;
	height: auto;
	text-align: left;
}

.rd-navbar-fixed .rd-nav-item {
	text-align: left;
}

.rd-navbar-fixed .rd-nav-item+.rd-nav-item {
	margin-top: 4px;
}

.rd-navbar-fixed .rd-nav-link {
	display: block;
	padding: 14px 56px 14px 18px;
}

.rd-navbar-fixed li.opened>.rd-navbar-dropdown {
	padding: 4px 0 0;
}

.rd-navbar-fixed li.opened>.rd-navbar-megamenu {
	padding-top: 15px;
	padding-bottom: 15px;
}

.rd-navbar-fixed li.opened>.rd-menu {
	opacity: 1;
	height: auto;
}

.rd-navbar-fixed li.opened>.rd-navbar-submenu-toggle::after {
	top: -3px;
	transform: rotate(180deg);
}

.rd-navbar-fixed .rd-menu {
	display: none;
	transition: opacity 0.3s, height 0.4s ease;
	opacity: 0;
	height: 0;
	overflow: hidden;
}

.rd-navbar-fixed .rd-navbar-submenu {
	position: relative;
}

.rd-navbar-fixed .rd-navbar-submenu .rd-navbar-dropdown .rd-navbar-submenu-toggle:after {
	height: 34px;
	line-height: 34px;
}

.rd-navbar-fixed .rd-navbar-submenu .rd-navbar-dropdown>li>a {
	padding-left: 30px;
}

.rd-navbar-fixed .rd-navbar-submenu .rd-navbar-dropdown li li>a,
.rd-navbar-fixed .rd-navbar-submenu .rd-navbar-megamenu ul li li>a {
	padding-left: 46px;
}

.rd-navbar-fixed .rd-navbar-submenu.opened>.rd-navbar-dropdown,
.rd-navbar-fixed .rd-navbar-submenu.opened>.rd-navbar-megamenu {
	display: block;
}

.rd-navbar-fixed .rd-megamenu-list>li>a,
.rd-navbar-fixed .rd-navbar-dropdown>li>a {
	display: block;
	padding: 9px 56px 9px 16px;
	font-size: 12px;
	line-height: 1.5;
}

.rd-navbar-fixed .rd-megamenu-list>li+li,
.rd-navbar-fixed .rd-navbar-dropdown>li+li {
	margin-top: 3px;
}

.rd-navbar-fixed .rd-megamenu-list>li>a {
	padding-left: 30px;
}

.rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title {
	position: relative;
	display: block;
	padding: 0 18px 7px;
	font-size: 15px;
}

.rd-navbar-fixed .rd-navbar-megamenu *+.rd-megamenu-list {
	margin-top: 11px;
}

.rd-navbar-fixed .rd-navbar-megamenu *+.rd-megamenu-title {
	margin-top: 20px;
}

.rd-navbar-fixed .rd-navbar-megamenu>li+li {
	margin-top: 20px;
}

.rd-navbar-fixed .rd-navbar-submenu-toggle {
	cursor: pointer;
}

.rd-navbar-fixed .rd-navbar-submenu-toggle::after {
	content: '\f107';
	position: absolute;
	top: 0;
	right: 0;
	width: 56px;
	height: 52px;
	font: 400 14px "FontAwesome";
	line-height: 48px;
	text-align: center;
	transition: 0.4s all ease;
	z-index: 2;
	cursor: pointer;
}

.rd-navbar-fixed .rd-navbar-collapse-toggle {
	display: block;
	top: 8px;
	z-index: 1031;
}

.rd-navbar-fixed .rd-navbar-collapse {
	position: fixed;
	right: 0;
	top: 56px;
	z-index: 1029;
	transform: translate3d(0, -10px, 0);
	padding: 15px;
	width: auto;
	border-radius: 0;
	text-align: left;
	font-size: 14px;
	opacity: 0;
	visibility: hidden;
	transition: .3s;
}

.rd-navbar-fixed .rd-navbar-collapse.active {
	transform: translate3d(0, 0, 0);
	opacity: 1;
	visibility: visible;
}

.rd-navbar-fixed .rd-navbar-collapse .list-inline>li {
	display: block;
}

.rd-navbar-fixed .rd-navbar-collapse .list-inline>li+li {
	margin-top: 10px;
}

.rd-navbar-fixed .rd-navbar-main-element {
	position: absolute;
	float: left;
}

.rd-navbar-fixed .rd-navbar-search .rd-search {
	position: fixed;
	right: 0;
	left: 0;
	top: 56px;
	z-index: 1029;
	width: 100%;
	opacity: 0;
	visibility: hidden;
	transition: .3s;
	transform: translate3d(0, -10px, 0);
}

.rd-navbar-fixed .rd-navbar-search .rd-search-results-live {
	display: none;
}

.rd-navbar-fixed .rd-navbar-search .form-input {
	padding-right: 50px;
}

.rd-navbar-fixed .rd-navbar-search .rd-search-form-submit {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	width: 50px;
	padding: 0;
	margin: 0;
	border: none;
	background-color: transparent;
}

.rd-navbar-fixed .rd-navbar-search.active .rd-search {
	opacity: 1;
	visibility: visible;
	transform: none;
}

.rd-navbar-fixed .rd-navbar-search-toggle {
	display: inline-flex;
}

.rd-navbar-fixed .rd-navbar-search-toggle span {
	display: inline-block;
	position: relative;
	width: 48px;
	height: 48px;
	font-size: 26px;
	line-height: 48px;
	text-align: center;
	cursor: pointer;
	background: none;
	border: none;
	outline: none;
	padding: 0;
}

.rd-navbar-fixed .rd-navbar-search-toggle span,
.rd-navbar-fixed .rd-navbar-search-toggle span::before,
.rd-navbar-fixed .rd-navbar-search-toggle span::after {
	transition: .3s all ease-in-out;
}

.rd-navbar-fixed .rd-navbar-search-toggle span::before,
.rd-navbar-fixed .rd-navbar-search-toggle span::after {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.rd-navbar-fixed .rd-navbar-search-toggle span::before {
	content: "";
	transform: rotate(0deg) scale(1);
	opacity: 1;
	visibility: visible;
	font-family: "Material Design Icons";
}

.rd-navbar-fixed .rd-navbar-search-toggle span::after {
	content: "";
	transform: rotate(-90deg) scale(0.4);
	opacity: 0;
	visibility: hidden;
	font-family: "Material Design Icons";
}

.rd-navbar-fixed .rd-navbar-search-toggle.active span::before {
	opacity: 0;
	visibility: hidden;
	transform: rotate(90deg) scale(0.4);
}

.rd-navbar-fixed .rd-navbar-search-toggle.active span::after {
	transform: rotate(0deg) scale(1);
	opacity: 1;
	visibility: visible;
}

.rd-navbar-fixed [class*='rd-navbar-fixed-element'] {
	position: fixed;
	top: 8px;
	z-index: 1032;
}

.rd-navbar-fixed .rd-navbar-fixed-element-1 {
	right: 0;
}

.rd-navbar-fixed .rd-navbar-fixed-element-2 {
	right: 46px;
}

.rd-navbar-fixed.rd-navbar--is-clone {
	display: none;
}

.rd-navbar-fixed .rd-navbar-fixed--visible {
	display: block;
}

.rd-navbar-fixed .rd-navbar-fixed--hidden {
	display: none;
}

.rd-navbar-fixed .rd-navbar-panel {
	color: #151515;
	box-shadow: none;
	background: #ffffff;
}

.rd-navbar-fixed .rd-navbar-nav-wrap {
	color: #ffffff;
	background: #ffffff;
	border: 1px solid #e0e0e0;
	box-shadow: none;
}

.rd-navbar-fixed .rd-nav-item:hover .rd-nav-link,
.rd-navbar-fixed .rd-nav-item.focus .rd-nav-link,
.rd-navbar-fixed .rd-nav-item.active .rd-nav-link,
.rd-navbar-fixed .rd-nav-item.opened .rd-nav-link {
	color: #ffffff;
	/* background: #0A043B; */
}

.rd-navbar-fixed .rd-nav-item:hover>.rd-navbar-submenu-toggle,
.rd-navbar-fixed .rd-nav-item.focus>.rd-navbar-submenu-toggle,
.rd-navbar-fixed .rd-nav-item.active>.rd-navbar-submenu-toggle,
.rd-navbar-fixed .rd-nav-item.opened>.rd-navbar-submenu-toggle {
	color: #150F3D;
}

.rd-navbar-fixed .rd-nav-link {
	color: #151515;
}

.rd-navbar-fixed .rd-megamenu-list>li>a,
.rd-navbar-fixed .rd-navbar-dropdown>li>a {
	color: #151515;
}

.rd-navbar-fixed .rd-megamenu-list>li:hover>a,
.rd-navbar-fixed .rd-megamenu-list>li.focus>a,
.rd-navbar-fixed .rd-megamenu-list>li.active>a,
.rd-navbar-fixed .rd-megamenu-list>li.opened>a,
.rd-navbar-fixed .rd-navbar-dropdown>li:hover>a,
.rd-navbar-fixed .rd-navbar-dropdown>li.focus>a,
.rd-navbar-fixed .rd-navbar-dropdown>li.active>a,
.rd-navbar-fixed .rd-navbar-dropdown>li.opened>a {
	color: #ffffff;
	background: #0A043B;
}

.rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title {
	color: #151515;
}

.rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title a,
.rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title a:focus,
.rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title a:active {
	color: inherit;
}

.rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title a:hover {
	color: #0A043B;
}

.rd-navbar-fixed .rd-navbar-submenu-toggle {
	color: #151515;
}

.rd-navbar-fixed .rd-navbar-search .rd-search-form-submit {
	color: #d6d6d6;
}

.rd-navbar-fixed .rd-navbar-search .rd-search-form-submit:hover {
	color: #0A043B;
}

.rd-navbar-fixed .rd-navbar-search-toggle {
	color: #151515;
}

.rd-navbar-fixed .rd-navbar-search-toggle:hover {
	color: #0A043B;
}

.rd-navbar-fixed .rd-navbar-collapse {
	background-color: #ffffff;
	box-shadow: 0 0 22px -4px rgba(0, 0, 0, 0.17);
}

.rd-navbar-fixed .rd-navbar-collapse,
.rd-navbar-fixed .rd-navbar-collapse a {
	color: #151515;
}

.context-dark .rd-navbar-fixed .rd-navbar-panel,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-panel,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-panel,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-panel,
.bg-primary .rd-navbar-fixed .rd-navbar-panel {
	background: #2c343b;
}

.context-dark .rd-navbar-fixed .rd-navbar-toggle,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle {
	display: inline-block;
	position: relative;
	width: 40px;
	height: 40px;
	line-height: 40px;
	cursor: pointer;
	color: #ffffff;
}

.context-dark .rd-navbar-fixed .rd-navbar-toggle span,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle span,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle span,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle span,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle span {
	position: relative;
	display: block;
	margin: auto;
	transition: .3s all ease;
}

.context-dark .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle span::after,
.context-dark .rd-navbar-fixed .rd-navbar-toggle span::before,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle span::before,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle span::before,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle span::before,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle span::before {
	content: "";
	position: absolute;
	left: 0;
	top: -6px;
	transition: .3s all ease;
}

.context-dark .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle span::after {
	top: 6px;
}

.context-dark .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle span::after,
.context-dark .rd-navbar-fixed .rd-navbar-toggle span::before,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle span::before,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle span::before,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle span::before,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle span::before,
.context-dark .rd-navbar-fixed .rd-navbar-toggle span,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle span,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle span,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle span,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle span {
	width: 24px;
	height: 2px;
	background-color: #ffffff;
	backface-visibility: hidden;
	border-radius: 0;
}

.context-dark .rd-navbar-fixed .rd-navbar-toggle span::before,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle span::before,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle span::before,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle span::before,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle span::before,
.context-dark .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle span::after,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle span::after {
	transform-origin: 1px center;
}

.context-dark .rd-navbar-fixed .rd-navbar-toggle.active span::before,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle.active span::before,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle.active span::before,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle.active span::before,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle.active span::before,
.context-dark .rd-navbar-fixed .rd-navbar-toggle.active span::after,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle.active span::after,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle.active span::after,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle.active span::after,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle.active span::after {
	top: 0;
	width: 15px;
}

.context-dark .rd-navbar-fixed .rd-navbar-toggle.active span::before,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle.active span::before,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle.active span::before,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle.active span::before,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle.active span::before {
	transform: rotate(-40deg);
}

.context-dark .rd-navbar-fixed .rd-navbar-toggle.active span::after,
.bg-gray-700 .rd-navbar-fixed .rd-navbar-toggle.active span::after,
.bg-blue-13 .rd-navbar-fixed .rd-navbar-toggle.active span::after,
.bg-blue-15 .rd-navbar-fixed .rd-navbar-toggle.active span::after,
.bg-primary .rd-navbar-fixed .rd-navbar-toggle.active span::after {
	transform: rotate(40deg);
}

html.rd-navbar-fixed-linked .page {
	padding-top: 56px;
}

@media(max-width: 768px){
	html.rd-navbar-fixed-linked .page {
		padding-top: 0px !important;
	}	
}

/*
*
* RD Navbar Sidebar
*/

.rd-navbar-sidebar {
	display: block;
	background: #ffffff;
}

.rd-navbar-sidebar .rd-navbar-main-outer {
	padding-left: 15px;
	padding-right: 15px;
}

.rd-navbar-sidebar .rd-navbar-main {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 22px 60px 22px 0;
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
	transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.rd-navbar-sidebar .rd-navbar-main-element {
	display: flex;
	align-items: center;
}

.rd-navbar-sidebar .rd-navbar-main-element>*+* {
	margin-left: 20px;
}

.rd-navbar-sidebar .rd-navbar-toggle {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	display: block;
	z-index: 1033;
}

.rd-navbar-sidebar .rd-navbar-panel {
	min-width: 200px;
	text-align: center;
}

.rd-navbar-sidebar .rd-navbar-brand img {
	/* width: auto;
    height: auto; */
	max-width: 100px;
	max-height: 100px;
}

.rd-navbar-sidebar .rd-navbar-nav-wrap {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 1032;
	width: 450px;
	padding: 94px 0 0 0;
	background: #ffffff;
	transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
	transform: translateX(100%);
	box-shadow: 0 0 13px 0 rgba(214, 214, 214, 0.16);
}

.rd-navbar-sidebar .rd-navbar-nav-wrap.active {
	transition-delay: .1s;
	transform: translateX(0);
}

.rd-navbar-sidebar .rd-navbar-nav {
	height: calc(100vh - 100px);
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	border-top: 1px solid #e0e0e0;
}

.rd-navbar-sidebar .rd-nav-item {
	position: relative;
	display: block;
	padding: 19px 30px 19px 40px;
}

.rd-navbar-sidebar .rd-nav-item.active .rd-nav-link {
	color: #0A043B;
}

.rd-navbar-sidebar .rd-nav-item .rd-nav-link,
.rd-navbar-sidebar .rd-nav-item>.rd-navbar-submenu-toggle {
	font-size: 18px;
}

.rd-navbar-sidebar .rd-nav-item+.rd-nav-item {
	border-top: 1px solid #e0e0e0;
}

.rd-navbar-sidebar .rd-nav-item:last-child {
	border-bottom: 1px solid #e0e0e0;
	margin-bottom: 30px;
}

.rd-navbar-sidebar .rd-nav-link {
	max-width: calc(100% - 30px);
	word-break: break-all;
	font-size: 18px;
	line-height: 1.4;
	text-transform: none;
	color: #d6d6d6;
}

.rd-navbar-sidebar .rd-nav-link:hover {
	color: #0A043B;
}

.rd-navbar-sidebar .rd-navbar-submenu .opened>.rd-navbar-submenu-toggle::after {
	transform: rotate(180deg);
}

.rd-navbar-sidebar .rd-navbar-submenu>.rd-navbar-dropdown,
.rd-navbar-sidebar .rd-navbar-submenu>.rd-navbar-megamenu {
	display: none;
	opacity: 0;
	visibility: hidden;
	will-change: opacity, visibility;
	transition: opacity .2s;
}

.rd-navbar-sidebar .rd-navbar-submenu.opened>.rd-navbar-dropdown,
.rd-navbar-sidebar .rd-navbar-submenu.opened>.rd-navbar-megamenu {
	display: block;
	opacity: 1;
	visibility: visible;
}

.rd-navbar-sidebar .rd-navbar-submenu>.rd-navbar-submenu>.rd-navbar-dropdown,
.rd-navbar-sidebar .rd-navbar-submenu>.rd-navbar-submenu>.rd-navbar-megamenu {
	transform: translateY(30px);
}

.rd-navbar-sidebar .rd-navbar-submenu>.rd-navbar-submenu.opened>.rd-navbar-dropdown,
.rd-navbar-sidebar .rd-navbar-submenu>.rd-navbar-submenu.opened>.rd-navbar-megamenu {
	transform: translateY(0);
}

.rd-navbar-sidebar .rd-navbar-submenu>.rd-navbar-submenu .rd-navbar-submenu>.rd-navbar-dropdown {
	transform: translateX(-20px);
}

.rd-navbar-sidebar .rd-navbar-submenu>.rd-navbar-submenu .rd-navbar-submenu.opened>.rd-navbar-dropdown {
	transform: translateX(0);
}

.rd-navbar-sidebar .rd-nav-item>.rd-navbar-submenu-toggle,
.rd-navbar-sidebar .rd-nav-item .rd-navbar--has-dropdown>.rd-navbar-submenu-toggle {
	position: absolute;
	top: 0;
	right: 0;
	padding-top: inherit;
	padding-right: inherit;
	padding-left: 10px;
	margin-bottom: inherit;
	display: inline-block;
	width: 30px;
	margin-left: 5px;
	text-align: center;
	cursor: pointer;
	color: #151515;
}

.rd-navbar-sidebar .rd-nav-item>.rd-navbar-submenu-toggle:hover,
.rd-navbar-sidebar .rd-nav-item .rd-navbar--has-dropdown>.rd-navbar-submenu-toggle:hover {
	color: #0A043B;
}

.rd-navbar-sidebar .rd-nav-item>.rd-navbar-submenu-toggle::after,
.rd-navbar-sidebar .rd-nav-item .rd-navbar--has-dropdown>.rd-navbar-submenu-toggle::after {
	content: '\f107';
	position: relative;
	display: inline-block;
	font-family: "FontAwesome";
	font-size: inherit;
	line-height: inherit;
	text-align: center;
	vertical-align: middle;
	transition: 0.4s all ease;
	z-index: 2;
	will-change: transform;
}

.rd-navbar-sidebar .rd-nav-item .rd-navbar--has-dropdown>.rd-navbar-submenu-toggle {
	font-size: 14px;
	color: #d6d6d6;
	margin-left: 7px;
}

.rd-navbar-sidebar .rd-menu {
	margin-top: 31px;
}

.rd-navbar-sidebar .rd-navbar-dropdown,
.rd-navbar-sidebar .rd-megamenu-list {
	font-size: 14px;
}

.rd-navbar-sidebar .rd-navbar-dropdown li>a,
.rd-navbar-sidebar .rd-megamenu-list li>a {
	position: relative;
	display: flex;
	padding: 2px 0 2px 17px;
	text-align: left;
}

.rd-navbar-sidebar .rd-navbar-dropdown li>a,
.rd-navbar-sidebar .rd-navbar-dropdown li>a:focus,
.rd-navbar-sidebar .rd-navbar-dropdown li>a:active,
.rd-navbar-sidebar .rd-megamenu-list li>a,
.rd-navbar-sidebar .rd-megamenu-list li>a:focus,
.rd-navbar-sidebar .rd-megamenu-list li>a:active {
	color: #151515;
}

.rd-navbar-sidebar .rd-navbar-dropdown li>a:hover,
.rd-navbar-sidebar .rd-megamenu-list li>a:hover {
	color: #0A043B;
}

.rd-navbar-sidebar .rd-navbar-dropdown li>a,
.rd-navbar-sidebar .rd-navbar-dropdown li>a::before,
.rd-navbar-sidebar .rd-megamenu-list li>a,
.rd-navbar-sidebar .rd-megamenu-list li>a::before {
	transition: .15s ease-in-out;
}

.rd-navbar-sidebar .rd-navbar-dropdown li>a::before,
.rd-navbar-sidebar .rd-megamenu-list li>a::before {
	content: '';
	position: absolute;
	top: 10px;
	left: 0;
	display: inline-block;
	width: 0;
	height: 0;
	font-size: 0;
	line-height: 0;
	border-style: solid;
	border-width: 4px 0 4px 10px;
	border-color: transparent transparent transparent #b7b7b7;
}

.rd-navbar-sidebar .rd-navbar-dropdown li.focus>a::before,
.rd-navbar-sidebar .rd-navbar-dropdown li.opened>a::before,
.rd-navbar-sidebar .rd-navbar-dropdown li>a:hover::before,
.rd-navbar-sidebar .rd-megamenu-list li.focus>a::before,
.rd-navbar-sidebar .rd-megamenu-list li.opened>a::before,
.rd-navbar-sidebar .rd-megamenu-list li>a:hover::before {
	border-left-color: #0A043B;
}

.rd-navbar-sidebar .rd-navbar-dropdown li+li,
.rd-navbar-sidebar .rd-megamenu-list li+li {
	margin-top: 8px;
}

.rd-navbar-sidebar .rd-navbar-megamenu {
	max-width: 450px;
	margin-bottom: -30px;
}

.rd-navbar-sidebar .rd-navbar-megamenu>* {
	margin-bottom: 30px;
}

.rd-navbar-sidebar .rd-navbar-megamenu>li {
	display: inline-block;
	vertical-align: top;
	width: 45%;
}

.rd-navbar-sidebar .rd-megamenu-title {
	display: none;
}

.rd-navbar-sidebar .rd-megamenu-list {
	margin-top: 0;
}

.rd-navbar-sidebar .rd-megamenu-list>li+li {
	margin-top: 10px;
}

.rd-navbar-sidebar .rd-navbar-dropdown .rd-navbar--has-dropdown>a {
	padding-right: 0;
}

.rd-navbar-sidebar .rd-navbar-dropdown .rd-navbar--has-dropdown>a::before {
	display: none;
}

.rd-navbar-sidebar .rd-navbar-dropdown .rd-navbar--has-dropdown>a:hover {
	padding-left: 0;
}

.rd-navbar-sidebar .rd-navbar-dropdown .rd-navbar--has-dropdown.focus>a {
	padding-left: 0;
}

.rd-navbar-sidebar .rd-navbar-dropdown .rd-navbar--has-dropdown.opened>.rd-navbar-submenu-toggle {
	color: #0A043B;
}

.rd-navbar-sidebar .rd-navbar-dropdown .rd-navbar--has-dropdown.opened>.rd-navbar-submenu-toggle::after {
	top: 1px;
}

.rd-navbar-sidebar .rd-navbar-dropdown>li+li {
	margin-top: 10px;
}

.rd-navbar-sidebar .rd-nav-item>.rd-navbar-dropdown {
	margin-top: 25px;
}

.rd-navbar-sidebar .rd-nav-item>.rd-navbar-dropdown .rd-navbar-dropdown {
	margin-top: 10px;
	padding-left: 15px;
}

.rd-navbar-sidebar .rd-nav-item>.rd-navbar-dropdown .rd-navbar-dropdown>li>a {
	font-size: 12px;
}

@media (min-width: 1200px) {
	.rd-navbar-sidebar .rd-navbar-megamenu {
		margin-bottom: -30px;
	}
	.rd-navbar-sidebar .rd-navbar-megamenu>* {
		margin-bottom: 30px;
	}
	.rd-navbar-sidebar .rd-navbar-dropdown>li+li,
	.rd-navbar-sidebar .rd-megamenu-list>li+li {
		margin-top: 18px;
	}
}

@media (min-width: 1200px) {
	.rd-navbar-sidebar .rd-navbar-nav-wrap {
		width: auto;
		left: calc(50% + 600px - 80px);
	}
}

.rd-navbar-sidebar.rd-navbar--is-clone {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1000;
	transform: translateY(-101%);
}

.rd-navbar-sidebar.rd-navbar--is-clone.rd-navbar--is-stuck {
	transform: translateY(0);
}

.rd-navbar-sidebar.rd-navbar--is-stuck {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 1000;
	box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.1);
}

.rd-navbar-sidebar.rd-navbar--is-stuck .rd-navbar-main {
	padding-top: 11px;
	padding-bottom: 11px;
}


/**
*
* RD Navbar Classic
*/

.rd-navbar-classic .rd-navbar-aside {
	white-space: nowrap;
}

.rd-navbar-classic .rd-navbar-aside>* {
	margin-top: 0;
}

.rd-navbar-classic .rd-navbar-aside>*+* {
	margin-left: 5px;
}

.rd-navbar-classic .rd-navbar-aside-item {
	display: inline-block;
	vertical-align: middle;
	white-space: normal;
}

.rd-navbar-classic.rd-navbar-static {
	transition: none;
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-main-outer {
	display: flex;
	padding-left: 15px;
	padding-right: 15px;
	transform: translate3d(0, 0, 0);
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-panel {
	flex-grow: 1;
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-main {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	max-width: 930px;
	min-height: 90px;
	padding: 16px 0;
	margin-left: auto;
	margin-right: auto;
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-aside-item {
	position: relative;
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-popup {
	margin-top: 35px;
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-brand {
	max-width: 150px;
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-brand img {
	/* width: auto;
    height: auto; */
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-main-element {
	display: flex;
	align-items: center;
	padding-left: 20px;
	padding-right: 20px;
}

.rd-navbar-classic.rd-navbar-static .rd-nav-item>.rd-navbar-dropdown {
	margin-top: 28px;
}

@media (min-width: 1200px) {
	.rd-navbar-classic.rd-navbar-static .rd-navbar-main {
		min-height: 110px;
		/* max-width: 1170px; */
	}
	.rd-navbar-classic.rd-navbar-static .rd-navbar-main-element {
		padding-left: 50px;
		padding-right: 50px;
	}
	.rd-navbar-classic.rd-navbar-static .rd-navbar-brand {
		max-width: 143px;
	}
}

@media (min-width: 1600px) {
	.rd-navbar-classic.rd-navbar-static .rd-navbar-popup {
		left: 50%;
		transform: translate3d(-50%, 0, 0);
		right: auto;
	}
}

.rd-navbar-classic.rd-navbar-static.rd-navbar--is-stuck,
.rd-navbar-classic.rd-navbar-static.rd-navbar--is-clone {
	box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.1);
}

.rd-navbar-classic.rd-navbar-static.rd-navbar--is-stuck .rd-navbar-main,
.rd-navbar-classic.rd-navbar-static.rd-navbar--is-clone .rd-navbar-main {
	min-height: 75px;
}

.rd-navbar-classic.rd-navbar-static.rd-navbar--is-stuck .rd-nav-item>.rd-navbar-dropdown,
.rd-navbar-classic.rd-navbar-static.rd-navbar--is-clone .rd-nav-item>.rd-navbar-dropdown {
	margin-top: 18px;
}

.rd-navbar-classic.rd-navbar-static.rd-navbar--is-stuck .rd-navbar-popup,
.rd-navbar-classic.rd-navbar-static.rd-navbar--is-clone .rd-navbar-popup {
	margin-top: 17px;
}

.rd-navbar-classic.rd-navbar-static.rd-navbar--is-stuck .rd-navbar-nav-item>.rd-navbar-submenu,
.rd-navbar-classic.rd-navbar-static.rd-navbar--is-clone .rd-navbar-nav-item>.rd-navbar-submenu {
	margin-top: 17px;
}

.rd-navbar-classic.rd-navbar-fixed .rd-navbar-panel {
	box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.1);
}

.rd-navbar-classic.rd-navbar-fixed .rd-navbar-brand {
	max-height: 52px;
	max-width: 160px;
}

.rd-navbar-classic.rd-navbar-fixed .rd-navbar-aside {
	position: fixed;
	right: 8px;
	top: 0;
	display: flex;
	align-items: center;
	height: 56px;
	z-index: 1030;
}

.rd-navbar-classic.rd-navbar-fixed .rd-navbar-aside .button-icon {
	padding: 5px;
	font-size: 0;
}

.rd-navbar-classic.rd-navbar-fixed .rd-navbar-aside .button-icon .icon {
	padding: 0;
	font-size: 20px;
}

.rd-navbar-classic.rd-navbar-fixed .rd-navbar-popup {
	position: absolute;
	margin-top: 11px;
	width: 100vw;
	right: -8px;
	overflow-y: auto;
	max-height: calc(100vh - 56px - 10px);
}

@media (min-width: 576px) {
	.rd-navbar-classic.rd-navbar-fixed .rd-navbar-popup {
		width: 360px;
	}
}

.context-dark .rd-navbar-classic .rd-navbar-aside-item>.button-primary:not(:hover),
.bg-gray-700 .rd-navbar-classic .rd-navbar-aside-item>.button-primary:not(:hover),
.bg-blue-13 .rd-navbar-classic .rd-navbar-aside-item>.button-primary:not(:hover),
.bg-blue-15 .rd-navbar-classic .rd-navbar-aside-item>.button-primary:not(:hover),
.bg-primary .rd-navbar-classic .rd-navbar-aside-item>.button-primary:not(:hover) {
	background: #5c6367;
	border-color: #5c6367;
}

.context-dark .rd-navbar-classic .rd-navbar-aside-item>.button-primary:not(:hover) .icon,
.bg-gray-700 .rd-navbar-classic .rd-navbar-aside-item>.button-primary:not(:hover) .icon,
.bg-blue-13 .rd-navbar-classic .rd-navbar-aside-item>.button-primary:not(:hover) .icon,
.bg-blue-15 .rd-navbar-classic .rd-navbar-aside-item>.button-primary:not(:hover) .icon,
.bg-primary .rd-navbar-classic .rd-navbar-aside-item>.button-primary:not(:hover) .icon {
	color: #0A043B;
}

.context-dark .rd-navbar-classic .rd-navbar-aside-item>.button-primary-outline:not(:hover),
.bg-gray-700 .rd-navbar-classic .rd-navbar-aside-item>.button-primary-outline:not(:hover),
.bg-blue-13 .rd-navbar-classic .rd-navbar-aside-item>.button-primary-outline:not(:hover),
.bg-blue-15 .rd-navbar-classic .rd-navbar-aside-item>.button-primary-outline:not(:hover),
.bg-primary .rd-navbar-classic .rd-navbar-aside-item>.button-primary-outline:not(:hover) {
	color: #ffffff;
	border-color: #5c6367;
}

.context-dark .rd-navbar-classic .rd-navbar-aside-item>.button-primary-outline:not(:hover) .icon,
.bg-gray-700 .rd-navbar-classic .rd-navbar-aside-item>.button-primary-outline:not(:hover) .icon,
.bg-blue-13 .rd-navbar-classic .rd-navbar-aside-item>.button-primary-outline:not(:hover) .icon,
.bg-blue-15 .rd-navbar-classic .rd-navbar-aside-item>.button-primary-outline:not(:hover) .icon,
.bg-primary .rd-navbar-classic .rd-navbar-aside-item>.button-primary-outline:not(:hover) .icon {
	color: #0A043B;
}

.context-dark .rd-navbar-static.rd-navbar-classic .rd-nav-link,
.bg-gray-700 .rd-navbar-static.rd-navbar-classic .rd-nav-link,
.bg-blue-13 .rd-navbar-static.rd-navbar-classic .rd-nav-link,
.bg-blue-15 .rd-navbar-static.rd-navbar-classic .rd-nav-link,
.bg-primary .rd-navbar-static.rd-navbar-classic .rd-nav-link,
.context-dark .rd-navbar-static.rd-navbar-classic .rd-navbar-submenu-toggle,
.bg-gray-700 .rd-navbar-static.rd-navbar-classic .rd-navbar-submenu-toggle,
.bg-blue-13 .rd-navbar-static.rd-navbar-classic .rd-navbar-submenu-toggle,
.bg-blue-15 .rd-navbar-static.rd-navbar-classic .rd-navbar-submenu-toggle,
.bg-primary .rd-navbar-static.rd-navbar-classic .rd-navbar-submenu-toggle {
	color: #ffffff;
}

.context-dark .rd-navbar-static.rd-navbar-classic .rd-nav-link:hover~.rd-navbar-submenu-toggle,
.bg-gray-700 .rd-navbar-static.rd-navbar-classic .rd-nav-link:hover~.rd-navbar-submenu-toggle,
.bg-blue-13 .rd-navbar-static.rd-navbar-classic .rd-nav-link:hover~.rd-navbar-submenu-toggle,
.bg-blue-15 .rd-navbar-static.rd-navbar-classic .rd-nav-link:hover~.rd-navbar-submenu-toggle,
.bg-primary .rd-navbar-static.rd-navbar-classic .rd-nav-link:hover~.rd-navbar-submenu-toggle,
.context-dark .rd-navbar-static.rd-navbar-classic .rd-nav-link:hover,
.bg-gray-700 .rd-navbar-static.rd-navbar-classic .rd-nav-link:hover,
.bg-blue-13 .rd-navbar-static.rd-navbar-classic .rd-nav-link:hover,
.bg-blue-15 .rd-navbar-static.rd-navbar-classic .rd-nav-link:hover,
.bg-primary .rd-navbar-static.rd-navbar-classic .rd-nav-link:hover,
.context-dark .rd-navbar-static.rd-navbar-classic .rd-nav-item.active .rd-nav-link,
.bg-gray-700 .rd-navbar-static.rd-navbar-classic .rd-nav-item.active .rd-nav-link,
.bg-blue-13 .rd-navbar-static.rd-navbar-classic .rd-nav-item.active .rd-nav-link,
.bg-blue-15 .rd-navbar-static.rd-navbar-classic .rd-nav-item.active .rd-nav-link,
.bg-primary .rd-navbar-static.rd-navbar-classic .rd-nav-item.active .rd-nav-link,
.context-dark .rd-navbar-static.rd-navbar-classic .rd-nav-item.active .rd-navbar-submenu-toggle,
.bg-gray-700 .rd-navbar-static.rd-navbar-classic .rd-nav-item.active .rd-navbar-submenu-toggle,
.bg-blue-13 .rd-navbar-static.rd-navbar-classic .rd-nav-item.active .rd-navbar-submenu-toggle,
.bg-blue-15 .rd-navbar-static.rd-navbar-classic .rd-nav-item.active .rd-navbar-submenu-toggle,
.bg-primary .rd-navbar-static.rd-navbar-classic .rd-nav-item.active .rd-navbar-submenu-toggle,
.context-dark .rd-navbar-static.rd-navbar-classic .rd-nav-item.focus .rd-nav-link,
.bg-gray-700 .rd-navbar-static.rd-navbar-classic .rd-nav-item.focus .rd-nav-link,
.bg-blue-13 .rd-navbar-static.rd-navbar-classic .rd-nav-item.focus .rd-nav-link,
.bg-blue-15 .rd-navbar-static.rd-navbar-classic .rd-nav-item.focus .rd-nav-link,
.bg-primary .rd-navbar-static.rd-navbar-classic .rd-nav-item.focus .rd-nav-link,
.context-dark .rd-navbar-static.rd-navbar-classic .rd-nav-item.focus .rd-navbar-submenu-toggle,
.bg-gray-700 .rd-navbar-static.rd-navbar-classic .rd-nav-item.focus .rd-navbar-submenu-toggle,
.bg-blue-13 .rd-navbar-static.rd-navbar-classic .rd-nav-item.focus .rd-navbar-submenu-toggle,
.bg-blue-15 .rd-navbar-static.rd-navbar-classic .rd-nav-item.focus .rd-navbar-submenu-toggle,
.bg-primary .rd-navbar-static.rd-navbar-classic .rd-nav-item.focus .rd-navbar-submenu-toggle {
	color: #0A043B;
}

.jumbotron-creative .rd-navbar-static.rd-navbar-classic:not(.rd-navbar--is-stuck) .rd-nav-link,
.jumbotron-creative .rd-navbar-static.rd-navbar-classic:not(.rd-navbar--is-stuck) .rd-navbar-submenu-toggle {
	color: #0A043B;
}

.jumbotron-creative .rd-navbar-static.rd-navbar-classic:not(.rd-navbar--is-stuck) .rd-nav-link:hover~.rd-navbar-submenu-toggle,
.jumbotron-creative .rd-navbar-static.rd-navbar-classic:not(.rd-navbar--is-stuck) .rd-nav-link:hover,
.jumbotron-creative .rd-navbar-static.rd-navbar-classic:not(.rd-navbar--is-stuck) .rd-nav-item.active .rd-nav-link,
.jumbotron-creative .rd-navbar-static.rd-navbar-classic:not(.rd-navbar--is-stuck) .rd-nav-item.active .rd-navbar-submenu-toggle,
.jumbotron-creative .rd-navbar-static.rd-navbar-classic:not(.rd-navbar--is-stuck) .rd-nav-item.focus .rd-nav-link,
.jumbotron-creative .rd-navbar-static.rd-navbar-classic:not(.rd-navbar--is-stuck) .rd-nav-item.focus .rd-navbar-submenu-toggle {
	/* color: rgba(255, 255, 255, 0.6); */
	color: #0A043B;
}

.jumbotron-creative .rd-navbar-static.rd-navbar-classic:not(.rd-navbar--is-stuck) .rd-navbar-aside-item>.button-primary-outline,
.jumbotron-creative .rd-navbar-static.rd-navbar-classic:not(.rd-navbar--is-stuck) .rd-navbar-aside-item>.button-primary-outline:focus {
	color: #0A043B;
	background-color: transparent;
	/* border-color: rgba(255, 255, 255, 0.2); */
	border-color: #0A043B;
}

.jumbotron-creative .rd-navbar-static.rd-navbar-classic:not(.rd-navbar--is-stuck) .rd-navbar-aside-item>.button-primary-outline:hover,
.jumbotron-creative .rd-navbar-static.rd-navbar-classic:not(.rd-navbar--is-stuck) .rd-navbar-aside-item>.button-primary-outline:active {
	color: #0A043B;
	background-color: #ffffff;
	border-color: #0A043B;
	/* border-color: #3f3a3a; */
}

.jumbotron-creative .rd-navbar-static.rd-navbar-classic:not(.rd-navbar--is-stuck) .rd-navbar-aside-item>.button-primary,
.jumbotron-creative .rd-navbar-static.rd-navbar-classic:not(.rd-navbar--is-stuck) .rd-navbar-aside-item>.button-primary:focus {
	color: #ffffff;
	background-color: #0A043B;
	border-color: #0A043B;
}

.jumbotron-creative .rd-navbar-static.rd-navbar-classic:not(.rd-navbar--is-stuck) .rd-navbar-aside-item>.button-primary:hover,
.jumbotron-creative .rd-navbar-static.rd-navbar-classic:not(.rd-navbar--is-stuck) .rd-navbar-aside-item>.button-primary:active {
	color: #ffffff;
	background-color: #ca5217;
	border-color: #ca5217;
}


/*
*
* RD Navbar Fullwidth
*/

.rd-navbar-fullwidth {
	display: block;
}

.rd-navbar-fullwidth .rd-navbar-nav>li+li {
	margin-left: 20px;
}

.rd-navbar-fullwidth.rd-navbar--is-stuck .rd-navbar-main,
.rd-navbar-fullwidth.rd-navbar--is-clone .rd-navbar-main {
	padding: 10px 0;
}

.context-dark .rd-navbar,
.bg-gray-700 .rd-navbar,
.bg-blue-13 .rd-navbar,
.bg-blue-15 .rd-navbar,
.bg-primary .rd-navbar {
	background-color: transparent;
}

.rd-navbar-wrap[style*='height: 0px;'] {
	margin-top: -1px;
}

.rd-navbar-wrap {
	height: 0px !important;
}

/*
*
* Search Results
*/

.rd-search-results-live {
	position: absolute;
	left: 0;
	right: 0;
	top: 100%;
	max-height: 600px;
	overflow-y: auto;
	margin: -3px 0 0;
	text-align: left;
	z-index: 998;
}

.rd-search-results-live #search-results {
	position: relative;
	padding: 16px 0 0;
	color: #151515;
	background: #ffffff;
	opacity: 0;
	visibility: hidden;
	transform-origin: 50% 0;
	transform: scale(1, 0.9);
	transition: .3s all ease;
}

.rd-search-results-live #search-results::before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	pointer-events: none;
	z-index: -1;
	border: 1px solid #e0e0e0;
	border-top: 0;
}

.rd-search-results-live #search-results.active {
	opacity: 1;
	visibility: visible;
	transform: scale(1, 1);
}

.rd-search-results-live .search-quick-result {
	padding-left: 15px;
	padding-right: 15px;
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 14px;
	font-weight: 700;
	line-height: 30px;
	text-transform: uppercase;
	color: #151515;
}

.rd-search-results-live .search-list {
	margin-top: 11px;
}

.rd-search-results-live .search-list li:only-child {
	padding: 0 15px 15px;
}

.rd-search-results-live .search-link {
	color: #d6d6d6;
}

.rd-search-results-live .search-link:hover {
	color: #0A043B;
}

.rd-search-results-live .search-error {
	font-size: 14px;
	line-height: 1.6;
}

.rd-search-results-live .search-title {
	position: relative;
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 14px;
	font-weight: 700;
	color: #151515;
}

.rd-search-results-live .search-title a:hover {
	color: #0A043B;
}

.rd-search-results-live .search-list-item-all {
	margin-top: 18px;
	width: 100%;
}

.rd-search-results-live .search-submit {
	position: relative;
	overflow: hidden;
	z-index: 0;
	display: block;
	padding: 8px;
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 14px;
	font-weight: 700;
	letter-spacing: 0.025em;
	text-transform: uppercase;
	text-align: center;
	color: #ffffff;
	background: #0A043B;
}

.rd-search-results-live .search-submit:hover {
	color: #ffffff;
	background: #d6d6d6;
}

.rd-search-results-live .match {
	display: none;
}

.not-empty~.rd-search-results-live {
	visibility: visible;
	opacity: 1;
}

.rd-search-results-live p {
	font-size: 11px;
}

.rd-search-results-live p * {
	margin: 0;
}

.rd-search-results-live .search-list-item {
	padding: 0 15px;
}

.rd-search-results-live *+p {
	margin-top: 5px;
}

.rd-search-results-live .search-list-item+.search-list-item {
	margin-top: 17px;
}

.rd-search-results .search-list {
	counter-reset: li;
	text-align: left;
	padding-left: 0;
	font-size: 18px;
	list-style-type: none;
	overflow: hidden;
}

.rd-search-results .search-list li div {
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
	white-space: nowrap;
}

.rd-search-results .search-list li:only-child::before {
	display: none;
}

.rd-search-results .search-list-item {
	position: relative;
	padding-left: 40px;
	font-size: 1rem;
	color: #9a9a9a;
}

.rd-search-results .search-list-item::before {
	content: counter(li, decimal-leading-zero) ".";
	counter-increment: li;
	position: absolute;
	left: 0;
	top: 0;
	font-size: 14px;
	line-height: inherit;
	font-weight: 300;
	color: #000000;
}

.rd-search-results .search-list-item+.search-list-item {
	margin-top: 40px;
}

.rd-search-results .search-title {
	font-size: 16px;
	color: #000000;
}

.rd-search-results .search {
	color: #ffffff;
	padding: 0 .25em;
	background: #0A043B;
}

.rd-search-results .match {
	padding: 5px;
	font-size: 12px;
	line-height: 1.7;
	letter-spacing: .1em;
	text-transform: uppercase;
	color: #000000;
}

.rd-search-results .match em {
	margin: 0;
	font-style: normal;
}

.rd-search-results p * {
	margin: 0;
}

.rd-search-results *+.match {
	margin-top: 10px;
}

@media (min-width: 768px) {
	.rd-search-results .search-title {
		font-size: 18px;
	}
	.rd-search-results .search-list-item::before {
		top: -1px;
		font-size: 16px;
	}
	.rd-search-results *+p {
		margin-top: 12px;
	}
}

@media (min-width: 992px) {
	.rd-search-results .search-list-item {
		padding-left: 40px;
	}
}

*+.rd-search-results {
	margin-top: 40px;
}

@media (min-width: 768px) {
	*+.rd-search-results {
		margin-top: 55px;
	}
}

.rd-search-classic {
	position: relative;
}

.rd-search-classic .form-input {
	color: #9a9a9a;
	padding-right: 60px;
}

.rd-search-classic .form-input,
.rd-search-classic .form-label {
	letter-spacing: 0;
}

.rd-search-classic .rd-search-submit {
	background: none;
	border: none;
	display: inline-block;
	padding: 0;
	outline: none;
	outline-offset: 0;
	cursor: pointer;
	-webkit-appearance: none;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	width: 50px;
	padding-top: 3px;
	padding-right: 2px;
	margin: 0;
	text-align: center;
	color: #151515;
	font-size: 0;
	line-height: 0;
}

.rd-search-classic .rd-search-submit::-moz-focus-inner {
	border: none;
	padding: 0;
}

.rd-search-classic .rd-search-submit:before {
	position: relative;
	top: -1px;
	content: '\e01c';
	font: 400 20px 'fl-budicons-launch';
	line-height: 1;
	transition: .33s all ease;
}

.rd-search-classic .rd-search-submit:hover {
	color: #0A043B;
}

.rd-search-classic.form-lg .form-input {
	padding-right: 70px;
}

.rd-search-classic.form-lg .rd-search-submit {
	width: 60px;
}

.rd-search.form-inline {
	position: relative;
}

.rd-search.form-inline .form-input {
	padding-right: 50px;
}

.rd-search.form-inline .button-link {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 50px;
	right: 0;
	margin: 0;
	font-size: 21px;
	color: #d6d6d6;
	transition: .33s;
}

.rd-search.form-inline .button-link::before {
	display: block;
	margin: auto;
}

.rd-search.form-inline .button-link:hover {
	color: #0A043B;
}

.rd-search.form-inline.form-sm .form-input {
	padding-right: 40px;
}

.rd-search.form-inline.form-sm .button-link {
	width: 40px;
	font-size: 18px;
}

.rd-search.form-inline.form-lg .form-input {
	padding-right: 60px;
}

.rd-search.form-inline.form-lg .button-link {
	width: 60px;
}

.tabs-custom {
	text-align: left;
}

.tabs-custom .nav-tabs {
	display: block;
	font-size: 0;
	line-height: 0;
	word-spacing: 0;
	border: 0;
}

.tabs-custom .nav-tabs:before,
.tabs-custom .nav-tabs:after {
	display: none;
}

.tabs-custom .nav-item {
	float: none;
	border: 0;
	cursor: pointer;
	transition: .33s all ease;
}

.tabs-custom .nav-link {
	margin: 0;
	border-radius: 0;
	border: 0;
}

.tabs-custom .nav-link.active {
	cursor: default;
	border: 0;
}

.tab-content>.tab-pane {
	display: block;
	visibility: hidden;
	height: 0;
	overflow: hidden;
}

.tab-content>.active {
	visibility: visible;
	height: auto;
	overflow: visible;
}

*+.tabs-horizontal.tabs-minimal {
	margin-top: 35px;
}

*+.tabs-vertical.tabs-minimal {
	margin-top: 40px;
}

@media (min-width: 768px) {
	*+.tabs-vertical.tabs-minimal {
		margin-top: 60px;
	}
}

@media (min-width: 1200px) {
	*+.tabs-vertical.tabs-minimal {
		margin-top: 80px;
	}
}

.tabs-minimal .nav-tabs {
	margin-bottom: -15px;
	text-align: center;
}

.tabs-minimal .nav-tabs>* {
	margin-bottom: 15px;
}

.tabs-minimal .nav-item {
	display: inline-block;
	padding: 0 7px;
}

.tabs-minimal .nav-item:not(:last-child) {
	border-right: 1px solid #f3f4f9;
}

.tabs-minimal .nav-link {
	padding: 0 8px;
	font: 600 14px/20px "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", rubiklight, "Helvetica Neue", Arial, sans-serif;
	text-transform: uppercase;
	letter-spacing: .05em;
	color: #868e96;
	vertical-align: middle;
}

.tabs-minimal .nav-link:hover,
.tabs-minimal .nav-link.active {
	color: #0A043B;
}

.tabs-minimal .tab-content {
	padding: 33px 0 0;
}

@media (min-width: 768px) {
	.tabs-horizontal.tabs-minimal .nav-item {
		padding: 0 18px;
	}
	.tabs-vertical {
		display: flex;
		align-items: flex-start;
	}
	.tabs-vertical .nav-tabs {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		flex-shrink: 0;
		max-width: 50%;
	}
	.tabs-vertical .nav-item {
		border: 0;
		width: 100%;
		text-align: left;
	}
	.tabs-vertical .tab-content {
		flex-grow: 1;
	}
	.tabs-vertical.tabs-minimal .nav-tabs {
		width: auto;
		min-width: 180px;
		border: 0;
	}
	.tabs-vertical.tabs-minimal .nav-item {
		margin: 0;
		border: 0;
	}
	.tabs-vertical.tabs-minimal .nav-item+.nav-item {
		border-top: 1px solid #f3f4f9;
	}
	.tabs-vertical.tabs-minimal .nav-link {
		position: relative;
		padding: 15px 0;
		overflow: hidden;
		text-align: left;
	}
	.tabs-vertical.tabs-minimal .tab-content {
		padding: 0 0 0 30px;
	}
}

@media (min-width: 992px) {
	.tabs-vertical.tabs-minimal .nav-tabs {
		min-width: 200px;
	}
	.tabs-vertical.tabs-minimal .tab-content {
		padding: 0 0 0 70px;
	}
}

@media (min-width: 1200px) {
	.tabs-vertical.tabs-minimal .tab-content {
		padding: 0 0 0 105px;
	}
}

.card-group-custom {
	margin-bottom: 0;
}

.card-group-custom .card+.card {
	margin-top: 0;
}

.card-group-custom.card-group-corporate .card+.card {
	margin-top: 30px;
}

.card-custom {
	display: block;
	margin: 0;
	background: inherit;
	border: 0;
	border-radius: 0;
	box-shadow: none;
	text-align: left;
}

.card-custom a {
	display: block;
}

.card-custom .card-header {
	padding: 0;
	border-bottom: 0;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.card-custom .card-body {
	padding: 0;
	border: 0;
}

.card-title {
	margin-bottom: 0;
}

*+.card-group-custom {
	margin-top: 35px;
}

@media (min-width: 768px) {
	*+.card-group-custom {
		margin-top: 50px;
	}
}

.card-corporate {
	text-align: left;
	border: 1px solid #e0e0e0;
}

.card-corporate .card-title a,
.card-corporate .card-body {
	background: #ffffff;
}

.card-corporate .card-title a {
	position: relative;
	z-index: 1;
	padding: 21px 73px 21px 32px;
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-weight: 500;
	font-size: 16px;
	line-height: 1.3;
	letter-spacing: .05em;
	color: #2c343b;
	transition: 1.3s all ease;
}

.card-corporate .card-title a .card-arrow:after {
	opacity: 0;
	visibility: hidden;
}

.card-corporate .card-title a.collapsed .card-arrow:after {
	opacity: 1;
	visibility: visible;
}

.card-corporate .card-arrow {
	position: absolute;
	top: 20px;
	right: 20px;
	z-index: 2;
	height: 28px;
	width: 28px;
	background: #0A043B;
	border-radius: 0;
	transition: .33s all ease;
	text-align: center;
}

.card-corporate .card-arrow::before,
.card-corporate .card-arrow::after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 4;
	color: #ffffff;
	font-family: 'Material Design Icons';
	font-size: 24px;
	transition: .15s;
}

.card-corporate .card-arrow::before {
	content: '\f465';
}

.card-corporate .card-arrow::after {
	content: '\f504';
}

.card-corporate .collapse {
	position: relative;
	z-index: 1;
	color: #9a9a9a;
	border-radius: 0 0 0 0;
}

.card-corporate .card-body {
	border-top: 1px solid #e0e0e0;
	padding: 18px 44px 25px 32px;
}

@media (max-width: 991.98px) {
	.card-corporate .card-title a,
	.card-corporate .card-body {
		padding-left: 25px;
	}
}

@media (min-width: 768px) {
	.card-corporate .card-title a {
		font-size: 18px;
	}
}


/*
*
* Tooltop Custom
*/

.tooltip {
	font-size: 1rem;
	line-height: 1.2;
}

.tooltip-inner {
	background-color: #0A043B;
}

.tooltip.bs-tooltip-top .arrow::before {
	border-top-color: #0A043B;
}

.tooltip.bs-tooltip-right .arrow::before {
	border-right-color: #0A043B;
}

.tooltip.bs-tooltip-bottom .arrow::before {
	border-bottom-color: #0A043B;
}

.tooltip.bs-tooltip-left .arrow::before {
	border-left-color: #0A043B;
}


/*
*
* Counter
*/

.counter-classic {
	text-align: center;
}

.counter-classic-main {
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 40px;
	line-height: 1;
	font-weight: 300;
}

.counter-classic-main>* {
	display: inline-block;
	font: inherit;
	color: inherit;
}

.counter-classic-divider {
	font-size: 0;
	line-height: 0;
}

.counter-classic-divider::before {
	content: '';
	display: inline-block;
	width: 35px;
	height: 4px;
	background: #0A043B;
}

*+.counter-classic-divider {
	margin-top: 10px;
}

*+.counter-classic-title {
	margin-top: 12px;
}

@media (min-width: 1200px) {
	.counter-classic-main {
		font-size: 70px;
	}
}

.context-dark .counter-classic-main,
.bg-gray-700 .counter-classic-main,
.bg-blue-13 .counter-classic-main,
.bg-blue-15 .counter-classic-main,
.bg-primary .counter-classic-main,
.context-dark .counter-classic-title,
.bg-gray-700 .counter-classic-title,
.bg-blue-13 .counter-classic-title,
.bg-blue-15 .counter-classic-title,
.bg-primary .counter-classic-title {
	color: #ffffff;
}

.bg-primary .counter-classic-divider::before {
	background: #ffffff;
}

.counter-modern {
	display: flex;
	align-items: flex-start;
	margin-bottom: -15px;
	margin-left: -15px;
	text-align: left;
}

.counter-modern:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.counter-modern>* {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 15px;
	margin-left: 15px;
}

.counter-modern-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 56px;
	height: 56px;
	flex-shrink: 0;
	border-radius: 50%;
	border: 2px solid #e0e0e0;
	font-size: 24px;
	line-height: 1;
	color: #0A043B;
}

.counter-modern-main {
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 40px;
	line-height: 1;
	font-weight: 300;
	white-space: nowrap;
}

.counter-modern-main>* {
	display: inline-block;
	font: inherit;
	color: inherit;
}

.context-dark .counter-modern-icon,
.bg-gray-700 .counter-modern-icon,
.bg-blue-13 .counter-modern-icon,
.bg-blue-15 .counter-modern-icon,
.bg-primary .counter-modern-icon {
	border-color: #ffffff;
}

*+.counter-modern-title {
	margin-top: 8px;
}

@media (max-width: 767.98px) {
	.counter-modern {
		max-width: 270px;
		margin-left: auto;
		margin-right: auto;
	}
}

@media (min-width: 1200px) {
	.counter-modern-main {
		font-size: 70px;
	}
}


/*
*
* Progress Bars
*/

.progress-linear {
	position: relative;
	text-align: left;
}

.progress-linear .progress-linear-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	transform: translate3d(0, -10px, 0);
	margin-bottom: -10px;
	margin-left: -5px;
	margin-right: -5px;
}

.progress-linear .progress-linear-header>* {
	margin-top: 10px;
	padding-left: 5px;
	padding-right: 5px;
}

.progress-linear .progress-linear-title {
	color: #0d0d0d;
}

.progress-linear .progress-linear-body {
	height: 3px;
	background: #e0e0e0;
}

.progress-linear .progress-linear-bar {
	position: relative;
	width: 0;
	height: inherit;
	background: #0A043B;
	transition: .5s all ease-in-out;
}

.progress-linear .progress-linear-bar::after {
	content: '';
	position: absolute;
	display: block;
	right: -8px;
	top: -3px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: inherit;
}

.progress-linear .progress-linear-counter {
	display: none;
	font-weight: 700;
	color: #0d0d0d;
}

.progress-linear .progress-linear-counter::after {
	content: "%";
}

*+.progress-linear-body {
	margin-top: 5px;
}

.progress-linear+.progress-linear {
	margin-top: 15px;
}

.progress-bar-color-1 .progress-linear-bar {
	background: #437685;
}

.progress-bar-secondary .progress-linear-bar {
	background: #ca5217;
}

.progress-bar-tertiary .progress-linear-bar {
	background: #7959b6;
}

@media (min-width: 992px) {
	.progress-linear+.progress-linear {
		margin-top: 25px;
	}
}


/** @section Progress Circle */

.progress-circle {
	display: inline-block;
	text-align: center;
	line-height: 1.2;
}

.progress-circle-block {
	position: relative;
}

.progress-circle-bg {
	fill: transparent;
	stroke: #f3f4f9;
	stroke-width: 2px;
}

.progress-circle-fg {
	fill: transparent;
	stroke: #0A043B;
	stroke-width: 3px;
}

.progress-circle-counter {
	position: absolute;
	top: 50%;
	left: 51%;
	font-size: 36px;
	line-height: 36px;
	transform: translate(-50%, -50%);
	color: #151515;
}

.progress-circle-counter::after {
	content: "%";
}

.progress-circle-title {
	font-size: 14px;
	letter-spacing: .05em;
	text-transform: uppercase;
}

*+.progress-bar-circle-title {
	margin-top: 12px;
}


/** @section Countdown */

.countdown {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: -5px;
	margin-right: -5px;
	line-height: 1.2;
	text-align: center;
}

.countdown-block {
	position: relative;
	flex: 0 1 auto;
	padding: 0 5px;
}

@media (min-width: 576px) {
	html:not(.ie-10):not(.ie-11):not(.edge) .countdown-block {
		width: 25%;
	}
}

.countdown-block-seconds {
	display: none;
}

.countdown-wrap {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.countdown-circle {
	fill: transparent;
}

html:not(.ie-11):not(.ie-10) .countdown-circle {
	width: 100%;
	height: auto;
}

.countdown-circle-bg {
	stroke: #f5f5f5;
	stroke-width: 2px;
}

.countdown-circle-fg {
	stroke: #0A043B;
	stroke-width: 3px;
}

.countdown-counter {
	font-size: 24px;
	font-weight: 400;
	line-height: 1;
	letter-spacing: 0.05em;
	color: #0d0d0d;
}

.countdown-title {
	font-size: 11px;
	line-height: 1.2;
	text-transform: uppercase;
	color: #868e96;
	letter-spacing: 0.05em;
}

@media (min-width: 576px) {
	.countdown-title {
		font-size: 14px;
	}
}

*+.countdown-title {
	margin-top: 5px;
}

*+.countdown {
	margin-top: 35px;
}

.countdown+* {
	margin-top: 35px;
}

@media (min-width: 576px) {
	.countdown-block-seconds {
		display: block;
	}
}

@media (min-width: 768px) {
	.countdown {
		margin-left: -15px;
		margin-right: -15px;
	}
	.countdown-block {
		padding: 0 15px;
	}
	.countdown-counter {
		font-size: 34px;
	}
	.countdown-circle-bg {
		stroke-width: 2px;
	}
	.countdown-circle-fg {
		stroke-width: 3px;
	}
}

@media (min-width: 992px) {
	.countdown-counter {
		font-size: 48px;
	}
}

.context-dark .countdown-counter,
.bg-gray-700 .countdown-counter,
.bg-blue-13 .countdown-counter,
.bg-blue-15 .countdown-counter,
.bg-primary .countdown-counter {
	color: #ffffff;
}

.context-dark .countdown-circle-bg,
.bg-gray-700 .countdown-circle-bg,
.bg-blue-13 .countdown-circle-bg,
.bg-blue-15 .countdown-circle-bg,
.bg-primary .countdown-circle-bg {
	stroke: rgba(255, 255, 255, 0.35);
}

.context-dark .countdown-circle-fg,
.bg-gray-700 .countdown-circle-fg,
.bg-blue-13 .countdown-circle-fg,
.bg-blue-15 .countdown-circle-fg,
.bg-primary .countdown-circle-fg {
	stroke: #ffffff;
	stroke-width: 4px;
}

.context-dark .countdown-title,
.bg-gray-700 .countdown-title,
.bg-blue-13 .countdown-title,
.bg-blue-15 .countdown-title,
.bg-primary .countdown-title {
	color: rgba(255, 255, 255, 0.8);
}


/*
* jQuery mousewheel plugin
*/


/*
------------------------------------------------------------------------------------------------------------------------
1. BASIC STYLE
------------------------------------------------------------------------------------------------------------------------
*/

.mCustomScrollbar {
	-ms-touch-action: pinch-zoom;
	touch-action: pinch-zoom;
	/* direct pointer events to js */
}

.mCustomScrollbar.mCS_no_scrollbar,
.mCustomScrollbar.mCS_touch_action {
	-ms-touch-action: auto;
	touch-action: auto;
}

.mCustomScrollBox {
	/* contains plugin's markup */
	position: relative;
	overflow: hidden;
	height: 100%;
	max-width: 100%;
	outline: none;
	direction: ltr;
}

.mCSB_container {
	/* contains the original content */
	overflow: hidden;
	width: auto;
	height: auto;
}


/*
------------------------------------------------------------------------------------------------------------------------
2. VERTICAL SCROLLBAR
y-axis
------------------------------------------------------------------------------------------------------------------------
*/

.mCSB_inside>.mCSB_container {
	margin-right: 30px;
}

.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
	margin-right: 0;
}


/* non-visible scrollbar */

.mCS-dir-rtl-custom>.mCSB_inside>.mCSB_container {
	/* RTL direction/left-side scrollbar */
	margin-right: 0;
	margin-left: 30px;
}

.mCS-dir-rtl-custom>.mCSB_inside>.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
	margin-left: 0;
}


/* RTL direction/left-side scrollbar */

.mCSB_scrollTools {
	/* contains scrollbar markup (draggable element, dragger rail, buttons etc.) */
	position: absolute;
	width: 16px;
	height: auto;
	left: auto;
	top: 0;
	right: 0;
	bottom: 0;
}

.mCSB_outside+.mCSB_scrollTools {
	right: -26px;
}


/* scrollbar position: outside */

.mCS-dir-rtl-custom>.mCSB_inside>.mCSB_scrollTools,
.mCS-dir-rtl-custom>.mCSB_outside+.mCSB_scrollTools {
	/* RTL direction/left-side scrollbar */
	right: auto;
	left: 0;
}

.mCS-dir-rtl-custom>.mCSB_outside+.mCSB_scrollTools {
	left: -26px;
}


/* RTL direction/left-side scrollbar (scrollbar position: outside) */

.mCSB_scrollTools .mCSB_draggerContainer {
	/* contains the draggable element and dragger rail markup */
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	height: auto;
}

.mCSB_scrollTools a+.mCSB_draggerContainer {
	margin: 20px 0;
}

.mCSB_scrollTools .mCSB_draggerRail {
	width: 16px;
	height: 100%;
	margin: 0 auto;
	border-radius: 0;
}

.mCSB_scrollTools .mCSB_dragger {
	/* the draggable element */
	cursor: pointer;
	width: 100%;
	height: 30px;
	/* minimum dragger height */
	z-index: 1;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
	/* the dragger element */
	position: relative;
	width: 16px;
	height: 100%;
	margin: 0 auto;
	border-radius: 0;
	text-align: center;
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
	width: 12px;
	/* auto-expanded scrollbar */
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded+.mCSB_draggerRail,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
	width: 8px;
	/* auto-expanded scrollbar */
}

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown {
	display: block;
	position: absolute;
	height: 20px;
	width: 100%;
	overflow: hidden;
	margin: 0 auto;
	cursor: pointer;
}

.mCSB_scrollTools .mCSB_buttonDown {
	bottom: 0;
}


/*
------------------------------------------------------------------------------------------------------------------------
3. HORIZONTAL SCROLLBAR
x-axis
------------------------------------------------------------------------------------------------------------------------
*/

.mCSB_horizontal.mCSB_inside>.mCSB_container {
	margin-right: 0;
	margin-bottom: 30px;
}

.mCSB_horizontal.mCSB_outside>.mCSB_container {
	min-height: 100%;
}

.mCSB_horizontal>.mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
	margin-bottom: 0;
}


/* non-visible scrollbar */

.mCSB_scrollTools.mCSB_scrollTools_horizontal {
	width: auto;
	height: 16px;
	top: auto;
	right: 0;
	bottom: 0;
	left: 0;
}

.mCustomScrollBox+.mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox+.mCSB_scrollTools+.mCSB_scrollTools.mCSB_scrollTools_horizontal {
	bottom: -26px;
}


/* scrollbar position: outside */

.mCSB_scrollTools.mCSB_scrollTools_horizontal a+.mCSB_draggerContainer {
	margin: 0 20px;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
	width: 100%;
	height: 2px;
	margin: 7px 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
	width: 30px;
	/* minimum dragger width */
	height: 100%;
	left: 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
	width: 100%;
	height: 4px;
	margin: 6px auto;
}

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
	height: 12px;
	/* auto-expanded scrollbar */
	margin: 2px auto;
}

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded+.mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
	height: 8px;
	/* auto-expanded scrollbar */
	margin: 4px 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft,
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
	display: block;
	position: absolute;
	width: 20px;
	height: 100%;
	overflow: hidden;
	margin: 0 auto;
	cursor: pointer;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
	left: 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
	right: 0;
}


/*
------------------------------------------------------------------------------------------------------------------------
4. VERTICAL AND HORIZONTAL SCROLLBARS
yx-axis
------------------------------------------------------------------------------------------------------------------------
*/

.mCSB_container_wrapper {
	position: absolute;
	height: auto;
	width: auto;
	overflow: hidden;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin-right: 30px;
	margin-bottom: 30px;
}

.mCSB_container_wrapper>.mCSB_container {
	padding-right: 30px;
	padding-bottom: 30px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.mCSB_vertical_horizontal>.mCSB_scrollTools.mCSB_scrollTools_vertical {
	bottom: 20px;
}

.mCSB_vertical_horizontal>.mCSB_scrollTools.mCSB_scrollTools_horizontal {
	right: 20px;
}


/* non-visible horizontal scrollbar */

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden+.mCSB_scrollTools.mCSB_scrollTools_vertical {
	bottom: 0;
}


/* non-visible vertical scrollbar/RTL direction/left-side scrollbar */

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden+.mCSB_scrollTools~.mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCS-dir-rtl-custom>.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside>.mCSB_scrollTools.mCSB_scrollTools_horizontal {
	right: 0;
}


/* RTL direction/left-side scrollbar */

.mCS-dir-rtl-custom>.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside>.mCSB_scrollTools.mCSB_scrollTools_horizontal {
	left: 20px;
}


/* non-visible scrollbar/RTL direction/left-side scrollbar */

.mCS-dir-rtl-custom>.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside>.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden+.mCSB_scrollTools~.mCSB_scrollTools.mCSB_scrollTools_horizontal {
	left: 0;
}

.mCS-dir-rtl-custom>.mCSB_inside>.mCSB_container_wrapper {
	/* RTL direction/left-side scrollbar */
	margin-right: 0;
	margin-left: 30px;
}

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden>.mCSB_container {
	padding-right: 0;
}

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden>.mCSB_container {
	padding-bottom: 0;
}

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside>.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
	margin-right: 0;
	/* non-visible scrollbar */
	margin-left: 0;
}


/* non-visible horizontal scrollbar */

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside>.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
	margin-bottom: 0;
}


/*
------------------------------------------------------------------------------------------------------------------------
5. TRANSITIONS
------------------------------------------------------------------------------------------------------------------------
*/

.mCSB_scrollTools,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
	-webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
	-moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
	-o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
	transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail {
	-webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
	-moz-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
	-o-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
	transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
}


/*
------------------------------------------------------------------------------------------------------------------------
6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS
------------------------------------------------------------------------------------------------------------------------
*/


/*
----------------------------------------
6.1 THEMES
----------------------------------------
*/


/* default theme ("light") */

.mCSB_scrollTools .mCSB_draggerRail {
	background-color: transparent;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
	background-color: #f2f3f8;
}

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
	background-color: #d1d4e6;
}

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
	/* background-image: url(../img/mCSB_buttons.png); */
	/* css sprites */
	background-repeat: no-repeat;
	opacity: 0.4;
}

.mCSB_scrollTools .mCSB_buttonUp {
	background-position: 0 0;
}

.mCSB_scrollTools .mCSB_buttonDown {
	background-position: 0 -20px;
}

.mCSB_scrollTools .mCSB_buttonLeft {
	background-position: 0 -40px;
}

.mCSB_scrollTools .mCSB_buttonRight {
	background-position: 0 -56px;
}

.mCSB_scrollTools .mCSB_buttonUp:hover,
.mCSB_scrollTools .mCSB_buttonDown:hover,
.mCSB_scrollTools .mCSB_buttonLeft:hover,
.mCSB_scrollTools .mCSB_buttonRight:hover {
	opacity: 0.75;
}

.mCSB_scrollTools .mCSB_buttonUp:active,
.mCSB_scrollTools .mCSB_buttonDown:active,
.mCSB_scrollTools .mCSB_buttonLeft:active,
.mCSB_scrollTools .mCSB_buttonRight:active {
	opacity: 0.9;
}


/* theme: "dark" */

.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
	background-color: #000000;
	background-color: rgba(0, 0, 0, 0.15);
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
	background-color: #000000;
	background-color: rgba(0, 0, 0, 0.75);
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
	background-color: rgba(0, 0, 0, 0.85);
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
	background-color: rgba(0, 0, 0, 0.9);
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonUp {
	background-position: -80px 0;
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonDown {
	background-position: -80px -20px;
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonLeft {
	background-position: -80px -40px;
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonRight {
	background-position: -80px -56px;
}


/*
*
* Owl Carousel
*/

.owl-carousel .animated {
	animation-duration: 1000ms;
	animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
	z-index: 0;
}

.owl-carousel .owl-animated-out {
	z-index: 1;
}

.owl-carousel .fadeOut {
	animation-name: fadeOut;
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}


/* 
 * 	Owl Carousel - Auto Height Plugin
 */

.owl-height {
	transition: height 500ms ease-in-out;
}


/* 
 *  Core Owl Carousel CSS File
 */

.owl-carousel {
	display: none;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
	/* position relative and z-index fix webkit rendering fonts issue */
	position: relative;
	z-index: 1;
}

.owl-carousel .owl-stage {
	position: relative;
	-ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

.owl-carousel .owl-stage-outer {
	position: relative;
	overflow: hidden;
	/* fix for flashing background */
	-webkit-transform: translate3d(0px, 0px, 0px);
	display: flex;
	justify-content: center;
	align-items: center;
	width: 95%;
}

.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
	cursor: pointer;
	user-select: none;
}

.owl-carousel.owl-loaded {
	display: block;
}

.owl-carousel.owl-loading {
	opacity: 0;
	display: block;
}

.owl-carousel.owl-hidden {
	opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
	display: none;
}

.owl-carousel .owl-item {
	position: relative;
	min-height: 1px;
	float: left;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
}

.owl-carousel .owl-grab {
	cursor: move;
	cursor: -webkit-grab;
	cursor: grab;
}

.owl-carousel.owl-rtl {
	direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
	float: right;
}


/* No Js */

.no-js .owl-carousel {
	display: block;
}


/* 
 * 	Owl Carousel - Lazy Load Plugin
 */

.owl-carousel .owl-item .owl-lazy {
	opacity: 0;
	transition: opacity 400ms ease;
}


/* 
 * 	Owl Carousel - Video Plugin
 */

.owl-carousel .owl-video-wrapper {
	position: relative;
	height: 100%;
	background: #000000;
}

.owl-carousel .owl-video-play-icon {
	position: absolute;
	height: 80px;
	width: 80px;
	left: 50%;
	top: 50%;
	margin-left: -40px;
	margin-top: -40px;
	font: 400 40px/80px 'FontAwesome';
	cursor: pointer;
	z-index: 1;
	transition: scale 100ms ease;
}

.owl-carousel .owl-video-play-icon:before {
	content: '\f144';
}

.owl-carousel .owl-video-play-icon:hover {
	transform: scale(1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
	display: none;
}

.owl-carousel .owl-video-tn {
	opacity: 0;
	height: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
	position: relative;
	z-index: 1;
}


/*
 * Owl Navigation
 */

.owl-nav.disabled {
	display: none !important;
}

.owl-prev,
.owl-next {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	font: 600 40px/40px 'fl-budicons-free';
	color: #0A043B;
	cursor: pointer;
	transition: .33s;
}

.owl-prev:hover,
.owl-next:hover {
	color: #0A043B;
}

.owl-prev {
	left: 0;
}

.owl-prev::before {
	content: '\e016';
}

.owl-next {
	right: 0;
}

.owl-next::before {
	content: '\e025';
}


/*
 * Owl Pagination
 */

.owl-dots {
	text-align: center;
	margin-top: 30px;
}

.owl-dot {
	position: relative;
	display: inline-block;
	width: 16px;
	height: 16px;
	border: 2px solid #b7b7b7;
	border-radius: 50%;
	margin: 0 5px;
	text-align: center;
	outline: none;
	cursor: pointer;
	background-color: transparent;
	transition: .22s;
	box-shadow: none;
}

.owl-dot span {
	display: none;
}

.owl-dot::before {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0);
	background: #b7b7b7;
	width: 6px;
	height: 6px;
	border-radius: inherit;
	transition: .22s;
	opacity: 0;
}

.owl-dot:only-child {
	display: none;
}

.owl-dot:hover,
.owl-dot.active {
	border-color: #0A043B;
}

.owl-dot.active::before {
	opacity: 1;
	background: #0A043B;
}

.context-dark .owl-dot,
.bg-gray-700 .owl-dot,
.bg-blue-13 .owl-dot,
.bg-blue-15 .owl-dot,
.bg-primary .owl-dot {
	border-color: rgba(255, 255, 255, 0.4);
}

.context-dark .owl-dot::before,
.bg-gray-700 .owl-dot::before,
.bg-blue-13 .owl-dot::before,
.bg-blue-15 .owl-dot::before,
.bg-primary .owl-dot::before {
	background: #ffffff;
}

.context-dark .owl-dot:hover,
.bg-gray-700 .owl-dot:hover,
.bg-blue-13 .owl-dot:hover,
.bg-blue-15 .owl-dot:hover,
.bg-primary .owl-dot:hover,
.context-dark .owl-dot.active,
.bg-gray-700 .owl-dot.active,
.bg-blue-13 .owl-dot.active,
.bg-blue-15 .owl-dot.active,
.bg-primary .owl-dot.active {
	border-color: #ffffff;
}

.owl-carousel-stretch .owl-stage,
.owl-carousel-stretch .owl-item {
	display: flex;
}

.owl-carousel-stretch .owl-item>* {
	width: 100%;
}

*+.owl-carousel-stretch {
	margin-top: 40px;
}

.owl-carousel-quote-classic .owl-stage-outer {
	padding: 10px 21px 45px;
	margin: -10px -21px -45px;
}

@media (min-width: 1600px) {
	.owl-carousel-quote-classic {
		max-width: 1720px;
		margin-left: auto;
		margin-right: auto;
	}
}

*+.owl-carousel-quote-classic {
	margin-top: 40px;
}

.owl-carousel-profile .owl-stage-outer {
	padding: 21px;
	margin: -21px;
	border: 1px solid transparent;
}

*+.owl-carousel-profile {
	margin-top: 40px;
}

.owl-carousel-wrap {
	position: relative;
}

.owl-nav-modern .owl-prev,
.owl-nav-modern .owl-next {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 70px;
	width: 40px;
	padding: 5px;
	overflow: hidden;
	color: #151515;
	background: #ffffff;
	box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.12);
}

.owl-nav-modern .owl-prev::before,
.owl-nav-modern .owl-next::before {
	z-index: 3;
	font-family: 'Material Design Icons';
	font-size: 20px;
}

.owl-nav-modern .owl-prev:hover,
.owl-nav-modern .owl-next:hover {
	color: #ffffff;
	background: #0A043B;
	width: 70px;
}

.owl-nav-modern .owl-prev {
	left: 0;
}

.owl-nav-modern .owl-prev:before {
	content: '\f14a';
}

.owl-nav-modern .owl-next {
	right: 0;
}

.owl-nav-modern .owl-next:before {
	content: '\f14f';
}

.owl-carousel_style-1 .owl-nav {
	display: none;
}

.owl-carousel_style-1 .owl-dots {
	margin-top: 10px;
	padding-bottom: 15px;
}

@media (min-width: 768px) {
	.owl-carousel_style-1 .owl-dots {
		display: none !important;
	}
}

@media (max-width: 575.98px) {
	.owl-carousel_style-1-outer-nav {
		display: none;
	}
}

.owl-carousel_style-1-outer-nav .owl-prev,
.owl-carousel_style-1-outer-nav .owl-next {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index: 10;
}

.owl-carousel_style-1-outer-nav .owl-prev {
	left: 0;
}

.owl-carousel_style-1-outer-nav .owl-next {
	right: 0;
}

.owl-carousel-indigo {
	position: relative;
	overflow: hidden;
	max-width: 100vw;
	padding: 0 15px;
	margin-left: auto;
	margin-right: auto;
	pointer-events: none;
}

.owl-carousel-indigo,
.owl-carousel-indigo * {
	backface-visibility: hidden;
}

.owl-carousel-indigo .owl-stage-outer {
	padding: 23px;
	margin: -23px;
}

.owl-carousel-indigo .owl-item.active {
	pointer-events: auto;
}

.owl-carousel-indigo .owl-dots {
	pointer-events: auto;
}

.owl-carousel-indigo *+.owl-dots {
	margin-top: 25px;
}

@media (max-width: 575.98px) {
	.owl-carousel-indigo .owl-dot {
		width: 11px;
		height: 11px;
		margin: 0 6px;
	}
}

@media (min-width: 768px) {
	.owl-carousel-indigo {
		padding: 0 0;
	}
	.owl-carousel-indigo *+.owl-dots {
		margin-top: 60px;
	}
}

.owl-carousel-dots-space .owl-dots {
	margin-bottom: 25px;
}


/*
*
* Material Parallax
*/

.parallax-content {
	position: relative;
	z-index: 1;
}

.parallax-content,
.parallax-content * {
	backface-visibility: hidden;
}

.parallax-container {
	position: relative;
	overflow: hidden;
	background-position: center center;
	background-size: cover;
}

.material-parallax {
	position: absolute;
	top: -1px;
	left: -1px;
	right: -10vw;
	bottom: 0;
	z-index: 0;
	background-position: center center;
}

.ipad .parallax-container,
.iphone .parallax-container {
	background-attachment: scroll !important;
}

.material-parallax img {
	display: none;
	position: absolute;
	left: 50%;
	bottom: 0;
	top: -1px;
	min-width: 101%;
	min-height: 101%;
	max-width: 100%;
	transform: translate3d(-50%, 0, 0) !important;
	will-change: transform;
}


/*
*
* Select 2 v4
*/

.select2-container {
	box-sizing: border-box;
	display: inline-block;
	margin: 0;
	position: relative;
	vertical-align: middle;
	text-align: left;
}

.select2-container .select2-selection--single {
	display: block;
	cursor: pointer;
	user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
	display: block;
	padding-left: 8px;
	padding-right: 20px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.select2-container .select2-selection--single .select2-selection__clear {
	position: relative;
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
	padding-right: 8px;
	padding-left: 20px;
}

.select2-container .select2-selection--multiple {
	display: block;
	cursor: pointer;
	min-height: 32px;
	user-select: none;
	-webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
	display: inline-block;
	overflow: hidden;
	padding-left: 8px;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.select2-container .select2-search--inline {
	float: left;
}

.select2-container .select2-search--inline .select2-search__field {
	border: none;
	font-size: 100%;
	margin-top: 5px;
	padding: 0;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
	-webkit-appearance: none;
}

.select2-dropdown {
	position: absolute;
	left: -100000px;
	z-index: 1000;
	display: block;
	overflow: hidden;
	width: 100%;
	border: 1px solid #e0e0e0;
	border-radius: 0;
	background-color: #ffffff;
}

.select2-results {
	display: block;
}

.select2-results__options {
	list-style: none;
	margin: 0;
	padding: 0;
}

.select2-results__option {
	padding: 3px 6px 3px 26px;
	user-select: none;
	-webkit-user-select: none;
}

.select2-results__option[aria-selected] {
	cursor: pointer;
}

.select2-container--open .select2-dropdown {
	left: 0;
}

.select2-container--open .select2-dropdown--above {
	border-bottom: none;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
	border-top: none;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.select2-search--dropdown {
	display: block;
	padding: 4px;
}

.select2-search--dropdown .select2-search__field {
	padding: 4px;
	width: 100%;
	box-sizing: border-box;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
	-webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
	display: none;
}

.select2-dropdown-borderless.select2-dropdown {
	color: #2c343b;
	border-color: transparent;
	box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}

.select2-close-mask {
	border: 0;
	margin: 0;
	padding: 0;
	display: block;
	position: fixed;
	left: 0;
	top: 0;
	min-height: 100%;
	min-width: 100%;
	height: auto;
	width: auto;
	opacity: 0;
	/* z-index: 99; */
	background-color: #ffffff;
	filter: alpha(opacity=0);
}

.select2-hidden-accessible {
	border: 0 !important;
	clip: rect(0 0 0 0) !important;
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
}

.select2-container--default .select2-selection--single {
	background-color: #ffffff;
	border: 1px solid #e0e0e0;
	border-radius: 0;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	display: block;
	padding: 12px 36px 12px 26px;
	color: #151515;
	font-size: 14px;
	line-height: 24px;
	font-weight: 400;
	min-height: 50px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
	cursor: pointer;
	float: right;
	font-weight: 700;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
	color: #151515;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
	position: absolute;
	top: 50%;
	transform: translate3d(0, -50%, 0);
	right: 14px;
	width: 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
	pointer-events: none;
	color: #151515;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b::before {
	position: relative;
	display: inline-block;
	content: "";
	font-family: "Material Design Icons";
	font-size: 18px;
	transform: rotate(0deg);
	transition: .1s;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
	float: left;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
	left: 1px;
	right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
	background-color: #f3f4f9;
	cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
	display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b::before {
	transform: rotate(180deg);
}

.select2-container--default .select2-selection--multiple {
	background-color: #ffffff;
	border: 1px solid #aaa;
	border-radius: 0.3rem;
	cursor: text;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
	box-sizing: border-box;
	list-style: none;
	margin: 0;
	padding: 0 5px;
	width: 100%;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
	list-style: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
	color: #999;
	margin-top: 5px;
	float: left;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
	cursor: pointer;
	float: right;
	font-weight: bold;
	margin-top: 5px;
	margin-right: 10px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
	background-color: #e4e4e4;
	border: 1px solid #aaa;
	border-radius: 4px;
	cursor: default;
	float: left;
	margin-right: 5px;
	margin-top: 5px;
	padding: 0 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
	color: #999;
	cursor: pointer;
	display: inline-block;
	font-weight: bold;
	margin-right: 2px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
	color: #333;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice,
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder,
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
	float: right;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
	margin-left: 5px;
	margin-right: auto;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
	margin-left: 2px;
	margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
	border: solid black 1px;
	outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
	background-color: #eee;
	cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
	display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown {
	border-top: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
	border: 1px solid #e0e0e0;
}

.select2-container--default .select2-search--inline .select2-search__field {
	background: transparent;
	border: none;
	outline: 0;
	box-shadow: none;
	-webkit-appearance: textfield;
}

.select2-container--default .select2-results>.select2-results__options {
	max-height: 200px;
	overflow-y: auto;
}

.select2-container--default .select2-results__option[role=group] {
	padding: 0;
}

.select2-container--default .select2-results__option[aria-disabled=true] {
	color: #9a9a9a;
}

.select2-container--default .select2-results__option[aria-selected=true] {
	color: #ffffff;
	background-color: #3c9ff2;
}

.select2-container--default .select2-results__option .select2-results__option {
	padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
	padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
	margin-left: -1em;
	padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
	margin-left: -2em;
	padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
	margin-left: -3em;
	padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
	margin-left: -4em;
	padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
	margin-left: -5em;
	padding-left: 6em;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	color: #ffffff;
	background-color: #0A043B;
}

.select2-container--default .select2-results__group {
	cursor: default;
	display: block;
	padding: 6px;
}


/*
*
* Modal Custom
*/

.modal-tip {
	padding: 30px 15px 7vh;
	visibility: hidden;
}

.modal-tip .close {
	cursor: pointer;
	color: #b7b7b7;
	opacity: 0;
}

.modal-tip .close span {
	transition: .22s;
}

.modal-tip .close span::before {
	content: '\f24c';
	font-family: 'Material Design Icons';
}

.modal-tip .close:hover {
	color: #2c343b;
}

.modal-tip.show {
	display: flex !important;
	visibility: visible;
}

.modal-tip.show .close {
	opacity: 1;
}

.modal-tip.fade .modal-dialog {
	transform: translate3d(0, -30px, 0);
}

.modal-tip.show .modal-dialog {
	transform: translate3d(0, 0, 0);
}

.modal-tip .modal-dialog {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	margin: 0 auto;
}

.modal-tip .modal-content {
	height: 100%;
	border-radius: 0;
}

.modal-tip .modal-body {
	height: 100%;
	overflow-y: auto;
}

@media (min-width: 768px) {
	.modal-tip {
		align-items: center;
	}
	.modal-tip .modal-content,
	.modal-tip .model-body {
		height: auto;
	}
}

@media (min-width: 768px) {
	.modal-tip .modal-dialog {
		max-width: 700px;
	}
	.modal-tip .modal-header,
	.modal-tip .modal-body {
		padding-left: 30px;
		padding-right: 30px;
	}
	.modal-tip .modal-header {
		padding-top: 20px;
		padding-bottom: 20px;
	}
	.modal-tip .modal-body {
		padding-top: 30px;
		padding-bottom: 30px;
	}
}


/*
*
* Google Maps
*/

.google-map-markers {
	display: none;
}

.google-map-container {
	width: 100%;
}

.google-map {
	width: 100%;
	height: 200px;
}

@media (min-width: 576px) {
	.google-map {
		height: 280px;
	}
}

@media (min-width: 992px) {
	.google-map {
		height: 360px;
	}
}

@media (min-width: 1200px) {
	.google-map {
		height: 440px;
	}
}

.gm-style-iw {
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
	font-size: 1rem;
	line-height: 1.71429;
	color: #151515;
}

.gm-wrapper>div>div:nth-child(1) {
	background: yellow !important;
}

.gm-wrapper>div>div>div:nth-child(2) {
	background-color: transparent;
	border-radius: 0 !important;
	box-shadow: none !important;
	display: none !important;
}

.gm-wrapper>div>div>div:nth-child(1) {
	display: none;
}

.gm-wrapper>div>div>div:nth-child(3) {
	z-index: 12;
}

.gm-wrapper>div>div>div:nth-child(3)>div {
	height: 20px !important;
}

.gm-wrapper>div>div>div:nth-child(3)>div:nth-child(1)>div {
	transform: skewX(30.6deg) !important;
}

.gm-wrapper>div>div>div:nth-child(3)>div:nth-child(2)>div {
	transform: skewX(-30.6deg) !important;
}

.gm-wrapper>div>div>div:nth-child(3)>div>div {
	height: 16px !important;
}

.gm-wrapper>div>div>div:nth-child(3)>div>div {
	background: #ffffff !important;
	box-shadow: none !important;
}

.gm-wrapper>div>div>div:nth-child(4) {
	background: #ffffff !important;
	border: 1px solid #e0e0e0;
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.google-map_1 {
		height: 385px;
	}
}


/* GDPR Suite */

.checkbox-custom:checked+span+.checkbox-custom-dummy::after {
	opacity: 1;
}

.agreement_checkbox label {
	width: 100%;
	display: block;
	text-align: left;
	position: relative;
}

.agreement_checkbox .form-validation {
	top: initial;
	bottom: -15px;
}

.card-group .card-body a {
	display: inline-block;
}

.lead {
	font-size: 17px;
	line-height: 22px;
	font-weight: 600;
	color: #08c0c9;
	text-transform: uppercase;
	margin: 0;
	letter-spacing: 1px;
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
}

p.lead {
	font-size: 20px;
	margin-bottom: 13px;
}

.text-info {
	color: #333;
	font-weight: bold;
}

a.text-info {
	color: #292929;
}

a.text-info:hover {
	color: #ff4b3c;
}

.text-info2 {
	color: #292929;
}

.text-info3 {
	color: #08c0c9;
}

.hide-text {
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
}

.input-block-level {
	display: block;
	width: 100%;
	min-height: 28px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.check_box {
	/* margin-top: 15px; */
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.check_box label {
	min-height: 30px;
}

.check_box label input {
	display: inline-block;
	/* margin-right: 5px; */
	width: 20px;
	height: 20px;
	-webkit-appearance: checkbox !important;
	-moz-appearance: checkbox !important;
	appearance: checkbox !important;
}

.check_box .empty {
	right: 0;
}

@media all and (max-width:1199px) {
	.check_box .empty {
		top: 25px;
	}
}

@media all and (max-width:768px) {
	.check_box .empty {
		top: 35px;
	}
}


/* CSS from biden */

.job-wrapper {
	border: 1px solid #ebebeb;
	padding: 45px 50px;
	background: #fff;
	position: relative;
}

@media (max-width: 767px) {
	.job-wrapper {
		padding: 25px 25px;
	}
}

.job-tag {
	position: relative;
	z-index: 2;
}

.job-instructor-profile {
	position: relative;
	z-index: 2;
}

.job-content {
	position: relative;
	z-index: 2;
}

div.job-tag span {
	background: #f5f7fc;
	margin-right: 10px;
	padding: 5px 10px;
	border-radius: 5px;
	display: inline-block;
	font-size: 13px;
	text-transform: uppercase;
	font-weight: 600;
	color: #0A043B;
	line-height: 1;
	min-height: 25px;
}

.job-tag span.tag-urgent {
	background: #ff4545;
	color: #fff;
}

.job-tag span.tag-fea {
	background: #0A043B;
	color: #fff;
}

.job-instructor-img {
	margin-right: 20px;
	width: 80px;
	height: 80px;
}

.job-instructor-img-2 {
	position: relative;
	z-index: 1;
}

@media (max-width: 767px) {
	.job-instructor-img-2 {
		float: none;
		margin-bottom: 20px;
	}
}

.job-instructor-title {
	overflow: hidden;
	text-transform:capitalize;
}

.job-instructor-title h4 {
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 6px;
}

.job-instructor-title h4:hover {
	color: #0A043B;
}

.job-instructor-title h5:hover {
	color: #0A043B;
}

.job-instructor-title span {
	color: #667488;
	font-size: 14px;
}

.job-instructor-title span i {
	color: #0A043B;
	margin-right: 5px;
	font-size: 18px;
}

.job-content h4 {
	font-size: 17px;
	font-weight: 500;
	margin-bottom: 15px;
}

.job-content h4:hover {
	color: #0A043B;
}

.job-content p {
	margin-bottom: 25px;
}

.job-salary span {
	margin-right: 50px;
	font-weight: 500;
	font-size: 15px;
}

@media (max-width: 767px) {
	.job-salary span {
		margin-right: 10px;
	}
}

.job-salary i {
	color: #0A043B;
	margin-right: 5px;
	font-size: 18px;
}

.job-salary a {
	font-weight: 700;
	font-size: 14px;
	color: #0A043B;
}

.job-salary a:hover {
	color: #fe9703;
}

.job-salary a:hover i {
	color: #fe9703;
}

.job-salary a i {
	margin-left: 5px;
	transform: translateY(0px);
	display: inline-block;
	font-size: 13px;
}

.banck-icon {
	position: absolute;
	right: 30px;
	z-index: 1;
	top: 30px;
}

.banck-icon i {
	font-size: 100px;
	line-height: 1;
	color: #f7f7f7;
}

.job-meta span {
	margin-right: 15px;
}

.job-meta-2 span {
	display: block;
	margin-bottom: 15px;
}

.job-meta-2 span i {
	color: #0A043B;
}

.job-filter .nice-select {
	border-radius: 0;
	border: solid 1px #ebebeb;
	float: none;
	font-size: 14px;
	height: 55px;
	line-height: 52px;
	width: 100%;
	color: #1c2733;
	font-size: 15px;
	font-weight: 500;
}

.job-filter .nice-select::after {
	right: 20px;
}

.job-widget {
	margin-bottom: 10px;
	border-left: 3px solid #0A043B;
}

.job-widget .nice-select {
	border-radius: 0;
	border: 0;
	float: none;
	font-size: 14px;
	height: 55px;
	line-height: 52px;
	width: 100%;
	color: #1c2733;
	font-size: 15px;
	font-weight: 500;
}

.job-widget .nice-select::after {
	right: 20px;
}

.ui-widget.ui-widget-content {
	border: 0;
	background: #ddd;
	margin-bottom: 15px;
	height: 5px;
}

#slider-range span.ui-slider-handle {
	background: #0A043B;
	border-radius: 30px;
	border: 0;
	height: 15px;
	width: 15px;
}

.ui-slider-horizontal .ui-slider-range {
	top: 0;
	height: 100%;
	background: #0A043B;
}

.price-filter button {
	background: #222;
	color: #fff;
	border: 0;
	padding: 11px 18px;
	line-height: 1;
	font-size: 12px;
	float: left;
}

.price-filter input {
	width: 90px;
	border: 0;
	display: inline-block;
	background: none;
	margin-left: 5px;
	color: #1c2733;
	font-weight: 600;
}

.price-filter span {
	display: inline-block;
}

.price-widget {
	background: #fff;
	padding: 30px;
	margin: 30px 0;
}

.job-item {
	background: #fff;
	padding: 50px;
	border-bottom: 2px solid #fe9703;
}

@media (max-height: 1112px) {
	.job-item {
		background: #fff;
		padding: 10px;
		border-bottom: 2px solid #fe9703;
	}
}
.job-item-2 {
	border-top: 5px solid #fe9703;
	border-bottom: none;
	position: relative;
}

.job-item .job-wrapper {
	border: 0;
	padding: 0;
}

.job-item .banck-icon {
	position: absolute;
	right: 30px;
	z-index: 1;
	top: 0;
}

.job-item .banck-icon i {
	font-size: 100px;
	line-height: 1;
	color: #f7f7f7;
}

.job-item .job-instructor-title {
	position: relative;
	z-index: 2;
	width: 65%;
}

@media (min-width: 501px)  and (max-width: 1112px) {
	.job-item .job-instructor-title {
		width: 100%;
	}

	.job-instructor-profile {
		text-align: center;
	}

	.job-instructor-img {
		margin-left: 40%;
	}
}

@media (max-width: 500px) {
	.job-item .job-instructor-title {
		width: 100%;
	}

	.job-instructor-profile {
		text-align: center;
	}

	.job-instructor-img {
		margin-left: 35%;
	}
}

.map-area iframe {
	min-height: 500px;
	width: 100%;
	border: 0;
}

.job-details-wrapper {
	padding: 0px 50px;
	padding-bottom: 60px;
}

@media (max-width: 767px) {
	.job-details-wrapper {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.job-details-inner {
	padding-top: 60px;
	border-top: 1px solid #eaeaea;
}

.job-list h4 {
	font-size: 22px;
	margin-bottom: 25px;
}

.job-list ul li {
	padding-left: 32px;
	font-size: 15px;
	font-weight: 500;
	position: relative;
	margin-bottom: 16px;
}

.job-list ul li::after {
	position: absolute;
	content: "";
	left: 0;
	top: 0px;
	font-family: "Font Awesome 5 Pro";
	font-size: 15px;
	color: #0A043B;
}

.job-details-video {
	position: relative;
}

.job-details-video img {
	width: 100%;
}

.job-play {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	display: inline-block;
	width: 100px;
	height: 75px;
	line-height: 75px;
	text-align: center;
	background: #ed1c24;
	color: #ffffff;
	border-radius: 7px;
	-webkit-border-radius: 7px;
	-moz-border-radius: 7px;
	-ms-border-radius: 7px;
	-o-border-radius: 7px;
	z-index: 1;
}

.job-play:focus {
	color: #ffffff;
}

.job-play:hover {
	color: #ffffff;
}

.job-thumb img {
	width: 100%;
}

.job-upload {
	position: relative;
}

.job-upload input {
	width: 100%;
	background: #f5f7fc;
	height: 50px;
	line-height: 50px;
	border: none;
	appearance: none;
	-moz-appearance: none;
}

.job-upload button {
	position: absolute;
	top: 10px;
	right: 20%;
	border-radius: 5px;
	padding-top: 7px;
	padding-bottom: 7px;
	font-size: 13px;
}

.job-upload button::before {
	border-radius: 5px;
}

.job-tags span {
	font-weight: 600;
	color: #1c2733;
	font-size: 14px;
	margin-right: 15px;
	min-width: 95px;
}

.job-tags ul {
	display: inline-block;
}

.job-tags ul li {
	display: inline-block;
	margin-right: 4px;
}

.job-tags a {
	font-size: 14px;
	font-weight: 500;
	border: 2px solid #e3e3e3;
	height: 30px;
	line-height: 30px;
	padding: 0 13px;
	border-radius: 5px;
	text-transform: uppercase;
}

.job-tags a:hover {
	border-color: #0A043B;
	color: #0A043B;
}

.job-share span {
	font-weight: 600;
	color: #1c2733;
	font-size: 14px;
	margin-right: 15px;
	min-width: 95px;
}

.job-map {
	height: 380px;
	width: 100%;
}

.job-map iframe {
	width: 100%;
	height: 100%;
	border: none;
}

.related-job h4 {
	font-size: 24px;
	margin-bottom: 25px;
}

.related-job-single {
	border: 1px solid #e3e3e3;
}

.related-job-slider .owl-nav {
	display: flex;
	position: absolute;
	top: -60px;
	right: 0;
}

.related-job-slider .owl-nav div button {
	width: 35px;
	height: 35px;
	line-height: 32px;
	color: #1c2733;
	border: 1px solid #e3e3e3;
	background: transparent;
}

.related-job-slider .owl-nav div button:hover {
	background: #0A043B;
	color: #ffffff;
	border-color: #0A043B;
}

.related-job-slider .owl-nav div.owl-prev {
	margin-right: 8px;
}

.related-job-slider .job-instructor-img {
	position: relative;
	z-index: 1;
}

@media (max-width: 767px) {
	.related-job-slider .job-instructor-img {
		margin-bottom: 30px;
		float: none;
	}
}

@media (max-width: 767px) {
	.related-job-slider .job-meta span {
		display: block;
		margin-bottom: 10px;
	}
}

.job-contact {
	padding: 35px 40px;
	border: 1px solid #e3e3e3;
}

@media (max-width: 767px) {
	.job-contact {
		padding: 15px;
	}
}

.job-contact h4 {
	font-size: 22px;
	margin-bottom: 25px;
}

.job-input-2 {
	position: relative;
}

.job-input-2 i {
	position: absolute;
	top: 23px;
	right: 25px;
	color: #0A043B;
}

.job-input-2 input,
.job-input-2 textarea {
	width: 100%;
	background: #f5f7fc;
	border: none;
	height: 60px;
	line-height: 60px;
	padding: 0 25px;
	padding-right: 45px;
	margin-bottom: 20px;
	color: #1c2733;
}

.job-input-2 input::placeholder,
.job-input-2 textarea::placeholder {
	color: #1c2733;
	font-weight: 600;
}

.job-input-2 input:focus,
.job-input-2 textarea:focus {
	border-color: #0A043B;
}

.job-input-2 input:focus::placeholder,
.job-input-2 textarea:focus::placeholder {
	opacity: 0;
}

.job-input-2 textarea {
	padding: 15px 20px;
	padding-right: 40px;
	height: 190px;
	resize: none;
	line-height: 28px;
}

.job-instructor-img img {
	width: inherit !important;
	display: inline-block !important;
}

.emp-sidebar {
	box-shadow: 0px 10px 30px 0px rgba(207, 207, 207, 0.25);
	padding: 30px;
}

.emp-sidebar .job-widget {
	border-left: 0;
	border: 1px solid #ebebeb;
}

.f-btn {
	color: #ffffff;
	font-weight: 700;
	font-size: 15px;
	background: #0A043B;
	line-height: 1;
	display: block;
	padding: 22px 30px;
	border-radius: 0;
	text-align: center;
}

.f-btn i {
	margin-left: 5px;
	font-size: 14px;
}

.emp-rating i {
	color: #fe9703;
	font-size: 12px;
}

.employer-item {
	/* box-shadow: 0px 10px 30px 0px rgba(207, 207, 207, 0.25); */
	border: solid 1px rgb(150, 145, 171);
}

@media (min-width: 1113px){
	.employer-item {
		padding: 20px;
	}
}

.empl-btn {
	position: absolute;
	top: 50%;
	bottom: 50%;
	right: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.empl-btn {
		position: static;
		margin-top: 20px;
		float: right;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.empl-btn {
		position: static;
		margin-top: 20px;
		float: right;
	}
}

@media (max-width: 767px) {
	.empl-btn {
		position: static;
		margin-top: 20px;
		float: right;
	}
}

.employer-item .banck-icon {
	right: 31%;
}

.job-meta span i {
	color: #0A043B;
}

.employer__inner {
	position: relative;
	z-index: 1;
}

.employer__inner::after {
	position: absolute;
	content: "";
	left: -115px;
	top: -65px;
	height: calc(100% + 110px);
	width: calc(100% + 230px);
	background: #ffffff;
	z-index: -1;
	border-top: 5px solid #fe9703;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
	.employer__inner::after {
		left: 0px;
		width: calc(100% + 0px);
	}
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.employer__inner::after {
		left: -15px;
		width: calc(100% + 30px);
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.employer__inner::after {
		left: -30px;
		width: calc(100% + 60px);
	}
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.employer__inner::after {
		left: -30px;
		width: calc(100% + 60px);
	}
}

@media (max-width: 767px) {
	.employer__inner::after {
		left: -15px;
		width: calc(100% + 30px);
	}
}

.employer__content h5 {
	margin-bottom: 0;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.employer__btn {
		margin-top: 20px;
	}
}

@media (max-width: 767px) {
	.employer__btn {
		margin-top: 20px;
	}
}

.employer__list ul li {
	font-size: 15px;
	font-weight: 500;
	position: relative;
	padding-left: 50px;
	margin-bottom: 33px;
	color: #1c2733;
	font-weight: 500;
}

.employer__list ul li::before {
	position: absolute;
	content: "";
	left: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	color: #0A043B;
	width: 35px;
	height: 35px;
	text-align: center;
	line-height: 31px;
	border: 2px solid #e0e0e0;
	font-family: "Font Awesome 5 Pro";
}

.employer__list ul li:hover {
	color: #0A043B;
}

.employer__list ul li:hover::before {
	background: #0A043B;
	border-color: #0A043B;
	color: #ffffff;
}

.employer__info-wrapper {
	border-top: 1px solid #e0e0e0;
}

.employer__info p {
	margin-bottom: 15px;
}

.employer__title h3 {
	font-size: 22px;
	position: relative;
	padding-bottom: 25px;
	margin-bottom: 20px;
}

.employer__title h3::after {
	position: absolute;
	content: "";
	left: 0;
	bottom: 0;
	width: 50px;
	height: 3px;
	background: #0A043B;
}

.employer__thumb img {
	width: 100%;
}

.employer__video {
	position: relative;
}

.employer__video::after {
	position: absolute;
	content: "";
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background: rgba(28, 39, 51, 0.3);
}

.employer__video img {
	width: 100%;
}

.employer__play {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	display: inline-block;
	width: 100px;
	height: 75px;
	line-height: 75px;
	text-align: center;
	background: #ed1c24;
	color: #ffffff;
	border-radius: 7px;
	-webkit-border-radius: 7px;
	-moz-border-radius: 7px;
	-ms-border-radius: 7px;
	-o-border-radius: 7px;
	z-index: 1;
}

.employer__play:focus {
	color: #ffffff;
}

.employer__play:hover {
	color: #ffffff;
}

.employer__faq .card {
	-webkit-box-shadow: 0px 10px 30px 0px rgba(207, 207, 207, 0.25);
	-moz-box-shadow: 0px 10px 30px 0px rgba(207, 207, 207, 0.25);
	box-shadow: 0px 10px 30px 0px rgba(207, 207, 207, 0.25);
}

.employer__company {
	padding: 40px;
	border: 1px solid #e0e0e0;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.employer__company {
		margin-top: 50px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.employer__company {
		margin-top: 50px;
	}
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.employer__company {
		margin-top: 50px;
	}
}

@media (max-width: 767px) {
	.employer__company {
		margin-top: 50px;
	}
}

.employer__company h4 {
	font-size: 22px;
	margin-bottom: 25px;
}

.employer__company ul li {
	overflow: hidden;
	margin-bottom: 18px;
}

.employer__company ul li:last-child {
	margin-bottom: 0;
}

.employer__company ul li h5 {
	width: 50%;
	float: left;
	font-size: 15px;
	color: #667488;
	margin-bottom: 0;
	line-height: 23px;
}

@media (max-width: 767px) {
	.employer__company ul li h5 {
		float: none;
		width: 100%;
	}
}

.employer__company ul li h5 i {
	color: #0A043B;
	padding-right: 8px;
}

.employer__company ul li span {
	display: inline-block;
	width: 50%;
	float: right;
	font-weight: 500;
	color: #1c2733;
}

@media (max-width: 767px) {
	.employer__company ul li span {
		float: none;
		width: 100%;
	}
}

.employer__map {
	height: 420px;
	width: 100%;
}

.employer__map iframe {
	height: 100%;
	width: 100%;
	border: none;
}

.job-item-3 {
	border: 1px solid #e0e0e0;
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-ms-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
}

.job-item-3:hover {
	-webkit-box-shadow: 0px 10px 30px 0px rgba(207, 207, 207, 0.25);
	-moz-box-shadow: 0px 10px 30px 0px rgba(207, 207, 207, 0.25);
	box-shadow: 0px 10px 30px 0px rgba(207, 207, 207, 0.25);
	border-color: #ffffff;
}

@media (max-width: 767px) {
	.job-item-3 .job-instructor-img {
		float: none;
		margin-bottom: 20px;
	}
}

.mb-30 {
	margin-bottom: 30px;
}

.f-left {
	float: left;
}

/* .empl-btn {
	position: absolute;
	top: 50%;
	bottom: 60%;
	right: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.empl-btn {
		position: static;
		margin-top: 20px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.empl-btn {
		position: static;
		margin-top: 20px;
	}
}

@media (max-width: 767px) {
	.empl-btn {
		position: static;
		margin-top: 20px;
	}
} */

.b-btn {
	color: #ffffff;
	font-weight: 700;
	font-size: 15px;
	background: #fe9703;
	line-height: 1;
	display: inline-block;
	padding: 17px 35px;
	border-radius: 30px;
	position: relative;
	border: none;
	outline: none;
	z-index: 1;
	overflow: hidden;
}

.b-btn i {
	margin-left: 5px;
}

.b-btn::before {
	top: 0px;
	width: 0px;
	left: auto;
	right: 0px;
	z-index: -1;
	bottom: 0px;
	content: "";
	position: absolute;
	transition: all 0.3s ease-in-out;
	background: #0A043B;
	border-radius: 30px;
}

.b-btn:hover {
	color: #ffffff;
}

.b-btn:hover::before {
	left: 0px;
	width: 100%;
	right: auto;
}

.b-btn-green {
	background: #26ae61;
}

.b-btn-green::before {
	background: #1c2733;
}

.b-btn-green-2::before {
	border-radius: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	-o-border-radius: 0;
}

.b-btn-green-2:hover {
	color: #1c2733;
}

.b-btn-green-2:hover::before {
	background: #ffffff;
}

.b-btn-brand {
	color: #ffffff;
	font-weight: 700;
	font-size: 15px;
	background: #fe9703;
	line-height: 1;
	display: inline-block;
	padding: 17px 35px;
	border-radius: 30px;
	position: relative;
	border: none;
	outline: none;
	z-index: 1;
	overflow: hidden;
}

.b-btn i {
	margin-left: 5px;
}

.b-btn-brand::before {
	top: 0px;
	width: 0px;
	left: auto;
	right: 0px;
	z-index: -1;
	bottom: 0px;
	content: "";
	position: absolute;
	transition: all 0.3s ease-in-out;
	border-radius: 30px;
}

.b-btn-green-brand {
	background: #0A043B;
}

.b-btn-green-brand:hover {
	background: #0A043B;
	color: #0A043B;
}

.b-btn-grey {
	background: #f5f7fc;
	color: #1c2733;
}

.b-btn-white:hover {
	background: #0A043B;
	color: #1c2733;
}

.b-btn-white:hover::before {
	background: #ffffff;
}

.b-btn-white-2 {
	background: #ffffff;
	color: #1c2733;
}

.b-btn-white-2::before {
	background: #1c2733;
}


/* basic pagination */

.basic-pagination ul {
	display: block;
}

.basic-pagination ul li {
	display: inline-block;
	margin-right: 8px;
}

.basic-pagination ul li.active a {
	background-color: #fe9703;
	color: #ffffff;
}

.basic-pagination ul li a {
	height: 45px;
	width: 45px;
	background: transparent;
	font-size: 15px;
	font-weight: 500;
	border-radius: 50%;
	line-height: 45px;
	margin: 0px;
	display: inline-block;
	text-align: center;
	position: relative;
	z-index: 1;
	background: #ffffff;
	color: #667488;
}

.basic-pagination ul li a:hover {
	background: #fe9703;
	color: #ffffff;
}

.basic-pagination-2 ul li a {
	background: #f5f7fc;
}

.mt-25 {
	margin-top: 25px;
}

.mt-30 {
	margin-top: 30px;
}

.mt-50 {
	margin-top: 50px;
}

.mb-25 {
	margin-bottom: 25px;
}

.mb-30 {
	margin-bottom: 30px;
}

.mb-75 {
	margin-bottom: 75px;
}

.pt-35 {
	padding-top: 35px;
}

.pb-140 {
	padding-bottom: 140px;
}


/* Candidate */

.grey-bg {
	background: #f5f7fc;
}

.candidate__item {
	padding: 30px 50px;
	/* padding-top: 50px; */
	padding-top: 20px;
	border: 1px solid rgb(10, 4, 59, 0.50);
	position: relative;
	z-index: 1;
	/* height: 460px; */
	/* new css added*/
	border-radius: 4px;
}

@media only screen and (max-width: 992px) {
	.candidate__item {
		padding-left: 10px;
		padding-right: 10px;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.candidate__item {
		padding-left: 10px;
		padding-right: 10px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.candidate__item {
		padding-left: 20px;
		padding-right: 20px;
	}
}

.candidate__item-2 {
	padding: 30px 50px;
	padding-top: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.candidate__item-2 {
		padding-left: 15px;
		padding-right: 15px;
	}
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.candidate__item-2 {
		padding-left: 15px;
		padding-right: 15px;
	}
}

@media (max-width: 767px) {
	.candidate__item-2 {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.candidate__inner {
	position: relative;
}

/* .candidate__inner::after {
	position: absolute;
	content: "";
	left: -115px;
	top: -60px;
	height: calc(100% + 120px);
	width: calc(100% + 150px);
	background: #ffffff;
	border-top: 5px solid red;
} */

/* @media only screen and (min-width: 1200px) and (max-width: 1600px) {
	.candidate__inner::after {
		left: -15px;
		width: calc(100% + 30px);
	}
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.candidate__inner::after {
		left: -30px;
		width: calc(100% + 60px);
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.candidate__inner::after {
		left: -30px;
		width: calc(100% + 60px);
	}
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.candidate__inner::after {
		left: -30px;
		width: calc(100% + 60px);
	}
}

@media (max-width: 767px) {
	.candidate__inner::after {
		left: -15px;
		width: calc(100% + 30px);
	}
} */

.candidate__content h5 {
	font-size: 20px;
	margin-bottom: 5px;
}

.candidate__content>span {
	color: #fe9703;
	font-weight: 500;
}

.candidate_desc > label {
	font-size: 16px;
}

.candidate_desc {
	text-overflow: ellipsis;
	height: 110px;
	overflow: hidden;
	/* white-space: nowrap; */
}

.candidate__info {
	/* new css added*/
}

.candidate__info span {
	display: block;
	margin-bottom: 5px;
}

.candidate__info span i {
	color: #0A043B;
	margin-right: 4px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.candidate__info-2 {
		margin-right: 0;
	}
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.candidate__info-2 {
		margin-right: 15px;
	}
}

@media (max-width: 767px) {
	.candidate__info-2 {
		margin-right: 15px;
		margin-bottom: 20px;
	}
}

.candidate__skill {
	/* new css added*/
	/* display: flex; */
}

.candidate__skill a {
	display: inline-block;
	height: 35px;
	line-height: 31px;
	padding: 0 10px;
	border: 2px solid #e3e3e3;
	border-radius: 18px;
	font-weight: 500;
	margin: 5px 5px;
}

.candidate__skill-2 a {
	margin: 0;
	margin-right: 10px;
}

@media (max-width: 767px) {
	.candidate__skill-2 a {
		margin-right: 5px;
	}
}

.candidate__btn {
	position: absolute;
	bottom: 0;
	left: 50%;
	-webkit-transform: translate(-50%, 50%);
	-moz-transform: translate(-50%, 50%);
	-ms-transform: translate(-50%, 50%);
	transform: translate(-50%, 50%);
	width: 100%;
}

.candidate__thumb {
	/* new css added*/
	display: flex;
	align-items: left;
	margin-top: 10px;
}

.candidate__thumb > img {
	width: 100px;
	height: 100px;
	border-radius: 50px;
}

.candidate_buttons_view {
	display: flex;
	justify-content: center;
	align-items: baseline;
}

.candidate_hold_reject_view {
	display: flex;
	align-items: baseline;
	justify-content: space-between;
}

.candidate-job-badge {
	text-align: right;
	width: 10%;
}


@media (max-width: 992px){
	.candidate__thumb {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.job_info_outer {
		text-align: center;
	}

	.candidate_desc {
		height: 50px;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.candidate_buttons_view {
		flex-direction: column;
		align-items: initial;
		margin-top: 10px;
	}

	.button-primary+.button {
		margin-top: 10px;
	}

	.candidate__item {
		/* height: 535px; */
	}

	.candidate-job-badge {
		text-align: center;
		width: auto;
	}
}

@media (min-width: 992px) and (max-width: 1225px){
	/* .candidate__thumb {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	} */

	/* .candidate__info_outer {
		text-align: center;
	} */

	.candidate_desc {
		height: 50px;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.candidate_buttons_view {
		flex-direction: column;
		align-items: initial;
		margin-top: 10px;
	}

	.button-primary+.button {
		margin-top: 10px;
	}

	.candidate__item {
		height: 480px;
	}

	/* .candidate-job-badge {
		text-align: center;
		width: auto;
	} */
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.candidate__thumb-2 {
		margin-right: 15px;
	}
}

@media (max-width: 767px) {
	.candidate__thumb-2 {
		margin-top: 20px;
	}
}

.div-margin {
	margin-left: -70px !important;
	margin-right: 30px !important;
}

/* Accordian */
.card1 {
    margin-left: 50px;
    z-index: 0;
	margin-top: 40px;
}

.card-top {
	margin-top: 135px;
	/* border: 2px solid rgb(150, 145, 171); */
	box-shadow: 0px 8px 60px -10px rgba(13,28,39,0.6);
	padding: 20px;
	margin-right: 20px;
}

/* @media screen and (max-width: 1000px) and (min-width: 700px) {
	.card-top {
		margin-left:50px;
	}
} */

#progressbar {
    position: absolute;
    left: 20px;
    overflow: hidden;
    color: #0A043B
}

#progressbar li {
    list-style-type: none;
    font-size: 8px;
    font-weight: 400;
}

#progressbar li:nth-child(3) {
    /* margin-bottom: 55px */
}

#progressbar .step0:before {
    content: "";
    color: #fff;
}

#progressbar li:before {
    width: 30px;
    height: 30px;
    line-height: 30px;
    display: block;
    font-size: 20px;
    background: #fff;
    border: 2px solid #0A043B;
    border-radius: 50%;
    margin: auto
}

#progressbar li:after {
    content: '';
    width: 3px;
    height: 66px;
    background: #BDBDBD;
    position: absolute;
    left: 58px;
    z-index: -1
}

#progressbar li.active:after {
    background: #0A043B
}

#progressbar li.active:before {
    background: #0A043B;
    font-family: FontAwesome;
    content: "\f00c"
}

.tick {
    width: 100px;
    height: 100px
}

.top-1 {
    margin-top: 10px
}

.top-2 {
    margin-top: 55px
}

.top-3 {
    margin-top: 55px
}

.top-4 {
    margin-top: 55px
}
@media screen and (max-width: 912px) {
    .card00 {
        padding-top: 30px
    }

    .card1 {
        border: none;
        margin-left: 50px
    }

    .card2 {
        border-bottom: 1px solid #F5F5F5;
        margin-bottom: 25px
    }

    #progressbar {
        left: -25px
    }
}

.candidate-list-outer-box:nth-child(odd) {
	padding-left: 0px;
}

@media (max-width: 768px){
	.candidate-list-outer-box {
		padding-left: 0px;
	}
}

@media (max-width: 321px){
	.page-item:nth-child(5) {
		display: none;
	}
}

.company_list_desc {
	/* width: 35vw; */
	/* padding-right: 20px; */
	/* overflow-wrap: break-word;
	word-wrap: break-word; */
}

.company_list_desc > label {
	text-align: justify;
}

.company-outer-view {
	padding-left: 0px;
}

.candidate__image {
	width: 100px;
	height: 100px;
}

.job-list-outer-box:nth-child(odd) {
	padding-left: 0px;
}

@media (max-width: 768px){
	.job-list-outer-box {
		padding-left: 0px;
	}
}

.job_item {
	padding: 30px 50px;
	/* padding-top: 50px; */
	padding-top: 20px;
	padding-left: 20px;
	padding-right: 20px;
	border: 1px solid rgb(10, 4, 59, 0.50);
	position: relative;
	z-index: 1;
	/* height: 460px; */
	/* min-height: 374px; */
	/* new css added*/
	min-height: 430px;
	/* max-height: 430px; */
	border-radius: 4px;
}

@media only screen and (max-width: 992px) {
	.job_item {
		padding-left: 10px;
		padding-right: 10px;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.job_item {
		padding-left: 10px;
		padding-right: 10px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.job_item {
		padding-left: 20px;
		padding-right: 20px;
	}
}

.job_item-2 {
	padding: 30px 50px;
	padding-top: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.job_item-2 {
		padding-left: 15px;
		padding-right: 15px;
	}
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.job_item-2 {
		padding-left: 15px;
		padding-right: 15px;
	}
}

@media (max-width: 767px) {
	.job_item-2 {
		padding-left: 15px;
		padding-right: 15px;
	}
}

/* Job styles Start */

.job_thumb {
	/* new css added*/
	display: flex;
	align-items: left;
	margin-top: 10px;
}

.job_thumb > img {
	width: 90px;
	height: 90px;
}

.candidate-job-badge {
	text-align: right;
	width: 10%;
}

.job_image {
	width: 100px;
	height: 100px;
}

.job_info {
	/* new css added*/
}

.job_info span {
	display: block;
	margin-bottom: 5px;
}

.job_info span i {
	color: #0A043B;
	margin-right: 4px;
}

.job_content h5 {
	font-size: 20px;
	margin-bottom: 5px;
}

.job_content>span {
	color: #fe9703;
	font-weight: 500;
}

.job_desc > label {
	font-size: 14px;
}

.job_desc {
	text-overflow: ellipsis;
	height: 72px;
	max-height: 72px;
	overflow: hidden;
	cursor: pointer;
	/* white-space: nowrap; */
}

.job_buttons_view {
	display: flex;
	align-items: baseline;
	justify-content: center;
}

.job-type-reject {
	display: flex;
	align-items: baseline;
	justify-content: center;
}

.job-badge {
	text-align: left;
	width: auto;
}

.job-btn-margin-left {
	margin-left: 10px;
}

@media (max-width: 992px){
	.candidate__thumb {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.job_info_outer {
		text-align: left;
	}

	.job_desc {
		/* height: 50px; */
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.job_buttons_view {
		flex-direction: column;
		align-items: initial;
		margin-top: 10px;
	}

	.job-type-reject {
		flex-direction: column;
		align-items: initial;
		margin-top: 10px;
	}

	.button-primary+.button {
		margin-top: 10px;
	}
	
	.job-badge {
		padding-left: 5%;
	}

	.job-btn-margin-left {
		margin-left: 0px;
	}
}

@media (min-width: 992px) and (max-width: 1225px){
	/* .candidate__thumb {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	} */

	/* .candidate__info_outer {
		text-align: center;
	} */

	.job_desc {
		/* height: 50px; */
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.job_buttons_view {
		flex-direction: column;
		align-items: initial;
		margin-top: 10px;
	}

	.job-type-reject {
		flex-direction: column;
		align-items: initial;
		margin-top: 10px;
	}

	.button-primary+.button {
		margin-top: 10px;
	}

	.candidate__item {
		height: 480px;
	}

	/* .candidate-job-badge {
		text-align: center;
		width: auto;
	} */
}

/* Job Styles End */

/* Resume Styles Start */
.profile-light-divider-vertical {
	border: 1px solid rgb(150, 145, 171);
	height: auto;
	width: 1px;
	margin-left: 10px;
	background-color: rgb(150, 145, 171);
}

.profile-light-divider-vertical-small {
	border: 1px solid rgb(150, 145, 171);
	height: 3vh;
	width: 1px;
	margin-left: 10px;
	margin-top: 4px;
	background-color: rgb(150, 145, 171);
}
                          
.candidate_name {
	padding-left: 10px;
}

.profile-light-name {
	min-width: 200px;
	text-transform: capitalize;
}

@media (max-width: 768px){
	.profile-light-divider-vertical {
		display: none;
	}

	.profile-light-divider-vertical-small {
		display: none;
	}

	.margins-certification{
		margin-left: 35px !important;
	}

	.profile-light-main{
		/* display: inline-block; */
		display: inline-grid;
	}

	.candidate_name {
		text-align: left;
		padding-left: 0px
	}

	.profile-light-position {
		text-align: letf;
	}

	.resume-job-title {
		justify-content: flex-start !important;
	}
}

@media (max-width:350px){
	.profile-light-main{
		display: inline-block;
	}
}
/* Resume Styles End */

/* Job Details Styles Start */
.location-date-font {
	font-size: 16px !important;
}

.company-title {
	display: flex;
	justify-content: space-between;
	vertical-align: middle;
}

.company-title > span {
	padding-top: 3px;
	font-size: 12px;
	font-style: italic;
	vertical-align: middle;
}

.company-title > span:hover {
	color: #0A043B;
	cursor: pointer;
}

.outer-button-view {
	text-align: center;
}

.apply-button-view {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
	vertical-align: middle;
	margin: 30px auto;
}

.apply-button-view > div {
	margin: 10px 10px;
}

.submit-form-view {
	display: flex;
	flex-wrap : nowrap;
	justify-content : flex-start;
}

@media only screen and (max-width: 768px) {
	.submit-form-view {
		display: inline-grid;
	}

	.job-submit-form-view {
		justify-content : flex-start;
	}
}	

.submit-form-view :nth-child(3n){
	/* margin-left: 20px; */
	/* font-weight: 500; */
}

/* About Us Page */
/* .founder-image-view {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	flex-shrink: 0;
	margin-top: 40px;
} */

.founder-image-view {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	flex-shrink: 0;
	margin-top: 79px;
	margin-left: 10vw;
	margin-right: 10vw;
}

.founder-image-view > img {
	height: 250px;
}

.founder-info-view {
	text-align: center;
}

.founder-info-view  > hr{
	margin-top: 50px;
	margin-bottom: 20px;
	width: 80%;
}

@media only screen and (max-width: 768px) {
	.founder-image-view :not( :first-child){
		margin-top: 20px;
	}

	.list-linked {
		margin-left: 0px;
		margin-right: 0px;
	}
} 

.timeline-classic-main-resume {
	position: relative;
	padding: 10px 0 30px 20px;
}

.timeline-classic-main-resume::before,
.timeline-classic-main-resume::after {
	content: '';
	position: absolute;
	pointer-events: none;
}

.timeline-classic-main-resume::before {
	left: 0;
	top: 0;
	bottom: 0;
	border-left: 1px solid rgb(150, 145, 171);
}

.timeline-classic-main-resume *+p {
	margin-top: 10px;
}

.timeline-classic-item:last-child .timeline-classic-main-resume {
	padding-bottom: 0;
}

.timeline-classic-item:last-child .timeline-classic-main-resume::before {
	bottom: 20px;
}

*+.timeline-classic {
	margin-top: 30px;
}

.timeline-classic-period-resume {
	padding-right: 20px;
	white-space: nowrap;
	letter-spacing: .025em;
}

@media (max-width: 767.98px) {
	.timeline-classic-period-resume {
		margin-left: -2px;
		color: green;
	}
}

@media (min-width: 768px) {
	.timeline-classic-main-resume {
		padding-bottom: 20px;
	}
	.timeline-classic-main-resume::after {
		top: 18px;
		left: -4px;
		width: 9px;
		height: 9px;
		background: green;
		border-radius: 50%;
	}
}

.select2-dropdown {
	border: 1px solid rgb(150, 145, 171)
}

.select2-container--default .select2-selection--single {
	border: 1px solid rgb(150, 145, 171)
}

.experience-and-eduction-div {
	border: 1px solid rgb(150, 145, 171);
}

.delete-icon-div {
	/* height: 50px; */
	/* width: 50px; */
	color: #ffffff;
	margin-bottom: 10px;
	cursor: pointer;
}

.resume :nth-child(2) > span{
	z-index: 0;
}

.Toastify__toast--success {
	background: #0A043B !important;
}

.modalStyles{
	background: #ffffff;
	margin-top: 20vh;
	margin-left: 5vw;
	margin-right: 5vw;
	height: 70vh;
	-webkit-box-shadow: 2px 2px 10px 7px rgba(76,76,76,0.29);
	box-shadow: 2px 2px 10px 7px rgba(76,76,76,0.29);
	/* z-index: 99; */
	overflow-y: scroll;
}

.ReactModal__Overlay {
	z-index: 9999;
}

.job-application-tag-div{
	display: flex;
    justify-content: left;
    align-content: flex-start;
	flex-wrap: wrap;
	padding-left: 10px;
}

.job-application-label{
	font-size: 12px;
    font-style: italic;
    text-decoration: underline;
	cursor: pointer;
	margin-left: 10px;
	margin-top: 5px;
}

.error-message{
	color: #eb6a6a;
}

.Toastify__toast--error {
	background: #0A043B !important;
}

.Toastify__toast--warning {
	background: #0A043B !important;
}

.Toastify__toast--info {
	background: #0A043B !important;
}

.search-icon-only-btton{
	max-height: 60px;
}

.react-select__value-container{
	z-index: 999;
	background-color: #ffffff;
	opacity: 1;
	font-weight: 500;
}

.list-style{
	list-style-type: disc;
	padding: 0 0 23px 1em;
	line-height: 26px;
}

.signup-checkbox-label {
	margin-left: -5px;
	text-decoration: underline;
	color: #35a1ff;
}

.privacy-list-ordered {
	counter-reset: li;
	padding-left: 0px;
	text-align: justify;
	color: #151515;
}

.privacy-list-ordered>li {
	position: relative;
	list-style: none;
	padding-left: 25px;
}

.privacy-list-ordered>li:before {
	content: "(" counter(li, lower-alpha) ")";
	counter-increment: li;
	position: absolute;
	top: 0;
	left: 0;
	display: inline-block;
	width: 15px;
	color: #000000;
}

.privacy-list-ordered>li+li {
	margin-top: 10px;
}

*+.privacy-list-ordered {
	margin-top: 15px;
}

.privacy-center{
	margin-left: 400px;
}

.privacy-link-color{
	color: #0000ff !important;
}

.privacy-nested2-list{
	margin-top: 0px;
	margin-left:70px;
}
.privacy-nested-list {
	counter-reset: item;
	padding-left: 0px;
	text-align: justify;
	color: #151515;
	margin-left: 60px;
}

.privacy-nested-list>li {
	position: relative;
	list-style: none;
	padding-left: 25px;
}

.privacy-nested-list>li:before {
	content: "(" counter(item, lower-roman) ")";
	counter-increment: item;
	position: absolute;
	margin-right: 40px;
	top: 0;
	left: -13px;
	display: inline-block;
	width: 15px;
	color: #000000;
}

.privacy-nested-list>li+li {
	margin-top: 10px;
}

*+.privacy-nested-list {
	margin-top: 15px;
}

.terms-list-ordered {
	counter-reset: item;
	padding-left: 0px;
	text-align: justify;
	color: #151515;
	margin-left: 20px;
}
.terms-list-ordered>li {
	display: block;
	color: #000000;
}
.terms-list-ordered>li:before {
	content: counters(item, ".") " ";
	counter-increment: item;
	position: absolute;
	left:35px;
	width:15px;
  }
.terms-termination-margin{
	margin-left: 20px;
}

.terms-list-heading{
	margin-left: 20px;
}

.job--title {
	display: flex;
	justify-content: flex-start;
	flex-direction: row;
	flex-wrap: wrap;
}

.job--title > span {
	font-size: 12px;
	text-transform: uppercase;
	line-height: 2.2;
	/* margin-left: 5px; */
}

.resume-div {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	flex-direction: row;
	padding: 0px 10px;
	margin-top: 20px;
	width: 702px;
	background-color: #FFFFFF;
	box-shadow: 0px 20px 60px rgba(241, 244, 248, 0.5);
	border-radius: 16px;
}

@media (max-width: 320px) {
	.resume-div {
		width: 280px;
		margin-left: 1px !important;
	}

	.file-upload-div {
		padding-right: 10px;
	}
	
}
@media (max-width:320px){
	.privacy-responsive{
		width: 290px !important;
		padding:8px;
	}
	.privacy-center{
		margin-left: 11px;
	}
	.text-center{
		text-align: center;
	}
	.terms-responsive{
		margin-left: 35px;
	}
}

@media (max-width: 375px) {
	.resume-div {
		width: 330px;
		margin-left: 1px !important;
	}

	.file-upload-div {
		padding: 10px !important;
	}
}
@media screen and(max-width: 375px), screen and (min-width:321px){
	.privacy-responsive{
		width: 330px;
		padding: 10px;
	}
	.terms-responsive{
		margin-left: 35px;
	}
	.privacy-center{
		margin-left: 11px;
	}
	.text-center{
		text-align: center;
	}
	.react-date-picker__wrapper{
		padding-left:20px !important;
	}
}
@media screen and (max-width: 414px), screen and (min-width:376){
	.privacy-responsive{
		width: 390px;
		padding:12px;
	}
}
@media screen and (max-width: 786px), screen and (min-width:415px){
	.privacy-responsive{
		 width: 750px;
		 padding: 15px;
	}
}
@media screen and(max-width: 1200px), screen and (min-width: 787px){
	.privacy-responsive{
		width: 1150px;
		padding: 20px;
	}
	.text-center{
		text-align: center;
	}
	.react-date-picker__wrapper{
		padding-left:2px !important;
	}
}

@media (max-width: 768px) {
	.resume-div {
		width: 290px;
		margin: auto !important;
		margin-bottom: 1px !important;
		padding: 15px 0px;
	}

	.submit-btn-resp {
		margin-top: 20px;
	}

	.block-form {
		margin-left: 10px;
		padding-right: 10px;
	}

	.file-upload-div {
		padding-right: 10px;
	}

	.default-resume {
		margin-left: 10px;
	}
}

@media (min-width: 769px) {
	.resume-div {
		width: 752px;
		margin-left: 1px !important;
	}
}

/* ==================================== */
.resume-content-div {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
}

@media (max-width: 320px) {
	.resume-content-div {
		align-items: flex-start;
	}
}

@media (max-width: 375px) {
	.resume-content-div {
		align-items: flex-start;
	}
}
/* ==================================== */
.file-icon-view {
	margin: 10px 10px 15px 10px;
	padding: 0px 10px;
}

@media (max-width: 320px){
	.file-icon-view {
		margin: 10px -28px;
		text-align: center;
	}
}

@media (max-width: 375px){
	.file-icon-view {
		margin: 10px -20px;
		text-align: center;
	}
}

@media (max-width: 768px){
	.file-icon-view {
		text-align: center;
	}
}

/* ==================================== */
.resume-file-view{
	min-width: 260px;
}

@media (max-width: 320px){
	.resume-file-view  {
		margin-left: -10px;
	}
}

@media (max-width: 375px){
	.resume-file-view  {
		margin-left: 0px;
	}
}
/* ==================================== */
.resume-file-title {
	font-size: 16px;
	color: #000000;
	font-weight: 700;
}

.resume-icon-view {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

@media (max-width: 768px){
	.resume-icon-view  {
		justify-content: center;
		margin: auto;
	}
}

#default-flag {
	margin-top: -10px;
}

.delete-icon-view {
	height: 50px;
}

.resume-delete-icon{
	font-size: 18px;
	color: "red" !important;
}

.skills-delete-icon{
	font-size: 18px;
	color: "red" !important;
}

.resume-vertical-line {
	width: 2px;
	background-color: #151515;
	height: 120px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 10px;
	margin-top: 10px !important;
}

@media screen AND (max-width: 768px) {
	.resume-vertical-line {
		display: none !important;
	}
}

.icon-alignment-file-document{
	top: 5px !important;
}

@media only screen and (min-width: 280px) and (max-width: 653px) {
	.icon-3-company-edit {
		font-size: 20px !important;
		margin-left: 5% !important;
	}
}

.job-application-hold{
	background-color: #279f8d;
	/* font-size: 12px;
	cursor: pointer;
	margin-top: 5px;
	/* background: green; */
	/* padding: 4px 10px !important; */
	/* font-weight: 500; */
	/* border-radius: 0; */
  	/* vertical-align: middle; */
	/* text-transform: uppercase; */
  	/* letter-spacing: .1em; */
}

.job-application-reject{
	/* font-size: 12px; */
    /* font-style: italic; */
	/* cursor: pointer; */
	/* margin-left: 1vw; */
	margin-top: 5px;
	background: #FAA0A0	 !important;
	border: 1px solid grey;
	padding: 4px 10px !important;
	/* color: #000000; */
	font-weight: 500;
	background-color: red;
}

.job-application-rejected{
	/* font-size: 12px; */
    /* font-style: italic; */
    /* text-decoration: underline; */
	/* cursor: pointer; */
	/* margin-left: 60%; */
	/* margin-top: 5px; */
	background: #b4002a;
	/* border: none; */
	/* padding: 0!important; */
	/* color: red; */
	/* text-decoration: underline; */
}

/* .candidate-job-reject{
	font-size: 12px;
    font-style: italic;
    text-decoration: underline;
	cursor: pointer;
	margin-left: 48%;
	margin-top: 5px;
	background: none!important;
	border: none;
	padding: 0!important;
	color: #9a9a9a;
	text-decoration: underline;
}

.candidate-job-reject:hover {
	color: red;
} */

.candidate-job-reject :hover {
	cursor: pointer;
	background: #d2691e;
}

.candidate-job-rejected :hover{
	cursor: pointer;
	background: #437685;
}

.status{
	display: inline-block;
	padding: 3px 6px 4px;
	font: 500 12px/12px "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", rubiklight, "Helvetica Neue", Arial, sans-serif;
	text-transform: uppercase;
	letter-spacing: .1em;
	color: #ffffff;
	background: #0A043B;
	border-radius: 0;
	vertical-align: middle;
	margin-left: 5px;
}

.status-secondary{
	background: rgb(39, 159, 141);
}

.status-tertiary{
	background: rgb(180, 0, 42);
}

.forgot-text-div {
	cursor: pointer;
}

.form-label-outside.error {
	color: #eb6a6a;
}

.related-job-row {
	cursor: pointer;
}

.border-line {
	border-right: 1px solid #000;
}

@media (max-width: 990px) {
	.job-status-vertical-line{
		display: none;
	}
}

.company_list_desc-admin {
	padding-right: 25px;
  }
  
  .company_list_desc-admin > label {
	text-align: justify;
  }
  
  .reject-admin{
	margin-top: 0%;
	margin-left : -30%;
  }
  
  .approved-admin{
	margin-top: 0%;
	margin-left : -30%;
  }
  
  .drop-down-admin{
	width: 30%;
	align-items: center;
	margin-left: 20%;
  }
  
  .empl-btn-admin {
	position: absolute;
	top: 50%;
	bottom: 50%;
	right: 0;
  }
  
  @media only screen and (min-width: 276px) and (max-width: 653px) {
	.empl-btn-admin {
	  position: static;
	  margin-top: 20px;
	}
  
	.reject-admin{
	  margin-top: 0% !important;
	  margin-left: 4%;
	}
  
	.approved-admin{
	  margin-top: 0% !important;
	  margin-left: 4%;
	}
  }

  @media (min-width: 760px)  and (max-width: 1112px) {
	.empl-btn-admin {
	  position: static;
	  margin-top: 20px;
	}
  
	.reject-admin{
	  margin-top: 0% !important;
	  margin-left: 2%;
	}
  
	.approved-admin{
	  margin-top: 0% !important;
	  margin-left: 2%;
	}
  }
  
  .disableClick{
	  pointer-events: none;
  }

  .admin-employer-row {
	cursor: pointer;
}
.captialise-text{
	text-transform: capitalize !important;
}

.company-light-title {
	text-transform: capitalize;
}

.rejected_job_card{ 
	min-height: 350px;
}

.slider-skills {
	width: 70%;
}

.infobox-wrapper {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	width: 100%;
	background-color: #0A043B;
	opacity: 0.85;
	padding: 12px;
	color: #FFFFFF;
	border: solid 1px #FFFFFF;
	border-radius: 10px;
}

.infobox-wrapper :hover{
	cursor: pointer;
}

.seeker-infobox-wrapper {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	width: 100%;
	background-color: #FFFFFF;
	opacity: 0.85;
	padding: 12px;
	color: green;
	border: solid 1px #FFFFFF;
	border-radius: 10px;
}

.seeker-infobox-wrapper :hover{
	cursor: pointer;
}

.inner-info {
	width: 100%;
	font-size: 14px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	text-transform: capitalize;
}

.inner-info :first-child{
	font-size: 15px;
	font-weight: 500;
	width: 100%;
	line-height: 20px;
}

.inner-info :nth-child(2){
	margin-top: 10px;
	text-decoration: underline;
	float: right;
	margin-left: 100%;
	margin-right: 35%;
	font-size: 12;
	font-weight: 500;
	width: 70px;
	line-height: 20px;
}

.seeker-info-label {
	width: 100px !important;
}

.activity-row {
	text-transform: capitalize;
	height: auto !important;
}

.activity-header-row {
	height: 30px !important;
}

.comment-td p {
	width: 100%;
	color: gray;
	font-size: 12px;
}

.comment-td label {
	font-size: 12px;
	/* font-style: italic; */
	color: black;
	font-weight: 600;
}

.comment-td span label {
	font-size: 12px;
	font-style: italic;
	/* font-style: normal; */
	color: black;
	font-weight: 300;
	margin-left: 5px;
}
/* .activity-row :nth-child(1){
	width: 19%;
	color: grey;
}

.activity-row :nth-child(2){
	width: 15%;
	font-weight: 900;
	color: grey;
}

.activity-row :nth-child(3){
	width: 20%;
	color: grey;
}

.activity-row :nth-child(4){
	width: 20%;
	color: grey;
}

.activity-row :nth-child(5){
	width: 30%;
	font-weight: 900;
	color: #000000;
} */

.cc-color-override--314168688 .cc-btn {
	color: #FFFFFF !important;
	border-top-color: rgb(10, 4, 59) !important;
	background-color: rgb(10, 4, 59) !important;
}

.city-name-resume {
	text-transform: capitalize;
	font-size: 12px;
}

.contact-name-resume {
	text-transform: capitalize;
}

.job-title-job-details {
	text-transform: capitalize;
}

.text-danger-skill {
	color : red;
}

.submit-resume-select {
	text-transform: capitalize;
}

.job-post-defaultvalue {
	text-transform: capitalize;
}

.text-uppercase {
	text-transform: uppercase;
}

.text-danger {
	color: #d2691e;
}

@media (max-width: 768px) {
	.rd-navbar-classic.rd-navbar-fixed .rd-navbar-aside .button-icon{
		font-size: 12px;
	}
}

i {
	font-style: normal;
}

.no-data-map{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 70vh;
	font-size: 16px;
	font-weight: 500;
	color: darkgray;
}

input::placeholder{
	text-transform: none !important;
}

.passwordTick {
    position:absolute;
	margin-top: 7.5%;
	margin-left: 52%;
	color: green;
}

.passwordCrossTick {
    position:absolute;
	margin-top: 7.5%;
	margin-left: 52%;
	color: red;
}

.menu-item::before {
	content: "";
	position: absolute;
	top: 14px;
	left: 0;
	display: inline-block;
	line-height: 0;
	font-size: 17px;
	color: black;
}

.menu-item {
	margin-right: 10px;
}

@media(max-width: 768px){
	.menu-item::before {
		position: relative;
		top: 3px;
		font-size: 16px;
	}

	.rd-navbar-fixed .rd-navbar-submenu .rd-navbar-dropdown li li>a, .rd-navbar-fixed .rd-navbar-submenu .rd-navbar-megamenu ul li li>a {
		padding-left: 30px;
	}
}

input {
	min-height: 30px;
	border-color: rgb(150, 145, 171);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
}

.label-years-in {
    position:relative;
	margin-top: 1vw;
	margin-left: 10%;
	/* color: green; */
}

.home-textbox {
	min-height: 60px !important;
}

.home-placeholder {
	top: 18px;
	margin-left: 10px;
}

.login-placeholder input::placeholder {
	margin-left: 10px;
}

.search-keyword-icon {
	top: 18px !important;
}
.edu-qua-vertical{
	display: flex;
	flex-wrap: wrap;
}

.edu-qua-paragraph{
	margin-left: 11vw;
	margin-top: 3px;
	margin-bottom: 3px;
}

.req-star {
	color: red;
	margin-left: 2px;
}

.card-Wrapper {
	padding: 10px 20px;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: row;
	flex-wrap: wrap;
}

.card-outer {
	background-color: #FFFFFF;
	min-height: 115px;
	max-height: 130px;
	box-shadow: 0px 20px 60px rgba(241, 244, 248, 0.5);
	border-radius: 16px;
  	transition: 0.3s;
}

.card-outer:hover {
	box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

@media screen and (max-width: 768px){
	.card-outer {
		max-height: 190px;
	}
}

.card-item-view {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: row !important;
	padding: 10px 10px;
	width: 40%;
	margin: 10px 10px;
	padding-bottom: 0px;
}

@media(max-width: 768px){
	.card-item-view {
		width: 100%;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
	}
}

.card-item-left {
	flex: 90%;
	margin-top: -5px;
	overflow: hidden;
}

.card-item-right {
	flex: 10%;
}

.card-title {
	font-size: 14px;
	font-weight: 700;
	/* text-transform: capitalize; */
	color: #000000;
}

.card-sub-item {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	padding-right: 10px;
}

.card-v-line {
	height: 15px;
	width: 1px;
	border: 0px solid lightgray;
	margin: 0px 10px;
	vertical-align: middle;
	margin-top: 2px;
	background-color: lightgray;
}

.card-sub-title {
	color: grey;
	font-size: 12px;
	font-weight: 600;
}

.card-desc {
	font-style: italic;
	font-size: 12px;
	color: grey;
	font-weight: 600;
	width: 327px;
	/* height: 100px; */
	overflow: hidden;
	text-overflow: ellipsis;
	max-height: 100px;
	white-space: nowrap;
	/* width: 100%; */
}

.card-icon-view {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex-direction: row;
	padding-top: 10px;
}

@media(max-width: 768px){
	.card-icon-view {
		margin-top: 7px;
		justify-content: center;
	}

	.card-icon-view-profile {
		margin-top: -10px;
		padding-top: 0px !important;
	}
}
@media(max-width:370px){
	.card-icon-view-profile{
		margin-top:-5px;
	}
}

.default-profile {
	font-size: 80px !important;
	color: #0A043B;
}

.react-datepicker__day--outside-month {
	background: none;
	color: transparent !important;
}

.upload-resume-btn-view {
	width: 240px;
}

@media (max-width: 768px){
	.upload-resume-btn-view{
		margin: 0px !important;
	}
}

.summary-input {
	min-height: 45px !important;
	height: auto !important;
	max-height: 90px !important;
	overflow-y: auto;
	resize: vertical !important;
}

.profile-pic-div {
	width: 50%;
}

.profile-pic-content-div {
	justify-content: space-between;
}

@media (max-width: 768px){
	.resume-div {
		justify-content: center;
	}

	.profile-pic-content-div {
		/* align-self: center;
		align-items: center; */
		justify-content: center;
	}
}

.resp-section-md {
	padding-top: 30px;
}

@media(max-width: 768px){
	.resp-section-md {
		padding-top: 0px;
	}
}

.sticky-pos {
	overflow-y: hidden;
}

.submit-btn {
	position: fixed;
	right: 115px;
	bottom: 30px;
	z-index: 100;
	box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.18);
	overflow: hidden;
	text-decoration: none;
}

.job-submit-btn {
	bottom: 45px;
}
.submit-btn-overlap {
	position: absolute;
	right: 0;
	bottom: 0;
	z-index: 100;
	box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.18);
	overflow: hidden;
	text-decoration: none;
}

.job-submit-btn-overlap {
	position: absolute;
	right: 6vw;
	bottom: 22vw !important;	
	z-index: 100;
	box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.18);
	overflow: hidden;
	text-decoration: none;
}

@media (max-width: 786px){
	.job-submit-btn-overlap{
		/* bottom: 88vw !important; */
		bottom: 330px !important;
	}
}

.job-provider-submit-btn-overlap {
	position: absolute;
	right: 8vw;
	bottom: 0;	
	z-index: 100;
	box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.18);
	overflow: hidden;
	text-decoration: none;
}

@media(max-width: 768px){
	.submit-btn {
		right: 25vw;
		bottom: 5px;
	}
	
	.submit-btn-overlap {
		right: 5%;
		bottom: 0px;
	}

	/* .job-submit-btn-overlap {
		right: 4vw;
		bottom: 12.5%;	
	} */

	.job-provider-submit-btn-overlap {
		right: 10%;
		bottom: 0.3%;	
	}
}

@media(min-width: 800px ) and (max-width: 1112px){
	.submit-btn {
		right: 115px;
		bottom: 30px;
	}

	.submit-btn-overlap {
		right: 115px;
		bottom: 30px;
	}

	.job-submit-btn-overlap {
		right: 6vw;
		bottom: 16vw;	
	}
}

.work-here-div {
	position: relative;
	top: -35px;
	height: 40px;
	display: inline-block !important;
	align-items: center;
	vertical-align: middle;
	padding: 10px;
}

/* Customize the label (the container) */
.work-here-div .container {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 16px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
  
/* Hide the browser's default checkbox */
.work-here-div .container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}
  
/* Create a custom checkbox */
.work-here-div .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
	background-color: #ffffff;
}
  
/* On mouse-over, add a grey background color */
.work-here-div .container:hover input ~ .checkmark {
	background-color: #ccc;
}
  
/* When the checkbox is checked, add a blue background */
.work-here-div .container input:checked ~ .checkmark {
	background-color: rgb(10, 4, 59);
}
  
/* Create the checkmark/indicator (hidden when not checked) */
.work-here-div .checkmark:after {
	content: "";
	position: absolute;
	display: none;
}
  
/* Show the checkmark when checked */
.work-here-div .container input:checked ~ .checkmark:after {
	display: block;
}
  
/* Style the checkmark/indicator */
.work-here-div .container .checkmark:after {
	left: 8px;
	top: 0px;
	width: 10px;
	height: 20px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.verify-phone-outer-wrapper{
	height: 100vh;
}

.padding-box{
	padding-bottom: 20px;
	padding-top: 10px;
}

.height-wid-box{
	width: 310px;
	max-height: 260px;
}

.img-resolution{
  width: 170px; 
  height:170px;
}

.height-row{
	padding:30px;
	width: calc(100vw - 31%);
	text-align: center;
	display: flex;
  	justify-content: space-between;
	margin-top: 0%;
}

@media (min-width: 275px) and (max-width: 360px) {
	.height-wid-box {
		width: 130px;
		max-height: 180px;
	}

	.img-resolution{
		width: 65px; 
		height: 65px;
  	}
}

@media (min-width: 360px) and (max-width: 375px) {
	.height-wid-box {
		width: 140px;
		max-height: 190px;
	}

	.img-resolution{
		width: 80px; 
		height: 80px;
  	}
}

@media (min-width: 376px) and (max-width: 768px) {
	.height-wid-box {
		width: 170px;
		max-height: 200px;
	}

	.img-resolution{
		width: 100px; 
		height: 100px;
  	}
}

@media (min-width: 769px) and (max-width: 1023px) {
	.height-wid-box {
		width: 160px;
		max-height: 160px;
	}

	.img-resolution{
		width: 55px;
		height: 55px;
  	}
}

@media(min-width: 1024px) and (max-width: 1280px){
	.height-wid-box {
		width: 160px;
		max-height: 160px;
	}

	.img-resolution{
		width: 60px;
		height: 60px;
  	}
}

@media (min-width: 1280px) {
	.height-wid-box {
		width: 240px;
		max-height: 284px;
	}

	.img-resolution{
		width: 210px;
		height: 210px;
  	}

	.height-row{
		width: 1150px;
	}
}

.div-center {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	flex-wrap: nowrap;
}



.header-login-view {
	display: flex;
	/* justify-content: space-between; */
	/* align-items: center; */
	flex-wrap: wrap;
	justify-content: flex-start;
	width:100%;
	height: auto ;
}

.login-outer-div {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30%;
	background-color: #f3f4f9;
	margin-top: 22px;
	height: auto;
}

@media (max-width: 321px) {
	.login-outer-div {
		width: 280px;
		padding: 0px 0px;
		margin-left: 10px;
		margin-right: 20px;
	}
}

@media (max-width: 768px) {
	.height-row{
		padding:0px;
		padding-left: 3%;
		padding-right: 5%;
		width: calc(100vw - 0%);
	}

	.login-outer-div {
		width: 350px;
		padding: 0px 0px;
	}
}

@media (max-width: 281px) {
	.height-row {
		padding: 0px;
		padding-left: 3%;
		padding-right: 12%;
		width: calc(113vw - 0%);
	}
}
/* @media (min-width: 769px) {
	.height-row{
		padding:0px;
		padding-left: 3%;
		padding-right: 5%;
		width: calc(100vw - 10%);
	}

	.login-outer-div {
		width: 350px;
		padding: 0px 0px;
	}
} */

.login-inner-div {
	padding: 30px 30px;
}

.login-inner-div label {
	justify-content: flex-start;
}

.login-inner-div input {
	min-height: 40px;
	height: 40px;
}

.login-btn-round {
	border-radius: 25px;
}

.privacy-p {
	font-size: 14px;
}

.breadcrumbs-custom-title {
	text-transform: capitalize !important;
	font-size: 28px !important;
}
.terms-custom-title{
	font-size: 28px !important;
}
.home-profile-pic{
	width: auto;
	height: 100px;
	/* border-radius: 50%; */
}

.candidate-home-profile-div {
	/* justify-content: space-between; */
}

.candidate-profile-home{
	flex: 0%;
}

.candidate-info-home{
	flex: 35%;
}

.candidate-btn-home{
	flex: 30%;
}

.resume-job-title {
	display: flex;
	justify-content: center;
	align-items: center;
}

.resume-edit-btn{
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 20px;
	/* margin-top: 15px; */
}

.candidate-basic-info-div{
	height: 90px;
	padding: 10px 10px;
}

.candidate-basic-info{
	text-transform: capitalize;
	font-size: 16px;
	color: #000000;
	font-weight: 600;
	line-height: 1.2;
}

.candidate-home-serarch-div{
	padding: 20px 130px;
}

.signup-link{
	background-color: transparent !important;
	color: white  !important;
	padding: 0px 0px  !important;
	font-weight: 500 !important;
	text-decoration: underline;
}

.center-div {
	display: flex;
	justify-content: center;
	align-items: center;
}

.resume-profile-info{
	box-shadow: 0px 20px 60px rgba(241, 244, 248, 0.5);
  	transition: 0.3s;
	padding: 20px;
	border-radius: 16px;
	background-color: #FFFFFF;
}

@media screen and (max-width: 768px){
	.resume-profile-info{
		padding: 10px 20px;
	}
}
@media screen and (max-width: 500px){
	.resume-profile-info{
		/* padding: 10px 20px; */
		width: 90vw ;
	}
}

.edit-profile-card {
	width: 30%;
}

@media (max-width: 768px){
	*+.profile-light-list{
		margin: 10px 20px;
	}

	.profile-container{
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.edit-profile-card{
		width: 50%;
	}

	.cand-home-prof-icon-view{
		margin-top: 50px !important;
	}
}

@media screen and (max-width: 415px) {
	.edit-profile-card{
		width: 100%;
	}
}

@media screen and (min-width: 769px) and (max-width: 1112px) {
	.edit-profile-card{
		width: 60%;
	}
}

.trouble-color{
  color: #0a043b;
}

.trouble-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.trouble-outer-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  flex-wrap: wrap;
}

@media(max-width:768px){
  .trouble-outer-div{
    justify-content: center;
  }
}

.trouble-inner-div{
  width: 500px;
  align-content: center;
}

.card-desc-text {
	max-height: 100px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
}

.section-sm, .section-md, .section-lg, .section-xl {
	padding: 30px 0px;
}

.show-more-less-text{
	font-size: 16px;
	color: #0A043B;
	text-decoration: underline;
	font-style: italic;
	font-weight: 600;
	margin-left: 10px;
	cursor: pointer;
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
}

.provider-reg-form-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
}
.provider-reg-form{
	width: calc(100vw - 65%);
}

@media(max-width: 768px){
	.provider-reg-form{
		width: calc(100vw - 35%);
	}
}

.editor-wrapper{
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	text-align:justify !important;
	font-size: inherit !important;
	color: #9a9a9a !important;
}

.editor-wrapper h1,
.editor-wrapper h2,
.editor-wrapper h3,
.editor-wrapper h4,
.editor-wrapper h5,
.editor-wrapper h6,
.editor-wrapper div,
.editor-wrapper span, 
.editor-wrapper p,
.editor-wrapper li,
.editor-wrapper ul,
.editor-wrapper ol,
.editor-wrapper strong,
.editor-wrapper b,
.editor-wrapper i {
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	text-align:justify !important;
	font-size: inherit !important;
	color: #9a9a9a !important;
}

.editor-wrapper span{
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	text-align:justify !important;
	font-size: inherit !important;
	color: #9a9a9a !important;
}

.editor-wrapper ul, ol{
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	font-size: inherit !important;
}
.editor-wrapper .tox{
	width: 100%;
	border: none;
	outline: 0;
	margin-bottom: 10px;
	overflow-y: hidden !important;
}

.editor-wrapper iframe {
    overflow:hidden !important;
}

.editor-wrapper ul, ol {
	list-style: disc !important;
	padding: 0;
	margin: 20px;
}
.editor-wrapper ul li, ol li {
	display: list-item;
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	text-align:justify !important;
	font-size: inherit !important;
}

.editor-wrapper .tinymce-content {
	white-space: normal !important; 
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	text-align:justify !important;
	font-size: inherit !important;
}
/* .popular-recruiter {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	padding: 0px 155px;
	margin: 0px 150px;
}
  
.popular-recruiter-item {
	display: flex;
	flex: 1 0 21%;
	margin: 5px;
	margin-bottom: 10px;
	height: auto;
	background-color: blue;
 	border: 1px black solid;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}

@media(max-width: 768px){
	.popular-recruiter {
		padding: 0px 0px;
		margin: 0px 0px;
	}
	.popular-recruiter-item {
		flex: 0 0 50%;
		min-width: 50%;
	}
} */

.skill-vertical{
	margin-bottom: 0px !important;
}

.fa-share-alt{
	color: #0A043B;
	cursor: pointer;
}

.fa-spinner {
	color: #0A043B;
}

.div-share{
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.remove-text-trans{
	text-transform: none !important;
}

.label-radius {
	border-radius: 4px;
}

.width-shortlist{
	width: 167px;
}

.letter-space-status{
	letter-spacing: .1em;
}

::-webkit-input-placeholder {
	font-style: italic;
}
:-moz-placeholder {
	font-style: italic;  
}
::-moz-placeholder {
	font-style: italic;  
}
:-ms-input-placeholder {  
	font-style: italic; 
}

@media screen and (max-width: 768px){
	.section-md{
		padding-top:none;
	}

	.row-50>*{
		margin-bottom: 20px;
	}

	.resume-button-view {
		margin: 0px 0px 55px 10px !important
	}

	.row-narrow {
		margin-right: 37px;
		margin-bottom: 10px;
	}

	.rd-navbar-fixed .rd-navbar-brand img {
		width: 50px;
	}

	.popular-recruiter-section {
		margin-top: 50px;
	}

	.parallax-image {
		background-repeat: no-repeat;
		background-position: right 26% top;
	}
}

.search-filter-btn {
	padding: 20px 0px;
	margin-top: 5px;
	max-height: 60px !important;
}

.search-filter-btn button {
	width: 70% !important;
	height: 60px !important;
}

.share-icons-styles{
	margin-left: -3% !important;
}

@media screen and (max-width: 768px){
	.share-icons-styles{
		margin-left: 0% !important;
	}
}

.candidateStatusHoverSelect {
	cursor: pointer;
	background: #279f8d;
	color: white;
	border-color: #279f8d;
}

@media screen and (max-width: 768px){
	.candidateStatusHoverSelect {
		margin-left: 14px !important;
	}

	.remove-width-mob {
		margin-left: 14px !important;
	}
}

.candidateStatusHoverReject{
	cursor: pointer;
	background:  #b4002a;
	color: white;
}

.applications-buttons {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.shortlisted-class {
	background : #0A043B !important;
}

.loadingCSS {
	height: 100px;
	/* width: 100%; */
	margin: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.background-company {
	background-color: rgb(221, 229, 237);	
}

.defailt-profile-div {
	margin-top: 0px;
}

.margin-companies-box {
	margin:auto;
}

/* .width-team {
	width:250px;
   	max-width:250px;
   	display: inline-block;
} */

.width-team {
	width: 268px;
   	height: 340px;
   	display: inline-block;
	box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
	border-radius: 24px;
}

.advisory-top {
	margin-top : -4%;
}

.link-class{
	text-decoration: underline;
	/* color:black; */
}

.link-class:hover{
	text-decoration: underline;
	color:forestgreen;
}

.view-password {
    position:absolute;
	margin-top: 7.5%;
	margin-left: 45%;
	color: #0A043B;
	cursor: pointer;	
}

.button-shortlisted {
	background: rgb(39, 159, 141);
	color: white;
	border-color: rgb(39, 159, 141);
}

.react-date-picker{
	min-height: 44px;
	/* width: 10vw; */
}

.react-date-picker__inputGroup {
	margin-left: 2vw;
	color: #000000;
}

.date-picker-div .react-calendar{
	z-index: 10px !important;
}

.react-calendar__tile--now {
	background-color: #0A043B !important;
	color: #FFFFFF;
}

.react-calendar__tile--hasActive {
	background-color: #0A043B !important;
	color: #FFFFFF;
}

/* .react-calendar__tile--active, .react-calendar__tile--active:enabled:focus {
	background-color: #0A043B !important;
	color: #FFFFFF;
} */

.react-calendar__month-view__days__day--weekend {
	color: #000000 !important;
}

.edit-file-upload {
	height: 28px !important;
	width: 27px !important;
	color: #ffffff;
	margin-bottom: 10px;
	cursor: pointer;
	
}

.icon-edit-photo {
	font-size: 16px;
	vertical-align: middle !important;
	/* margin-bottom: 0px !important; */
}

.profile-incomplete-overlay {
	position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
}

.profile-incomplete-modal {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
    position: absolute !important;
	flex-direction: column;
	width: 30vw;
	left: 35%;
	/* height: 30%; */
    top: 45%;
    
    right: auto;
    bottom: auto; 
    background-color: white;
	opacity: 1;
	box-shadow: 1px 4px 29px -4px rgba(18,17,17,0.82);
	-webkit-box-shadow: 1px 4px 29px -4px rgba(18,17,17,0.82);
	-moz-box-shadow: 1px 4px 29px -4px rgba(18,17,17,0.82);
}

@media (max-width: 768px){
	.profile-incomplete-modal {
		left: 3vw;
		width: 90vw;
	}
}

.incomplete-profile-container{
	background-color: white;
	opacity: 1;
}
  
.modal-dialog {
	margin: 0;
}

.edit-file-upload-empty-profile {
	border-radius: 4px;
	width: auto !important;
}

.card-icon-view-profile .button {
	overflow: inherit !important;
	line-height: 1;
}

.datepicker-icon {
	z-index: 1px !important;
}

input[type=checkbox] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.provider-profile-image-container{
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}

.provider-profile-info-text {
	font-size: 14px;
}

.shl-loader-div {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 60vh;
}

.csv-uploader textarea {
	height: 60px;
	overflow-y: auto;
}
/* Changes */
.top_margin{
	margin-top: -20px;
}
.bottom_margin{
	margin-bottom: 8px;
}

.header-bottom {
	margin-bottom: 50px;
}

@media screen and (max-width: 768px){
	.header-bottom {
		margin-bottom: 0px;
	}

	.view-password {
		position:absolute;
		margin-top: 14.5%;
		margin-left: 32vw;
	}
}
.forgotpassword-resendcode-button{
	margin-left: 220px;
	margin-top: 0px;
}

.text-justify{
	text-align: justify !important;
}

.cover-div {
	background-image: url('/img/blue-1634110.png');
	/* Full-screen */
	height: 100vh;
	/* Center the background image */
	background-position: center;
	/* Scale and zoom in the image */
	background-size: cover;
	/* Add position: relative to enable absolutely positioned elements inside the image (place text) */
	position: relative;
	/* Add a white text color to all elements inside the .bgimg container */
	color: white;
	/* Set the font-size to 25 pixels */
	font-size: 18px;
	overflow-y: auto;
}

.home-desc-text{
	font-size: 20px;
	width: 550px;
}

.cover-div h1 {
	/* Add a white text color to all elements inside the .bgimg container */
	color: white;
	font-weight: 700;
	letter-spacing: 3px;
	/* Add a font */
	/* font-family: "Courier New", Courier, monospace; */
	/* Set the font-size to 25 pixels */
	font-size: 55px;
}
  /* Position text in the middle */
.middle {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
}

.input{
	font-size: 14px;
	font-weight: bold;
	float: left;
	border: 0;
	-webkit-border-radius: 3px;
	   -moz-border-radius: 3px;
			border-radius: 3px;
	padding: 0 10px;
	/* height: 35px; */
}

.contact-label{
	font-weight: 500;
}

@media(max-width: 768px){
	.home-desc-text{
		width: 250px !important;
	}

	.cover-div h1 {
		letter-spacing: 2px;
		font-size: 35px;
	}

	.contact-label{
		font-size: 14px;
		width: 300px !important;
	}
	
}


.tinyMCEstyle {
	text-align:justify !important;
	font-size: 14px !important;
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
}

.tinyMCEstyle p {
	text-align:justify !important;
    font-size: 14px !important;
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	line-height: 1.71429 !important;
	background: transparent !important;
	color: #000000 !important;
	letter-spacing: normal !important;
}

.tinyMCEstyle span {
	text-align:justify !important;
    font-size: 14px !important;
    font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	background: transparent !important;
	line-height: 1.71429 !important;
	color: #000000 !important;
	letter-spacing: normal !important;
}

.skill-wrapper {
	text-align: center;
	min-width: 170px;
	/* padding: 10px; */
}

.skill-title-div {
	padding: 0px 20px 20px 20px;
}

.skill-title{
	font-size: 12px;
	color: #000000;
	font-weight: 500;
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	line-height: 1.2;
}

.skill-info-container {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px 20px;
}

.marks-get {
	font-size: 16px;
	font-weight: 600;
	color: #000000;
}

.skill-chart-div {
	display: flex;
	align-items: center;
	justify-content: center;
}

.out-of-marks {
	margin-left: 2px;
	font-size: 10px;
}

.skill-chart{
	height: 40px;
	width: 40px;
}

.skill-component-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: row;
	/* flex-wrap: wrap; */
	background-color: #F5F5F5;
	padding: 10px 10px 15px 10px;
	border: 1px solid lightgray;
	margin-left: 15px;
}

.skill-veritcal-line {
	width: 1px;
	height: 95%;
	vertical-align: middle;
	background-color: lightgray;
	margin: 0px 10px;
}

.resp-skill-horizontal-line {
	width: 95%;
	height: 1px;
	vertical-align: middle;
	background-color: lightgray;
	margin: 15px 10px 10px 10px;
	display: none;
}

@media(max-width: 768px){
	.skill-component-container {
		width: calc(100vw - 2.5rem);
		justify-content: center;
	}

	.skill-veritcal-line{
		display: none;
	}

	.skill-wrapper {
		min-width: 250px;
		padding: 15px;
	}

	.resp-skill-horizontal-line {
		display: block;
	}
}
.skill-horizontal-line {
	width: 95%;
	height: 1px;
	vertical-align: middle;
	background-color: lightgray;
	margin: 0px 10px 10px 10px;
}

.skill-info-wrapper{
	border: 1px solid lightgray;
	margin-left: 15px;
	width: 85%;
}

@media(max-width: 768px){
	.skill-info-wrapper{
		width: calc(100vw - 2.5rem);
	}
}

.title-container {
	background-color: #F5F5F5;
	width: 100%;
	height: 35px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 10px;
}

.main-skill-info-view {
	display: flex;
	padding: 10px;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
}

.info-marks-get {
	font-size: 10px;
	font-weight: 500;
	color: #000000;
	margin-left: 10px;
	font-family: "Rubik" !important;
}

.info-out-of-marks {
	margin-left: 2px;
	font-size: 10px;
}

.info-header-skill-bar-view {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 0px 10px;
}

.sub-skill-name {
	font-size: 10px;
	font-weight: 500;
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
}

.skill-info-with-slider-view {
	display: flex;
	align-items: baseline;
	margin-right: 10px;
}

.rangeslider {
	margin: 10px 0px !important;
}

.rangeslider-horizontal {
	width: 130px;
}

@media(max-width: 768px){
	.rangeslider-horizontal {
		width: 200px
	}
}

.rangeslider-horizontal .rangeslider__fill {
	background-color: #0A043B !important;
}

.rangeslider-horizontal {
	height: 5px !important;
}

.rangeslider-horizontal .rangeslider__handle:after {
	width: 10px !important;
	height: 10px !important;
	top: -1px !important;
	left: -1px !important;
}

.rangeslider-horizontal .rangeslider__handle {
	width: 10px !important;
	height: 10px !important;
}

.sub_module_name_div {
	padding-left: 15px;
}

.sub_module_name {
	font-size: 11px;
	font-weight: 600;
	color: #000000;
}

.rangeslider__labels .rangeslider__label-item {
	font-size: 8px !important;
	/* color: #000000 !important; */
	top: 5px !important;
}

.personality-slider-margin {
	margin-left: 20px;
	margin-right: 40px;
	margin-bottom: 20px;
}

.sub-skill-margin {
	margin-left: 20px;
}

.personality_icon_view {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.personality_icon_view label {
	font-size: 14px;
	color: #000000;
}

.personality_complete_icon {
	font-size: 35px !important;
	color: #0A043B;
	margin-right: 10px;
}

.assesment_info_chevron {
	 position: relative;
	 font-size: 60px !important;
	 color: #F5F5F5;
	/*top: 19vh;
	left: 20px;
	font-size: 60px !important;
	
	z-index: -1; */
}

.personality-slider-margin .rangeslider-horizontal .rangeslider__fill{
	background: transparent !important;
}
.personality-slider-margin .rangeslider{
	background: linear-gradient(to right, #6495ED, #0A043B) !important;
}

.arrow-up {
	width: 0; 
	height: 0; 
	border-left: 25px solid transparent;
	border-right: 25px solid transparent;
	border-bottom: 25px solid #F5F5F5;
	position: relative;
	top: 30px;
}
.display-inline{
	display:inline !important;
}
.display-inline-block{
	display:inline-block !important;
}
@media(max-width: 320px){
	.react-date-picker__wrapper{
		padding-left: 20px !important;
	}
	.education-date-responsive{
		width: 400px !important;
	}
}
@media screen and (max-width: 1100px), screen and(min-width:720px){
	.react-date-picker__wrapper{
		padding-left:13px;
	}
}
.react-date-picker__wrapper{
	border: 1px solid #9691ab !important;
	border-radius: 4px;
}
@media (max-width: 786px){
	.briefcase-responsive{
		margin-left:-19px ;
	}
}

@media screen and (max-width: 1100px), screen and(min-width:720px){
	.react-date-picker__wrapper{
		padding-left:13px;
	}
}
.react-date-picker__wrapper{
	border: 1px solid #9691ab !important;
	border-radius: 4px;
}

@media (max-width: 320px){
	.react-date-picker__wrapper{
		padding-left:22px;
	}
	.education-date-responsive{
		min-width: 400px;
	}
	.to-date-resposive .react-date-picker__inputGroup{
		margin-left: 15px !important;
		margin-right: -20px !important;
	}
	.work-here-responsive{
		margin-left: 6px !important;
		margin-top: 20px !important;
	}
}
@media screen and (max-width:375px), screen and (min-width:321px){
	.education-date-responsive{
		min-width: 400px;
	}
	.to-date-resposive .react-date-picker__inputGroup{
		margin-left: 15px !important;
		margin-right: -20px !important;
	}
	.work-here-responsive{
		margin-top: 100px !important;
		margin-left: -68px !important;
	}
}
@media screen and (max-width:699px),screen and(min-width:530px){
	 .education-date-responsive{
		width: 600px;
	}
	.work-here-responsive{
		margin-left:-75px;
	}
}
@media screen and(max-width:786px), screen and (min-width:700px){
	.too-date-responsive{
		margin-left: -220px !important;
	}
	.work-here-responsive{
		margin-top: 0px !important;
		margin-left: -68px !important;
	}
	.date-of-birth-responsive .react-date-picker__wrapper{
		padding-left:10px !important;
	}
} 
@media screen and(max-width:1200px), screen and (min-width:790px){
	.to-date-resposive .react-date-picker__inputGroup{
		margin-left: 36px !important;
		margin-right: -20px !important;
	}
}
@media(min-width:1210px){
	.date-of-birth-responsive .react-date-picker__wrapper{
		padding-left:4px !important;
	}
	.work-here-responsive{
		margin-left:-45px !important;
	}
}
.jumbotron-title-div{
	width: 568px;
    /* height: 173px; */
    margin-top: 50px;
    /* margin-left: -122px; */
    padding-right: 60px;
    text-align: left;
}
.font-rubik{
	font-family: 'rubiklight', Arial, sans-se rif;
}
.jumbotron-title-creative{
	font-family: 'Rubik', Arial, sans-se rif;
	font-style: normal;
	font-weight: bold !important;
	font-size: 44px !important;
	line-height: 126%;
	font-feature-settings: 'pnum' on, 'lnum' on;
	color: #FFFFFF;
}
.jumbotron-para{
	width:375px;
	height:50px;
	color: #FFFFFF;
	font-size: 15px;
	font-family: 'rubiklight', Arial, sans-se rif;
	line-height: 138%;
	font-weight: 550;
	letter-spacing: 0.09em;
	margin-left:4px;
}
.login-btn{
	 position:absolute; 
	background-color:#080838;
	margin-top: 88px;
    margin-left: -344px;
	width: 82px;
	height: 40px;
	padding:6px;
	border:1px solid #080838;
	border-radius:4px; 
	color:#FFFFFF;
}
.login-btn:hover, .btn-position:hover, .search-hover{
	color:#FFFFFF !important;
}
.btn-position{
	background-color: #B8B8B9;
	width:82px;
	height:40px;
	border:1px solid #B8B8B9;
	border-radius: 4px;
	margin-left: -234px;
	margin-top: 88px;
	color:#FFFFFF;
	position:absolute;
	padding:6px;
}
.screenshot-div{
	margin-left:62px;
}
.mobile-app-screenshot{
	/* position: absolute; */
	width:226px;
	height:454px;
	border:1px solid #FFFFFF;
	border-radius:4px;
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.dedicated-platform-div{
	margin-left: 250px;
    margin-top: 102px;
	width: 481px;
	height: 238px;
}
.dedicated-platform-heading{
	/* width: 481px;
	font-size: 45px;
	letter-spacing: 0.9px;
	height: 108px;
	font-weight:700;
	text-align:left;
	line-height: 126%;
	font-family: 'Rubik'; */
	font-size:44px;
	text-align:left;
	line-height: 126%;
	font-weight: 700;
	font-feature-settings: 'pnum' on, 'lnum' on;
	color: #000000;
	font-family: 'Rubik';
}
.dedicated-platform-para{
	/* color: #616161;
    font-size: 14px;
    text-align: left;
    margin-top: 20px;
    line-height: 1.4em;
    letter-spacing: 1.8px;
	font-weight: 400; */
	margin-left: 4px;
	margin-top: 10px;
	font-size: 14px;
	line-height: 138.5%;
	letter-spacing: 0.08em;
	font-style: normal;
	font-weight: 400;
	color: #616161;
	text-align: left;
	font-feature-settings: 'pnum' on, 'lnum' on;
	font-family: 'Rubik';
}
.search-job-btn{
	margin-top: 30px;
    background-color: #080838;
    width: 160px;
    height: 40px;
    padding: 6px;
    border: 1px solid #080838;
    border-radius: 4px;
	color:#FFFFFF;
}
.user-partner-joblisting-div{
	margin-top: 120px;
    margin-left: 257px;
	width: 654px;
	height: 117px;
	background: #FFFFFF;
	box-shadow: 0px 2px 24px rgba(88, 126, 236, 0.15);
	border-radius: 16px;
	padding: 26px 96px 27px 64px;
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))
}
.total-user-count-div{
	width:491px;
	height:61px;
	margin-left: 11px;
	/* padding:10px; */
}
.bg-color-hp{
	/* width: 1152px; */
	height: 1000px;
	background-color: #FAFBFD;
}
.total-user-label{
	color: #080838;
	font-weight: bold;
    font-size: 28px;
}
.user-label-position{
	margin-left: 0px;
   margin-right:200px;
}
.partner-label-position{
	margin-left:-69px;
	margin-right:130px;
}
.font-small{
	margin-top:-10px;
	font-size:12px !important;
	font-weight:500;
	color:#000000 !important;
	font-family: 'Rubik';
}
.position-absolute{
	margin-left: 1px;
	letter-spacing: 2px;
}
.user-label-div{
    margin-top: 9px;
    margin-left: 0px;
    letter-spacing: 3px;
	font-family:'Rubik';
}
.partners-position{
	margin-left:-82px;
}
.skill-tool-div{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 240px;
	margin-top:100px;
	flex-wrap: wrap;
}

.skill-tool-div :nth-child(1) {
	width: 28vw;
}

.font-rubik-bold{
	font-family:'Rubik';
}
.text-black{
	color:black !important;
}
.skill-heading{
	font-size:44px;
	text-align:left;
	line-height: 126%;
	font-weight: 700;
	font-feature-settings: 'pnum' on, 'lnum' on;
	color: #000000;
}
.skill-para{
	margin-left: 4px;
	margin-top: 10px;
	font-size: 14px;
	line-height: 138.5%;
    letter-spacing: 0.08em;
	font-style: normal;
	font-weight: 400;
	color: #616161;
	text-align: left;
	font-feature-settings: 'pnum' on, 'lnum' on;
}
.start-btn{
	margin-top: 25px !important;
    width: 144px !important;
}
.girl-img-div{
	width:354px;
	height:317px;
	margin-left: 150px;
	max-height: 317px;
    /* margin-top: -260px; */
}

.girl-img-div img {
	max-width:354px;
	max-height: 317px;
}
@media(max-width: 768px) {
	.girl-img-div{
		margin-left: 0;
		margin-top:30px;
	}
}

.service-hospitality-div{
	display: flex;
	flex-wrap:wrap;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 100px;
	/* margin-left:64px; */
}

@media(max-width: 768px){
	.service-hospitality-div{
		margin-top: 100px;
		/* width: 100%; */
	}
}

.target-icon-wrapper{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap:wrap;
	margin-top: 50px;
	/* flex-direction: column; */
}

.service-hospitality-heading-div{
	width: 989px;
	height: 167px;
	text-align:center;
	font-size:44px;
	color: #000000
}

.service-hospitality-heading-div p {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 700;
	font-size: 44px;
	line-height: 126%;
	/* or 55px */
	text-align: center;
	font-feature-settings: 'pnum' on, 'lnum' on;
	color: #000000;
}

@media(max-width: 768px){
	.service-hospitality-heading-div{
		width: 90vw;
		height: auto;
		font-size: 25px;
	}

	.service-hospitality-heading-div p{
		font-size: 25px;
	}
}

.target-icon-div{
	margin-top:20px;
	width: 279px;
	height: 277px;
	box-shadow: 0px 4px 18px rgba(88, 126, 236, 0.18);
	border-radius: 8px;
	/* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) */
}
.top-brand-icon{
	margin-left:65px;
}
.target-img{
	margin-top:35px;
}
.target-para{
	font-size: 19px;
    letter-spacing: 1px;
}
.new-candidate-div{
	border: 1px solid black;
	height:430px;
	margin-top:260px !important;
}
.new-candidate-heading{
	margin-top:50px;
	margin-bottom:50px;
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 700;
	font-size: 44px;
	line-height: 50px;
	text-align: center;
	color: #0B132A;
}
.new-candidates-list-hp{
	border: 1px solid blue;
	border-radius:4px;
	width:300px;
	height:200px
}
.bg-white{
	background-color:white !important;
}
.box-new-candidate {
	position: relative;
	display: flex;
	padding: 25px 14px;
	height: 240px !important;
	width: 400px !important;
	text-align: center;
}
.our-recruiter-heading{
	margin-top: 20px;
	margin-bottom:50px;
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 700;
	font-size: 44px;
	line-height: 50px;
	text-align: center;
	color: #0B132A;
	letter-spacing: 0;
}

.assesment-request-box {
	background-color: #0A043B;
	padding: 10px 20px;
	margin-top: 25px;
	border-radius: 4px;
	text-align: center;
	color: #FFFFFF;
	font-weight: 300;
	font-size: 15px;
	width: 25vw;
}

.assesment-request-box span{
	font-weight: 900;
}

.assesment-request-box :nth-child(2){
	cursor: pointer;
}

@media (max-width: 786px){
	.assesment-request-box {
		width: 80vw;
	}

	.resume-edit-btn-responsive{
		margin-left:-100px ;
	}
	.briefcase-responsive{
		margin-left:-19px ;
	}
}
@media(max-width:1200px) ,screen and (min-width:1001px){
	.work-here-responsive{
		margin-left:59px !important;
	}
	.to-date-responsive{
		margin-left:100px !important;
	}
}
@media(max-width:1000px){
	.to-date-responsive{
		margin-left:100px !important;
	}
	.work-here-responsive{
		margin-left:150px !important;
	}
}
@media(min-width:1210px){
	.to-date-responsive{
		margin-left:100px !important;
	}
	.work-here-responsive{
		margin-left:150px !important;
	}
}
@media(max-width: 320px){
	.react-date-picker__wrapper{
		padding-left: 20px !important;
	}
	.react-date-picker__inputGroup{
		margin-left:10px;
		margin-right:-10px;
	}
	.education-date-responsive{
		width: 400px !important;
	}
	.work-here-responsive{
		margin-top:22px !important;
		margin-left:6px !important;
	}
	.to-date-responsive{
		margin-left:0px !important;
	}
}
@media(max-width:414px){
	.education-date-responsive{
		width: 400px !important;
	}
	.work-here-responsive{
		margin-top:130px !important;
		margin-left:-70px !important;
	}
	.to-date-responsive{
		margin-left:0px !important;
	}
}
@media(max-width:780px){
	.education-date-responsive{
		width: 800px !important;
	}
	.work-here-responsive{
		margin-left:150px;
	}
	.to-date-responsive{
		margin-left:0px !important;
	}
}
.display-inline{
	display:inline !important;
}
.provider-home-corousel-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.provider-home-corousel-wrapper .owl-item {
	width: 100% !important;
}

.provider-home-corousel {
	width: 300px !important;
	height: 300px !important;
	background-color: #FFFFFF;
	display: flex;
	justify-content: center;
	align-items: center;
	/* width: 1152px !important; */
}

@media (max-width: 500px){
	.provider-home-corousel{
		width: 250px !important;
		height: 250px !important;
	}
}
.temp-corousel {
	width: 100%;
}

.courousel-and-recruiter-warapper {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
} 

.home-skill-wrapper {
	display: flex;
	justify-content: center;
	flex-direction:column;
	align-items: center;
	width: 100%;
	margin-bottom: 100px;
}

.assesment-report-table {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 90%;
	padding-left: 10%;
}

.header-btn-wrapper {
	margin-top: 20px;
	margin-left: 8vw;
}

.fa-down-arrow{
	color:#FFFFFF;
	/* margin-left:200px; */
}

.down-arrrow-div{
    margin-top: 70px;
    margin-left: 565px;
}

.get-in-touch-btn{
	padding: 8px 15px;
    background-color: #080838;
    border-radius: 4px;
	line-height: 12px;
}

.text-white{
	color: #ffffff;
}

.touch-btn-font{
	font-size:12px;
}

.line-height-30{
	line-height:30px;
}

.footer-signin-btn{
	border:1px solid #080838;
	width:170px;
	height:32px;
	padding: 5px;
	background-color: #ffffff;
	border-radius:4px;
	color:#000000;
}

.footer-sign-btn-pos{
	margin-top:22px;
}

.footer-signin-btn-pos{
	margin-left: 183px;
    margin-top: -32px;
}

.nav-list-post{
	margin-top:8px;
	margin-bottom:10px;
}

.footer-search-btn{
	border:1px solid #ffffff;
	width:170px;
	height:32px;
	padding:5px;
	background-color: #0a043b;
	border-radius:4px;
}

.footer-search-btn-pos{
	margin-top:10px;
}
.footer-signup-btn-pos{
	margin-left: 184px;
    margin-top: -32px;
}
.padding-left-15px{
	padding-left:12px;
}

.footer-btn-div{
	margin-top:-6px;
}

.text-placeholder::placeholder{
	font-style:none;
	font-family: 'rubiklight', Arial, sans-se rif;
}
.browse-jobs-btn{
	position:absolute; 
   background-color:#080838;
   margin-top: 88px;
   margin-left: -125px;
   display:flex;
   /* width: 82px;
   height: 40px; */
   padding:6px;
   border:1px solid #080838;
   border-radius:4px; 
   color:#FFFFFF;
}

.jumbotron-creative .rd-navbar-static.rd-navbar-classic:not(.rd-navbar--is-stuck) .rd-nav-link, .jumbotron-creative .rd-navbar-static.rd-navbar-classic:not(.rd-navbar--is-stuck) .rd-navbar-submenu-toggle{
	color:#ffffff !important;
}

.footer-search-button{
	text-align:left;
	padding-left:14px;
	border:1px solid white;
	width:170px;
	height:32px;
	border-radius:4px;
	background-color:#0a043b;
}
/* Grey Button Start */
.button-grey,
.button-grey:focus {
	color: #ffffff;
	background-color: #B8B8B9;
	border-color: #B8B8B9;
}

.button-grey:hover,
.button-grey:active {
	color: #ffffff;
	/* background-color: #ca5217;
	border-color: #ca5217; */
	background-color: #ca5217;
	border-color: #ca5217;
}

.bg-grey .button-grey,
.bg-grey .button-grey:focus {
	color: #151515;
	background-color: #ffffff;
	border-color: #ffffff;
}

.bg-grey .button-grey:hover,
.bg-grey .button-grey:active {
	color: #ffffff;
	background-color: #ca5217;
	border-color: #ca5217;
}
/* Grey Button Ends */


.provider-home-corousel-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.provider-home-corousel-wrapper .owl-item {
	width: 100% !important;
}

.provider-home-corousel {
	width: 300px !important;
	height: 300px !important;
	background-color: #FFFFFF;
	display: flex;
	justify-content: center;
	align-items: center;
	/* width: 1152px !important; */
}

.temp-corousel {
	width: 100%;
}

/* .courousel-and-recruiter-warapper {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
} */

.home-skill-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-bottom: 100px;
}

.header-btn-wrapper {
	margin-top: 20px;
	margin-left: 8vw;
}

.csv-import-button {
	display: flex;
	justify-content: center;
	align-items: center;
}
.csv-import-button button:focus,.csv-import-button button:hover {
	background-color:rgb(39, 159, 141) !important;
	border-color:rgb(39, 159, 141) !important;
}

.import-skill-div {
	margin-left: 10.7% !important;
	border-collapse: collapse;
	margin-top: 4% !important;
}

.assesment-report-table {
	/* width: 76%;
	margin-left: 12%; */
	margin-right: 12%;
	margin-top: -3%;
}

@media only screen and (max-width: 768px) {
	.import-skill-div {
		margin-left: 0px !important;
		max-width:fit-content;
	}

	.skill-import-table {
        width: 100%;
        margin-bottom: 15px;
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        border: 1px solid #DDD;
    }

	.assesment-report-table {
		overflow-x: auto;
		width: 96%;
		margin-left: 2%;
		margin-right: 2%;
	}
}

/* New Job List Changes Starting from Here */
.job-list-section {
	background-color: #FAFBFD;
}

.new-section-bg {
	background-color: #FAFBFD;
}

.search-Wrapper {
	background-color: #FFFFFF;
	/* height: 135px; */
	width: 100%;
}

@media(max-width: 768px){
	.search-Wrapper{
		display: none;
	}
}

.inner-search-wrapper{
	padding: 20px;
	padding-bottom: 30px;
}

.search-title {
	padding-top: 14px;
}

.search-title-label {
	font-size: 14px;
	color: #181818;
	/* font-weight: 600; */
	font-weight: 400;
	line-height: 14px;
	text-transform: uppercase;
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
}

.search-title-label-mobile {
	font-size: 14px;
	/* color: #181818; */
	font-weight: 600;
	line-height: 14px;
	text-transform: uppercase;
	background-color: transparent;
}

.search-hr {
	width: 98%;
	border: 1px solid #F4F4F4;
	/* margin-left: calc(50% - 100%/2); */
}

.search-content {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 30px 0px;
	/* height: 30px; */
}

.search-input-wrapper {
	width: 85%;
}

.search-input-wrapper input {
	width: 100%;
	height: 100%;
	padding: 10px 0px;
	border: none;
	font-size: 18px;
	line-height: 21px;
}

.search-input-wrapper input::placeholder {
	font-size: 18px;
	color: rgba(24, 24, 24, 0.2);
	font-style: normal;
	font-weight: normal;
}

.search-slider-icon {
	height: 30px;
	width: 30px;
	opacity: 0.35;
	font-size: 30px !important;
	transform: rotate(270deg);
	margin-top: 10px;
}

.search-slider-icon-mobile {
	display: none !important;
	/* height: 30px;
	width: 30px;
	opacity: 0.35;
	font-size: 30px !important;
	transform: rotate(270deg);
	margin-top: 10px; */
}

.search-icon-div-mobile {
	display: none;
}

.active-search-slider-icon {
	color: #0A043B !important;
	opacity: 1;
}

.active-search-slider-icon-mobile {
	/* color: #0A043B !important; */
	/* opacity: 1; */
	display: none;
}

.search-btn {
	padding: 30px;
	font-size: 16px !important;
	height: 35px;
	width: 35px;
	margin-right: 20px;
}

@media(max-width: 321px){
	.search-Wrapper {
		margin-top: 20px;
	}

	.search-btn {
		margin-right: 0px;
	}

	.search-input-wrapper {
		width: 55%;
	}
}

.search-Wrapper-mobile {
	display: none;
}

/* .search-wrapper-mobile-view {
	display: none;
} */

.inner-search-wrapper-mobile {
	display: none;
}

.filter-div {
	display: none;
}

.more-button {
	display: none;
}

.more-button-desktop {
	display: block !important;
	font-size: 12px !important;
}


@media (min-width: 322px) and (max-width: 768px){

	.more-button-desktop {
		display: none !important;
	}
	
	.more-button {
		display: block !important;
		margin-bottom: 30px;
	}

	.search-Wrapper {
		margin-top: 20px;
	}

	.search-btn {
		margin-right: 0px;
	}

	.filter-div {
		display: inline;
	}

	.inner-search-wrapper-mobile {
		/* position:fixed; */
		/* left:0; */
		/* bottom:0; */
		/* right:0; */
		/* padding: 20px; */
		padding-bottom: 0px;
		background-color: #0A043B;
		border-radius: 8px;
		min-height: 50px;
		width: 65vw;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		/* flex: 1; */
		/* position: absolute; */
		/* display: inline; */
	}

	/* .keyword-box-mobile {
		border: solid 1px !important;
		margin-top: 5px;
		margin-bottom: 5px;
	} */
	.keyword-box-mobile {
		border: solid 1px !important;
		margin-top: 10px;
		margin-bottom: 10px;
		height: 42px;
		font-family: 'rubik';
		padding-left: 10px;
	}
	
	.list li > label {
		text-transform: capitalize;
		color: black !important;
	}
	
	.inner-search-wrapper-mobile input {
		background: transparent;
		width: 100%;
		color: #FFFFFF;
		border: none;
		/* margin-bottom: 10px; */
	}
	
	.inner-search-wrapper-mobile input::placeholder{
		color: #FFFFFF;
		font-weight: 600;
		font-style: normal;
		/* margin-left: 10px !impor; */
	}

	.jobs-mobile-vertical-small {
		border: 0.5px solid white;
		height: 46px;
		/* width: 1px; */
		margin-left: 20px;
		/* margin-top: 4px; */
		background-color: white;
	}

	.search-input-wrapper {
		width: 65%;
		background-color: #0A043B;
	}

	.search-input-wrapper-mobile {
		/* width: 65%; */
		background-color: transparent;
	}

	.search-input-wrapper-mobile input {
		background-color: #0A043B;
		border: none;
		color: white;
	}

	.search-input-wrapper-mobile input::placeholder {
		color: white;
	}

	.search-wrapper-mobile-view {
		/* background-color: #FFFFFF; */
		width: 90vw;
		position: fixed;
		bottom: 20px;
		/* right: 0px; */
		/* left: 0px; */
		z-index: 99;
		margin: 0px 5px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		background-color: #0A043B;
		border-radius: 10px;
	}

	.search-wrapper-mobile-view-absolute {
		width: 90vw;
		position: relative;
		bottom: 0px;
		z-index: 99;
		/* margin: 0px 10px; */
		display: flex;
		justify-content: flex-start;
		align-items: center;
		background-color: #0A043B;
		border-radius: 10px;
	}

	.search-slider-icon-mobile {
		height: 30px;
		width: 30px;
		opacity: 0.35;
		font-size: 30px !important;
		transform: rotate(270deg);
		margin-top: 10px;	
	}

	.search-content-mobile {
		/* display: flex; */
		/* justify-content: space-between; */
		align-items: center;
		padding: 0px 5px 0px 0px;
		height: 30px;
		background-color: #0A043B;
		/* margin-top: -4%; */
		margin-right: 30%;
	}

	.search-icon-div-mobile {
		color: white;
		margin-left: 5px;
		display: inline;
		/* margin-top: -4%; */
		/* font-size: 20px; */
	}

	.font-size-search {
		font-size: 20px !important;
	}

	.search-title-mobile {
		background-color: white;
		width: 186%;
		margin-top: 10px;
	}
}

/* .search-input-wrapper-mobile {
	display: none;
} */

.job-list-first-sec {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 30px 0px;
	padding: 10px 0px;
}

.jobs-for-u-text {
	width: 100%; 
	text-align: center; 
	/* font-size: 15px; */
	font-size: 14px;
	/* font-weight: 600; */
	font-weight: 500 !important;
	border-bottom: 1px solid #E7E7E7; 
	line-height: 0.1em;
	margin: 10px 0 0px; 
	color: #181818;
	text-transform: uppercase;
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
 } 
 
 .jobs-for-u-text span { 
	 background: #FAFBFD; 
	 padding:0 70px; 
	 font-weight: 500 !important;
}

.job-list-outer-wrapper {
	margin-top: 10px;
}

.job-list-inner-wrapper {
	margin-top: 50px;
	height: 500px;
	overflow-y: scroll;
	padding-right: 10px;
}

.recruiter-list-inner-wrapper {
	height: auto;
	overflow-y: hidden;
}

.job-item-outer {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
	padding: 10px 20px;
	margin-bottom: 20px;
	background-color: #FFFFFF;
	box-shadow: 0px 20px 60px rgba(241, 244, 248, 0.5);
	border-radius: 16px;
	max-height: 150px;
}

@media (max-width: 768px){
	.job-item-outer {
		padding: 10px 10px;
		max-height: 230px;
	}
}
.job-logo-view {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 20px 10px;
	width: 10%;
}

.job-info-view {
	width: 70%;
	margin: 0px 0px;
}

.job-action-view {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 20%;
}

@media(max-width: 768px){
	.job-action-view {
		width: 100%;
		justify-content: flex-end;
		flex-direction: row;
		align-items: center;
		/* display: none; */
		height: 25px;
	}

	.job-logo-view {
		width: 20%;
		padding: 10px 0px;
	}

	.job-info-view {
		margin: 0px 5px;
	}

	.button-sm-resp {
		padding: 5px 10px;
		font-size: 12px;
	}
}

.job-logo {
	height: 55px;
	width: 55px;
	border-radius: 50%;
}

.job-upper-info-view {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@media(max-width: 768px){
	.job-upper-info-view {
		/* flex-wrap: wrap; */
	}
}

.job-title-view {
	min-width: 55%;
}

.job-title-label {
	font-style: normal;
	font-weight: 500;
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	font-size: 14px;
	line-height: 150%;
	color: #181818;
	text-transform: capitalize;
}

.simillar-jobs-details {
	margin-top: 13.9% !important;
}

@media(max-width:950px){
	.job-title-label {
		width: 100%;
	}

	.simillar-jobs-details {
		margin-bottom: 50px;
		margin-top: 0px !important;
	}

	.similar-job-item-outer {
		flex-wrap: nowrap;
	}

	.companypage-footer {
		margin-top: 25px;
	}

	.get-in-touch-btn {
		margin-left: 15px
	}
}

.job-tag-info-view {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
	min-width: 60%;
}

@media(max-width: 768px) {
	.job-tag-info-view {
		margin-left: 27px;
	}
}

.job-status-view {
	width: auto;
	background: rgba(39, 159, 109, 0.53);
	border-radius: 16px;
	padding: 5px 20px;
	margin: 10px 10px;
}

.job-status {
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	font-style: normal;
	/* font-weight: 600; */
	font-weight: 500;
	font-size: 10px;
	line-height: 12px;
	display: flex;
	align-items: center;
	text-align: center;
	text-transform: capitalize;
	color: #FFFFFF;
}

.job-tag-view {
	width: auto;
	background: #F4F4F4;
	border-radius: 16px;
	padding: 5px 20px;
	margin: 10px 10px;
}

.job-tag {
	font-style: normal;
	font-weight: 600;
	font-size: 10px;
	line-height: 12px;
	display: flex;
	align-items: center;
	text-align: center;
	text-transform: capitalize;
	color: #0A043B;
}

@media(max-width: 768px){
	.job-status-view{
		margin-left: -18px !important;
		padding: 5px 10px;
		margin: 5px 0px;
	}

	.job-tag-view {
		padding: 5px 10px;
		margin: 5px 5px;
	}

	.job-status {
		line-height: 10px;
	}

	.job-tag {
		line-height: 10px;
	}
}

.job-application-view {
	width: auto;
	background: #0A043B;
	border-radius: 16px;
	padding: 5px 20px;
	margin: 10px 10px;
	cursor: pointer;
}

.job-application {
	font-style: normal;
	font-weight: 600;
	font-size: 10px;
	line-height: 12px;
	display: flex;
	align-items: center;
	text-align: center;
	text-transform: capitalize;
	color: #FFFFFF;
}

.job-reject-view {
	width: auto;
	background: #D48E5B;
	border-radius: 16px;
	padding: 5px 20px;
	margin: 10px 10px;
	cursor: pointer;
}

.job-reject {
	font-style: normal;
	font-weight: 600;
	font-size: 10px;
	line-height: 12px;
	display: flex;
	align-items: center;
	text-align: center;
	text-transform: capitalize;
	color: #FFFFFF;
}

.additional-job-info {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	padding: 0;
}

.job-provider-name {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 150%;
	color: #181818;
	text-transform: capitalize;
}

.job-salary {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 11px;
	color: #9A9A9A;
	margin-left: 10px;
}

@media (max-width: 768px){
	.job-provider-name {
		margin-right: 10px;
		margin-bottom: 5px;
	}

	.job-salary {
		margin-left: 0px;
		margin-top:-3px;
	}
}

.job-desc-wrapper {
	margin-top: 10px;
	text-align: justify;
	line-height: 1.5em;
	height: 3em;
	overflow: hidden;
	background: -webkit-linear-gradient(#000000, #eee);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.job-desc-wrapper :first-line {
	color: #000000;
}

@media(max-width: 768px){
	.job-desc-wrapper {
		margin-top: 2px;
		line-height: 1.3em;
	}
}

.job-filter-wrapper {
	padding: 25px 15px;
	background: #FFFFFF;
	box-shadow: 0px 20px 60px rgba(241, 244, 248, 0.5);
	border-radius: 16px;
	margin-top: 42%;
}

@media(max-width: 768px){
	.job-filter-wrapper{
		margin: 20px 10px 10px 10px;
		display: none;
	}
}

.job-shortlist-label {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 14px;
	text-align: center;
	text-decoration-line: underline;
	text-transform: uppercase;
	color: #0A043B;
	margin-top: 15px;
	cursor: pointer;
}

@media (max-width: 768px){
	.job-shortlist-label {
		margin: 0px 10px;
	}
}

.hr {
	height: 0;
	border: 1px solid #E7E7E7;
	margin-top: 10px;
	margin-bottom: 10px;
}

.new-job-logo {
	border-radius: 50%;
}

.new-job-item-outer {
	border-radius: 0px;
}

.job-list-login-wrapper {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	flex-wrap: wrap;
}

.joblist-login-overlay {
	/* position: absolute; */
	height: 100%;
	width: 100%;
	opacity: 0.25;
	background: #FFFFFF;
	/* background-color: rgba(255, 255, 255, 0.78); */
}

.login-btn-inner-view {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	opacity: 1;
	width: 100%;
	top: 45%;
}

@media(max-width: 768px){
	.login-btn-inner-view {
		top: 50%;
	}
}

.candidate-portfolio-btn {
	padding: 8px 15px;
	font-size: 12px;
}

.new-signin-btn-pos{
	margin-left: -488px;
	margin-top: 30px;
}   
.new-signup-btn-pos{
	margin-left: 30px;
    /* margin-top: 86px; */
}
.get-in-touch-button{
	margin-right: -154px;	
}
.start-now-btn-pos{
    margin-top: 15px; 
	margin-left: 0;
	text-align: center;
	font-weight:500;
}
.search-job-button-pos{
	margin-left: -313px;
    margin-top: 17px;
	font-weight: 500;
	color: #FCFDFE;
}

/* .primary-btn {
	font-weight: 800;
	font-size: 12px;
	line-height: 12px;
	text-align: center;
	letter-spacing: 0.14em;
	text-transform: uppercase;
	color: #FCFDFE;
} */

.browse-job-button{
	margin-top: 90px;
    margin-left: -554px;
	font-weight:600;
}
.find-can-button{
	margin-top: 90px;
    margin-left: -526px;
	font-weight: 600;
}
.sign-btn-div{
	margin-top: 20px;
    margin-left: 131px;
}

.home-start-now-div {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
	flex-wrap: wrap;
}

@media(max-width: 768px){
	.home-start-now-div {
		align-items: center;
		justify-content: center;
		width: calc(100% - 4rem);
		text-align: center;
	}
}
.header-spec-btn-wrapper {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	margin-top: 0px;
}

.alert-margin {
	/* margin-left: -10px !important; */
	text-align: left;
}

.about-heading {
	width: 350px;
	height: 59px;
	margin-left: 95px;
	font-family: Rubik;
	font-weight: bold;
	font-size: 44px;
	line-height: 126%;
	font-feature-settings: 'pnum' on, 'lnum' on;
	color: #000000;
	letter-spacing: .025em;
}

.about-welcome-para {
	margin-left: 95px;
	width: 375px;
	height: 177px;
	margin-top: 77px !important;
	font-family: rubiklight,Arial,sans-serif;
	font-size: 15px;
	line-height: 1.4em;
	letter-spacing: 0.08em;
	color: #616161;
	text-align: justify;
}

.welcome-img {
	left: 49.39%;
	right: 7.64%;
	top: 10.54%;
	bottom: 83.52%;
	transform: matrix(1, 0, 0, 1, 0, 0);
	border-radius: 10px;
}

.associate-recruiter-heading {
	font-size: 44px;
	font-family:'Rubik';
	/* margin-top: -20% !important; */
	color: #0B132A;
}

.about-team-head {
	/* width: 404px; */
	height: 52px;
	/* margin-left: 367px; */
	font-family: Rubik;
	font-weight: bold;
	font-size: 44px;
	line-height: 52px;
	/* font-feature-settings: 'pnum' on, 'lnum' on; */
	color: #0B132A;
}

.founder-name {
	font-size: 24px;
	line-height: 28px;
	color: #151515;
	/* margin-left: 53px; */
	/* margin-right: 62px; */
	margin-top: 19px;
}

.founder-title {
	/* font-family: Rubik; */
	/* font-style: normal; */
	/* font-weight: normal; */
	font-size: 14.6667px;
	line-height: 17px;
	text-align: center;
	color: #000000;
	margin-top: 20px;
}

.founder-desc {
	/* font-family: Rubik; */
	/* font-style: normal; */
	/* font-weight: normal; */
	font-size: 14.6667px;
	line-height: 17px;
	text-align: center;
	color: #000000;
	margin-top: 7px;
}

.signup-btn {
	height: 35px;
}

.new-bg {
	background: #FAFBFD;
}

.alert-width {
	width: 97% !important;
} 
.jumbotron-main-creative{
	display: flex;
	flex-direction: column;
    align-items: flex-start;
	justify-content: flex-start !important;
	flex-wrap:wrap;
	/* margin-top:116px; */
	margin-right: 10px;
	width: 37% !important;
} 
.header-heading-div{
    width: 100%;
    text-align: left;
}
.header-heading{
	font-size:38px;
	font-family: Rubik,Arial,sans-se rif;
	color:#ffffff;
	font-weight: 600;
}
.header-para{
	margin-top: 10px;
	font-size:15px;
	/* width:354px; */
	text-align:left;
	color:#ffffff;
	letter-spacing: 0.1em;
	font-weight: 400;
}
.signinup-btn-div{
	margin-top:15px;
}
.signup-btn-pos{
	margin-left: 30px;
    margin-top: 0px;
}

.new-candidate-heading p {
	font-size: 44px;
	font-weight:700;
}

@media (max-width: 768px){
	.mobile-view-btn-responsive{
		margin-top:0px;
		display: none;
	}
	.responsive-hidden{
		display:none;
	}
	.header-heading-div{
		width: 285px;
	}
	.header-heading{
		font-size:25px !important;
		text-align:center !important;
	}
	.jumbotron-main-creative{
		align-items: center;
		max-width:95vw;
		align-content: center;
	}
	.header-para{
		font-size: 12px;
    	width: 268px;
    	text-align: center;
	}
	.signup-btn-pos{
		margin-top:-1px !important;
	}
	.login-outer-div{
		margin-top: -78px;
    	/* margin-left: 20px; */
	}
	.hide-login-mobile{
		display:none !important;
	}
	.screenshot-div{
		justify-content: center;
		margin-left:0px !important;
	}
	.dedicated-platform-div{
		margin-left:0px !important;
		width:350px !important;
	}
	.dedicated-platform-heading{
		font-size: 25px !important;
		width: 267px !important;
		text-align: center ;
		margin-left:14px;
		margin-bottom: 30px;
	}
	.dedicated-platform-para{
		text-align: center;
    	font-size: 12px;
    	margin-top: -20px;
    	max-width: 90vw;
	}
	.search-job-button-pos{
		margin-left: -30px !important;
	}
	.skill-heading{
		font-size:25px !important;
	}
	.skill-para{
		text-align:center !important;
		font-size: 12px;
	}
	.rd-navbar-brand a{
		margin-left:0px!important;
	}
	.skill-tool-div :nth-child(1){
		width: calc(100% - 1rem);
		text-align: center;
	}

	.skill-tool-div {
		max-width: 94vw;
		height: auto;
	}
		
	.our-recruiter-heading{
		font-size:25px;
	}
	.top-brand-icon{
		margin-left:0px;
	}
	.new-candidate-heading p {
		font-size:25px;
	}
	.fast-access-div{
		display: flex;
		flex-direction: column;
		height: 140px;
		/* margin-left:15px; */
	}
	.rd-navbar-panel{
		display:flex;
		justify-content: space-between;
		flex-direction:row-reverse;
	}
}
@media(max-width:1240px){
	.signup-btn-responsive{
		margin-top:0px !important;
	}
}
@media(max-width:320px){
	.nav-list-post{
		font-size:10px;
	}
}
@media(max-width:1200px){
	.rd-navbar-panel{
		display:flex;
		justify-content: space-between;
		flex-direction:row-reverse;
	}
	.rd-navbar-brand a{
		margin-left:0px;
	}
	.brand-logo-dark{
		width:50px;
	}
}
@media(max-width:1000px){
	.screenshot-div{
		justify-content: center;
		margin-left:0px !important;
	}	
	/* .dedicated-platform-para{
		width: 486px;
	} */
	.top-brand-icon {
		margin-left:0px;
	}
}
@media(max-width:380px){
	.fast-access-div{
		display:flex;
		flex-direction:column;
		height:240px;
	}
	.footer-signin-btn-pos{
		position:absolute;
		margin-top:10px;
	}
	.footer-search-btn-pos{
		position: absolute;
    	margin-top: -26px;
	}
	.footer-signup-btn-pos{
		position:absolute;
		margin-left:0px;
		margin-top:85px;
	}
	.footer-signin-btn-responsive{
		margin-left: -183px;
		margin-top: 42px;
	}
	.footer-search-btn-responsive{
		margin-top:10px;
	}
	.footer-signup-btn-responsive{
		margin-top:10px;
	}
	.nav-list-post{
		font-size:11px;
	}
}

.experience-card p ::first-line{
	color: #000000;
}

.category-italic-header{
	margin-right: 5px;
	font-size: 10px;
	font-style: italic;
}

.submit-resume-skill{
	color:#000000;
	margin-left:0px;
}
.justify-content-sa{
	justify-content:space-around;
}
.footer-font-16{
	font-size:16px;
	letter-spacing: 0.04em;
	font-weight:600;
}
.quick-search{
	margin-top:7px;
}
.contact-page-email{
	margin-left:0px;
}

.resume-assesment-div{
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 10px 0px;
	padding: 10px 0px;
}

.resume-skill-para {
	width: 60%;
	text-align: justify;
}

.resume-bold {
	color: #000000;
	font-weight: 600;
}

.cursor {
	cursor: pointer;
}

.employer-details-text {
	margin-right: 8%;
}

.filter-div-top {
	margin-top: 18% !important;
}

.rec.rec-arrow {
    background-color: #0A043B;
	color: #FFFFFF;
}

.rec.rec-arrow:hover {
    background-color: rgb(39, 159, 141) !important;
	border-color: rgb(39, 159, 141) !important;
}

.rec.rec-arrow:focus {
    background-color: rgb(39, 159, 141) !important;
	border-color: rgb(39, 159, 141) !important;
}

.width-new-candidate {
	width: 98% !important;
	margin-left: 15px !important;
}

.team-img {
	width: 199px;
	border-radius: 12px;
}

@media only screen and (max-width: 768px) {
	.team-img {
		width: 155px;
	}

	.width-new-candidate {
		margin-left: 5px !important;
	}
}
@media only screen and (max-width: 1000px){
	.about-welcome-responsive{
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.about-welcome-para {
		margin-left: 10vw;
		height: auto;
		width: 80vw;
		margin-top: 20px !important;
	}
	.about-heading {
		margin-left: 0px;
		text-align: center;
		font-size: 25px;
		width: auto;
	}
	.about-team-head{
		font-size:25px !important;
	}
}
@media (max-width:500px){
	.welcome-img{
		width: 80vw !important;
	}
}

.new-candidate-carousel{
	margin-top:90px;
	margin-bottom:30px;
}
.our-recruiter-carsousel{
	margin-top:90px;
}
.one-stop-section{
	margin-top:90px;
}
.applied-btn{
	background-color: rgb(39, 159, 141) !important;
    border-color: rgb(39, 159, 141) !important;
	color:#FFFFFF;
}
.start-journey-div{
	display: flex;
	justify-content: center;
	align-items: center;
	padding:15px;
	margin-bottom:15px;
}
.sign-in-label{
	padding-left:5px;
}
.start-journey-font{
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif;
	font-size:15px;
	color:#0B132A;
	font-weight: 500;
}

.alert-center {
	text-align: center;
	max-width: 100% !important;
}

/* .editor-wrapper p span {
	font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif !important;
} */
.editor-wrapper p{
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	color:#9a9a9a !important;
	font-size: 14px !important;
	font-weight: 400 !important;
}
.editor-wrapper span{
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	color:#9a9a9a !important;
	font-size: 14px !important;
	font-weight: 400 !important;
}
.editor-wrapper strong{
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	color:#9a9a9a !important;
	font-size: 14px !important;
	font-weight: 400 !important;
}

.editor-wrapper li {
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	color:#9a9a9a !important;
	font-size: 14px !important;
	font-weight: 400 !important;
}

.checkbox-error-msg{
	margin-left:-25px;
	color:red;
}

.activity-section {
	min-height: 53vh;
}

.footer-div-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
}
.filters-mobile-view {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.footer-section-1{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 50%;
	margin-left: -3%;
}

.footer-section-1 ul{
	margin-left: -8px;
}

.footer-section-2 {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 50%;
}

/* .footer-section-2 ul{
	margin-left: -12px;
} */
/* .footer-section-2 p {
	margin-top: -47px;
} */

@media(max-width: 768px){
	.footer-div-container {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;
		flex-direction: row;
	}

	.footer-section-2 p {
		margin-top: 0px;
	}

	.footer-section-1 ul{
		margin-left: -20px;
	}
}



.header-btn {
	font-style: normal;
	font-weight: 500;
	line-height: 12px;
	letter-spacing: 0.14em;
	color: #FCFDFE;
}

.header-btn-group{
	margin-top: 15px;
}

@media(max-width: 768px){
	.header-login-btn {
		margin-top: 0px !important;
	}
}

.start-journey-btn{
	display: flex;
   justify-content: flex-start;
   flex-wrap: wrap;
   align-items: center;
   margin-left: 10px;
   flex-direction: row;
}
.start-journey-signup{
   margin-top:0px;
   margin-left:10px;
}
@media (min-width: 790px){
   .react-date-picker__inputGroup{
	   margin-left:3vw;
   }
}
@media (max-width:1300px){
   .start-journey-signup{
	   margin-top:0px !important;
   }
}
@media (max-width:400px){
   .start-journey-div{
	   flex-direction:column;
   }
}
@media (max-width:780px){
   .breadcrumbs-custom-title ,.terms-custom-title{
	   font-size:22px !important;
   }
   .about-heading-div{
	   margin-top:15px;
   }
}
@media (max-width:500px){
   .list-nested-responsive{
	   margin-left:-5vw;
   }
   .nested-list-terms-responsive{
	   margin-left:4vw;
   }
   .terms-list-heading-responsive{
	   margin-left:20px;
   }
}

.terms-of-use-wrapper strong{
   font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif;
}
.privacy-list-wrapper strong{
   font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif;
}

@media (min-width: 322px) and (max-width: 768px){
	.mobile-div-filter {
		/* display: flex; */
		/* justify-content: space-between; */
		align-items: center;
	}

	/* .align-center {
		text-align: center;
	} */

	.filter-parent-div {
		display: block !important;
	}

	.title-mobile {
		display: none;
	}

	input[type=checkbox] {
		-webkit-appearance: checkbox !important;
		-moz-appearance: checkbox !important;
		appearance: checkbox !important;
	}

	.jobcount-font {
		font-family: 'Rubik', Arial, sans-se rif;
		color: #4c4c4d;
	}

	.relevance-color {
		color: #0c073b;
		text-transform: none;
	}

	.filter-color {
		color: white;
	}
}

.filter-parent-div {
	display: none;
}

.width-recur {
	width: 100%;
	margin-right: 70px !important;
}
.visibility-hidden{
	visibility:hidden;
}

.infinite-scroll-component__outerdiv {
	width: 100%;
}

.infinite-scroll-component {
	overflow-x: hidden !important;
}

.job-application-view:hover,
.job-application-view:active {
	background-color: rgb(39, 159, 141);
	border-color: rgb(39, 159, 141);
}
@media (max-width:786px){
	.job-title-view{
		min-width: 60%;
	}

	.filter-line-bars {
		width: 25px;
		height: 25px;
		margin-right: 15px;
	}
}

@media (max-width:786px){
	.margin-top-20-responsive{
		margin-top:-20px !important;
	}
	.margin-top10-responsive{
		margin-top: 10px !important;
	}
	.label-years-in{
		margin-left:0px;
	}
	.margin-top-10-responsive{
		margin-top: -10px !important;
	}
	.margin-left10-responsive{
		margin-left:10px !important;
	}
	.post-job-btn{
		right:6vw !important;
	}
	.margin-top20-responsive{
		margin-top: 20px !important;
	}
	.margin-top0-responsive{
		margin-top:0px !important;
	}
	.job-application-view{
		margin: 0px;
		padding: 5px 10px;
		margin-left: 5px;
	}
		@media(max-device-width:768px) {
			.job-application-view {
				padding: 4px 6px;
				margin-left: 4px;
			}
		}
	.additional-job-info {
		width: 250px;
	}
	.margin-left0-responsive{
		margin-left:0px !important;
	}
	.profile-btn-responsive{
		padding: 5px 10px;
		margin-top: 5px !important;
	}
	.candidate-list-div-responsive{
		height:40px;
	}
	.card-item-view-responsive{
		margin-left:18vw !important;
	}
	.resume-job-title-responsive{
		width: -webkit-fill-available;
	}
	.profile-pic-responsive{
		margin-left:-80px;
	}
}
.activity-counter-main-menu {
	/* height: 2px;
	width: 2px; */
	border-radius: 50%;
	background-color: #0A043B;
	color: #FFFFFF;
	margin-left: 2px;
	font-size: 8px;
	padding: 0px 1px;
}

/* .activity-dummy {
	height: 10px !important;
	width: 10px !important;
} */

.activity-counter {
	height: 25px;
	width: 25px;
	border-radius: 50%;
	background-color: #0A043B;
	color: #FFFFFF;
	margin-left: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px 10px;
	text-align: center;
	font-size: 12px;
	font-weight: 700;
}

@media (max-width:400px){
	.sbt-btn-responsive{
		margin-top: 90px !important;
	}
}

.check-opacity::after {
	opacity: 1 !important;
}

@media (max-width:786px){
	.activity-counter {
		display: inline;
		padding: 5px 8px;
	}
}

@media (max-width:400px){
	.job-status-view{
		/* margin-left:3vw !important; */
		padding:5px !important;
	}
}

.resend-code-button{
	margin-top: 0px;
	margin-left: 20px;
}

.menu-nav-link {
	font-style: normal;
	font-weight: 500;
	line-height: 13px;
	letter-spacing: 0.14em;
	font-size: 16px;
	color: #150F3D !important;
	text-transform: uppercase;
}

@media (max-width:500px){
	.scroll-table td{
		align-items: center;
		padding: 5px;
	}
	
	.table-job-listing-badge-responsive{
		width:100%;
	}
	.table-apply-btn{
		padding: 5px 20px !important;
	}
	.scroll-table tbody{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}
	
	.scroll-table .table-job-listing-main{
		min-width:200px !important;
	}
	.company-minimal-responsive{
		min-width:220px !important;
	}
	.padding-right-15{
		padding-right:15px;
	}
}

.border-0{
	border: 0px;
}

.comapny-page-info-icons {
	width: 20px !important;
}

.vacancy-open-count {
	font-size: 12px !important;
}

.width-17{
	width: 17px;
}

.width-18{
	width: 18px;
}
.margin-left-0{
	margin-left:0px;
}

.height-fit-content{
	height: fit-content;
}

@media (max-width:786px){
	.skill-component-container{
		flex-wrap: wrap;
	}
}

.similar-job-logo-view {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* padding: 20px 10px; */
    width: 30%;
}

.similar-job-title-view {
    min-width: 100%;
}

.candidate-job-salary {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 11px;
	color: #9A9A9A;
	/* margin-left: 10px; */
}

.simillar-candidate-details {
	margin-top: 0;
}

@media (max-width:786px) {
    .similar-job-logo-view {
        width: 27%;
        padding: 10px 0px;
    }

	.simillar-candidate-details {
		margin-bottom: 50px;
	}
}

.job-filter-div{
	margin-top:41%;
}

.font-weight-300{
	font-weight: 300;
}

@media (max-width: 500px){
	.job-application-view{
		margin-right:5px;
	}
}

@media (max-width: 400px){
	.job-title-view{
		min-width: 60% !important;
	}
}

.empty-list-alert{
	margin-top: 51px !important;
}

.loader-loading-list{
	height:80vh;
}

.alert-width-div {
	max-width: 830px;
}

.big-location-name{
	max-width: 260px;
    display: inline-grid;
    margin-left: 3px;
}
@media (max-width: 786px){
	.big-location-name{
		max-width:220px;
		display: inline-flex;
	}
}

.india-presence-div{
	display: inline-grid;
	margin-left:-3px;
}

@media (max-width: 786px){
	.india-presence-div{
		width:300px;
	}

	.left-text {
		text-align: left !important;
		display: flex;
		justify-content: flex-start;
	}
}
@media (max-width: 400px){
	.india-presence-div{
		max-width: 260px;
	}
}

@media(max-width: 310px){
	.india-presence-div{
		max-width: 220px;
	}
}

.CircularProgressbar .CircularProgressbar-text {
	font-family: "Rubik" !important;
	font-weight: 500;
}

.tinyMCEstyle li {
	text-align:justify !important;
	display: inline  !important;
    font-size: 14px !important;
    font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif !important;
	background: transparent !important;
}

.tinyMCEstyle ul {
	text-align:justify !important;
	display: inline  !important;
    font-size: 14px !important;
    font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif !important;
	background: transparent !important;
}
.tinyMCEstyle ol {
	text-align:justify !important;
	display: inline  !important;
    font-size: 14px !important;
    font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif !important;
	background: transparent !important;
}

.tinyMCEstyle strong{
	text-align:justify !important;
	display: inline  !important;
    font-size: 14px !important;
    font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif !important;
	background: transparent !important;
}

.card-overflow {
	height: 50px;
	max-height:60px;
}

.tinyMCEstyle div {
	background: transparent !important;
}

.hyperlink {
	color: #0A043B;
}

.shortlisted-style {
	margin-top: 15px;
	cursor: pointer;
	padding: 9px 8px;
	font-size: 12px;
}

.remove-shortlisted-style {
	margin-top: 15px;
	cursor: pointer;
	padding: 8px 15px;
	font-size: 12px;
}

.wrapper-tick {
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #eee */
}

@media (max-width: 768px) {
	.shortlisted-style {
		margin-top: 0px !important;
		cursor: pointer;
		padding: 5px 8px;
		font-size: 12px;
		margin-right: 5px;
		margin-left: 5px;
	}

	.wrapper-tick {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 0px !important;
		margin-right: 5px;
		margin-left: 5px;
	}

	.remove-shortlisted-style {
		margin-top: 0px !important;
		cursor: pointer;
		padding: 5px 15px;
		font-size: 12px;
		margin-right: 5px;
		margin-left: 5px;
	}
}

.animated-check {
    height: 2em;
    width: 2em;
}

.animated-check path {
    fill: none;
    /* stroke: #7ac142; */
	stroke: rgb(39, 159, 141);
    stroke-width: 4;
    stroke-dasharray: 23;
    stroke-dashoffset: 23;
    animation: draw 1s linear forwards;
    stroke-linecap: round;
    stroke-linejoin: round
}

@keyframes draw {
    to {
        stroke-dashoffset: 0
    }
}
.search-component-chevron{
	font-size: 25px !important;
	color: #0A043B ;
}

.employer-carousel-div{
	width:250px !important;
	max-width: 250px !important;
	min-width: 250px !important;
	height: 250px !important;
}

@media(max-width: 400px){
	.employer-carousel-div{
		width: 220px !important;
		max-width: 220px !important;
		min-width: 220px !important;
		height: 220px !important;
		background-color: #ffffff;
		padding:0px !important;
	}
	.employer-carousel-div img{
		width:150px !important;
	}
}
@media (max-width: 350px){
	.employer-carousel-div{
		width:160px !important;
		max-width: 160px !important;
		min-width: 160px !important; 
		height:160px !important;
	}
}

.application-btn-div{
	margin-top:18px;
}

.application-btn{
	padding: 5px;
	font-size:12px;
	line-height:18px;
}
.more-btn-padding{
	padding: 5px 34.5px 5px 34.5px;
}

@media (max-width: 786px){
	.application-btn-div{
		margin-top:-30px;
		padding:5px;
	}	
	.application-btn{
		padding: 5px;
		font-size:12px;
		line-height: 15px;
	}
	.more-btn-mobile{
		padding: 5px !important;
	}
	
}

.website_com {
	word-wrap: break-word;
}

.editor-wrapper > *::first-letter {
	text-transform: uppercase !important;
}

.editor-wrapper div::first-letter {
	text-transform: uppercase !important;
}


.editor-wrapper p::first-letter {
	text-transform: uppercase !important;
}

.editor-wrapper li::first-letter {
	text-transform: uppercase !important;
}

.editor-wrapper table,
.editor-wrapper tbody,
.editor-wrapper tr,
.editor-wrapper table td {
	border: none !important;
	background-color: transparent !important;
}

.shortlist-candidate-outer-div {
	margin-top: 6%;
}

.shortlist-candidate-title-label {
    font-style: normal;
    font-weight: 500;
    font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
    font-size: 14px;
    line-height: 150%;
    color: #181818;
    text-transform: capitalize;
}

.company-website-link-div{
	overflow-wrap: break-word;
}

.job-details-icon-div {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	width: 7vw;
}
.ticker-container-div{
	width:100% !important;
	height:50px;
	display:flex;
	align-items: center !important;
	justify-content: center;
	color:#0A043B;
	background-color:transparent;
	white-space:nowrap;
}

.ticker-container-div .ticker{
	width:10000% !important;
	display: inline-flex !important;
}

.move-ticker-div{
	display: inline-flex !important;
}
.move-ticker{
	font-weight:500;
	font-size:16px;
	padding-right:5px;
}

.move-ticker-margin{
	margin-left:20px;
}
.ticker-margin-space{
	margin-left:10px;
}

ul#menu {
   width:100%;
   overflow:hidden;
   text-align:justify; /*Added this*/
   margin-top: 10px;
}

ul#menu li {
	margin-right: 10px !important;
	text-transform: capitalize;
	display:inline-block; 
}


@media (max-width: 786px){
	.nearby-button {
		width: 100%;
	}

	.nearby-button-div {
		background-color: hsl(0, 0%, 100%);
		margin-bottom: 0% !important;
	}

	ul#menu {
		background-color: white;
		margin-top: 5%;
		border-radius: 4px;
	}

	ul#menu li {
		padding: 5px;
	}
}


.job-disabled-modal {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
    position: absolute !important;
	flex-direction: column;
	width: 50vw;
	left: 25%;
    top: 25%;

    right: auto;
    bottom: auto; 
    background-color: white;
	opacity: 1;
	box-shadow: 1px 4px 29px -4px rgba(18,17,17,0.82);
	-webkit-box-shadow: 1px 4px 29px -4px rgba(18,17,17,0.82);
	-moz-box-shadow: 1px 4px 29px -4px rgba(18,17,17,0.82);
}

@media (max-width: 768px){
	.job-disabled-modal {
		left: 3vw;
		width: 90vw;
	}

	.job-details-icon-div {
		width: 24vw;
	}
}

.job-disabled-width {
	width: 100%;
}

.disable-btn {
	padding: 5px;
	font-size:12px;
	line-height:18px;
}

.disabled-input {
	width: 98%;
	height: 30%;
}

.reason-label {
	font-style: italic;
	color: red;
}

.disable-reason {
	min-height: 5px !important;
	margin-top: 20px;
}

.disable-btn-list{
	padding: 5px 25px;
	font-size:12px;
	line-height:18px;
}

.border-disable {
	border: 2px solid gray;
}

.border-disabled {
	background-color: #ddd;
}

@media (max-width: 768px){
	.disable-btn-list{
		padding: 5px;
		font-size:12px;
		line-height:15px;
		width: auto;
	}
}

.spinner-disable {
	margin-right: 40px;
}

.employer-noitem-msg-div{
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	width:100%;
}

.no-text-transform {
	text-transform: none;
}

.html2canvas-container { width: 3000px !important; height: 3000px !important; }

.border-selected-candidate {
	border: 3px solid rgba(39, 159, 109, 0.53);
	height: 65px;
	width: 65px;
	padding: 3px;
}

@media (max-width:786px){
	.reject-btn-responsive{
		margin-top: 0px !important;
		margin-left: 5px !important; 
	}
}

.select-btn-responsive{
	padding: 5px 10px;
	margin-top: 5px !important;
}

.select-btn-application {
	margin-top: 8px;
	cursor: pointer;
	padding: 9px 19px;
	font-size: 12px;
}

.selected-btn-application {
	margin-top: 8px;
	cursor: pointer;
	padding: 9px 10px;
	font-size: 12px;
	background: #279f8d;
	color: white;
	border-color: #279f8d;
}

.app-short-top {
	margin-top: 3%;
}
.loading-startnow-btn {
	height: 50px ;
	display: flex;
}
.loading-startnow-btn div{
	display: flex;
	align-items: center;
	height: 50px !important;
	width:50px !important;
	margin-top:20px;
	margin-left:20px;
}

.red-cross-image{
	height: 28px;
	width: 28px;
	margin-top: 3px;
}

.text-danger {
	color: #ff4444;
}

.margin-left10{
	margin-left:10px;
}
.share-icon-div{
	margin-top: 20px;
	display: flex;
	align-items: center;
}

@media (max-width: 768px){
	.share-icon-div{
		padding-bottom: 45px;
	}
}

.circular-percent-job-div{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 90px;
	width: 90px;
	padding: 15px;
}

.width-10-percent{
	width: 10% !important; 
}
@media( max-width: 780px){
	.circular-percent-job-responsive{
		height: 50px;
		width:50px;
		padding: 5px;
		margin-left:20px;
	}
	.reponsive-job-action-view{
		width: 70% !important;
	}
}

@media (max-width: 768px) {
    .top_margin {
        margin-top: 0px;
    }
}

.pop-up{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: flex-start;
	background-color: #fff;
	max-width: 250px;
	color: #0A043B;
	padding: 20px;
	position: absolute;
	float: right;
	border-radius: 10px;
	margin-top: -20px;
	margin-left: 30px;
	z-index: 11;
	opacity: 1;
	box-shadow: 1px 4px 29px -4px rgba(18,17,17,0.82);
	-webkit-box-shadow: 1px 4px 29px -4px rgba(18,17,17,0.82);
	-moz-box-shadow: 1px 4px 29px -4px rgba(18,17,17,0.82);
	box-sizing: border-box;
}

.pop-up.arrow-left:after {
  content: " ";
  position: absolute;
  left: -15px;
  top: 15px;
  border-top: 15px solid transparent;
  border-right: 15px solid white;
  border-left: none;
  border-bottom: 15px solid transparent;
}

.fa-info-font{
	font-size:18px !important;
	color: #0A043B !important;
	margin-top:-8px;
}

.fa-times-circle-font{
	font-size:20px !important;
	color: #DCDCDC;
	background-color: white !important;
	position: absolute;
	top:0;
	right: 0;
	margin-right: 5px;
}

.popup-msg-text{
	font-size: 14px;
	font-weight: 550;
	text-align: left;
	color: #0A043B;
}

/* .best-match{
	height: 45px;
} */

@media( max-width: 780px){
	.best-match {
		background-color: rgb(255, 255, 255);
		margin-bottom: 0% !important;
		color: #000000;
		width: 100%;
	}

	.sort-search {
		height: 90%;
	}

	.sort-search-icon {
		font-size: 20px !important;
	}
}

.text-transform-none{
	text-transform: none;
}

/* width */
::-webkit-scrollbar {
	width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
	background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 8px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
	background: var(--brand-color);
	width: 10px !important;
  }

@media (max-width: 768px){
	.padding-bottom-45{
		padding-bottom: 45px;
	}
}
	
.fa.fa-instagram {
	color: transparent;
	background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
	background: -webkit-radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
	background-clip: text;
	-webkit-background-clip: text;
	font-size: 27px;
	vertical-align: middle;
}

.list-inline-footer {
	display: flex;
	margin-left:25px !important;
	align-items: center;
	width: 100%;
}

.list-inline-footer li:hover {
	cursor: pointer;
}

.faq-search-div{
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-bottom:10px;
	flex-direction: row;
}

.faq-search-btn{
	margin-top: 0px;
}

.padding-right-20{
	padding-right: 20px;
}

mark{
    background: #fdff32;
	color: #9a9a9a;
}

.card-corporate .card-title a mark {
	font-size: 18px;
	color: #2c343b;
	font-weight: 300;
}

.slideUp {
	animation-name: slideUp;
	animation-name: slideUp;
	animation-duration: 1s;
	animation-duration: 1s;
	visibility: visible;
	top: 0 !important;
}

@keyframes slideUp {
	0% {
	  opacity: 0;
	  transform: translateY(70%);
	} 
	100% {
	  opacity: 1;
	  transform: translateY(0%);
	}
}
.show-more {
	display: flex;
	justify-content: center;
	align-items: center;
}

/* .about-social {
	width: 35%;
} */

.about-social li:hover {
	cursor: pointer;
}

.about-social-heading {
	font-style: normal;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0.14em;
	color: #151515;
}

@media (max-width: 768px){
	.about-social-heading {
		height: auto;
		width: 80vw;
		margin-top: 20px !important;
	}
}

.contact-icon {
	font-size: 15px;
	margin-top: 7px !important;
}

.contact-icon-insta {
	font-size: 30px !important;
	height: 42px !important;
	cursor: pointer;
}

.email-icon {
	font-size: 26px;
	margin-top: 7px;
}

.row-social {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
}

.row-width {
	width: 100% !important;
	display: contents;
}	
.padding-0{
	padding: 0px !important;
}
.padding-top-10{
	padding-top: 10px !important;
}

.padding-bottom-0{
	padding-bottom: 0px !important;
}

.sort-by-percent-btn{
	padding:7px 15px;
}

.reject-btn{
	padding: 8px 20px;
}

.add-video-label{
	padding-right: 20px;
}

.more-btn-style{
	padding: 9px 26px;
}

.remove-btn-style{
	padding: 9px 18px;
}

@media (max-width: 768px){
	.disable-btn-div{
		padding-left: 0px;
	}
	.remove-btn-style{
		padding: 5px;
	}
}

.add-resume-btn{
	padding: 8px 21px;
}

.submit-otp-btn{
	padding: 8px 15px !important;
	min-height: auto !important;
}

.faq-input-textbox{
	height: 36px;
	padding-left:8px;
}

.admin-dashboard-table-div{
	overflow-x:scroll;
	width:70vw;
}

.admin-dashboard-table{
	overflow-x: scroll;
	column-width: 30px !important;
    column-gap: 30px !important;
	column-rule: 2px solid black !important;
}

.admin-candidiate-dashboard-div{
	display: flex;
	width: 100%;
	padding:20px;
	align-items: center;
	justify-content:center;
}

.candidate-total-div{
	display: flex;
    width: 90%;
	justify-content: space-around;
	padding: 12px;
	background-color: #ffffff;
}	

.candidate-total-inner-div{
	display: flex;
	width: 30%;
}

.admin-candidate-dashboard{
	display: flex;
	width: 95%;
	overflow-x: scroll;
}

.showmore-btn-admin-candidate{
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	padding: 20px;
}

.admin-candidate-name{
	color: #080838!important;
	cursor: pointer;
}

.padding-top-10{
	padding-top: 10px;
}

.infinite-admin-employer-div{
	height: 370px !important;
}

.admin-employer-dashboard-div{
	display: flex;
	flex-direction: row;
	background-color: #FFFFFF;
	height: auto;
	width: 100%;
	padding: 20px;
	border-radius: 8px;
}

.admin-employer-total{
	display: flex;
	/* justify-content: space-between; */
	flex-direction:column;
	align-items: self-start;
}

.admin-employer-total-inner{
	display: flex;
	justify-content: space-between;
}

.padding-right-27{
	padding-right:27px;
}

.padding-right-23{
	padding-right:23px;
}

.admin-dashboard-table th{
	padding:5px;
	padding-right:15px;
	text-align: center;
	white-space:normal;
}

.admin-dashboard-table td{
	padding:5px;
	text-align: center;
}

.admin-dashboard-table tr{
	padding:5px;
	text-align: center;
}

.left-admin-employer-div{
	display: flex;
	flex-direction: row;
	width:50%;
}

.admin-employer-total-row{
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: flex-end;
	width: 50%;
}

.right-admin-employer-div{
	display: flex;
	flex-direction: row;
	width: 50%;
}

.admin-candidate-total-name-div{
	display: flex;
	width: 81%;
	flex-direction: column;
}

.admin-candidate-total-value-div{
	display: flex;
	flex-direction: column;
	width: 15%;
}

.table-section-admin-candidate{
	padding-left:40px;
}

@media(max-width: 768px){
	.candidate-total-div{
		flex-direction: column;
	}
	.candidate-total-inner-div{
		width: 100%;
	}
	.left-admin-employer-div{
		width: 100%;
	}
	.admin-employer-dashboard-div{
		flex-direction: column;
	}
	.right-admin-employer-div{
		width: 100%;
	}
	.right-admin-employer-div{
		justify-content: space-between;
	}
	.admin-dashboard-table-div{
		width:30%;
	}
	.table-section-admin-candidate{
		padding-left:15px;
	}
}

.cursor-pointer{
	cursor:pointer !important;
}

.no-of-cv-input{
	width:50px;
	text-align: center;
}
.user-not-present-msg{
	color: red;
}

.padding-top-0{
	padding-top:0px;
}

.footer-browse-job-div{
	margin-left: 58px;
}
.quick-search-div{
	margin-left: -15px;
}

@media (max-width: 768px){
	.footer-browse-job-div{
		margin-left: 32px;
	}

	.footer-browse-li{
		line-height: initial;
	}

	.padding-bottom-5{
		padding-bottom: 5px;
	}
}

.padding-top-50{
	padding-top: 50px !important;
}

.job-n-company-profile-div {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.company-profile-heading {
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	font-weight: 500;
	font-style: italic;
	cursor: pointer;
	text-transform: none;
	text-decoration: underline;
}

.company-details-div {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
}

.brand-landing-job-btn {
	width: 135px;
	margin-left: 30px;
}

@media(max-width: 768px){
	.brand-landing-job-btn-div {
		gap: 20px
	}

	.brand-landing-job-btn-div, .button-primary+.button {
		margin-top: 0px;
	}

	.brand-landing-job-btn {
		width: auto;
		margin-left: 0px;
		display: block !important;
	}

	.brand-landing-share-icon-div {
		margin-bottom: 70px;
	}
}

.brand-banner {
	width: 100%;
	position: relative;
	margin-top: 100px;
}

.brand-banner-img {
	width: 100%;
	height: auto;
	/* position: relative; */
}

.brand-banner-btn {
	position: absolute;
	top: 75%;
	left: 25.5vw;
}

.brand-banner-btn:hover{
	background-color: #e76042;
}

.banner-job-logo {
	width: auto;
	height: auto;
}

.royal-orchid-banner-btn {
	background-color: #312B64;
	border-color: #312B64;
	top: 50%;
	left: 31.5vw;
}

.royal-orchid-banner-btn:hover {
	background-color: #312B64;
}

@media(max-width: 768px){
	.brand-banner {
		margin-top: 0px;
	}

	.brand-banner-img {
		height: auto;
		/* position: relative; */
	}

	.brand-banner-btn {
		position: absolute;
		top: 62%;
		left: 25vw;
		display: block !important;
		font-size: 8px !important;
		padding: 2px 5px !important;
	}

	.royal-orchid-banner-btn {
		top: 40%;
		left: 25.5vw;
	}
}

.open-vacancy .width-10-percent{
	width: 20% !important; 
}

.job-item-outer-brand-banner{
	max-height: 100%;
}

.job-description-brand-banner {
	margin-top: 10px;
	text-align: justify;
	line-height: 1.5em;
	height: 100%;
	color: blue;
	overflow: hidden;
	color: #000000 !important;
}

.job-description-brand-banner p {
	color: #000000 !important;
}

.assesment-disable-btn{
	font-size: 10px !important;
	padding:5px 10px !important;
	letter-spacing: .1em;
	line-height: 13px !important;
}

.padding-top-20{
	padding-top:20px;
}


.date-filter-div{
	display: flex;
    justify-content: center;
    align-items: center;
	padding-bottom: 20px;
}

.padding-right-10{
	padding-right: 10px;
}

.date-filter-search-div{
	display: flex;
	justify-content: left;
	align-items: center;
	padding-bottom: 0px;
}

.date-filter-div .react-date-picker__inputGroup{
	margin-left: 10px;
}

.date-filter-div .react-date-picker__inputGroup input{
	padding: 0px;
	min-width: 10px;
	font-size: 14px !important;
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
}

.date-filter-div .react-date-picker__inputGroup__input--hasLeadingZero{
	font-size: 14px !important;
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	padding-left: calc(1px + 0.54em) !important;
}

.react-date-picker__inputGroup__input--hasLeadingZero{
	margin-top:-1px;
}

.margin-left5{
	margin-left:5px !important;
}

.download-excel-candidate-btn-div{
	display: flex;
	flex-direction: row-reverse;
	width: 95%;
	padding: 10px;
}

.download-job-excelbtn-div{
	display: flex;
    width: 98%;
    flex-direction: row-reverse;
}

.admin-dashboard-row-approve-div{
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.accept-reject-employer-label-div{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.employer-descrition-div{
	width: 70%;
	cursor: pointer;
}

.employer-logo-class{
	width:14%;
	cursor: pointer;
}

.accept-reject-employer-label-div label{
	color: #080838 !important;
	cursor: pointer;
	margin-right: 10px;
}

.break-word-hypen{
	hyphens: auto !important;
}

.admin-dashboard-row-approve-div .wrapper-tick{
	height: 25px;
	width: 25px;
}

.admin-dashboard-row-approve-div .img{
	height: 25px;
	width: 25px;
}

.full-excel-btn-div{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.youtube-icon{
	color: red;
	font-size:31px !important;
}

.youtube-icon-li{
	margin-top: 5px !important;
}

.about-share-ul{
	display: flex !important;
	justify-content: flex-start !important;
	align-items: center !important;
}

.blog-banner-img-section{
	width: 90%;
	border: 2px dotted #FAFBFD;
	border-radius: 8px;
	height: 350px;
	padding: 10px;
	display: flex;
	justify-content: center;
	flex-direction: row;
	background-color: #ffffff;
}

.defalut-banner-img-div{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.banner-image-label-div{
	display: flex;
	justify-content: left;
	align-items: center;
}

.blog-banner-img-section img{
	width: 100% !important;
	height: 100% !important;
}

.blog-banner-edit-div{
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;
	width: 7%;
}

.blog-banner-div{
	display: flex;
	justify-content: center;
	align-items: center;
	width:90%;
	height:100%;
}

.blog-banner-img-section:hover{
	box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.width-50{
	width: 50% !important;
}

.width-80{
	width: 80% !important;
}

.blog-seo-title{
	font-style: italic !important;
}

.count-title-char-div{
	display: flex;
	width: 100%;
}

.blog-card-outer{
	display: flex;
    justify-content: center;
    max-height: 300px !important;
    height: 300px;
}

.blog-banner-img{
	width: 100% !important;
	height: 90% !important;
	max-width: 100% !important;
}

.width-100{
	width: 100%;
}

.blog-bothimage-div{
	display: flex;
	justify-content:center;
	width: 100%;
}

.width-30{
	width: 30% !important;
}

.width-70{
	width: 70% !important;
}

.blog-banner-img-div{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 350px;
	position: relative;
}

.blog-banner-img-div:hover{
	box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
	border:5px solid #FAFBFD;
	border-radius: 4px;
}

.background-color-white{
	background-color: #ffffff;
	border: 5px solid #FAFBFD;
}

.blog-edit-items{
	display: flex;
    height: 100%;
    position: absolute;
    width: 100%;
    align-items: flex-start;
	right: 2em;
    top: 2em;
}

.blog-both-label-div{
	display: flex;
	width: 100%;
}

.blog-banner-label-div{
	display: flex;
	flex-direction: flex-start;
	width: 52%;
}

.blog-feature-label-div{
	display: flex;
	width: 100%;
	flex-direction: flex-start;
}

.both-banner-feature-div{
	display: flex;
	flex-direction: row-reverse;
}

.title-blog-link{
	white-space: inherit !important;
}

.role-button {
	display: flex;
	justify-content: center;
	align-items: center;
}

@media(max-width: 768px){
	.role-button {
		justify-content: left;
		align-items: center;
	}
}

.delete-role {
	cursor: pointer;
}

.usercreate-button {
	display: flex;
	justify-content: center;
	align-items: center;
}

.edit-user {
	cursor: pointer;
}

.loader-users {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 90%;
	height: 55vh;
}

.roles-table {
	width: 80%;
}

.view-password-user {
    position:absolute;
	margin-top: 2.5%;
	margin-left: 94%;
	color: #0A043B;
	cursor: pointer;	
}

.tjp-user-div {
	background-image: url(/img/bg-image.jpg);
	height: 60vw;
}

.outer-login-div {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
	background-image: url(/img/bg-image.jpg);
}

.main_div {
    width: 365px;
    background: #fff;
    padding: 15px 30px 30px;
    border-radius: 5px;
    box-shadow: 5px 5px 10px rgb(0 0 0 / 15%);
}

.brand_logo_view {
    margin-top: 0;
    display: flex;
    justify-content: center;
}

.title {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    color: #0A043B;
    margin-top: 15px;
    text-transform: uppercase;
    text-decoration: none;
}

.input_box {
    height: 50px;
    width: 100%;
    position: relative;
    margin-top: 15px;
}

.view-password-login {
    position:absolute;
	margin-top: 4.5%;
	margin-left: 93%;
	color: #0A043B;
	cursor: pointer;	
}

.banner-div{
	display: flex;
	flex-direction: column;
	width: 100% !important;
	position: relative;
}

.blog-img-banner{
	display: flex;
	width:100%;
	justify-content: center;
	align-items: center;
	background-color: #ffffff;
	height: 350px;
	border-radius: 4px;
}

.blog-img-banner:hover{
	box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.edit-delete-btn-div{
	display: flex;
	position: absolute;
	top: 3rem;
	right:2rem;
	justify-content: flex-end;
	width: 100%;
}

.banner-img-new{
	height: 100% !important;
}

.blog-second-image-class{
	height: 100% !important;
	width: 70% !important;
}

.font-color-white{
	color: #ffffff;
}

.default-blog-author-img{
	font-size: 26px !important;
}

.edit-icon-blog-details{
	display: flex;
    width: 100%;
    position: absolute;
    align-items: flex-end;
	right: 5em;
}

@media (max-width: 768px) {
	.edit-delete-btn-div-responsive{
		right: 3em;
		margin-top: 70px;
	}
	.blog-edit-items-responsive{
		justify-content: flex-end !important;
	}
	.blog-banner-label-div{
		width: 100% !important;
	}
	.author-image-responsive{
		margin-left:10px !important;
		display: flex;
		justify-content: center;
		width: 100%;
	}
}

.both-feature-image-wrapper{
	display: flex;
	width: 100%;
	flex-direction: row;
}

.feature-image-wrapper{
	display: flex;
	width: 35%;
	flex-direction: column;
}

.second-feature-image-wrapper{
	display: flex;
	width: 65%;
	flex-direction: column;
}

@media (max-width: 768px){
	.feature-image-wrapper{
		width: 100%;
	}
	.second-feature-image-wrapper{
		width: 100%;
	}
	.both-feature-image-wrapper{
		flex-wrap: wrap;
	}
}

.add-photo-edit-icon{
	right: 0.5em !important;
	top: 0 !important;
}

.company-gallery-image-div{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100% !important;
	height: 100% !important;
}

.margin-top-10{
	margin-top:-10px !important; 
}

.blog-unsubscription-div{
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 200px;
}

.blog-unsubscription-div p{
	font-size: 40px;
	color: #0A043B;;
	font-weight: 600;
	padding-bottom: 30px;
}

.brand-banner-tata {
	width: 100%;
	position: relative;	
}

.tjp-banner {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 70vw;
	height: 165px;
	background-color: #15565d;
	padding: 20px;
	border-radius: 10px;
	margin-bottom:15px;
}
  
.logo {
	width: 15%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 10px;
	margin-right: 10px;
}
  
.logo img {
	width: 160px;
	height: auto;
}
  
.banner-divider {
	width: 1px;
	height: 100%;
	background-color: #ffffff;
	margin-left: 10px;
	margin-right: 10px;
}
  
.banner-content {
	width: 70%;
	height: 100%;
	padding-left: 10px;
	padding-right: 11%;
	text-align: left;
	margin-top: 2%;
}
  
.banner-content h5 {
	color: #ffffff;
	font-weight: 500 !important;
	font-size: 19px;
}
  
.banner-content p {
	color: #ffffff;
	font-weight: 400 !important;
}
  
.banner-btn-view {
	width: 90px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 30px;
}
  
.banner-btn-view button {
	color: #15565d;
	width: 100%;
	font-size: 10px;
	border-radius: 20px;
	background-color: #ffffff;
	outline: none;
	border: none;
	cursor: pointer;
	height: 36.9px;
	font-size: 14px !important;
}

@media (max-width: 768px) {
	.tjp-banner {
		display: grid;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 10%;
		background-color: #15565d;
		border-radius: 10px;
	}

	.logo {
		width: 100%;
		display: block;
	}

	.banner-divider {
		width: 100%;
		height: 1px;
		background-color: #ffffff;
		margin-left: 5px;
		margin-right: 5px;
		margin-top: 2%;
	}

	.banner-content {
		width: 110%;
		height: 100%;
		padding-left: 5px;
		text-align: center;
		margin-top: 5%;
	}

	.banner-content p {
		margin-top: 2% !important;
	}

	.banner-btn-view {
		width: 100% !important;
		display: flex;
		width: 90px;
		justify-content: center;
	}

	.banner-btn-view button {
		width: 30%;
	}
}


.plus-initiative-outer-wrapper{
	display: flex;
	width: 100%;
	flex-direction: row;
	height: 500px;
	justify-content: center;
	align-items: center;
	background-color: #ffffff;
}

.plus-image-div{
	display: flex;
	width: 50%;
	justify-content: center;
	align-items: center;
	padding: 10px;
}

.plus-initiative-para-outer-div{
	display: flex;
	width: 40%;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding:10px
}

.highlights-of-initiative-outer-div{
	display: flex;
	width: 100%;
	height: 350px;
	background-color: #FAFBFD;
	flex-direction: column;
}

.highlights-heading{
	display: flex;
	justify-content: left;
	width:100%;
	padding-top:25px;
	padding-left: 25px;
}

.highlights-box-div{
	display: flex;
	padding-top: 20px;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	width: 100%;
}

.highlights-second-box-div{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 200px;
	width:25%;
	border: 4px solid #D6D8DA;
	border-radius: 20px;
	box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
	background-color: #ffffff;
	margin-left: 22px;
	margin-right: 22px;
	flex-direction: column;
}
.hightlight-box-element{
	color: #66C9CB;
	font-size: 80px;
}

.box-element-text{
	font-size: 30px;
	color: #000000;
	margin-top:-22px;
	letter-spacing: -0.9px;
}

.highlights-first-box-div{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 20%;
	height: 160px;
	border-radius: 20px;
	box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
	background-color: #ffffff;
	flex-direction: column;
	border: 4px solid #D6D8DA;
}

.highlight-first-box-element{
	color: #66C9CB;
	font-size: 28px;
}

.first-box-element-text{
	font-size: 21px;
	color: #000000;
	padding-left: 35px;
	line-height:20.2px;
}

.first-box-element-text-div{
	width: 100%;
	justify-content: center;
	align-items: center;
}

.highlights-third-box-div{
	display: flex;
	justify-content: center;
	align-items: center;
	border: 4px solid #D6D8DA;
	border-radius: 20px;
	box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
	background-color: #ffffff;
	flex-direction: column;
	width: 20%;
	height: 160px;
}

.third-box-element-text{
	font-size: 21px;
	color: #000000;
	margin-top:-10px;
}

.vision-of-initiative-outer-wrapper{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	background-color: #ffffff;
	height: 400px;
}

.plus-heading-div{
	display: flex;
	justify-content: left;
	align-items: center;
	width:100%;
}

.plus-initiative-para{
	color: #000000;
	font-size: 14px;
}

.vision-image-div{
	display: flex;
	width: 55%;
	justify-content: center;
	align-items: center;
	padding: 10px;
}

.vision-para-outer{
	display: flex;
	width: 40%;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding-right:20px;
}

.vision-initiative-heading{
	display: flex;
	justify-content: left;
	width: 100%;
	padding-bottom: 20px;
}

.about-job-plus-wrapper{
	display: flex;
	justify-content: center;
	width: 100%;
	align-items: center;
}

.about-job-plus-para-outer{
	display: flex;
	justify-content: center;
	width: 55%;
	padding: 10px;
	flex-direction: column;
	padding-right:25px;
}

.about-job-plus-image-div{
	display: flex;
	justify-content:center;
	width: 30%;
	padding: 10px;
}

.tourism-hospitality-skill-wrapper{
	display: flex;
	width: 65%;
	justify-content: center;
}

.tourism-image-div{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30%;
	padding:10px;
}

.tourism-para-div{
	display:flex;
	width: 65%;
	justify-content: center;
	flex-direction: column;
	padding: 10px;
}

.plus-carousel-img{
	height: 200px !important;
	width: 275px !important;
}

/* .photo-gallery-heading{
	margin-left: 5vw;
} */

.plus-project-wrapper{
	display: flex;
	width: 100%;
	justify-content: center;
	height: 400px;
}

.plus-project-para-outer{
	display: flex;
	justify-content: center;
	width: 35%;
	padding:10px;
	flex-direction: column;
}

.plus-project-images-div{
	display: flex;
	justify-content: center;
	align-items: center;
	width:55%;
	padding: 10px;
}

.plus-oberoi-marriot-div{
	display: flex;
	width:100%;
	padding-top:20px;
}

.plus-oberoi-marriot-div img{
	width: 186px;
	height: auto !important;
}

.vertical-line-separtor{
	border-left: 2px solid #CCCCCC;
  	height: 85px;
	padding-right: 5px;
}

.padding-right-5{
	padding-right: 5px;
}

.plus-partner-button-div{
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	padding-top: 20px;
	height: 220px;
	background-color: #0A043B;
	flex-direction: column;
}

.plus-initiative-video-outer{
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	height: 450px !important;
	background-color: #FAFBFD;
}

.plus-initiative-video-outer div{
	display: flex;
	justify-content: center;
	align-items: center;
	width:100% !important;
	height:100% !important;
}

@media (max-width: 768px){
	.plus-initiative-outer-wrapper{
		flex-direction: column-reverse;
		height: auto;
	}
	.plus-image-div{
		width: 100%;
	}
	.plus-initiative-para-outer-div{
		width: 100%;
	}
	.highlights-box-div{
		flex-direction: column;
		height: auto;
	}
	.highlights-second-box-div{
		width: 90%;
		margin-bottom:10px;
	}
	.highlights-first-box-div{
		width:75%;
		margin-bottom:10px;
	}
	.highlights-third-box-div{
		width:75%;
		margin-bottom:10px;
	}
	.plus-project-para-outer{
		width: 100%;
	}
	.plus-project-wrapper{
		flex-direction: column;
		height: auto;
	}
	.highlights-of-initiative-outer-div{
		height: auto;
	}
	.plus-project-images-div{
		flex-direction: column;
		width:100%;
	}
	.vision-of-initiative-outer-wrapper{
		flex-direction: column-reverse;
		width: 100%;
		height: auto;
	}
	.vision-para-outer{
		width: 100%;
		padding-left:0px;
	}
	.vision-image-div{
		width: 100%;
	}
	.photo-gallery-heading{
		margin-left: 0;
	}
	.about-job-plus-wrapper{
		flex-direction: column;
	}
	.about-job-plus-para-outer{
		width:100%;
	}
	.about-job-plus-image-div{
		width: 100%;
	}
	.tourism-hospitality-skill-wrapper{
		flex-direction: row;
		width: 90%;
		margin-top: 10px;
	}
	.tourism-para-div{
		width: 100%;
	}
	.tourism-image-div{
		width: 50%;
	}
	.plus-westin-image{
		padding:0px;
		padding-bottom: 10px;
	}
	.plus-oberoi-marriot-div{
		justify-content: center;
	}
	.plus-dubai-image{
		padding: 0px;
	}
	.plus-initiative-video-outer{
		height: 250px;
	}
}

@media (max-width: 350px){
	.plus-oberoi-marriot-div{
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}

/* .plus-parallex-carousel-outer{
	display: flex;
	justify-content: center;
	align-items: center;
	width:100%;
}

.plus-parallex-carousel-outer .rec-dot_active{
	background-color: #0A043B !important;
	color: #0A043B !important;
	border-color: #0A043B !important;
} */

.section-parallex-carousel{
	margin-top: 100px;
}

.plus-carousel-first{
	width:100% !important;
	height: 400px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #FAFBFD;
}
.plus-carousel-logo{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 20px;
	background-color: #ffffff;
	border: 5px solid #0A043B;
	border-radius: 50%;
	height: 200px;
	width: 200px;
}

.plus-carousel-text{
	display: flex;
	justify-content:center;
	align-items: center;
	width: 55%;
	padding: 20px;
}

.initiative-logo-image{
	height: 50px !important;
	width: auto !important;
}

.plus-carousel-para{
	font-size: 24px;
	line-height: initial;
	color: #ffffff;
}

.plus-banner-text-div span{
	color: #ffffff;
	font-weight: 600!important;
	font-size: 24px;
}
.plus-parallex-carousel-outer .krmNah{
	box-shadow: 0 0 1px 3px #0A043B !important;
}

@media (max-width: 768px){
	.plus-carousel-para{
		font-size: 18px !important;
	}
	.plus-carousel-text span{
		font-size: 18px !important;
	}
	.initiative-logo-image {
		width:60px !important;
		height:60px !important;
	}
	.section-parallex-carousel{
		margin-top: 0px;
		padding:10px 0px 10px 0px;
	}
	/* .plus-carousel-first{
		height: 280px;
		flex-wrap: wrap;
	} */
	.plus-carousel-img{
		width: 250px !important;
		height: 180px !important;
	}
}

@media (max-width: 400px){
	.plus-photo-gallary .plus-carousel-img{
		width: 250px !important;
		height: 180px !important;
	}
}

.navbar-plus-initiative-menu{
	margin-left: -3px !important;
}

.plus-oberoi-marriot-outer-div{
	display: flex;
	width: 82%;
	padding-top:20px;
	height: 80px;
}

@media (max-width: 768px){
	.plus-oberoi-marriot-outer-div{
		width: 100%;
	}
}

.partner-us-modal {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
    position: absolute !important;
	flex-direction: column;
	width: 50vw;
	left: 25%;
    top: 0;
    right: auto;
    bottom: auto; 
    background-color: white;
	opacity: 1;
	box-shadow: 1px 4px 29px -4px rgba(18,17,17,0.82);
	-webkit-box-shadow: 1px 4px 29px -4px rgba(18,17,17,0.82);
	-moz-box-shadow: 1px 4px 29px -4px rgba(18,17,17,0.82);
}

@media (max-width: 768px) {
	.partner-us-modal {
		left: 5vw;
		width: 90vw;
	}

	.job-details-icon-div {
		width: 24vw;
	}

}

.padding-left15{
	padding-left: 15px;
}

.plus-thsc-para{
	padding-left: 27px;
}

@media (max-width: 768px){
	.highlights-div-box{
		display: flex;
		justify-content: center;
		align-items: center;
		height: 550px !important;
		width: 100% !important;	
	}
	.empty-highlight-div{
		margin-bottom: 0px!important;
	}
}

@media (max-width: 350px){
	.first-box-element-text {
		font-size: 16px;
	}
	.third-box-element-text{
		font-size: 16px;
	}
	.highlight-first-box-element{
		font-size: 25px;
	}
	.hightlight-box-element{
		font-size: 60px;
	}
	.box-element-text {
		font-size: 24px;
	}
}

.plus-enquiry-outer{
	display: flex;
	justify-content: center;
	align-items: center;
	width:100%;
	margin-bottom: 20px;
}

.brand-color{
	color: #0A043B !important;
}

@media (max-width: 768px){
	.plus-enquiry-table{
		width:50%;
		overflow-y: scroll;
	}
	.plus-enquiry-outer{
		display: block;
		overflow-x: scroll;
	}
	.partner-modal-div{
		height: auto !important;
	}
}

.plus-video-player{
	width: 100%;
	height: 300px;
	min-width: 100%;
	min-height: 100%;
}

@media (max-width: 350px){
	.partner-us-modal {
		top:5%;
		height: 500px;
		overflow-y: scroll;
	}
}

.blog-details-html-jsx {
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	font-size: 14px !important;
	font-style: normal !important;
	font-weight: 400 !important;
	text-align: justify !important;
}

.blog-details-html-jsx .mce-content-body .MsoNormal > *,
.blog-details-html-jsx .mce-content-body p > * {
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	font-size: 14px !important;
	font-weight: 400 !important;
	line-height: 1.5 !important;
}

.blog-details-html-jsx .mce-content-body .MsoNormal > span > span > a ,
.blog-details-html-jsx .mce-content-body p > span > span > a  {
	color: #3366CC !important;
}

.blog-details-html-jsx .mce-content-body h2, 
.blog-details-html-jsx .mce-content-body .heading-2 {
	font-weight: 600 !important;
	line-height: 1.20833 !important;
}

.blog-details-html-jsx .mce-content-body p+h2, 
.blog-details-html-jsx .mce-content-body p+.heading-2 {
	margin-top: 0px !important;
}

.blog-details-html-jsx .mce-content-body h2 > span > *, 
.blog-details-html-jsx .mce-content-body .heading-2 > span > *
{
	font-weight: 600 !important;
	font-size: 18px !important;
}

.blog-details-html-jsx .mce-content-body h3 > span > *, 
.blog-details-html-jsx .mce-content-body .heading-3 > span > *
{
	font-weight: 600 !important;
	font-size: 15px !important;
}

.blog-details-html-jsx a,
.blog-details-html-jsx a > * {
	color: #3366CC !important
}

.plus-heading-div h1{
	font-weight: 500;
    font-family: Rubik,Calibri,Trebuchet MS,sans-serif!important;
    font-size: 24px;
}
@media (max-width: 768px){
	.plus-heading-div h1{
		font-size: 18px;
	}
}
.plus-para-div{
	margin-top:3px;
}

.blog-details-html-jsx .mce-content-body h2 > span > span > *, 
.blog-details-html-jsx .mce-content-body .heading-2 > span > span >  *
{
	font-weight: 600 !important;
	font-size: 18px !important;
}

.blog-details-html-jsx .mce-content-body p > strong > span > * {
	font-weight: 600 !important;
}

.blog-details-html-jsx .mce-content-body h2 > strong > span > *, 
.blog-details-html-jsx .mce-content-body .heading-2 > strong > span >  *
{
	font-weight: 600 !important;
	font-size: 18px !important;
}

.plus-banner-outer-div{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 200px;
}

.plus-banner-div{
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	height: 120px;
	border: 2px solid #0A043B;
	background: #0A043B;
	padding: 10px;
}

.plus-banner-text-div{
	display: flex;
	justify-content: right;
	flex-direction: end;
	width: 75%;
	padding-right: 30px;
}

.plus-banner-combine-image-div{
	display: flex;
	justify-content: center;
	width: auto;
	align-items: center;
	padding-right: 25px;
}

.highlights-service-outer-div{
	display: flex;
	justify-content: space-between !important;
	width: 100%;
	align-items: center;
	padding: 15px;
}

.hightlight-div{
	display: flex;
	justify-content: center;
	padding:10px;
}

.hightlight-blue-text {
	display: flex;
	justify-content: center;
	width: 100%;
	align-items: center;
	flex-direction: column;
}

.highlight-blue-para{
	font-weight: 600 !important;
	font-size: 22px;
	color: #5B9BD6;
	text-align: center;
	line-height: normal;
}

.hightlight-service-text{
	color: #000000;
	text-align: center;
	margin-top:0px !important;
}

.tourism-hospitality-outer-wrapper{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.affiliations-div{
	display: flex;
	justify-content: center;
	align-items: center;
	padding-right: 25px;
}

.affiliations-div p{
	font-size: 22px;
	color: #000000;
	font-weight: 600 !important;
}

.tourism-para p{
	font-size: 13px !important;
}

.tourism-heading h4{
	font-size: 18px !important;
}

.affiliation-vertical-line{
	border: 2px solid #0A043B;
	height: 150px;
	background-color: #0A043B;
}

.plus-partner-text-div{
	padding-bottom:10px;
}

.plus-partner-text-div p{
	font-size: 22px;
	color: #ffffff;
	font-weight: 500;
}

.partnter-btn{
	color: #000000 !important;
	font-weight: 700 !important;
	padding: 15px 100px 15px 100px; 
	border-radius: 10px;
	background-color: #ffffff;
}

.btn-text-partner{
	font-weight: 500;
	font-size: 18px;
	text-transform: none;
}

.what-we-do-initiative-wrapper{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	flex-direction: row;
	padding: 10px;
}

.plus-clients-wrapper{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	flex-direction: row;
	padding: 10px;
}

.clients-images-div{
	display: flex;
	align-items: center;
	width: 70%;
	padding: 10px 10px 10px 5px;
}

.active-complete-project-outer-div{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 30%;
	padding: 10px;
}

.plus-westin-image{
	width: auto;
	height: 200px;
}

.plus-dubai-image{
	width: auto;
	height: 200px;
}
.active-complete-heading{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.active-complete-heading p{
	font-size: 22px;
	font-weight: 600;
	color: #000000;
	text-align: center;
	line-height: initial;
} 

.active-complete-para p{
	color: #000000;
	text-align:center;
}

.logo-class-oberoi{
	position: absolute;
}
.combine-image-class{
	height: 45px;
	width: auto;
}

.what-we-do-outer-div {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	background-color: #FAFBFD;
	flex-direction: column;
	min-height: 400px;
	padding: 20px;
}

.what-we-do-heading-div {
	display: flex;
	justify-content: flex-start;
	width: 100%;
}

.what-we-do-column-div{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	width: 100%;
}

.recruit-circle {
	border-radius: 10px;
	margin-bottom: 1.5%;
	width: 125px;
	height: 125px;
	border: 4px solid #0a043b;
	background-color: #0a043b;
	border-radius: 50%;
	text-align: center; 
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
}

.recruit-circle h4 {
	color: #FFFFFF;
}
.what-we-do-column-div ol {
	list-style-type: upper-roman !important;
}

.what-wedo-first-div li{
	padding-left:20px;
	color: #000000;
}

.what-we-do-column-div h3{
	text-align: center;
	font-weight: 600;
	margin: 10px o;
}

.what-wedo-first-div{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 33%;
	padding: 15px
}

.what-wedo-third-div{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 35%;
}

.what-wedo-third-div li{
	padding-left:20px;
	color: #000000;
}

.what-we-do-column-div h4{
	line-height: initial !important;
}

.plus-images-client-div{
	display: flex;
	justify-content: center;
	align-items: flex-end;
	flex-direction: column;
	/* position: relative; */
}

.comine-logo-class{
	display: flex;
	justify-content: center;
	/* position: absolute; */
	background-color: #ffffff;
	height: 45px;
	width: 100%;
	margin-bottom: 15px;
	padding-top:5px;
	padding-bottom: 5px;
	/* z-index: 99999; */
}

.client-oberoi-image-class{
	width: 130px;
	height: auto;
}

.client-mahindra-image-class{
	width: 65px;
	height: auto;
}

.client-marriott-image-class{
	width: 80px !important;
	height: auto !important;
}

@media (max-width: 768px){
	.vision-of-initiative-outer-wrapper{
		flex-direction: column;
	}
	.what-we-do-column-div{
		flex-direction: column;
	}
	.what-wedo-first-div{
		width: 100%;
		flex-direction: row;
		padding: 0px;
	}
	.what-wedo-third-div{
		width: 100%;
		flex-direction: row;
	}
	.recruit-circle{
		width: 75px !important;
		max-width: 75px !important;
		min-width: 75px !important;
		height: 75px !important;
		margin-right: 30px;
		margin-top: -60px !important;
	}
	.photo-gallery-heading-h4-div {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.what-we-do-column-div h4{
		font-size:15px;
	}
	.what-we-do-column-div ol{
		margin:10px 0px 10px 0px;
	}
	.what-wedo-first-div li {
		padding-left: 10px;
		line-height: normal !important;
	}
	.what-wedo-third-div li{
		padding-left:10px;
		line-height: normal !important;
	}
	.clients-images-div{
		width: 100%;
		padding:0px;
	}
	.plus-images-client-div{
		width:100% ;
	}
	.plus-clients-wrapper{
		flex-direction: column;
		padding-top: 10px;
	}
	.active-complete-project-outer-div{
		width:100%;
	}
	.active-complete-heading p {
		font-size: 14px;
		font-weight: 600;
	}
	.plus-westin-image{
		width:110px !important;
		height:110px;
		padding:2px;
	}
	.comine-logo-class{
		height:20px;
		padding:0px;
	}
	.plus-dubai-image{
		padding: 2px;
		width: 110px;
		height: 110px;
	}
	.client-oberoi-image-class{
		width: 80px;
	}
	.client-marriott-image-class{
		width: 50px !important;
	}
	.highlights-service-outer-div {
		flex-wrap: wrap;
		align-items: flex-start;
		padding: 10px 0px;
	}
	.plus-partner-text-div p {
		font-size: 16px;
		text-align: center;
	}
	.plus-partner-text-div{
		width:90%;
		padding-bottom:5px;
	}
	.partnter-btn{
		padding: 15px 35px 15px 35px;
	}
	.btn-text-partner {
		font-size:15px;
	}
	.affiliations-div{
		width:25%;
		padding-right:5px;
	}
	.affiliations-div p {
		font-size:14px;
	}
	.tourism-heading h4{
		font-size:12px !important;
	}
	.highlight-blue-para{
		font-size: 14px;
	}
	.hightlight-div{
		width:33%;
		padding: 10px 0px;
	}
	.hightlight-service-text{
		font-size:14px;
		line-height: normal;
		margin-top:5px !important;
	}
	.responsive-highlight-div{
		width: 50%;
	}
	.plus-carousel-para{
		font-size: 18px;
	}
	.plus-banner-text-div span{
		font-size: 18px;
	}
	.plus-carousel-logo {
		height: 100px;
		width:100px;
		border: 6px solid #0A043B;
	}
	.plus-banner-div {
		height: 180px;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 10px;
	}
	.plus-banner-combine-image-div {
		padding-right: 10px;
	}
	.plus-banner-text-div{
		padding:0px;
	}
	.plus-banner-outer-div{
		justify-content: flex-start;
		align-items: center;
	}
	.tourism-hospitality-outer-wrapper{
		flex-direction: column;
	}
	.tourism-para p{
		font-size: 11px !important;
	}
	.affiliation-vertical-line{
		border: 2px solid #0A043B;
		height: 2px;
		background-color:#0A043B;
		width:40%;
	}
	.padding-top-0 {
		padding-top: 0px !important;
	}

	.what-we-do-outer-div {
		padding: 5px;
	}
}

.partner-us-modal{
	top: 17%;
	height: 75vh;
	overflow-y: scroll;
	padding: 10px;
}

.section-background-color{
	background-color: #FAFBFD;
}

.partner-section{
	padding-bottom: 0px;
}

.what-we-do-heading{
	padding-left: 20px !important;
}

@media (max-width: 768px){
	.what-we-do-heading{
		padding-left: 0px !important;
	}	
	.vision-initiative-heading{
		padding-bottom: 0px;
		display:flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
	}
	.plus-client-section{
		padding-top: 25px;
		padding-bottom: 10px;
	}
	.plus-highlight-section{
		padding-top: 0px;
	}
	.plus-joint-image{
		padding-top:3px !important;
		padding-bottom: 0px !important;
	}
	.client-mahindra-image-class{
		width: 45px;
	}
}

.plus-inquiry-show-more{
	padding-bottom: 20px;
}

.partnter-btn:hover , .partnter-btn:active{
	background-color: #279f8d;
	color: #ffffff !important;
	border-color: #279f8d;
}

@media (min-width: 768px){
	.plus-banner-outer-div{
		height: 225px;
	}
	.jumbotron-creative-inner{
		margin-top: 100px;
	}
}

@media (max-width: 768px){
	.header-login-view{
		justify-content: center;
		align-items: center;
	}
}

.new-banner-login-btn{
	border: 3px solid #ffffff !important;
}

.sign-btn-p-text{
	font-weight: 500;
}

@media (min-width: 768px){
	.vertical-line-new-banner{
		width:3px;
		height: 300px;
		background-color: #FA5F2A;
		margin-right: 10px;
		margin-top: 100px;
	}
}

.password-view-new{
	margin-top: 11% !important;
	margin-left: 42% !important;
}

@media (max-width: 768px){
	.password-view-new{
		margin-top: 13% !important;
		margin-left: 41% !important;
	}
}

.contact-form-div {
	border-radius: 60px;
	border: 2px solid #D6D8DA;
	padding: 50px;
	width: 90%;
	margin-left: 30px;
}
.option-contact-div {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	padding: 20px;
	width: 100%;
}
.no-option-form {
	margin-top: 19%;
	margin-bottom: 19%;
	text-align: center;
}
.send-message-div {
	display: flex;
	align-items: center;
	justify-content: center;
}
.contact-form-outer-div{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}
.contact-form-input-wrap{
	display: flex !important;
}
.contact-form-input-wrap span{
	margin-top: -10px !important;
}
.radio-contact input {
	min-height: 23px;
}
#default-flag-contact {
	margin-top: -10px;
	min-height: 23px;
	cursor: pointer;
}
.resume-contact-div {
	display: flex;
}
.file-contact-view {
	padding: 0px 10px;
}
.resume-contact-title {
	font-size: 14px;
	color: #000000;
	font-weight: 700;
}
@media (max-width: 768px) {
	.option-contact-div {
		display: block;
		padding: 20px;
		width: 100%;		
	}
	.contact-form-div {
		padding: 25px;
		width: 100%;
		margin-left: 0px;
	}
}

.h4-contact {
	cursor: auto !important;
}

.job-card-border-class{
	border: 2px solid #0A043B;
}

.search-offline-toogle-div{
	color: #0A043B;
	font-size: 30px;
}

.offline-job-row-class{
	background-color:  #D3D3D3 !important;
}

.login-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	background-image: url(/img/bg-image.jpg);
	margin-top: 100px;
}

.logged-menu {
	font-size: 16px;
    text-transform: uppercase;
	color: #150F3D;
}
.blue-header {
	height: 217px;
	text-align: center;
}

.contact-heading-text {
	font-family: "Rubik";
	font-style: normal;
	color: #ffffff;
	font-size: 64px;
	font-weight: 600;
	text-align: center;
	line-height: 76px;
	/* width: 300px; */
	margin-top: 7%;
  }

  @media (max-width: 768px) {
	.contact-heading-text {
	  font-size: 40px;
	}

	.blue-header{
		height: auto;
	}
  }

  .hero-services {
	width: 1600px;
	max-width: 100%;
	margin-top: 7%;
	display: flex;
	justify-content: center;
	align-items: center;
  }
  @media (max-width: 768px) {
	.hero-services {
	  width: 100%;
	}
	.hero-services img {
		height: 280px !important;
	}
  }
  .hero-services img {
	width: 1600px;
	height: 350px;
	-o-object-fit: cover;
	   object-fit: cover;
  }
  .hero-services .hero-content-services {
	position: absolute;
	margin-top: 8%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	color: #FFFFFF !important;
  }
  @media (max-width: 768px) {
	.hero-services .hero-content-services {
	  margin-top: 45%;
	}
  }
  .hero-services .hero-content-services h1 {
	font-size: 64px;
	font-weight: 600;
	margin-bottom: 20px;
	line-height: 76px;
	color: #FFFFFF;
  }
  @media (max-width: 768px) {
	.hero-services .hero-content-services h1 {
	  font-size: 40px;
	  line-height: 40px;
	}
  }
  .hero-services .hero-content-services p {
	font-size: 24px;
	color: #FFFFFF;
	font-weight: 500;
	line-height: 156%;
  }
  @media (max-width: 768px) {
	.hero-services .hero-content-services p {
	  font-size: 16px;
	}

	.options-mobile {
		margin-left: 18px;
	}
  }

@media(max-height: 600px){
	.rd-navbar-static .rd-menu {
		height: 75vh;
		overflow-y: scroll;
	}
  }

  .verification-div {
	display: flex;
  	justify-content: left;
	margin-left: -8px;
  }

  .verification-div button {
	margin: 0 10px;
  }

@media(max-width:768px) {
	.job-title-label-one {
		width: 100% !important;
	}

	.recruiter-info-view {
		width: 75% !important;
	}

	.recruiter-item-info-view{
		flex-wrap: wrap !important;
	}
}
  .job-title-label-one {
	font-style: normal;
	font-weight: 500;
	font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
	font-size: 14px;
	line-height: 150%;
	color: black;
	text-transform: capitalize;
}

.loadingCSS-login{
	display: flex;
	justify-content: center;
	align-items: center;

}

.clear-icon {
	font-size: 25px !important;
	color: #0A043B;
	cursor: pointer;
}

.employer-invitation-modal {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	position: absolute !important;
	flex-direction: column;
	width: 40vw;
	left: 30%;
	top: 25%;
	right: auto;
	bottom: auto;
	background-color: white;
	opacity: 1;
	box-shadow: 1px 4px 29px -4px rgba(18, 17, 17, 0.82);
	-webkit-box-shadow: 1px 4px 29px -4px rgba(18, 17, 17, 0.82);
	-moz-box-shadow: 1px 4px 29px -4px rgba(18, 17, 17, 0.82);
	z-index: 1000;
}

@media (max-width: 768px) {
	.employer-invitation-modal {
		left: 3vw;
		width: 90vw;
	}

	.job-details-icon-div {
		width: 24vw;
	}
}

.employer-invitation-form {
	background-color: white;
	width: 100%;
}

.input-div-invitation {
	width: 100%;
	padding: 30px;
}

.loadingCSS-invitation {
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal-footer-invitation {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1rem;
	border-top: 1px solid #e9ecef;
	margin-right: 3%;
}

.button-invitation {
	margin-top: 0px;
}

.whatsapp-invitation {
	cursor: pointer;
}

.title-bar-heading-div{
	margin-top: 0px !important;
	padding-top: 4px !important;
	padding-bottom: 4px !important;
}

.create-company-video-icons{
	position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
	width: 70%;
	margin-top: -23%;
	height: auto;
}

@media(min-height: 650px) and (max-height: 800px){
	.rd-navbar-static .rd-menu-admin {
		height: 75vh !important;
		overflow-y: scroll !important;
	}
}