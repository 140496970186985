/*!

 =========================================================
 * Gulvel Web React - v1.0.0 based on Gulvel Web - v2.2.0
 =========================================================

 * Product Page: https://www.gulvel.com/product/job-plus-2
 * Copyright 2019 Gulvel Inc (http://www.gulvel.com)

 * Designed by www.invisionapp.com Coded by www.gulvel.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

@import 'job-plus/variables';
@import 'job-plus/mixins';

// Employer Dashboard
@import "job-plus/navbars";
@import "job-plus/employer-dashboard";
@import "job-plus/blog-list-page";
@import "job-plus/job-landing-page";
@import "job-plus/error-boundry";
@import "job-plus/blog";
@import "job-plus/home-page";
@import "job-plus/contact-us";
@import "job-plus/thank-you";
@import "job-plus/create-email-edm";
@import "./job-plus/sign-in-popup";
