/*!

 =========================================================
 * Gulvel Web React - v1.0.0 based on Gulvel Web - v2.2.0
 =========================================================

 * Product Page: https://www.gulvel.com/product/job-plus-2
 * Copyright 2019 Gulvel Inc (http://www.gulvel.com)

 * Designed by www.invisionapp.com Coded by www.gulvel.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
/*      light colors - used for select dropdown         */
.navbar,
.navbar > .container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-left: 0px; }
  @media (max-width: 768px) {
    .navbar,
    .navbar > .container {
      display: block;
      width: 100vw; } }

.navbar {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  padding: 1rem 1.15rem;
  border-bottom: 1px solid #eceef3;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  /*
  |-----------------------------------
  | Start navbar logo or brand etc..
  |-----------------------------------
  */
  /*
  |-----------------------------------
  | Start navbar menu
  |-----------------------------------
  */ }
  @media (min-width: 576px) {
    .navbar .container {
      max-width: 540px;
      margin-top: 0px !important; } }
  @media (min-width: 768px) {
    .navbar .container {
      max-width: 720px;
      margin-top: 0px !important; } }
  @media (min-width: 992px) {
    .navbar .container {
      max-width: 960px;
      margin-top: 0px !important; } }
  @media (min-width: 1200px) {
    .navbar .container {
      max-width: 1140px;
      margin-top: 0px !important; } }
  .navbar .navbar-header {
    display: flex;
    align-items: center; }
    @media (max-width: 768px) {
      .navbar .navbar-header img {
        width: 60px;
        height: 60px; } }
    @media (max-width: 768px) {
      .navbar .navbar-header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row-reverse; } }
    .navbar .navbar-header .navbar-toggler {
      cursor: pointer;
      border: none;
      display: none;
      outline: none; }
      @media (max-width: 768px) {
        .navbar .navbar-header .navbar-toggler {
          display: block; } }
      .navbar .navbar-header .navbar-toggler span {
        height: 2px;
        width: 22px;
        background-color: #595959;
        display: block; }
      .navbar .navbar-header .navbar-toggler span:not(:last-child) {
        margin-bottom: 0.2rem; }
    .navbar .navbar-header > a {
      font-weight: 500;
      color: #000000; }
  .navbar .navbar-menu {
    display: flex;
    align-items: center;
    flex-basis: auto;
    flex-grow: 1; }
    @media (max-width: 768px) {
      .navbar .navbar-menu {
        display: none;
        text-align: center; } }
    .navbar .navbar-menu.active {
      display: flex !important; }
    .navbar .navbar-menu .navbar-nav {
      margin-left: auto;
      flex-direction: row;
      display: flex;
      padding-left: 0;
      margin-bottom: 0;
      list-style: none; }
      @media (max-width: 768px) {
        .navbar .navbar-menu .navbar-nav {
          width: 100%;
          display: flex;
          border-top: 1px solid #EEE;
          margin-top: 1rem;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column; } }
      .navbar .navbar-menu .navbar-nav > li > ul > li > a {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-transform: capitalize;
        color: #66615b;
        opacity: 0.5;
        color: #000000;
        text-decoration: none;
        display: inline-block;
        padding: 0.5rem 1rem; }
        .navbar .navbar-menu .navbar-nav > li > ul > li > a > i {
          width: 30px; }
        .navbar .navbar-menu .navbar-nav > li > ul > li > a:hover {
          color: #66615b;
          opacity: 1; }
      .navbar .navbar-menu .navbar-nav > li > a {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-transform: capitalize;
        color: #66615b;
        opacity: 0.5;
        color: #000000;
        text-decoration: none;
        display: inline-block;
        padding: 0.5rem 1rem; }
        .navbar .navbar-menu .navbar-nav > li > a:hover {
          color: #66615b;
          opacity: 1; }
      .navbar .navbar-menu .navbar-nav > li.active a {
        color: #150F3D;
        opacity: 1; }
      .navbar .navbar-menu .navbar-nav > li + li {
        margin-left: 5px;
        margin-right: 5px; }
        @media (max-width: 768px) {
          .navbar .navbar-menu .navbar-nav > li + li {
            margin-left: 0px;
            margin-right: 0px;
            text-align: left; } }
      .navbar .navbar-menu .navbar-nav .home-menu {
        margin-right: 9px; }
      .navbar .navbar-menu .navbar-nav .navbar-dropdown .dropdown {
        list-style: none;
        position: absolute;
        top: 164%;
        left: 0;
        background-color: #fff;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        min-width: 160px;
        width: auto;
        white-space: nowrap;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
        z-index: 99999;
        border-radius: 0.75rem;
        display: none; }
        @media (max-width: 768px) {
          .navbar .navbar-menu .navbar-nav .navbar-dropdown .dropdown {
            position: relative;
            box-shadow: none;
            text-align: left; } }
        .navbar .navbar-menu .navbar-nav .navbar-dropdown .dropdown li a {
          color: #000000;
          padding: 0.25rem 1rem;
          display: block; }
        .navbar .navbar-menu .navbar-nav .navbar-dropdown .dropdown.show {
          display: block !important; }
      .navbar .navbar-menu .navbar-nav .dropdown > .separator {
        height: 1px;
        width: 100%;
        margin-top: 9px;
        margin-bottom: 9px;
        background-color: #eceef3; }
  .navbar .navbar-dropdown {
    position: relative; }

.navbar .navbar-header > a span {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: #66615b;
  opacity: 0.5; }

.navbar .navbar-header h4 {
  font-weight: 500;
  font-size: 1.25rem; }
  @media (max-width: 768px) {
    .navbar .navbar-header h4 {
      font-size: 1.05rem; } }

.header-sign-btn-li {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px !important; }

.navbar-menu-span {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: #000000;
  text-decoration: none;
  display: inline-block;
  padding: 0.5rem 1rem;
  color: #150F3D; }

.navbar-menu-span:hover {
  cursor: pointer; }

.home-row-container {
  padding: 20px; }

.dashboar-outer-section {
  background-color: #FAFBFD; }

.search-div {
  width: 119%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 4rem;
  background-color: #FAFBFD; }
  @media screen and (max-width: 768px) {
    .search-div {
      margin-left: 20px;
      width: 109%; } }
  .search-div input::placeholder {
    color: #000000 !important;
    text-align: left;
    margin-left: 10px;
    text-transform: uppercase !important;
    font-style: normal; }
  .search-div input:focus::placeholder {
    opacity: 0; }
  .search-div .search-input {
    width: 80%;
    text-align: left;
    color: #000000;
    font-size: 14px;
    border: none;
    padding-left: 10px; }

.dashboard {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #FAFBFD; }
  @media screen and (max-width: 768px) {
    .dashboard {
      flex-wrap: wrap-reverse;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 0px;
      flex-direction: column;
      margin: 0px; } }
  .dashboard .left-panel {
    height: 100%;
    width: 30vw;
    text-align: center;
    margin: 10px;
    padding: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column; }
    @media screen and (max-width: 768px) {
      .dashboard .left-panel {
        width: 100%;
        margin: 0px; } }
    .dashboard .left-panel .active-job-div {
      width: 100%;
      padding: 10px;
      text-align: left;
      margin-top: 20px;
      cursor: pointer; }
      @media screen and (max-width: 768px) {
        .dashboard .left-panel .active-job-div {
          margin-top: 0px;
          height: auto; } }
      .dashboard .left-panel .active-job-div .active-job-label {
        color: #000000;
        font-size: 14px;
        font-weight: 450;
        margin-bottom: 15px;
        font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
        text-transform: uppercase; }
    .dashboard .left-panel .complete-job-list {
      width: 100%;
      padding: 10px;
      text-align: left;
      margin-top: 20px;
      cursor: pointer; }
      @media screen and (max-width: 768px) {
        .dashboard .left-panel .complete-job-list {
          margin-top: 0px;
          height: auto; } }
      .dashboard .left-panel .complete-job-list .complete-job-label {
        color: #000000;
        font-weight: 450;
        margin-bottom: 15px;
        font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
        text-transform: uppercase; }
    .dashboard .left-panel .infinte-scroll-div {
      height: 300px;
      overflow-y: auto;
      padding-bottom: 20px; }
      @media screen and (max-width: 500px) {
        .dashboard .left-panel .infinte-scroll-div {
          height: 40vh;
          overflow-y: scroll;
          padding-bottom: 5px; } }
      @media screen and (max-width: 350px) {
        .dashboard .left-panel .infinte-scroll-div {
          height: 30vh;
          overflow-y: scroll;
          padding-bottom: 5px; } }
  .dashboard .right-panel {
    height: 100%;
    width: 70vw;
    text-align: center;
    margin: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column; }
    @media screen and (max-width: 768px) {
      .dashboard .right-panel {
        width: 98%;
        height: 100%;
        margin: 0px; } }
    .dashboard .right-panel .visit-job-post-div {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background-color: #FFFFFF !important;
      margin: 15px;
      padding: 0px 10px;
      align-self: flex-end; }
      .dashboard .right-panel .visit-job-post-div label {
        font-size: 13px;
        color: #0A043B;
        font-style: italic;
        font-weight: 500;
        font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
        margin-right: 20px; }
      .dashboard .right-panel .visit-job-post-div i {
        font-size: 10px;
        color: #0A043B;
        font-weight: 400; }
    .dashboard .right-panel .summary-application-div {
      background-color: #FFFFFF;
      height: auto;
      width: 100%;
      padding: 20px;
      border-radius: 8px; }
      @media screen and (max-width: 768px) {
        .dashboard .right-panel .summary-application-div {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          overflow-x: scroll;
          flex-wrap: wrap;
          height: 100%;
          padding: 10px; } }
      .dashboard .right-panel .summary-application-div .summary-top-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        padding: 0px 20px;
        flex-wrap: wrap;
        flex: 50%; }
        @media screen and (max-width: 768px) {
          .dashboard .right-panel .summary-application-div .summary-top-div {
            padding: 0px;
            flex: 100% 1;
            justify-content: flex-start;
            align-items: flex-start; } }
        .dashboard .right-panel .summary-application-div .summary-top-div .summary-left-div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
          padding: 0px 20px;
          flex-wrap: wrap;
          flex: 50%; }
          @media screen and (max-width: 768px) {
            .dashboard .right-panel .summary-application-div .summary-top-div .summary-left-div {
              justify-content: space-between;
              margin-right: 10px;
              flex-wrap: nowrap;
              width: 50%;
              padding: 0px 0px 10px 0px; } }
          .dashboard .right-panel .summary-application-div .summary-top-div .summary-left-div label {
            font-size: 13px;
            color: #9A9A9A;
            font-weight: 500;
            font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
            line-height: 22px; }
            @media screen and (max-width: 768px) {
              .dashboard .right-panel .summary-application-div .summary-top-div .summary-left-div label {
                text-align: left; } }
        .dashboard .right-panel .summary-application-div .summary-top-div .summary-right-div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
          padding: 0px 20px;
          flex-wrap: wrap;
          flex: 50%; }
          @media screen and (max-width: 768px) {
            .dashboard .right-panel .summary-application-div .summary-top-div .summary-right-div {
              justify-content: space-between;
              margin-right: 10px;
              flex-wrap: nowrap;
              padding: 0px 0px 10px 0px;
              width: 50%; } }
          .dashboard .right-panel .summary-application-div .summary-top-div .summary-right-div label {
            font-size: 13px;
            color: #9A9A9A;
            font-weight: 500;
            font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
            line-height: 22px; }
            @media screen and (max-width: 768px) {
              .dashboard .right-panel .summary-application-div .summary-top-div .summary-right-div label {
                text-align: left; } }
      .dashboard .right-panel .summary-application-div .summary-middle-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        padding: 0px 20px;
        flex-wrap: wrap;
        flex: 50%; }
        @media screen and (max-width: 768px) {
          .dashboard .right-panel .summary-application-div .summary-middle-div {
            padding: 0px;
            flex: 100% 1;
            justify-content: flex-start;
            align-items: flex-start; } }
        .dashboard .right-panel .summary-application-div .summary-middle-div .summary-left-div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
          padding: 0px 20px;
          flex-wrap: wrap;
          flex: 50%; }
          @media screen and (max-width: 768px) {
            .dashboard .right-panel .summary-application-div .summary-middle-div .summary-left-div {
              justify-content: space-between;
              margin-right: 10px;
              flex-wrap: nowrap;
              width: 50%;
              padding: 0px 0px 10px 0px; } }
          .dashboard .right-panel .summary-application-div .summary-middle-div .summary-left-div label {
            font-size: 13px;
            color: #9A9A9A;
            font-weight: 500;
            font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
            line-height: 22px; }
            @media screen and (max-width: 768px) {
              .dashboard .right-panel .summary-application-div .summary-middle-div .summary-left-div label {
                text-align: left; } }
        .dashboard .right-panel .summary-application-div .summary-middle-div .summary-right-div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
          padding: 0px 20px;
          flex-wrap: wrap;
          flex: 50%; }
          @media screen and (max-width: 768px) {
            .dashboard .right-panel .summary-application-div .summary-middle-div .summary-right-div {
              justify-content: space-between;
              margin-right: 10px;
              flex-wrap: nowrap;
              padding: 0px 0px 10px 0px;
              width: 50%; } }
          .dashboard .right-panel .summary-application-div .summary-middle-div .summary-right-div label {
            font-size: 13px;
            color: #9A9A9A;
            font-weight: 500;
            font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
            line-height: 22px; }
            @media screen and (max-width: 768px) {
              .dashboard .right-panel .summary-application-div .summary-middle-div .summary-right-div label {
                text-align: left; } }
      .dashboard .right-panel .summary-application-div .summary-bottom-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        padding: 0px 20px;
        flex-wrap: wrap;
        flex: 50%; }
        @media screen and (max-width: 768px) {
          .dashboard .right-panel .summary-application-div .summary-bottom-div {
            padding: 0px;
            flex: 100% 1;
            justify-content: flex-start;
            align-items: flex-start; } }
        .dashboard .right-panel .summary-application-div .summary-bottom-div .summary-left-div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
          padding: 0px 20px;
          flex-wrap: wrap;
          flex: 50%; }
          @media screen and (max-width: 768px) {
            .dashboard .right-panel .summary-application-div .summary-bottom-div .summary-left-div {
              justify-content: space-between;
              margin-right: 10px;
              flex-wrap: nowrap;
              padding: 0px 0px 10px 0px;
              width: 50%; } }
          .dashboard .right-panel .summary-application-div .summary-bottom-div .summary-left-div label {
            font-size: 13px;
            color: #9A9A9A;
            font-weight: 500;
            font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
            line-height: 22px; }
            @media screen and (max-width: 768px) {
              .dashboard .right-panel .summary-application-div .summary-bottom-div .summary-left-div label {
                text-align: left; } }
          .dashboard .right-panel .summary-application-div .summary-bottom-div .summary-left-div .action-label {
            cursor: pointer;
            color: #0A043B; }
        .dashboard .right-panel .summary-application-div .summary-bottom-div .active-inactive-div {
          flex: 0% 1 !important;
          width: 15% !important; }
          @media screen and (max-width: 768px) {
            .dashboard .right-panel .summary-application-div .summary-bottom-div .active-inactive-div {
              justify-content: space-between;
              margin-right: 10px;
              flex-wrap: nowrap;
              width: 100% !important;
              padding: 0px 0px 10px 0px;
              flex: 100% 1 !important; } }
          .dashboard .right-panel .summary-application-div .summary-bottom-div .active-inactive-div .active-inactive-label {
            font-weight: 400;
            line-height: 12px;
            font-size: 15px !important;
            color: #000000;
            font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
            cursor: pointer; }
            @media screen and (max-width: 768px) {
              .dashboard .right-panel .summary-application-div .summary-bottom-div .active-inactive-div .active-inactive-label {
                text-align: left; } }
        .dashboard .right-panel .summary-application-div .summary-bottom-div .summary-right-div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
          padding: 0px 20px;
          flex-wrap: wrap;
          flex: 50%; }
          @media screen and (max-width: 768px) {
            .dashboard .right-panel .summary-application-div .summary-bottom-div .summary-right-div {
              justify-content: space-between;
              margin-right: 10px;
              flex-wrap: nowrap;
              padding: 0px 0px 10px 0px;
              width: 50%; } }
          .dashboard .right-panel .summary-application-div .summary-bottom-div .summary-right-div label {
            font-size: 13px;
            color: #9A9A9A;
            font-weight: 500;
            font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
            line-height: 22px; }
            @media screen and (max-width: 768px) {
              .dashboard .right-panel .summary-application-div .summary-bottom-div .summary-right-div label {
                text-align: left; } }
          .dashboard .right-panel .summary-application-div .summary-bottom-div .summary-right-div .visit-job-arraow-div {
            background-color: transparent;
            width: 100%;
            padding: 5px 10px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-size: 14px;
            color: #000000;
            font-weight: 600;
            cursor: pointer; }
            .dashboard .right-panel .summary-application-div .summary-bottom-div .summary-right-div .visit-job-arraow-div label {
              margin-right: 10px;
              cursor: pointer; }
              .dashboard .right-panel .summary-application-div .summary-bottom-div .summary-right-div .visit-job-arraow-div label:hover {
                color: #0A043B; }
            .dashboard .right-panel .summary-application-div .summary-bottom-div .summary-right-div .visit-job-arraow-div img {
              width: 25px;
              height: 25px;
              cursor: pointer; }
    .dashboard .right-panel .detail-application-div {
      width: 100%;
      padding: 20px 0px;
      text-transform: capitalize; }
      .dashboard .right-panel .detail-application-div .no-record-found-div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size: 16px;
        color: #000000; }
      .dashboard .right-panel .detail-application-div .table-loading-div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%; }
      .dashboard .right-panel .detail-application-div .candidate-list-div {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        height: 500px;
        margin-top: 10px;
        padding-top: 10px;
        overflow-y: auto; }
        .dashboard .right-panel .detail-application-div .candidate-list-div .candidate-list-inner-div {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          width: 100%;
          min-height: 250px; }
        .dashboard .right-panel .detail-application-div .candidate-list-div .candidate-row {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: row;
          width: 100%;
          height: auto;
          padding: 5px 10px;
          background-color: #FFFFFF;
          margin-right: 10px;
          border-radius: 4px;
          margin-bottom: 10px;
          flex-wrap: wrap; }
          @media screen and (max-width: 768px) {
            .dashboard .right-panel .detail-application-div .candidate-list-div .candidate-row {
              height: 100%; } }
          .dashboard .right-panel .detail-application-div .candidate-list-div .candidate-row .candidate-name-div {
            flex-basis: 40%;
            text-align: left;
            cursor: pointer;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center; }
            @media screen and (max-width: 768px) {
              .dashboard .right-panel .detail-application-div .candidate-list-div .candidate-row .candidate-name-div {
                flex-basis: 85%;
                max-height: 35px; } }
            @media screen and (min-width: 1325px) {
              .dashboard .right-panel .detail-application-div .candidate-list-div .candidate-row .candidate-name-div {
                flex-basis: 45%; } }
            .dashboard .right-panel .detail-application-div .candidate-list-div .candidate-row .candidate-name-div label {
              font-weight: 600;
              line-height: 12px;
              font-size: 13px !important;
              color: #000000;
              font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
              font-style: italic;
              cursor: pointer; }
          .dashboard .right-panel .detail-application-div .candidate-list-div .candidate-row .candidate-percent-div {
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px; }
          .dashboard .right-panel .detail-application-div .candidate-list-div .candidate-row .provider-action-div {
            width: 30%;
            justify-content: flex-end;
            align-items: center;
            padding: 0px 20px; }
            @media screen and (max-width: 768px) {
              .dashboard .right-panel .detail-application-div .candidate-list-div .candidate-row .provider-action-div {
                flex-basis: 45%;
                stify-content: flex-start;
                padding: 0px 0px;
                margin-right: 10px; } }
            .dashboard .right-panel .detail-application-div .candidate-list-div .candidate-row .provider-action-div .status-select-div {
              width: 70%;
              align-self: flex-end;
              float: right; }
              @media screen and (max-width: 768px) {
                .dashboard .right-panel .detail-application-div .candidate-list-div .candidate-row .provider-action-div .status-select-div {
                  width: 100%;
                  float: left;
                  text-align: left; } }
              .dashboard .right-panel .detail-application-div .candidate-list-div .candidate-row .provider-action-div .status-select-div label {
                font-weight: 600;
                line-height: 12px;
                font-size: 13px !important;
                color: #000000;
                font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
                font-style: italic; }
          .dashboard .right-panel .detail-application-div .candidate-list-div .candidate-row .assesment-div {
            flex-basis: 20%;
            text-align: left; }
            @media screen and (max-width: 768px) {
              .dashboard .right-panel .detail-application-div .candidate-list-div .candidate-row .assesment-div {
                float: right;
                justify-self: end; } }
            .dashboard .right-panel .detail-application-div .candidate-list-div .candidate-row .assesment-div .assesment-button {
              padding: 5px 10px;
              font-size: 10px; }
            .dashboard .right-panel .detail-application-div .candidate-list-div .candidate-row .assesment-div label {
              font-weight: 600;
              line-height: 12px;
              font-size: 13px !important;
              color: #000000;
              font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
              font-style: italic; }

.employer-home-page-outer-div {
  width: 100%;
  height: 100vh;
  height: 10vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #FAFBFD; }
  @media screen and (max-width: 768px) {
    .employer-home-page-outer-div {
      flex-wrap: wrap;
      height: 100%; } }

.margin-top0 {
  margin-top: 0px !important; }

.dashboard-row-margin {
  margin: 28px 10px 10px 0px !important;
  height: 40px !important;
  width: 100% !important; }

.title-dashboard-arrow-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-right: 10px; }
  @media screen and (max-width: 768px) {
    .title-dashboard-arrow-div {
      margin-top: 0px;
      margin-right: 0px;
      flex-wrap: wrap;
      height: 100%; } }
  .title-dashboard-arrow-div .employer-homepage-title-div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 60px; }
    @media screen and (max-width: 768px) {
      .title-dashboard-arrow-div .employer-homepage-title-div {
        width: 100%;
        height: 100%;
        justify-content: flex-start; } }
    .title-dashboard-arrow-div .employer-homepage-title-div .employer-homepage-title {
      font-size: 30px;
      color: #000;
      font-weight: 450; }
      @media screen and (max-width: 768px) {
        .title-dashboard-arrow-div .employer-homepage-title-div .employer-homepage-title {
          font-size: 20px; } }
  .title-dashboard-arrow-div .dashboard-arrow-div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
    background-color: #FAFBFD;
    height: 60px;
    padding: 5px 15px 5px 5px;
    flex-direction: row; }
    .title-dashboard-arrow-div .dashboard-arrow-div .view-dashboard {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      background-color: #FFFFFF !important;
      padding: 1px 5px; }
    @media screen and (max-width: 768px) {
      .title-dashboard-arrow-div .dashboard-arrow-div {
        width: 100%;
        height: 100%;
        flex-direction: row;
        justify-content: flex-end;
        margin: 0px 5px;
        margin-bottom: 10px; } }
    .title-dashboard-arrow-div .dashboard-arrow-div i {
      font-size: 10px;
      color: #0A043B;
      font-weight: 400; }
    .title-dashboard-arrow-div .dashboard-arrow-div .visit-dashboard-text {
      font-size: 15px;
      color: #000;
      padding-right: 10px;
      font-weight: 400 !important;
      font-style: italic; }
      .title-dashboard-arrow-div .dashboard-arrow-div .visit-dashboard-text:hover {
        color: #0A043B;
        cursor: pointer; }
    .title-dashboard-arrow-div .dashboard-arrow-div .bold-image {
      width: 15px;
      height: 20px; }
      @media screen and (max-width: 768px) {
        .title-dashboard-arrow-div .dashboard-arrow-div .bold-image {
          width: 25px;
          height: 25px; } }

.notification-active-job-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  color: #000; }
  @media screen and (max-width: 768px) {
    .notification-active-job-div {
      width: 100%;
      flex-wrap: wrap-reverse;
      height: 100%; } }
  .notification-active-job-div .notification-board-div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 50%;
    height: 80vh;
    background-color: #ffffff;
    padding: 20px; }
    @media screen and (max-width: 768px) {
      .notification-active-job-div .notification-board-div {
        width: 100%;
        margin-right: 0px;
        padding: 0px;
        margin-top: 30px;
        height: 50vh; } }
    .notification-active-job-div .notification-board-div .notification-board-heading {
      margin-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      width: 100%;
      padding-bottom: 10px; }
      @media screen and (max-width: 768px) {
        .notification-active-job-div .notification-board-div .notification-board-heading {
          justify-content: left;
          padding-left: 0px;
          padding-bottom: 0px;
          padding-left: 15px; } }
      .notification-active-job-div .notification-board-div .notification-board-heading p {
        font-size: 18px !important;
        font-weight: 500;
        color: #000000; }
    .notification-active-job-div .notification-board-div p {
      font-size: 14px !important;
      color: gray; }
      @media screen and (max-width: 768px) {
        .notification-active-job-div .notification-board-div p {
          font-size: 20px; } }
    .notification-active-job-div .notification-board-div .list-for-notification-board {
      margin-top: 20px;
      padding: 0px 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 55vh;
      width: 100%;
      overflow-y: scroll; }
      .notification-active-job-div .notification-board-div .list-for-notification-board ol {
        height: 100%; }
      .notification-active-job-div .notification-board-div .list-for-notification-board p,
      .notification-active-job-div .notification-board-div .list-for-notification-board span {
        font-size: 12px !important;
        line-height: 15px;
        color: #9A9A9A;
        font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important; }
        @media screen and (max-width: 768px) {
          .notification-active-job-div .notification-board-div .list-for-notification-board p,
          .notification-active-job-div .notification-board-div .list-for-notification-board span {
            font-size: 14px; } }
      .notification-active-job-div .notification-board-div .list-for-notification-board span {
        color: #0A043B;
        font-style: italic; }
        .notification-active-job-div .notification-board-div .list-for-notification-board span:hover {
          cursor: pointer; }
      .notification-active-job-div .notification-board-div .list-for-notification-board .title-name-text {
        font-weight: 600;
        line-height: 12px;
        font-size: 13px !important;
        color: #000000;
        font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important; }
  .notification-active-job-div .active-job-post-dashboard-div {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 50%;
    height: 80vh;
    background-color: #ffffff;
    padding: 9px;
    margin-left: -17px; }
    .notification-active-job-div .active-job-post-dashboard-div .margin-top-100 {
      margin-top: 100px; }
    @media screen and (max-width: 768px) {
      .notification-active-job-div .active-job-post-dashboard-div {
        width: 100%;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin-left: 0px;
        padding: 8px; } }
    .notification-active-job-div .active-job-post-dashboard-div p {
      font-size: 25px; }
      @media screen and (max-width: 768px) {
        .notification-active-job-div .active-job-post-dashboard-div p {
          font-size: 20px; } }
    .notification-active-job-div .active-job-post-dashboard-div .active-job-post-heading {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: row;
      width: 100%;
      margin-left: -15px;
      padding-bottom: 20px; }
      @media screen and (max-width: 768px) {
        .notification-active-job-div .active-job-post-dashboard-div .active-job-post-heading {
          justify-content: left;
          padding-left: 15px; } }
      @media screen and (max-width: 375px) {
        .notification-active-job-div .active-job-post-dashboard-div .active-job-post-heading {
          margin-top: 0px;
          padding-bottom: 0px; } }
      .notification-active-job-div .active-job-post-dashboard-div .active-job-post-heading p {
        font-size: 18px !important;
        font-weight: 500; }
    .notification-active-job-div .active-job-post-dashboard-div .loading-center {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0px; }
    .notification-active-job-div .active-job-post-dashboard-div .job-row-component-div {
      height: 55vh;
      width: 100%;
      margin-top: 10px;
      overflow-y: scroll; }
      @media screen and (max-width: 768px) {
        .notification-active-job-div .active-job-post-dashboard-div .job-row-component-div {
          height: 40vh; } }
      @media screen and (max-width: 350px) {
        .notification-active-job-div .active-job-post-dashboard-div .job-row-component-div {
          height: 30vh; } }
      .notification-active-job-div .active-job-post-dashboard-div .job-row-component-div .padding-bottom-10 {
        padding-bottom: 10px; }
      @media screen and (max-width: 768px) {
        .notification-active-job-div .active-job-post-dashboard-div .job-row-component-div p {
          font-size: 12px; } }

.dashboar-row-outer-main-div {
  height: 40px;
  margin: 0;
  padding: 0;
  width: 100%;
  margin: 10px 10px 10px 10px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px; }

.dashboar-row-outer-div {
  width: 95%;
  height: 40px;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: row;
  margin: 10px 10px 10px 10px;
  border: 1px solid #9691ab;
  padding: 0;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px; }
  .dashboar-row-outer-div:focus {
    background-color: #0A043B; }
  .dashboar-row-outer-div .circle {
    width: 55px;
    height: 40px;
    float: left;
    background-color: #0A043B;
    border-radius: 50%;
    overflow: hidden;
    top: 0;
    position: relative;
    margin-right: 10px; }
  .dashboar-row-outer-div .dashboard-circle {
    width: 40px !important;
    height: 40px !important; }
  .dashboar-row-outer-div .job-title {
    font-size: 13px;
    text-transform: capitalize; }
    @media screen and (max-width: 768px) {
      .dashboar-row-outer-div .job-title {
        font-size: 11px !important; }
        .dashboar-row-outer-div .job-titlep {
          font-size: 11px !important; } }
  .dashboar-row-outer-div .job-create-date-label {
    font-size: 13px; }
    @media screen and (max-width: 768px) {
      .dashboar-row-outer-div .job-create-date-label {
        font-size: 11px; } }
  .dashboar-row-outer-div .main-content-div-if-notification {
    flex-basis: 50%;
    border-right: 1px solid #9691ab;
    height: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center; }
  .dashboar-row-outer-div .date-div-if-notification {
    flex-basis: 15%;
    border-right: 1px solid #9691ab;
    height: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100px; }
  .dashboar-row-outer-div .main-content-no-notification {
    flex-basis: 20rem;
    border-right: 1px solid #9691ab;
    height: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center; }
  .dashboar-row-outer-div .date-no-notification {
    flex-basis: 15%;
    border-right: 1px solid #9691ab;
    height: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: none;
    min-width: 90px; }
  .dashboar-row-outer-div .notification-div {
    flex-basis: 27%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; }
    .dashboar-row-outer-div .notification-div label {
      font-family: Rubik, Calibri, "Trebuchet MS", sans-serif;
      width: 100%;
      letter-spacing: 1px; }
  .dashboar-row-outer-div label {
    font-size: 18px;
    font-weight: 600;
    color: #000000; }

.dashboard-onclick-class {
  border: 2px solid #0A043B; }
  .dashboard-onclick-class label {
    color: #9A9A9A !important; }
  .dashboard-onclick-class .accept-reject-employer-label-div label {
    color: #080838 !important; }

.admin-dashboard-onclick-class {
  border: 2px solid #0A043B;
  background-color: #0A043B; }
  .admin-dashboard-onclick-class label {
    color: #ffffff !important; }

.dashboard-hover:hover {
  background-color: #0A043B !important; }
  .dashboard-hover:hover label {
    color: #FFFFFF !important; }

.new-dashboard-outer-div {
  display: flex;
  justify-content: center !important;
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding: 5px 5px 5px 15px;
  height: auto;
  background-color: #FFFFFF;
  margin-bottom: 10px;
  border-radius: 8px; }
  .new-dashboard-outer-div label,
  .new-dashboard-outer-div p,
  .new-dashboard-outer-div div,
  .new-dashboard-outer-div span {
    font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important; }
  .new-dashboard-outer-div:hover {
    border: 2px solid #0A043B;
    border-radius: 8px;
    height: auto; }
  .new-dashboard-outer-div .first-dashboard-item {
    display: flex;
    align-items: center;
    width: 100%;
    height: auto; }
  @media screen and (max-width: 768px) {
    .new-dashboard-outer-div {
      width: 98%; } }

.job-description-div {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 5px;
  padding-left: 15px; }
  .job-description-div p {
    font-weight: 600;
    line-height: 12px;
    font-size: 13px !important;
    color: #000000;
    font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important; }
  .job-description-div label {
    font-size: 12px;
    line-height: 15px;
    text-align: left;
    color: #9A9A9A;
    font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important; }

.dashboard-job-logo {
  border-radius: 50%;
  width: 35px;
  height: 35px; }
  @media screen and (max-width: 500px) {
    .dashboard-job-logo {
      min-height: 25px;
      min-width: 25px;
      max-height: 25px;
      max-width: 25px;
      height: 25px !important;
      width: 25px !important; } }

.double-job-component {
  margin-top: -80px;
  margin-left: 285px; }

.new-dashboard-outer-div-class {
  display: flex;
  width: 48%; }
  .new-dashboard-outer-div-class:hover {
    border: 2px solid #0A043B;
    border-radius: 8px; }
  .new-dashboard-outer-div-class .job-desciption {
    margin-top: -13px; }
  .new-dashboard-outer-div-class .second-dashboard-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px; }

@media screen and (min-width: 768px) {
  .filters-style {
    margin-left: 5px;
    margin-right: 5px; } }

.filter-dashboard {
  margin-left: 80%; }

.dashboard-width {
  width: 100%; }

.filter-label {
  text-align: left; }

.search-content-dashboard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  text-align: left;
  margin-bottom: 20px; }

.filter-div-width {
  margin-right: 5%;
  cursor: pointer; }
  @media screen and (max-width: 768px) {
    .filter-div-width {
      margin-right: 0px;
      width: 38% !important; } }

.search-div-width {
  width: 80%; }

.label-filter {
  font-size: 14px;
  cursor: pointer; }
  @media screen and (max-width: 768px) {
    .label-filter {
      margin-left: 10px !important; } }

.disable-btn {
  opacity: 0.70; }
  .disable-btn:hover {
    background-color: #0A043B;
    border: 1px solid #0A043B; }

.auto-suggest-component-outer-wrapper .react-autosuggest__suggestions-container {
  max-height: 150px;
  overflow-y: scroll;
  cursor: pointer; }

.display-flex-justify-aligned-center-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; }

.admin-candidate-list-loading {
  margin: 0;
  height: 50px; }

.ordered-list-faq-class {
  list-style: decimal !important; }

.latest-blog-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #FAFBFD;
  width: 100%;
  height: 400px;
  border-radius: 8px;
  overflow: hidden; }
  .latest-blog-div .latest-blog-image {
    height: 100%;
    width: 75%;
    flex: 1.5 1;
    border-radius: 8px; }
    .latest-blog-div .latest-blog-image img {
      width: auto;
      height: 100%;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px; }
    .latest-blog-div .latest-blog-image .blog-edit-icon {
      position: absolute;
      left: calc(100% - 70.5rem);
      margin-top: 10px;
      color: #0A043B;
      background-color: #FFFFFF;
      font-size: 20px;
      visibility: hidden;
      cursor: pointer;
      padding: 0px 10px; }
    .latest-blog-div .latest-blog-image:hover .blog-edit-icon {
      visibility: visible; }
  .latest-blog-div .latest-blog-info-div {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 15px;
    flex: 1;
    overflow: hidden;
    height: 100%; }
    .latest-blog-div .latest-blog-info-div p {
      display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 9 !important;
      -webkit-box-orient: vertical;
      overflow: hidden; }

.post-top-text {
  font-size: 13px;
  font-weight: 500;
  color: #0A043B;
  text-transform: uppercase;
  font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
  letter-spacing: 0.15rem;
  position: relative;
  margin-top: 20px; }
  .post-top-text::before {
    content: " ";
    height: 2px;
    width: 50%;
    background: #0A043B;
    position: absolute;
    margin: 0;
    left: 0;
    right: 0;
    top: 20px; }

.post-date-label {
  font-size: 12px;
  font-weight: 500;
  color: #0A043B;
  text-transform: uppercase;
  font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
  margin-top: 10px;
  margin-bottom: 10px;
  font-style: italic; }

h4 {
  font-weight: 500;
  font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
  cursor: pointer; }

.blog-list-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 50px;
  padding: 10px;
  border-radius: 8px; }

.blog-item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 1;
  width: 25%;
  max-width: 33%;
  flex-direction: column;
  margin: 10px;
  background-color: #FAFBFD;
  border-radius: 8px;
  position: relative;
  height: 600px; }
  .blog-item label, .blog-item p, .blog-item h4 {
    margin: 10px; }
  .blog-item img {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    align-self: center;
    max-height: 200px;
    width: 100%;
    min-height: 200px; }
  .blog-item .blog-edit-icon {
    position: absolute;
    left: 87%;
    top: 10px;
    background-color: #FFFFFF;
    color: #0A043B;
    font-size: 20px;
    visibility: hidden;
    cursor: pointer;
    padding: 0px 10px; }
  .blog-item:hover .blog-edit-icon {
    visibility: visible; }
  .blog-item p {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 9 !important;
    -webkit-box-orient: vertical;
    overflow: hidden; }

.blog-details-div {
  padding: 20px; }
  .blog-details-div h1 {
    text-transform: capitalize;
    font-weight: 500;
    font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
    margin-top: 20px;
    font-size: 44px; }
  .blog-details-div p {
    -webkit-line-clamp: unset;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
    text-align: justify;
    color: #999; }

.main-div {
  min-height: 50vh;
  height: 100%;
  width: 80vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto; }
  .main-div .item-outer-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 5px 10px;
    padding: 0px 20px 20px 20px;
    background-color: #fff;
    width: 90%;
    border: 1px solid #9691ab; }
    @media (max-width: 768px) {
      .main-div .item-outer-div {
        width: 100%;
        margin: 0 0px 10px; } }
    .main-div .item-outer-div .heading {
      clear: left;
      padding: 15px 20px;
      font-weight: 500;
      border-bottom: 1px solid #9691ab;
      font-size: 14px;
      color: #0A043B;
      width: 100%;
      font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
      margin-bottom: 20px; }
    .main-div .item-outer-div .inner-div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      width: 100%; }
    .main-div .item-outer-div .item-div {
      width: 20%;
      max-width: 20%;
      padding: 5px 15px;
      font-size: 12px; }
      @media (max-width: 768px) {
        .main-div .item-outer-div .item-div {
          width: 100%;
          max-width: 100%; } }
      .main-div .item-outer-div .item-div a {
        word-break: break-word;
        width: 100%;
        font-weight: 400;
        margin-right: 20px;
        text-transform: capitalize;
        color: #0A043B;
        font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important; }

.error-boundry-outer-view {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 90vh; }
  .error-boundry-outer-view .img-div {
    display: flex;
    justify-content: center;
    align-items: center; }
    .error-boundry-outer-view .img-div img {
      width: 350px;
      height: 350px; }
      @media (max-width: 768px) {
        .error-boundry-outer-view .img-div img {
          margin-right: 10px; } }
  .error-boundry-outer-view .header-div {
    display: flex;
    justify-content: center;
    align-items: center; }
    .error-boundry-outer-view .header-div h4 {
      font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
      color: #0A043B; }
  .error-boundry-outer-view .sub-header-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10px; }
    .error-boundry-outer-view .sub-header-div p {
      margin: 0;
      font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
      font-weight: 500;
      color: #66615b;
      font-size: 14px; }

.blog {
  font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important; }
  .blog .section-top-start {
    margin-top: 7rem !important; }
    @media (max-width: 640px) {
      .blog .section-top-start {
        margin-top: 0rem !important; } }
    @media (min-width: 640px) and (max-width: 768px) {
      .blog .section-top-start {
        margin-top: 7rem !important; } }
  .blog .p-4 {
    padding: 1rem !important; }
  .blog .p-3 {
    padding: .75rem !important; }
  .blog .p-12 {
    padding: 3rem !important; }
  .blog .p-5 {
    padding: 1.25rem !important; }
  .blog .p-6 {
    padding: 1.5rem !important; }
  .blog .p-8 {
    padding: 2rem !important; }
  .blog .py-3 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important; }
  .blog .py-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important; }
  .blog .px-3 {
    padding-left: .75rem !important;
    padding-right: .75rem !important; }
  .blog .py-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .blog .px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important; }
  .blog .px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important; }
  .blog .px-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important; }
  .blog .py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .blog .px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important; }
  .blog .px-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important; }
  .blog .py-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .blog .py-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .blog .py-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important; }
  .blog .px-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important; }
  .blog .py-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .blog .px-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important; }
  .blog .py-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .blog .py-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .blog .py-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important; }
  .blog .pt-4 {
    padding-top: 1rem !important; }
  .blog .pb-4 {
    padding-bottom: 1rem !important; }
  .blog .pl-5 {
    padding-left: 1.25rem !important; }
  .blog .pt-6 {
    padding-top: 1.5rem !important; }
  .blog .pt-20 {
    padding-top: 5rem !important; }
  .blog .pb-32 {
    padding-bottom: 8rem !important; }
  .blog .pl-3 {
    padding-left: .75rem !important; }
  .blog .pt-40 {
    padding-top: 10rem !important; }
  .blog .pt-24 {
    padding-top: 6rem !important; }
  .blog .pb-10 {
    padding-bottom: 2.5rem !important; }
  .blog .pb-2 {
    padding-bottom: .5rem !important; }
  .blog .pt-8 {
    padding-top: 2rem !important; }
  .blog .pb-16 {
    padding-bottom: 4rem !important; }
  .blog .pb-8 {
    padding-bottom: 2rem !important; }
  .blog .pb-20 {
    padding-bottom: 5rem !important; }
  .blog .pb-12 {
    padding-bottom: 3rem !important; }
  .blog .pl-4 {
    padding-left: 1rem !important; }
  .blog .pt-5 {
    padding-top: 1.25rem !important; }
  .blog .pl-6 {
    padding-left: 1.5rem !important; }
  .blog .pb-40 {
    padding-bottom: 10rem !important; }
  .blog .pt-3 {
    padding-top: .75rem !important; }
  .blog .pt-px {
    padding-top: 1px !important; }
  .blog .pt-12 {
    padding-top: 3rem !important; }
  .blog .pr-4 {
    padding-right: 1rem !important; }
  .blog .pt-16 {
    padding-top: 4rem !important; }
  .blog .pb-6 {
    padding-bottom: 1.5rem !important; }
  .blog .pt-10 {
    padding-top: 2.5rem !important; }
  .blog .pb-5 {
    padding-bottom: 1.25rem !important; }
  .blog .pr-10 {
    padding-right: 2.5rem !important; }
  .blog .pr-3 {
    padding-right: .75rem !important; }
  .blog .pb-3 {
    padding-bottom: .75rem !important; }
  .blog .pr-12 {
    padding-right: 3rem !important; }
  .blog .pb-24 {
    padding-bottom: 6rem !important; }
  .blog .my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .blog .mx-auto {
    margin-left: auto !important;
    margin-right: auto !important; }
  .blog .-mx-4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important; }
  .blog .my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .blog .-mx-2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important; }
  .blog .-mx-3 {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important; }
  .blog .-mx-5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important; }
  .blog .my-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important; }
  .blog .mx-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important; }
  .blog .my-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important; }
  .blog .my-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important; }
  .blog .-mx-8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important; }
  .blog .my-3 {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important; }
  .blog .my-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .blog .my-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .blog .mt-4 {
    margin-top: 1rem !important; }
  .blog .mb-8 {
    margin-bottom: 2rem !important; }
  .blog .mr-auto {
    margin-right: auto !important; }
  .blog .mb-1 {
    margin-bottom: .25rem !important; }
  .blog .mb-3 {
    margin-bottom: .75rem !important; }
  .blog .mb-2 {
    margin-bottom: .5rem !important; }
  .blog .mt-auto {
    margin-top: auto !important; }
  .blog .mb-4 {
    margin-bottom: 1rem !important; }
  .blog .mb-12 {
    margin-bottom: 3rem !important; }
  .blog .mb-6 {
    margin-bottom: 1.5rem !important; }
  .blog .ml-auto {
    margin-left: auto !important; }
  .blog .mt-12 {
    margin-top: 3rem !important; }
  .blog .mb-16 {
    margin-bottom: 4rem !important; }
  .blog .-mt-24 {
    margin-top: -6rem !important; }
  .blog .mt-3 {
    margin-top: .75rem !important; }
  .blog .ml-2 {
    margin-left: .5rem !important; }
  .blog .-mb-6 {
    margin-bottom: -1.5rem !important; }
  .blog .mt-2 {
    margin-top: .5rem !important; }
  .blog .mt-6 {
    margin-top: 1.5rem !important; }
  .blog .mr-4 {
    margin-right: 1rem !important; }
  .blog .mr-1 {
    margin-right: .25rem !important; }
  .blog .mb-5 {
    margin-bottom: 1.25rem !important; }
  .blog .ml-1 {
    margin-left: .25rem !important; }
  .blog .mr-3 {
    margin-right: .75rem !important; }
  .blog .mt-8 {
    margin-top: 2rem !important; }
  .blog .mr-2 {
    margin-right: .5rem !important; }
  .blog .mb-10 {
    margin-bottom: 2.5rem !important; }
  .blog .mr-8 {
    margin-right: 2rem !important; }
  .blog .ml-4 {
    margin-left: 1rem !important; }
  .blog .mb-20 {
    margin-bottom: 5rem !important; }
  .blog .mr-5 {
    margin-right: 1.25rem !important; }
  .blog .mt-32 {
    margin-top: 8rem !important; }
  .blog .mt-24 {
    margin-top: 6rem !important; }
  .blog .mt-16 {
    margin-top: 4rem !important; }
  .blog .mt-5 {
    margin-top: 1.25rem !important; }
  .blog .-ml-12 {
    margin-left: -3rem !important; }
  .blog .-mt-12 {
    margin-top: -3rem !important; }
  .blog .-mr-12 {
    margin-right: -3rem !important; }
  .blog .-mb-12 {
    margin-bottom: -3rem !important; }
  .blog .mb-24 {
    margin-bottom: 6rem !important; }
  .blog .-mr-6 {
    margin-right: -1.5rem !important; }
  .blog .font-heading {
    font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important; }
  .blog .text-center {
    text-align: center !important; }
  .blog .text-3xl {
    font-size: 1.875rem !important; }
    .blog .text-3xl span {
      font-size: 1.875rem !important; }
  .blog .text-sm {
    font-size: .875rem !important; }
  .blog .text-xs {
    font-size: .75rem !important; }
  .blog .text-4xl {
    font-size: 2.25rem !important;
    line-height: 3.2rem !important; }
  .blog .text-lg {
    font-size: 1.125rem !important; }
  .blog .text-2xl {
    font-size: 1.5rem !important; }
  .blog .text-xl {
    font-size: 1.25rem !important; }
  .blog .text-base {
    font-size: 1rem !important; }
  .blog .font-semibold {
    font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
    font-weight: 600 !important; }
  .blog .font-bold {
    font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
    font-weight: 600 !important; }
  .blog .text-5xl {
    font-size: 3rem !important;
    line-height: 4rem !important; }
  .blog .flex {
    display: -moz-box !important;
    display: flex !important; }
  .blog .flex-wrap {
    flex-wrap: wrap !important; }
  .blog .justify-center {
    -moz-box-pack: center !important;
    justify-content: center !important; }
  .blog .-mx-3 {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important; }
  .blog .w-full {
    width: 100% !important; }
  .blog .width-half {
    width: 50% !important; }
  .blog .width-two-third {
    width: 66.666667% !important; }
    @media (max-width: 640px) {
      .blog .width-two-third {
        width: 100% !important; } }
  .blog .width-one-third {
    width: 33.333333% !important; }
    @media (max-width: 640px) {
      .blog .width-one-third {
        width: 100% !important; } }
  .blog .width-one-fourth {
    width: 25% !important; }
  .blog .width-two-fifth {
    width: 40% !important; }
  .blog .width-three-fifth {
    width: 60% !important; }
  .blog .width-one-fifth {
    width: 20% !important; }
  .blog .w-12 {
    width: 3rem !important; }
  .blog .w-8 {
    width: 2rem !important; }
  .blog .w-24 {
    width: 6rem !important; }
  .blog .w-auto {
    width: auto !important; }
  .blog .w-16 {
    width: 4rem !important; }
  .blog .w-5 {
    width: 1.25rem !important; }
  .blog .w-40 {
    width: 10rem !important; }
  .blog .w-64 {
    width: 16rem !important; }
  .blog .max-w-2xl {
    max-width: 42rem !important; }
  .blog .max-w-xl {
    max-width: 36rem !important; }
  .blog .px-3 {
    padding-left: .75rem !important;
    padding-right: .75rem !important; }
  .blog .mb-12 {
    margin-bottom: 3rem !important; }
  .blog .hover-up-5,
  .blog .hover-up-5:hover {
    -webkit-transition: .3s;
    -moz-transition: .3s;
    transition: .3s; }
  .blog .hover-up-5:hover {
    -webkit-transform: translateY(-5px);
    -moz-transform: translateY(-5px);
    transform: translateY(-5px); }
  .blog .h-10 {
    height: 2.5rem !important; }
  .blog .h-4 {
    height: 1rem !important; }
  .blog .h-6 {
    height: 1.5rem !important; }
  .blog .h-12 {
    height: 3rem !important; }
  .blog .h-8 {
    height: 2rem !important; }
  .blog .h-48 {
    height: 12rem !important; }
  .blog .h-36 {
    height: 9rem !important; }
  .blog .h-24 {
    height: 6rem !important; }
  .blog .h-5 {
    height: 1.25rem !important; }
  .blog .h-80 {
    height: 20rem !important; }
  .blog .h-16 {
    height: 4rem !important; }
  .blog .h-32 {
    height: 8rem !important; }
  .blog .h-full {
    height: 100% !important; }
  .blog .h-20 {
    height: 5rem !important; }
  .blog .h-64 {
    height: 16rem !important; }
  .blog .h-128 {
    height: 32rem !important; }
  .blog .object-cover {
    object-fit: cover !important; }
  .blog .rounded {
    border-radius: .25rem !important; }
  .blog .rounded-xl {
    border-radius: .75rem !important; }
  .blog .rounded-full {
    border-radius: 9999px !important; }
  .blog .rounded-lg {
    border-radius: .5rem !important; }
  .blog .rounded-l {
    border-top-left-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important; }
  .blog .rounded-r {
    border-top-right-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important; }
  .blog .text-blue-200 {
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important; }
  .blog .text-blue-300 {
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important; }
  .blog .text-blue-400 {
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important; }
  .blog .text-blue-600 {
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important; }
  .blog .text-blueGray-200 {
    --tw-text-opacity: 1 !important;
    color: rgba(226, 232, 240, var(--tw-text-opacity)) !important; }
  .blog .text-blueGray-100 {
    --tw-text-opacity: 1 !important;
    color: rgba(241, 245, 249, var(--tw-text-opacity)) !important; }
  .blog .text-gray-800 {
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important; }
  .blog .inline-block {
    display: inline-block !important; }
  .blog .bg-blue-100,
  .blog .bg-blue-200 {
    --tw-bg-opacity: 1 !important; }
  .blog .bg-blue-100 {
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important; }
  .blog .bg-blueGray-500 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(100, 116, 139, var(--tw-bg-opacity)) !important; }
  .blog .bg-blue-500 {
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important; }
  .blog .bg-blue-50, .blog .bg-blue-500 {
    --tw-bg-opacity:1 !important; }
  .blog .bg-blue-50 {
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important; }
  .blog .bg-white {
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important; }
  .blog .bg-blueGray-800, .blog .bg-white {
    --tw-bg-opacity:1 !important; }
  .blog .focus-bg-white:focus {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important; }
  .blog .bg-gray-100 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important; }
  .blog .border {
    border-width: 1px !important; }
  .blog .border-r {
    border-right-width: 1px !important; }
  .blog .border-t {
    border-top-width: 1px !important; }
  .blog .border-l-4 {
    border-left-width: 4px !important; }
  .blog .border-b {
    border-bottom-width: 1px !important; }
  .blog .border-blue-200 {
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important; }
  .blog .border-blueGray-100 {
    --tw-border-opacity: 1 !important;
    border-color: rgba(241, 245, 249, var(--tw-border-opacity)) !important; }
  .blog .border-gray-100 {
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important; }
  .blog .border-blueGray-200 {
    --tw-border-opacity: 1 !important;
    border-color: rgba(226, 232, 240, var(--tw-border-opacity)) !important; }
  .blog .border-blue-300 {
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important; }
  .blog .border-blue-300, .blog .border-white {
    --tw-border-opacity:1 !important; }
  .blog .border-white {
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important; }
  .blog .border-gray-200 {
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important; }
  .blog .border-blue-500 {
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important; }
  .blog .hover-border-gray-200:hover {
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important; }
  .blog .text-white {
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important; }
  .blog .text-blue-800,
  .blog .text-white {
    --tw-text-opacity: 1 !important; }
  .blog .bg-cover {
    background-size: cover !important; }
  @media (min-width: 768px) {
    .blog .author-banner-div {
      margin-top: -20px; } }
  .blog .bg-center {
    background-position: 50% !important; }
  .blog .bg-top {
    background-position: top !important; }
  .blog .bg-no-repeat {
    background-repeat: no-repeat !important; }
  .blog .text-blueGray-400 {
    --tw-text-opacity: 1 !important;
    color: rgba(148, 163, 184, var(--tw-text-opacity)) !important; }
  .blog .text-blue-500:hover {
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important; }
  .blog .leading-none {
    line-height: 1 !important; }
  .blog .leading-loose {
    line-height: 2 !important; }
  .blog .leading-normal {
    line-height: 1.5 !important; }
  .blog .hover-underline:hover {
    text-decoration: underline !important; }
  .blog .shadow {
    --tw-shadow: 0px 2px 4px rgba(148, 163, 184, 0.05), 0px 6px 24px rgba(235, 238, 251, 0.4) !important;
    -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important; }
  .blog .antialiased {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important; }
  .blog .max-w-screen-sm {
    max-width: 640px !important; }
  .blog .space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(.75rem * var(--tw-space-y-reverse)) !important; }
  .blog .space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1rem * var(--tw-space-y-reverse)) !important; }
  .blog .-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-.5rem * calc(1 - var(--tw-space-x-reverse))) !important; }
  .blog .space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(3rem * var(--tw-space-y-reverse)) !important; }
  .blog .space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse))) !important; }
  .blog .space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(.5rem * var(--tw-space-y-reverse)) !important; }
  .blog .flex-1 {
    -moz-box-flex: 1 !important;
    flex: 1 1 !important; }
  .blog .flex-shrink-0 {
    flex-shrink: 0 !important; }
  .blog .leading-relaxed {
    line-height: 1.625 !important; }
  .blog .items-center {
    -moz-box-align: center !important;
    align-items: center !important; }
  .blog .resize-none {
    resize: none !important; }
  .blog .outline-none {
    outline: none !important; }
    .blog .outline-none input {
      border: none !important;
      outline: none !important;
      border-color: transparent !important;
      border-width: 0px !important;
      border-radius: 0px !important;
      border-style: none !important; }
  .blog .focus-outline-none:focus {
    outline: 2px solid transparent !important;
    outline-offset: 2px !important; }
  .blog .placeholder-white:-ms-input-placeholder {
    --tw-placeholder-opacity: 1 !important;
    color: rgba(24, 24, 24, var(--tw-placeholder-opacity)) !important; }
  .blog .placeholder-white::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1 !important;
    color: rgba(24, 24, 24, var(--tw-placeholder-opacity)) !important; }
  .blog .placeholder-white::placeholder {
    --tw-placeholder-opacity: 1 !important;
    color: rgba(24, 24, 24, var(--tw-placeholder-opacity)) !important; }
  .blog .input-div {
    height: 4rem;
    margin-bottom: 20px; }
  .blog .subscribe-button {
    width: 9rem !important;
    margin-top: auto;
    margin-bottom: auto; }
  .blog strong {
    font-weight: 600 !important; }
  .blog .blog-item-div {
    position: relative; }
  .blog .blog-edit-icon {
    position: absolute;
    right: 1.5rem;
    margin-top: 10px;
    color: #0A043B;
    background-color: #FFFFFF;
    font-size: 20px;
    visibility: visible;
    cursor: pointer;
    padding: 0px 10px; }
  .blog .blog-details-banner {
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.3); }
    @media (min-width: 1275px) and (max-width: 1300px) {
      .blog .blog-details-banner {
        height: 300px !important; } }
  @media (min-width: 640px) {
    .blog .sm-w-8 {
      width: 2rem !important; }
    .blog .sm-w-10 {
      width: 2.5rem !important; }
    .blog .sm-h-8 {
      height: 2rem !important; }
    .blog .sm-h-10 {
      height: 2.5rem !important; }
    .blog .sm-px-6 {
      padding-left: 1.5rem !important;
      padding-right: 1.5rem !important; }
    .blog .sm-py-4 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important; } }
  @media (min-width: 768px) {
    .blog .md-w-full {
      width: 100% !important; }
    .blog .md-width-two-third {
      width: 66.666667% !important; }
    .blog .md-text-lg {
      font-size: 1.125rem !important; }
    .blog .md-mb-0 {
      margin-bottom: 0 !important; }
    .blog .md-mr-6 {
      margin-right: 1.5rem !important; } }
  @media (min-width: 1024px) {
    .blog .lg-text-3xl {
      font-size: 1.875rem !important; } }
  .blog .flex-direction-coloum {
    flex-direction: column;
    align-items: flex-start !important; }
  .blog .reply-icon-div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    color: #0A043B; }
  .blog .default-comment-author {
    font-size: 30px !important; }
  .blog .default-comment-reply-author {
    font-size: 21px !important; }
  .blog .default-reply-author {
    font-size: 18px !important;
    margin-top: -4px !important; }
  .blog .title-blog-link:hover {
    color: #0A043B !important; }
  @media (max-width: 768px) {
    .blog .tag-item {
      font-size: 8px !important; } }
  @media (max-width: 768px) {
    .blog .blog-title-responsive {
      font-size: 12px !important;
      line-height: 14px !important; } }
  @media (min-width: 1275px) and (max-width: 1300px) {
    .blog .blog-title-responsive {
      font-size: 2.5rem !important;
      line-height: 3rem !important; } }
  @media (max-width: 768px) {
    .blog .blog-details-banner {
      padding-top: 10px !important;
      padding-bottom: 0px !important;
      margin-bottom: 10px !important;
      margin-top: 90px !important; } }
  @media (min-width: 1275px) and (max-width: 1300px) {
    .blog .blog-margin-10 {
      margin-top: -15px; } }

.hero-banner-image-outer-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  margin-top: 100px;
  height: auto;
  padding-bottom: 10px; }
  @media (max-width: 768px) {
    .hero-banner-image-outer-wrapper {
      margin-top: 0px;
      padding-top: 0px;
      height: 400px;
      background-image: url(/img/figma/tjp-web-banner.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: right 18% top; } }
  @media (max-width: 768px) {
    .hero-banner-image-outer-wrapper .outer-banner-mobile-view-height-reset {
      height: 350px; } }

@media (max-width: 768px) {
  .outer-banner-mobile-view-height-set {
    height: 540px !important; } }

@media (max-width: 768px) {
  .login-signup-form-margin-mobile {
    margin-top: 100px; } }

@media (max-width: 768px) {
  .jumbotron-main-creative {
    margin-top: 0px; } }

@media (max-width: 768px) {
  .jumbotron-main-creative .header-heading-div {
    margin-left: 0px;
    margin-top: 0px; } }

.hero-image-class {
  height: auto;
  width: 100%; }
  @media (max-width: 768px) {
    .hero-image-class {
      display: none; } }

.hero-banner-outer-div {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 999;
  width: 100%;
  height: auto; }
  @media (max-width: 768px) {
    .hero-banner-outer-div {
      position: static; } }

.login-validation-height {
  height: 650px !important; }
  @media (max-width: 768px) {
    .login-validation-height {
      height: 650px !important; } }

.login-validation-height-reset {
  height: 540px !important; }

.signup-validation-height {
  height: 550px; }

.user-notpresent-height {
  height: 600px !important; }

@media (min-width: 768px) {
  .user-notpresent-validation-height {
    height: 670px !important; } }

.option-contact-div input:is([type="radio"], [type="checkbox"]):after {
  width: 19px;
  height: 19px;
  border-radius: 50%;
  position: relative;
  background-color: #d1d3d1;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
  top: 15%;
  margin-left: -2px; }

.option-contact-div input:is([type="radio"], [type="checkbox"]):checked:after {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
  background-color: #26ae61;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
  top: 15%;
  margin-left: -2px; }

.option-contact-div .contact-radio-label {
  font-size: 16px;
  color: #000000;
  font-weight: 700;
  margin-top: -8px;
  position: absolute;
  margin-left: 15px; }

.contact-option-other-div {
  margin-left: 4.5%; }
  @media (max-width: 768px) {
    .contact-option-other-div {
      margin-left: 0; } }

.p-5 {
  padding: 1.25rem; }

.justify-center {
  justify-content: center; }

.items-center {
  align-items: center; }

@media (min-width: 1024px) {
  .lg-max-w-3xl {
    max-width: 48rem; } }

@media (min-width: 1024px) {
  .lg-p-12 {
    padding: 3rem; } }

.bg-color {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 251, 253, var(--tw-bg-opacity)); }

.min-h-screen {
  min-height: 70vh; }

.flex {
  display: flex; }

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow); }

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity)); }

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity)); }

.p-8 {
  padding: 2rem; }

.rounded-3xl {
  border-radius: 1.5rem; }

.max-w-xl {
  max-width: 36rem; }

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem; }

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem; }

.mt-4 {
  margin-top: 1rem; }

.font-awesome-icon-class {
  font-size: 50px;
  color: #008000; }

.margin-top-100 {
  margin-top: 100px; }
  @media (max-width: 768px) {
    .margin-top-100 {
      margin-top: 0px; } }

.email-edm-checkbox-outer-div {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  width: 40%; }
  .email-edm-checkbox-outer-div[type="checkbox"] {
    display: visible; }
    .email-edm-checkbox-outer-div[type="checkbox"]:checked + label:before {
      background: #222;
      display: visible; }

.email-edm-checkbox {
  min-width: 20px !important;
  min-height: 20px !important; }

.save-email-button-outer-div {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  flex-direction: row; }

.test-disable-btn {
  opacity: 0.70 !important; }

.email-edm-item-icon {
  display: flex;
  justify-content: space-evenly;
  align-items: center; }

.email-edm-showmore-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px; }

.email-subject-td {
  text-align: left !important; }

.admin-email-edm-list-table {
  width: 100%;
  padding: 30px; }

.email-edm-csv-btn-div {
  display: flex;
  justify-content: center;
  align-items: center; }

.sign-in-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out; }

.sign-in-overlay.visible {
  opacity: 1;
  pointer-events: auto; }

.sign-in-popup {
  position: relative;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  padding: 20px;
  text-align: center; }
  @media (max-width: 768px) {
    .sign-in-popup {
      width: 90%; } }

.sign-in-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #150F3D;
  font-weight: 600; }

.sign-in-close:hover {
  color: #279f8d; }

.sign-in-popup h2 {
  margin-top: 0;
  margin-top: 50px;
  margin-bottom: 20px;
  width: 30vw;
  color: #150F3D;
  font-family: "Rubik";
  font-weight: 500;
  font-size: 24px;
  line-height: 150%; }
  @media (max-width: 768px) {
    .sign-in-popup h2 {
      width: 90%; } }

.popup-sign-in-btn {
  color: #FFFFFF !important;
  margin-bottom: 20px; }
