$font-family: 'Rubik', sans-serif;
$font-size-base: 16px;
$base-color: $font-color;
$text-dark: #000000;
$border-color: #eceef3;

$navbar-body-color: #fff;
// $navbar-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.035);
$navbar-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
$navbar-padding-y: 1rem;
$navbar-padding-x: 1.15rem;

$navbar-link-hover: $base-color;
$navbar-link-padding-y: 0.5rem;
$navbar-link-padding-x: 1rem;

$navbar-dropdown-padding-y: 0.5rem;
$navbar-dropdown-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
$navbar-dropdown-radius: 0.75rem;

$navbar-dropdown-item-padding-y: 0.25rem;
$navbar-dropdown-item-padding-x: 1rem;

$navbar-dropdown-separator-margin-y: 9px;
$navbar-dropdown-separator-color: $border-color;

// Start navbar

.navbar,
.navbar>.container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-left: 0px;

  @media (max-width: 768px) {
    display: block;
    width: 100vw;
  }
}

.navbar {
  box-shadow: $navbar-shadow;
  background-color: $navbar-body-color;
  padding: $navbar-padding-y $navbar-padding-x;
  border-bottom: 1px solid $border-color;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;

  .container {
    @media (min-width: 576px) {
      max-width: 540px;
      margin-top: 0px !important;
    }

    @media (min-width: 768px) {
      max-width: 720px;
      margin-top: 0px !important;
    }

    @media (min-width: 992px) {
      max-width: 960px;
      margin-top: 0px !important;
    }

    @media (min-width: 1200px) {
      max-width: 1140px;
      margin-top: 0px !important;
    }
  }

  /*
  |-----------------------------------
  | Start navbar logo or brand etc..
  |-----------------------------------
  */
  .navbar-header {
    display: flex;
    align-items: center;

    & img{

      @media(max-width:768px){
        width: 60px;
        height: 60px;
      }
    }

    @media (max-width: 768px) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row-reverse;
    }

    .navbar-toggler {
      // border-radius: 5px;
      // background-color: transparent;
      cursor: pointer;
      border: none;
      display: none;
      outline: none;

      @media (max-width: 768px) {
        display: block
      }

      span {
        height: 2px;
        width: 22px;
        background-color: lighten($text-dark, 35%);
        display: block;
      }

      span:not(:last-child) {
        margin-bottom: 0.2rem;
      }
    }

    >a {
      font-weight: 500;
      color: $text-dark;
    }
  }

  /*
  |-----------------------------------
  | Start navbar menu
  |-----------------------------------
  */
  .navbar-menu {
    display: flex;
    align-items: center;
    flex-basis: auto;
    flex-grow: 1;

    @media (max-width: 768px) {
      display: none;
      text-align: center;
    }

    &.active {
      display: flex !important;
    }

    // Ul
    .navbar-nav {
      margin-left: auto;
      flex-direction: row;
      display: flex;
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;

      @media (max-width: 768px) {
        width: 100%;
        display: flex;
        border-top: 1px solid #EEE;
        margin-top: 1rem;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
      }

      >li {
        >ul > li > a {
          font-family: 'Rubik';
          font-style: normal;
          font-weight: 500;
          font-size: $font-size-base;
          line-height: 24px;
          text-transform: capitalize;
          color: $base-color;
          opacity: 0.5;

          color: $text-dark;
          text-decoration: none;
          display: inline-block;
          padding: $navbar-link-padding-y $navbar-link-padding-x;

          > i {
            width: 30px;
          }

          &:hover {
            color: $navbar-link-hover;
            opacity: 1;
          }
        }
        >a {
          font-family: 'Rubik';
          font-style: normal;
          font-weight: 500;
          font-size: $font-size-base;
          line-height: 24px;
          text-transform: capitalize;
          color: $base-color;
          opacity: 0.5;

          color: $text-dark;
          text-decoration: none;
          display: inline-block;
          padding: $navbar-link-padding-y $navbar-link-padding-x;

          &:hover {
            color: $navbar-link-hover;
            opacity: 1;
          }

          @media (max-width: 768px) {
            // border-bottom: 1px solid $border-color;
          }
        }

        &.active {
          a {
            color: #150F3D;
            opacity: 1;
          }
        }
      }

      >li+li {
        margin-left: 5px;
        margin-right: 5px;

        @media (max-width: 768px) {
          margin-left: 0px;
          margin-right: 0px;
          text-align: left;
        }
      }

      .home-menu {
        margin-right: 9px;
      }
  
      .navbar-dropdown {
        .dropdown {
          list-style: none;
          position: absolute;
          top: 164%;
          left: 0;
          background-color: #fff;
          padding-top: $navbar-dropdown-padding-y;
          padding-bottom: $navbar-dropdown-padding-y;
          min-width: 160px;
          width: auto;
          white-space: nowrap;
          box-shadow: $navbar-dropdown-shadow;
          z-index: 99999;
          border-radius: $navbar-dropdown-radius;
          display: none;

          @media (max-width: 768px) {
            position: relative;
            box-shadow: none;
            text-align: left;
          }

          li {
            a {
              color: $text-dark;
              padding: $navbar-dropdown-item-padding-y $navbar-dropdown-item-padding-x;
              display: block;
            }
          }

          &.show {
            display: block !important;
          }
        }
      }

      .dropdown>.separator {
        height: 1px;
        width: 100%;

        margin: {
          top: $navbar-dropdown-separator-margin-y;
          bottom: $navbar-dropdown-separator-margin-y;
        }
        background-color: $navbar-dropdown-separator-color;
      }
    }
  }

  .navbar-dropdown {
    position: relative;
  }
}


// Custom
.navbar .navbar-header>a span {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: $font-size-base;
  line-height: 24px;
  text-transform: capitalize;
  color: $base-color;
  opacity: 0.5;
}

.navbar .navbar-header h4 {
  font-weight: 500;
  font-size: 1.25rem;

  @media (max-width: 768px) {
    font-size: 1.05rem;
  }
}

.header-sign-btn-li{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px !important;
}

// .navbar-dropdown .dropdown-toggler i.fa-angle-down {
//   transition: transform 0.3s ease;
// }

// .navbar-dropdown:hover .dropdown-toggler i.fa-angle-down {
//   transform: rotate(180deg);
// }

.navbar-menu-span{
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: #000000;
  text-decoration: none;
  display: inline-block;
  padding: 0.5rem 1rem;
  color: #150F3D;
}

.navbar-menu-span:hover{
  cursor: pointer;
}