.main-div {
    min-height: 50vh;
    height: 100%;
    width: 80vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;

    .item-outer-div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0 5px 10px;
        padding: 0px 20px 20px 20px;
        background-color: #fff;
        width: 90%;
        border: 1px solid rgb(150, 145, 171);

        @media (max-width: 768px){
            width: 100%;
            margin: 0 0px 10px;
        }

        & .heading {
            clear: left;
            padding: 15px 20px;
            font-weight: 500;
            border-bottom: 1px solid rgb(150, 145, 171);
            font-size: 14px;
            color: $brand-color;
            width: 100%;
            font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
            margin-bottom: 20px;
        }

        & .inner-div {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            // max-width: 20%;
        }

        & .item-div {
            width: 20%;
            max-width: 20%;
            padding: 5px 15px;
            font-size: 12px;

            @media (max-width: 768px){
                width: 100%;
                max-width: 100%;
            }

            & a {
                word-break: break-word;
                width: 100%;
                font-weight: 400;
                margin-right: 20px;
                text-transform: capitalize;
                color: $brand-color;
                font-family: 'Rubik', Calibri, 'Trebuchet MS', sans-serif !important;
            }
        }
    }
}